import { Box, Button } from "@mui/material";
import { IconPinKeyboard } from "@neurosolutionsgroup/components";
import { PinIconsDefintion, SafeAreas } from "@neurosolutionsgroup/models";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

interface FixedIconPinKeyboardProps {
  setValue: Dispatch<SetStateAction<string>>;
  pinIcons: PinIconsDefintion;
  confirmDisabled: boolean;
  onConfirm: VoidFunction;
  safeAreas: SafeAreas;
}

const FixedIconPinKeyboard = ({
  setValue,
  pinIcons,
  confirmDisabled,
  onConfirm,
  safeAreas,
}: FixedIconPinKeyboardProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      sx={{
        backgroundColor: "#fff",
        padding: 1,
        paddingBottom: `calc(${safeAreas.bottom} + 12px)`,
      }}
    >
      <IconPinKeyboard setValue={setValue} pinIcons={pinIcons} />
      <Button
        onClick={onConfirm}
        disabled={confirmDisabled}
        color="secondary"
        variant="contained"
        sx={{
          marginTop: 2,
        }}
      >
        {t("general.actions.confirm")}
      </Button>
    </Box>
  );
};

export default FixedIconPinKeyboard;
