import FaceIcon, { FaceState } from "./FaceIcon";
import FeedbackIcon from "./FeedbackIcon";
import { useTranslation } from "react-i18next";
import React from "react";
import { ButtonBase, Grid, Typography } from "@mui/material";

interface IntensityFormProps {
  intensity?: number;
  setIntensity: (newValue: number) => void;
}

const IntensityForm = ({
  intensity,
  setIntensity,
}: IntensityFormProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid container direction="row" justifyContent="space-around">
      <Grid item>
        <IntensityChoice
          label={t("journal.observations.question.intensity.low")}
          icon={FaceState.Happy}
          active={intensity === 1}
          onClick={() => setIntensity(1)}
        />
      </Grid>
      <Grid item>
        <IntensityChoice
          label={t("journal.observations.question.intensity.moderate")}
          icon={FaceState.Neutral}
          active={intensity === 2}
          onClick={() => setIntensity(2)}
        />
      </Grid>
      <Grid item>
        <IntensityChoice
          label={t("journal.observations.question.intensity.high")}
          icon={FaceState.Sad}
          active={intensity === 3}
          onClick={() => setIntensity(3)}
        />
      </Grid>
    </Grid>
  );
};

export default IntensityForm;

interface IntensityChoiceProps {
  label: string;
  icon: FaceState;
  active: boolean;
  onClick: VoidFunction;
}

const IntensityChoice: React.FC<IntensityChoiceProps> = ({
  label,
  icon,
  active,
  onClick,
}) => {
  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <ButtonBase
          onClick={onClick}
          sx={{
            backgroundColor: "#fff",
            borderRadius: "27px",
            padding: "2px",
          }}
        >
          <FeedbackIcon
            shape="square"
            icon={<FaceIcon state={icon} />}
            active={active}
          />
        </ButtonBase>
      </Grid>
      <Grid item>
        <Typography textAlign="center" color="#fff">
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};
