import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChallengeProgressBar from "../../../Components/Challenge/ChallengeDisplayCard/ChallengeProgressBar";

const CurrentChallengeCardDetailsEmptyState = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box>
      {[40, 20, 60, 100].map((value, i) => (
        <Grid container alignItems="center" mb={1} key={i}>
          <Grid item xs={12} display="flex">
            <Typography fontSize="0.8rem">
              {t("general.time.week")}&nbsp;{i + 1}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography fontSize="0.85rem" fontWeight={800}>
              -
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <ChallengeProgressBar value={value} emptyState />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default CurrentChallengeCardDetailsEmptyState;
