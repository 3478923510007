/**
 * Function that transforms any string into a style that is compatible with Google Analytics.
 * @param stringToTransform - Event name or property to transform.
 * @returns Transformed string.
 */
export const gaStringTransformer = (stringToTransform: string): string => {
  let transformedString = stringToTransform.toLowerCase();

  transformedString = transformedString.replace(/[- ]/g, "_");

  transformedString = transformedString.replace(/[.,'/#!$%^&*;:{}=`~()]/g, "");

  return transformedString;
};

export const gaTransformObjectProperties = (
  objectToTransform: Record<string, unknown>
): Record<string, unknown> => {
  const transformedObject: Record<string, unknown> | undefined = {};

  Object.keys(objectToTransform).forEach((key) => {
    transformedObject[gaStringTransformer(key)] = objectToTransform[key];
  });

  return transformedObject;
};

export default gaStringTransformer;
