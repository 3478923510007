import { Box } from "@mui/material";
import {
  getAuth,
  signInWithEmailAndPassword,
  fetchSignInMethodsForEmail,
  GoogleAuthProvider,
  signInWithRedirect,
  OAuthProvider,
} from "firebase/auth";
import useUserProfile from "common/hooks/account/useUserProfile";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AuthIntroPage,
  LandingPage,
  PoliciesPage,
  SignUpPage,
  SignUpSecondPage,
} from "@neurosolutionsgroup/webviews-pages";
import useAuth from "common/hooks/auth/useAuth";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { useAppInitializationContext } from "common/hooks/AppInitializationContext";
import useParameters from "common/hooks/Parameters/useParameters";
import {
  UniWebViewActions,
  UnityAuthAction,
  UnityAuthProvider,
} from "common/hooks/Parameters/UniWebViewActions";
import { CustomWindow } from "custom.window";
import {
  ConfigString,
  WebviewsFeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import { SAFE_AREAS } from "stylesheets";
import { Tools } from "@neurosolutionsgroup/tools";
import Logos from "assets/logo";
import { AgeGateV2 } from "@neurosolutionsgroup/components";
import {
  AgeGateCompleted,
  AppRegistrationSubmitted,
  NavigationSignupCreateAccount,
  NavigationSignupLogin,
  PoliciesAccepted,
  ThirdPartyAppRegistrationSubmitted,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import SubscriptionAssets from "assets/subscription";
import useNavigation from "common/hooks/navigation/useNavigation";

declare let window: CustomWindow;

const SignUp: React.FC = () => {
  // App data.
  const {
    functions: { pageView },
    handleEvent,
  } = useAnalytics();
  const { handleUnknownError } = useErrorsContext();
  const { language } = useLanguage();
  const { sendMessageToUnity, version, os, openPrivacyPolicy, openTerms } =
    useParameters();
  const { checkFeatureFlag, getRemoteConfigValue } = useRemoteConfig();

  // Navigation.
  const navigate = useNavigate();
  const {
    actions: { setParentSectionOpen },
    selectors: { shorterFtue },
  } = useNavigation();
  const [searchParams] = useSearchParams();

  // User data.
  const { userInitiated } = useAppInitializationContext();
  const { trackRegistration, setupAuthFromCustomToken } = useAuth();
  const {
    actions: { registerUser },
  } = useUserProfile();

  // State.
  const [email, setEmail] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState<
    "none" | "authintro" | "policies" | "email" | "info"
  >(searchParams.get("skip-intro") ? "policies" : "authintro");
  const [ageGateIsOpen, setAgeGateIsOpen] = useState<boolean>(false);

  const thirdPartyAuth =
    getRemoteConfigValue(ConfigString.ThirdPartyAuth).asString() === "true";

  const logoImageString = Tools.Language.languageSwitch(language, {
    en: Logos.KairosIllustratedEN,
    fr: Logos.KairosIllustratedFR,
  });

  const privacyEmail = "privacy@kairosgame.com";

  const showOriginalIntroPage = useMemo(() => {
    if (activeStep === "authintro") {
      return !checkFeatureFlag(WebviewsFeatureFlag.ShorterFTUE, version);
    } else {
      return false;
    }
  }, [activeStep, version]);

  const showShorterFTUELandingPage = useMemo(() => {
    if (activeStep === "authintro") {
      return checkFeatureFlag(WebviewsFeatureFlag.ShorterFTUE, version);
    } else {
      return false;
    }
  }, [activeStep, version]);

  window.nativeAuthResult = async (
    successful: boolean,
    customToken?: string
  ) => {
    if (!successful || !customToken) {
      setLoading(false);
      return;
    }

    await setupAuthFromCustomToken(customToken);
  };

  useEffect(() => {
    if (userInitiated) {
      navigate("/");
    }
  }, [userInitiated]);

  useEffect(() => {
    switch (activeStep) {
      case "authintro":
        pageView("landing-page");
        break;
      case "policies":
        pageView("policies");
        break;
      case "email":
        pageView("account-creation");
        break;
      case "info":
        pageView("account-creation-info");
        break;
    }
  }, [activeStep]);

  const onIntroContinue = () => {
    setAgeGateIsOpen(true);

    const event: NavigationSignupCreateAccount = {
      name: "Navigation - Signup to Account Creation",
    };

    handleEvent(event);
  };

  const onAgeGateComplete = () => {
    const event: AgeGateCompleted = {
      name: "Age Gate Completed",
    };

    handleEvent(event);

    setActiveStep("policies");
    setAgeGateIsOpen(false);
  };

  const onIntroLogin = () => {
    navigate("/login");

    const event: NavigationSignupLogin = {
      name: "Navigation - Signup to Login",
    };

    handleEvent(event);
  };

  const onPoliciesContinue = () => {
    const event: PoliciesAccepted = {
      name: "Policies Accepted",
    };

    handleEvent(event);

    setActiveStep("email");
  };

  const onContinueEmail = async (email: string) => {
    setLoading(true);

    // Check if email already in use before next screen.
    const auth = getAuth();

    const signInMethods = await fetchSignInMethodsForEmail(auth, email);

    if (signInMethods.length > 0) {
      setLoading(false);

      navigate("/login", {
        state: { email },
      });

      return;
    }

    setLoading(false);
    setEmail(email);
    setActiveStep("info");
  };

  const onContinueGoogle = () => {
    setLoading(true);

    const event: ThirdPartyAppRegistrationSubmitted = {
      name: "Third Party Registration Started",
      eventProperties: {
        provider: "google",
      },
    };

    handleEvent(event);

    if (checkFeatureFlag(WebviewsFeatureFlag.NativeThirdPartyAuth, version)) {
      sendMessageToUnity(
        UniWebViewActions.NativeAuthenication,
        `provider=${UnityAuthProvider.Google}&language=${language}&flow=${UnityAuthAction.Signup}`
      );
    } else {
      const provider = new GoogleAuthProvider();

      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

      const auth = getAuth();

      auth.languageCode = language;

      signInWithRedirect(auth, provider);
    }
  };

  const onContinueApple = () => {
    setLoading(true);

    const event: ThirdPartyAppRegistrationSubmitted = {
      name: "Third Party Registration Started",
      eventProperties: {
        provider: "apple",
      },
    };

    handleEvent(event);

    if (
      os === "ios" &&
      checkFeatureFlag(WebviewsFeatureFlag.NativeThirdPartyAuth, version)
    ) {
      sendMessageToUnity(
        UniWebViewActions.NativeAuthenication,
        `provider=${UnityAuthProvider.Apple}&language=${language}&flow=${UnityAuthAction.Signup}`
      );
    } else {
      const provider = new OAuthProvider("apple.com");

      provider.addScope("email");
      provider.addScope("name");

      const auth = getAuth();

      provider.setCustomParameters({
        locale: language,
      });

      signInWithRedirect(auth, provider);
    }
  };

  const parseName = (
    name: string
  ): { firstName?: string; lastName?: string } => {
    return { firstName: name, lastName: undefined };
  };

  const onSubmit = async (name: string, password: string) => {
    if (email) {
      setLoading(true);

      const event: AppRegistrationSubmitted = {
        name: "App Registration Submitted",
      };

      handleEvent(event);

      const names = parseName(name);

      try {
        await registerUser(email, password, names.firstName, names.lastName);
      } catch (err) {
        setLoading(false);

        handleUnknownError(err);

        return;
      }

      const auth = getAuth();

      try {
        const userCred = await signInWithEmailAndPassword(
          auth,
          email,
          password
        );

        if (shorterFtue) {
          setParentSectionOpen(true);
        }

        trackRegistration(userCred.user.uid, "email", language, email, name);
      } catch (err) {
        setLoading(false);

        handleUnknownError(err);

        navigate("/login");

        return;
      }
    } else {
      setActiveStep("none");
    }
  };

  return (
    <Box
      sx={(theme) => ({
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.background.default,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      })}
    >
      <AuthIntroPage
        onSignup={onIntroContinue}
        onLogin={onIntroLogin}
        open={showOriginalIntroPage}
        logoImageString={logoImageString}
        productName={Tools.Environment.getProductName()}
        safeAreas={SAFE_AREAS}
      />
      <LandingPage
        language={language}
        logoImgSrc={{
          de: Logos.KairosSimpleTagEN,
          en: Logos.KairosSimpleTagEN,
          fr: Logos.KairosSimpleTagFR,
        }}
        mainImgSrc={SubscriptionAssets.Decoration}
        onCreateAccount={onIntroContinue}
        onLogin={onIntroLogin}
        open={showShorterFTUELandingPage}
        productName={Tools.Environment.getProductName()}
        safeAreas={SAFE_AREAS}
      />
      <PoliciesPage
        onContinue={onPoliciesContinue}
        onBack={() => {
          setActiveStep("authintro");
        }}
        openTerms={openTerms}
        openPrivacyPolicy={openPrivacyPolicy}
        open={activeStep === "policies"}
        loading={loading}
        logoImageString={logoImageString}
        privacyEmail={privacyEmail}
        language={language}
        os={os}
        safeAreas={SAFE_AREAS}
        productName={Tools.Environment.getProductName()}
      />
      <SignUpPage
        onContinueEmail={onContinueEmail}
        onContinueGoogle={thirdPartyAuth ? onContinueGoogle : undefined}
        onContinueApple={thirdPartyAuth ? onContinueApple : undefined}
        onBack={() => {
          setActiveStep("policies");
        }}
        open={activeStep === "email"}
        loading={loading}
        logoImageString={logoImageString}
        language={language}
        os={os}
        safeAreas={SAFE_AREAS}
        productName={Tools.Environment.getProductName()}
      />
      <SignUpSecondPage
        open={activeStep === "info"}
        onBack={() => setActiveStep("email")}
        onSubmit={onSubmit}
        loading={loading}
        onPrivacyPolicyClick={() => openPrivacyPolicy(language)}
        onTermsClick={() => openTerms(language)}
        safeAreas={SAFE_AREAS}
        logoImageString={logoImageString}
        productName={Tools.Environment.getProductName()}
      />
      <AgeGateV2
        show={ageGateIsOpen}
        onComplete={onAgeGateComplete}
        onClose={() => setAgeGateIsOpen(false)}
        safeAreas={SAFE_AREAS}
      />
    </Box>
  );
};

export default SignUp;
