import { Box, Button, Grid, Typography } from "@mui/material";
import { Drawer } from "common/Components";
import { WebviewLink } from "@neurosolutionsgroup/components";
import React from "react";
import CoachAssets from "assets/coach";
import { Trans, useTranslation } from "react-i18next";
import useParameters from "common/hooks/Parameters/useParameters";
import useLanguage from "common/hooks/Parameters/useLanguage";

export interface MedicalConsentDrawerProps {
  open: boolean;
  onAccept: () => void;
  onClose: () => void;
}

const MedicalConsentDrawer: React.FC<MedicalConsentDrawerProps> = ({
  open,
  onAccept,
  onClose,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: "settings.child" });
  const { t: generalT } = useTranslation();
  const { openPrivacyPolicy, openTerms } = useParameters();
  const { language } = useLanguage();

  return (
    <Drawer open={open} onClose={onClose}>
      <Grid container direction="column" alignItems="center" p={1}>
        <Grid item mb={1}>
          <Typography variant="h4" textAlign="center">
            {t("medicalConsent.title")}
          </Typography>
        </Grid>
        <Grid
          item
          mt="auto"
          ml={4}
          sx={{
            img: {
              width: "50vw",
              maxWidth: "300px",
            },
          }}
        >
          <img src={CoachAssets.CoachDynamic} alt="" />
        </Grid>
      </Grid>
      <Grid item mb={1}>
        <Typography textAlign="center">
          {t("medicalConsent.text.one")}
        </Typography>
      </Grid>
      <Grid item mb={1}>
        <Typography textAlign="center">
          <Trans
            i18nKey="settings.child.medicalConsent.text.two"
            components={{ bold: <strong /> }}
          />
        </Typography>
      </Grid>
      <Grid item mb={2}>
        <Box display="flex" justifyContent="center">
          <Typography fontSize={"0.8rem"} color="#000">
            <WebviewLink
              color="#000"
              onClick={() => {
                openTerms(language);
              }}
            >
              {generalT("settings.tos")}
            </WebviewLink>
            &nbsp;&&nbsp;
            <WebviewLink
              color="#000"
              onClick={() => {
                openPrivacyPolicy(language);
              }}
            >
              {generalT("settings.privacyPolicy")}
            </WebviewLink>
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box display="flex" justifyContent="center">
          <Button
            data-cy="button-medical-consent"
            onClick={onAccept}
            color="secondary"
            variant="contained"
          >
            {t("medicalConsent.button")}
          </Button>
        </Box>
      </Grid>
    </Drawer>
  );
};
export default MedicalConsentDrawer;
