import { TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import NPSDialog, { NPSDialogProps } from "./NPSDialog";

interface NPSCommentDialogProps
  extends Omit<
    NPSDialogProps,
    "onSubmit" | "skipText" | "submitText" | "children"
  > {
  onSubmit: (comment: string) => void;
}

const NPSCommentDialog: React.FC<NPSCommentDialogProps> = ({
  onSubmit,
  ...props
}) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState("");

  return (
    <NPSDialog
      {...props}
      onSubmit={() => onSubmit(comment)}
      submitText={t("general.actions.submit")}
      skipText={t("general.nothanks")}
    >
      <Typography fontWeight="bold" my={1}>
        {t("ftue.nps.comment.label")}
      </Typography>
      <TextField
        value={comment}
        onChange={(e) => setComment(e.currentTarget.value)}
        multiline
        fullWidth
        minRows={6}
        maxRows={15}
        sx={{
          textarea: {
            fontWeight: "normal",
            fontSize: "0.9rem",
          },
          fieldset: {
            border: "none",
          },
        }}
      />
    </NPSDialog>
  );
};

export default NPSCommentDialog;
