import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useChildren from "common/hooks/children/useChildren";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useMedication from "common/hooks/medications/useMedication";
import { useTranslation } from "react-i18next";
import { GraphTools } from "@neurosolutionsgroup/graphs";
import { medicationLegendFormatter } from "./GraphTools";

interface LegendProps {
  prescriptions: string[];
}

const Legend = ({ prescriptions }: LegendProps): JSX.Element | null => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const { medicationById } = useMedication().selectors;

  const {
    selectors: { prescriptionsByGamerChildId },
  } = useFollowUp();

  const {
    selectors: { selectedChild },
  } = useChildren();

  return selectedChild ? (
    <Box>
      <Typography variant="h5" mb={2}>
        {t("dashboard.med.graph.legend.title")}
      </Typography>
      <Grid container spacing={1} rowSpacing={2}>
        {prescriptions.map((p, i, array) => {
          return (
            <Grid item xs={6} sm={4} key={p}>
              <Box display="flex" alignItems="start">
                <Box mr={0.5} display="flex" alignItems="center">
                  {GraphTools.getGraphIcon(array.length - (i + 1))}
                </Box>
                <Typography
                  fontSize="0.8rem"
                  component="span"
                  sx={{
                    ul: {
                      "paddingInlineStart": "20px",
                      "margin": 0,
                      "& li::marker": {
                        color: GraphTools.getGraphColor(array.length - (i + 1)),
                      },
                    },
                  }}
                >
                  {medicationLegendFormatter(
                    p,
                    prescriptionsByGamerChildId[selectedChild] ?? [],
                    language,
                    t("dashboard.med.graph.legend.noprescription"),
                    medicationById
                  )}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  ) : null;
};

export default Legend;
