import {
  FirestoreCollection,
  NotificationCategorySettings,
  NotificationsSettings,
} from "@neurosolutionsgroup/models";
import NotificationCategorySettingsSetterService, {
  NotificationOptInLastSeenSettingsSetterService,
} from "./NotificationSettingsSetter";
import { getAuth } from "firebase/auth";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { Tools } from "@neurosolutionsgroup/tools";

const updateSettings = async (
  globalOptIn: boolean,
  categories: NotificationCategorySettings
): Promise<NotificationsSettings> => {
  const auth = getAuth();

  const userId = auth.currentUser?.uid;

  if (!userId) {
    return Promise.reject(new Error("User not authenticated."));
  }

  const db = getFirestore();

  const docRef = doc(db, FirestoreCollection.Users, userId);

  await updateDoc(docRef, {
    "masterSettings.settings.notificationSettings.globalOptIn": globalOptIn,
    "masterSettings.settings.notificationSettings.categories": categories,
  });

  return {
    globalOptIn,
    categories,
  };
};

const setOptInLastSeen = async (): Promise<void> => {
  const auth = getAuth();

  const userId = auth.currentUser?.uid;

  if (!userId) {
    return Promise.reject(new Error("User not authenticated."));
  }

  const db = getFirestore();

  const docRef = doc(db, FirestoreCollection.Users, userId);

  const timestamp = Tools.Time.Dates.getTimeStamp();

  await updateDoc(docRef, {
    "masterSettings.settings.notificationSettings.optInLastSeen": timestamp,
  });

  return;
};

const MockSettingsSetterAdapter: NotificationCategorySettingsSetterService &
  NotificationOptInLastSeenSettingsSetterService = {
  updateSettings,
  setOptInLastSeen,
};

export default MockSettingsSetterAdapter;
