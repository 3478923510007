import {
  DeviceData,
  SessionTrackingBody,
  SessionTrackingEvent,
} from "@neurosolutionsgroup/models";
import { AppData, useAnalyticsContext } from "./AnalyticsContext";
import { AnalyticsEvent } from "./Events";
import {
  AnalyticsFunctions,
  OnUserLoginFunction,
  PageViewFunction,
  SetProfilePropertiesFunction,
  TrackEventFunction,
} from "./models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

interface UseAnalyticsResult {
  functions: AnalyticsFunctions;
  handleEvent: (event: AnalyticsEvent) => void;
  handleEvents: (event: AnalyticsEvent[]) => void;
  analyticsInitializationComplete: boolean;
  appData: AppData;
  setAppData: (appData: AppData) => void;
  deviceData: DeviceData;
  setDeviceData: (deviceData: DeviceData) => void;
  language: string;
  setLanguage: (language: string) => void;
  trackSession: (
    sessionEvent: SessionTrackingEvent,
    userId: string,
    eventTime: number,
    childId?: string
  ) => void;
}

const useAnalytics = (): UseAnalyticsResult => {
  const {
    services,
    initializationComplete,
    appData,
    setAppData,
    deviceData,
    setDeviceData,
    language,
    setLanguage,
    setEventQueue,
  } = useAnalyticsContext();

  const onUserLogin: OnUserLoginFunction = (
    distinctId: string,
    language?: string,
    email?: string,
    created?: number
  ) => {
    if (!initializationComplete) {
      throw new Error(
        "[Analytics] onUserLogin called before analytics initialized, check all calls are within AnalyticsProvider."
      );
    }

    services.forEach((service) => {
      try {
        service.functions.onUserLogin(distinctId, language, email, created);
      } catch (err) {
        console.error(
          `[Analytics] Error onUserLogin for service: ${service.name}`,
          err
        );
      }
    });
  };

  const pageView: PageViewFunction = (path) => {
    if (!initializationComplete) {
      throw new Error(
        "[Analytics] pageView called before analytics initialized, check all calls are within AnalyticsProvider."
      );
    }

    services.forEach((service) => {
      service.functions.pageView(path);
    });
  };

  const trackEvent: TrackEventFunction = (
    eventName,
    properties,
    servicesToTrack
  ) => {
    if (!initializationComplete) {
      throw new Error(
        "[Analytics] trackEvent called before analytics initialized, check all calls are within AnalyticsProvider."
      );
    }

    services.forEach((service) => {
      if (
        !servicesToTrack ||
        servicesToTrack.length === 0 ||
        servicesToTrack.includes(service.name)
      ) {
        service.functions.trackEvent(eventName, properties);
      }
    });
  };

  const setProfileProperties: SetProfilePropertiesFunction = (
    properties,
    servicesToSet
  ) => {
    if (!initializationComplete) {
      throw new Error(
        "[Analytics] setProfileProperties called before analytics initialized, check all calls are within AnalyticsProvider."
      );
    }

    services.forEach((service) => {
      if (
        !servicesToSet ||
        servicesToSet.length === 0 ||
        servicesToSet.includes(service.name)
      ) {
        service.functions.setProfileProperties(properties);
      }
    });
  };

  const setOnceProfileProperties: SetProfilePropertiesFunction = (
    properties
  ) => {
    if (!initializationComplete) {
      throw new Error(
        "[Analytics] setProfileProperties called before analytics initialized, check all calls are within AnalyticsProvider."
      );
    }

    services.forEach((service) => {
      service.functions.setOnceProfileProperties(properties);
    });
  };

  const handleEvent = (event: AnalyticsEvent): void => {
    setEventQueue((current) => [...current, event]);
  };

  const handleEvents = (events: AnalyticsEvent[]): void => {
    setEventQueue((current) => [...current, ...events]);
  };

  const trackSession = async (
    sessionEvent: SessionTrackingEvent,
    userId: string,
    eventTime: number,
    childId?: string
  ): Promise<void> => {
    const args: SessionTrackingBody = {
      sessionEvent,
      userId,
      childId,
      deviceId: deviceData.deviceId,
      eventTime,
      deviceUtcOffsetMin: -new Date().getTimezoneOffset(),
    };

    try {
      await FirebaseAPI.Session.postSessionEvent(args);
    } catch (err) {
      console.error("Failed to track session event.", err);
    }
  };

  return {
    functions: {
      onUserLogin,
      pageView,
      trackEvent,
      setProfileProperties,
      setOnceProfileProperties,
    },
    handleEvent,
    handleEvents,
    analyticsInitializationComplete: initializationComplete,
    appData,
    setAppData,
    deviceData,
    setDeviceData,
    language,
    setLanguage,
    trackSession,
  };
};

export default useAnalytics;
