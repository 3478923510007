import { Box, Typography } from "@mui/material";
import {
  CoachMessageBanner,
  EmptyStateAddButton,
} from "@neurosolutionsgroup/components";
import { FirestoreChallenge } from "@neurosolutionsgroup/models";
import useChallenges from "common/hooks/challenges/useChallenges";
import useChildren from "common/hooks/children/useChildren";
import React, { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ActiveChallenges from "./Challenge/ActiveChallenges";
import ChallengeHistory from "./Challenge/ChallengeHistory";
import ChallengesForApproval from "./Challenge/ChallengesForApproval";
import CoachAssets from "assets/coach";

export interface ChallengeSectionProps {
  onChallengeCreate: () => void;
  onChallengeDelete: (c: FirestoreChallenge) => void;
  selectedChild: string | null;
}

const ChallengeSection: React.FC<ChallengeSectionProps> = ({
  onChallengeCreate,
  onChallengeDelete,
  selectedChild,
}) => {
  const { t } = useTranslation();

  const {
    selectors: {
      challengeFeatureFlagActive,
      challenges,
      challengesForApproval,
      challengeLimitMet,
    },
  } = useChallenges();
  const {
    selectors: { childrenById },
  } = useChildren();

  const [historyEmpty, setHistoryEmpty] = useState(true);

  const filteredChallenges = useMemo(() => {
    if (selectedChild) {
      return challenges.filter((c) => c.childId === selectedChild);
    } else if (childrenById) {
      return challenges.filter((c) =>
        Object.keys(childrenById).includes(c.childId)
      );
    }
    return [];
  }, [challenges, childrenById, selectedChild]);

  const filteredChallengesForApproval = useMemo(() => {
    if (selectedChild) {
      return challengesForApproval.filter((c) => c.childId === selectedChild);
    } else {
      return challengesForApproval;
    }
  }, [challengesForApproval, selectedChild]);

  const shouldShowEmptyState = useMemo(() => {
    return (
      filteredChallengesForApproval.length === 0 &&
      filteredChallenges.length === 0 &&
      historyEmpty
    );
  }, [filteredChallengesForApproval, filteredChallenges, historyEmpty]);

  return (
    <Box pb="60px">
      <Typography variant="h2" my={2}>
        {t("routine.challenge.title")}
      </Typography>

      <Box>
        <EmptyStateAddButton
          text={t("routine.challenge.add")}
          onClick={onChallengeCreate}
          disabled={challengeLimitMet}
          data-cy="create-routine-button"
        />
        {challengeLimitMet ? (
          <Typography ml={2} mt={1} fontSize="0.85rem" fontWeight={600}>
            {t("routine.challenge.limit")}
          </Typography>
        ) : null}
      </Box>

      {shouldShowEmptyState ? (
        <CoachMessageBanner
          coachImgSrc={CoachAssets.CoachChallenge}
          coachImagePosition="right"
          bannerContent={<Trans i18nKey="routine.challenge.empty" />}
          sx={{ marginTop: 2, marginX: "-1rem" }}
        />
      ) : null}

      {filteredChallengesForApproval.length > 0 ? (
        <ChallengesForApproval
          filteredChallengesForApproval={filteredChallengesForApproval}
          onChallengeDelete={onChallengeDelete}
        />
      ) : null}
      {challengeFeatureFlagActive && filteredChallenges.length > 0 ? (
        <ActiveChallenges
          activeChallenges={filteredChallenges}
          onChallengeDelete={onChallengeDelete}
        />
      ) : null}
      <ChallengeHistory
        selectedChild={selectedChild}
        setHistoryEmpty={setHistoryEmpty}
      />
    </Box>
  );
};
export default ChallengeSection;
