import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import { PremiumLock } from "@neurosolutionsgroup/components";
import { CypressProps } from "@neurosolutionsgroup/models";

interface TemplateChoiceProps extends CypressProps {
  text: string;
  imageSrc: string;
  onClick: VoidFunction;
  active: boolean;
  disabled?: boolean;
  onPremiumLockClick: VoidFunction;
  premiumLock?: boolean;
}

const TemplateChoice: React.FC<TemplateChoiceProps> = ({
  text,
  imageSrc,
  onClick,
  active,
  disabled = false,
  onPremiumLockClick,
  premiumLock = false,
  ...props
}) => {
  return (
    <Grid item xs={12}>
      <PremiumLock
        active={premiumLock}
        onClick={onPremiumLockClick}
        variant="long"
        display="block"
        borderRadius="10px"
      >
        <ButtonBase
          data-cy={props["data-cy"]}
          sx={(theme) => ({
            width: "100%",
            height: "12.5vh",
            backgroundColor: disabled ? "#bbb" : "#fff",
            borderRadius: "10px",
            borderWidth: "3px",
            borderStyle: "solid",
            borderColor: active
              ? theme.palette.secondary.main
              : disabled
              ? "#bbb"
              : "#fff",
          })}
          onClick={onClick}
          disabled={disabled}
        >
          <Box
            width="100%"
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              img: {
                maxHeight: "10vh",
                opacity: disabled ? 0.5 : 1,
              },
            }}
          >
            <img src={imageSrc} alt={text} />
            <Typography fontWeight="bold" textAlign="right">
              {text}
            </Typography>
          </Box>
        </ButtonBase>
      </PremiumLock>
    </Grid>
  );
};

export default TemplateChoice;
