export enum ConfigString {
  AppLink = "config_link_app",
  ForgottenPasswordLink = "config_link_forgotten_password",
  PrivacyDE = "config_link_privacy_de",
  PrivacyEN = "config_link_privacy_en",
  PrivacyFR = "config_link_privacy_fr",
  SupportLink = "config_link_support",
  SupportLinkDE = "config_link_support_de",
  TermsDE = "config_link_terms_de",
  TermsEN = "config_link_terms_en",
  TermsFR = "config_link_terms_fr",
  WebAppSettings = "config_link_webapp_settings",
  ThirdPartyAuth = "config_third_party_auth",
}
