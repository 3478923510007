import { HookResult } from "common/hooks/HookResult";
import {
  APP_USED_COUNT_KEY,
  CompleteAccountInfo,
  FirestoreCollection,
  Language,
  MasterSettings,
  NotificationSettings,
} from "@neurosolutionsgroup/models";
import { useProfileContext } from "./UserProfileContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import useAuth from "../auth/useAuth";
import useLanguage from "../Parameters/useLanguage";
import { Tools } from "@neurosolutionsgroup/tools";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import useParameters from "../Parameters/useParameters";

export enum SubscriptionNotifications {
  ThreeDays = "SubscriptionReminderThreeDays",
  SevenDays = "SubscriptionReminderSevenDays",
  ThirteenDays = "SubscriptionReminderThirteenDays",
}

interface UseUserProfileSelectors {
  loading: boolean;
  userProfile: CompleteAccountInfo | undefined;
}

interface UseUserProfileActions {
  deleteUserInfo: () => Promise<void>;
  incrementAppVisits: () => Promise<void>;
  onUserRegistration: (version?: string) => Promise<void>;
  registerUser: (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ) => Promise<void>;
  sendConfirmationEmail: () => Promise<boolean>;
  updateLanguage: (language: Language) => Promise<void>;
  updateSoundSetting: (sounds: boolean) => Promise<void>;
  updateMasterSettingsKey: (
    key: string,
    value: string | boolean | number | NotificationSettings
  ) => Promise<void>;
}

const useUserProfile = (): HookResult<
  UseUserProfileSelectors,
  UseUserProfileActions
> => {
  const { checkFeatureFlag } = useRemoteConfig();
  const { loading, setLoading, userProfile, setUserProfile } =
    useProfileContext();
  const { language } = useLanguage();
  const { version } = useParameters();

  const { user, logOut } = useAuth();

  const registerUser = async (
    email: string,
    password: string,
    firstName?: string,
    lastName?: string
  ): Promise<void> => {
    const createDefaultChild = checkFeatureFlag(
      WebviewsFeatureFlag.DefaultChildCreation,
      version
    );

    await FirebaseAPI.Account.registerUser({
      email,
      password,
      firstName,
      lastName,
      language,
      createDefaultChild,
    });

    return;
  };

  const onUserRegistration = async (version?: string) => {
    if (version) {
      updateMasterSettingsKey("creationVersion", version);
    }
  };

  const updateMasterSettingsKey = async (
    key: string,
    value: string | boolean | number | NotificationSettings
  ): Promise<void> => {
    if (userProfile && user) {
      const masterSettings: MasterSettings = userProfile?.masterSettings ?? {
        settings: {},
        version: 0,
      };

      const previousValue = masterSettings.settings[key];

      masterSettings.settings[key] = value;

      masterSettings.version = 0;

      // Optimistic state update.
      setUserProfile((current) =>
        current && current.masterSettings
          ? {
              ...current,
              masterSettings: {
                ...current.masterSettings,
                settings: {
                  ...current.masterSettings?.settings,
                  [key]: value,
                },
                version: 0,
              },
            }
          : current
      );

      try {
        const db = getFirestore();

        const updateData = {
          [`masterSettings.settings.${key}`]: value,
        };

        await updateDoc(
          doc(db, FirestoreCollection.Users, user.uid),
          updateData
        );
      } catch (err) {
        // Revert optimism.
        setUserProfile((current) =>
          current && current.masterSettings
            ? {
                ...current,
                masterSettings: {
                  ...current.masterSettings,
                  settings: {
                    ...current.masterSettings?.settings,
                    [key]: previousValue,
                  },
                },
              }
            : current
        );

        return Promise.reject(err);
      }
    }
  };

  const incrementAppVisits = async (): Promise<void> => {
    if (userProfile) {
      const masterSettings: MasterSettings = userProfile?.masterSettings ?? {
        settings: {},
        version: 0,
      };

      let appUsedCount = masterSettings.settings[APP_USED_COUNT_KEY];

      if (appUsedCount && typeof appUsedCount === "number") {
        appUsedCount++;
      } else {
        appUsedCount = 1;
      }

      masterSettings.settings[APP_USED_COUNT_KEY] = appUsedCount;

      await FirebaseAPI.Account.putMasterSettings(masterSettings);
    }
  };

  const sendConfirmationEmail = async (): Promise<boolean> => {
    //return API.Account.Security.sendConfirmationEmail().then(() => true);
    console.log("UserProfileActions.sendConfirmationEmail");
    return false;
  };

  const updateLanguage = async (language: Language): Promise<void> => {
    setLoading(true);

    try {
      const masterSettings: MasterSettings = userProfile?.masterSettings ?? {
        settings: {},
        version: 0,
      };

      masterSettings.settings.language =
        Tools.Language.languageToSettingsLanguage(language);

      const result = await FirebaseAPI.Account.putMasterSettings(
        masterSettings
      );

      setUserProfile((current) =>
        current ? { ...current, masterSettings: result } : current
      );
    } finally {
      setLoading(false);
    }
  };

  const updateSoundSetting = async (sounds: boolean): Promise<void> => {
    setLoading(true);

    try {
      const masterSettings: MasterSettings = userProfile?.masterSettings ?? {
        settings: {},
        version: 0,
      };

      masterSettings.settings.sounds = sounds;

      const result = await FirebaseAPI.Account.putMasterSettings(
        masterSettings
      );

      setUserProfile((current) =>
        current ? { ...current, masterSettings: result } : current
      );
    } finally {
      setLoading(false);
    }
  };

  const deleteUserInfo = async (): Promise<void> => {
    setLoading(true);

    try {
      await FirebaseAPI.Account.deleteUserInfo();
    } finally {
      await logOut();

      setLoading(false);
    }
  };

  return {
    selectors: {
      loading,
      userProfile,
    },
    actions: {
      deleteUserInfo,
      incrementAppVisits,
      onUserRegistration,
      registerUser,
      sendConfirmationEmail,
      updateLanguage,
      updateSoundSetting,
      updateMasterSettingsKey,
    },
  };
};

export default useUserProfile;
