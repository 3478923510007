import { StaticDatePicker } from "@mui/x-date-pickers";
import WeekPickerDay from "./WeekPickerDay";

interface StaticWeekPickerProps {
  date: Date | null;
  onDatePickerChange: (date: Date | null, close?: boolean) => void;
  taggedDates?: Date[];
  disableFuture?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

const StaticWeekPicker = ({
  date,
  onDatePickerChange,
  taggedDates,
  disableFuture,
  minDate,
  maxDate,
}: StaticWeekPickerProps): JSX.Element => {
  return (
    <StaticDatePicker
      value={date}
      onChange={(value) => onDatePickerChange(value, false)}
      onAccept={(value) => onDatePickerChange(value, true)}
      slots={{
        day: (props) => (
          <WeekPickerDay
            selectedDate={date}
            taggedDates={taggedDates}
            {...props}
          />
        ),
      }}
      displayStaticWrapperAs="desktop"
      views={["year", "month", "day"]}
      disableFuture={disableFuture}
      minDate={minDate}
      maxDate={maxDate}
    />
  );
};

export default StaticWeekPicker;
