import { Box, CircularProgress, Divider, Fab, Typography } from "@mui/material";
import {
  DrawerFilter,
  FilterCategory,
  Icons,
  NoteCard,
  WeekNotesDisplay,
  WeekPicker,
} from "@neurosolutionsgroup/components";
import { needsLoc, taskCategoryLoc } from "@neurosolutionsgroup/localization";
import { GamerChild, Note } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import { EmptyStateButton } from "@neurosolutionsgroup/webviews-pages";
import DynamismAssets from "assets/dynamism";
import { Page } from "common/Components";
import useChildren from "common/hooks/children/useChildren";
import { NotesProvider } from "common/hooks/notes/NoteContext";
import useNotes from "common/hooks/notes/useNotes";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREA_BOTTOM, SAFE_AREAS } from "stylesheets";
import NoteCreation from "./NoteCreation";
import NoteEdition from "./NoteEdition";

const JournalComponent = (): JSX.Element | null => {
  const { language, dateLocale } = useLanguage();
  const { t } = useTranslation();
  const {
    actions: { onJournalAddClicked },
  } = useFTUE();

  const {
    selectors: { selectedChild, childrenById },
  } = useChildren();
  const {
    currentDate,
    loading,
    noteDates,
    noteDateCounts,
    notesForWeek,
    setCurrentDate,
  } = useNotes();
  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const [filters, setFilters] = useState<string[]>([]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [openAddNoteForm, setOpenAddNoteForm] = useState(false);
  const [openEditNoteForm, setOpenEditNoteForm] = useState(false);

  const gamerChild: GamerChild | undefined = selectedChild
    ? childrenById[selectedChild]
    : undefined;

  const showNotesEmptyState = !Object.values(notesForWeek).some(
    (notes) => notes.length > 0
  );

  const filterOptions: FilterCategory<string>[] = useMemo(() => {
    const sideEffectIds: string[] = [];
    const skillsIds: string[] = [];
    const taskCategoryIds: string[] = [];

    const sideEffectCounts: { [id: string]: number } = {};
    const skillsCounts: { [id: string]: number } = {};
    const taskCategoryCounts: { [id: string]: number } = {};

    Object.values(notesForWeek).forEach((notes) => {
      notes.forEach((note) => {
        if (sideEffectById) {
          const sideEffectId = note.sideEffectId;
          const skillId = note.needId;
          const routineCategoryId = note.routineCategoryId;

          if (sideEffectId) {
            if (!sideEffectIds.includes(sideEffectId)) {
              sideEffectIds.push(sideEffectId);
            }

            sideEffectCounts[sideEffectId] = sideEffectCounts[sideEffectId]
              ? sideEffectCounts[sideEffectId] + 1
              : 1;
          } else if (skillId) {
            if (!skillsIds.includes(skillId)) {
              skillsIds.push(skillId);
            }
            skillsCounts[skillId] = skillsCounts[skillId]
              ? skillsCounts[skillId] + 1
              : 1;
          } else if (routineCategoryId) {
            if (!taskCategoryIds.includes(routineCategoryId)) {
              taskCategoryIds.push(routineCategoryId);
            }
            taskCategoryCounts[routineCategoryId] = taskCategoryCounts[
              routineCategoryId
            ]
              ? taskCategoryCounts[routineCategoryId] + 1
              : 1;
          }
        }
      });
    });

    const taskCategoryFilters: FilterCategory<string>[] = [];

    if (taskCategoryIds.length > 0) {
      taskCategoryFilters.push({
        label: t("journal.filters.taskCategories"),
        options: taskCategoryIds
          .sort((a, b) =>
            taskCategoryLoc[language][a].localeCompare(
              taskCategoryLoc[language][b]
            )
          )
          .map((id) => {
            return {
              id,
              label: taskCategoryLoc[language][id],
              count: taskCategoryCounts[id],
            };
          }),
      });
    }

    const sideEffectFilters: FilterCategory<string>[] = [];

    if (sideEffectIds.length > 0 && sideEffectById) {
      sideEffectFilters.push({
        label: t("journal.filters.symptoms"),
        options: sideEffectIds
          .sort((a, b) =>
            (sideEffectById[a]?.name[language] ?? a).localeCompare(
              sideEffectById[b]?.name[language] ?? b
            )
          )
          .map((sid) => ({
            id: sid,
            label: sideEffectById[sid]?.name[language],
            count: sideEffectCounts[sid] ?? 0,
          })),
      });
    }

    const skillFilters: FilterCategory<string>[] = [];

    if (skillsIds.length > 0) {
      skillFilters.push({
        label: t("dashboard.skills.nav"),
        options: Tools.Data.Skills.sortAlphabetically(
          skillsIds,
          true,
          language
        ).map((s) => ({
          id: s,
          label: needsLoc[language].needs[s],
          count: skillsCounts[s] ?? 0,
        })),
      });
    }

    return [...sideEffectFilters, ...skillFilters, ...taskCategoryFilters];
  }, [language, notesForWeek]);

  useEffect(() => {
    // Remove filter from values if it is no longer in options.
    filters.forEach((filter) => {
      const optionExists = filterOptions.some((category) =>
        category.options.some((option) => option.id === filter)
      );

      if (!optionExists) {
        setFilters((current) => {
          const clone = [...current];

          return clone.filter((value) => value !== filter);
        });
      }
    });
  }, [filterOptions, filters]);

  const noteFilter = (note: Note): boolean => {
    if (filters.length === 0) {
      return true;
    }

    if (note.routineCategoryId && filters.includes(note.routineCategoryId)) {
      return !(note.sideEffectId || note.needId);
    }

    if (note.sideEffectId && filters.includes(note.sideEffectId)) {
      return true;
    }

    if (note.needId && filters.includes(note.needId)) {
      return true;
    }

    return false;
  };

  const noteSort = (a: Note, b: Note): number => {
    if (a.eventTime) {
      if (b.eventTime) {
        return a.eventTime - b.eventTime;
      } else {
        return 1;
      }
    } else {
      if (b.eventTime) {
        return -1;
      } else {
        return a.title.localeCompare(b.title);
      }
    }
  };

  const onAddNote = () => {
    onJournalAddClicked();
    setOpenAddNoteForm(true);
  };

  const onNoteClick = (note: Note) => {
    setSelectedNote(note);
    setOpenEditNoteForm(true);
  };

  return gamerChild ? (
    <Page
      sxBody={{
        paddingBottom: "62px",
      }}
    >
      <FTUEFlowDefinitions.JournalFTUEFlow.Screen />
      <Box display="flex" justifyContent="flex-end">
        <WeekPicker
          date={currentDate}
          onChange={setCurrentDate}
          language={language}
          locale={dateLocale}
          taggedDates={noteDates}
        />
      </Box>
      <Box mt={2}>
        <WeekNotesDisplay
          weekEndDate={currentDate}
          dateTags={noteDateCounts}
          dateLocale={dateLocale}
        />
      </Box>
      <Divider
        sx={{
          marginY: 1,
        }}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" minHeight="1.5rem">
          <Typography variant="h4">{t("journal.notes.title")} </Typography>
          {loading ? (
            <CircularProgress
              size="1.5rem"
              color="secondary"
              sx={{ marginLeft: 1 }}
            />
          ) : null}
        </Box>

        <DrawerFilter
          options={filterOptions}
          value={filters}
          onChange={setFilters}
          disabled={filterOptions.length === 0}
          safeAreas={SAFE_AREAS}
        />
      </Box>

      <Box mt={2}>
        {Object.entries(notesForWeek).map(([date, notes]) => {
          const filteredNotes = notes.filter(noteFilter);

          return filteredNotes.length > 0 ? (
            <Box key={date} mt={1} mb={5}>
              <Typography>
                {format(
                  Tools.Time.Dates.parseDateStringToJsDate(date),
                  "d MMMM yyyy",
                  {
                    locale: dateLocale,
                  }
                )}
              </Typography>
              {filteredNotes.sort(noteSort).map((note) => (
                <NoteCard
                  key={note.noteId}
                  language={language}
                  note={note}
                  onClick={() => onNoteClick(note)}
                  sx={{
                    marginTop: 1,
                  }}
                  taskCategoryLoc={taskCategoryLoc}
                  sideEffectsById={sideEffectById}
                  skillLoc={needsLoc}
                />
              ))}
            </Box>
          ) : null;
        })}
      </Box>

      {loading ? null : showNotesEmptyState ? (
        <EmptyStateButton
          imgSrc={DynamismAssets.Journal}
          onClick={onAddNote}
          text={t("ftue.journal.addNote.text")}
          imgSize="95vw"
          data-cy="journal-empty-state-add"
        />
      ) : (
        <Fab
          onClick={onAddNote}
          color="primary"
          sx={{
            position: "absolute",
            bottom: `calc(${SAFE_AREA_BOTTOM} + 138px)`,
            right: "12px",
            svg: {
              width: "30px",
              height: "30px",
            },
          }}
          data-cy="add-note"
        >
          <Icons.AddIcon color={"#fff"} />
        </Fab>
      )}

      <NoteCreation
        open={openAddNoteForm}
        onClose={() => setOpenAddNoteForm(false)}
        gamerChild={gamerChild}
      />
      <NoteEdition
        open={openEditNoteForm}
        onClose={() => {
          setOpenEditNoteForm(false);
          setSelectedNote(null);
        }}
        note={selectedNote}
        gamerChild={gamerChild}
      />
    </Page>
  ) : null;
};

const Journal = (): JSX.Element => {
  return (
    <NotesProvider>
      <JournalComponent />
    </NotesProvider>
  );
};

export default Journal;
