import Challenges from "./Challenges";
import Medical from "./Medical";
import Skills from "./Skills";
import Notes from "./Notes";

const Data = {
  Challenges,
  Medical,
  Skills,
  Notes,
};

export default Data;
