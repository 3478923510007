import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CurrentChallengeCardSummaryEmptyStateProps {
  challengeIconImgSrc: string;
}

const CurrentChallengeCardSummaryEmptyState = ({
  challengeIconImgSrc,
}: CurrentChallengeCardSummaryEmptyStateProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        img: {
          width: "15vw",
          maxWidth: "55px",
          marginRight: 1,
        },
      }}
    >
      <img src={challengeIconImgSrc} alt="" />

      <Box>
        <Typography color="#629d9b" fontWeight={700} fontSize="0.95rem">
          {t("dashboard.skills.currentChallenge.empty.title")}
        </Typography>

        <Typography fontSize="0.85rem">
          {t("dashboard.skills.currentChallenge.empty.text")}
        </Typography>
      </Box>
    </Box>
  );
};

export default CurrentChallengeCardSummaryEmptyState;
