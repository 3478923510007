import { Tools } from "@neurosolutionsgroup/tools";
import { AnalyticService, PageViewFunction } from "../../models";
import { AnalyticsEvent } from "../../Events";

export class ConsoleTrackerDebugger {
  static initialised = false;
  static events: AnalyticsEvent[] = [];
  static properties: Record<string, unknown> = {};
  static userId: string | null = null;
  static pageViews: string[] = [];

  static init = () => {
    this.initialised = true;
  };

  static addEvent = (event: AnalyticsEvent) => {
    ConsoleTrackerDebugger.events.push(event);
  };

  static addUserProperties = (properties: Record<string, unknown>) => {
    ConsoleTrackerDebugger.properties = {
      ...ConsoleTrackerDebugger.properties,
      ...properties,
    };
  };

  static authUser = (id: string, properties: Record<string, unknown>) => {
    ConsoleTrackerDebugger.userId = id;

    ConsoleTrackerDebugger.addUserProperties(properties);
  };

  static pageView = (path: string) => {
    ConsoleTrackerDebugger.events.push({
      name: "Page View - " + path,
    });
  };
}

const init = (): void => {
  console.info("[Analytics] Initialised.");

  ConsoleTrackerDebugger.init();
};

const onUserLogin = (distinctId: string, language: string): void => {
  console.info(`[Analytics] User logged in ${distinctId}.`, {
    language,
    timezone: Tools.Time.Dates.getTimezone(),
  });

  ConsoleTrackerDebugger.authUser(distinctId, {
    language,
    timezone: Tools.Time.Dates.getTimezone(),
  });
};

const pageView: PageViewFunction = (path) => {
  console.info(`[Analytics] Event: Page View "${path}"`);

  ConsoleTrackerDebugger.pageView(path);
};

const trackEvent = (
  eventName: string,
  properties?: { [key: string]: unknown }
): void => {
  console.info(`[Analytics] Event: "${eventName}" tracked.`, properties);

  ConsoleTrackerDebugger.addEvent({
    name: eventName,
    eventProperties: properties,
  });
};

const setProfileProperties = (properties: { [key: string]: unknown }): void => {
  console.info(`[Analytics] Profile updated.`, properties);

  ConsoleTrackerDebugger.addUserProperties(properties);
};

const setOnceProfileProperties = (properties: {
  [key: string]: unknown;
}): void => {
  console.info(`[Analytics] Profile updated (set once).`, properties);

  ConsoleTrackerDebugger.addUserProperties(properties);
};

const functions = {
  onUserLogin,
  pageView,
  trackEvent,
  setProfileProperties,
  setOnceProfileProperties,
};

export const ConsoleService: AnalyticService = {
  name: "Console",
  init,
  functions,
};
