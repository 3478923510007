import React, { ReactNode } from "react";

export interface LoaderProps {
  /** Loader object to show */
  loader?: ReactNode;
  dark?: boolean;
}

/**
 * A simple component that shows a loader icon passed in props, or a default loader.
 */
const Loader = ({ loader, dark = false }: LoaderProps): JSX.Element => {
  return (
    <div className="loader">
      <div
        className={
          "loader__background" + (dark ? " loader__background--dark" : "")
        }
      ></div>
      <div className="loader__wrap">
        {loader ?? (
          <div className="loader__clock">
            <svg
              className="loader__clock-face"
              viewBox="0 0 6.3499999 6.3500002"
              version="1.1"
              id="svg5006"
            >
              <defs id="defs5000" />
              <g id="layer1" transform="translate(0,-290.64998)">
                <g
                  id="g372"
                  transform="matrix(0.04760379,0,0,-0.04760379,3.1026184,294.13688)"
                >
                  <path
                    d="m 27.368,51.102 -44.03,4.902 -31.284,-31.367 5.018,-44.017 37.542,-23.52 41.797,14.687 14.576,41.835 z"
                    style={{
                      fill: "#3b6466",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    id="path374"
                  />
                </g>
              </g>
            </svg>
            <svg
              className="loader__clock-hands"
              viewBox="0 0 6.3499999 6.3500002"
              version="1.1"
              id="svg5006"
            >
              <defs id="defs5000" />
              <g id="layer1" transform="translate(0,-290.64998)">
                <g
                  id="g372"
                  transform="matrix(0.04760379,0,0,-0.04760379,3.1026184,294.13688)"
                >
                  <path
                    d="M 1.7545183,-4.2108441 -28.944,42.458 7.984,11.186 28.723,10.031 Z"
                    style={{
                      fill: "#ffffff",
                      fillOpacity: 1,
                      fillRule: "nonzero",
                      stroke: "none",
                    }}
                    id="path374"
                  />
                </g>
              </g>
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default Loader;
