import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";
import FullscreenPage from "./FullScreenPage";
import { BackgroundHexagons, HexagonPlacement } from "../BackgroundHexagons";
import PageContentContainer from "./PageContentContainer";
import { SafeAreas } from "@neurosolutionsgroup/models";

interface PageWithHeaderProps extends PropsWithChildren {
  headerContent: JSX.Element;
  color?: string;
  safeAreas?: SafeAreas;
  withHexagons?: boolean;
  sx?: SxProps<Theme>;
}

const PageWithHeader = ({
  headerContent,
  color = "secondary.main",
  safeAreas,
  sx,
  withHexagons = true,
  ...props
}: PageWithHeaderProps): JSX.Element => {
  const hexagons: HexagonPlacement[] = [
    { size: 70, rotation: 15, top: 50, left: -30 },
    { size: 20, rotation: 15, top: -5, left: "40%" },
    { size: 20, rotation: 15, top: 50, left: "55%" },
    { size: 70, rotation: 15, top: -30, left: "70%" },
    { size: 100, rotation: 15, top: 40, right: -50 },
  ];

  return (
    <FullscreenPage
      sx={[
        {
          backgroundColor: color,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {withHexagons ? <BackgroundHexagons hexagons={hexagons} /> : null}
      <PageContentContainer safeAreas={safeAreas}>
        <Box
          height="65px"
          minHeight="65px"
          display="flex"
          alignItems="center"
          ml={2}
        >
          {headerContent}
        </Box>
        <Box
          sx={[
            {
              flexGrow: 1,
              backgroundColor: "background.default",
              borderRadius: "20px",
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {props.children}
        </Box>
      </PageContentContainer>
    </FullscreenPage>
  );
};

export default PageWithHeader;
