import DashboardFilters from "./DashboardFilters";
import DashboardRoutines from "./DashboardRoutines";
import DashboardRoutinesGraph from "./DashboardRoutinesGraph";
import DashboardTimePeriod from "./DashboardTimePeriod";

const DashboardFTUEHints = {
  DashboardFilters,
  DashboardRoutines,
  DashboardRoutinesGraph,
  DashboardTimePeriod,
};

export default DashboardFTUEHints;
