import { Box, ButtonBase, Typography } from "@mui/material";
import React, { PropsWithChildren, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { ValidationProgress } from "..";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import useFTUE from "useFTUE";
import { ArrowIcon } from "components";

const ValidationNav: React.FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    selectors: { progress },
    actions: { canDisplayFlow, setValidationProgress },
  } = useFTUE();

  const validationProgress = progress[FTUEFlow.Validation].progress;

  useEffect(() => {
    if (validationProgress === ValidationProgress.Later) {
      navigate("/dashboard");
    }
  }, [validationProgress]);

  useEffect(() => {
    if (
      location.pathname === "/follow-ups" &&
      validationProgress === ValidationProgress.Later
    ) {
      onNext();
    }
  }, [location]);

  const onNext = () => {
    setValidationProgress(ValidationProgress.Intro);
  };

  return (
    <FTUEHint
      condition={
        validationProgress === ValidationProgress.Later &&
        canDisplayFlow(FTUEFlow.Validation)
      }
    >
      {validationProgress === ValidationProgress.Later &&
      canDisplayFlow(FTUEFlow.Validation) ? (
        <>
          <ButtonBase
            sx={{
              position: "fixed",
              top: 0,
              right: 0,
              margin: "0.5em",
              padding: "0.5em",
            }}
            onClick={onNext}
          >
            <Typography color="#fff">{t("general.actions.close")}</Typography>
          </ButtonBase>
          <Box
            sx={{
              position: "fixed",
              top: "40vh",
              left: 0,
              right: 0,
            }}
          >
            <Typography color="#fff" m={1} textAlign="center" fontWeight="bold">
              {t("ftue.validation.later.text")}
            </Typography>
          </Box>
          <Box
            sx={{
              position: "fixed",
              bottom: "6em",
              left: "20vw",
              width: "20vw",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography fontWeight="bold" color="#fff" mb={1}>
              {t("ftue.validation.later.here")}
            </Typography>
            <ArrowIcon />
          </Box>
          {props.children}
        </>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};

export default ValidationNav;
