import { useCallback } from "react";
import EndScreen from "./Screens/End";
import TemplateScreen from "./Screens/TemplateScreen";
import { FTUEFlow } from "models";
import { FirstRoutineProgress } from "../FirstRoutineProgress";
import useFTUE from "useFTUE";
import { Language, SafeAreas, Template } from "@neurosolutionsgroup/models";
import FirstRoutineScreenComponent from "./FirstRoutineScreenComponent";

export interface FTUEFirstRoutineScreenProps {
  introImageSrc: string;
  safeAreas: SafeAreas;
  language: Language;
  lockChallenge: boolean;
  lockedRoutineTemplates: Template[];
  onTemplateChoice: (template: Template | undefined) => void;
  onFtueEnd: VoidFunction;
  onPremiumLockChallengeClick?: () => void;
  onPremiumLockTemplateClick?: (template: Template) => void;
}

const FTUEFirstRoutineScreen = ({
  introImageSrc,
  lockChallenge,
  lockedRoutineTemplates,
  onTemplateChoice,
  onFtueEnd,
  onPremiumLockChallengeClick,
  onPremiumLockTemplateClick,
  safeAreas,
  language,
}: FTUEFirstRoutineScreenProps): JSX.Element | null => {
  const {
    selectors: { assets, progress },
    actions: { canDisplayFlow, setFirstRoutineProgress },
  } = useFTUE();

  const firstRoutineProgress = progress[FTUEFlow.FirstRoutine].progress;

  const showFullScreen = () => {
    const fullScreenSteps = [
      FirstRoutineProgress.Intro,
      FirstRoutineProgress.RoutineDisplay,
    ];

    return fullScreenSteps.includes(firstRoutineProgress);
  };

  const onTemplateBack = () => {
    setFirstRoutineProgress(FirstRoutineProgress.None);
  };

  const onTemplateChoiceConfirm = (template?: Template) => {
    setFirstRoutineProgress(
      FirstRoutineProgress.TemplateChosen,
      template?.toFixed()
    );
    onTemplateChoice(template);
  };

  const renderFTUESection = useCallback(() => {
    switch (firstRoutineProgress) {
      case FirstRoutineProgress.Intro:
        return (
          <TemplateScreen
            assets={assets}
            language={language}
            lockedChallenge={lockChallenge}
            lockedTemplates={lockedRoutineTemplates}
            onBack={onTemplateBack}
            onPremiumLockChallengeClick={() =>
              onPremiumLockChallengeClick && onPremiumLockChallengeClick()
            }
            onPremiumLockTemplateClick={(template: Template) =>
              onPremiumLockTemplateClick && onPremiumLockTemplateClick(template)
            }
            onTemplateChoice={onTemplateChoiceConfirm}
            safeAreas={safeAreas}
          />
        );
      case FirstRoutineProgress.RoutineDisplay:
        return <EndScreen onFtueEnd={onFtueEnd} />;
      default:
        return null;
    }
  }, [firstRoutineProgress]);

  return (
    <>
      {canDisplayFlow(FTUEFlow.FirstRoutine) && showFullScreen() ? (
        <FirstRoutineScreenComponent safeAreas={safeAreas}>
          {renderFTUESection()}
        </FirstRoutineScreenComponent>
      ) : null}
    </>
  );
};

export default FTUEFirstRoutineScreen;
