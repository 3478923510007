import { SafeAreas } from "@neurosolutionsgroup/models";
import AuthPageContainer from "./components/AuthPageContainer";
import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import AuthTextField from "./components/AuthTextField";
import { useEffect, useMemo, useRef, useState } from "react";
import ShowPasswordAdornment from "./components/ShowPasswordAdornment";
import { WebviewLink } from "@neurosolutionsgroup/components";
import GoogleButton from "./components/GoogleButton";
import AppleButton from "./components/AppleButton";
import TextDivider from "./components/TextDivider";

interface LoginPageProps {
  allowThirdPartyAuth: boolean;
  authError: boolean;
  clearError: VoidFunction;
  loading: boolean;
  logoImageString: string;
  onContinueApple: VoidFunction;
  onContinueGoogle: VoidFunction;
  onForgottenPasswordLinkClick: VoidFunction;
  onSignUpClick: VoidFunction;
  onSubmit: (email: string, password: string) => void;
  open: boolean;
  os: "android" | "ios";
  productName: string;
  safeAreas: SafeAreas;
  initEmail?: string;
}

const LoginPage = ({
  allowThirdPartyAuth,
  authError,
  clearError,
  loading,
  logoImageString,
  onContinueApple,
  onContinueGoogle,
  onForgottenPasswordLinkClick,
  onSignUpClick,
  onSubmit,
  open,
  os,
  productName,
  safeAreas,
  initEmail,
}: LoginPageProps): JSX.Element => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [emailBlur, setEmailBlur] = useState(false);
  const [password, setPassword] = useState("");
  const [displayPassword, setDisplayPassword] = useState(false);

  const passwordRef = useRef<HTMLInputElement>(null);

  const emailError: string | undefined = useMemo(() => {
    return emailBlur &&
      !new RegExp(
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ).test(email)
      ? t("auth.inputs.email.error.format")
      : undefined;
  }, [email, emailBlur]);

  useEffect(() => {
    if (initEmail) {
      setEmail(initEmail);
    }
  }, [initEmail]);

  const onSubmitClick = () => {
    onSubmit(email, password);
  };

  return (
    <AuthPageContainer open={open} safeAreas={safeAreas}>
      <form>
        <Grid
          container
          direction="column"
          spacing={2}
          p={1}
          px={2}
          mt={safeAreas.top}
        >
          <Grid item display="flex" alignItems="start">
            <Box
              sx={{
                marginX: "auto",
                img: {
                  height: "100px",
                },
              }}
            >
              <img src={logoImageString} alt={productName} />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="h4" textAlign="center">
              {t("auth.login.title")}
            </Typography>
          </Grid>
          <Grid item>
            <AuthTextField
              id="email-input"
              data-cy="email-input"
              label={t("auth.inputs.email.label")}
              placeholder={t("auth.inputs.email.placeholder")}
              value={email}
              onChange={(e) => setEmail(e.currentTarget.value)}
              type="email"
              autoComplete="username"
              onReturnKey={() => {
                passwordRef.current?.focus();
                passwordRef.current?.click();
              }}
              error={!!emailError || authError}
              errorText={emailError}
              onBlur={() => {
                setEmailBlur(true);
                clearError();
              }}
            />
          </Grid>
          <Grid item>
            <AuthTextField
              ref={passwordRef}
              id="password-input"
              data-cy="password-input"
              label={t("auth.inputs.password.label")}
              placeholder={t("auth.inputs.password.placeholder")}
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              type={displayPassword ? "text" : "password"}
              autoComplete="current-password"
              endAdornment={
                <ShowPasswordAdornment
                  active={displayPassword}
                  setActive={setDisplayPassword}
                />
              }
              error={authError}
              errorText={t("auth.inputs.password.error.unknown")}
              onBlur={() => {
                clearError();
              }}
            />
            <Typography fontSize={"0.8rem"} textAlign="right" mt={0.5}>
              <WebviewLink
                onClick={() => {
                  onForgottenPasswordLinkClick();
                }}
              >
                {t("auth.login.forgottenPassword")}
              </WebviewLink>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              data-cy="login-submit-button"
              onClick={(e) => {
                e.preventDefault();
                onSubmitClick();
              }}
              color="secondary"
              variant="contained"
              fullWidth
              disabled={
                email.length === 0 || password.length === 0 || !!emailError
              }
              type="submit"
            >
              {loading ? (
                <CircularProgress
                  size="1.5rem"
                  style={{
                    color: "#fff",
                  }}
                />
              ) : (
                t("auth.login.action")
              )}
            </Button>
          </Grid>
          {allowThirdPartyAuth ? (
            <>
              <Grid item display="flex" justifyContent="center" my={-1}>
                <TextDivider text={t("general.or")} />
              </Grid>
              {os === "android" ? (
                <>
                  <Grid item>
                    <GoogleButton
                      onClick={onContinueGoogle}
                      type="button"
                      authVariant="signin"
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item>
                    <AppleButton
                      onClick={onContinueApple}
                      type="button"
                      authVariant="signin"
                      disabled={loading}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <AppleButton
                      onClick={onContinueApple}
                      type="button"
                      authVariant="signin"
                      disabled={loading}
                    />
                  </Grid>
                  <Grid item>
                    <GoogleButton
                      onClick={onContinueGoogle}
                      type="button"
                      authVariant="signin"
                      disabled={loading}
                    />
                  </Grid>
                </>
              )}
            </>
          ) : null}
          <Grid item>
            <Typography
              fontSize={"0.8rem"}
              textAlign="center"
              color="#secondary"
              mt={1}
            >
              <WebviewLink
                onClick={() => {
                  onSignUpClick();
                }}
              >
                <Trans i18nKey="auth.login.signup" />
              </WebviewLink>
            </Typography>
          </Grid>
        </Grid>
      </form>
    </AuthPageContainer>
  );
};

export default LoginPage;
