const randBetween = (min: number, max: number): number => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const selectABTestGroup = (bPercentage: number): "A" | "B" => {
  return randBetween(0, 100) > bPercentage ? "A" : "B";
};

const Maths = {
  randBetween,
  selectABTestGroup,
};

export default Maths;
