import {
  ScrollSelect,
  ScrollSelectOption,
} from "@neurosolutionsgroup/components";
import { ChildSecurityOption } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";

interface ChildSecuritySelectionProps {
  value: ChildSecurityOption | null;
  onChange: (value: ChildSecurityOption) => void;
  paddingX: string | number;
}

const ChildSecuritySelection = ({
  value,
  onChange,
  paddingX,
}: ChildSecuritySelectionProps): JSX.Element => {
  const { t } = useTranslation();

  const options: ScrollSelectOption<number>[] = [
    {
      value: 1,
      label: t("settings.child.iconsPassword"),
      dataCy: "child-security-icons",
    },
    {
      value: 2,
      label: t("settings.child.digitsPassword"),
      dataCy: "child-security-digits",
    },
    {
      value: 0,
      label: t("settings.child.noPassword"),
      dataCy: "child-security-none",
    },
  ];

  return (
    <ScrollSelect
      options={options}
      value={value}
      onChange={onChange}
      paddingX={paddingX}
    />
  );
};

export default ChildSecuritySelection;
