import { SafeAreas } from "@neurosolutionsgroup/models";

export interface FTUEJournalScreenProps {
  safeAreas: SafeAreas;
}

const FTUEJournalScreen = (): JSX.Element | null => {
  return null;
};

export default FTUEJournalScreen;
