import React from "react";
import clsx from "clsx";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { SAFE_AREA_BOTTOM } from "stylesheets";
import { CypressProps } from "@neurosolutionsgroup/models";

interface StickyButtonsProps extends CypressProps {
  onCancel?: VoidFunction;
  onConfirm: VoidFunction;
  disabled?: boolean;
  confirmText?: string;
  cancelText?: string;
  fullWidth?: boolean;
  confirmColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  cancelColor?:
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning"
    | undefined;
  confirmVariant?: "text" | "contained" | "outlined";
  cancelVariant?: "text" | "contained" | "outlined";
  loading?: boolean;
}

export const STICKY_BUTTONS_PAGE_PADDING = `calc(60px + ${SAFE_AREA_BOTTOM})`;

const StickyButtons: React.FC<StickyButtonsProps> = ({
  onCancel,
  onConfirm,
  disabled = false,
  confirmText,
  cancelText,
  fullWidth = false,
  confirmColor = "secondary",
  cancelColor = "primary",
  confirmVariant = "contained",
  cancelVariant = "text",
  loading,
  ...props
}) => {
  const rootClass = "parent-sticky-buttons";

  const { t } = useTranslation();

  return (
    <div className={rootClass}>
      <Grid container justifyContent="center" spacing={2}>
        {onCancel && (
          <Grid item>
            <Button
              color={cancelColor}
              variant={cancelVariant}
              onClick={onCancel}
              classes={{
                root: clsx(
                  rootClass + "__button",
                  rootClass + "__button--negative"
                ),
              }}
            >
              {cancelText ?? t("general.actions.cancel")}
            </Button>
          </Grid>
        )}
        <Grid item flexGrow={!onCancel && fullWidth ? 1 : undefined}>
          <Button
            id="routine-confirm-button"
            data-cy={props["data-cy"] ?? "confirm-form-button"}
            color={confirmColor}
            variant={confirmVariant}
            onClick={onConfirm}
            disabled={disabled}
            className={clsx(
              rootClass + "__button",
              rootClass + "__button--positive"
            )}
            fullWidth={!onCancel && fullWidth}
          >
            {loading ? (
              <CircularProgress
                size="1.5rem"
                style={{
                  color: "#fff",
                }}
              />
            ) : (
              confirmText ?? t("general.actions.confirm")
            )}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default StickyButtons;
