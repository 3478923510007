import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

export type FaceState = "sad" | "neutral" | "happy";

export interface FaceIconProps extends IconProps {
  state: FaceState;
}

const FaceIcon = ({
  state,
  color = DEFAULT_ICON_COLOR,
  ...props
}: FaceIconProps): JSX.Element => {
  const path = () => {
    switch (state) {
      case "sad":
        return (
          <>
            <ellipse
              cx="2.17"
              cy="4.05"
              rx="2.17"
              ry="2.17"
              fill={color}
              id="ellipse156"
            />
            <ellipse
              cx="17.83"
              cy="4.05"
              rx="2.17"
              ry="2.17"
              fill={color}
              id="ellipse158"
            />
            <path
              d="m 16.38,17.30 c 0,-3.93 -12.03,-4.52 -12.03,0"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              id="path160"
            />
          </>
        );
      case "happy":
        return (
          <>
            <ellipse
              cx="2.17"
              cy="4.05"
              rx="2.17"
              ry="2.17"
              fill={color}
              id="ellipse84"
            />
            <ellipse
              cx="17.83"
              cy="4.05"
              rx="2.17"
              ry="2.17"
              fill={color}
              id="ellipse86"
            />
            <path
              d="m 4.35,14.12 c 0,3.93 12.03,4.52 12.03,0"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              id="path88"
            />
          </>
        );
      case "neutral":
      default:
        return (
          <>
            <ellipse
              cx="2.17"
              cy="4.05"
              rx="2.17"
              ry="2.17"
              fill={color}
              id="ellipse120"
            />
            <ellipse
              cx="17.83"
              cy="4.05"
              rx="2.17"
              ry="2.17"
              fill={color}
              id="ellipse122"
            />
            <path
              d="M8 16.81, L18 10.77"
              stroke={color}
              strokeWidth="2.5"
              strokeLinecap="round"
              id="line124"
            />
          </>
        );
    }
  };

  return (
    <BaseIcon classModifier="face" {...props}>
      {path()}
    </BaseIcon>
  );
};

export default FaceIcon;
