import {
  AccountBase,
  CompleteAccountInfo,
  DowngradeUserArgs,
  MasterSettings,
  NotificationsSettings,
  RegisterCompleteUserBody,
  RegisterRaalmUserBody,
  RegisterRestrictedUserBody,
  RegisterUserBody,
  SaveSensitiveInfoBody,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import { executeAuthedGet, getHeaders, getURL, handleApiError } from "./utils";

interface CheckUserExistsByEmailResult {
  emailTaken: boolean;
}

/**
 * Check if an email is already associated with an account.
 */
const checkUserExistsByEmail = async (email: string): Promise<boolean> => {
  const URL = getURL();
  const PATH = "/api/account/email";

  const body = {
    email,
  };

  try {
    const result = await axios.post<CheckUserExistsByEmailResult>(
      URL + PATH,
      body
    );

    return result.data.emailTaken;
  } catch (err) {
    return handleApiError(err);
  }
};

const downgrade = async (args: DowngradeUserArgs): Promise<boolean> => {
  const URL = getURL();
  const PATH = "/api/account/downgrade";

  const headers = await getHeaders(true, "downgrade");

  try {
    await axios.post(URL + PATH, args, { headers });

    return true;
  } catch (err) {
    return handleApiError(err);
  }
};

const getUserInfo = async (): Promise<CompleteAccountInfo> => {
  const PATH = "/api/account/complete";

  return executeAuthedGet(PATH);
};

const getMasterSettings = async (): Promise<MasterSettings> => {
  const PATH = "/api/account/settings";

  return executeAuthedGet(PATH);
};

const deleteUserInfo = async (): Promise<void> => {
  const URL = getURL() + "/api/account/user-info";

  const headers = await getHeaders(true, "deleteUserInfo");

  try {
    return await axios.delete(URL, { headers });
  } catch (err) {
    return handleApiError(err);
  }
};

const getCustomToken = async (): Promise<string> => {
  const PATH = "/api/account/auth-token";

  const result = await executeAuthedGet<{ token: string }>(PATH);

  return result.token;
};

const getNotificationSettings = async (): Promise<NotificationsSettings> => {
  const PATH = "/api/notificationsSettings";

  return await executeAuthedGet<NotificationsSettings>(PATH);
};

const putUserInfo = async (userInfo: SaveSensitiveInfoBody): Promise<void> => {
  const URL = getURL() + "/api/account/user-info";

  const headers = await getHeaders(true, "putUserInfo");

  try {
    await axios.put(URL, userInfo, {
      headers,
    });
  } catch (err) {
    return handleApiError(err);
  }
};

const putMasterSettings = async (
  settings: MasterSettings
): Promise<MasterSettings> => {
  const URL = getURL() + "/api/account/settings";

  const headers = await getHeaders(true, "putMasterSettings");

  try {
    const result = await axios.put<MasterSettings>(URL, settings, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const registerRaalmUser = async (
  body: RegisterRaalmUserBody
): Promise<AccountBase> => {
  const URL = getURL();
  const PATH = "/api/account/raalm";

  try {
    const result = await axios.post<AccountBase>(URL + PATH, body);

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const registerUser = async (
  body: RegisterCompleteUserBody
): Promise<AccountBase> => {
  const URL = getURL() + "/api/account/complete";

  try {
    const result = await axios.post<AccountBase>(URL, body);

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const registerUserRestricted = async (
  body: RegisterRestrictedUserBody
): Promise<AccountBase> => {
  const URL = getURL() + "/api/account/restricted";

  try {
    const result = await axios.post<AccountBase>(URL, body);

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const registerUserSimple = async (
  body: RegisterUserBody
): Promise<AccountBase> => {
  const URL = getURL() + "/api/account";

  try {
    const result = await axios.post<AccountBase>(URL, body);

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const requestPasswordReset = async (
  email: string,
  captchaResponse: string | null
): Promise<boolean> => {
  const URL = getURL() + "/api/account/recover";

  const body = {
    email,
    captchaResponse,
  };

  try {
    await axios.post(URL, body);

    return true;
  } catch (err) {
    return handleApiError(err);
  }
};

const resetPassword = async (
  password: string,
  token: string
): Promise<boolean> => {
  const URL = getURL() + "/api/account/reset-password";

  const body = {
    password,
    token,
  };

  try {
    await axios.post(URL, body);

    return true;
  } catch (err) {
    return handleApiError(err);
  }
};

const reupgrade = async (): Promise<boolean> => {
  const URL = getURL();
  const PATH = "/api/account/reupgrade";

  const headers = await getHeaders(true, "reupgrade");

  try {
    await axios.post(URL + PATH, null, { headers });

    return true;
  } catch (err) {
    return handleApiError(err);
  }
};

const updateNotificationSettings = async (
  body: NotificationsSettings
): Promise<NotificationsSettings> => {
  const URL = getURL() + "/api/notificationsSettings";

  const headers = await getHeaders(true, "updateNotificationSettings");

  return await axios.post(URL, body, { headers });
};

const Account = {
  checkUserExistsByEmail,
  deleteUserInfo,
  downgrade,
  getCustomToken,
  getMasterSettings,
  getNotificationSettings,
  getUserInfo,
  putMasterSettings,
  putUserInfo,
  registerRaalmUser,
  registerUser,
  registerUserRestricted,
  registerUserSimple,
  requestPasswordReset,
  resetPassword,
  reupgrade,
  updateNotificationSettings,
};

export default Account;
