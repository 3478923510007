import { Box, Checkbox, Input } from "@mui/material";
import { StickyButtons } from "common/Components";
import { PageSection } from "common/Components/PageSection";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";
import FilterOption from "common/Components/Filter/FilterOption";
import { Icons } from "@neurosolutionsgroup/components";

export enum SideEffectFormState {
  creation,
  edition,
}

export interface SideEffectFormProps {
  selectedSideEffects: string[];
  setSelectedSideEffects: (value: string[]) => void;
  onSubmit: () => void;
  onCancel: () => void;
  onBack?: () => void;
  formState: SideEffectFormState;
}

const SideEffectForm: React.FC<SideEffectFormProps> = ({
  selectedSideEffects,
  setSelectedSideEffects,
  onSubmit,
  onCancel,
  onBack,
  formState,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const {
    selectors: { sideEffectById, sideEffectsSortedAlphabeticlly },
  } = useSideEffect();

  const [filter, setFilter] = useState<string>("");
  const [frequentlyFollowedFilter, setfrequentlyFollowedFilter] =
    useState<boolean>(false);

  const filteredSideEffect = useCallback((): string[] => {
    const output: string[] = [];

    sideEffectsSortedAlphabeticlly.forEach((se) => {
      const sideEffectContainFilter = se.name[language]
        .toLowerCase()
        .includes(filter.toLowerCase());
      if (
        sideEffectContainFilter &&
        ((frequentlyFollowedFilter && se.isFrequentlyFollowed) ||
          !frequentlyFollowedFilter)
      ) {
        output.push(se.id);
      }
    });
    return output;
  }, [
    filter,
    sideEffectById,
    frequentlyFollowedFilter,
    sideEffectsSortedAlphabeticlly,
  ]);

  const frequentlyFollowedSideEffect = useCallback(() => {
    const output: string[] = [];

    if (sideEffectById) {
      Object.entries(sideEffectById).forEach(([key, value]) => {
        if (value.isFrequentlyFollowed) {
          output.push(key);
        }
      });
    }
    return output;
  }, [sideEffectById]);

  const removeSelectedSideEffect = (id: string) => {
    const temp: string[] = [...selectedSideEffects];
    const indexToRemove = temp.indexOf(id);
    temp.splice(indexToRemove, 1);
    setSelectedSideEffects(temp);
  };
  const addSelectedSideEffect = (id: string) => {
    setSelectedSideEffects([...selectedSideEffects, id]);
  };

  const sideEffectselectionChange = (id: string) => {
    if (selectedSideEffects.includes(id)) {
      removeSelectedSideEffect(id);
    } else {
      addSelectedSideEffect(id);
    }
  };

  return (
    <Box>
      <Box pb={STICKY_BUTTONS_PAGE_PADDING}>
        <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.SelectSideEffects
          handleOnNext={onSubmit}
          handleOnBack={
            onBack
              ? onBack
              : () => {
                  return;
                }
          }
        >
          <PageSection title={t("general.sideEffect")}>
            <Input
              value={filter}
              onChange={(event) => setFilter(event.target.value)}
              startAdornment={
                <Box px={1}>
                  <Icons.SearchIcon color="#c4c4c4" />
                </Box>
              }
              disableUnderline={true}
              placeholder={t("medication.followUp.edit.searchSideEffect")}
              fullWidth
              sx={{
                mb: "1em",
                borderRadius: "0.5em",
              }}
            />
            <FilterOption
              label={t("general.frequentlyFollowed")}
              active={frequentlyFollowedFilter}
              onClick={() => setfrequentlyFollowedFilter((current) => !current)}
              count={frequentlyFollowedSideEffect().length}
            />
            {filteredSideEffect().map((id) => {
              return sideEffectById ? (
                <Box
                  px={1}
                  key={id}
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  alignItems="center"
                  onClick={() => sideEffectselectionChange(id)}
                >
                  <Box>{sideEffectById[id].name[language]}</Box>
                  <Checkbox
                    checked={selectedSideEffects.includes(id)}
                    color="secondary"
                  />
                </Box>
              ) : null;
            })}
          </PageSection>
        </FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.SelectSideEffects>
      </Box>
      <StickyButtons
        onCancel={onCancel}
        onConfirm={() => {
          if (selectedSideEffects) {
            onSubmit();
          }
        }}
        confirmText={
          formState === SideEffectFormState.creation
            ? t("general.actions.next")
            : undefined
        }
      />
    </Box>
  );
};
export default SideEffectForm;
