import { ChildIconsDefintion } from "@neurosolutionsgroup/models";
import DefaultChildIcon from "./pi_-1.png";
import ChildIcon0 from "./pi_0.png";
import ChildIcon1 from "./pi_1.png";
import ChildIcon2 from "./pi_2.png";
import ChildIcon3 from "./pi_3.png";
import ChildIcon4 from "./pi_4.png";
import ChildIcon5 from "./pi_5.png";

const ChildIcons: ChildIconsDefintion = {
  "-1": DefaultChildIcon,
  0: ChildIcon0,
  1: ChildIcon1,
  2: ChildIcon2,
  3: ChildIcon3,
  4: ChildIcon4,
  5: ChildIcon5,
};

export default ChildIcons;
