import { Box } from "@mui/material";
import { useMemo } from "react";

export interface GaugeProps {
  min: number;
  max: number;
  value: number;
}

const Gauge = ({ min, max, value }: GaugeProps): JSX.Element => {
  const normalisedValue = useMemo(() => {
    return value / (max - min);
  }, [min, max, value]);

  const clipPath = useMemo(() => {
    // Split clip path into right and left sides.
    // This creates clip path with a line from the center at the correct angle.
    // It then goes to the nearest corner without going back over the guage (Top left if lhs, top right if rhs).
    // Then back the center via the top right then bottom right.
    // To debug you can add a background colour to the clip path Box.
    if (normalisedValue >= 0.5) {
      // Right-hand side.

      const angleRads = Math.PI * (1 - normalisedValue);

      const sinOfAngle = Math.sin(angleRads);

      const cosOfAngle = Math.cos(angleRads);

      const height = 100 - 100 * sinOfAngle;

      const length = 50 + 50 * cosOfAngle;

      // rhs goes directly to top right (100% 0).
      return `polygon(50% 100%, ${length}% ${height}%, 100% 0, 100% 100%)`;
    } else {
      // Left-hand side.

      const angleRads = Math.PI * normalisedValue;

      const sinOfAngle = Math.sin(angleRads);

      const cosOfAngle = Math.cos(angleRads);

      const height = 100 - 100 * sinOfAngle;

      const length = 50 - 50 * cosOfAngle;

      // lhs goes to top left (0 0), before going to top right.
      return `polygon(50% 100%, ${length}% ${height}%, 0 0, 100% 0, 100% 100%)`;
    }
  }, [normalisedValue]);

  const needleAngle = useMemo(() => {
    const angleFactor = normalisedValue - 0.5;

    return 180 * angleFactor;
  }, [normalisedValue]);

  return (
    <Box
      id="gauge"
      sx={{
        position: "relative",
        overflow: "visible",
        marginBottom: "5%",
      }}
    >
      <Box display="flex">
        <svg viewBox="0 0 256 128" fill="none" width="100%">
          <path
            d="M255.999 127.998L232.959 62.0781L183.039 99.1981L191.999 127.998L255.999 127.998Z"
            fill="#2CD25A"
          />
          <path
            d="M232.956 62.0728L187.124 16.6328L163.836 75.5128L183.036 99.1928L232.956 62.0728Z"
            fill="#99E332"
          />
          <path
            d="M187.128 16.64L128 0L128 64L163.84 75.52L187.128 16.64Z"
            fill="#DEEE17"
          />
          <path
            d="M128.011 0L68.8828 14.72L93.4511 72.96L128.011 64L128.011 0Z"
            fill="#FCD313"
          />
          <path
            d="M68.8708 14.7266L23.0391 58.2466L72.3191 100.487L93.4391 72.9666L68.8708 14.7266Z"
            fill="#FE8026"
          />
          <path
            d="M23.04 58.2344L0 127.994H64L72.32 100.474L23.04 58.2344Z"
            fill="#FF233A"
          />
        </svg>
      </Box>

      <Box
        id="gauge-mask"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          clipPath: clipPath,
        }}
      >
        <svg viewBox="0 0 256 128" fill="none" width="100%">
          <path
            d="M256 128L232.96 62.08L187.128 16.64L128 0L68.8717 14.72L23.0 58L-0.1 128H64L72.32 100.48L93.44 72.96L128 64L163.84 75.52L183.04 99.2L192 128L256 128Z"
            fill="#f5efe7"
          />
        </svg>
      </Box>

      <Box
        id="gauge-needle"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflow: "visible",
          svg: {
            overflow: "visible",
            path: {
              transformOrigin: "50% 100%",
              transform: `rotate(${needleAngle}deg)`,
            },
          },
        }}
      >
        <svg viewBox="0 0 256 128" fill="none" width="100%">
          <path d="M128 128 L 142 104 L 128 35 L 114 104 Z" fill="#31737C" />
        </svg>
      </Box>
    </Box>
  );
};

export default Gauge;
