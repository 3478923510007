import {
  AppGroupAccessList,
  CompleteChild,
  FirestoreGamerChild,
  GamerChild,
  GetAllTaskHistoriesForChildBody,
  GetValidatedTaskHistoriesBody,
  Language,
  TaskHistory,
  UpdateTaskHistoryBody,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import { executeAuthedGet, getHeaders, getURL, handleApiError } from "./utils";

const getCompletChildren = async (
  deviceUtcOffsetMin: number
): Promise<CompleteChild[]> => {
  const PATH = "/api/completeChild";

  const extraHeaders = {
    "device-utc-offset": deviceUtcOffsetMin,
  };

  return executeAuthedGet(PATH, extraHeaders);
};

const getGamerChildren = async (): Promise<GamerChild[]> => {
  const PATH = "/api/gamerChild";

  return executeAuthedGet(PATH);
};

const getChildGroups = async (
  childId: string
): Promise<AppGroupAccessList[]> => {
  const PATH = `/api/gamerChild/${childId}/groups`;

  return executeAuthedGet(PATH);
};

const removeChildFromGroup = async (
  childId: string,
  groupId: string
): Promise<boolean> => {
  const URL = getURL() + `/api/gamerChild/${childId}/groups/${groupId}`;

  const headers = await getHeaders(true, "removeChildFromGroup");

  try {
    return await axios.delete(URL, {
      headers,
    });
  } catch (err) {
    return handleApiError(err);
  }
};

const setupDefaultChild = async (
  language: Language
): Promise<FirestoreGamerChild | null> => {
  const URL = getURL() + `/api/gamerChild/default`;

  const headers = await getHeaders(true, "setupDefaultChild");

  try {
    return await axios.post(
      URL,
      { language },
      {
        headers,
      }
    );
  } catch (err) {
    return handleApiError(err);
  }
};

const getMissingHistories = async (
  body: GetAllTaskHistoriesForChildBody
): Promise<TaskHistory[]> => {
  const URL = getURL() + "/api/taskHistory/getHistories";

  const headers = await getHeaders(true, "getMissingHistories");

  try {
    const result = await axios.post<TaskHistory[]>(URL, body, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const updateGamerChild = async (body: GamerChild): Promise<GamerChild> => {
  const URL = getURL() + "/api/gamerChild/settings";

  const headers = await getHeaders(true, "updateGamerChild");

  try {
    const result = await axios.put<GamerChild>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const getValidatedTaskHistory = async (
  body: GetValidatedTaskHistoriesBody
): Promise<TaskHistory[]> => {
  const URL = getURL() + "/api/taskHistory/validated";

  const headers = await getHeaders(true, "getValidatedTaskHistory");

  try {
    const result = await axios.post<TaskHistory[]>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const updateTaskHistory = async (
  body: UpdateTaskHistoryBody,
  v2 = true
): Promise<GamerChild> => {
  const URL = getURL() + "/api/taskHistory";

  const headers = await getHeaders(true, "updateTaskHistory");

  if (headers && v2) {
    headers["accept-version"] = "v2";
  }

  try {
    const result = await axios.put<GamerChild>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const validateTasks = async (
  body: UpdateTaskHistoryBody,
  v2 = true
): Promise<GamerChild> => {
  const URL = getURL() + "/api/taskHistory/validate";

  const headers = await getHeaders(true, "validateTasks");

  if (headers && v2) {
    headers["accept-version"] = "v2";
  }

  try {
    const result = await axios.put<GamerChild>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const deleteGamerChild = async (gamerChildId: string): Promise<void> => {
  const URL = getURL() + `/api/gamerChild/${gamerChildId}`;

  const headers = await getHeaders(true, "deleteGamerChild");

  try {
    return await axios.delete(URL, {
      headers,
    });
  } catch (err) {
    return handleApiError(err);
  }
};

const Child = {
  deleteGamerChild,
  getChildGroups,
  getCompletChildren,
  getGamerChildren,
  getMissingHistories,
  getValidatedTaskHistory,
  removeChildFromGroup,
  setupDefaultChild,
  updateGamerChild,
  updateTaskHistory,
  validateTasks,
};

export default Child;
