import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

export interface InformationIconProps extends IconProps {
  variant?: "contained" | "outline";
  secondaryColor?: string;
  highVis?: boolean;
}

export const InformationIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  variant = "contained",
  secondaryColor,
  highVis = false,
  ...props
}: InformationIconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="information" {...props}>
      {variant === "contained" ? (
        <path
          fill={color}
          strokeWidth="1"
          d="M 5 2.4140625 L 0 10 L 5 17.585938 L 15 17.585938 L 20 10 L 15 2.4140625 L 5 2.4140625 z M 10.007812 4.5449219 C 10.328012 4.5449219 10.576253 4.6254994 10.751953 4.7871094 C 10.932653 4.9487294 11.023438 5.1809987 11.023438 5.4804688 C 11.023438 5.7656687 10.932653 5.98988 10.751953 6.15625 C 10.576253 6.31786 10.328013 6.3984375 10.007812 6.3984375 C 9.6772125 6.3984375 9.4210344 6.31786 9.2402344 6.15625 C 9.0646344 5.98988 8.9765625 5.7656687 8.9765625 5.4804688 C 8.9765625 5.1809987 9.0646344 4.9487294 9.2402344 4.7871094 C 9.4210344 4.6254994 9.6772125 4.5449219 10.007812 4.5449219 z M 9.0859375 7.5683594 L 10.90625 7.5683594 L 10.90625 15.455078 L 9.0859375 15.455078 L 9.0859375 7.5683594 z "
        />
      ) : (
        <>
          <path
            d="M 14.714315,2.8470509 H 5.2854877 L 0.57112202,9.9999998 5.2854877,17.152949 h 9.4288273 l 4.714556,-7.1529492 z"
            id="path-hex"
            fill={secondaryColor}
            stroke={color}
            strokeWidth="1"
            clipPath="none"
          />
          <path
            d="M 10.9065,15.454545 H 9.0858 v -7.8859 h 1.8207 z M 8.9773,5.479505 c 0,-0.29947 0.0878,-0.53001 0.2634,-0.69163 0.1808,-0.16161 0.4365,-0.24242 0.7671,-0.24242 0.3202,0 0.5681,0.08081 0.7438,0.24242 0.1807,0.16162 0.2711,0.39216 0.2711,0.69163 0,0.2852 -0.0904,0.51099 -0.2711,0.67736 -0.1757,0.16161 -0.4236,0.24242 -0.7438,0.24242 -0.3306,0 -0.5863,-0.08081 -0.7671,-0.24242 C 9.0651,5.990495 8.9773,5.764705 8.9773,5.479505 Z"
            id="path-i"
            fill={color}
            stroke={color}
            strokeWidth="0.5"
            display="inline"
            clipPath="none"
          />
        </>
      )}
    </BaseIcon>
  );
};

export default InformationIcon;
