export type CatMap = {
  [iconId: string]: {
    [textId: string]: string;
  };
};

/**
 * Map of icons with their predefined text indexes, and the category ID for each text.
 */
export const catmap: CatMap = {
  "1": {
    "0": "1",
  },
  "2": {
    "0": "6",
  },
  "3": {
    "0": "2",
  },
  "4": {
    "0": "2",
  },
  "5": {
    "0": "7",
  },
  "6": {
    "0": "7",
  },
  "7": {
    "0": "5",
    "1": "5",
    "2": "7",
  },
  "8": {
    "0": "2",
  },
  "9": {
    "0": "1",
  },
  "10": {
    "0": "1",
  },
  "11": {
    "0": "1",
    "1": "6",
    "2": "7",
  },
  "12": {
    "0": "3",
    "1": "6",
    "2": "6",
  },
  "13": {
    "0": "3",
  },
  "14": {
    "0": "5",
    "1": "5",
  },
  "15": {
    "0": "1",
  },
  "16": {
    "0": "3",
    "1": "3",
    "2": "3",
  },
  "17": {
    "0": "1",
  },
  "18": {
    "0": "1",
    "1": "1",
  },
  "19": {
    "0": "6",
  },
  "20": {
    "0": "1",
    "1": "6",
    "2": "6",
  },
  "21": {
    "0": "6",
  },
  "22": {
    "0": "1",
  },
  "23": {
    "0": "2",
    "1": "2",
    "2": "2",
  },
  "24": {
    "0": "7",
  },
  "25": {
    "0": "3",
  },
  "26": {
    "0": "3",
  },
  "27": {
    "0": "6",
    "1": "1",
    "2": "1",
  },
  "28": {
    "0": "5",
    "1": "5",
  },
  "29": {
    "0": "5",
  },
  "30": {
    "0": "5",
  },
  "31": {
    "0": "8",
  },
  "32": {
    "0": "8",
  },
  "33": {
    "0": "5",
  },
  "34": {
    "0": "6",
    "1": "1",
  },
  "35": {
    "0": "2",
  },
  "36": {
    "0": "2",
  },
  "37": {
    "0": "2",
    "1": "2",
    "2": "2",
  },
  "38": {
    "0": "2",
  },
  "39": {
    "0": "1",
    "1": "2",
    "2": "2",
  },
  "40": {
    "0": "1",
  },
  "41": {
    "0": "3",
  },
  "42": {
    "0": "1",
  },
  "43": {
    "0": "3",
  },
  "44": {
    "0": "3",
  },
  "45": {
    "0": "1",
  },
  "46": {
    "0": "6",
    "1": "6",
  },
  "47": {
    "0": "5",
  },
  "48": {
    "0": "5",
  },
  "49": {
    "0": "1",
  },
  "50": {
    "0": "7",
  },
  "51": {
    "0": "1",
  },
  "52": {
    "0": "5",
    "1": "5",
  },
  "53": {
    "0": "7",
  },
  "54": {
    "0": "7",
  },
  "55": {
    "0": "7",
  },
  "56": {
    "0": "7",
  },
  "57": {
    "0": "7",
  },
  "58": {
    "0": "1",
  },
  "59": {
    "0": "7",
    "1": "7",
  },
  "60": {
    "0": "5",
    "1": "5",
  },
  "61": {
    "0": "1",
  },
  "62": {
    "0": "8",
    "1": "8",
  },
  "63": {
    "0": "2",
  },
  "64": {
    "0": "1",
    "1": "1",
    "2": "6",
  },
  "65": {
    "0": "1",
    "1": "1",
    "2": "6",
  },
  "66": {
    "0": "1",
    "1": "1",
    "2": "6",
  },
  "67": {
    "0": "1",
    "1": "6",
  },
  "68": {
    "0": "6",
    "1": "1",
  },
  "69": {
    "0": "2",
  },
  "70": {
    "0": "5",
    "1": "5",
  },
  "71": {
    "0": "5",
    "1": "5",
  },
  "72": {
    "0": "3",
  },
  "73": {
    "0": "7",
    "1": "3",
  },
  "74": {
    "0": "7",
  },
  "75": {
    "0": "7",
  },
  "76": {
    "0": "1",
    "1": "1",
  },
  "77": {
    "0": "7",
  },
  "78": {
    "0": "3",
  },
  "79": {
    "0": "1",
  },
  "80": {
    "0": "1",
  },
  "81": {
    "0": "1",
  },
  "82": {
    "0": "8",
    "1": "8",
  },
  "83": {
    "0": "8",
    "1": "8",
  },
  "84": {
    "0": "8",
  },
  "85": {
    "0": "8",
  },
  "86": {
    "0": "8",
    "1": "8",
  },
  "87": {
    "0": "8",
    "1": "8",
  },
  "88": {
    "0": "8",
    "1": "8",
  },
  "89": {
    "0": "8",
    "1": "8",
  },
  "90": {
    "0": "8",
  },
  "91": {
    "0": "8",
  },
  "92": {
    "0": "8",
    "1": "8",
  },
  "93": {
    "0": "8",
    "1": "8",
  },
  "94": {
    "0": "8",
  },
  "95": {
    "0": "8",
  },
  "96": {
    "0": "8",
    "1": "8",
  },
  "97": {
    "0": "8",
    "1": "8",
  },
  "98": {
    "0": "8",
  },
  "99": {
    "0": "8",
  },
  "100": {
    "0": "8",
  },
  "101": {
    "0": "8",
  },
  "102": {
    "0": "8",
    "1": "8",
  },
  "103": {
    "0": "8",
    "1": "8",
  },
  "104": {
    "0": "8",
  },
  "105": {
    "0": "8",
  },
  "106": {
    "0": "7",
  },
  "107": {
    "0": "7",
  },
  "108": {
    "0": "1",
    "1": "1",
  },
  "109": {
    "0": "1",
    "1": "1",
  },
  "110": {
    "0": "1",
    "1": "1",
  },
  "111": {
    "0": "1",
  },
  "112": {
    "0": "1",
  },
  "113": {
    "0": "1",
    "1": "1",
  },
  "114": {
    "0": "1",
    "1": "1",
  },
  "115": {
    "0": "1",
    "1": "1",
  },
  "116": {
    "0": "1",
    "1": "1",
  },
  "117": {
    "0": "1",
    "1": "1",
  },
  "118": {
    "0": "1",
    "1": "1",
  },
  "119": {
    "0": "1",
    "1": "1",
  },
};
