import { Box, Button, Typography, useTheme } from "@mui/material";
import {
  ControlledDrawer,
  DrawerMenuSection,
  Icons,
} from "@neurosolutionsgroup/components";
import {
  CypressProps,
  Language,
  Routine,
  RoutineIconsDefinition,
  SafeAreas,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface DowngradeDialogRoutinePickerProps extends CypressProps {
  childName: string;
  disabled: boolean;
  language: Language;
  onChange: (value: string) => void;
  routineIcons: RoutineIconsDefinition;
  routines: Routine[];
  safeAreas: SafeAreas;
  value: string | null;
  zIndex: number;
}

const DowngradeDialogRoutinePicker = ({
  childName,
  disabled,
  language,
  onChange,
  routineIcons,
  routines,
  safeAreas,
  value,
  zIndex,
  ...props
}: DowngradeDialogRoutinePickerProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const renderInputValue = (): JSX.Element => {
    if (value === null) {
      return (
        <>
          {t("navigation.subscription.downgrade.selectRoutine.placeholder")}
          <Icons.ArrowIcon
            arrowType="default"
            arrowDirection="down"
            color={palette.secondary.main}
          />
        </>
      );
    } else {
      const routine = routines.find((r) => r.id === value);

      return (
        <>
          <img src={routineIcons[routine?.icon ?? 0]} alt="" />
          {routine?.name}
          <Icons.ArrowIcon
            arrowType="default"
            arrowDirection="down"
            color={palette.secondary.main}
          />
        </>
      );
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={disabled}
        variant="text"
        color="secondary"
        fullWidth
        sx={{
          height: "3.5rem",
          borderRadius: "20px",
          paddingY: 0.5,
          img: {
            width: "3rem",
            height: "3rem",
            marginRight: 1,
          },
          svg: {
            marginLeft: "auto",
            width: "15px",
            height: "15px",
          },
        }}
        data-cy={props["data-cy"]}
      >
        {renderInputValue()}
      </Button>
      <ControlledDrawer
        open={open}
        onClose={() => setOpen(false)}
        safeAreas={safeAreas}
        sx={{
          zIndex,
        }}
      >
        <DrawerMenuSection variant="header">
          <Typography variant="h4">
            {t("navigation.subscription.downgrade.selectRoutine.drawTitle", {
              childName,
            })}
          </Typography>
        </DrawerMenuSection>
        {routines.map((routine) => (
          <DrawerMenuSection
            key={routine.id}
            onClick={(e) => {
              e.stopPropagation();
              onChange(routine.id);
              setOpen(false);
            }}
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              paddingX: 4,
              img: {
                width: "3.5rem",
                height: "3.5rem",
                objectFit: "contain",
              },
            }}
            data-cy={"downgrade-routine-option"}
          >
            <img src={routineIcons[routine.icon ?? 0]} alt="" />
            <Box ml={1}>
              <Typography fontWeight="bold" textAlign="left">
                {routine.name}
              </Typography>
              <Typography textAlign="left">
                {Tools.Time.Strings.localizedTimePeriodFromSeconds(
                  routine.start,
                  routine.end,
                  language
                )}
              </Typography>
            </Box>
          </DrawerMenuSection>
        ))}
      </ControlledDrawer>
    </>
  );
};

export default DowngradeDialogRoutinePicker;
