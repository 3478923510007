import { Box, Typography } from "@mui/material";
import { InformationButton } from "@neurosolutionsgroup/components";
import CoachAssets from "assets/coach";
import React, { PropsWithChildren } from "react";

interface PageSectionClasses {
  root?: string;
  title?: string;
  body?: string;
}

interface PageSectionProps {
  title: string | JSX.Element;
  classes?: PageSectionClasses;
  showBottomBorder?: boolean;
  info?: string | string[] | JSX.Element;
  noMargin?: boolean;
}

const PageSection: React.FC<PropsWithChildren<PageSectionProps>> = ({
  title,
  classes,
  showBottomBorder = true,
  info,
  noMargin = false,
  ...props
}) => {
  return (
    <Box
      pt={1}
      className={classes?.root}
      sx={{
        maxWidth: "100%",
        overflowX: "visible",
      }}
    >
      {typeof title === "string" ? (
        <Box display="flex" alignItems="center">
          <Typography variant="h3">{title} </Typography>
          {info ? (
            <InformationButton
              infoText={info}
              coachImage={CoachAssets.CoachFull}
              sx={{
                marginLeft: 1,
                height: "20px",
              }}
            />
          ) : null}
        </Box>
      ) : (
        title
      )}
      <Box
        mt={noMargin ? 0 : 1}
        mb={noMargin ? 0 : 2}
        className={classes?.body}
      >
        {props.children}
      </Box>
      {showBottomBorder && <hr />}
    </Box>
  );
};

export default PageSection;
