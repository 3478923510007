import { Button, ButtonProps, CircularProgress } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface AppleButtonProps extends ButtonProps {
  authVariant: "continue" | "signin";
  loading?: boolean;
}

const GoogleButton: React.FC<AppleButtonProps> = ({
  authVariant,
  loading,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Button
      {...props}
      color="secondary"
      variant="text"
      fullWidth
      sx={{
        img: {
          height: "1.5rem",
          width: "1.5rem",
          marginRight: "6px",
        },
      }}
    >
      {loading ? (
        <CircularProgress size="1.5rem" color="secondary" />
      ) : (
        <>
          <svg width="24" height="24" viewBox="-3 0 7.86 7.86">
            <g
              id="g107"
              transform="matrix(0.03010341,0,0,0.03010341,-2.9214002,-1.755e-7)"
            >
              <path
                d="m 255.878,133.451 c 0,-10.734 -0.871,-18.567 -2.756,-26.69 H 130.55 v 48.448 h 71.947 c -1.45,12.04 -9.283,30.172 -26.69,42.356 l -0.244,1.622 38.755,30.023 2.685,0.268 c 24.659,-22.774 38.875,-56.282 38.875,-96.027"
                fill="#4285f4"
                id="path9"
              />
              <path
                d="m 130.55,261.1 c 35.248,0 64.839,-11.605 86.453,-31.622 l -41.196,-31.913 c -11.024,7.688 -25.82,13.055 -45.257,13.055 -34.523,0 -63.824,-22.773 -74.269,-54.25 l -1.531,0.13 -40.298,31.187 -0.527,1.465 C 35.393,231.798 79.49,261.1 130.55,261.1"
                fill="#34a853"
                id="path11"
              />
              <path
                d="m 56.281,156.37 c -2.756,-8.123 -4.351,-16.827 -4.351,-25.82 0,-8.994 1.595,-17.697 4.206,-25.82 L 56.063,103 15.26,71.312 13.925,71.947 C 5.077,89.644 0,109.517 0,130.55 c 0,21.033 5.077,40.905 13.925,58.602 L 56.281,156.37"
                fill="#fbbc05"
                id="path13"
              />
              <path
                d="m 130.55,50.479 c 24.514,0 41.05,10.589 50.479,19.438 L 217.873,33.943 C 195.245,12.91 165.798,0 130.55,0 79.49,0 35.393,29.301 13.925,71.947 L 56.136,104.73 C 66.726,73.253 96.027,50.479 130.55,50.479"
                fill="#eb4335"
                id="path15"
              />
            </g>
          </svg>
          &nbsp;
          {t(`auth.google.${authVariant}`)}
        </>
      )}
    </Button>
  );
};

export default GoogleButton;
