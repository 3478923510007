import { Grid, ButtonBase, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import React from "react";

export interface AuthpageHeaderProps {
  logoImageString: string;
  imageAlt: string;
  onBack?: VoidFunction;
}

const AuthpageHeader: React.FC<AuthpageHeaderProps> = ({
  logoImageString,
  onBack,
  imageAlt,
}) => {
  const { palette } = useTheme();

  return (
    <Grid item container>
      <Grid item flex={1}>
        {onBack && (
          <ButtonBase
            onClick={onBack}
            sx={{
              padding: "12px",
              borderRadius: "10px",
              svg: {
                width: "2rem",
                height: "2rem",
              },
            }}
          >
            <Icons.ArrowIcon
              arrowDirection="left"
              color={palette.primary.main}
            />
          </ButtonBase>
        )}
      </Grid>
      <Grid
        item
        sx={{
          mt: 4,
          marginX: "auto",
          img: {
            height: "8em",
          },
        }}
      >
        <img src={logoImageString} alt={imageAlt} />
      </Grid>
      <Grid item flex={1} />
    </Grid>
  );
};
export default AuthpageHeader;
