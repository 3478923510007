import { Typography } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface SettingsInputGroupProps extends PropsWithChildren {
  labelText: string;
  htmlFor?: string;
  className?: string;
}

const SettingsInputGroup: React.FC<SettingsInputGroupProps> = ({
  labelText,
  htmlFor,
  className,
  ...props
}) => {
  return (
    <div className={"settings-input-group pb-2 " + (className ?? "")}>
      <Typography>
        <label className="settings-input-group__label" htmlFor={htmlFor}>
          {labelText}
        </label>
      </Typography>
      {props.children}
    </div>
  );
};

export default SettingsInputGroup;
