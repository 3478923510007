import { Box, Grid, Typography } from "@mui/material";
import { Icons, ValidationButton } from "@neurosolutionsgroup/components";

interface ChallengeValidationLineComponentProps {
  index: number | null;
  text: string;
  state: boolean | null;
  confirmed: boolean;
  onChange: (done: boolean) => void;
}

const ChallengeValidationLineComponent = ({
  index,
  text,
  state,
  confirmed,
  onChange,
}: ChallengeValidationLineComponentProps): JSX.Element => {
  return (
    <Grid
      container
      spacing={1}
      alignItems="center"
      data-cy="challenge-validation-line"
    >
      <Grid item>
        <Box
          sx={{
            width: "1.5rem",
            backgroundColor: "#EAF1F2",
            borderRadius: 999,
            paddingY: 0.5,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography fontWeight={800} fontSize="0.8rem">
            {index === null ? "-" : index}
          </Typography>
        </Box>
      </Grid>
      <Grid item flexGrow={1}>
        <Typography
          sx={{
            fontStyle: "italic",
            fontSize: "0.9rem",
          }}
        >
          {text}
        </Typography>
      </Grid>
      <Grid item display="flex">
        <Box mr={1}>
          <ValidationButton
            active={state === true}
            onClick={() => onChange(true)}
            disabled={confirmed}
            sx={{
              visibility: confirmed && state === false ? "hidden" : undefined,
            }}
            data-cy="challenge-validation-done"
          >
            <Icons.CheckMarkIcon checkStyle="kairos" color="#A6DA2D" />
          </ValidationButton>
        </Box>
        <Box>
          <ValidationButton
            active={state === false}
            onClick={() => onChange(false)}
            disabled={confirmed}
            sx={{
              visibility: confirmed && state === true ? "hidden" : undefined,
            }}
            data-cy="challenge-validation-not-done"
          >
            <Icons.CloseIcon color="#ef4044" />
          </ValidationButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChallengeValidationLineComponent;
