import { Box, Grid, RadioGroup, Typography } from "@mui/material";
import { PriceInfo } from "@neurosolutionsgroup/models";
import PlanSelectionButton from "./PlanSelectionButton";
import { useTranslation } from "react-i18next";
import PlanMarketingTag from "./PlanMarketingTag";
import { useMemo } from "react";

export type Product = "monthly" | "annualTrial";

export interface PlanSelectionProps {
  onPriceSelected: (product: Product) => void;
  priceInfo?: PriceInfo;
  value: Product;
}

const PlanSelection = ({
  onPriceSelected,
  priceInfo,
  value,
}: PlanSelectionProps): JSX.Element => {
  const { t } = useTranslation();

  const annualSaving = useMemo(() => {
    const annualPrice = priceInfo?.annualTrial.price;

    const monthlyPrice = priceInfo?.monthly.price;

    if (annualPrice && monthlyPrice) {
      const monthlyTotal = monthlyPrice * 12;

      const diff = monthlyTotal - annualPrice;

      return Math.floor((diff / monthlyTotal) * 100);
    } else {
      return 35;
    }
  }, [priceInfo]);

  return (
    <RadioGroup value={value}>
      <Grid container spacing={1} mb={0.5}>
        <Grid item xs={6} />
        <Grid item xs={6}>
          <Box
            sx={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "primary.main",
              borderRadius: 999,
              backgroundColor: "#fff",
            }}
          >
            <Typography
              color="primary"
              textAlign="center"
              fontWeight={700}
              fontSize="0.85rem"
              textTransform="uppercase"
            >
              {t("navigation.subscription.screenTwo.drawer.popular")}
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={1} alignItems="stretch">
        <Grid item xs={6} display="flex" alignItems="stretch">
          <PlanSelectionButton
            priceString={priceInfo?.monthly.priceString}
            priceSuffix={t("navigation.subscription.monthly.priceSuffix")}
            title={t("navigation.subscription.monthly.title")}
            text={t("navigation.subscription.monthly.text")}
            isSelected={value === "monthly"}
            onSelected={() => onPriceSelected("monthly")}
            radioValue="monthly"
          />
        </Grid>
        <Grid item xs={6} display="flex" alignItems="stretch">
          <PlanMarketingTag
            content={t("navigation.subscription.annual.additionalText", {
              count: annualSaving,
            })}
          >
            <PlanSelectionButton
              priceString={priceInfo?.annualTrial.priceString}
              priceSuffix={t("navigation.subscription.annual.priceSuffix")}
              title={t("navigation.subscription.annual.title")}
              text={t("navigation.subscription.annual.text")}
              isSelected={value === "annualTrial"}
              onSelected={() => onPriceSelected("annualTrial")}
              radioValue="annualTrial"
            />
          </PlanMarketingTag>
        </Grid>
      </Grid>
    </RadioGroup>
  );
};

export default PlanSelection;
