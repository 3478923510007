import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const MedalIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="save" {...props}>
      <circle cx="10" cy="7.55005" r="4" fill={color} />
      <circle cx="10" cy="7.55005" r="6.75" stroke={color} strokeWidth="1.5" />
      <path
        d="M5 12.2116C5 12.2116 5.00005 16.6296 5.00005 18.9444L9.84238 17.0206L14.538 18.9444V12.2115"
        stroke={color}
        strokeWidth="1.37915"
      />
    </BaseIcon>
  );
};

export default MedalIcon;
