import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const ExternalLinkIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="external-link" {...props}>
      <path
        d="M 1.6666667,20 Q 1,20 0.5,19.5 0,19 0,18.333333 V 1.6666667 Q 0,1 0.5,0.5 1,0 1.6666667,0 H 9.416667 V 1.6666667 H 1.6666667 V 18.333333 H 18.333333 v -7.75 H 20 v 7.75 Q 20,19 19.5,19.5 19,20 18.333333,20 Z M 7.277778,13.916667 6.111111,12.722222 17.166667,1.6666667 H 11.083333 V 0 H 20 V 8.916667 H 18.333333 V 2.8611111 Z"
        fill={color}
      />
    </BaseIcon>
  );
};

export default ExternalLinkIcon;
