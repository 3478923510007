import React, { useMemo, useState } from "react";
import { MainPage as MainPageComponent } from "@neurosolutionsgroup/webviews-pages";
import useChildren from "common/hooks/children/useChildren";
import useNavigation from "common/hooks/navigation/useNavigation";
import { Tools } from "@neurosolutionsgroup/tools";
import useParameters from "common/hooks/Parameters/useParameters";
import {
  ExternalLink,
  UniWebViewActions,
} from "common/hooks/Parameters/UniWebViewActions";
import { AgeGateV2, Loader } from "@neurosolutionsgroup/components";
import VisitChildSectionPrompt from "common/Components/VisitChildSectionPrompt/VisitChildSectionPrompt";
import ChildPinEntry from "./Components/ChildPinEntry";
import useUserProfile from "common/hooks/account/useUserProfile";
import ParentalCodeGate from "common/Components/ParentalCodeGate/ParentalCodeGate";
import {
  ConfigString,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import { useNavigate } from "react-router";
import { useAppDataContext } from "common/hooks/AppDataContext";
import { SAFE_AREAS } from "stylesheets";
import ChildIcons from "assets/child-icons";
import CoachAssets from "assets/coach";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import { sum } from "lodash";
import useChallenges from "common/hooks/challenges/useChallenges";

const MainPage = (): JSX.Element => {
  const { refreshAppData } = useAppDataContext();
  const {
    selectors: { totalChallengesToValidate },
  } = useChallenges();
  const {
    selectors: {
      childIds,
      childrenById,
      firstCreatedChild,
      hasChildStartPlaying,
      isFirstCreatedChild,
      childrenSortedByCreation,
      lastValidationSent,
      tasksToValidate,
    },
    actions: { updateSelectedChild },
  } = useChildren();
  const {
    selectors: { getObservationsToDo, recordsInitializationComplete },
  } = useFollowUp();
  const navigate = useNavigate();
  const {
    actions: { setParentSectionOpen },
  } = useNavigation();
  const { linkHandler, sendMessageToUnity } = useParameters();
  const { getRemoteConfigValue } = useRemoteConfig();
  const {
    selectors: { userProfile },
  } = useUserProfile();

  const [codeForgot, setCodeForgot] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [selectedChildId, setSelectedChildId] = useState<string>();
  const [showAgeGate, setShowAgeGate] = useState<boolean>(false);
  const [showPasswordGate, setShowPasswordGate] = useState<boolean>(false);
  const [visitChildSectionPromptOpen, setVisitChildSectionPromptOpen] =
    useState<boolean>(false);

  const userHasRegisteredPin = useMemo((): boolean => {
    if (userProfile?.masterSettings?.settings["nip"]) {
      return true;
    }
    return false;
  }, [userProfile]);

  const parentTasksToDo: number = useMemo(() => {
    if (!recordsInitializationComplete) {
      return 0;
    }

    const validations = sum(
      Object.values(tasksToValidate).map((tasks) => tasks.length)
    );

    let observations = 0;

    childIds.forEach((cid) => {
      observations += getObservationsToDo(cid).length;
    });

    return validations + observations + totalChallengesToValidate;
  }, [
    getObservationsToDo,
    childIds,
    tasksToValidate,
    recordsInitializationComplete,
    totalChallengesToValidate,
  ]);

  const onParentSectionClick = () => {
    userHasRegisteredPin ? setShowPasswordGate(true) : setShowAgeGate(true);
  };

  const onAgeGateCompleted = () => {
    setShowAgeGate(false);
    if (codeForgot) {
      linkHandler(
        ExternalLink.WebAppSettings,
        getRemoteConfigValue(ConfigString.WebAppSettings).asString()
      );
    } else {
      setShowPasswordGate(true);
    }
  };

  const onPasswordGateComplete = () => {
    setShowPasswordGate(false);
    updateSelectedChild();
    navigate("/");
    refreshAppData();
    setParentSectionOpen(true);
  };

  const onForgotCode = () => {
    setCodeForgot(true);
    setShowPasswordGate(false);
    setShowAgeGate(true);
  };

  const openChildSection = (childId: string, asParent: boolean) => {
    const elapsed = lastValidationSent
      ? Tools.Time.Dates.getTimeStamp() - lastValidationSent
      : undefined;

    if (elapsed && elapsed < 5) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        sendMessageToUnity(
          UniWebViewActions.openChildSection,
          `gamerChildId=${childId}&asParent=${asParent}`
        );
      }, (5 - elapsed) * 1000);
    } else {
      sendMessageToUnity(
        UniWebViewActions.openChildSection,
        `gamerChildId=${childId}&asParent=${asParent}`
      );
    }
  };

  const shouldOpenVisitChildSectionPrompt = (childId: string): boolean => {
    return (
      !!isFirstCreatedChild(childId) &&
      !hasChildStartPlaying(childId) &&
      !visitChildSectionPromptOpen
    );
  };

  const onChildClick = (childId: string) => {
    if (shouldOpenVisitChildSectionPrompt(childId)) {
      setVisitChildSectionPromptOpen(true);
    } else {
      if (childrenById[childId].nip) {
        setSelectedChildId(childId);
      } else {
        openChildSection(childId, false);
      }
    }
  };

  const onChildSectionSelected = (childId: string) => {
    if (shouldOpenVisitChildSectionPrompt(childId)) {
      setVisitChildSectionPromptOpen(true);
    } else {
      if (childrenById[childId].nip) {
        setSelectedChildId(childId);
      } else {
        openChildSection(childId, false);
      }
    }
  };

  const onChildPinSuccess = () => {
    if (selectedChildId) {
      openChildSection(selectedChildId, false);
      setSelectedChildId(undefined);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <MainPageComponent
        childList={childrenSortedByCreation}
        childIcons={ChildIcons}
        coachImage={CoachAssets.CoachFull}
        coachProfileImage={CoachAssets.CoachProfile}
        onParentSectionClick={onParentSectionClick}
        onChildClick={onChildClick}
        safeAreas={SAFE_AREAS}
        parentTasksToDo={parentTasksToDo}
        testIds={{
          parentSectionButton: "parent-section-button",
        }}
      />
      <AgeGateV2
        show={showAgeGate}
        onComplete={onAgeGateCompleted}
        onClose={() => setShowAgeGate(false)}
        safeAreas={SAFE_AREAS}
      />
      <ParentalCodeGate
        show={showPasswordGate}
        onComplete={onPasswordGateComplete}
        onClose={() => setShowPasswordGate(false)}
        onForgotCode={onForgotCode}
      />
      <ChildPinEntry
        onClose={() => setSelectedChildId(undefined)}
        onSuccess={onChildPinSuccess}
        childId={selectedChildId}
      />
      <VisitChildSectionPrompt
        open={visitChildSectionPromptOpen}
        onClose={() => setVisitChildSectionPromptOpen(false)}
        visitAsChild={() => {
          if (firstCreatedChild) {
            setVisitChildSectionPromptOpen(false);
            onChildSectionSelected(firstCreatedChild.id);
          }
        }}
        visitAsParent={() => {
          if (firstCreatedChild) {
            setVisitChildSectionPromptOpen(false);
            openChildSection(firstCreatedChild.id, true);
          }
        }}
      />
    </>
  );
};

export default MainPage;
