import { Badge, Grid, badgeClasses } from "@mui/material";
import { ChildIconsDefintion, GamerChild } from "@neurosolutionsgroup/models";
import { GenericPageProps } from "../../genericPageProps";
import ParentSectionBox from "./ParentSectionBox";
import { useTranslation } from "react-i18next";
import ChildSectionBox from "./ChildSectionBox";
import ChildButton from "./ChildButton";
import EmptyStateCard from "./EmptyStateCard";
import {
  BackgroundOpacity,
  BounceEffect,
  FullscreenPage,
} from "@neurosolutionsgroup/components";
import MainPageButton from "./MainPageButton";

interface MainPageProps extends GenericPageProps {
  childList: GamerChild[];
  childIcons: ChildIconsDefintion;
  onChildClick: (childId: string) => void;
  onParentSectionClick: VoidFunction;
  coachImage: string;
  coachProfileImage: string;
  parentTasksToDo: number;
  testIds?: {
    parentSectionButton?: string;
  };
}

const MainPageComponent = ({
  childList,
  childIcons,
  onChildClick,
  onParentSectionClick,
  coachImage,
  coachProfileImage,
  parentTasksToDo,
  testIds,
  safeAreas,
}: MainPageProps): JSX.Element => {
  const { t } = useTranslation();

  const activeChildLast = childList.filter((child) => !child.isDisabled);

  const showEmptyState = activeChildLast.length === 0;

  return (
    <FullscreenPage>
      <BackgroundOpacity active={showEmptyState} />
      <ParentSectionBox safeAreas={safeAreas}>
        <BounceEffect
          active={showEmptyState}
          sx={{
            zIndex: 10001,
          }}
        >
          <MainPageButton
            content={
              <Badge
                color="primary"
                badgeContent={parentTasksToDo}
                max={99}
                sx={{
                  [`& .${badgeClasses.badge}`]: {
                    transform: "scale(1) translate(100%,-40%)",
                  },
                }}
              >
                {t("navigation.parent.button")}
              </Badge>
            }
            imgSrc={coachProfileImage}
            onClick={onParentSectionClick}
            data-cy={testIds?.parentSectionButton}
          />
        </BounceEffect>
      </ParentSectionBox>
      <ChildSectionBox safeAreas={safeAreas}>
        <Grid
          container
          direction="column"
          spacing={3}
          justifyContent="center"
          alignItems="center"
          flexWrap="nowrap"
        >
          {activeChildLast.map((child) => (
            <Grid item key={child.id} flexBasis="3rem" flexShrink={1}>
              <ChildButton
                onClick={() => onChildClick(child.id)}
                childIcon={childIcons[child.icon]}
                childName={child.name}
              />
            </Grid>
          ))}
          {showEmptyState ? (
            <Grid
              item
              sx={{
                zIndex: 10001,
                alignSelf: "stretch",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <EmptyStateCard
                coachImage={coachImage}
                coachText={t("navigation.parent.empty")}
              />
            </Grid>
          ) : null}
        </Grid>
      </ChildSectionBox>
    </FullscreenPage>
  );
};

export default MainPageComponent;
