import { Badge, badgeClasses, SxProps, Theme } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { intervalToDuration, isBefore, isSameDay } from "date-fns";

interface WeekPickerDayProps extends PickersDayProps<Date> {
  selectedDate: Date | null;
  taggedDates?: Date[];
}

const WeekPickerDay = ({
  selectedDate,
  taggedDates,
  ...props
}: WeekPickerDayProps): JSX.Element => {
  const dateIsTagged = (dateArray: Date[], d: Date): boolean => {
    return dateArray.some((td) => isSameDay(d, td));
  };

  if (!(props.day instanceof Date) || !selectedDate) {
    return (
      <Badge
        variant="dot"
        color="secondary"
        sx={{ [`& .${badgeClasses.badge}`]: { top: "10px", right: "10px" } }}
        invisible={
          taggedDates
            ? !(
                dateIsTagged(taggedDates, props.day) &&
                !props.outsideCurrentMonth
              )
            : true
        }
        key={props.key}
      >
        <PickersDay {...props} />
      </Badge>
    );
  }

  const { selected, ...propsToPass } = props;

  const beforeSelectedDate = isBefore(props.day, selectedDate) || selected;

  let style = undefined;

  if (beforeSelectedDate) {
    const diff = intervalToDuration({
      start: props.day,
      end: selectedDate,
    });

    const dayDiff = diff.days;

    const monthDiff = diff.months;

    const baseHighlightStyle: SxProps<Theme> = (theme) => ({
      "borderWidth": "1px",
      "borderColor": theme.palette.secondary.main,
      "borderStyle": "solid",
      "boxSizing": "border-box",
      "marginX": 0,
      "width": "40px",
      "&:hover": {
        backgroundColor: theme.palette.secondary.main,
        color: "#fff",
      },
    });

    const startStyle: SxProps<Theme> = (theme) => ({
      ...baseHighlightStyle(theme),
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      borderRight: "none",
    });

    const middleStyle: SxProps<Theme> = (theme) => ({
      ...baseHighlightStyle(theme),
      borderLeft: "none",
      borderRight: "none",
      borderRadius: 0,
    });

    const endStyle: SxProps<Theme> = (theme) => ({
      ...baseHighlightStyle(theme),
      borderLeft: "none",
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    });

    if (dayDiff !== undefined && monthDiff === 0) {
      if (dayDiff === 7 - 1) {
        style = startStyle;
      } else if (dayDiff < 7 - 1 && dayDiff > 0) {
        style = middleStyle;
      } else if (selected) {
        style = endStyle;
      }
    }
  }

  return (
    <Badge
      variant="dot"
      color="secondary"
      sx={{ [`& .${badgeClasses.badge}`]: { top: "10px", right: "10px" } }}
      invisible={
        taggedDates
          ? !(
              dateIsTagged(taggedDates, props.day) &&
              !propsToPass.outsideCurrentMonth
            )
          : true
      }
      key={propsToPass.key}
    >
      <PickersDay
        sx={style}
        {...propsToPass}
        // onClick={
        //   props.selected
        //     ? () => onDatePickerChange(day, true)
        //     : undefined
        // }
      />
    </Badge>
  );
};

export default WeekPickerDay;
