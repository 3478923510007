import { CloseablePage } from "common/Components";
import React, { useCallback, useMemo, useRef, useState } from "react";
import PrescriptionForm, {
  PrescriptionFormState,
} from "../prescriptionForm/PrescriptionForm";
import { Prescription } from "@neurosolutionsgroup/models";
import SideEffectForm, {
  SideEffectFormState,
} from "../SideEffectForm/SideEffectForm";
import ConfirmationForm from "./ConfirmationForm/ConfirmationForm";
import { useTranslation } from "react-i18next";
import useChildren from "common/hooks/children/useChildren";
import { Dialogs, Icons } from "@neurosolutionsgroup/components";
import { Typography } from "@mui/material";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import { FTUEFlow, useFTUE } from "@neurosolutionsgroup/webviews-ftue";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import DialogAssets from "assets/dialogs";

const ROOT_CLASS = "follow-up-edit";
export interface FollowUpCreationProps {
  isOpen: boolean;
  onClose: () => void;
}

export enum CreationState {
  Prescription,
  SecondPrescription,
  SideEffect,
  Confirmation,
}

const FollowUpCreation: React.FC<FollowUpCreationProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const {
    selectors: { selectedChild, childrenById, medicalChildrenByGamerId },
  } = useChildren();
  const {
    actions: { createFollowUp },
    selectors: { getActiveFollowedSideEffects },
  } = useFollowUp();

  const {
    actions: { canDisplayFlow },
  } = useFTUE();
  const { handleUnknownError } = useErrorsContext();

  const scrollRef = useRef<HTMLDivElement>(null);

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showAnotherMedDialog, setShowAnotherMedDialog] = useState(false);
  const [creationState, setCreationState] = useState<CreationState>(
    CreationState.Prescription
  );
  const [prescriptions, setPrescriptions] = useState<Prescription[]>([]);
  const [selectedSideEffects, setSelectedSideEffects] = useState<string[]>(
    getActiveFollowedSideEffects().map((fse) => {
      return fse.sideEffectId;
    })
  );
  const [takesOtherMedication, setTakesOtherMedication] =
    useState<boolean>(false);

  const addPrescription = (p: Prescription) => {
    const temp: Prescription[] = [...prescriptions];
    temp.push(p);
    setPrescriptions(temp);
  };

  const medicalChildId = useMemo(() => {
    return selectedChild
      ? medicalChildrenByGamerId[selectedChild]?.medicalChildId
      : null;
  }, [selectedChild]);

  const onSubmit = async () => {
    if (selectedChild) {
      try {
        await createFollowUp(selectedChild, prescriptions, selectedSideEffects);
        onClose();
      } catch (err: unknown) {
        handleUnknownError(err);
      }
    }
  };

  const onNext = () => {
    if (creationState === CreationState.Prescription) {
      if (
        takesOtherMedication === true &&
        !canDisplayFlow(FTUEFlow.Prescriptions)
      ) {
        if (prescriptions.length !== 2) {
          setShowAnotherMedDialog(true);
        } else {
          setCreationState(CreationState.SecondPrescription);
        }
      } else {
        setCreationState(CreationState.SideEffect);
      }
    } else if (creationState === CreationState.SecondPrescription) {
      setCreationState(CreationState.SideEffect);
    } else if (creationState === CreationState.SideEffect) {
      setCreationState(CreationState.Confirmation);
    }

    scrollRef.current?.scrollTo(0, 0);
  };

  const firstPrescription = useCallback(
    (): Prescription | undefined =>
      prescriptions.length >= 1 ? prescriptions[0] : undefined,
    [prescriptions]
  );

  const secondPrescription = useCallback(
    (): Prescription | undefined =>
      prescriptions.length === 2 ? prescriptions[1] : undefined,
    [prescriptions]
  );

  const formToShow = () => {
    if (medicalChildId) {
      switch (creationState) {
        case CreationState.Prescription:
          return (
            <PrescriptionForm
              onCancel={() => setShowCancelDialog(true)}
              onSubmit={(p) => {
                if (firstPrescription()) {
                  setPrescriptions((current) => {
                    const clone = [...current];

                    clone[0] = p;

                    return clone;
                  });
                } else {
                  addPrescription(p);
                }
                onNext();
              }}
              takesOtherMedication={takesOtherMedication}
              setTakesOtherMedication={setTakesOtherMedication}
              prescription={firstPrescription()}
              medicalChildId={medicalChildId}
              formState={PrescriptionFormState.creationCompleteFirst}
            />
          );
        case CreationState.SecondPrescription:
          return (
            <PrescriptionForm
              onCancel={() => setShowCancelDialog(true)}
              onSubmit={(p) => {
                if (secondPrescription()) {
                  setPrescriptions((current) => {
                    const clone = [...current];

                    clone[1] = p;

                    return clone;
                  });
                } else {
                  addPrescription(p);
                }
                onNext();
              }}
              takesOtherMedication={takesOtherMedication}
              setTakesOtherMedication={setTakesOtherMedication}
              prescription={secondPrescription()}
              medicalChildId={medicalChildId}
              formState={PrescriptionFormState.creationCompleteSecond}
            />
          );
        case CreationState.SideEffect:
          return (
            <SideEffectForm
              selectedSideEffects={selectedSideEffects}
              setSelectedSideEffects={setSelectedSideEffects}
              onSubmit={onNext}
              onCancel={() => setShowCancelDialog(true)}
              formState={SideEffectFormState.creation}
              onBack={onBackClick}
            />
          );
        case CreationState.Confirmation:
          return (
            <ConfirmationForm
              childName={
                selectedChild && childrenById[selectedChild]
                  ? childrenById[selectedChild].name
                  : ""
              }
              medicalChildId={medicalChildId}
              prescriptions={prescriptions}
              setPrescriptions={setPrescriptions}
              sideEffects={selectedSideEffects}
              setSideEffects={setSelectedSideEffects}
              onCancel={() => setShowCancelDialog(true)}
              onSubmit={onSubmit}
              onBack={onBackClick}
              setTakesAnotherMedication={setTakesOtherMedication}
            />
          );
      }
    } else {
      return null;
    }
  };

  const pageBackElement = useCallback((): JSX.Element => {
    switch (creationState) {
      case CreationState.SecondPrescription:
        return (
          <>
            <Icons.ArrowIcon arrowDirection="left" color="#fff" />
            {t("medication.followUp.edit.navigation.back.firstMedication")}
          </>
        );
      case CreationState.SideEffect:
        return (
          <>
            <Icons.ArrowIcon arrowDirection="left" color="#fff" />
            {t("medication.followUp.edit.navigation.back.medication")}
          </>
        );
      case CreationState.Confirmation:
        return (
          <>
            <Icons.ArrowIcon arrowDirection="left" color="#fff" />
            {t("medication.followUp.edit.navigation.back.sideEffects")}
          </>
        );
      default:
        return <></>;
    }
  }, [creationState]);

  const pageTitle = useCallback((): string => {
    switch (creationState) {
      case CreationState.Prescription:
      case CreationState.SideEffect:
        return t("medication.followUp.edit.title");
      case CreationState.SecondPrescription:
        return t("medication.followUp.edit.takesOtherMedication.page.title");
      case CreationState.Confirmation:
        return t("medication.followUp.edit.confirm");
      default:
        return "title not found";
    }
  }, [creationState]);

  const onBackClick = () => {
    switch (creationState) {
      case CreationState.SecondPrescription:
        setCreationState(CreationState.Prescription);
        break;
      case CreationState.SideEffect:
        setCreationState(CreationState.Prescription);
        break;
      case CreationState.Confirmation:
        setCreationState(CreationState.SideEffect);
        break;
    }
  };

  return (
    <CloseablePage
      isOpen={isOpen}
      onClose={onBackClick}
      closeElement={pageBackElement()}
      className={ROOT_CLASS}
      header={<h2 className={ROOT_CLASS + "__title"}>{pageTitle()}</h2>}
      ref={scrollRef}
    >
      {formToShow()}
      <Dialogs.AlertDialog
        open={showAnotherMedDialog}
        onClose={() => setShowAnotherMedDialog(false)}
        title={t("medication.followUp.edit.takesOtherMedication.dialog.title")}
        text={
          <Typography>
            {t("medication.followUp.edit.takesOtherMedication.dialog.text")}
          </Typography>
        }
        positiveAction={{
          text: t("general.actions.add"),
          action: () => {
            setCreationState(CreationState.SecondPrescription);
            setShowAnotherMedDialog(false);
          },
        }}
        negativeAction={{
          text: t("general.actions.no"),
          action: () => {
            setPrescriptions((current) => [
              { ...current[0], takesOtherMedication: true },
            ]);
            setCreationState(CreationState.SideEffect);
            setShowAnotherMedDialog(false);
          },
        }}
      />

      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        title={t("medication.followUp.edit.cancel.title")}
        text={t("medication.followUp.edit.cancel.text")}
        onPositiveAction={() => {
          setShowCancelDialog(false);
          onClose();
        }}
        onNegativeAction={() => {
          setShowCancelDialog(false);
        }}
        imgSrc={DialogAssets.CancelTag}
      />
    </CloseablePage>
  );
};

export default FollowUpCreation;
