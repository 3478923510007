import { Box, ButtonBase, useTheme } from "@mui/material";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import Icons from "../../Icons";
import { IconManager } from "@neurosolutionsgroup/tools";

interface TaskIconPickerDisplayProps {
  icon: number;
  iconManager: IconManager;
  onClick: VoidFunction;
  showEmptyState: boolean;
  emptyStateImage?: string;
}

const TaskIconPickerDisplay = ({
  icon,
  iconManager,
  onClick,
  showEmptyState,
  emptyStateImage,
}: TaskIconPickerDisplayProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      data-cy="add-icon-button"
      onClick={onClick}
      sx={(theme) => ({
        ...SharedStyles.Layout.square("8em"),
        "borderRadius": "1em",
        "zIndex": 0,
        ...SharedStyles.Layout.flexCenter,
        "& .icon-picker__add-container": {
          ...SharedStyles.Layout.square("8em"),
          zIndex: 1,
          borderRadius: "1em",
          backgroundColor: icon ? "#fff" : theme.palette.secondary.main,
          ...SharedStyles.Layout.flexCenter,
        },
        "& .icon": {
          ...SharedStyles.Layout.square("6em"),
          path: {
            fill: "#fff",
          },
        },
        "& .icon-picker__icon": {
          ...SharedStyles.Layout.square("7em"),
        },
        "&:after":
          !icon && showEmptyState && emptyStateImage
            ? {
                content: "''",
                backgroundImage: `url(${emptyStateImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
                position: "absolute",
                zIndex: -1,
                ...SharedStyles.Layout.square("80vw"),
                maxWidth: "350px",
                maxHeight: "350px",
                top: "50%",
              }
            : { display: "none" },
      })}
    >
      <Box className="icon-picker__add-container">
        {icon ? (
          <img
            key={icon}
            className="icon-picker__icon"
            src={iconManager.getIcon(icon)}
            alt={`Task Icon ${icon}`}
          />
        ) : (
          <Icons.AddIcon color={palette.primary.main} />
        )}
      </Box>
    </ButtonBase>
  );
};

export default TaskIconPickerDisplay;
