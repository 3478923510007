import { Button, CircularProgress, Grid } from "@mui/material";
import React, { MouseEventHandler } from "react";

export interface AuthContinueButtonProps {
  text: string;
  disabled: boolean;
  loading: boolean;
  onContinue: VoidFunction;
  dataCy?: string;
}

const AuthContinueButton: React.FC<AuthContinueButtonProps> = ({
  text,
  disabled,
  loading,
  onContinue,
  dataCy = "submit-button-signup1",
}) => {
  const onClick: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault();
    onContinue();
  };

  return (
    <Grid
      item
      container
      display={"flex"}
      justifyContent={"center"}
      width={"100%"}
    >
      <Button
        data-cy={dataCy}
        onClick={onClick}
        color="secondary"
        variant="contained"
        disabled={disabled}
        type="submit"
      >
        {loading ? (
          <CircularProgress
            size="1.5rem"
            style={{
              color: "#fff",
            }}
          />
        ) : (
          text
        )}
      </Button>
    </Grid>
  );
};
export default AuthContinueButton;
