import { Box, CircularProgress } from "@mui/material";
import { ExpandableCard } from "@neurosolutionsgroup/components";
import {
  Challenge,
  ChallengeHistory,
  FirestoreChallenge,
} from "@neurosolutionsgroup/models";
import React, { useEffect, useState } from "react";
import useChallenges from "common/hooks/challenges/useChallenges";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import ChallengeAccordionDetails from "./ChallengeAccordionDetails";
import ChallengeAccordionSummary from "./ChallengeAccodionSummary";

export interface ChallengeCardProps {
  challenge: Challenge;
  onChallengeValidationComplete: (challenge: FirestoreChallenge) => void;
}

const ChallengeValidationCard: React.FC<ChallengeCardProps> = ({
  challenge,
  onChallengeValidationComplete,
}) => {
  const {
    actions: { validateChallenges },
  } = useChallenges();
  const { handleUnknownError } = useErrorsContext();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChallengeValidated = async (
    challengeId: string,
    history: ChallengeHistory[]
  ) => {
    try {
      setLoading(true);
      const challenge = await validateChallenges(challengeId, history);
      onChallengeValidationComplete(challenge);
    } catch (err) {
      handleUnknownError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setOpen(false);
    }
  }, [loading]);

  return (
    <>
      <ExpandableCard
        open={open}
        setOpen={setOpen}
        summaryComponent={
          loading ? (
            <Box
              height="4em"
              display="flex"
              alignItems="center"
              justifyContent="center"
              flexGrow={1}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <ChallengeAccordionSummary challenge={challenge} />
          )
        }
        detailsComponent={
          <ChallengeAccordionDetails
            challenge={challenge}
            onChallengeValidated={onChallengeValidated}
          />
        }
      />
    </>
  );
};

export default ChallengeValidationCard;
