import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const ChatIcon = ({
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon {...props} classModifier="chat">
      <path
        d="M 19.101899,12.866861 V 3.8142141 c 0,-0.9026937 -0.638311,-1.9277507 -1.754771,-1.9277507 H 2.6529075 c -1.1164479,0 -1.7548064,1.025057 -1.7548064,1.9277507 v 9.0526469 c 0,0.902712 0.6383585,1.927745 1.7548064,1.927745 h 8.3059655 l 2.618564,2.893503 c 0.418456,0.4636 1.047306,0.549219 1.547968,0.284758 0.467432,-0.246918 0.734767,-0.741233 0.734767,-1.265425 v -1.912836 h 1.486956 c 1.11646,0 1.754771,-1.025033 1.754771,-1.927745 z"
        stroke={color}
        strokeWidth="1.7962"
        id="path1"
      />
    </BaseIcon>
  );
};

export default ChatIcon;
