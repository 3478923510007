import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import DashboardPrescription, {
  DashboardPrescriptionsProgress,
  DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL,
} from "..";
import { FTUEFlow } from "models";
import { FTUEHint } from "components";
import useFTUE from "useFTUE";

const DashboardPrescriptionsSideEffectsTimePeriod: React.FC<
  PropsWithChildren
> = (props) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setDashboardPrescriptionsProgress },
  } = useFTUE();

  const dashboardPrescriptionsProgress =
    progress[FTUEFlow.DashboardPrescriptions].progress;

  const onNext = () => {
    setDashboardPrescriptionsProgress(
      DashboardPrescriptionsProgress.SideEffectsTimePeriod
    );
  };

  const onQuit = () => {
    setDashboardPrescriptionsProgress(
      DashboardPrescriptionsProgress.DashboardPrescriptionsFTUEFinished
    );
  };

  return (
    <FTUEHint
      condition={
        dashboardPrescriptionsProgress ===
          DashboardPrescriptionsProgress.Reminder &&
        canDisplayFlow(FTUEFlow.DashboardPrescriptions)
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.dashboardPrescriptions.sideEffectsTimePeriod.text")}
      floating={true}
      hintAfter={true}
      scrollPosition="center"
      currentStep={DashboardPrescription.Logic.calculateProgress(
        DashboardPrescriptionsProgress.Reminder
      )}
      maxStep={DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default DashboardPrescriptionsSideEffectsTimePeriod;
