export interface Vesa {
  id: string;
  adviceType: AdviceType;
  action?: VesaAction;
}

export enum VesaActionType {
  link = "link",
}

export enum AdviceType {
  parentPositiveReinforcement = 1,
  referenceToParentalGuide = 2,
  routineAdvice = 3,
  childPositiveReinforcement = 4,
}

interface VesaAction {
  type: VesaActionType;
  url?: string;
  isExternal?: boolean;
}

const VESA_1: Vesa = {
  id: "VESA_1",
  adviceType: 1,
};

const VESA_2: Vesa = {
  id: "VESA_2",
  adviceType: 2,
  action: {
    type: VesaActionType.link,
    url: "/guide#cat-1",
  },
};

const VESA_3: Vesa = {
  id: "VESA_3",
  adviceType: 3,
};
const VESA_4: Vesa = {
  id: "VESA_4",
  adviceType: 4,
};
const VESA_5: Vesa = {
  id: "VESA_5",
  adviceType: 1,
};

const VESA_6: Vesa = {
  id: "VESA_6",
  adviceType: 2,
  action: {
    type: VesaActionType.link,
    url: "/guide#sub-2",
  },
};

const VESA_7: Vesa = {
  id: "VESA_7",
  adviceType: 3,
};

const VESA_8: Vesa = {
  id: "VESA_8",
  adviceType: 4,
};

const VESA_9: Vesa = {
  id: "VESA_9",
  adviceType: 1,
};
const VESA_10: Vesa = {
  id: "VESA_10",
  adviceType: 2,
  action: {
    type: VesaActionType.link,
    url: "/guide#sub-5",
  },
};
const VESA_11: Vesa = {
  id: "VESA_11",
  adviceType: 3,
  action: {
    type: VesaActionType.link,
    url: "/dashboard",
  },
};

const VESA_12: Vesa = {
  id: "VESA_12",
  adviceType: 4,
};
const VESA_13: Vesa = {
  id: "VESA_13",
  adviceType: 2,
  action: {
    type: VesaActionType.link,
    url: "/guide#sub-4",
  },
};
const VESA_14: Vesa = {
  id: "VESA_14",
  adviceType: 3,
  action: {
    type: VesaActionType.link,
    url: "/dashboard#category-details",
  },
};

const VESA_15: Vesa = {
  id: "VESA_15",
  adviceType: 1,
};
const VESA_16: Vesa = {
  id: "VESA_16",
  adviceType: 3,
  action: {
    type: VesaActionType.link,
    url: "/journal",
  },
};
const VESA_17: Vesa = {
  id: "VESA_17",
  adviceType: 2,
  action: {
    type: VesaActionType.link,
    url: "/guide#cat-2",
  },
};

const VESA_18: Vesa = {
  id: "VESA_18",
  adviceType: 1,
};

const VESA_19: Vesa = {
  id: "VESA_19",
  adviceType: 3,
};
const VESA_20: Vesa = {
  id: "VESA_20",
  adviceType: 2,
  action: {
    type: VesaActionType.link,
    url: "/guide#autonomy",
  },
};

const messages = [
  VESA_1,
  VESA_2,
  VESA_3,
  VESA_4,
  VESA_5,
  VESA_6,
  VESA_7,
  VESA_8,
  VESA_9,
  VESA_10,
  VESA_11,
  VESA_12,
  VESA_13,
  VESA_14,
  VESA_15,
  VESA_16,
  VESA_17,
  VESA_18,
  VESA_19,
  VESA_20,
];

export default messages;
