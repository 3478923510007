import React from "react";
import { Loader as NeuroLoader } from "@neurosolutionsgroup/components";
import { createPortal } from "react-dom";

const loaderRoot = document.getElementById("loader");

const Loader: React.FC = () => {
  return (
    loaderRoot &&
    createPortal(
      <>
        <NeuroLoader />
      </>,
      loaderRoot
    )
  );
};

export default Loader;
