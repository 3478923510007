export enum ValidationProgress {
  None = "none",
  Later = "FTUE_Validation_Later",
  Intro = "FTUE_Validation_Intro",
  Display = "FTUE_Validation_Display",
  Prefill = "FTUE_Validation_Prefill",
  Validate = "FTUE_Validation_Validate",
  ValidationFTUEFinished = "FTUE_Validation_Finished",
}

export const VALIDATION_PROGRESS_TOTAL = 4;
