import { Box, Typography } from "@mui/material";
import { InfoDrawer } from "@neurosolutionsgroup/components";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";
import Prescriptions, {
  PrescriptionsProgress,
  PRESCRIPTIONS_PROGRESS_TOTAL,
} from "..";
import useFTUE from "useFTUE";
import { SafeAreas } from "@neurosolutionsgroup/models";

export interface PrescriptionEndProps extends PropsWithChildren {
  safeAreas: SafeAreas;
  onNext: VoidFunction;
}

const PrescriptionEnd = (props: PrescriptionEndProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { assets, progress },
    actions: { canDisplayFlow, setPrescriptionsProgress },
  } = useFTUE();

  const prescriptionsProgress = progress[FTUEFlow.Prescriptions].progress;

  const onNext = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Finished);
    props.onNext();
  };

  const onQuit = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Finished, true);
  };

  return (
    <FTUEHint
      condition={
        prescriptionsProgress === PrescriptionsProgress.Confirmation &&
        canDisplayFlow(FTUEFlow.Prescriptions)
      }
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      currentStep={Prescriptions.Logic.calculateProgress(
        PrescriptionsProgress.Confirmation
      )}
      maxStep={PRESCRIPTIONS_PROGRESS_TOTAL}
    >
      {props.children}
      {prescriptionsProgress === PrescriptionsProgress.Confirmation &&
      canDisplayFlow(FTUEFlow.Prescriptions) ? (
        <InfoDrawer
          sx={{
            "& .MuiBackdrop-root": {
              display: "none",
            },
          }}
          open={true}
          onClose={onNext}
          title={t("ftue.prescription.end.title")}
          text={
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                img: {
                  width: "80vw",
                  maxHeight: "300px",
                  objectFit: "contain",
                },
              }}
            >
              <img src={assets.prescriptionGraph} alt="" />
              <Typography my={2} mx={1}>
                <Trans i18nKey="ftue.prescription.end.text" />
              </Typography>
            </Box>
          }
          infoIconImage={assets.infoCoachSmall}
          coachImage={null}
          safeAreas={props.safeAreas}
        />
      ) : null}
    </FTUEHint>
  );
};

export default PrescriptionEnd;
