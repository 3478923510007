import { Box, Typography } from "@mui/material";
import {
  InformationButton,
  InputError,
  InputLabel,
  SelectInput,
  TextInput,
} from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { format, Locale, nextMonday, startOfISOWeek } from "date-fns";
import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

interface ChallengeConfigSectionProps {
  frequency: number;
  setFrequency: React.Dispatch<React.SetStateAction<number>>;
  duration: number;
  setDuration: React.Dispatch<React.SetStateAction<number>>;
  startDateOption: "thisWeek" | "nextWeek" | null;
  setStartDateOption: React.Dispatch<
    React.SetStateAction<"thisWeek" | "nextWeek" | null>
  >;
  rewardIndex: number | null;
  setRewardIndex: React.Dispatch<React.SetStateAction<number | null>>;
  reward: string;
  setReward: React.Dispatch<React.SetStateAction<string>>;
  coachInfoIconImgSrc: string;
  frequencyInfoImgSrc: string;
  rewardInfoImgSrc: string;
  safeAreas: SafeAreas;
  dateLocale: Locale;
}

const ChallengeConfigSection = ({
  frequency,
  setFrequency,
  duration,
  setDuration,
  startDateOption,
  setStartDateOption,
  rewardIndex,
  setRewardIndex,
  reward,
  setReward,
  coachInfoIconImgSrc,
  frequencyInfoImgSrc,
  rewardInfoImgSrc,
  safeAreas,
  dateLocale,
}: ChallengeConfigSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const rewardOptions = [
    {
      value: 0,
      label: t("routine.challenge.rewards.0"),
    },
    {
      value: 1,
      label: t("routine.challenge.rewards.1"),
    },
    {
      value: 2,
      label: t("routine.challenge.rewards.2"),
    },
    {
      value: 3,
      label: t("routine.challenge.rewards.3"),
    },
    {
      value: 4,
      label: t("routine.challenge.rewards.4"),
    },
    {
      value: 5,
      label: t("routine.challenge.rewards.5"),
    },
    {
      value: 6,
      label: t("routine.challenge.rewards.6"),
    },
    {
      value: 7,
      label: t("routine.challenge.rewards.7"),
    },
    {
      value: 8,
      label: t("routine.challenge.rewards.8"),
    },
    {
      value: 9,
      label: t("routine.challenge.rewards.9"),
    },
  ];

  const MAX_REWARD_LENGTH = 150;

  const rewardError = useMemo(() => reward.length > 150, [reward]);

  useEffect(() => {
    if (rewardIndex !== null && rewardIndex !== 0) {
      setReward(rewardOptions[rewardIndex].label);
    }
  }, [rewardIndex]);

  const onRewardSelected = (index: number) => {
    if (index === 0 && rewardIndex !== 0) {
      setReward("");
    }

    setRewardIndex(index);
  };

  return (
    <>
      <Box mb={1.5}>
        <InputLabel>
          {t("routine.challenge.inputs.frequency.label")}{" "}
          <InformationButton
            infoTitle={t("routine.challenge.inputs.frequency.label")}
            infoText={
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={2}
              >
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    img: {
                      width: "60%",
                      maxWidth: "300px",
                      objectFit: "contain",
                    },
                  }}
                >
                  <img src={frequencyInfoImgSrc} alt="" />
                </Box>
                <Typography fontSize="0.9rem">
                  <Trans
                    i18nKey={"routine.challenge.inputs.frequency.info.text"}
                  />
                </Typography>
              </Box>
            }
            infoIconImage={coachInfoIconImgSrc}
            coachImage={null}
            inline
          />
        </InputLabel>

        <SelectInput
          value={frequency}
          options={[1, 2, 3, 4, 5].map((freq) => ({
            value: freq,
            label: t("routine.challenge.inputs.frequency.option", {
              count: freq,
            }),
          }))}
          onChange={(o) => setFrequency(o.value)}
          label={t("routine.challenge.inputs.frequency.label")}
          placeholder={t("routine.challenge.inputs.frequency.label")}
          fullWidth
          safeAreas={safeAreas}
          data-cy="challenge-frequency-input"
        />
      </Box>

      <Box mb={1.5}>
        <InputLabel>{t("routine.challenge.inputs.duration.label")}</InputLabel>

        <SelectInput
          value={duration}
          options={[1, 2, 3, 4].map((dur) => ({
            value: dur,
            label: t("routine.challenge.inputs.duration.option", {
              count: dur,
            }),
          }))}
          onChange={(o) => setDuration(o.value)}
          label={t("routine.challenge.inputs.duration.label")}
          placeholder={t("routine.challenge.inputs.duration.label")}
          fullWidth
          safeAreas={safeAreas}
          data-cy="challenge-duration-input"
        />
      </Box>

      <Box mb={1.5}>
        <InputLabel>{t("routine.challenge.inputs.startDate.label")}</InputLabel>

        <SelectInput
          value={startDateOption}
          options={[
            {
              value: "thisWeek",
              label: `${t(
                "routine.challenge.inputs.startDate.thisWeek"
              )} (${format(startOfISOWeek(new Date()), "d MMM yyyy", {
                locale: dateLocale,
              })})`,
            },
            {
              value: "nextWeek",
              label: `${t(
                "routine.challenge.inputs.startDate.nextWeek"
              )} (${format(nextMonday(new Date()), "d MMM yyyy", {
                locale: dateLocale,
              })})`,
            },
          ]}
          onChange={(o) => setStartDateOption(o.value)}
          label={t("routine.challenge.inputs.startDate.label")}
          placeholder={t("routine.challenge.inputs.startDate.placeholder")}
          fullWidth
          safeAreas={safeAreas}
          data-cy="challenge-startdate-input"
        />
      </Box>

      <Box mb={1.5}>
        <InputLabel>
          {t("routine.challenge.inputs.reward.label")}{" "}
          <InformationButton
            infoTitle={t("routine.challenge.inputs.reward.label")}
            infoText={
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mb={2}
              >
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    img: {
                      width: "60%",
                      maxWidth: "300px",
                      objectFit: "contain",
                    },
                  }}
                >
                  <img src={rewardInfoImgSrc} alt="" />
                </Box>
                <Typography fontSize="0.9rem">
                  <Trans
                    i18nKey={"routine.challenge.inputs.reward.info.text"}
                  />
                </Typography>
              </Box>
            }
            infoIconImage={coachInfoIconImgSrc}
            coachImage={null}
            inline
          />
        </InputLabel>

        <SelectInput
          label={t("routine.challenge.inputs.reward.label")}
          placeholder={t("routine.challenge.inputs.reward.placeholderSelect")}
          options={rewardOptions}
          value={rewardIndex}
          onChange={(o) => onRewardSelected(o.value)}
          fullWidth
          truncateOnOverflow
          safeAreas={safeAreas}
          data-cy="challenge-reward-select"
        />

        {rewardIndex === 0 ? (
          <Box mt={2}>
            <TextInput
              placeholder={t("routine.challenge.inputs.reward.placeholder")}
              value={reward}
              onChange={(e) => setReward(e.currentTarget.value)}
              error={rewardError}
              multiline
              fullWidth
              minRows={1}
              data-cy="challenge-edition-reward-input"
            />
            {rewardError ? (
              <InputError>
                {t("general.charactersMax", { count: MAX_REWARD_LENGTH })}
              </InputError>
            ) : null}
          </Box>
        ) : null}
      </Box>
    </>
  );
};

export default ChallengeConfigSection;
