import React from "react";
import clsx from "clsx";

export const DEFAULT_ICON_COLOR = "#000";

export interface IconProps {
  "onClick"?: VoidFunction;
  "color"?: string;
  "className"?: string;
  "children"?: JSX.Element | JSX.Element[];
  "data-cy"?: string;
}

export interface BaseIconProps extends IconProps {
  classModifier: string;
  stroke?: string;
  className?: string;
}

const BaseIcon = ({
  onClick,
  classModifier,
  stroke,
  className,
  ...props
}: BaseIconProps): JSX.Element => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke={stroke}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className={clsx(
        "icon",
        "icon-" + classModifier,
        {
          "icon--clickable": onClick,
        },
        className
      )}
      data-cy={props["data-cy"]}
    >
      {props.children}
    </svg>
  );
};

export default BaseIcon;
