import { Grid, Typography, Box } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import React from "react";

export interface NotificationButtonProps {
  label: string;
  state: boolean;
  setState: (value: boolean) => void;
}

const NotificationButton: React.FC<NotificationButtonProps> = ({
  label,
  state,
  setState,
}) => {
  return (
    <Grid container sx={{ width: "100%", marginY: 1 }}>
      <Grid sx={{ width: "100%" }}>
        <label>
          <Grid container justifyContent="space-between">
            <Box sx={{ width: "80%" }}>
              <Typography>{label}</Typography>
            </Box>

            <Box
              sx={(theme) => ({
                borderColor: theme.palette.secondary.main,
                border: 2,
                borderStyle: "solid",
                borderRadius: 2,
                height: "1.5rem",
                width: "1.5rem",
                justifyItems: "center",
                alignItems: "center",
                svg: {
                  height: "1.5rem",
                  width: "1.5rem",
                },
              })}
            >
              {state ? (
                <Icons.CheckMarkIcon color="#65C466" checkStyle="kairos" />
              ) : null}
            </Box>
            <Box
              sx={{
                display: "none",
              }}
            >
              <input
                type="checkbox"
                value={state.toString()}
                checked={state}
                onChange={() => setState(!state)}
              />
            </Box>
          </Grid>
        </label>
      </Grid>
    </Grid>
  );
};
export default NotificationButton;
