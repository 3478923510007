import { Box, Typography } from "@mui/material";
import {
  BackgroundHexagons,
  calculateStickyButtonPagePadding,
  FullscreenPage,
  PageContentContainer,
  StickyButtons,
} from "@neurosolutionsgroup/components";
import { Language, SafeAreas } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";

interface LandingPageProps {
  language: Language;
  logoImgSrc: Record<Language, string>;
  mainImgSrc: string;
  onCreateAccount: VoidFunction;
  onLogin: VoidFunction;
  open: boolean;
  productName: string;
  safeAreas: SafeAreas;
}

const LandingPage = ({
  language,
  logoImgSrc,
  mainImgSrc,
  onCreateAccount,
  onLogin,
  open,
  productName,
  safeAreas,
}: LandingPageProps): JSX.Element | null => {
  const { t } = useTranslation();

  return open ? (
    <FullscreenPage
      sx={{
        backgroundColor: "primary.main",
      }}
    >
      <BackgroundHexagons />

      <PageContentContainer
        sx={(theme) => ({
          position: "relative",
          zIndex: 1,
          justifyContent: "space-around",
          paddingTop: `max(calc(${safeAreas.top} + ${theme.spacing(
            2
          )}), ${theme.spacing(4)})`,
          paddingBottom: `calc(${calculateStickyButtonPagePadding(
            safeAreas,
            true
          )} + 18px)`,
        })}
      >
        <Box
          sx={{
            marginX: "auto",
            img: {
              width: "300px",
              maxWidth: "45vw",
              maxHeight: "150px",
              objectFit: "contain",
            },
          }}
        >
          <img src={logoImgSrc[language]} alt={productName} />
        </Box>

        <Box
          sx={{
            marginX: "auto",
            flexShrink: 1,
            flexGrow: 1,
            maxHeight: "40vh",
            img: {
              width: "90vw",
              maxWidth: "450px",
              objectFit: "contain",
              height: 0,
              minHeight: "100%",
            },
          }}
        >
          <img src={mainImgSrc} alt="" />
        </Box>

        <Box mx={2} display="flex" justifyContent="center">
          <Typography
            textAlign="left"
            color="primary.contrastText"
            fontWeight={800}
            fontSize={language === "en" ? "2rem" : "1.8rem"}
            maxWidth="425px"
            sx={(theme) => ({
              fontSize: language === "en" ? "2rem" : "1.8rem",
              [theme.breakpoints.not("xs")]: {
                fontSize: language === "en" ? "2.5rem" : "2.3rem",
              },
            })}
          >
            {t("auth.signupOrLogin.marketingText")}
          </Typography>
        </Box>
      </PageContentContainer>

      <StickyButtons
        onConfirm={onCreateAccount}
        confirmColor="success"
        confirmText={t("auth.signupOrLogin.buttons.create", {
          productName,
        })}
        onCancel={onLogin}
        cancelColor="success"
        cancelVariant="blank"
        cancelText={t("auth.signupOrLogin.buttons.login", {
          productName,
        })}
        vertical
        safeAreas={safeAreas}
        data-cy="landing-page-actions"
      />
    </FullscreenPage>
  ) : null;
};

export default LandingPage;
