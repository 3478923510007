import { Box, ButtonBase, Grid } from "@mui/material";
import { PinIconsDefintion } from "@neurosolutionsgroup/models";
import Icons from "../../../Icons";

interface IconPinDisplayProps {
  clickable: boolean;
  clearable: boolean;
  value: string;
  setValue: (value: string) => void;
  pinIcons: PinIconsDefintion;
  onClick?: VoidFunction;
  error?: boolean;
}

const IconPinDisplay = ({
  clickable,
  clearable,
  value,
  setValue,
  pinIcons,
  onClick,
  error,
}: IconPinDisplayProps): JSX.Element => {
  return (
    <Box display="flex">
      <ButtonBase
        sx={{
          boxShadow: error
            ? "inset 0px 0px 1px 1px #ef4145"
            : "inset 0px 0px 1px 1px rgba(0, 0, 0, 0.15)",
          backgroundColor: "#fff",
          borderRadius: "10px",
          margin: "1em",
          width: "100%",
        }}
        onClick={() => (clickable && onClick ? onClick() : undefined)}
        disableRipple={!clickable}
      >
        <Grid container spacing={1} alignItems="center" width="100%">
          {value.length > 0 ? (
            value.split("").map((i, index) => (
              <Grid
                item
                xs={clearable ? 2.5 : 3}
                sx={{
                  "height": "5em",
                  "padding": "0.5em",
                  "display": "flex",
                  "justifyContent": "center",
                  "& img": {
                    height: "100%",
                  },
                }}
                mt={1}
                key={index}
              >
                <img src={pinIcons[parseInt(i)]} alt="" />
              </Grid>
            ))
          ) : (
            <Grid
              item
              xs={3}
              sx={{
                "height": "5em",
                "padding": "0.5em",
                "display": "flex",
                "justifyContent": "center",
                "& img": {
                  height: "100%",
                },
              }}
              mt={1}
            ></Grid>
          )}
          {clearable ? (
            <Grid
              item
              xs={2}
              sx={{
                "height": "5em",
                "padding": "0.5em",
                "display": "flex",
                "justifyContent": "center",
                "alignItems": "center",
                "& svg": {
                  height: "2em",
                  width: "2em",
                },
              }}
              ml="auto"
              mt={1}
            >
              <ButtonBase
                onClick={() => setValue("")}
                sx={(theme) => ({
                  backgroundColor: theme.palette.secondary.main,
                  opacity: 0.5,
                  borderRadius: "999px",
                  padding: "0.5em",
                })}
              >
                <Icons.CloseIcon color="#fff" />
              </ButtonBase>
            </Grid>
          ) : null}
        </Grid>
      </ButtonBase>
    </Box>
  );
};

export default IconPinDisplay;
