import { constructFollowedSideEffect } from "./followedSideEffect";
import { constructGamerChildDoc } from "./gamerChild";
import { constructMedicalChild } from "./medicalChild";
import { constructPrescription } from "./prescription";
import { constructRecordSummaries } from "./recordSummary";
import { generateTaskHistoryData } from "./routineData";
import { constructUserDoc } from "./user";

const DemoAccount = {
  constructFollowedSideEffect,
  constructGamerChildDoc,
  constructMedicalChild,
  constructPrescription,
  constructRecordSummaries,
  constructUserDoc,
  generateTaskHistoryData,
};

export default DemoAccount;
