import { AxiosRequestConfig } from "axios";
import { getIdToken } from "./getIdToken";

export const getHeaders = async (
  requiresAuth = true,
  logNamespace?: string
): Promise<AxiosRequestConfig["headers"]> => {
  const headers: AxiosRequestConfig["headers"] = {};

  if (requiresAuth) {
    headers["Authorization"] = "Bearer " + (await getIdToken(logNamespace));
  }

  return headers;
};
