import { DeviceData } from "@neurosolutionsgroup/models";

const isPermissionRequired = (
  os: "android" | "ios",
  deviceData?: DeviceData
): boolean => {
  if (os === "ios") {
    return true;
  } else {
    if (
      deviceData &&
      deviceData.osVersion &&
      deviceData.osVersion.includes("Android OS ")
    ) {
      const androidVersionString = deviceData.osVersion.split(" ")[2];

      try {
        const androidVersion = parseInt(androidVersionString);

        if (androidVersion >= 13) {
          return true;
        }
      } catch {
        return false;
      }
    }

    return false;
  }
};

const NotificationsLogic = {
  isPermissionRequired,
};

export default NotificationsLogic;
