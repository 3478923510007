import {
  ChatbotMessageSent,
  createABTestRegistered,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { WebviewLink } from "@neurosolutionsgroup/components";
import { ChatMessage } from "@neurosolutionsgroup/models";
import {
  ConfigNumber,
  ConfigString,
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import { Tools } from "@neurosolutionsgroup/tools";
import useUserProfile from "common/hooks/account/useUserProfile";
import { ExternalLink } from "common/hooks/Parameters/UniWebViewActions";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useParameters from "common/hooks/Parameters/useParameters";
import React from "react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

interface ChatBotHookResult {
  canDisplayChatbot: boolean;
  loading: boolean;
  messages: ChatMessage[];
  onChatSubmit: (message: string) => void;
}

const useChatBot = (): ChatBotHookResult => {
  const { handleEvent } = useAnalytics();
  const { language } = useLanguage();
  const location = useLocation();
  const { linkHandler, version } = useParameters();
  const { checkFeatureFlag, getNumberConfig, getRemoteConfigValue } =
    useRemoteConfig();
  const { t } = useTranslation();
  const {
    selectors: { userProfile },
    actions: { updateMasterSettingsKey },
  } = useUserProfile();

  const [canDisplayChatbot, setCanDisplayChatbot] = useState(false);
  const [loading, setLoading] = useState(false);
  const [conversationId, setConversationId] = useState<string | undefined>();
  const [messages, setMessages] = useState<ChatMessage[]>([
    {
      isUserMessage: false,
      message: t("coach.chatbot.introMessage"),
    },
  ]);

  useEffect(() => {
    setMessages([
      {
        isUserMessage: false,
        message: t("coach.chatbot.introMessage"),
      },
    ]);
  }, [language]);

  useEffect(() => {
    const flagActive = checkFeatureFlag(WebviewsFeatureFlag.ChatBot, version);

    if (!flagActive) {
      setCanDisplayChatbot(false);
      return;
    }

    if (userProfile?.masterSettings?.settings["AB-Chatbot"]) {
      setCanDisplayChatbot(
        userProfile?.masterSettings?.settings["AB-Chatbot"] === "B"
      );
    } else {
      const bPercentage = getNumberConfig(
        ConfigNumber.AbTestChatbotBPercentage
      );

      const group = Tools.Maths.selectABTestGroup(bPercentage);

      updateMasterSettingsKey("AB-Chatbot", group);

      handleEvent(
        createABTestRegistered("Chatbot." + group, "AB-Chatbot", group)
      );

      setCanDisplayChatbot(group === "B");
    }
  }, [userProfile, version, location]);

  const sendChatMessage = async (message: string) => {
    let _conversationId: string | undefined = conversationId;

    if (!_conversationId) {
      _conversationId = await FirebaseAPI.Chat.createConversation();

      setConversationId(_conversationId);
    }

    if (!_conversationId) {
      return Promise.reject("Failed to generate conversation.");
    }

    const apiLanguage = language === "fr" ? "French" : "English";

    const response = await FirebaseAPI.Chat.sendChatMessage(
      message,
      _conversationId,
      apiLanguage
    );

    setMessages((current) => [
      ...current,
      { message: response.content, isUserMessage: false },
    ]);
  };

  const onChatSubmit = async (message: string) => {
    setLoading(true);

    const event: ChatbotMessageSent = {
      name: "Chatbot Message Sent",
      eventProperties: {
        "Length of message": message.length,
      },
    };

    handleEvent(event);

    setMessages((current) => [...current, { message, isUserMessage: true }]);

    try {
      await sendChatMessage(message);
    } catch (error) {
      // Retry once.
      try {
        await sendChatMessage(message);
      } catch (error) {
        setMessages((current) => [
          ...current,
          {
            message: (
              <>
                <strong>{t("navigation.error.title")}</strong>
                <br />
                <br />
                {t("coach.chatbot.error")}{" "}
                <WebviewLink
                  onClick={() => {
                    linkHandler(
                      ExternalLink.Support,
                      getRemoteConfigValue(ConfigString.SupportLink).asString()
                    );
                  }}
                  color="error"
                  sx={{
                    marginTop: "-2px",
                  }}
                >
                  {t("navigation.error.supportLink")}
                </WebviewLink>
              </>
            ),
            isUserMessage: false,
            error: true,
          },
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    canDisplayChatbot,
    loading,
    messages,
    onChatSubmit,
  };
};

export default useChatBot;
