import { Box, Typography } from "@mui/material";
import { InformationButton } from "common/Components";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import React, { ReactNode, useState } from "react";

export interface ValidationSectionProps {
  title: string;
  showTitle: boolean;
  children: ReactNode;
  infodrawerText?: string;
}

const ValidationSection: React.FC<ValidationSectionProps> = ({
  title,
  showTitle,
  children,
  infodrawerText,
}) => {
  const [openInfoDrawer, setOpenInfoDrawer] = useState<boolean>(false);

  return (
    <>
      <Box>
        {showTitle && (
          <Typography variant="h3" pb={1}>
            {title}{" "}
            {infodrawerText && (
              <InformationButton
                data-cy="info-button"
                onClick={() => {
                  setOpenInfoDrawer(true);
                }}
              />
            )}
          </Typography>
        )}
        {children}
      </Box>
      {infodrawerText && (
        <InfoDrawer
          data-cy="info-drawer"
          open={openInfoDrawer}
          onClose={() => setOpenInfoDrawer(false)}
          text={infodrawerText}
        />
      )}
    </>
  );
};
export default ValidationSection;
