import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useCallback } from "react";
import useCoach from "./useCoach";
import CoachDrawer from "./CoachDrawer";
import { Trans, useTranslation } from "react-i18next";
import {
  CoachNotificationID,
  CoachNotificationType,
} from "./CoachNotifications";
import { Typography } from "@mui/material";
import CoachFullScreen from "./CoachFullScreen";
import { useNavigate } from "react-router-dom";
import { Dialogs } from "@neurosolutionsgroup/components";
import CoachAssets from "assets/coach";

const Coach: React.FC = () => {
  const {
    coachCanBeDisplayed,
    coachMessage,
    onCoachDismissal,
    currentCoachMessageDefintion,
  } = useCoach();

  const { language } = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onCoachDismissalCheck = () => {
    if (
      coachMessage?.id === CoachNotificationID.TenthRoutineValidated ||
      coachMessage?.id === CoachNotificationID.FirstObservationsComplete
    ) {
      navigate("/guide");
    }

    onCoachDismissal();
  };

  const onSecondaryActionClicked = () => {
    if (coachMessage?.id === CoachNotificationID.PositiveReinforcement) {
      navigate("/guide#sub-positiveReinforcement");
    }

    onCoachDismissal();
  };

  const renderMessage = useCallback((): JSX.Element | null => {
    if (coachMessage) {
      return (
        <Typography textAlign="center">
          <Trans
            i18nKey={`coach.coachNotification.${coachMessage.id.toString()}.message`}
            components={{ bold: <strong /> }}
            values={coachMessage.data}
          />
        </Typography>
      );
    } else {
      return null;
    }
  }, [coachMessage, language, t]);

  const renderTitle = useCallback((): JSX.Element | null => {
    if (coachMessage) {
      return (
        <Typography
          textAlign={
            coachMessage.id === CoachNotificationID.DifficultTaskSucceeded
              ? "left"
              : "center"
          }
          variant="h4"
          sx={(theme) => ({
            marginX: theme.spacing(1),
          })}
        >
          <Trans
            i18nKey={`coach.coachNotification.${coachMessage.id.toString()}.title`}
            components={{ bold: <strong /> }}
            values={coachMessage.data}
          />
        </Typography>
      );
    } else {
      return null;
    }
  }, [coachMessage, language, t]);

  const renderDialogText = () => {
    const message = renderMessage();
    if (message !== null) {
      return (
        <>
          <img src={CoachAssets.CoachDynamic} alt="" />
          {message}
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderMessageType = useCallback((): JSX.Element | null => {
    if (
      !(coachCanBeDisplayed && coachMessage && currentCoachMessageDefintion)
    ) {
      return null;
    }

    switch (currentCoachMessageDefintion.type) {
      case CoachNotificationType.Dialog:
        return (
          <Dialogs.AlertDialog
            open={true}
            title={t(
              `coach.coachNotification.${coachMessage.id.toString()}.title`
            )}
            text={renderDialogText()}
            positiveAction={{
              text: t(
                `coach.coachNotification.${coachMessage.id.toString()}.action`
              ),
              action: () => {
                onCoachDismissalCheck();
              },
              variant: "contained",
              color: "secondary",
            }}
            negativeAction={
              currentCoachMessageDefintion.secondaryAction
                ? {
                    text: t(
                      `coach.coachNotification.${coachMessage.id.toString()}.secondaryAction`
                    ),
                    action: () => {
                      onCoachDismissalCheck();
                    },
                    variant: "text",
                    color: "error",
                  }
                : undefined
            }
          />
        );
      case CoachNotificationType.Information:
      case CoachNotificationType.PositiveReinforcement:
        return (
          <CoachDrawer
            open={true}
            onClose={() => console.log("Coach close drawer intercepted.")}
            coachTitle={renderTitle()}
            onConfirm={onCoachDismissalCheck}
            type={currentCoachMessageDefintion.type}
            action={
              currentCoachMessageDefintion.customAction
                ? `coach.coachNotification.${coachMessage.id.toString()}.action`
                : undefined
            }
            secondaryActionText={
              currentCoachMessageDefintion.secondaryAction
                ? `coach.coachNotification.${coachMessage.id.toString()}.secondaryAction`
                : undefined
            }
            onSecondaryActionClicked={onSecondaryActionClicked}
            showInfoIcon={
              coachMessage.id !== CoachNotificationID.DifficultTaskSucceeded
            }
          >
            {renderMessage()}
          </CoachDrawer>
        );
      case CoachNotificationType.FullScreen:
        return (
          <CoachFullScreen
            coachNotification={currentCoachMessageDefintion}
            onDismiss={onCoachDismissal}
          />
        );
      default:
        return null;
    }
  }, [coachCanBeDisplayed, coachMessage, currentCoachMessageDefintion]);

  return renderMessageType();
};

export default Coach;
