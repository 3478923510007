import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  drawerClasses,
  Grid,
  Typography,
} from "@mui/material";
import { PageHeader } from "common/Components/Headers";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useNavigation from "common/hooks/navigation/useNavigation";
import useParameters from "common/hooks/Parameters/useParameters";
import Logos from "assets/logo";
import DynamismAssets from "assets/dynamism";
import { useNavigate } from "react-router-dom";
import DebugMenu from "./DebugMenu";
import { Tools } from "@neurosolutionsgroup/tools";
import { ExternalLink } from "common/hooks/Parameters/UniWebViewActions";
import {
  ConfigString,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import { Icons } from "@neurosolutionsgroup/components";
import useSubscription from "common/hooks/subscription/useSubscription";
import LaunchIcon from "@mui/icons-material/Launch";

const OptionPage: React.FC = () => {
  const ROOT_CLASS = "option-page";

  const { t } = useTranslation();
  const { linkHandler } = useParameters();
  const { getRemoteConfigValue } = useRemoteConfig();
  const navigate = useNavigate();
  const {
    selectors: { menuPageOpen },
    actions: { setMenuPageOpen },
  } = useNavigation();

  const { subscriptionStatus, onShowSubscriptionScreen } = useSubscription();

  const [openDebugMenu, setOpenDebugMenu] = useState(false);

  const onSubscribeClick = () => {
    setMenuPageOpen(false);
    onShowSubscriptionScreen();
  };

  return (
    <Drawer
      classes={{ paperAnchorRight: ROOT_CLASS + "__menu" }}
      anchor="right"
      open={menuPageOpen}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          "&:after": {
            content: "''",
            backgroundImage: `url(${DynamismAssets.Menu})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom left",
            backgroundSize: "contain",
            display: "block",
            position: "fixed",
            top: 0,
            bottom: "-25px",
            left: "-45px",
            right: 0,
            maxWidth: "400px",
            zIndex: -1,
          },
        },
      }}
    >
      <PageHeader
        leftElement={
          <ButtonBase
            onClick={() => setMenuPageOpen(false)}
            className="option-menu-button"
            sx={{
              "padding": "0.5em",
              "& .icon-close": {
                width: "3em",
                height: "3em",
              },
            }}
            data-cy="settings-back"
          >
            <Icons.CloseIcon color={"#fff"} />
          </ButtonBase>
        }
        rightElement={
          <Box
            p={1}
            className={"option-page__logo__container"}
            display="flex"
            alignItems="center"
          >
            <img
              className={"option-page__logo"}
              src={Logos.KairosLogoWhite}
              alt=""
            />
          </Box>
        }
      />
      <Grid
        container
        direction="column"
        alignItems="flex-end"
        spacing={2}
        mt={1}
      >
        {!subscriptionStatus?.isSubscribed ? (
          <Grid item>
            <Button
              onClick={onSubscribeClick}
              variant="contained"
              color="success"
            >
              {t("navigation.subscription.upgrade")}
            </Button>
          </Grid>
        ) : null}
        <Grid item>
          <ButtonBase
            onClick={() => {
              navigate("/guide");
              setMenuPageOpen(false);
            }}
            sx={(theme) => ({
              padding: theme.spacing(1),
            })}
          >
            <Typography fontWeight="bold" color="#fff" variant="h1">
              {t("navigation.optionMenu.guide")}
            </Typography>
          </ButtonBase>
        </Grid>
        <Grid item>
          <ButtonBase
            data-cy="nav-settings-button"
            onClick={() => {
              navigate("/settings");
              setMenuPageOpen(false);
            }}
            sx={(theme) => ({
              padding: theme.spacing(1),
            })}
          >
            <Typography fontWeight="bold" color="#fff" variant="h1">
              {t("navigation.optionMenu.settings")}
            </Typography>
          </ButtonBase>
        </Grid>
        <Grid item>
          <ButtonBase
            onClick={() => {
              linkHandler(
                ExternalLink.Support,
                getRemoteConfigValue(ConfigString.SupportLink).asString()
              );
            }}
            sx={(theme) => ({
              padding: theme.spacing(1),
            })}
          >
            <Typography fontWeight="bold" color="#fff" variant="h1">
              {t("navigation.optionMenu.support")}
            </Typography>
            <LaunchIcon sx={{ marginTop: 0.5, marginLeft: 1, color: "#fff" }} />
          </ButtonBase>
        </Grid>
      </Grid>
      {Tools.Environment.isStaging() ? (
        <>
          <Box
            sx={{
              position: "fixed",
              bottom: "0.5rem",
              right: "0.5rem",
            }}
          >
            <Button onClick={() => setOpenDebugMenu(true)}>Debug Menu</Button>
          </Box>
          <Dialog open={openDebugMenu} onClose={() => setOpenDebugMenu(false)}>
            <DialogTitle>Debug Menu</DialogTitle>
            <DialogContent>
              <DebugMenu />
            </DialogContent>
          </Dialog>
        </>
      ) : null}
    </Drawer>
  );
};

export default OptionPage;
