import { PriceInfo, SafeAreas } from "@neurosolutionsgroup/models";
import PlanSelection, {
  Product,
} from "./Components/PlanSelection/PlanSelection";
import {
  BackgroundHexagons,
  FullscreenPage,
  PageContentContainer,
  PremiumTag,
  StickyDrawer,
} from "@neurosolutionsgroup/components";
import {
  Box,
  Button,
  CircularProgress,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { createRef, useEffect, useMemo, useState } from "react";
import TierComparisonCard from "./Components/TierComparisonCard/TierComparisonCard";

interface SubscriptionScreenNoTrialProps {
  loading: boolean;
  onCancel: VoidFunction;
  onConfirm: (plan: Product) => void;
  open: boolean;
  priceInfo?: PriceInfo;
  safeAreas: SafeAreas;
  subscriptionDecorationImgSrc: string;
  store: "Apple App Store" | "Google Play Store";
  sx?: SxProps<Theme>;
}

const SubscriptionScreenNoTrial = ({
  loading,
  onCancel,
  onConfirm,
  open,
  priceInfo,
  safeAreas,
  subscriptionDecorationImgSrc,
  store,
  sx,
}: SubscriptionScreenNoTrialProps): JSX.Element | null => {
  const { t } = useTranslation();

  const [plan, setPlan] = useState<Product>("annualTrial");
  const [height, setHeight] = useState<number | undefined>(undefined);

  const shouldShowTaxDisclaimer = useMemo(() => {
    if (store === "Apple App Store") {
      return false;
    }

    if (priceInfo?.monthly.currency) {
      return ["USD", "CAD"].includes(priceInfo?.monthly.currency.toUpperCase());
    } else {
      return priceInfo?.monthly.priceString.includes("$");
    }
  }, [store, priceInfo]);

  const ref = createRef<HTMLDivElement>();

  useEffect(() => {
    setHeight(ref.current?.clientHeight);
  });

  const period = plan === "monthly" ? "month" : "year";

  const onCancelClick = () => {
    setPlan("annualTrial");
    onCancel();
  };

  const onConfirmClick = () => {
    onConfirm(plan);
  };

  return open ? (
    <FullscreenPage
      sx={[
        {
          backgroundColor: "primary.main",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <BackgroundHexagons />

      <PageContentContainer
        safeAreas={safeAreas}
        sx={{ justifyContent: "space-around", position: "relative", zIndex: 1 }}
      >
        <Box display="flex" justifyContent="flex-end" pr={2} pt={1}>
          <PremiumTag text={t("navigation.subscription.tiers.premium")} />
        </Box>

        <Box
          sx={{
            marginX: "auto",
            img: {
              maxWidth: "80vw",
              maxHeight: "200px",
              objectFit: "contain",
            },
          }}
        >
          <img src={subscriptionDecorationImgSrc} alt="" />
        </Box>

        <Typography
          textAlign="center"
          variant="h2"
          color="primary.contrastText"
        >
          {t("navigation.subscription.screenNoTrial.title")}
        </Typography>

        <Box my={3} mx={2} display="flex" justifyContent="center">
          <Box maxWidth="600px" flexGrow={1}>
            <TierComparisonCard />
          </Box>
        </Box>

        <Box minHeight={height} />
      </PageContentContainer>
      <StickyDrawer ref={ref} safeAreas={safeAreas}>
        <Box p={1} pb={2}>
          <Typography mb={1} mt={0.5} fontWeight={600} fontSize="0.9rem">
            {t("navigation.subscription.screenTwo.drawer.title")}
          </Typography>
          <Box mb={1}>
            <PlanSelection
              priceInfo={priceInfo}
              value={plan}
              onPriceSelected={setPlan}
            />
          </Box>
          <Box mb={1}>
            <Typography
              textAlign="center"
              color="secondary"
              fontWeight={600}
              fontSize="0.75rem"
            >
              {shouldShowTaxDisclaimer ? (
                <>{t("navigation.subscription.taxes")} </>
              ) : null}
              {t("navigation.subscription.screenNoTrial.smallprint", {
                period: t("navigation.subscription.screenTwo.period." + period),
                store,
              })}
            </Typography>
          </Box>
          <Button
            onClick={onConfirmClick}
            variant="contained"
            color="success"
            fullWidth
            data-cy="purchase-button"
          >
            {loading ? (
              <CircularProgress size="1.5rem" color="inherit" />
            ) : (
              t("navigation.subscription.upgrade")
            )}
          </Button>
          <Button
            onClick={onCancelClick}
            color="secondary"
            variant="text"
            fullWidth
            sx={{
              "marginTop": 0.5,
              "boxShadow": "none",
              "background": "none",
              "&:hover": {
                background: "none",
              },
            }}
          >
            {t("general.nothanks")}
          </Button>
        </Box>
      </StickyDrawer>
    </FullscreenPage>
  ) : null;
};

export default SubscriptionScreenNoTrial;
