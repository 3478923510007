import { Box, Typography } from "@mui/material";
import { CloseablePage } from "common/Components";
import React, { useEffect, useState } from "react";
import SideEffectForm, {
  SideEffectFormState,
} from "../SideEffectForm/SideEffectForm";
import { useTranslation } from "react-i18next";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useChildren from "common/hooks/children/useChildren";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";

const ROOT_CLASS = "edit-side-effect";
export interface EditSideEffectProps {
  open: boolean;
  onClose: VoidFunction;
  sideEffects: string[];
}

const EditSideEffect: React.FC<EditSideEffectProps> = ({
  open,
  onClose,
  sideEffects,
}) => {
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();

  const {
    actions: { updateFollowedSideEffects },
  } = useFollowUp();
  const {
    selectors: { selectedChild },
  } = useChildren();

  const [sideEffectsState, setSideEffectsState] =
    useState<string[]>(sideEffects);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  const onSubmit = () => {
    if (selectedChild) {
      try {
        updateFollowedSideEffects(selectedChild, sideEffectsState);
        onClose();
      } catch (err: unknown) {
        handleUnknownError(err);
      }
    }
  };

  useEffect(() => {
    setSideEffectsState(sideEffects);
  }, [sideEffects]);

  return (
    <CloseablePage
      isOpen={open}
      onClose={onClose}
      closeElement={<></>}
      className={ROOT_CLASS}
      header={
        <Box
          className={ROOT_CLASS + "--title title-header"}
          display="flex"
          justifyContent="start"
        >
          <Typography variant="h1" color="#fff">
            {t("general.sideEffect")}
          </Typography>
        </Box>
      }
    >
      <SideEffectForm
        selectedSideEffects={sideEffectsState}
        setSelectedSideEffects={setSideEffectsState}
        onSubmit={onSubmit}
        onCancel={() => setShowCancelDialog(true)}
        formState={SideEffectFormState.edition}
      />

      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        onPositiveAction={onClose}
        onNegativeAction={() => setShowCancelDialog(false)}
        imgSrc={DialogAssets.ModificationTag}
      />
    </CloseablePage>
  );
};

export default EditSideEffect;
