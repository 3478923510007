import { Box } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";

interface ChildDisplayProps extends CypressProps {
  childName: string;
  childIconSrc: string;
}

const ChildDisplay = ({
  childName,
  childIconSrc,
  ...props
}: ChildDisplayProps): JSX.Element => {
  return (
    <Box
      sx={(theme) => ({
        boxSizing: "border-box",
        display: "inline-flex",
        alignItems: "center",
        backgroundColor: "#fff",
        borderColor: theme.palette.secondary.main,
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: "999px",
        padding: "6px 1em",
        fontWeight: "bold",
        img: {
          height: "1.5rem",
          width: "1.5rem",
          marginRight: "6px",
        },
      })}
      data-cy={props["data-cy"] ?? "child-display"}
    >
      <img src={childIconSrc} alt="" />
      {childName}
    </Box>
  );
};

export default ChildDisplay;
