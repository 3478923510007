import { SimplifiedRecordWithQuestion } from "./Record";

/** Options for whether or now a child has a condition */
export enum HasCondition {
  NO,
  YES,
  UNKNOWN,
}

/** Conditions that a child can have */
export enum Condition {
  UNLISTED,
  ADD,
  ADHD,
  ASD,
}

export enum MedicationResponse {
  NO,
  YES,
  UNKNOWN,
}

export const INTENSITY_QUESTION_ID = "cc493f17-6096-46e5-aacd-cbff5a2be6a0";
export const FREQUENCY_QUESTION_ID = "39f391aa-faa7-4c75-abbd-a49bcff2b2e0";

export interface RecordsMap {
  [sideEffectId: string]: SimplifiedRecordWithQuestion[];
}

export interface JoinedPrescriptions {
  [joinedPrescriptionId: string]: {
    start: string;
    end: string;
  };
}
