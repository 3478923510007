import BlueBackground from "./blue-sprites/Background_Blue_Sprites.svg";
import RedBackground from "./red-sprites/Background_Red_Sprites.svg";
import RedBackgroundRoutine from "./red-sprites/background-red-sprites_routine.svg";
import ErrorDialogBackground from "./error_dialog_background.svg";
import HexagonBackground from "./hexagon_background.svg";
import HexagonSpritesMixed from "./hexagon_sprites_mixed.svg";
import HalloweenBackground from "./black-sprites/background-black-sprites_mobile.svg";
import OptInBackground from "./optin_background.svg";

const BackgroundAssets = {
  BlueBackground,
  RedBackground,
  RedBackgroundRoutine,
  ErrorDialogBackground,
  HalloweenBackground,
  HexagonBackground,
  HexagonSpritesMixed,
  OptInBackground,
};

export default BackgroundAssets;
