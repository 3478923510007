import { Box } from "@mui/material";
import React from "react";

interface feedbackIconProps extends React.HTMLProps<HTMLDivElement> {
  shape: "round" | "square";
  icon: JSX.Element;
  active?: boolean;
}

const FeedbackIcon: React.FC<feedbackIconProps> = ({
  shape,
  icon,
  active = false,
}) => {
  return (
    <Box
      sx={(theme) => ({
        borderRadius: shape === "round" ? "50px" : "25px",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: active ? theme.palette.secondary.main : "#fff",
        backgroundColor: active ? theme.palette.secondary.light : "#fff",
        width: "100px",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        svg: {
          path: {
            stroke: active ? "#fff" : theme.palette.secondary.main,
          },
          ellipse: {
            fill: active ? "#fff" : theme.palette.secondary.main,
          },
        },
      })}
    >
      {icon}
    </Box>
  );
};

export default FeedbackIcon;
