import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogContentText,
  DialogProps,
  useTheme,
} from "@mui/material";
import { Challenge } from "@neurosolutionsgroup/models";
import useChildren from "common/hooks/children/useChildren";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { calculateChallengeValidationMessageToDisplay } from "./ChallengeFinishedDialogLogic";

export interface ChallengeFinishedDialogProps extends DialogProps {
  challenge: Challenge;
  onClose: VoidFunction;
}

const ChallengeFinishedDialog: React.FC<ChallengeFinishedDialogProps> = ({
  challenge,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const {
    selectors: { childrenById },
  } = useChildren();

  const childName = () => {
    return childrenById[challenge.childId].name;
  };

  const childMale = () => {
    return childrenById[challenge.childId].gender === 1 ? false : true;
  };

  const challengeContextText = useMemo(
    () =>
      calculateChallengeValidationMessageToDisplay(
        challenge,
        childName(),
        childMale(),
        t
      ),
    [challenge]
  );

  return (
    <Dialog
      {...props}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          minWidth: "80vw",
        },
      }}
      data-cy="challenge-finished-dialog"
    >
      <DialogContent>
        <DialogContentText color={palette.secondary.main}>
          {challengeContextText}
        </DialogContentText>
        <br />
        {challenge.successful &&
        challenge.reward &&
        !challenge.createdByName ? (
          <>
            <DialogContentText color={palette.secondary.main}>
              {t("validation.challenge.finishedDialog.reward")}
            </DialogContentText>
            <br />
            <DialogContentText color={palette.secondary.main}>
              {challenge.reward.text}
            </DialogContentText>
          </>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose && onClose()}>
          {t("general.actions.close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChallengeFinishedDialog;
