import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageSection } from "common/Components/PageSection";
import useChildren from "common/hooks/children/useChildren";
import { EditChild } from "../EditChild/EditChild";
import { GamerChild } from "@neurosolutionsgroup/models";
import ChildIcons from "assets/child-icons";
import { Button, Grid, useTheme } from "@mui/material";
import { Icons, PremiumLock } from "@neurosolutionsgroup/components";
import {
  ConfigNumber,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import ChildCreation from "../ChildCreation";
import {
  ChildAccountCreationStarted,
  DefaultChildEditionStarted,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import useSubscription from "common/hooks/subscription/useSubscription";
import DefaultChildEditDialog from "common/Components/Dialogs/DefaultChildEditDialog";

export const SettingsChildSelector: React.FC = () => {
  const ROOT_CLASS = "settings-child-selector";

  const { handleEvent } = useAnalytics();
  const { t } = useTranslation();
  const { palette } = useTheme();

  const { selectedChild, childIds, childrenById } = useChildren().selectors;
  const { onPremiumFeatureClick, permissions } = useSubscription();

  const [childEditing, editChild] = useState<GamerChild | null>(null);
  const [openChildCreation, setOpenChildCreation] = useState(false);
  const [placeholderName, setPlaceholderName] = useState<string | undefined>(
    undefined
  );
  const [defaultChildWarningDialog, setDefaultChildWarningDialog] =
    useState<boolean>(false);

  const goToChild = (child: GamerChild) => editChild(child);

  const newChild = () => {
    if (selectedChild && childrenById[selectedChild].isDefault) {
      setDefaultChildWarningDialog(true);
    } else {
      setOpenChildCreation(true);

      const event: ChildAccountCreationStarted = {
        name: "Kid Account Creation Started",
      };

      handleEvent(event);
    }
  };
  const editDefaultChild = (placeholderName: string) => {
    const event: DefaultChildEditionStarted = {
      name: "Default Child Edition Started",
    };

    handleEvent(event);

    setPlaceholderName(placeholderName);
    setOpenChildCreation(true);
  };

  const { getNumberConfig } = useRemoteConfig();

  const renderChild = (child: GamerChild, idx: number) =>
    child && (
      <Grid item>
        <PremiumLock
          active={!permissions.childCreation && !!child.isDisabled}
          onClick={() => onPremiumFeatureClick(PremiumFeature.LockedChild)}
          variant="button-long"
        >
          <Button
            data-cy={`settings-child-${idx}`}
            key={idx}
            onClick={() => {
              if (child.isDefault) {
                editDefaultChild(child.name);
              } else {
                goToChild(child);
              }
            }}
            variant="text"
            color="secondary"
          >
            <img
              className={`${ROOT_CLASS}__icon`}
              src={ChildIcons[child.icon]}
              alt={`Child Icon ${child.icon}`}
            />
            {child.name}
          </Button>
        </PremiumLock>
      </Grid>
    );

  const renderChildren = () =>
    childIds.map((gamerChildId, idx) =>
      renderChild(childrenById[gamerChildId], idx)
    );

  const renderAddButton = () =>
    childIds.length < getNumberConfig(ConfigNumber.ChildLimit) ? (
      <Grid item>
        <PremiumLock
          onClick={() => onPremiumFeatureClick(PremiumFeature.Child)}
          active={!permissions.childCreation}
          variant="button-long"
          data-cy="premium-lock-settings-child"
        >
          <Button
            onClick={newChild}
            variant="text"
            color="secondary"
            data-cy="settings-child-add"
          >
            <Icons.AddIcon color={palette.secondary.main} />
          </Button>
        </PremiumLock>
      </Grid>
    ) : null;

  return (
    <PageSection
      title={t("settings.childProfiles")}
      classes={{ body: `${ROOT_CLASS}__body` }}
      showBottomBorder
      noMargin
    >
      <div className={`${ROOT_CLASS}__scroll-container`}>
        <Grid container spacing={1} flexWrap="nowrap">
          {renderChildren()}
          {renderAddButton()}
        </Grid>
      </div>
      <EditChild
        open={childEditing !== null}
        onClose={() => editChild(null)}
        canBeDeleted={childIds.length > 1}
        child={childEditing}
      />
      <ChildCreation
        open={openChildCreation}
        onClose={() => setOpenChildCreation(false)}
        onBack={() => setOpenChildCreation(false)}
        placeholderName={placeholderName}
        codeStyle={placeholderName ? 0 : null}
      />
      <DefaultChildEditDialog
        onCancel={() => {
          setDefaultChildWarningDialog(false);
        }}
        onEdit={() => {
          setDefaultChildWarningDialog(false);
          editDefaultChild(placeholderName ?? "");
        }}
        open={defaultChildWarningDialog}
      />
    </PageSection>
  );
};
