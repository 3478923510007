import React, { useEffect, useState } from "react";
import queryString from "query-string";
import { CustomWindow } from "custom.window";
import useLanguage from "common/hooks/Parameters/useLanguage";

import "./App.scss";
import useParameters from "common/hooks/Parameters/useParameters";
import AppRouter from "AppRouter";
import AppDataProviders from "AppDataProviders";
import useAuth from "common/hooks/auth/useAuth";
import { ButtonBase, Typography } from "@mui/material";
import { preloadAssets } from "assets/AssetPreloader";
import MiscAssets from "assets/misc";
import * as Sentry from "@sentry/react";
import CoachAssets from "assets/coach";
import { Language } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { UniWebViewActions } from "common/hooks/Parameters/UniWebViewActions";
import AnalyticsDebugger from "common/Analytics/AnalyticsDebugger";

declare let window: CustomWindow;

preloadAssets([
  MiscAssets.FTUEEnd,
  CoachAssets.CoachDynamic,
  CoachAssets.CoachCategory1,
  CoachAssets.CoachCategory2,
  CoachAssets.CoachCategory3,
  CoachAssets.CoachCategory4,
]);

const App = (): JSX.Element => {
  const { setLanguage } = useLanguage();
  const {
    setOS,
    setVersion,
    setIsTest,
    setBuildNumber,
    sendMessageToUnity,
    passSubscriptionInfo,
    passAppInfo,
  } = useParameters();
  const { logOut, setupAuthFromCustomToken } = useAuth();

  const [stagingClicks, setStaginClicks] = useState(0);

  window.Subscription = {
    passSubscriptionInfo: (infoJson) => passSubscriptionInfo(infoJson),
  };

  window.passAppInfo = (info) => {
    passAppInfo(info);
    setLanguage(Tools.Language.languageCodeToKnownLanguage(info.language));
  };

  window.changeLanguage = (newLanguage: Language): void => {
    setLanguage(newLanguage);
  };

  window.FirebaseAuth = {
    passFirebaseToken: (token?: string) => {
      try {
        Sentry.addBreadcrumb({
          category: "auth",
          message: "Received token from Unity.",
          level: "info",
        });

        setupAuthFromCustomToken(token);
      } catch (ex) {
        console.error(ex);
        Sentry.captureException(ex);
      }
    },
    unityAuthenticated: () => {
      console.log("Unity authenticated successfully.");
    },
  };

  window.logOut = () => {
    logOut();
  };

  useEffect(() => {
    sendMessageToUnity(UniWebViewActions.LoadingComplete);

    const params = queryString.parse(window.location.search);

    if (params.lang) {
      setLanguage(
        Tools.Language.languageCodeToKnownLanguage(params.lang.toString())
      );
    }

    if (params.os) {
      setOS(params.os.toString() === "android" ? "android" : "ios");
    }

    if (params.version) {
      setVersion(params.version.toString());
    }

    if (params.build) {
      setBuildNumber(params.build.toString());
    }

    sendMessageToUnity(UniWebViewActions.GetAppInfo);

    if (params.test !== undefined) {
      setIsTest(true);
    }
  }, []);

  return (
    <AppDataProviders>
      <AppRouter />
      {Tools.Environment.isStaging() &&
      process.env.NODE_ENV !== "development" &&
      stagingClicks < 3 ? (
        <ButtonBase
          onClick={() => setStaginClicks((current) => current + 1)}
          sx={{
            position: "fixed",
            zIndex: 1000,
            opacity: 0.5,
            marginLeft: "40vw",
            marginTop: "0.8rem",
            width: "20vw",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography color="#fff">Staging</Typography>
        </ButtonBase>
      ) : null}
      {Tools.Environment.isDevBuild() ? <AnalyticsDebugger /> : null}
    </AppDataProviders>
  );
};

export default App;
