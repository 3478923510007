import { Dialogs } from "@neurosolutionsgroup/components";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import {
  catmap,
  GamerChild,
  Note,
  orderedNeedKeys,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  NoteFormState,
  NoteFormPage,
} from "@neurosolutionsgroup/webviews-pages";
import ChildIcons from "assets/child-icons";
import CoachAssets from "assets/coach";
import DialogAssets from "assets/dialogs";
import useChildren from "common/hooks/children/useChildren";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useNotes from "common/hooks/notes/useNotes";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useTasks from "common/hooks/routines/useTasks";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import { isEqual } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREAS } from "stylesheets";

interface NoteEditionProps {
  open: boolean;
  onClose: VoidFunction;
  note: Note | null;
  gamerChild: GamerChild;
}

const NoteEdition = ({
  open,
  onClose,
  note,
  gamerChild,
}: NoteEditionProps): JSX.Element | null => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();

  const {
    selectors: { getActiveFollowedSideEffects },
  } = useFollowUp();
  const { deleteNote, editNote } = useNotes();
  const {
    selectors: { sideEffectById },
  } = useSideEffect();
  const {
    selectors: { tasksById },
  } = useTasks();
  const {
    selectors: { medicalChildrenByGamerId },
  } = useChildren();

  const emptyFormState: NoteFormState = {
    title: "",
    text: "",
    date: Tools.Time.Dates.getMidnightDate(),
    sideEffectId: null,
    routineCategoryId: null,
    skillId: null,
  };

  const [formState, setFormState] = useState<NoteFormState>(emptyFormState);
  const [loading, setLoading] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const followedSideEffects = useMemo(() => {
    const followedSideEffectsForChild = getActiveFollowedSideEffects(
      gamerChild.id
    );

    return followedSideEffectsForChild.map((se) => se.sideEffectId);
  }, [gamerChild]);

  const myRoutineCategoryOptions: string[] = useMemo(() => {
    const categories: string[] = [];

    Object.keys(tasksById).forEach((tid) => {
      const category =
        tasksById[tid].category ?? catmap[tasksById[tid].icon.toFixed(0)]["0"];

      if (!categories.includes(category)) {
        categories.push(category);
      }
    });

    return categories;
  }, [tasksById, language]);

  const resetForm = () => {
    setFormState(emptyFormState);
    setShowCancelDialog(false);
    setShowDeleteDialog(false);
  };

  const onComplete = async (state: NoteFormState) => {
    if (!state.date || !note) {
      return;
    }

    setLoading(true);

    try {
      await editNote({
        ...note,
        title: state.title,
        text: state.text,
        eventDate: Tools.Time.Strings.getDateStringFromDate(state.date),
        sideEffectId: state.sideEffectId ?? undefined,
        routineCategoryId: state.routineCategoryId ?? undefined,
        needId: state.skillId,
      });

      resetForm();
      onClose();
    } catch (err: unknown) {
      handleUnknownError(err);
    } finally {
      setLoading(false);
    }
  };

  const onDelete = () => {
    setShowDeleteDialog(true);
  };

  const onDeleteConfirm = () => {
    if (note) {
      deleteNote(note.noteId, note.eventDate)
        .then(() => {
          resetForm();
          onClose();
        })
        .catch((err) => {
          handleUnknownError(err);
        });
    } else {
      onClose();
    }
  };

  const hasNoteBeenEditied = (
    formState: NoteFormState,
    note: Note | null
  ): boolean => {
    console.log("hasNoteBeenEditied", formState, note);

    if (note === null) {
      return isEqual(formState, emptyFormState);
    } else {
      return !(
        formState.title === note.title &&
        formState.text === note.text &&
        formState.sideEffectId === (note.sideEffectId ?? null) &&
        formState.routineCategoryId === (note.routineCategoryId ?? null) &&
        formState.skillId === note.needId
      );
    }
  };

  const onCancel = () => {
    const noteEdited = hasNoteBeenEditied(formState, note);

    if (!noteEdited) {
      onCancelConfirm();
    } else {
      setShowCancelDialog(true);
    }
  };

  const onCancelConfirm = () => {
    resetForm();
    onClose();
  };

  useEffect(() => {
    if (note) {
      setFormState({
        title: note.title,
        text: note.text,
        date: Tools.Time.Dates.parseDateStringToJsDate(note.eventDate),
        sideEffectId: note.sideEffectId ?? null,
        routineCategoryId: note.routineCategoryId ?? null,
        skillId: note.needId,
      });
    }
  }, [note]);

  const targetedSkills = (): string[] => {
    const output = medicalChildrenByGamerId[gamerChild.id]?.needs;
    if (output !== undefined) {
      return output;
    }
    return [];
  };

  return open ? (
    <>
      <NoteFormPage
        edit
        formState={formState}
        setFormState={setFormState}
        onComplete={onComplete}
        onCancel={onCancel}
        onDelete={onDelete}
        language={language}
        sideEffectsById={sideEffectById}
        followedSideEffects={followedSideEffects}
        safeAreas={SAFE_AREAS}
        loading={loading}
        taskCategoryLoc={taskCategoryLoc}
        userTaskCategoryIds={myRoutineCategoryOptions}
        childName={gamerChild.name}
        childIconSrc={ChildIcons[gamerChild.icon]}
        coachImageSrc={CoachAssets.CoachDynamic}
        targetedSkills={targetedSkills()}
        skillIds={orderedNeedKeys}
      />
      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        title={t("general.cancel.title")}
        text={t("general.cancel.generic")}
        onPositiveAction={onCancelConfirm}
        onNegativeAction={() => setShowCancelDialog(false)}
        imgSrc={DialogAssets.CancelTag}
        hideIrreversibleWarning
        data-cy="note-edition-cancel"
      />
      <Dialogs.ConfirmationDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title={t("journal.notes.delete.title")}
        text={t("journal.notes.delete.text")}
        onPositiveAction={onDeleteConfirm}
        onNegativeAction={() => setShowDeleteDialog(false)}
        imgSrc={DialogAssets.DeleteTag}
        data-cy="note-delete-confirm"
      />
    </>
  ) : null;
};

export default NoteEdition;
