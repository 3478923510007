import type { Locale as DateFnsLocale } from "date-fns";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import i18next from "i18next";

// there's no neutral 'en' because date formats change between English locales
// (e.g. US: "March 29, 2022"; GB: "29 March 2022"); let's use 'CA' for now.
import enDateLocale from "date-fns/locale/en-CA";
import frDateLocale from "date-fns/locale/fr";
import deDateLocale from "date-fns/locale/de";
import { useAnalytics } from "@neurosolutionsgroup/analytics";
import { Language } from "@neurosolutionsgroup/models";
import { useRemoteConfig } from "@neurosolutionsgroup/remote-config";
import { initI18n } from "common/i18n/i18n";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SelectOption } from "@neurosolutionsgroup/components";

initI18n();

const dateLocales: Record<Language, DateFnsLocale> = {
  en: enDateLocale,
  fr: frDateLocale,
  de: deDateLocale,
};

interface LanguageHookResult {
  language: Language;
  dateLocale: DateFnsLocale;
  setLanguage: (lang: Language) => void;
  languageSet: boolean;
  languages: Language[];
  languageOptions: SelectOption<Language>[];
}

export const LanguageContext = React.createContext<LanguageHookResult>({
  language: "en",
  dateLocale: dateLocales["en"],
  setLanguage: () => {
    return;
  },
  languageSet: false,
  languages: ["en"],
  languageOptions: [],
});

export const LanguageProvider: React.FC<PropsWithChildren> = (props) => {
  const { setLanguage: setAnalyticsLanguage } = useAnalytics();
  const { getActivatedLanguages } = useRemoteConfig();

  const [language, setLanguage] = useState<Language>(
    getActivatedLanguages()[0]
  );
  const [languageSet, setLanguageSet] = useState(false);

  const languages = useMemo(() => {
    return getActivatedLanguages();
  }, [getActivatedLanguages]);

  const languageOptions = useMemo(() => {
    const options: SelectOption<Language>[] = [];

    if (languages.includes("en")) {
      options.push({ value: "en", label: "English" });
    }

    if (languages.includes("fr")) {
      options.push({ value: "fr", label: "Français" });
    }

    if (languages.includes("de")) {
      options.push({ value: "de", label: "Deutsche" });
    }

    return options;
  }, [languages]);

  useEffect(() => {
    if (languages.includes(language)) {
      setAnalyticsLanguage(language);
    } else {
      setAnalyticsLanguage(languages[0]);
    }

    setLanguageSet(true);
  }, [language]);

  return (
    <LanguageContext.Provider
      value={{
        language,
        dateLocale: dateLocales[language],
        setLanguage,
        languageSet,
        languages,
        languageOptions,
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={dateLocales[language]}
      >
        {props.children}
      </LocalizationProvider>
    </LanguageContext.Provider>
  );
};

const useLanguage = (): LanguageHookResult => {
  const {
    language,
    dateLocale,
    setLanguage,
    languageSet,
    languages,
    languageOptions,
  } = useContext(LanguageContext);

  const setLanguageProxy = (language: Language): void => {
    if (languages.includes(language)) {
      setLanguage(language);
      i18next.changeLanguage(language);
    }
  };

  return {
    language,
    dateLocale,
    setLanguage: setLanguageProxy,
    languageSet,
    languages,
    languageOptions,
  };
};

export default useLanguage;
