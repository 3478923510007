import { getHeaders, getURL, handleApiError } from "./utils";
import { ChatResponse } from "@neurosolutionsgroup/models";
import axios from "axios";

const createConversation = async (): Promise<string> => {
  const URL = getURL() + "/chatbot/conversations";

  const headers = await getHeaders(true, "createConversation");

  try {
    const result = await axios.post(URL, undefined, { headers });

    return result.data.id;
  } catch (err) {
    return handleApiError(err);
  }
};

const sendChatMessage = async (
  message: string,
  conversationId: string,
  language: "English" | "French"
): Promise<ChatResponse> => {
  const URL = getURL() + "/chatbot/chat/" + conversationId;

  const headers = await getHeaders(true, "sendChatMessage");

  try {
    const result = await axios.post<ChatResponse>(
      URL,
      { message, language },
      { headers }
    );

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const Chat = {
  createConversation,
  sendChatMessage,
};

export default Chat;
