interface ChatLoaderProps {
  color: string;
  width?: string;
}

const ChatLoader = ({
  color = "#000",
  width = "30px",
}: ChatLoaderProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} viewBox="0 0 200 100">
      <circle
        fill={color}
        stroke={color}
        strokeWidth="15"
        r="15"
        cx="40"
        cy="50"
      >
        <animate
          attributeName="opacity"
          calcMode="spline"
          dur="1.5"
          values="1;0;1;"
          keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite"
          begin="-.4"
        ></animate>
      </circle>
      <circle
        fill={color}
        stroke={color}
        strokeWidth="15"
        r="15"
        cx="100"
        cy="50"
      >
        <animate
          attributeName="opacity"
          calcMode="spline"
          dur="1.5"
          values="1;0;1;"
          keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite"
          begin="-.2"
        ></animate>
      </circle>
      <circle
        fill={color}
        stroke={color}
        strokeWidth="15"
        r="15"
        cx="160"
        cy="50"
      >
        <animate
          attributeName="opacity"
          calcMode="spline"
          dur="1.5"
          values="1;0;1;"
          keySplines=".5 0 .5 1;.5 0 .5 1"
          repeatCount="indefinite"
          begin="0"
        ></animate>
      </circle>
    </svg>
  );
};

export default ChatLoader;
