import firebase from "../FireBase/Firebase";
import { FirestoreCollection, SideEffect } from "@neurosolutionsgroup/models";
const SideEffectQueries = {
  getAll: async (): Promise<{ [key: string]: SideEffect }> => {
    return (await firebase.tools.getCollection(
      FirestoreCollection.SideEffect
    )) as { [key: string]: SideEffect };
  },
};

export default SideEffectQueries;
