import { Box, SxProps, Theme, Typography } from "@mui/material";
import {
  BackgroundHexagons,
  calculateStickyButtonPagePadding,
  FullscreenPage,
  PageContentContainer,
  PremiumTag,
  StickyButtons,
} from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import TierComparisonCard from "./Components/TierComparisonCard/TierComparisonCard";

interface SubscriptionScreen1Props {
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
  open: boolean;
  safeAreas: SafeAreas;
  subscriptionCancelImgSrc: string;
  subscriptionDecorationImgSrc: string;
  sx?: SxProps<Theme>;
}

const SubscriptionScreen1 = ({
  onCancel,
  onConfirm,
  open,
  safeAreas,
  subscriptionCancelImgSrc,
  subscriptionDecorationImgSrc,
  sx,
}: SubscriptionScreen1Props): JSX.Element | null => {
  const { t } = useTranslation();

  return open ? (
    <FullscreenPage
      sx={[
        {
          backgroundColor: "primary.main",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <BackgroundHexagons />

      <PageContentContainer
        safeAreas={safeAreas}
        sx={{
          paddingBottom: calculateStickyButtonPagePadding(safeAreas, true),
          position: "relative",
          zIndex: 1,
        }}
      >
        <Box display="flex" justifyContent="flex-end" pr={2} pt={2}>
          <PremiumTag text={t("navigation.subscription.tiers.premium")} />
        </Box>

        <Box
          sx={{
            marginX: "auto",
            img: {
              maxWidth: "80vw",
              maxHeight: "250px",
              objectFit: "contain",
            },
          }}
        >
          <img src={subscriptionDecorationImgSrc} alt="" />
        </Box>

        <Typography
          textAlign="center"
          variant="h2"
          color="primary.contrastText"
        >
          {t("navigation.subscription.screenOne.title")}
        </Typography>

        <Box my={3} mx={2} display="flex" justifyContent="center">
          <Box maxWidth="600px" flexGrow={1}>
            <TierComparisonCard />
          </Box>
        </Box>

        <Box
          sx={{
            marginX: "auto",
            img: {
              maxWidth: "60vw",
              maxHeight: "200px",
              objectFit: "contain",
            },
          }}
        >
          <img src={subscriptionCancelImgSrc} alt="" />
        </Box>

        <Typography
          textAlign="center"
          variant="h3"
          color="primary.contrastText"
          mt={1}
          mb={2}
        >
          {t("navigation.subscription.screenOne.cancel")}
        </Typography>

        <StickyButtons
          onConfirm={onConfirm}
          confirmColor="success"
          confirmText={t("navigation.subscription.screenOne.actions.confirm")}
          onCancel={onCancel}
          cancelColor="secondary"
          cancelVariant="blank"
          cancelText={t("general.nothanks")}
          vertical
          safeAreas={safeAreas}
        />
      </PageContentContainer>
    </FullscreenPage>
  ) : null;
};

export default SubscriptionScreen1;
