import { Challenge, ChallengeHistory } from "@neurosolutionsgroup/models";
import Time from "../../../Time";

const generateMissedChallengeValidations = (
  challenge: Challenge
): ChallengeHistory[] => {
  const missedValidations: ChallengeHistory[] = [];

  if (challenge.endDate && challenge.endDate < Time.Dates.getTimeStamp()) {
    const missingHistoriesLength =
      (challenge.occurrences ?? 1) - challenge.history.length;

    if (missingHistoriesLength > 0) {
      for (let i = 0; i < missingHistoriesLength; i++) {
        missedValidations.push({
          childStatus: null,
          childExecutionDate: null,
          parentStatus: null,
          validationDate: null,
          week: null,
          day: null,
          canBeRetried: null,
        });
      }
    }
  }

  return missedValidations;
};

export default generateMissedChallengeValidations;
