import {
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Drawer,
  drawerClasses,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { WebviewLink } from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";
import AuthTextField from "./components/AuthTextField";
import ShowPasswordAdornment from "./components/ShowPasswordAdornment";
import PasswordStrength from "./components/PasswordStrength";

interface SignUpSecondPageProps {
  onSubmit: (name: string, password: string) => void;
  onBack: VoidFunction;
  onPrivacyPolicyClick: VoidFunction;
  onTermsClick: VoidFunction;
  open: boolean;
  loading: boolean;
  safeAreas: SafeAreas;
  logoImageString: string;
  productName: string;
}

const SignUpSecondPage = ({
  onSubmit,
  onBack,
  onPrivacyPolicyClick,
  onTermsClick,
  open,
  loading,
  safeAreas,
  logoImageString,
  productName,
}: SignUpSecondPageProps): JSX.Element => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const [name, setName] = useState("");
  const [nameError, setNameError] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordConfirmationError, setPasswordConfirmationError] =
    useState(false);
  const [displayPassword, setDisplayPassword] = useState<boolean>(false);
  const [displayPasswordMatch, setDisplayPasswordMatch] =
    useState<boolean>(false);

  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfirmationRef = useRef<HTMLInputElement>(null);

  const validateForm = () => {
    return (
      name.length > 0 &&
      password.length >= 6 &&
      password === passwordConfirmation
    );
  };

  const onFormSubmit: MouseEventHandler = (e) => {
    e.preventDefault();

    if (!name) {
      setNameError(true);
      return;
    }

    if (password.length < 6) {
      setPasswordError(true);
      return;
    }

    if (passwordConfirmation !== password) {
      setPasswordConfirmationError(true);
      return;
    }

    onSubmit(name, password);
  };

  const onNameBlur = () => {
    if (name.length === 0) {
      setNameError(true);
    }
  };

  useEffect(() => {
    setNameError(false);
  }, [name]);

  const onPasswordBlur = () => {
    if (password.length < 6) {
      setPasswordError(true);
    }

    if (passwordConfirmation.length > 0 && password !== passwordConfirmation) {
      setPasswordConfirmationError(true);
    }
  };

  useEffect(() => {
    setPasswordError(false);
    setPasswordConfirmationError(false);
  }, [password]);

  const onPasswordConfirmationBlur = () => {
    if (passwordConfirmation !== password) {
      setPasswordConfirmationError(true);
    }
  };

  useEffect(() => {
    setPasswordConfirmationError(false);
  }, [passwordConfirmation]);

  const handleExternalLinkClicked = (link: "terms" | "privacy") => {
    if (link === "terms") {
      onTermsClick();
    } else {
      onPrivacyPolicyClick();
    }
  };

  return (
    <Drawer
      open={open}
      anchor="bottom"
      sx={(theme) => ({
        [`& .${drawerClasses.paper}`]: {
          height: "100%",
          backgroundColor: theme.palette.background.default,
          borderRadius: 0,
        },
      })}
    >
      <form>
        <Grid
          container
          direction="column"
          spacing={2}
          p={1}
          px={2}
          mt={safeAreas.top}
        >
          <Grid item display="flex" alignItems="start">
            <Box flex={1}>
              <ButtonBase
                onClick={onBack}
                sx={{
                  padding: "12px",
                  borderRadius: "10px",
                  svg: {
                    width: "2rem",
                    height: "2rem",
                  },
                }}
              >
                <Icons.ArrowIcon
                  arrowDirection="left"
                  color={palette.primary.main}
                />
              </ButtonBase>
            </Box>
            <Box
              sx={{
                marginX: "auto",
                img: {
                  height: "100px",
                },
              }}
            >
              <img src={logoImageString} alt={productName} />
            </Box>
            <Box flex={1} />
          </Grid>
          <Grid item>
            <Typography variant="h4" textAlign="center">
              {t("auth.signup.title", { product: productName })}
            </Typography>
          </Grid>
          <Grid item>
            <AuthTextField
              id="name-input"
              data-cy="name-input"
              label={t("auth.inputs.name.label")}
              value={name}
              onChange={(e) => setName(e.currentTarget.value)}
              type="text"
              autoFocus={true}
              onBlur={onNameBlur}
              error={nameError}
              errorText={t("auth.inputs.name.error.required")}
              onReturnKey={() => {
                passwordRef.current?.focus();
                passwordRef.current?.click();
              }}
            />
          </Grid>
          <Grid item>
            <AuthTextField
              ref={passwordRef}
              id="password-input"
              data-cy="password-input"
              label={t("auth.inputs.password.label")}
              placeholder={t("auth.inputs.password.placeholder")}
              value={password}
              onChange={(e) => setPassword(e.currentTarget.value)}
              autoComplete="new-password"
              onBlur={onPasswordBlur}
              error={passwordError}
              errorText={t("auth.inputs.password.error.length")}
              onReturnKey={() => {
                passwordConfirmationRef.current?.focus();
                passwordConfirmationRef.current?.click();
              }}
              endAdornment={
                <ShowPasswordAdornment
                  active={displayPassword}
                  setActive={setDisplayPassword}
                />
              }
              type={displayPassword ? "text" : "password"}
            />
            <Box px={2} pt={0.5}>
              <PasswordStrength password={password} />
            </Box>
          </Grid>
          <Grid item>
            <AuthTextField
              ref={passwordConfirmationRef}
              id="password-confirmation-input"
              data-cy="password-confirmation-input"
              label={t("auth.inputs.passwordConfirmation.label")}
              placeholder={t("auth.inputs.passwordConfirmation.label")}
              value={passwordConfirmation}
              onChange={(e) => setPasswordConfirmation(e.currentTarget.value)}
              autoComplete="new-password"
              onBlur={onPasswordConfirmationBlur}
              error={passwordConfirmationError}
              errorText={t("auth.inputs.passwordConfirmation.error.match")}
              endAdornment={
                <ShowPasswordAdornment
                  active={displayPasswordMatch}
                  setActive={setDisplayPasswordMatch}
                />
              }
              type={displayPasswordMatch ? "text" : "password"}
            />
          </Grid>
          <Grid item>
            <Typography fontSize={"0.75rem"} textAlign="center">
              <WebviewLink
                onClick={() => {
                  handleExternalLinkClicked("terms");
                }}
              >
                {t("settings.tos")}
              </WebviewLink>
              &nbsp;&&nbsp;
              <WebviewLink
                onClick={() => {
                  handleExternalLinkClicked("privacy");
                }}
              >
                {t("settings.privacyPolicy")}
              </WebviewLink>
            </Typography>
          </Grid>
          <Grid item>
            <Button
              data-cy="submit-button-signup2"
              onClick={onFormSubmit}
              color="secondary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={!validateForm() || loading}
            >
              {loading ? (
                <CircularProgress
                  size="1.5rem"
                  style={{
                    color: "#fff",
                  }}
                />
              ) : (
                t("auth.signup.action")
              )}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Drawer>
  );
};

export default SignUpSecondPage;
