import React from "react";
import clsx from "clsx";
import { Icons } from "@neurosolutionsgroup/components";
import { Button, useTheme } from "@mui/material";

export interface TaskButtonProps extends React.ComponentProps<typeof Button> {
  /** Align the label to the left, and show a chevron on the right end. */
  showChevron?: boolean;
}

/**
 * A full-width button that represents a full task. It takes up the entire
 * container width and is optionally capped with a chevron at the end.
 *
 * @param props @see TaskButtonProps
 */
const TaskButton: React.FC<TaskButtonProps> = ({
  showChevron = true,
  ...props
}) => {
  const ROOT_CLASS = "task-button";

  const classes = [
    ROOT_CLASS,
    { [`${ROOT_CLASS}__with-chevron`]: showChevron },
    props.className,
  ];

  const { palette } = useTheme();

  return (
    <Button {...props} className={clsx(...classes)}>
      <div className={`${ROOT_CLASS}__children`}>{props.children}</div>
      {showChevron ? (
        <Icons.ArrowIcon
          arrowDirection="right"
          color={palette.secondary.main}
        />
      ) : null}
    </Button>
  );
};

export default TaskButton;
