import { useTranslation } from "react-i18next";
import {
  ControlledDrawer,
  DrawerMenuSection,
  Icons,
} from "@neurosolutionsgroup/components";
import { Typography, useTheme } from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";

export interface SideEffectsCardMenuProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  onEdit: () => void;
  safeAreas: SafeAreas;
}

const SideEffectsCardMenu = ({
  isOpen,
  setIsOpen,
  onEdit,
  safeAreas,
}: SideEffectsCardMenuProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  return (
    <ControlledDrawer
      open={isOpen}
      anchor="bottom"
      onClose={() => setIsOpen(false)}
      safeAreas={safeAreas}
    >
      <DrawerMenuSection variant="header">
        <Typography variant="h4">
          {t("medication.sideEffectsCard.sideEffectOption.title")}
        </Typography>
      </DrawerMenuSection>
      <DrawerMenuSection
        onClick={onEdit}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          svg: {
            height: "1.5rem",
            width: "1.5rem",
          },
        }}
      >
        <Icons.EditIcon color={palette.secondary.main} />
        <Typography>
          {t("medication.sideEffectsCard.sideEffectOption.editSideEffect")}
        </Typography>
      </DrawerMenuSection>
    </ControlledDrawer>
  );
};

export default SideEffectsCardMenu;
