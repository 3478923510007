import { Box, Grid } from "@mui/material";
import {
  logo_dec,
  logo_firefolk,
  logo_fpt,
  logo_medteq,
  logo_mfc,
  logo_neuro,
  logo_sarbakan,
  logo_uqtr,
  logo_vqb,
} from "assets/credits";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React from "react";
import { useTranslation } from "react-i18next";

const neuroEmployees = [
  {
    name: "Annie Martineau",
    role: { fr: "Présidente et Fondatrice", en: "President and Founder" },
  },
  {
    name: "Édouard Garceau-Bolduc",
    role: { fr: "Directeur marketing", en: "Marketing Director" },
  },
  {
    name: "Quentin Cervilla",
    role: { fr: "Créateur de contenus", en: "Content Creator" },
  },
  {
    name: "Laurence Carignan",
    role: { fr: "Gestionnaire de produit", en: "Product Owner" },
  },
  {
    name: "Anne-Sophie Fournier",
    role: { fr: "Gestionnaire de produit", en: "Product Owner" },
  },
  {
    name: "Joanie Paquet",
    role: { fr: "Designer UX/UI", en: "UX/UI Designer" },
  },
  {
    name: "Olivier Dupont",
    role: { fr: "Directeur gamification", en: "Gamification Director" },
  },
  {
    name: "Hoang Yen Pham",
    role: { fr: "Développeuse Full Stack", en: "Full Stack Developer" },
  },
  {
    name: "Alexandre Meesen",
    role: { fr: "Concepteur de niveaux", en: "Level Designer" },
  },
  {
    name: "Andrew Faulkner",
    role: { fr: "Développeur Full Stack", en: "Full Stack Developer" },
  },
  {
    name: "Antoine Lacroix",
    role: { fr: "Développeur Full Stack", en: "Full Stack Developer" },
  },
  {
    name: "William Munoz",
    role: {
      fr: "Spécialiste Assurance Qualité",
      en: "Quality Assurance Specialist",
    },
  },
];

const Credits: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const getRole = (role: { en: string; fr: string }) => {
    switch (language) {
      case "fr":
        return role.fr;
      case "en":
      case "de":
        return role.en;
    }
  };

  return (
    <Box mb={4}>
      <Box m={1} mb={6}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} container justifyContent="center">
            <img
              className="credits__logo credits__logo--neuro"
              src={logo_neuro}
              alt="Neuro Solutions Group"
            />
          </Grid>
          <Grid
            item
            container
            direction="column"
            spacing={1}
            xs={12}
            sm={8}
            md={4}
          >
            {neuroEmployees.map((ne) => (
              <Grid item container>
                <Grid item xs={6}>
                  <b>{ne.name}</b>
                </Grid>
                <Grid item xs={6}>
                  {getRole(ne.role)}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
            <img
              className="credits__logo credits__logo--partner"
              src={logo_uqtr}
              alt="UQTR"
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
            <img
              className="credits__logo credits__logo--partner"
              src={logo_firefolk}
              alt="Firefolk"
            />
          </Grid>
          <Grid item container justifyContent="center" xs={12} sm={6} md={4}>
            <img
              className="credits__logo credits__logo--partner"
              src={logo_sarbakan}
              alt="Sarbakan"
            />
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <h3>{t("settings.partnership")}</h3>
        <Grid container direction="row" justifyContent="center" spacing={3}>
          <Grid item xs={7} justifyContent="center" alignItems="center">
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_mfc}
                alt="Media Fund Canada"
              />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_vqb}
                alt="Ville de Québec"
              />
            </Box>
          </Grid>
          <Grid item xs={8} sm={6}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_medteq}
                alt="MEDTEQ"
              />
            </Box>
          </Grid>
          <Grid item xs={8} sm={6}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_fpt}
                alt="Fondation des petits trésors"
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              height="100%"
              justifyContent="center"
              alignItems="center"
            >
              <img
                className="credits__logo credits__logo--partner"
                src={logo_dec}
                alt="Développement économique Canada"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Credits;
