import {
  NotificationCategory,
  NotificationCategorySettings,
  NotificationsSettings,
} from "@neurosolutionsgroup/models";
import { useNotificationsContext } from "./NotificationContext";
import NotificationsManager from "./NotificationsManager";
import {
  FeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import useParameters from "../Parameters/useParameters";
import { Tools } from "@neurosolutionsgroup/tools";

interface UseNotificationsResult {
  ftueNotificationOpen: boolean;
  notificationsInitializationComplete: boolean;
  globalOptIn: boolean;
  notificationsSettings: NotificationCategorySettings;
  requiresNativePermission: boolean;
  optInLastSeen?: number;
  updateSettings: (
    globalOptIn: boolean,
    categories: NotificationCategorySettings
  ) => Promise<NotificationsSettings>;
  requestNativePermission: () => Promise<boolean>;
  setFtueNotificationOpen: (open: boolean) => void;
  setOptInLastSeen: () => Promise<void>;
  triggerFtueNotificationOptIn: VoidFunction;
  updateRoutineNotifications: () => Promise<boolean | null>;
}

const useNotifications = (): UseNotificationsResult => {
  const {
    ftueNotificationOpen,
    notificationsInitializationComplete,
    globalOptIn,
    notificationsSettings,
    requiresNativePermission,
    optInLastSeen,
    setFtueNotificationOpen,
    setGlobalOptIn,
    setNotificationsSettings,
    setOptInLastSeen: setOptInLastSeenState,
    triggerFtueNotificationOptIn,
  } = useNotificationsContext();

  const { version } = useParameters();
  const { checkFeatureFlag } = useRemoteConfig();

  const updateSettings = async (
    globalOptIn: boolean,
    categories: NotificationCategorySettings
  ): Promise<NotificationsSettings> => {
    const notificationsManager = new NotificationsManager(
      checkFeatureFlag(FeatureFlag.CustomerIo, version),
      Tools.Environment.isDevBuild()
    );

    await notificationsManager.updateSettings(globalOptIn, categories);

    setGlobalOptIn(globalOptIn);
    setNotificationsSettings(categories);

    if (globalOptIn) {
      await notificationsManager.requestNativePermission();
    }

    return {
      globalOptIn,
      categories,
    };
  };

  const setOptInLastSeen = async (): Promise<void> => {
    const notificationsManager = new NotificationsManager(
      checkFeatureFlag(FeatureFlag.CustomerIo, version),
      Tools.Environment.isDevBuild()
    );

    await notificationsManager.setOptInLastSeen();

    setOptInLastSeenState(Tools.Time.Dates.getTimeStamp());
  };

  const requestNativePermission = async (): Promise<boolean> => {
    const notificationsManager = new NotificationsManager(
      checkFeatureFlag(FeatureFlag.CustomerIo, version),
      Tools.Environment.isDevBuild()
    );

    const res = await notificationsManager.requestNativePermission();
    if (res) {
      return res;
    }
    return false;
  };

  const updateRoutineNotifications = async (): Promise<boolean | null> => {
    if (globalOptIn && notificationsSettings[NotificationCategory.Routine]) {
      const notificationsManager = new NotificationsManager(
        checkFeatureFlag(FeatureFlag.CustomerIo, version),
        Tools.Environment.isDevBuild()
      );

      return notificationsManager.toggleRoutineNotifications(true);
    } else {
      return false;
    }
  };

  return {
    ftueNotificationOpen,
    setFtueNotificationOpen,
    notificationsInitializationComplete,
    globalOptIn,
    notificationsSettings,
    requiresNativePermission,
    optInLastSeen,
    updateSettings,
    requestNativePermission,
    setOptInLastSeen,
    triggerFtueNotificationOptIn,
    updateRoutineNotifications,
  };
};

export default useNotifications;
