import { useTheme } from "@mui/material";
import { InputAdornment, ButtonBase } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import React from "react";

export interface ShowPasswordAdornmentProps {
  active: boolean;
  setActive: (active: boolean) => void;
}

const ShowPasswordAdornment: React.FC<ShowPasswordAdornmentProps> = ({
  active,
  setActive,
}) => {
  const theme = useTheme();

  return (
    <InputAdornment position="end">
      <ButtonBase
        onClick={() => setActive(!active)}
        sx={{
          padding: "6px 12px",
          borderRadius: "5px",
          svg: {
            width: "1.5rem",
            height: "1.5rem",
          },
        }}
      >
        <Icons.EyeIcon
          color={
            active
              ? theme.palette.secondary.light
              : theme.palette.secondary.main
          }
          variant={active ? "open" : "closed"}
        />
      </ButtonBase>
    </InputAdornment>
  );
};
export default ShowPasswordAdornment;
