import { Box, ButtonBase, Grid, Typography } from "@mui/material";

export interface ScrollSelectOption<T extends string | number> {
  value: T;
  label: string | JSX.Element;
  dataCy?: string;
}

export interface ScrollSelectProps<T extends string | number> {
  value: T | null;
  options: ScrollSelectOption<T>[];
  onChange: (value: T) => void;
  paddingX: number | string;
  fullWidth?: boolean;
}

const ScrollSelect = <T extends string | number>({
  value,
  options,
  onChange,
  fullWidth = true,
  paddingX,
}: ScrollSelectProps<T>): JSX.Element => {
  return (
    <Box
      sx={{
        "width": fullWidth ? "100vw" : "100%",
        "overflowX": "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        "marginLeft": typeof paddingX === "string" ? "-" + paddingX : -paddingX,
        "paddingLeft": paddingX,
        "paddingRight": paddingX,
      }}
    >
      <Grid container spacing={1} flexWrap="nowrap">
        {options.map((option) => (
          <Grid key={option.value} item>
            <ButtonBase
              onClick={() => onChange(option.value)}
              sx={(theme) => ({
                backgroundColor:
                  value === option.value
                    ? theme.palette.secondary.main
                    : "#fff",
                borderRadius: 2,
                mr: 1,
                p: 1,
                minWidth: "3rem",
                whiteSpace: "nowrap",
              })}
              data-cy={option.dataCy}
            >
              {typeof option.label === "string" ? (
                <Typography
                  color={value === option.value ? "#fff" : "secondary"}
                >
                  {option.label}
                </Typography>
              ) : (
                option.label
              )}
            </ButtonBase>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ScrollSelect;
