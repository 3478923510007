import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const DashboardIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="dashboard" {...props}>
      <path
        d="m 1.1111111,11.111111 h 6.6666667 c 0.611111,0 1.111111,-0.5 1.111111,-1.111111 V 1.1111111 C 8.8888888,0.5 8.3888888,0 7.7777778,0 H 1.1111111 C 0.5,0 0,0.5 0,1.1111111 V 10 c 0,0.611111 0.5,1.111111 1.1111111,1.111111 z m 0,8.888889 h 6.6666667 c 0.611111,0 1.111111,-0.5 1.111111,-1.111111 v -4.444444 c 0,-0.611111 -0.5,-1.111111 -1.111111,-1.111111 H 1.1111111 C 0.5,13.333334 0,13.833334 0,14.444445 v 4.444444 C 0,19.5 0.5,20 1.1111111,20 Z m 11.1111109,0 h 6.666667 C 19.5,20 20,19.5 20,18.888889 V 10 C 20,9.3888888 19.5,8.8888888 18.888889,8.8888888 h -6.666667 c -0.611111,0 -1.111111,0.5 -1.111111,1.1111112 v 8.888889 C 11.111111,19.5 11.611111,20 12.222222,20 Z M 11.111111,1.1111111 v 4.4444444 c 0,0.6111111 0.5,1.1111111 1.111111,1.1111111 h 6.666667 C 19.5,6.6666666 20,6.1666666 20,5.5555555 V 1.1111111 C 20,0.5 19.5,0 18.888889,0 h -6.666667 c -0.611111,0 -1.111111,0.5 -1.111111,1.1111111 z"
        fill={color}
      />
    </BaseIcon>
  );
};

export default DashboardIcon;
