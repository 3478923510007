import {
  FirestoreTaskHistoriesSummaryDocument,
  TaskHistory,
  TaskSummaryByTask,
} from "@neurosolutionsgroup/models";
import { add, startOfDay, sub } from "date-fns";
import { v4 } from "uuid";

const getRandomStatus = (failChance: number): boolean => {
  return Math.random() > failChance;
};

export interface HistoryGenerationRoutine {
  end: number;
  tasks: {
    id: string;
  }[];
}

export const generateTaskHistoryData = (
  userId: string,
  tenantId: string,
  gamerChildId: string,
  currentDate: Date,
  routine: HistoryGenerationRoutine,
  accountCreationTimestamp: number,
  start?: Date,
  endDays?: number,
  lastGeneration?: number,
  // Most recent history to add for certain tasks.
  taskSpecificHistory?: {
    [taskId: string]: (boolean | null)[];
  }
): {
  rootSummary: FirestoreTaskHistoriesSummaryDocument;
  taskHistoriesSummaries: TaskSummaryByTask;
} => {
  const startOfData = start ?? sub(currentDate, { weeks: 3 });
  const endOfData = sub(currentDate, { days: endDays ?? 1 });
  let date = startOfData;

  const taskHistoriesSummaries: TaskSummaryByTask = {};

  while (date.getTime() < endOfData.getTime()) {
    // Generate Data.
    const dayOfWeek = date.getDay();
    const dayStart = startOfDay(date);
    const routineEnd = add(dayStart, { seconds: routine.end });
    const routineEndTimestamp = Math.floor(routineEnd.getTime() / 1000);

    // Only generate for weekdays.
    if (dayOfWeek >= 1 && dayOfWeek <= 5) {
      routine.tasks.forEach((task) => {
        const history: TaskHistory = {
          task: task.id,
          id: v4(),
          childStatus: null,
          status: getRandomStatus(0.2),
          statusSetTime: routineEndTimestamp,
          dueTime: routineEndTimestamp,
          confirmTime: routineEndTimestamp,
          durationSeconds: null,
        };

        if (taskHistoriesSummaries[task.id]) {
          taskHistoriesSummaries[task.id].history.push(history);
        } else {
          taskHistoriesSummaries[task.id] = {
            userId,
            tenantId,
            gamerChildId,
            id: task.id,
            unvalidated: {},
            history: [history],
          };
        }
      });
    }

    date = add(date, { days: 1 });
  }

  if (taskSpecificHistory) {
    Object.keys(taskSpecificHistory).forEach((taskId) => {
      const newHistory = taskSpecificHistory[taskId];

      const historyLength = taskHistoriesSummaries[taskId].history.length;

      newHistory.forEach((h, i) => {
        taskHistoriesSummaries[taskId].history[
          historyLength - newHistory.length + i
        ].status = h;
      });
    });
  }

  const rootSummary: FirestoreTaskHistoriesSummaryDocument = {
    gamerChildId,
    userId,
    tenantId,
    migrationV2: accountCreationTimestamp,
    lastGeneration: lastGeneration ?? Math.floor(currentDate.getTime() / 1000),
    taskHistories: {},
  };

  return {
    rootSummary,
    taskHistoriesSummaries,
  };
};
