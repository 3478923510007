import clsx from "clsx";
import React from "react";

interface DrawerSectionProps {
  className?: string;
  variant?: "header" | "standard" | "blank";
  onClick?: () => void;
}

const DrawerSection: React.FC<React.PropsWithChildren<DrawerSectionProps>> = ({
  className,
  variant = "standard",
  onClick,
  ...props
}) => {
  return (
    <div
      className={clsx(
        "drawer-section",
        "drawer-section--" + variant,
        className
      )}
      onClick={onClick}
    >
      {props.children}
    </div>
  );
};

export default DrawerSection;
