import {
  Box,
  Button,
  Divider,
  drawerClasses,
  Grid,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {
  ControlledDrawer,
  Filter,
  SelectInputButton,
} from "@neurosolutionsgroup/components";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Language,
  SafeAreas,
  SideEffectById,
  TaskCategoryLoc,
} from "@neurosolutionsgroup/models";
import SubjectPickerList from "./SubjectPickerList";
import { needsLoc } from "@neurosolutionsgroup/localization";
import { Tools } from "@neurosolutionsgroup/tools";
export interface SubjectPickerProps {
  userTaskCategories: string[];
  taskCategoryLoc: TaskCategoryLoc;
  sideEffectById: SideEffectById;
  activeFollowedSideEffects: string[];
  targetedSkills: string[];
  skillIds: string[];
  initialSideEffectId: string | null;
  initialRoutineCategoryId: string | null;
  initialSkillId: string | null;
  onSubjectChange: (
    sideEffectId: string | null,
    skillId: string | null,
    routineCategoryId: string | null
  ) => void;
  label: string;
  language: Language;
  safeAreas: SafeAreas;
  fullWidth?: boolean;
  sx?: SxProps<Theme>;
}

const enum FilterValue {
  Mine,
  SideEffects,
  Skills,
  RoutineCategories,
}

const SubjectPicker = ({
  userTaskCategories,
  taskCategoryLoc,
  sideEffectById,
  activeFollowedSideEffects,
  targetedSkills,
  skillIds,
  initialSideEffectId,
  initialRoutineCategoryId,
  initialSkillId,
  onSubjectChange,
  label,
  language,
  safeAreas,
  fullWidth,
  sx,
}: SubjectPickerProps): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const [filter, setFilter] = useState<FilterValue[]>([
    FilterValue.SideEffects,
  ]);

  const [sideEffectId, setSideEffectId] = useState<string | null>(null);
  const [routineCategoryId, setRoutineCategoryId] = useState<string | null>(
    null
  );
  const [skillId, setSkillId] = useState<string | null>(null);

  useEffect(() => {
    if (
      mySideEffectOptions.length +
        userTaskCategories.length +
        targetedSkillsOptions.length >
      0
    ) {
      setFilter([FilterValue.Mine]);
    }
  }, []);

  useEffect(() => {
    setSideEffectId(initialSideEffectId);
  }, [initialSideEffectId]);

  useEffect(() => {
    setSkillId(initialSkillId);
  }, [initialSkillId]);

  useEffect(() => {
    setRoutineCategoryId(initialRoutineCategoryId);
  }, [initialRoutineCategoryId]);

  const setSideEffectValue = (value: string, checked: boolean) => {
    if (checked) {
      setSideEffectId(value);
      setRoutineCategoryId(null);
      setSkillId(null);
    } else {
      setSideEffectId(null);
    }
  };

  const setSkillValue = (value: string, checked: boolean) => {
    if (checked) {
      setSkillId(value);
      setSideEffectId(null);
      setRoutineCategoryId(null);
    } else {
      setSideEffectId(null);
    }
  };

  const setRoutineValue = (value: string, checked: boolean) => {
    if (checked) {
      setRoutineCategoryId(value);
      setSideEffectId(null);
      setSkillId(null);
    } else {
      setRoutineCategoryId(null);
    }
  };

  const onConfirm = () => {
    if (sideEffectId) {
      onSubjectChange(sideEffectId, null, null);
    } else if (skillId) {
      onSubjectChange(null, skillId, null);
    } else if (routineCategoryId) {
      onSubjectChange(null, null, routineCategoryId);
    } else {
      onSubjectChange(null, null, null);
    }

    setOpen(false);
  };

  const onCancel = () => {
    setOpen(false);

    setSideEffectId(initialSideEffectId);
    setSkillId(initialSkillId);
    setRoutineCategoryId(initialRoutineCategoryId);
  };

  const value: string | null = useMemo(() => {
    if (
      sideEffectById &&
      initialSideEffectId &&
      sideEffectById[initialSideEffectId]
    ) {
      return sideEffectById[initialSideEffectId].name[language];
    } else if (initialSkillId) {
      return needsLoc[language].needs[initialSkillId];
    } else if (initialRoutineCategoryId) {
      return taskCategoryLoc[language][initialRoutineCategoryId];
    }
    return t("journal.notes.form.subject.placeholder");
  }, [initialSideEffectId, initialSkillId, initialRoutineCategoryId, language]);

  const mySideEffectOptions: string[] = useMemo(() => {
    const sideEffects: string[] = [];

    activeFollowedSideEffects.forEach((fse) => {
      sideEffects.push(fse);
    });

    return sideEffects.sort((a, b) =>
      (sideEffectById[a]?.name[language] ?? a).localeCompare(
        sideEffectById[b]?.name[language] ?? b
      )
    );
  }, [activeFollowedSideEffects, sideEffectById]);

  const targetedSkillsOptions: string[] = Tools.Data.Skills.sortAlphabetically(
    targetedSkills,
    true,
    language
  );

  const sideEffectOptions: string[] = useMemo(() => {
    return sideEffectById
      ? Object.keys(sideEffectById).sort((a, b) =>
          (sideEffectById[a]?.name[language] ?? a).localeCompare(
            sideEffectById[b]?.name[language] ?? b
          )
        )
      : [];
  }, [sideEffectById]);

  const skillsOption: string[] = Tools.Data.Skills.sortAlphabetically(
    skillIds,
    true,
    language
  );

  const routineCategoryOptions: string[] = Object.keys(
    taskCategoryLoc[language]
  )
    .sort((a, b) =>
      taskCategoryLoc[language][a].localeCompare(taskCategoryLoc[language][b])
    )
    .filter((category) => category !== "unknown");

  const myRoutineCategoryOptions: string[] = userTaskCategories
    .sort((a, b) =>
      taskCategoryLoc[language][a].localeCompare(taskCategoryLoc[language][b])
    )
    .filter((category) => category !== "unknown");

  const regularFilter = [
    {
      id: FilterValue.SideEffects,
      label: t("journal.notes.form.subject.filter.symptoms"),
      count: sideEffectOptions.length,
    },
    {
      id: FilterValue.Skills,
      label: t("journal.notes.form.subject.filter.skills"),
      count: skillsOption.length,
    },
    {
      id: FilterValue.RoutineCategories,
      label: t("journal.notes.form.subject.filter.routineCategory"),
      count: routineCategoryOptions.length,
    },
  ];

  const filterOptions =
    mySideEffectOptions.length +
      userTaskCategories.length +
      targetedSkillsOptions.length >
    0
      ? [
          {
            id: FilterValue.Mine,
            label: t("journal.notes.form.subject.filter.mine"),
            count:
              mySideEffectOptions.length +
              userTaskCategories.length +
              targetedSkillsOptions.length,
          },
          ...regularFilter,
        ]
      : regularFilter;

  return (
    <>
      <SelectInputButton
        onClick={() => setOpen(true)}
        color="secondary"
        fullWidth={fullWidth}
        data-cy={"subject-picker-button"}
      >
        {value}
      </SelectInputButton>
      <ControlledDrawer
        anchor="bottom"
        open={open}
        onClose={onCancel}
        sx={{
          [`& .${drawerClasses.paper}`]: {
            maxHeight: "calc(90vh)",
          },
        }}
        safeAreas={safeAreas}
      >
        <Box p={2}>
          <Typography variant="h4" textAlign="center">
            {label}
          </Typography>
        </Box>
        <Box my={1}>
          <Divider />
        </Box>
        <Box>
          <Filter
            options={filterOptions}
            value={filter}
            onChange={setFilter}
            single
            sx={{
              width: "calc(100vw + 1em)",
            }}
          />
        </Box>
        {filter.includes(FilterValue.Mine) && (
          <Grid
            container
            pl={1}
            spacing={1}
            sx={{
              overflowY: "auto",
            }}
          >
            {mySideEffectOptions.length > 0 ? (
              <SubjectPickerList
                withTitle={true}
                title={t("journal.notes.form.subject.filter.symptoms")}
                list={mySideEffectOptions.map((seid) => {
                  return {
                    text: sideEffectById && sideEffectById[seid].name[language],
                    id: seid,
                  };
                })}
                selectedValue={sideEffectId}
                setValue={setSideEffectValue}
              />
            ) : null}
            {targetedSkillsOptions.length > 0 ? (
              <SubjectPickerList
                withTitle={true}
                title={t("journal.notes.form.subject.filter.skills")}
                list={targetedSkillsOptions.map((s) => {
                  return {
                    text: needsLoc[language].needs[s],
                    id: s,
                  };
                })}
                selectedValue={skillId}
                setValue={setSkillValue}
              />
            ) : null}
            {userTaskCategories.length > 0 ? (
              <SubjectPickerList
                withTitle={true}
                title={t("journal.notes.form.subject.filter.routineCategory")}
                list={myRoutineCategoryOptions.map((category) => {
                  return {
                    text: taskCategoryLoc[language][category],
                    id: category,
                  };
                })}
                selectedValue={routineCategoryId}
                setValue={setRoutineValue}
              />
            ) : null}
          </Grid>
        )}
        {filter.includes(FilterValue.SideEffects) && (
          <SubjectPickerList
            withTitle={true}
            title={t("journal.notes.form.subject.filter.symptoms")}
            list={sideEffectOptions.map((seid) => {
              return {
                text: sideEffectById && sideEffectById[seid].name[language],
                id: seid,
              };
            })}
            selectedValue={sideEffectId}
            setValue={setSideEffectValue}
          />
        )}
        {filter.includes(FilterValue.Skills) && (
          <SubjectPickerList
            withTitle={true}
            title={t("journal.notes.form.subject.filter.skills")}
            list={skillsOption.map((s) => {
              return {
                text: needsLoc[language].needs[s],
                id: s,
              };
            })}
            selectedValue={skillId}
            setValue={setSkillValue}
          />
        )}
        {filter.includes(FilterValue.RoutineCategories) && (
          <SubjectPickerList
            withTitle={true}
            title={t("journal.notes.form.subject.filter.routineCategory")}
            list={routineCategoryOptions.map((category) => {
              return {
                text: taskCategoryLoc[language][category],
                id: category,
              };
            })}
            selectedValue={routineCategoryId}
            setValue={setRoutineValue}
          />
        )}
        <Box mt="auto" px={2} py={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={onConfirm}
            data-cy="subject-picker-confirm"
          >
            {t("general.actions.confirm")}
          </Button>
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default SubjectPicker;
