import { Box, Grid2, Typography } from "@mui/material";
import {
  PrintableToolDownloaded,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import {
  InformationButton,
  WebviewLink,
} from "@neurosolutionsgroup/components";
import CoachAssets from "assets/coach";
import PrintableToolAssets from "assets/printableTools";
import CloseablePage from "common/Components/CloseablePage/CloseablePage";
import useDownload from "common/hooks/download/useDownload";
import useNavigation from "common/hooks/navigation/useNavigation";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useParameters from "common/hooks/Parameters/useParameters";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SAFE_AREA_BOTTOM } from "stylesheets";
import ToolOption from "./ToolOption";

enum PrintableTool {
  ActivityBook = "activityBook",
  BehaviorChart = "behaviorChart",
  EmotionWheel = "emotionWheel",
  RewardChart = "rewardChart",
}

const PrintableToolsPage = (): JSX.Element => {
  const { handleEvent } = useAnalytics();
  const { language } = useLanguage();
  const navigate = useNavigate();
  const {
    actions: { setMenuPageOpen },
  } = useNavigation();
  const { openExternalLinkGeneric } = useParameters();
  const { t } = useTranslation();

  const { loading, downloadFile, cancelDownload } = useDownload();

  const [chosenDownload, setChosenDownload] = useState<
    PrintableTool | undefined
  >(undefined);

  const onClose = () => {
    setChosenDownload(undefined);
    cancelDownload();
    setMenuPageOpen(true);
    navigate("/dashboard");
  };

  const getPdfPath = (tool: PrintableTool) => {
    switch (tool) {
      case PrintableTool.ActivityBook:
        return language === "en"
          ? PrintableToolAssets.pdfs.ActivityBookPDFEN
          : PrintableToolAssets.pdfs.ActivityBookPDFFR;
      case PrintableTool.BehaviorChart:
        return language === "en"
          ? PrintableToolAssets.pdfs.BehaviorChartPDFEN
          : PrintableToolAssets.pdfs.BehaviorChartPDFFR;
      case PrintableTool.EmotionWheel:
        return language === "en"
          ? PrintableToolAssets.pdfs.EmotionWheelPDFEN
          : PrintableToolAssets.pdfs.EmotionWheelPDFFR;
      case PrintableTool.RewardChart:
        return language === "en"
          ? PrintableToolAssets.pdfs.RewardChartPDFEN
          : PrintableToolAssets.pdfs.RewardChartPDFFR;
    }
  };

  const onClick = (tool: PrintableTool) => {
    setChosenDownload(tool);

    const url = "https://" + window.location.hostname + getPdfPath(tool);

    const fileName = t(`navigation.printableTools.tools.${tool}.fileName`);

    downloadFile(fileName, url);

    const event: PrintableToolDownloaded = {
      name: "Printable Tool Downloaded",
      eventProperties: {
        tool,
        language,
      },
    };

    handleEvent(event);
  };

  const onWebsiteLinkClick = () => {
    openExternalLinkGeneric(
      language === "en"
        ? "https://kairosgame.com/en/the-ultimate-collection-of-free-printable-parenting-tools"
        : "https://kairosgame.com/compilation-ultime-des-outils-gratuits-kairos"
    );
  };

  return (
    <CloseablePage
      color="primary"
      header={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h2" color="#fff">
            {t("navigation.printableTools.title")}
          </Typography>

          <InformationButton
            color="white"
            infoTitle={t("navigation.printableTools.title")}
            infoText={
              <Typography textAlign="center">
                <Trans i18nKey="navigation.printableTools.infoText">
                  You can print our tools to support your child outside of the
                  app! For guidance on how to use the tools, check out{" "}
                  <WebviewLink
                    onClick={onWebsiteLinkClick}
                    sx={{
                      marginTop: "-.2rem",
                    }}
                  >
                    our website
                  </WebviewLink>
                  !
                </Trans>
              </Typography>
            }
            coachImage={CoachAssets.CoachInfoGeneral}
            infoIconImage={CoachAssets.CoachInfoHead}
            sx={{
              marginY: -1,
            }}
          />
        </Box>
      }
      onClose={onClose}
      isOpen
    >
      <Box mb={SAFE_AREA_BOTTOM} pt={{ xs: 1, sm: 2 }} pb={4}>
        <Grid2 container spacing={4}>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <ToolOption
              text={t("navigation.printableTools.tools.emotionWheel.title")}
              imageSrc={
                language === "en"
                  ? PrintableToolAssets.images.EmotionWheelEN
                  : PrintableToolAssets.images.EmotionWheelFR
              }
              onClick={() => onClick(PrintableTool.EmotionWheel)}
              loading={loading && chosenDownload === PrintableTool.EmotionWheel}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <ToolOption
              text={t("navigation.printableTools.tools.behaviorChart.title")}
              imageSrc={
                language === "en"
                  ? PrintableToolAssets.images.BehaviorChartEN
                  : PrintableToolAssets.images.BehaviorChartFR
              }
              onClick={() => onClick(PrintableTool.BehaviorChart)}
              loading={
                loading && chosenDownload === PrintableTool.BehaviorChart
              }
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <ToolOption
              text={t("navigation.printableTools.tools.rewardChart.title")}
              imageSrc={
                language === "en"
                  ? PrintableToolAssets.images.RewardChartEN
                  : PrintableToolAssets.images.RewardChartFR
              }
              onClick={() => onClick(PrintableTool.RewardChart)}
              loading={loading && chosenDownload === PrintableTool.RewardChart}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, sm: 6 }}>
            <ToolOption
              text={t("navigation.printableTools.tools.activityBook.title")}
              imageSrc={
                language === "en"
                  ? PrintableToolAssets.images.ActivityBookEN
                  : PrintableToolAssets.images.ActivityBookFR
              }
              onClick={() => onClick(PrintableTool.ActivityBook)}
              loading={loading && chosenDownload === PrintableTool.ActivityBook}
            />
          </Grid2>
        </Grid2>
      </Box>
    </CloseablePage>
  );
};

export default PrintableToolsPage;
