import { getAuth } from "firebase/auth";

export const getIdToken = async (logNamespace?: string): Promise<string> => {
  const auth = getAuth();

  if (!auth.currentUser) {
    return Promise.reject(
      new Error(
        `ID token requested when user not authenticated. Check orchestration of requests. Namespace: ${logNamespace}`
      )
    );
  }

  return auth.currentUser.getIdToken();
};
