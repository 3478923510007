import FTUEFirstRoutineLogic from "./FirstRoutineLogic";
import {
  FIRST_ROUTINE_PROGRESS_TOTAL,
  FirstRoutineProgress,
} from "./FirstRoutineProgress";
import { FTUEFirstRoutineScreen } from "./FullScreen";
import FirstRoutineFTUEHints from "./Hints";

export { FIRST_ROUTINE_PROGRESS_TOTAL, FirstRoutineProgress };

const FirstRoutineFTUEFlow = {
  Hints: FirstRoutineFTUEHints,
  Logic: FTUEFirstRoutineLogic,
  Screen: FTUEFirstRoutineScreen,
};

export default FirstRoutineFTUEFlow;
