import { Box } from "@mui/material";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Prescriptions, {
  PrescriptionsProgress,
  PRESCRIPTIONS_PROGRESS_TOTAL,
} from "..";
import useFTUE from "useFTUE";

const DatePicker: React.FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setPrescriptionsProgress },
  } = useFTUE();

  const prescriptionsProgress = progress[FTUEFlow.Prescriptions].progress;

  const onNext = () => {
    setPrescriptionsProgress(PrescriptionsProgress.DatePicker);
  };
  const onQuit = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Finished, true);
  };

  return (
    <FTUEHint
      condition={
        prescriptionsProgress === PrescriptionsProgress.Intro &&
        canDisplayFlow(FTUEFlow.Prescriptions)
      }
      hint={t("ftue.prescription.datePicker.text")}
      nextText={t("general.actions.next")}
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      currentStep={Prescriptions.Logic.calculateProgress(
        PrescriptionsProgress.Intro
      )}
      maxStep={PRESCRIPTIONS_PROGRESS_TOTAL}
    >
      {prescriptionsProgress === PrescriptionsProgress.Intro &&
      canDisplayFlow(FTUEFlow.Prescriptions) ? (
        <Box
          sx={{
            h3: {
              color: "#fff",
            },
          }}
        >
          {props.children}
        </Box>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};
export default DatePicker;
