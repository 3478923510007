import { Box, Typography } from "@mui/material";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";
import React from "react";
import { useTranslation } from "react-i18next";

interface DefaultChildEditDialogProps {
  onCancel: VoidFunction;
  onEdit: VoidFunction;
  open: boolean;
}

const DefaultChildEditDialog = ({
  onCancel,
  onEdit,
  open,
}: DefaultChildEditDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialogs.AlertDialog
      title={t("settings.child.editDefaultChildDialog.title")}
      text={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            img: {
              maxWidth: "100px",
            },
          }}
        >
          <img src={DialogAssets.DefaultChildDialog} alt="" />
          <Typography textAlign="center" fontSize="0.8rem" mt={1}>
            {t("settings.child.editDefaultChildDialog.text")}
          </Typography>
        </Box>
      }
      positiveAction={{
        text: t("settings.child.editDefaultChildDialog.positiveAction"),
        action: onEdit,
        color: "secondary",
        variant: "contained",
      }}
      negativeAction={{
        text: t("general.actions.cancel"),
        action: onCancel,
        color: "primary",
        variant: "text",
      }}
      open={open}
    />
  );
};

export default DefaultChildEditDialog;
