import React from "react";
import {
  SwipeableDrawerProps,
  SwipeableDrawer,
  drawerClasses,
} from "@mui/material";
import { ProviderProps } from "@neurosolutionsgroup/tools";
import { SAFE_AREA_BOTTOM } from "stylesheets";

export interface DrawerProps
  extends Omit<SwipeableDrawerProps, "onOpen" | "children">,
    ProviderProps {}

const Drawer: React.FC<DrawerProps> = (props) => {
  return (
    <SwipeableDrawer
      {...props}
      anchor={props.anchor ?? "bottom"}
      disableSwipeToOpen={true}
      onOpen={() => console.log("Drawer swipe intercepted.")}
      sx={{
        ...props.sx,
        [`& .${drawerClasses.paper}`]: {
          paddingBottom: `calc(1rem + ${SAFE_AREA_BOTTOM})`,
        },
      }}
    >
      {props.children}
    </SwipeableDrawer>
  );
};

export default Drawer;
