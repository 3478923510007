import { ButtonBase, Grid, Radio, Typography } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";

interface PlanSelectionButtonProps extends CypressProps {
  title: string;
  priceString?: string | JSX.Element;
  priceSuffix?: string;
  text: string;
  isSelected: boolean;
  onSelected: () => void;
  radioValue: string;
  additionalText?: string;
}

const PlanSelectionButton = ({
  title,
  priceString,
  priceSuffix,
  text,
  isSelected,
  onSelected,
  radioValue,
  ...props
}: PlanSelectionButtonProps): JSX.Element => {
  return (
    <ButtonBase
      data-cy={props["data-cy"]}
      onClick={onSelected}
      sx={{
        backgroundColor: "#fff",
        borderRadius: "5px",
        boxSizing: "border-box",
        borderWidth: isSelected ? "2px" : "2px",
        borderStyle: "solid",
        borderColor: isSelected ? "success.main" : "background.default",
        width: "100%",
        padding: 1,
        paddingTop: 0.5,
        display: "flex",
        alignItems: "flex-end",
      }}
    >
      <Grid container rowSpacing={0} spacing={0}>
        <Grid
          item
          flexGrow={1}
          sx={{
            textAlign: "left",
            paddingTop: 0.5,
          }}
        >
          <Typography
            color="secondary"
            fontFamily="Ander Hedge"
            letterSpacing=".1rem"
            fontWeight="bold"
            fontSize="1.1rem"
            textAlign="left"
            lineHeight="1.1rem"
          >
            {title}
          </Typography>
          <span>
            {priceString && (
              <Typography
                component="span"
                color="primary"
                fontWeight="bold"
                textAlign="left"
              >
                {priceString}
              </Typography>
            )}
            {priceSuffix && (
              <>
                <Typography
                  component="span"
                  color="primary"
                  fontSize="0.8rem"
                  textAlign="left"
                >
                  {" / "}
                  {priceSuffix}
                </Typography>
              </>
            )}
          </span>
        </Grid>
        <Grid item>
          <Radio
            color="success"
            value={radioValue}
            sx={{
              padding: 0,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" fontSize="0.75rem" textAlign="left">
            {text}
          </Typography>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default PlanSelectionButton;
