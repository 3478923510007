import { LocalizedString } from "../language";

export enum MedicationCategory {
  MyMedication = "myMedication",
  ADHD = "ADHD",
}

/**
 * A representation of a medication.
 *
 * @category Medical
 */
export interface Medication {
  id: string;
  name: LocalizedString;
  dosage: number[];
  category: MedicationCategory;
  unit?: "mg" | "ml";
}

export const OTHER_MEDICATION_ID = "00000000-0000-0000-0000-000000000000";
