import { Box, BoxProps, Container, Grid } from "@mui/material";

interface CoachMessageBannerProps extends BoxProps {
  bannerContent: JSX.Element;
  coachImgSrc: string;
  coachImagePosition: "left" | "right";
}

const CoachMessageBanner = ({
  bannerContent,
  coachImgSrc,
  coachImagePosition,
  sx,
  ...props
}: CoachMessageBannerProps): JSX.Element => {
  return (
    <Box
      sx={[
        {
          backgroundColor: "#FAF7F3",
          paddingX: 3,
          paddingY: 2,
          fontSize: "0.9rem",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      <Container maxWidth="sm" disableGutters>
        <Grid
          container
          alignItems="center"
          flexDirection={coachImagePosition === "left" ? "row" : "row-reverse"}
          spacing={2}
        >
          <Grid
            item
            xs={4}
            sm={3}
            sx={{
              display: "flex",
              justifyContent: "center",
              img: {
                maxWidth: "100%",
                objectFit: "contain",
              },
            }}
          >
            <img src={coachImgSrc} alt="" />
          </Grid>
          <Grid item xs={8} sm={9}>
            {bannerContent}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CoachMessageBanner;
