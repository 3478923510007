import { Note } from "@neurosolutionsgroup/models";
import { addIdToCategory } from "./addIdToCategory";

export interface CategorySeed {
  ids: string[];
  counts: { [id: string]: number };
}

export interface FilterSeed {
  skill: CategorySeed;
  task: CategorySeed;
  sideEffect: CategorySeed;
}

export const getFilterSeed = (notes: Note[]): FilterSeed => {
  const sideEffectCounts: { [id: string]: number } = {};
  const skillsCounts: { [id: string]: number } = {};
  const taskCategoryCounts: { [id: string]: number } = {};

  Object.values(notes).forEach((note) => {
    const sideEffectId = note.sideEffectId;
    const skillId = note.needId;
    const routineCategoryId = note.routineCategoryId;

    if (sideEffectId) {
      sideEffectCounts[sideEffectId] = addIdToCategory(
        sideEffectId,
        sideEffectCounts
      );
    } else if (skillId) {
      skillsCounts[skillId] = addIdToCategory(skillId, skillsCounts);
    } else if (routineCategoryId) {
      taskCategoryCounts[routineCategoryId] = addIdToCategory(
        routineCategoryId,
        taskCategoryCounts
      );
    }
  });

  return {
    skill: {
      ids: Object.keys(skillsCounts),
      counts: skillsCounts,
    },
    sideEffect: {
      ids: Object.keys(sideEffectCounts),
      counts: sideEffectCounts,
    },
    task: {
      ids: Object.keys(taskCategoryCounts),
      counts: taskCategoryCounts,
    },
  };
};
