import {
  Challenge,
  ChallengeCreationArgs,
  ChallengeHistory,
  ChallengeV2,
  FirestoreChallenge,
  FirestoreCollection,
} from "@neurosolutionsgroup/models";
import { HookResult } from "../HookResult";
import { useChallengeContext } from "./ChallengesContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  ChallengeCreated,
  ChallengeDeleted,
  ChallengeOccurrenceValidated,
  ChallengeValidated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { getISODay } from "date-fns";
import {
  and,
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";
import useAuth from "../auth/useAuth";

export interface useChallengesSelectors {
  challengeFeatureFlagActive: boolean;
  challenges: FirestoreChallenge[];
  challengesForApproval: FirestoreChallenge[];
  challengeReadyToValidateForSelectedChild: FirestoreChallenge[];
  deactivatedChallenges: string[];
  loading: boolean;
  totalChallengesToValidate: number;
}

export interface useChallengesActions {
  approveChallenge: (
    challengeId: string,
    approvalStatus: boolean,
    message?: string
  ) => Promise<void>;
  createChallenge: (
    challenge: ChallengeCreationArgs,
    isFreeTrial: boolean | null,
    customReward: boolean
  ) => Promise<void>;
  validateChallenges: (
    challengeId: string,
    history: ChallengeHistory[]
  ) => Promise<FirestoreChallenge>;
  deleteChallenge: (challenge: FirestoreChallenge) => Promise<void>;
  childValidation: (
    challenge: FirestoreChallenge,
    status: boolean
  ) => Promise<void>;
  childWasNotified: (challenge: Challenge) => Promise<void>;
  getAllChallengesForChild: (childId: string) => Promise<FirestoreChallenge[]>;
}

const useChallenges = (): HookResult<
  useChallengesSelectors,
  useChallengesActions
> => {
  const {
    challengeFeatureFlagActive,
    challenges,
    setChallenges,
    challengesForApproval,
    setChallengesForApproval,
    challengeReadyToValidateForSelectedChild,
    deactivatedChallenges,
    setDeactivatedChallenges,
    totalChallengesToValidate,
    loading,
    setLoading,
  } = useChallengeContext();
  const { handleEvent } = useAnalytics();
  const { user, tenantId } = useAuth();

  const createChallenge = async (
    challenge: ChallengeCreationArgs,
    isFreeTrial: boolean | null,
    customReward?: boolean
  ): Promise<void> => {
    setLoading(true);

    try {
      await FirebaseAPI.Challenge.postChallenge(challenge);

      const event: ChallengeCreated = {
        name: "Challenge Created",
        eventProperties: {
          "Custom Reward": customReward ?? true,
          "Challenge Skill": challenge.skillId,
          "Challenge Frequency": challenge.frequency,
          "Challenge Duration": challenge.duration,
          "Days": challenge.days,
          "Icon": challenge.icon,
          "Time Period Length Seconds":
            challenge.startTime && challenge.endTime
              ? challenge.endTime - challenge.startTime
              : null,
          "Created in Free Trial": isFreeTrial,
        },
      };

      handleEvent(event);
    } finally {
      setLoading(false);
    }
  };

  const deleteChallenge = async (
    challenge: FirestoreChallenge
  ): Promise<void> => {
    setLoading(true);

    try {
      const updatedChallenge = await FirebaseAPI.Challenge.deleteChallenge(
        challenge.id
      );

      setChallenges((current) =>
        [...current].filter((c) => c.id !== challenge.id)
      );

      if (updatedChallenge.deleted === false) {
        setDeactivatedChallenges((current) => [...current, challenge.id]);
      }

      const event: ChallengeDeleted = {
        name: "Challenge Deleted",
      };

      handleEvent(event);
    } finally {
      setLoading(false);
    }
  };

  const validateChallenges = async (
    challengeId: string,
    history: ChallengeHistory[]
  ): Promise<FirestoreChallenge> => {
    const newChallenge = await FirebaseAPI.Challenge.parentValidation(
      challengeId,
      {
        history,
      }
    );

    setChallenges((current) => [
      ...current.filter((c) => c.id !== newChallenge.id),
      newChallenge,
    ]);

    if (newChallenge.successful !== null) {
      const event: ChallengeValidated = {
        name: "Challenge Validated",
        eventProperties: {
          Status: newChallenge.successful,
        },
      };

      handleEvent(event);
    } else {
      const event: ChallengeOccurrenceValidated = {
        name: "Challenge Occurrence Validated",
        eventProperties: {
          Statuses: history.flatMap((h) =>
            h.parentStatus !== null ? h.parentStatus : []
          ),
        },
      };

      handleEvent(event);
    }

    return newChallenge;
  };

  const childValidation = async (
    challenge: FirestoreChallenge,
    status: boolean
  ): Promise<void> => {
    setLoading(true);

    try {
      if (Tools.Data.Challenges.V2.isChallengeV2(challenge)) {
        const updatedChallenge = await FirebaseAPI.Challenge.childValidation(
          challenge.id,
          {
            childStatus: status,
            childExecutionDate: Tools.Time.Dates.getTimeStamp(),
            weekIndex: Tools.Data.Challenges.V2.calculateChallengeWeekIndex(
              (challenge as ChallengeV2).startDate,
              Tools.Time.Dates.getTimeStamp()
            ),
            dayIndex: getISODay(new Date()),
          }
        );

        setChallenges((current) => [
          ...current.filter((c) => c.id !== updatedChallenge.id),
          updatedChallenge,
        ]);
      } else {
        const updatedChallenge = await FirebaseAPI.Challenge.childValidation(
          challenge.id,
          {
            childStatus: status,
            childExecutionDate: Tools.Time.Dates.getTimeStamp(),
          }
        );

        setChallenges((current) => [
          ...current.filter((c) => c.id !== updatedChallenge.id),
          updatedChallenge,
        ]);
      }
    } finally {
      setLoading(false);
    }
  };

  const approveChallenge = async (
    challengeId: string,
    challengeStatus: boolean,
    message?: string
  ): Promise<void> => {
    setLoading(true);

    try {
      const updatedChallenge = await FirebaseAPI.Challenge.approveChallenge(
        challengeId,
        challengeStatus,
        message
      );

      setChallenges((current) => [
        ...current.filter((c) => c.id !== updatedChallenge.id),
        updatedChallenge,
      ]);
    } finally {
      setLoading(false);
    }
  };

  const childWasNotified = async (challenge: Challenge): Promise<void> => {
    setLoading(true);

    try {
      const updatedChallenge = await FirebaseAPI.Challenge.childWasNotified(
        challenge.id
      );

      setChallengesForApproval((current) => [
        ...current.filter((c) => c.id !== updatedChallenge.id),
      ]);
    } finally {
      setLoading(false);
    }
  };

  const getAllChallengesForChild = async (
    childId: string
  ): Promise<FirestoreChallenge[]> => {
    if (!user || !tenantId) {
      return [];
    }

    const db = getFirestore();

    const q = query(
      collection(db, FirestoreCollection.Challenges),
      and(
        where("userId", "==", user.uid),
        where("tenantId", "==", tenantId),
        where("deleted", "==", false),
        where("childId", "==", childId),
        where("version", "==", 2)
      )
    );

    const queryResult = await getDocs(q);

    return queryResult.docs.map(
      (doc) => ({ ...doc.data(), id: doc.id } as FirestoreChallenge)
    );
  };

  return {
    selectors: {
      challengeFeatureFlagActive,
      challenges,
      challengesForApproval,
      challengeReadyToValidateForSelectedChild,
      deactivatedChallenges,
      loading,
      totalChallengesToValidate,
    },
    actions: {
      approveChallenge,
      childValidation,
      childWasNotified,
      createChallenge,
      validateChallenges,
      deleteChallenge,
      getAllChallengesForChild,
    },
  };
};

export default useChallenges;
