import {
  ChallengeHistory,
  ChallengeV2,
  ChallengeValidationWeek,
  FirestoreChallenge,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { ChallengeValidationCardV2 } from "@neurosolutionsgroup/webviews-pages";
import useChallenges from "common/hooks/challenges/useChallenges";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useTasks from "common/hooks/routines/useTasks";
import { getISODay } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";

interface ChallengeCardWrapperProps {
  challenge: ChallengeV2;
  onChallengeCompleted: (
    challenge: FirestoreChallenge,
    status: "failed" | "partial" | "successful" | "unfinished"
  ) => void;
}

const ChallengeValidationCardV2Wrapper = ({
  challenge,
  onChallengeCompleted,
}: ChallengeCardWrapperProps): JSX.Element => {
  const {
    actions: { validateChallenges },
  } = useChallenges();
  const { handleUnknownError } = useErrorsContext();
  const { dateLocale } = useLanguage();
  const { t } = useTranslation();

  const { iconManager } = useTasks().selectors;

  const onValidation = async (validationWeeks: ChallengeValidationWeek[]) => {
    const history: ChallengeHistory[] = [];

    const currentWeekIndex =
      Tools.Data.Challenges.V2.calculateChallengeWeekIndex(
        challenge.startDate,
        Tools.Time.Dates.getTimeStamp()
      );

    const currentDayIndex = getISODay(new Date());

    validationWeeks.forEach((validationWeek) => {
      // Calculate retries.
      if (validationWeek.weekIndex === currentWeekIndex) {
        const retries =
          Tools.Data.Challenges.V2.howManyHistoriesCanBeRetiredForCurrentWeek(
            challenge,
            validationWeek.validations,
            currentWeekIndex,
            currentDayIndex
          );

        for (
          let i = 0;
          i < retries && i < validationWeek.validations.length;
          i++
        ) {
          if (validationWeek.validations[i].parentStatus === false) {
            validationWeek.validations[i].canBeRetried = true;
          }
        }
      }

      validationWeek.validations.forEach((validation) => {
        // Only validate history that was validated in this execution.
        if (!validation.validationDate && validation.parentStatus !== null) {
          history.push(validation);
        }
      });
    });

    try {
      const validatedChallenge = await validateChallenges(
        challenge.id,
        history
      );
      const challengeStatus =
        Tools.Data.Challenges.V2.calculateFinishedChallengeStatus(
          validatedChallenge as ChallengeV2
        );
      onChallengeCompleted(validatedChallenge, challengeStatus);
    } catch (err) {
      handleUnknownError(err);
    }
  };

  const initialValidations =
    Tools.Data.Challenges.V2.transformHistoryToValidations(challenge.history);

  return (
    <ChallengeValidationCardV2
      title={challenge.title}
      icon={challenge.icon}
      frequency={challenge.frequency}
      iconManager={iconManager}
      initialValidations={initialValidations}
      weekIndexToString={(weekIndex: number) =>
        Tools.Data.Challenges.V2.challengeWeekToString(
          (challenge as ChallengeV2).startDate,
          weekIndex,
          dateLocale
        )
      }
      weekDayToString={(dayIndex: number | null) =>
        dayIndex !== null
          ? Tools.Time.Days.weekDayToString(dayIndex, dateLocale)
          : t("validation.challenge.card.undetermined")
      }
      onValidation={onValidation}
      data-cy="challenge-validation-card"
    />
  );
};

export default ChallengeValidationCardV2Wrapper;
