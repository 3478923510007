import PinIcon0 from "./pin_icon_0.png";
import PinIcon1 from "./pin_icon_1.png";
import PinIcon2 from "./pin_icon_2.png";
import PinIcon3 from "./pin_icon_3.png";
import PinIcon4 from "./pin_icon_4.png";
import PinIcon5 from "./pin_icon_5.png";
import PinIcon6 from "./pin_icon_6.png";
import PinIcon7 from "./pin_icon_7.png";
import PinIcon8 from "./pin_icon_8.png";
import PinIcon9 from "./pin_icon_9.png";
import Padlock from "./padlock.svg";

const PinIcons: Record<number, string> = {
  0: PinIcon0,
  1: PinIcon1,
  2: PinIcon2,
  3: PinIcon3,
  4: PinIcon4,
  5: PinIcon5,
  6: PinIcon6,
  7: PinIcon7,
  8: PinIcon8,
  9: PinIcon9,
};

const PinAssets = {
  Padlock,
  PinIcons,
};

export default PinAssets;
