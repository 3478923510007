import { Box, Grid, Typography } from "@mui/material";
import { Tools } from "@neurosolutionsgroup/tools";
import MiscAssets from "assets/misc";
import { AccordionCard } from "common/Components";
import useChildren from "common/hooks/children/useChildren";
import useRoutines from "common/hooks/routines/useRoutines";
import { getISODay, getUnixTime, startOfDay } from "date-fns";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

const TodaysRoutinesCard: React.FC = () => {
  const { selectedChild, childrenById } = useChildren().selectors;
  const { routinesById } = useRoutines().selectors;

  const { t } = useTranslation();

  const [expanded, setExpanded] = useState(false);

  interface TodaysRoutines {
    [routineId: string]: boolean;
  }

  const getTodaysRoutine = useCallback((): TodaysRoutines => {
    const todaysRoutines: TodaysRoutines = {};

    if (selectedChild) {
      // Get routines that child is assigned to and are active today.
      const routines = Object.keys(routinesById).filter(
        (routineId) =>
          routinesById[routineId].users.includes(selectedChild) &&
          !routinesById[routineId].deleted &&
          !routinesById[routineId].disabled &&
          Tools.Time.Days.dayInDays(
            Tools.Time.Days.dayIndexes[getISODay(new Date()) - 1],
            routinesById[routineId].days
          )
      );

      routines.forEach((routineId) => {
        const startOfDayDate = getUnixTime(startOfDay(new Date()));

        const routineEnd = routinesById[routineId].end + startOfDayDate;

        if (getUnixTime(new Date()) > routineEnd) {
          // If routine end time passed, routine considered complete.
          todaysRoutines[routineId] = true;
        } else {
          // Else if all the task for that routine have a confirmed history, routine considered complete.
          const completedTasks = childrenById[selectedChild]?.history
            ? childrenById[selectedChild].history.filter(
                (h) =>
                  routinesById[routineId].tasks
                    .map((t) => t.id)
                    .includes(h.task) &&
                  h.dueTime > startOfDayDate &&
                  h.confirmTime !== null
              )
            : [];

          let allTasksCompleted = true;

          const routine = routinesById[routineId];

          if (routine) {
            routine.tasks
              .filter((t) => t.deleted !== true)
              .forEach((t) => {
                if (!completedTasks.map((h) => h.task).includes(t.id)) {
                  allTasksCompleted = false;
                }
              });
          }

          todaysRoutines[routineId] = allTasksCompleted;
        }
      });
    }

    return todaysRoutines;
  }, [selectedChild, childrenById, routinesById]);

  return (
    <AccordionCard
      expanded={expanded}
      setExpanded={setExpanded}
      title={t("dashboard.routine.today.title")}
    >
      <Grid container columnSpacing={2}>
        <Grid
          item
          xs={3}
          display="grid"
          justifyContent="center"
          alignItems="center"
          alignSelf="center"
        >
          <Box
            sx={{
              img: {
                width: "100%",
                maxWidth: "8rem",
              },
            }}
          >
            <img src={MiscAssets.TodaysRoutineIcon} alt="" />
          </Box>
        </Grid>
        {Object.keys(getTodaysRoutine()).length !== 0 ? (
          <Grid item xs={9} flexGrow={1} container direction="column">
            <Grid item container alignItems="flex-end" mb={1} spacing={0.5}>
              <Grid item ml="auto" sm={3}>
                <Typography
                  color="secondary"
                  fontSize="0.75rem"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {t("dashboard.routine.today.toCome")}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography
                  color="secondary"
                  fontSize="0.75rem"
                  textAlign="center"
                  fontWeight="bold"
                >
                  {t("dashboard.routine.today.completed")}
                </Typography>
              </Grid>
            </Grid>
            {Object.keys(getTodaysRoutine())
              .sort((a, b) => routinesById[a].start - routinesById[b].start)
              .map((routineId) => (
                <Grid
                  item
                  container
                  key={routineId}
                  alignItems="center"
                  spacing={0.5}
                >
                  <Grid item xs={6}>
                    <Typography fontSize="0.83rem" color="secondary">
                      {routinesById[routineId].name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      img: {
                        width: "2rem",
                      },
                    }}
                  >
                    {getTodaysRoutine()[routineId] ? null : (
                      <img src={MiscAssets.ClockIcon} alt="To Do" />
                    )}
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      img: {
                        width: "2rem",
                      },
                    }}
                  >
                    {getTodaysRoutine()[routineId] ? (
                      <img src={MiscAssets.RoutineCheckIcon} alt="Done" />
                    ) : null}
                  </Grid>
                </Grid>
              ))}
          </Grid>
        ) : (
          <Grid item xs={8} sm={9} flexGrow={1} alignSelf="center">
            <Box pl={2}>
              {Object.keys(routinesById).filter((r) => !routinesById[r].deleted)
                .length === 0 ? (
                <Typography fontSize="0.83rem" color="secondary">
                  {t("dashboard.routine.today.noRoutine")}
                </Typography>
              ) : (
                <Typography fontSize="0.83rem" color="secondary">
                  {t("dashboard.routine.today.noRoutineToday")}
                </Typography>
              )}
            </Box>
          </Grid>
        )}
      </Grid>
    </AccordionCard>
  );
};

export default TodaysRoutinesCard;
