import { Box, Typography, useTheme } from "@mui/material";
import {
  AddFirstItemButton,
  IconButton,
  Icons,
  InformationButton,
} from "@neurosolutionsgroup/components";
import {
  Language,
  SafeAreas,
  SideEffect,
  SideEffectById,
} from "@neurosolutionsgroup/models";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import SideEffectsCardMenu from "./SideEffectsCardMenu";

export interface SideEffectsCardProps {
  sideEffects: string[];
  onEdit: () => void;
  language: Language;
  infoIconImage: string;
  infoGraphImage: string;
  safeAreas: SafeAreas;
  sideEffectById?: SideEffectById;
  withMenu?: boolean;
  disabled?: boolean;
}
const SideEffectsCard = ({
  sideEffects,
  onEdit,
  language,
  infoIconImage,
  infoGraphImage,
  safeAreas,
  sideEffectById,
  withMenu = false,
  disabled,
}: SideEffectsCardProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [sideEffectMenuOpen, setSideEffectMenuOpen] = useState<boolean>(false);

  const followedSideEffect = (): SideEffect[] => {
    const output: SideEffect[] = [];
    if (sideEffectById) {
      sideEffects.forEach((se) => {
        output.push(sideEffectById[se]);
      });
    }
    return output;
  };

  const px = 4;

  return (
    <>
      <Box
        className={
          disabled
            ? "side-effects-card__container--disabled"
            : "side-effects-card__container"
        }
        borderRadius={5}
        mt={2}
        sx={{
          backgroundColor: "#fff",
          opacity: disabled ? 0.5 : 1,
        }}
      >
        <Box
          borderBottom={1}
          py={2}
          px={px}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography variant="h3">
            {t("medication.sideEffectsCard.title")}
          </Typography>
          <InformationButton
            infoTitle={t("medication.sideEffectsCard.info.text")}
            infoText={
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  img: {
                    width: "80vw",
                    maxHeight: "300px",
                    objectFit: "contain",
                  },
                }}
              >
                <img src={infoGraphImage} alt="" />
                <Typography my={2} mx={1}>
                  <Trans i18nKey="ftue.prescription.end.text" />
                </Typography>
              </Box>
            }
            infoIconImage={infoIconImage}
            coachImage={null}
            sx={{
              marginLeft: 1,
              marginRight: "auto",
            }}
          />
          {withMenu && (
            <IconButton
              onClick={() => {
                if (withMenu && !disabled) {
                  setSideEffectMenuOpen(true);
                } else {
                  onEdit();
                }
              }}
              disabled={disabled}
            >
              <Icons.MenuIcon color={palette.secondary.main} />
            </IconButton>
          )}
        </Box>
        <Box px={px} py={1}>
          {sideEffects.length > 0 ? (
            followedSideEffect().map((se) => {
              return <div key={se.id}>{se.name[language]}</div>;
            })
          ) : (
            <AddFirstItemButton
              label={t("medication.sideEffectsCard.addSideEffect")}
              onClick={onEdit}
            />
          )}
        </Box>
      </Box>
      {withMenu && (
        <SideEffectsCardMenu
          isOpen={sideEffectMenuOpen}
          setIsOpen={() => setSideEffectMenuOpen(false)}
          onEdit={() => {
            setSideEffectMenuOpen(false);
            onEdit();
          }}
          safeAreas={safeAreas}
        />
      )}
    </>
  );
};

export default SideEffectsCard;
