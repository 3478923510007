import { PriceInfo, SubscriptionStatus } from "@neurosolutionsgroup/models";
import { Permissions, useSubscriptionContext } from "./SubscriptionContext";
import {
  createPremiumFeatureEvent,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";

interface UseSubscriptionResult {
  onPremiumFeatureClick: (
    feature: PremiumFeature,
    eventProperties?: Record<string, unknown>
  ) => void;
  onShowSubscriptionScreen: (debugNoTrial?: boolean) => void;
  permissions: Permissions;
  priceInfo: PriceInfo | undefined;
  subscriptionStatus: SubscriptionStatus | null;
}

const useSubscription = (): UseSubscriptionResult => {
  const {
    onShowSubscriptionScreen,
    permissions,
    priceInfo,
    subscriptionStatus,
  } = useSubscriptionContext();

  const { handleEvent } = useAnalytics();

  const onPremiumFeatureClick = (
    feature: PremiumFeature,
    eventProperties?: Record<string, unknown>
  ) => {
    const event = createPremiumFeatureEvent(feature, eventProperties);

    handleEvent(event);

    onShowSubscriptionScreen();
  };

  return {
    onPremiumFeatureClick,
    onShowSubscriptionScreen,
    permissions,
    priceInfo,
    subscriptionStatus,
  };
};

export default useSubscription;
