import {
  BackButton,
  FullscreenPage,
  IconPinDisplay,
  Icons,
  TextInput,
} from "@neurosolutionsgroup/components";
import { Button, ButtonBase, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import FixedIconPinKeyboard from "./Components/FixedIconPinKeyboard";
import { useState } from "react";
import { PinIconsDefintion } from "@neurosolutionsgroup/models";
import { GenericPageProps } from "../../genericPageProps";

interface ChildCodeCreationPageProps extends GenericPageProps {
  childName: string;
  iconCode: boolean;
  onBack: VoidFunction;
  onConfirm: (value: string) => void;
  pinIcons: PinIconsDefintion;
}

const ChildCodeCreationPage = ({
  childName,
  iconCode,
  onBack,
  onConfirm,
  pinIcons,
  safeAreas,
  sx,
}: ChildCodeCreationPageProps): JSX.Element => {
  const { t } = useTranslation();

  const [value, setValue] = useState("");

  const disabled = value.length < 1 || value.length > 4;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const patern = new RegExp("^[0-9]*$");
    if (patern.test(newValue) && newValue.length < 5) {
      setValue(newValue);
    }
  };

  return (
    <FullscreenPage sx={sx}>
      {iconCode ? (
        <Grid height="100%" container flexDirection="column">
          <Grid item>
            <BackButton onClick={onBack} color="#fff" />
          </Grid>
          <Grid item px={2} mt="auto">
            <Typography>
              {t("settings.child.pin.input.label", { childName })}
            </Typography>
            <IconPinDisplay
              value={value}
              clearable={true}
              clickable={false}
              setValue={setValue}
              pinIcons={pinIcons}
            />
          </Grid>
          <Grid item alignSelf="center">
            <FixedIconPinKeyboard
              setValue={setValue}
              confirmDisabled={disabled}
              onConfirm={() => onConfirm(value)}
              pinIcons={pinIcons}
              safeAreas={safeAreas}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid
          height="100%"
          container
          flexDirection="column"
          justifyContent="space-between"
        >
          <Grid item>
            <BackButton onClick={onBack} color="#fff" />
          </Grid>
          <Grid item px={2}>
            <Typography mb={1}>
              {t("settings.child.pin.input.label", { childName })}
            </Typography>
            <TextInput
              value={value}
              onChange={onChange}
              autoFocus
              fullWidth
              inputProps={{
                pattern: "\\d*",
                inputMode: "numeric",
                maxLength: 4,
              }}
              InputProps={{
                endAdornment: (
                  <ButtonBase
                    onClick={() => setValue("")}
                    sx={(theme) => ({
                      backgroundColor: theme.palette.secondary.main,
                      opacity: 0.5,
                      borderRadius: "999px",
                      padding: "0.25rem",
                      svg: {
                        width: "1rem",
                        height: "1rem",
                      },
                    })}
                  >
                    <Icons.CloseIcon color="#fff" />
                  </ButtonBase>
                ),
              }}
            />
          </Grid>
          <Grid item alignSelf="center">
            <Button
              onClick={() => onConfirm(value)}
              disabled={disabled}
              color="secondary"
              variant="contained"
              sx={{
                marginBottom: `calc(${safeAreas.bottom} + 12px)`,
              }}
            >
              {t("general.actions.confirm")}
            </Button>
          </Grid>
        </Grid>
      )}
    </FullscreenPage>
  );
};

export default ChildCodeCreationPage;
