import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PageSection } from "common/Components/PageSection";
import { CloseablePage, TaskButton } from "common/Components";
import Credits from "./Credits/Credits";
import useParameters from "common/hooks/Parameters/useParameters";
import useLanguage from "common/hooks/Parameters/useLanguage";

export const About: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const { openPrivacyPolicy, openTerms } = useParameters();

  const [openCredits, setOpenCredits] = useState(false);

  return (
    <>
      <PageSection title={t("settings.conditionsTitle")} showBottomBorder>
        <TaskButton onClick={() => openPrivacyPolicy(language)}>
          {t("settings.privacyPolicy")}
        </TaskButton>
        <TaskButton onClick={() => openTerms(language)}>
          {t("settings.tos")}
        </TaskButton>
      </PageSection>
      <PageSection title={t("settings.creditsTitle")} showBottomBorder>
        <TaskButton onClick={() => setOpenCredits(true)}>
          {t("settings.credits")}
        </TaskButton>
      </PageSection>
      <CloseablePage
        isOpen={openCredits}
        onClose={() => setOpenCredits(false)}
        header={<h2>{t("settings.credits")}</h2>}
      >
        <Credits />
      </CloseablePage>
    </>
  );
};
