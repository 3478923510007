import { ChallengeHistory, ChallengeV2 } from "@neurosolutionsgroup/models";
import Time from "../../../Time";

const calculateDaysLeftInWeek = (
  currentDayIndex: number,
  days: number | null,
  includeToday: boolean
): number => {
  if (days === null || days === 0 || days === 127) {
    return (includeToday ? 8 : 7) - currentDayIndex;
  } else {
    let daysLeftOfWeek = 0;

    const startDay = includeToday ? currentDayIndex : currentDayIndex + 1;

    for (let day = startDay; day < 7; day++) {
      // Binary day indexes start at 0, but we use ISO day indexes (starting at 1).
      const binaryDayRepresentation = Time.Days.dayIndexes[day - 1];

      if (Time.Days.dayInDays(binaryDayRepresentation, days)) {
        daysLeftOfWeek++;
      }
    }

    return daysLeftOfWeek;
  }
};

/**
 * Calculate whether a challenge can be retried this week.
 * Should only be called if at least one history was validated as not done, and is for the current week.
 * Otherwise history should always have canBeRetried === false.
 * @param challenge The challenge that is being validated.
 * @param updatedHistory The challenge history with the validated history updated or added.
 * @param currentWeekIndex The week index within the challenge of the current week.
 * @param currentDayIndex The current days ISO day index (Monday === 1).
 * @returns The number of retries possible.
 */
const howManyHistoriesCanBeRetiredForCurrentWeek = (
  challenge: ChallengeV2,
  updatedHistory: ChallengeHistory[],
  currentWeekIndex: number,
  currentDayIndex: number
): number => {
  // If any validation was done today, do not include today in days left count.
  const includeToday = !updatedHistory.some(
    (history) => history.day === currentDayIndex
  );

  const daysLeftOfWeek = calculateDaysLeftInWeek(
    currentDayIndex,
    challenge.days,
    includeToday
  );

  const doneHistories = updatedHistory.filter(
    (history) =>
      history.week === currentWeekIndex && history.parentStatus === true
  );

  const doneHistoriesCount = doneHistories.length;

  const failedHistories = updatedHistory.filter(
    (history) =>
      history.week === currentWeekIndex && history.parentStatus === false
  );

  const failedHistoriesCount = failedHistories.length;

  if (failedHistoriesCount === 0) {
    return 0;
  }

  const leftToDo = challenge.frequency - doneHistoriesCount;

  if (daysLeftOfWeek < challenge.frequency) {
    const retries = failedHistoriesCount - (leftToDo - daysLeftOfWeek);

    return retries > 0 ? retries : 0;
  }

  return leftToDo;
};

export default howManyHistoriesCanBeRetiredForCurrentWeek;
