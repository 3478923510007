import { ExpandableCard } from "@neurosolutionsgroup/components";
import { ChallengeV2 } from "@neurosolutionsgroup/models";
import { useState } from "react";
import CurrentChallengeCardSummary from "./CurrentChallengeCardSummary";
import {
  accordionClasses,
  accordionDetailsClasses,
  accordionSummaryClasses,
} from "@mui/material";
import CurrentChallengeCardDetails from "./CurrentChallengeCardDetails";
import { Tools } from "@neurosolutionsgroup/tools";

interface CurrentChallengeCardProps {
  challenge: ChallengeV2 | null;
  challengeIconImgSrc: string;
  dateLocale: Locale;
  taskIconAssets: Record<number, string>;
  defaultOpen?: boolean;
}

const CurrentChallengeCard = ({
  challenge,
  challengeIconImgSrc,
  dateLocale,
  taskIconAssets,
  defaultOpen,
}: CurrentChallengeCardProps): JSX.Element => {
  const [open, setOpen] = useState(!!defaultOpen);

  const currentChallengeWeek = challenge
    ? Tools.Data.Challenges.V2.calculateChallengeWeekIndex(
        challenge.startDate,
        Tools.Time.Dates.getTimeStamp()
      )
    : 0;

  return (
    <ExpandableCard
      open={open}
      setOpen={setOpen}
      hideExpandIcon
      summaryComponent={
        <CurrentChallengeCardSummary
          challenge={challenge}
          challengeIconImgSrc={challengeIconImgSrc}
          currentChallengeWeek={currentChallengeWeek}
          dateLocale={dateLocale}
          open={open}
          taskIconAssets={taskIconAssets}
        />
      }
      detailsComponent={
        <CurrentChallengeCardDetails
          challenge={challenge}
          currentChallengeWeek={currentChallengeWeek}
        />
      }
      sx={(theme) => ({
        [`&.${accordionClasses.root}`]: {
          margin: 0,
        },
        [`& .${accordionDetailsClasses.root}`]: {
          borderTop: `1px solid ${theme.palette.secondary.main}`,
        },
        [`& .${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]:
          {
            paddingY: 0,
            boxShadow: "none",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            [`& .${accordionSummaryClasses.content}`]: {
              marginTop: 2,
              marginBottom: 2,
            },
          },
      })}
      data-cy="skills-current-challenge"
    />
  );
};

export default CurrentChallengeCard;
