import { Box, Typography } from "@mui/material";
import { Dialogs } from "@neurosolutionsgroup/components";
import { Trans, useTranslation } from "react-i18next";

interface ImpossibleChallengeDialogProps {
  coachErrorImgSrc: string;
  error: "errorThisWeek" | "errorAnyWeek" | null;
  onClose: VoidFunction;
}

const ImpossibleChallengeDialog = ({
  coachErrorImgSrc,
  error,
  onClose,
}: ImpossibleChallengeDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Dialogs.AlertDialog
      open={!!error}
      onClose={onClose}
      title={t("routine.challenge.impossible.title").toUpperCase()}
      text={
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              img: {
                width: "60%",
                maxWidth: "200px",
                objectFit: "contain",
              },
            }}
          >
            <img src={coachErrorImgSrc} alt="" />
          </Box>
          <Typography mt={2}>
            {error !== null ? (
              <Trans i18nKey={`routine.challenge.impossible.${error}`} />
            ) : null}
          </Typography>
        </Box>
      }
      positiveAction={{
        action: onClose,
        text: t("routine.challenge.impossible.action"),
      }}
    />
  );
};

export default ImpossibleChallengeDialog;
