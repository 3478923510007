import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren, useMemo } from "react";
import { FirstRoutineProgress } from "..";
import useFTUE from "useFTUE";

export interface RoutineConfirmationProps extends PropsWithChildren {
  onContinue: VoidFunction;
  continueDisabled: boolean;
}

const RoutineConfirmation: React.FC<RoutineConfirmationProps> = ({
  onContinue,
  continueDisabled,
  ...props
}) => {
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setFirstRoutineProgress },
  } = useFTUE();

  const firstRoutineProgress = progress[FTUEFlow.FirstRoutine].progress;

  const onNext = () => {
    onContinue();
    setFirstRoutineProgress(FirstRoutineProgress.RoutineConfirmed);
  };

  const condition = useMemo(
    () =>
      firstRoutineProgress === FirstRoutineProgress.TemplateChosen &&
      canDisplayFlow(FTUEFlow.FirstRoutine),
    [progress, canDisplayFlow]
  );

  return (
    <FTUEHint
      condition={condition}
      onNext={onNext}
      nextDisabled={continueDisabled}
      scrollPosition="start"
      backgroundOverlay={false}
    >
      {props.children}
    </FTUEHint>
  );
};

export default RoutineConfirmation;
