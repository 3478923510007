import Child from "./Child";
import FollowedSideEffect from "./FollowedSideEffect";
import Medication from "./Medication";
import Prescription from "./Prescription";
import Record from "./Record";

const Medical = {
  Child,
  FollowedSideEffect,
  Medication,
  Prescription,
  Record,
};

export default Medical;
