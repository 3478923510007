import AppleSmall from "./Apple_small.svg";
import GoogleSmall from "./Google_small.png";
import KairosLogoWhite from "./KAIROS-single-logo-small.png";
import KairosIllustratedEN from "./KAIROS-1-logo-illustratif-EN-small.png";
import KairosIllustratedFR from "./KAIROS-1-logo-illustratif-FR-small.png";
import KairosSimpleTagEN from "./kairos-simple-tag-en.png";
import KairosSimpleTagFR from "./kairos-simple-tag-fr.png";

const Logos = {
  AppleSmall,
  GoogleSmall,
  KairosLogoWhite,
  KairosIllustratedEN,
  KairosIllustratedFR,
  KairosSimpleTagEN,
  KairosSimpleTagFR,
};

export default Logos;
