import { CustomWindow } from "custom.window";
import UnityTools from "./UnityTools";
import { Tools } from "@neurosolutionsgroup/tools";
import { UniWebViewActions } from "common/hooks/Parameters/UniWebViewActions";

declare let window: CustomWindow;

const requestNativePermission = async (
  timeout = 10000,
  test?: boolean
): Promise<boolean | null> => {
  const urlString = "uniwebview://request-notification-permission";

  if (process.env.NODE_ENV === "development") {
    return requestNativePermissionDev(urlString);
  }

  let permissionCallbackGranted: boolean | null = null;
  let callbackReceived = false;

  // Setup callback.
  window.notificationPermissionCallback = (permissionGranted) => {
    permissionCallbackGranted = permissionGranted;
    callbackReceived = true;
  };

  if (Tools.Environment.isDevBuild() || test) {
    console.log(urlString);
  } else {
    window.location.href = urlString;
  }

  await UnityTools.unityCallbackHandler(
    callbackReceived,
    () => {
      callbackReceived = true;
    },
    timeout
  );

  return permissionCallbackGranted;
};

const requestNativePermissionDev = (urlString: string): boolean => {
  console.log(urlString);

  // Add check here if reponse other than true needed for testing.
  return true;
};

const toggleRoutineNotifications = async (
  active: boolean,
  timeout: number,
  test?: boolean
): Promise<boolean | null> => {
  const urlString = `uniwebview://${UniWebViewActions.ToggleRoutineNotifications}?active=${active}`;

  let callbackResult: boolean | null = null;
  let callbackReceived = false;

  // Setup callback.
  window.notificationRoutineToggleCallback = () => {
    callbackResult = true;
    callbackReceived = true;
  };

  if (Tools.Environment.isDevBuild() || test) {
    console.log(urlString);
  } else {
    window.location.href = urlString;
  }

  await UnityTools.unityCallbackHandler(
    callbackReceived,
    () => {
      callbackReceived = true;
    },
    timeout
  );

  return callbackResult;
};

const UnityAdapter = {
  requestNativePermission,
  toggleRoutineNotifications,
};

export default UnityAdapter;
