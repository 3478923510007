import { useTheme } from "@mui/material";
import {
  PickersDay,
  pickersDayClasses,
  PickersDayProps,
} from "@mui/x-date-pickers";

const DatePickerDay = (props: PickersDayProps<Date>): JSX.Element => {
  const { palette } = useTheme();

  return (
    <PickersDay
      // onClick={props.selected ? () => onDatePickerChange(day, true) : undefined}
      {...props}
      sx={{
        [`&.${pickersDayClasses.selected}`]: {
          backgroundColor: palette.secondary.main,
        },
        [`&.${pickersDayClasses.selected}:hover`]: {
          backgroundColor: palette.secondary.light,
        },
      }}
    />
  );
};

export default DatePickerDay;
