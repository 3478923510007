import { Box, Grid, Typography } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";

interface ChallengeValidationSummaryProps extends CypressProps {
  title: string;
  icon: number;
  frequency: number;
  valiationsToDo: number;
  taskIconAssets: Record<number, string>;
}

const ChallengeValidationSummary = ({
  title,
  icon,
  frequency,
  valiationsToDo,
  taskIconAssets,
  ...props
}: ChallengeValidationSummaryProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      alignItems="center"
      spacing={1}
      width="100%"
      flexWrap="nowrap"
      data-cy={props["data-cy"]}
    >
      <Grid
        item
        sx={{
          flexGrow: 0,
          display: "flex",
          alignItems: "center",
          img: {
            width: "3.5em",
            objectFit: "contain",
          },
        }}
      >
        <img src={taskIconAssets[icon]} alt="" />
      </Grid>
      <Grid item>
        <Typography fontWeight="bold" textAlign="left" fontSize="0.9rem">
          {title}
        </Typography>
        <Typography textAlign="left" fontSize="0.85rem">
          {t("validation.challenge.card.objective", { count: frequency })}
        </Typography>
      </Grid>
      <Grid item flexGrow={1} display="flex" justifyContent="flex-end">
        <Box
          sx={(theme) => ({
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "5px",
            paddingX: "0.75em",
          })}
          data-cy="challenge-validation-count"
        >
          <Typography fontWeight="bold">{valiationsToDo}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ChallengeValidationSummary;
