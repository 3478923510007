import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { Icons, ValidationButton } from "@neurosolutionsgroup/components";
import { Routine } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import TaskAssets from "assets/task-icons";
import { useCoach } from "common/hooks/messaging/Coach";
import { Loader } from "common/Components";
import {
  FTUEFlowDefinitions,
  FTUEFlow,
  useFTUE,
  ValidationProgress,
} from "@neurosolutionsgroup/webviews-ftue";
import useChildren from "common/hooks/children/useChildren";
import useParameters from "common/hooks/Parameters/useParameters";
import useTasks from "common/hooks/routines/useTasks";
import {
  ValidationStorage,
  ValidationTaskHistory,
} from "pages/Validation/Validation";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface TaskValidationProps {
  initialHistory: ValidationTaskHistory[];
  routine: Routine;
  setLoadingStatus: React.Dispatch<SetStateAction<LoadingStatus>>;
  setValidationDone: (done: boolean) => void;
  localValidationStatus: ValidationStorage;
  setLocalValidationStatus: React.Dispatch<SetStateAction<ValidationStorage>>;
  onValidation: () => void;
  isLast: boolean;
}

export enum LoadingStatus {
  None,
  Loading,
  Success,
  Error,
}

const TaskValidation: React.FC<TaskValidationProps> = ({
  initialHistory,
  routine,
  setLoadingStatus,
  setValidationDone,
  localValidationStatus,
  setLocalValidationStatus,
  onValidation,
  isLast,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { version } = useParameters();

  const {
    selectors: { progress },
  } = useFTUE();

  const { onTaskValidation } = useCoach();

  const { tasksById } = useTasks().selectors;
  const {
    selectors: { selectedChild },
    actions: { validateTasks },
  } = useChildren();

  const [history, setHistory] = useState<ValidationTaskHistory[]>(
    initialHistory.map((h) => ({
      ...h,
      childStatus: h.status ?? null,
      status:
        h.id && localValidationStatus[h.id] !== undefined
          ? localValidationStatus[h.id]
          : h.status,
    }))
  );
  const [localLoadingStatus, setLocalLoadingStatus] = useState(
    LoadingStatus.None
  );

  useEffect(() => {
    setHistory(
      initialHistory.map((h) => ({
        ...h,
        childStatus: h.status ?? null,
        status:
          h.id && localValidationStatus[h.id] !== undefined
            ? localValidationStatus[h.id]
            : h.status,
      }))
    );
  }, [initialHistory, localValidationStatus]);

  const isValid = useCallback((): boolean => {
    return history.some((h) => h.status !== undefined);
  }, [history]);

  const onValidate = () => {
    if (selectedChild) {
      setValidationDone(true);

      let localLoading = false;

      const validatedHistory = history.filter((h) => h.status !== undefined);
      const unvalidatedHistory = history.filter((h) => h.status === undefined);

      if (unvalidatedHistory.length > 0) {
        localLoading = true;
        setLocalLoadingStatus(LoadingStatus.Loading);
      } else {
        setLoadingStatus(LoadingStatus.Loading);
      }

      const newHistory = validatedHistory.map((h) => ({
        ...h,
        confirmTime: Tools.Time.Dates.getTimeStamp(),
        status: h.status === undefined ? null : h.status,
      }));

      validateTasks(newHistory, selectedChild)
        .then(() => {
          onTaskValidation(
            newHistory,
            routine,
            unvalidatedHistory.length === 0,
            isLast
          );

          if (localLoading) {
            setLocalLoadingStatus(LoadingStatus.Success);

            setTimeout(() => {
              setLocalLoadingStatus(LoadingStatus.None);
            }, 1000);
          } else {
            setLoadingStatus(LoadingStatus.Success);
          }
        })
        .catch(() => {
          if (localLoading) {
            setLocalLoadingStatus(LoadingStatus.Error);

            setTimeout(() => {
              setLocalLoadingStatus(LoadingStatus.None);
            }, 2000);
          } else {
            setLoadingStatus(LoadingStatus.Error);

            setTimeout(() => {
              setLoadingStatus(LoadingStatus.None);
            }, 2000);
          }
        });

      onValidation();
    }
  };

  const onSetAllClick = (status: boolean | null): void => {
    if (progress[FTUEFlow.Validation].progress !== ValidationProgress.Display) {
      setLocalValidationStatus((current) => {
        const clone = { ...current };

        history.forEach((h) => {
          if (h.id) {
            clone[h.id] = getAllStatus() === status ? undefined : status;
          }
        });

        return clone;
      });
    }
  };

  const onTaskValidationButtonClick = (
    status: boolean | null,
    id?: string
  ): void => {
    if (progress[FTUEFlow.Validation].progress !== ValidationProgress.Display) {
      if (id) {
        setLocalValidationStatus((current) => {
          const clone = { ...current };

          clone[id] = status;

          return clone;
        });
      }
    }
  };

  const getAllStatus = useCallback((): boolean | null | undefined => {
    return history.length > 0 &&
      history.every((h) => h.status === history[0].status)
      ? history[0].status
      : undefined;
  }, [history]);

  return (
    <Grid container direction="column" spacing={1}>
      {version === "1.24.0" && localLoadingStatus === LoadingStatus.Loading ? (
        <Loader />
      ) : null}
      <FTUEFlowDefinitions.ValidationFTUEFlow.Hints.StatusPrefill>
        <Grid item container>
          <Grid item xs={6}></Grid>
          <Grid
            item
            xs={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize={"0.6rem"} color={"#000"} fontWeight="bold">
              {getAllStatus() === null
                ? t("general.actions.unselect")
                : t("general.tout")}
            </Typography>
            <ValidationButton
              onClick={() => onSetAllClick(null)}
              active={getAllStatus() === null}
              mini
            >
              N/A
            </ValidationButton>
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize={"0.6rem"} color={"#000"} fontWeight="bold">
              {getAllStatus() === true
                ? t("general.actions.unselect")
                : t("general.tout")}
            </Typography>
            <ValidationButton
              onClick={() => onSetAllClick(true)}
              active={getAllStatus() === true}
              mini
              data-cy="task-validation-all-success-button"
            >
              <Icons.CheckMarkIcon color="#A6DA2D" checkStyle="kairos" />
            </ValidationButton>
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontSize={"0.6rem"} color={"#000"} fontWeight="bold">
              {getAllStatus() === false
                ? t("general.actions.unselect")
                : t("general.tout")}
            </Typography>
            <ValidationButton
              onClick={() => onSetAllClick(false)}
              active={getAllStatus() === false}
              mini
            >
              <Icons.CloseIcon color="#ef4044" />
            </ValidationButton>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: "6px" }} />
        {history
          .sort(
            (a, b) =>
              (tasksById[a.task].index ?? a.statusSetTime) -
              (tasksById[b.task].index ?? b.statusSetTime)
          )
          .map((h) =>
            h.id ? (
              <Grid item container key={h.id}>
                <Grid
                  item
                  xs={6}
                  sx={{
                    "& img": {
                      height: "3em",
                    },
                  }}
                >
                  <img
                    src={TaskAssets.TaskIcons[tasksById[h.task].icon]}
                    alt={`Task Icon ${tasksById[h.task].icon}`}
                  />
                  <Typography color="#000" fontSize="0.75rem">
                    {tasksById[h.task].name}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ValidationButton
                    onClick={() => onTaskValidationButtonClick(null, h.id)}
                    active={h.status === null}
                  >
                    N/A
                  </ValidationButton>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ValidationButton
                    onClick={() => onTaskValidationButtonClick(true, h.id)}
                    active={h.status === true}
                  >
                    <Icons.CheckMarkIcon color="#A6DA2D" checkStyle="kairos" />
                  </ValidationButton>
                </Grid>
                <Grid
                  item
                  xs={2}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <ValidationButton
                    onClick={() => onTaskValidationButtonClick(false, h.id)}
                    active={h.status === false}
                  >
                    <Icons.CloseIcon color="#ef4044" />
                  </ValidationButton>
                </Grid>
                <Divider sx={{ marginTop: "6px" }} />
              </Grid>
            ) : null
          )}
      </FTUEFlowDefinitions.ValidationFTUEFlow.Hints.StatusPrefill>
      <Grid item display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          onClick={onValidate}
          disabled={!isValid()}
          sx={{
            "height": "2.5rem",
            "& .icon": {
              width: "1.5rem",
              height: "1.5rem",
            },
          }}
          data-cy="validate-button"
        >
          {localLoadingStatus === LoadingStatus.None ? (
            t("general.actions.validate")
          ) : localLoadingStatus === LoadingStatus.Loading ? (
            <CircularProgress
              size="1.5rem"
              style={{
                color: "#fff",
              }}
            />
          ) : localLoadingStatus === LoadingStatus.Success ? (
            <Icons.CircledCheckMarkIcon color={palette.secondary.main} />
          ) : (
            <Icons.AlertIcon />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TaskValidation;
