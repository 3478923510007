import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Observation, {
  ObservationProgress,
  OBSERVATION_PROGRESS_TOTAL,
} from "..";
import useFTUE from "useFTUE";

export interface ObservationIntensityProps extends PropsWithChildren {
  onNext: VoidFunction;
}

const ObservationFrequency: React.FC<ObservationIntensityProps> = ({
  onNext,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setObservationProgress },
  } = useFTUE();

  const observationProgress = progress[FTUEFlow.Observation].progress;

  const onNextClick = () => {
    onNext();
    setObservationProgress(ObservationProgress.ObservationsFTUEFinished);
  };

  const onQuit = () => {
    setObservationProgress(ObservationProgress.ObservationsFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        observationProgress === ObservationProgress.Frequency &&
        canDisplayFlow(FTUEFlow.Observation)
      }
      hint={t("ftue.observation.intensity.text")}
      onNext={onNextClick}
      nextText={t("general.actions.finish")}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      floating={true}
      currentStep={Observation.Logic.calculateProgress(
        ObservationProgress.Frequency
      )}
      maxStep={OBSERVATION_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default ObservationFrequency;
