import { Box, Grid, Typography } from "@mui/material";
import {
  InformationButton,
  InputError,
  InputLabel,
  TaskIconPicker,
  TextInput,
} from "@neurosolutionsgroup/components";
import { Language, SafeAreas } from "@neurosolutionsgroup/models";
import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import SkillPicker from "../SkillPicker";
import { IconManager } from "@neurosolutionsgroup/tools";
import { CompleteIconsCategory } from "@neurosolutionsgroup/models";

interface ChallengeSectionProps {
  language: Language;
  mySkillOptions: string[];
  skill: string | null;
  setSkill: React.Dispatch<React.SetStateAction<string | null>>;
  title: string;
  setTitle: React.Dispatch<React.SetStateAction<string>>;
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  icon: number;
  setIcon: React.Dispatch<React.SetStateAction<number>>;
  categorizedIcons: CompleteIconsCategory[];
  taskIconAssets: Record<number, string>;
  iconManager: IconManager;
  coachInfoIconImgSrc: string;
  skillInfoImgSrc: string;
  safeAreas: SafeAreas;
}

const ChallengeSection = ({
  language,
  mySkillOptions,
  skill,
  setSkill,
  title,
  setTitle,
  description,
  setDescription,
  icon,
  setIcon,
  categorizedIcons,
  taskIconAssets,
  iconManager,
  coachInfoIconImgSrc,
  skillInfoImgSrc,
  safeAreas,
}: ChallengeSectionProps): JSX.Element => {
  const { t } = useTranslation();

  const MAX_TITLE_LENGTH = 65;
  const MAX_DESCRIPTION_LENGTH = 250;

  const titleError = useMemo(() => {
    return title.length > MAX_TITLE_LENGTH;
  }, [title]);

  const descriptionError = useMemo(() => {
    return description.length > MAX_DESCRIPTION_LENGTH;
  }, [description]);

  return (
    <>
      <Box mb={2}>
        <InputLabel>
          {t("routine.challenge.inputs.skill.label")}{" "}
          <InformationButton
            infoTitle={t("routine.challenge.inputs.skill.info.title")}
            infoText={
              <Box display="flex" flexDirection="column" alignItems="center">
                <Box
                  mb={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    img: { maxWidth: "80%", objectFit: "contain" },
                  }}
                >
                  <img src={skillInfoImgSrc} alt="" />
                </Box>
                <Typography fontSize="0.9rem">
                  <Trans i18nKey={"routine.challenge.inputs.skill.info.text"} />
                </Typography>
              </Box>
            }
            infoIconImage={coachInfoIconImgSrc}
            coachImage={null}
            inline
          />
        </InputLabel>
        <SkillPicker
          language={language}
          mySkillOptions={mySkillOptions}
          value={skill}
          onChange={setSkill}
          safeAreas={safeAreas}
        />
      </Box>
      <Grid container spacing={2}>
        <Grid item>
          <InputLabel>{t("routine.challenge.inputs.icon.label")}</InputLabel>
          <TaskIconPicker
            variant="challenge"
            categorizedIcons={categorizedIcons}
            icon={icon}
            setIcon={setIcon}
            iconManager={iconManager}
            safeAreas={safeAreas}
            lockPremiumIcons={false}
            onPremiumIconClicked={() => {}}
          />
        </Grid>
        <Grid item flexGrow={1} display="flex" flexDirection="column">
          <InputLabel>{t("routine.challenge.inputs.title.label")}</InputLabel>
          <TextInput
            placeholder={t("routine.challenge.inputs.title.placeholder")}
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
            error={titleError}
            fullWidth
            multiline
            data-cy={"challenge-edition-title-input"}
          />
          {titleError ? (
            <InputError>
              {t("general.charactersMax", { count: MAX_TITLE_LENGTH })}
            </InputError>
          ) : null}
        </Grid>
      </Grid>
      <Box mt={2}>
        <InputLabel>
          {t("routine.challenge.inputs.description.label")}&nbsp;(
          {t("general.optional")})
        </InputLabel>
        <TextInput
          placeholder={t("routine.challenge.inputs.description.placeholder")}
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          error={descriptionError}
          multiline
          fullWidth
          minRows={1}
          data-cy="challenge-edition-description-input"
        />
        {descriptionError ? (
          <InputError>
            {t("general.charactersMax", { count: MAX_DESCRIPTION_LENGTH })}
          </InputError>
        ) : null}
      </Box>
    </>
  );
};

export default ChallengeSection;
