import { FTUEFlow } from "models/FTUEFlow";
import { PrescriptionsProgress } from "..";
import useFTUE from "useFTUE";
import PrescriptionIntroDialog from "./PrescriptionIntroDialog";
import { SafeAreas } from "@neurosolutionsgroup/models";

export interface FTUEPrescriptionScreenProps {
  introImageSrc: string;
  safeAreas: SafeAreas;
}

const FTUEPrescriptionScreen = ({
  introImageSrc,
  safeAreas,
}: FTUEPrescriptionScreenProps): JSX.Element | null => {
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setPrescriptionsProgress },
  } = useFTUE();

  const prescriptionsProgress = progress[FTUEFlow.Prescriptions].progress;

  const onIntroContinue = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Intro);
  };

  const onIntroLater = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Later);
  };

  return canDisplayFlow(FTUEFlow.Prescriptions) &&
    prescriptionsProgress === PrescriptionsProgress.None ? (
    <PrescriptionIntroDialog
      imgSrc={introImageSrc}
      onContinue={onIntroContinue}
      onLaterClick={onIntroLater}
      safeAreas={safeAreas}
    />
  ) : null;
};
export default FTUEPrescriptionScreen;
