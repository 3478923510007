import { Grid, Typography } from "@mui/material";
import MiscAssets from "assets/misc";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

const IntroCoach: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      width="100%"
      height="100%"
      p={1}
      flexWrap="nowrap"
    >
      <Grid
        item
        flex="1 1 auto"
        sx={{
          img: {
            height: 0,
            minHeight: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
          },
        }}
        display="flex"
        justifyContent="center"
        p={1}
      >
        <img src={MiscAssets.IntroCoach} alt="" />
      </Grid>
      <Grid item>
        <Typography variant="h4" color="#fff" textAlign="center">
          {t("navigation.intro.coach.title")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="#fff" textAlign="center">
          <Trans i18nKey="navigation.intro.coach.text" />
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntroCoach;
