import { Box } from "@mui/material";
import {
  DashboardMedicalSectionOpened,
  DashboardRoutineSectionOpened,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { Filter, Page } from "common/Components";
import {
  DashboardProgress,
  FTUEFlow,
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MedicationDashboard from "./Medication/MedicationDashboard";
import RoutineDashboard from "./Routines/RoutineDashboard";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";
import { SAFE_AREAS } from "stylesheets";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useChildren from "common/hooks/children/useChildren";
import SkillsDashboardWrapper from "./SkillsDashboardWrapper";
import useChallenges from "common/hooks/challenges/useChallenges";

enum NavState {
  Medication,
  Routines,
  Skills,
}

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { handleEvent } = useAnalytics();
  const {
    selectors: { progress },
  } = useFTUE();
  const {
    selectors: { challenges },
  } = useChallenges();
  const {
    selectors: { childIds, selectedChild },
  } = useChildren();

  const [navState, setNavState] = useState<NavState[]>([NavState.Routines]);

  useEffect(() => {
    if (navState.includes(NavState.Routines)) {
      const event: DashboardRoutineSectionOpened = {
        name: "Dashboard Routine Section Opened",
      };

      handleEvent(event);
    } else if (navState.includes(NavState.Medication)) {
      const event: DashboardMedicalSectionOpened = {
        name: "Dashboard Medical Section Opened",
      };

      handleEvent(event);
    }
  }, [navState]);

  useEffect(() => {
    if (
      challenges.filter((challenge) => challenge.childId === selectedChild)
        .length > 0
    ) {
      setNavState([NavState.Skills]);
    }
  }, [challenges, selectedChild]);

  return (
    <Page className="home-page" ftue={childIds.length === 0}>
      <FTUEFlowDefinitions.DashboardFTUEFlow.Screen
        safeAreas={SAFE_AREAS}
        language={language}
      />
      <Box pb={STICKY_BUTTONS_PAGE_PADDING}>
        <FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardFilters>
          <Filter
            options={[
              {
                id: NavState.Routines,
                label: t("dashboard.routines"),
              },
              {
                id: NavState.Skills,
                label: t("dashboard.skills.nav"),
              },
              {
                id: NavState.Medication,
                label: t("dashboard.prescription"),
                disabled:
                  progress[FTUEFlow.Dashboard].progress ===
                  DashboardProgress.Intro,
              },
            ]}
            value={navState}
            onChange={setNavState}
            single
            data-cy="dashboard-section-filter"
          />
        </FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardFilters>
        {navState.includes(NavState.Medication) && (
          <Box mt={2}>
            <MedicationDashboard />
          </Box>
        )}
        {navState.includes(NavState.Skills) ? (
          <Box mt={2}>
            <SkillsDashboardWrapper />
          </Box>
        ) : null}
        {navState.includes(NavState.Routines) && (
          <Box mt={2}>
            <RoutineDashboard />
          </Box>
        )}
      </Box>
    </Page>
  );
};

export default Dashboard;
