import { inputBaseClasses, TextField, TextFieldProps } from "@mui/material";

interface TextInputProps {
  "data-cy"?: string;
}

const TextInput: React.FC<TextInputProps & TextFieldProps> = ({
  "data-cy": dataCy,
  ...props
}) => {
  return (
    <TextField
      {...props}
      inputProps={{
        ...props.inputProps,
        "data-cy": dataCy,
      }}
      color="secondary"
      sx={(theme) => ({
        [`& .${inputBaseClasses.multiline}`]: {
          padding: "8px 10px",
        },
        input: {
          fontSize: "0.875rem",
          lineHeight: 1.75,
          fontWeight: "normal",
          padding: "8px 10px",
        },
        textarea: {
          fontSize: "0.875rem",
          lineHeight: 1.75,
          fontWeight: "normal",
        },
        fieldset: {
          boxShadow: "0 0 1px 1px rgba(0, 0, 0, 0.15) inset",
          borderWidth: props.error ? "1px" : 0,
          borderColor: theme.palette.error.main,
          borderStyle: "solid",
        },
      })}
    />
  );
};

export default TextInput;
