import {
  AvatarDefinition,
  FirestoreGamerChildDocument,
} from "@neurosolutionsgroup/models";
import { v4 } from "uuid";
import levelsL15 from "./gamerChild/Level15/levels.json";
import currenciesL15 from "./gamerChild/Level15/currencies.json";
import powersL15 from "./gamerChild/Level15/powers.json";
import rewardsL15 from "./gamerChild/Level15/rewards.json";
import levelsNew from "./gamerChild/New/levels.json";
import currenciesNew from "./gamerChild/New/currencies.json";
import powersNew from "./gamerChild/New/powers.json";
import rewardsNew from "./gamerChild/New/rewards.json";

export const constructGamerChildDoc = (
  userId: string,
  tenantId: string,
  gamerChildId: string,
  name: string,
  creation: number,
  icon: number,
  newChild: boolean,
  birthDate?: number
): FirestoreGamerChildDocument => {
  return {
    userId,
    tenantId,
    name,
    creation,
    birthDate: birthDate ?? null,
    gender: 0,
    icon,
    id: gamerChildId,
    nip: null,
    isDisabled: null,
    useImageNip: false,
    userSetting: {
      controllerType: 0,
    },
    avatarCustomization: constructAvatar(),
    gameProgression: constructGameProgression(newChild),
    isDefault: null,
  };
};

const constructGameProgression = (
  newChild: boolean
): Record<string, unknown> => {
  return {
    achievements: null,
    askParentTrial: false,
    completedLevels: newChild ? levelsNew : levelsL15,
    currencies: newChild ? currenciesNew : currenciesL15,
    haveSeenStory: true,
    hqDecorations: null,
    lastCompletedLevel: 0,
    lastDatesChecked: null,
    lastNewLevel: 0,
    lifes: null,
    loginDaysCount: 0,
    loginRewardEventCount: null,
    notifications: null,
    powers: newChild ? powersNew : powersL15,
    progressionReworked: false,
    rewards: newChild ? rewardsNew : rewardsL15,
    seenCinematics: newChild ? [] : ["CINEMATIC_INTRO", "CINEMATIC_1-14"],
    seenCompletedChallengesNotifications: [],
    seenDialogues: newChild
      ? []
      : [
          "FTUE_STORYINTRO",
          "FTUE_WORLDMAP",
          "MIDDLE_AGES_REFACTOR_2022",
          "FTUE_SHOP_WARDROBE",
          "FTUE_SHOP_PROMPT",
          "FTUE_ShopBuy",
          "FTUE_ShopItemLocked",
          "FTUE_ShopItemUnlocked",
          "FTUE_ShopItemLockedChallenge",
          "FTUE_ShopRecolour",
          "FTUE_WARDROBE_PROMPT",
          "FTUE_Wardrobe",
          "FTUE_TRAININGROOM",
          "FTUE_TROPHYSHELF",
          "FTUE_POWERSCREEN",
          "FTUE_WardrobeReset",
          "LEVEL_1_1_BEGIN",
          "MIDDLE_AGES_REFACTOR_2022",
          "FTUE_ROUTINE",
          "FTUE_TIMER",
          "FTUE_ROUTINE_TOOL_TASK_REWARD",
          "FTUE_TEMPORAL_CHEST_RECAP",
          "FTUE_OPEN_SURPRISE_BOX",
          "FTUE_LOOT_SURPRISE_BOX",
        ],
    shouldShowSarbakanUpdate: false,
    supriseBoxes: null,
    trophies: newChild
      ? null
      : [
          {
            item1: "Trophy Onboarding",
            item2: 1,
          },
        ],
    unlimitedLevelsPerDay: false,
    updates: newChild
      ? []
      : [
          "NEW_PLAYER_1.27.0",
          "LOGIN_REWARD",
          "MIDDLE_AGES_REFACTOR_2022",
          "MIDDLE_AGES_REFACTOR_2022_XP",
          "XP_CUBES_TO_RECAP_SCREEN",
          "CLEARED_FIRST_OUTFIT",
          "TEMPORAL_CHEST_FIRST_OPENED",
          "BeginnerLuckPerfectRoutine",
        ],
    version: 0,
  };
};

const constructAvatar = (): Record<string, unknown> => {
  const outfitId = v4();

  const avatarItems = defaultAvatarItems;

  const outfit: AvatarDefinition = {
    Accessory: "0Accessory Default",
    Backpack: "0Backpack Default",
    Eye: "0Eye Default",
    Glove: "0Glove Default",
    Hair: "0Hair Default",
    Hat: "0Hat Default",
    Index: 0,
    Mouth: "0Mouth Default",
    Nose: "Nose Default",
    Pant: "0Pant Default",
    Shirt: "0Shirt Default",
    Shoe: "0Shoe Default",
    Skin: "0Skin Default",
    Watch: "0Watch Default",
  };

  return {
    equippedOutfit: outfitId,
    favouriteOutfit: null,
    items: avatarItems.map((item) => ({ item1: item, item2: 1 })),
    outfits: {
      [outfitId]: outfit,
    },
    version: 1,
  };
};

const defaultAvatarItems = [
  "0Accessory Default",
  "0Backpack Default",
  "0Eye Default",
  "Eye Default 2",
  "Eye Default 3",
  "0Glove Default",
  "0Hair Default",
  "0Hair Girl Default",
  "0Hat Default",
  "0Mouth Default",
  "Nose Default",
  "Nose Default1",
  "Nose Default2",
  "Nose Default3",
  "Nose Default4",
  "0Pant Default",
  "0Shirt Default",
  "0Shoe Default",
  "0Skin Default",
  "0Watch Default",
  "Eye Default 4",
  "Eye Default 5",
  "Eye Default 6",
  "Eye Round",
  "Eye Round Green",
  "Eye Small",
  "Angry Mouth",
  "Sad Mouth",
  "Smily Mouth",
  "Mouth Generic 1",
  "Mouth Generic 2",
  "Mouth OneTheet",
  "Skin Black",
  "Glove Day to Day",
  "Skin Asian",
  "Skin Tan",
  "Nose Default Asian",
  "Nose Default3 Asian",
  "Nose Default Black",
  "Nose Default3 Black",
  "Nose Default Tan",
  "Nose Default3 Tan",
  "Nude Lips Mouth",
];
