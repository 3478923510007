import { ButtonBase } from "@mui/material";
import { PropsWithChildren } from "react";

interface DurationSliderStepProps extends PropsWithChildren {
  onClick: VoidFunction;
  disabled?: boolean;
  dataCy?: string;
}

const DurationSliderStep = ({
  onClick,
  disabled = false,
  dataCy,
  ...props
}: DurationSliderStepProps): JSX.Element => {
  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      sx={(theme) => ({
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "999px",
        padding: 0.25,
        color: "#fff",
        opacity: disabled ? 0.6 : 1,
      })}
      data-cy={dataCy}
    >
      {props.children}
    </ButtonBase>
  );
};

export default DurationSliderStep;
