import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Dashboard, { DashboardProgress, DASHBOARD_PROGRESS_TOTAL } from "..";
import { FTUEFlow } from "../../../models/FTUEFlow";
import { FTUEHint } from "../../../components";
import useFTUE from "useFTUE";

const DashboardFilters = (props: PropsWithChildren) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setDashboardProgress },
  } = useFTUE();

  const dashboardProgress = progress[FTUEFlow.Dashboard].progress;

  const onNext = () => {
    setDashboardProgress(DashboardProgress.Filters);
  };

  const onQuit = () => {
    setDashboardProgress(DashboardProgress.DashboardFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        dashboardProgress === DashboardProgress.Intro &&
        canDisplayFlow(FTUEFlow.Dashboard)
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.dashboard.filter.text")}
      floating
      currentStep={Dashboard.Logic.calculateProgress(DashboardProgress.Intro)}
      maxStep={DASHBOARD_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default DashboardFilters;
