import formatISO from "date-fns/formatISO";
import compareAsc from "date-fns/compareAsc";
import parseISO from "date-fns/parseISO";
import { format, isSameMonth, isSameYear } from "date-fns";
import { Language } from "@neurosolutionsgroup/models";

const NBSP = "\u00a0";

const compareDateStrings = (a: string, b: string): number => {
  return compareAsc(parseISO(a), parseISO(b));
};

/**
 * Generate the string representation of date in format YYYY-MM-DD.
 */
const getDateStringFromDate = (date: Date): string => {
  return formatISO(date, { representation: "date" });
};

const localizedDurationFromMinutes = (
  minutes: number,
  localShortMins: string,
  localShortHours: string
): string => {
  if (minutes < 60) {
    return `${minutes} ${localShortMins}`;
  } else {
    return `${Math.floor(minutes / 60)} ${localShortHours} ${
      minutes % 60 === 0 ? "00" : minutes % 60
    }`;
  }
};

/**
 * Generate a localised string representation of a time of day defined by number of seconds since midnight.
 * @param seconds Representation of time of day by number of seconds since midnight.
 * @param language The language of the string you want, either "en" or "fr".
 * @returns A localised string displaying a human readable time.
 */
const localizedTimeFromSeconds = (
  seconds: number,
  language: Language,
  breakable = false
): string => {
  const space = breakable ? " " : NBSP;

  const isAM = seconds < 43200;

  if (language === "en") {
    seconds = seconds < 46800 ? seconds : seconds - 43200;
  }

  let hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  if (language === "en" && hours === 0) {
    hours = 12;
  }

  if (language === "en") {
    return (
      (hours < 10 ? "0" : "") +
      hours.toFixed(0) +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes.toFixed(0) +
      space +
      (isAM ? "AM" : "PM")
    );
  } else {
    return (
      (hours < 10 ? "0" : "") +
      hours.toFixed(0) +
      ":" +
      (minutes < 10 ? "0" : "") +
      minutes.toFixed(0)
    );
  }
};

/**
 * Generate a localised string representation of a preiod of time, with start and end defined by number of seconds since midnight.
 * @param start Representation of start of period by number of seconds since midnight.
 * @param end Representation of end of period by number of seconds since midnight.
 * @param language The language of the string you want, either "en" or "fr".
 * @returns
 */
const localizedTimePeriodFromSeconds = (
  start: number,
  end: number,
  language: Language
): string => {
  const noon = 43200;

  const startIsAM = start < noon;
  const endIsAM = end < noon;
  const bothSamePeriod = startIsAM === endIsAM;

  if (language === "en") {
    start = start < 46800 ? start : start - noon;
    end = end < 46800 ? end : end - noon;
  }

  const hours = (seconds: number): number => {
    return Math.floor(seconds / 3600);
  };
  const minutes = (seconds: number): number => {
    return Math.floor((seconds - hours(seconds) * 3600) / 60);
  };

  if (language === "en") {
    return (
      (hours(start) < 10 ? "0" : "") +
      hours(start).toFixed(0) +
      ":" +
      (minutes(start) < 10 ? "0" : "") +
      minutes(start).toFixed(0) +
      (bothSamePeriod ? "" : `${NBSP}AM`) +
      ` - ` +
      (hours(end) < 10 ? "0" : "") +
      hours(end).toFixed(0) +
      ":" +
      (minutes(end) < 10 ? "0" : "") +
      minutes(end).toFixed(0) +
      NBSP +
      (endIsAM ? "AM" : "PM")
    );
  } else {
    return (
      (hours(start) < 10 ? "0" : "") +
      hours(start).toFixed(0) +
      ":" +
      (minutes(start) < 10 ? "0" : "") +
      minutes(start).toFixed(0) +
      ` - ` +
      (hours(end) < 10 ? "0" : "") +
      hours(end).toFixed(0) +
      ":" +
      (minutes(end) < 10 ? "0" : "") +
      minutes(end).toFixed(0)
    );
  }
};

const localizedPeriodFromDates = (
  start: Date,
  end: Date,
  dateLocale: Locale
): string => {
  const endDate = format(end, "d LLL u", { locale: dateLocale });

  let startDate = format(start, "d", { locale: dateLocale });

  if (!isSameMonth(end, start)) {
    startDate = startDate + " " + format(start, "LLL", { locale: dateLocale });
  }

  if (!isSameYear(end, start)) {
    startDate = startDate + " " + format(start, "u", { locale: dateLocale });
  }

  return startDate + " - " + endDate;
};

/**
 * Generate a string representation of a time of day defined by number of seconds since midnight.
 * @param {number} seconds Representation of time of day by number of seconds since midnight.
 * @returns {string} A string displaying a human readable time.
 */
const secondsToTimeStamp = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds - hours * 3600) / 60);

  return (
    (hours < 10 ? "0" : "") +
    hours.toFixed(0) +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes.toFixed(0)
  );
};

const Strings = {
  compareDateStrings,
  getDateStringFromDate,
  localizedDurationFromMinutes,
  localizedPeriodFromDates,
  localizedTimeFromSeconds,
  localizedTimePeriodFromSeconds,
  secondsToTimeStamp,
};

export default Strings;
