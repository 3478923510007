import { Box, Grid, Typography } from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

interface PasswordStrengthProps {
  password: string;
}

const PasswordStrength: React.FC<PasswordStrengthProps> = ({ password }) => {
  const { t } = useTranslation();

  const passwordStrengthColours = {
    strong: "#31737c",
    moderate: "#fdca4f",
    weak: "#ff002e",
  };

  const passwordStrength: "weak" | "moderate" | "strong" = useMemo(() => {
    if (
      new RegExp(
        /^(?=.*[A-Z])(?=.*[!@#$&*%#])(?=.*[0-9])(?=.*[a-z]).{8,}$/
      ).test(password)
    ) {
      return "strong";
    } else if (
      new RegExp(/^(?=.*[A-Z,0-9,!@#$&*%#])(?=.*[a-z]).{8,}$/).test(password)
    ) {
      return "moderate";
    } else {
      return "weak";
    }
  }, [password]);

  return password.length >= 6 ? (
    <Grid container alignItems="center" spacing={1}>
      <Grid item flex={1}>
        <Box
          sx={{
            height: "4px",
            backgroundColor: passwordStrengthColours[passwordStrength],
            borderRadius: "2px",
          }}
        />
      </Grid>
      <Grid item>
        <Typography fontSize="0.8rem">
          {t(`auth.inputs.password.strength.${passwordStrength}`)}
        </Typography>
      </Grid>
    </Grid>
  ) : null;
};

export default PasswordStrength;
