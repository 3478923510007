import { Language, Prescription } from "@neurosolutionsgroup/models";
import { MedicationById } from "common/hooks/medications/MedicationContext";
import React from "react";

export const medicationLegendFormatter = (
  value: string,
  prescriptions: Prescription[],
  language: Language,
  emptyString: string,
  medicationById?: MedicationById
): JSX.Element | string => {
  const prescriptionIds = value.split(":");

  if (prescriptionIds.length === 0 || prescriptionIds[0] === "") {
    return emptyString;
  }

  const prescriptionsFiltered = prescriptions.filter((p) =>
    prescriptionIds.includes(p.prescriptionId)
  );

  const prescriptionStrings = prescriptionsFiltered.map((p) => {
    // 20mg / 10mg / 5mg
    const doseText = p.drug.doses
      ? `${p.drug.doses
          .sort((a, b) => a.time - b.time)
          .map(
            (d, i) =>
              `${d.quantity}mg ${i !== p.drug.doses.length - 1 ? "/" : ""}`
          )
          .join(" ")}`
      : "";

    const drug = medicationById ? medicationById[p.drug.drugId] : undefined;

    const drugText = drug ? drug.name[language] : p.drug.drugId;

    return `${drugText} ${doseText}`;
  });

  return (
    <>
      {prescriptionStrings.map((p, i) => (
        <span key={i}>
          {i > 0 ? <br /> : null}
          {p}
        </span>
      ))}
    </>
  );
};

export const getGraphXDomain = (
  dataLength: number,
  ticks: number[]
): number[] => {
  const padding = dataLength < 4 ? 0.2 : 0.65;

  return [ticks[0] - padding, ticks[ticks.length - 1] + padding];
};
