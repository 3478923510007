import { Box, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface PlanMarketingTagProps extends PropsWithChildren {
  content: string;
}

const PlanMarketingTag = ({
  content,
  ...props
}: PlanMarketingTagProps): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        borderRadius: "5px",
      }}
      display="flex"
      flexDirection="column"
      alignItems="stretch"
      width="100%"
      boxSizing="border-box"
    >
      <Typography
        color="#fff"
        textAlign="center"
        fontWeight={700}
        fontSize="0.85rem"
        textTransform="uppercase"
        my={0.2}
      >
        {content}
      </Typography>
      {props.children}
    </Box>
  );
};

export default PlanMarketingTag;
