import DownloadIcon from "@mui/icons-material/Download";
import { Button, Grid2, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface ToolOptionProps {
  imageSrc: string;
  text: string;
  onClick: () => void;
  loading: boolean;
}

const ToolOption = ({
  imageSrc,
  text,
  onClick,
  loading,
}: ToolOptionProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid2 container spacing={2}>
      <Grid2
        size={{ xs: 6, sm: 12 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          img: {
            maxHeight: "200px",
            maxWidth: "100%",
            objectFit: "contain",
          },
        }}
      >
        <img src={imageSrc} alt="" />
      </Grid2>
      <Grid2
        size={{ xs: 6, sm: 12 }}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography mb={1} fontWeight={700} textAlign="center">
          {text}
        </Typography>
        <Button
          onClick={onClick}
          loading={loading}
          loadingPosition="start"
          startIcon={<DownloadIcon />}
          color="secondary"
          variant="contained"
          fullWidth
          sx={{
            maxWidth: "200px",
          }}
        >
          {t("general.actions.download")}
        </Button>
      </Grid2>
    </Grid2>
  );
};

export default ToolOption;
