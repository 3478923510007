import { Grid, Typography } from "@mui/material";
import React from "react";
import NotificationButton, {
  NotificationButtonProps,
} from "./NotificationButton";

export interface NotificationGroupProps {
  groupTitle: string;
  buttons: NotificationButtonProps[];
}

const NotificationGroup: React.FC<NotificationGroupProps> = ({
  groupTitle,
  buttons,
}) => {
  return (
    <Grid container sx={{ width: "100%" }}>
      <Typography fontWeight="bold">{groupTitle}</Typography>
      {buttons.map((b, i) => {
        return (
          <NotificationButton
            key={i}
            label={b.label}
            state={b.state}
            setState={b.setState}
          />
        );
      })}
    </Grid>
  );
};
export default NotificationGroup;
