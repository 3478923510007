import { Grid, Typography } from "@mui/material";
import { Tools } from "@neurosolutionsgroup/tools";
import DynamismAssets from "assets/dynamism";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React from "react";
import { useTranslation } from "react-i18next";

interface ObservationEmptyStateProps {
  small: boolean;
}

const ObservationEmptyState = ({
  small,
}: ObservationEmptyStateProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return small ? (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        img: {
          height: "150px",
        },
      }}
    >
      <img src={DynamismAssets.Observation_Validation} alt="" />
    </Grid>
  ) : (
    <Grid
      item
      xs={12}
      sx={{
        "display": "flex",
        "justifyContent": "center",
        "position": "relative",
        "img": {
          width: "100%",
        },
        "& .observation-empty-state__text": {
          position: "absolute",
          top: "20%",
          right: 0,
          left: "60%",
        },
      }}
    >
      <Typography
        className="observation-empty-state__text"
        fontSize="9px"
        fontWeight="bold"
      >
        {t("validation.observations.emptyStateImage")}
      </Typography>
      <img
        src={Tools.Language.languageSwitch(language, {
          de: DynamismAssets.ObservationEmptyDE,
          en: DynamismAssets.ObservationEmptyEN,
          fr: DynamismAssets.ObservationEmptyFR,
        })}
        alt=""
      />
    </Grid>
  );
};

export default ObservationEmptyState;
