import { Grid } from "@mui/material";
import { StickyButtons } from "@neurosolutionsgroup/components";
import IntroStart from "./IntroStart";
import { useTranslation } from "react-i18next";
import { FullScreenFTUEProps } from "components";
import useFTUE from "useFTUE";
import { DashboardProgress } from "flows/Dashboard";

const Intro = (props: FullScreenFTUEProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    actions: { setDashboardProgress },
  } = useFTUE();

  const onDiscover = () => {
    setDashboardProgress(DashboardProgress.Intro);
  };

  return (
    <Grid container direction="column" height="100%">
      <Grid item flexGrow={1} width="100%">
        <IntroStart />
      </Grid>
      <StickyButtons
        onConfirm={onDiscover}
        confirmText={t("ftue.dashboard.discover.text")}
        safeAreas={props.safeAreas}
      />
    </Grid>
  );
};

export default Intro;
