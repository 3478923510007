import FirebaseAPI from "@neurosolutionsgroup/api-client";
import {
  NotificationCategorySettings,
  NotificationsSettings,
} from "@neurosolutionsgroup/models";
import NotificationCategorySettingsSetterService, {
  NotificationOptInLastSeenSettingsSetterService,
} from "./NotificationSettingsSetter";

const updateSettings = async (
  globalOptIn: boolean,
  categories: NotificationCategorySettings
): Promise<NotificationsSettings> => {
  return await FirebaseAPI.Analytics.CustomerIO.updateSettings(
    globalOptIn,
    categories
  );
};

const setOptInLastSeen = async (): Promise<void> => {
  return await FirebaseAPI.Analytics.CustomerIO.setOptInLastSeen();
};

const CustomerIOSettingsSetterAdapter: NotificationCategorySettingsSetterService &
  NotificationOptInLastSeenSettingsSetterService = {
  updateSettings,
  setOptInLastSeen,
};

export default CustomerIOSettingsSetterAdapter;
