export enum MedPeriod {
  LastTwoWeeks,
  ThisMonth,
  LastTwoMonths,
  LastThreeMonths,
  LastSixMonths,
  LastNineMonths,
  LastYear,
}

export const medicalPeriodWeeks = {
  [MedPeriod.LastTwoWeeks]: 2,
  [MedPeriod.ThisMonth]: 4,
  [MedPeriod.LastTwoMonths]: 8,
  [MedPeriod.LastThreeMonths]: 12,
  [MedPeriod.LastSixMonths]: 24,
  [MedPeriod.LastNineMonths]: 36,
  [MedPeriod.LastYear]: 52,
};

export type MedPeriodLoc = {
  en: {
    [x: number]: string;
  };
  fr: {
    [x: number]: string;
  };
  de: {
    [x: number]: string;
  };
};
