import {
  Button,
  inputBaseClasses,
  TextField,
  TextFieldProps,
} from "@mui/material";
import Icons from "../Icons";
import { SharedStyles } from "@neurosolutionsgroup/themes";

type ChatInputProps = {
  onSubmit: VoidFunction;
};

const ChatInput = ({
  onSubmit,
  ...props
}: ChatInputProps & TextFieldProps): JSX.Element => {
  const ChatInputButton = (
    <Button
      onClick={onSubmit}
      disabled={props.disabled}
      color="secondary"
      variant="contained"
      sx={{
        marginLeft: 1,
        borderRadius: "999px",
        minWidth: "2rem",
        minHeight: "2rem",
        padding: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        svg: {
          width: "1rem",
          height: "1rem",
        },
      }}
    >
      <Icons.ArrowIcon arrowDirection="right" color="#fff" arrowType="simple" />
    </Button>
  );

  return (
    <TextField
      color="secondary"
      {...props}
      multiline
      maxRows={6}
      sx={[
        {
          boxShadow: SharedStyles.Shadows.defaultShadow,
          [`& .${inputBaseClasses.root}`]: {
            paddingY: 1,
            fontWeight: "normal",
          },
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      InputProps={{
        endAdornment: ChatInputButton,
        ...props.InputProps,
      }}
    />
  );
};

export default ChatInput;
