import {
  Accordion,
  accordionClasses,
  AccordionProps,
  styled,
} from "@mui/material";

const FormAccordion = styled((props: AccordionProps) => (
  <Accordion {...props} />
))({
  "boxShadow":
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 0px 0px 1px rgba(255,255,255,0.5)",
  "overflow": "hidden",
  [`&.${accordionClasses.expanded}`]: {
    borderRadius: "10px",
  },
  "&:first-of-type": {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
  },
  "&:last-of-type": {
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
  },
});

export default FormAccordion;
