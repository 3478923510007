import { Box, Grid, Typography } from "@mui/material";
import BackgroundAssets from "assets/background";
import CoachAssets from "assets/coach";
import { StickyButtons } from "common/Components";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CoachNotification, CoachNotificationID } from "./CoachNotifications";

interface CoachFullScreenProps {
  coachNotification: CoachNotification;
  onDismiss: VoidFunction;
}

const CoachFullScreen: React.FC<CoachFullScreenProps> = ({
  coachNotification,
  onDismiss,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const onConfirm = () => {
    navigate("/follow-ups");
    onDismiss();
  };

  const getImage = () => {
    switch (coachNotification.id) {
      case CoachNotificationID.ValidationReminder:
        return CoachAssets.ValidationReminder;
      default:
        return undefined;
    }
  };

  return (
    <Box
      sx={{
        position: "fixed",
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "secondary.main",
        backgroundImage: `url(${BackgroundAssets.BlueBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingBottom: STICKY_BUTTONS_PAGE_PADDING,
      }}
      p={1}
    >
      <Grid container direction="column" spacing={1} height="100%">
        <Grid item mt="auto">
          <Typography variant="h3" color="#fff" textAlign="center">
            {t(
              `coach.coachNotification.${coachNotification.id.toString()}.title`
            )}
          </Typography>
        </Grid>
        <Grid item my="auto">
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              img: {
                maxHeight: "50vh",
                width: "auto",
              },
            }}
          >
            <img src={getImage()} alt="" />
          </Box>
        </Grid>
        <Grid item>
          <Typography color="#fff" textAlign="center">
            <Trans
              i18nKey={`coach.coachNotification.${coachNotification.id.toString()}.message`}
              components={{ bold: <strong /> }}
            />
          </Typography>
        </Grid>
        <StickyButtons
          onConfirm={onConfirm}
          confirmText={t("general.actions.validate")}
          onCancel={onDismiss}
          cancelText={t("general.actions.later")}
        />
      </Grid>
    </Box>
  );
};

export default CoachFullScreen;
