import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import {
  coachNotificationDefinitions,
  CoachNotificationID,
} from "./CoachNotifications";
import { useProfileContext } from "common/hooks/account/UserProfileContext";
import useAuth from "common/hooks/auth/useAuth";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  CoachFlags,
  FirestoreCollection,
  MessageRecord,
  MessagingDocument,
} from "@neurosolutionsgroup/models";
import { getFirestore, doc, onSnapshot, Unsubscribe } from "firebase/firestore";
import {
  createMessageDoc,
  isCoachNotificationId,
  migrateOldMessageRecords,
} from "../tools";
import { useFTUEContext } from "@neurosolutionsgroup/webviews-ftue";

export interface DifficultTaskResult {
  taskId: string;
  taskShouldDisplay: boolean;
  dueTime: number;
  newCount?: number;
  newLatestValidation?: number;
}

export interface CoachMessage {
  id: CoachNotificationID;
  data?: Record<string, number | string | string[]>;
}

export interface CoachContextData {
  coachRecordsInitializationComplete: boolean;
  coachRecords: MessageRecord[];
  setCoachRecords: React.Dispatch<SetStateAction<MessageRecord[]>>;
  coachFlags: CoachFlags;
  coachMessage: CoachMessage | undefined;
  setCoachMessage: React.Dispatch<SetStateAction<CoachMessage | undefined>>;
  coachMessageQueue: CoachMessage[];
  setCoachMessageQueue: React.Dispatch<SetStateAction<CoachMessage[]>>;
  coachCanBeDisplayed: boolean;
  setCoachCanBeDisplayed: React.Dispatch<SetStateAction<boolean>>;
  failedTaskByChildId: {
    [childId: string]: DifficultTaskResult[];
  };
  setFailedTaskByChildId: React.Dispatch<
    SetStateAction<{
      [childId: string]: DifficultTaskResult[];
    }>
  >;
}

const [useCoachContext, CoachContextProvider] =
  Tools.Context.createGenericContext<CoachContextData>(__filename);

interface CoachProviderProps {
  active?: boolean;
}

const CoachProvider: React.FC<PropsWithChildren<CoachProviderProps>> = ({
  active = true,
  ...props
}) => {
  const [
    coachRecordsInitializationComplete,
    setCoachRecordsInitializationComplete,
  ] = useState(false);
  const [coachRecords, setCoachRecords] = useState<MessageRecord[]>([]);
  const [coachFlags, setCoachFlags] = useState<CoachFlags>({});
  const [coachMessage, setCoachMessage] = useState<CoachMessage | undefined>(
    undefined
  );
  const [coachMessageQueue, setCoachMessageQueue] = useState<CoachMessage[]>(
    []
  );
  const [coachCanBeDisplayed, setCoachCanBeDisplayed] = useState(active);
  const [failedTaskByChildId, setFailedTaskByChildId] = useState<{
    [childId: string]: DifficultTaskResult[];
  }>({});

  const { firstPageFTUEIsRunning } = useFTUEContext();
  const { profileInitializationComplete, coachNotifications } =
    useProfileContext();

  const { user, tenantId } = useAuth();

  useEffect(() => {
    if (profileInitializationComplete) {
      if (user && tenantId) {
        let unsub: Unsubscribe | null = null;

        const loadMessages = async () => {
          const db = getFirestore();
          const collection = FirestoreCollection.CoachMessages;

          unsub = onSnapshot(doc(db, collection, user.uid), async (docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data() as MessagingDocument;

              if (data.messages) {
                const messages = Object.values(data.messages);

                setCoachRecords(messages);
                if (data.flags) {
                  setCoachFlags(data.flags);
                }
                setCoachRecordsInitializationComplete(true);
              }
            } else {
              const migratedMessages = migrateOldMessageRecords(
                coachNotifications.filter((notif) =>
                  isCoachNotificationId(notif.id)
                )
              );

              await createMessageDoc(
                collection,
                user.uid,
                tenantId,
                migratedMessages
              );
            }
          });
        };

        loadMessages();

        return () => {
          if (unsub) {
            unsub();
          }
        };
      }
    }
  }, [profileInitializationComplete, user]);

  useEffect(() => {
    setCoachCanBeDisplayed(!firstPageFTUEIsRunning);
  }, [firstPageFTUEIsRunning]);

  useEffect(() => {
    if (coachMessageQueue.length > 0) {
      setCoachMessage(
        // Sort notifications by priority and only show the highest.
        coachMessageQueue.sort((a, b) => {
          return (
            coachNotificationDefinitions[b.id].priority -
            coachNotificationDefinitions[a.id].priority
          );
        })[0]
      );
    }
  }, [coachMessageQueue]);

  useEffect(() => {
    if (!user) {
      setCoachRecords([]);
      setCoachMessage(undefined);
      setCoachMessageQueue([]);
      setCoachCanBeDisplayed(active);
    }
  }, [user]);

  return (
    <CoachContextProvider
      value={{
        coachRecordsInitializationComplete,
        coachRecords,
        setCoachRecords,
        coachFlags,
        coachMessage,
        setCoachMessage,
        coachMessageQueue,
        setCoachMessageQueue,
        coachCanBeDisplayed,
        setCoachCanBeDisplayed: active
          ? setCoachCanBeDisplayed
          : () => {
              console.log("Coach de-activated.");
            },
        failedTaskByChildId,
        setFailedTaskByChildId,
      }}
    >
      {props.children}
    </CoachContextProvider>
  );
};

export { useCoachContext, CoachProvider };
