import { SessionTrackingBody } from "@neurosolutionsgroup/models";
import { getURL, handleApiError } from "./utils";
import axios from "axios";

const postSessionEvent = async (
  body: SessionTrackingBody
): Promise<boolean> => {
  const URL = getURL() + "/session";

  try {
    await axios.post(URL, body);

    return true;
  } catch (err) {
    return handleApiError(err);
  }
};

const Session = {
  postSessionEvent,
};

export default Session;
