import { Challenge } from "@neurosolutionsgroup/models";
import Time from "../../../Time";

const challengeNeedsValidation = (
  challenge: Challenge,
  childIsDisabled: boolean
): boolean => {
  if (childIsDisabled) {
    return false;
  }

  if (challenge.requiresApproval) {
    return false;
  }

  if (challenge.successful !== null) {
    return false;
  }

  if (challenge.history.some((history) => history.parentStatus === null)) {
    return true;
  }

  return (
    challenge.endDate !== null && challenge.endDate < Time.Dates.getTimeStamp()
  );
};

export default challengeNeedsValidation;
