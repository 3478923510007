import { Box, Typography } from "@mui/material";
import {
  Dialogs,
  InputError,
  TextInput,
} from "@neurosolutionsgroup/components";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface ChallengeRejectionDialogProps {
  open: boolean;
  onClose: VoidFunction;
  onRejectionCommentComplete: (comment: string) => void;
}

const ChallengeRejectionDialog = ({
  open,
  onClose,
  onRejectionCommentComplete,
}: ChallengeRejectionDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const REJECTION_COMMENT_MAXLENGTH = 250;

  const [rejectionComment, setRejectionComment] = useState("");

  const error = rejectionComment.length > REJECTION_COMMENT_MAXLENGTH;

  return (
    <Dialogs.AlertDialog
      open={open}
      onClose={onClose}
      positiveAction={{
        "text": t("general.actions.reject"),
        "action": () => onRejectionCommentComplete(rejectionComment),
        "data-cy": "challenge-rejection-confirm",
      }}
      negativeAction={{
        "text": t("general.actions.cancel"),
        "action": onClose,
        "data-cy": "challenge-rejection-cancel",
      }}
      text={
        <Box>
          <Typography mb={1}>{t("routine.challenge.reject.text")}</Typography>
          <TextInput
            placeholder={t("routine.challenge.reject.placeholder")}
            value={rejectionComment}
            onChange={(e) => {
              setRejectionComment(e.currentTarget.value);
            }}
            fullWidth
            error={error}
            data-cy="challenge-edition-title-input"
            multiline
            minRows={2}
          />
          {error ? (
            <InputError>
              {t("general.charactersMax", {
                count: REJECTION_COMMENT_MAXLENGTH,
              })}
            </InputError>
          ) : null}
        </Box>
      }
      data-cy={"challenge-rejection-dialog"}
    />
  );
};

export default ChallengeRejectionDialog;
