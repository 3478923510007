export enum JournalProgress {
  None = "none",
  Later = "FTUE_Journal_Later",
  Intro = "FTUE_Journal_Intro",
  DaysPicker = "FTUE_Journal_Days_Picker",
  AddNote = "FTUE_Add_Note",
  FillNote = "FTUE_Fill_Note",
  JournalFTUEFinished = "FTUE_Journal_Finished",
}

export const JOURNAL_PROGRESS_TOTAL = 4;
