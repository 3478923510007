import Confirmation from "./Confirmation";
import DatePicker from "./DatePicker";
import Dosage from "./Dosage";
import MedicationPicker from "./MedicationPicker";
import MedicationSelection from "./MedicationSelection";
import OtherMedication from "./OtherMedication";
import PrescriptionEnd from "./PrescriptionEnd";
import SelectSideEffects from "./SelectSideEffects";

const PrescriptionsHints = {
  Confirmation,
  DatePicker,
  Dosage,
  MedicationPicker,
  MedicationSelection,
  OtherMedication,
  PrescriptionEnd,
  SelectSideEffects,
};

export default PrescriptionsHints;
