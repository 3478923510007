import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Prescriptions, {
  PrescriptionsProgress,
  PRESCRIPTIONS_PROGRESS_TOTAL,
} from "..";
import useFTUE from "useFTUE";

const MedicationPicker: React.FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setPrescriptionsProgress },
  } = useFTUE();

  const prescriptionsProgress = progress[FTUEFlow.Prescriptions].progress;

  const onClick = () => {
    setPrescriptionsProgress(PrescriptionsProgress.TrigerMedication);
  };

  const onQuit = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Finished, true);
  };

  return (
    <FTUEHint
      condition={
        prescriptionsProgress === PrescriptionsProgress.DatePicker &&
        canDisplayFlow(FTUEFlow.Prescriptions)
      }
      hint={t("ftue.prescription.triggerMedication.text")}
      onClick={onClick}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      currentStep={Prescriptions.Logic.calculateProgress(
        PrescriptionsProgress.DatePicker
      )}
      maxStep={PRESCRIPTIONS_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};
export default MedicationPicker;
