import {
  Box,
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogProps,
  DialogTitle,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import BackgroundAssets from "assets/background";
import MiscAssets from "assets/misc";
import React from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../auth/useAuth";
import useParameters from "../Parameters/useParameters";
import { ErrorDefinition } from "./ErrorDefinition";
import { ExternalLink } from "../Parameters/UniWebViewActions";
import {
  ConfigString,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";

interface ErrorDialogProps extends DialogProps {
  error: ErrorDefinition;
  onClose: VoidFunction;
}

const ErrorDialog: React.FC<ErrorDialogProps> = ({
  error,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const { linkHandler } = useParameters();
  const { getRemoteConfigValue } = useRemoteConfig();

  const { user, logOut } = useAuth();

  const onLogOut = async () => {
    await logOut();
  };

  return (
    <Dialog
      {...props}
      onClose={() => console.log("ErrorDialog close intercepted.")}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          width: "90vw",
          maxWidth: "500px",
          height: "60vh",
          maxHeight: "750px",
          backgroundImage: `url(${BackgroundAssets.ErrorDialogBackground})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
          backgroundPosition: "top",
        },
      }}
      PaperProps={{
        "data-cy": "error-dialog",
      }}
    >
      <DialogTitle
        color="#fff"
        sx={{
          fontSize: "1rem",
          padding: "8px 12px",
        }}
      >
        {t("navigation.error.dialog.title")} {error.code}
      </DialogTitle>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "stretch",
          img: {
            width: "40%",
            marginBottom: "3rem",
          },
        }}
      >
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="center"
          alignItems="flex-start"
        >
          <img src={MiscAssets.ErrorTag} alt="" />
        </Box>
        <Box flexGrow={1}>
          <Typography textAlign="center" mb={1}>
            {t(error.userMessageKey)}
          </Typography>
          <Typography textAlign="center">
            {t("navigation.error.support")}&nbsp;
            <MuiLink
              onClick={() => {
                linkHandler(
                  ExternalLink.Support,
                  getRemoteConfigValue(ConfigString.SupportLink).asString()
                );
              }}
            >
              {t("navigation.error.supportLink")}
            </MuiLink>
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        {user ? (
          <Button color="secondary" variant="text" onClick={onLogOut}>
            {t("general.actions.logout")}
          </Button>
        ) : null}
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            error.action && error.action.actionFunction();
            onClose();
          }}
        >
          {error.action
            ? t(error.action.actionTextKey)
            : t("navigation.error.retry")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorDialog;
