import { Dialogs } from "@neurosolutionsgroup/components";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import {
  catmap,
  GamerChild,
  orderedNeedKeys,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  NoteFormState,
  NoteFormPage,
} from "@neurosolutionsgroup/webviews-pages";
import ChildIcons from "assets/child-icons";
import CoachAssets from "assets/coach";
import DialogAssets from "assets/dialogs";
import useChildren from "common/hooks/children/useChildren";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useNotes from "common/hooks/notes/useNotes";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useTasks from "common/hooks/routines/useTasks";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREAS } from "stylesheets";
import { v4 } from "uuid";

interface NoteCreationProps {
  open: boolean;
  onClose: VoidFunction;
  gamerChild: GamerChild;
}

const NoteCreation = ({
  open,
  onClose,
  gamerChild,
}: NoteCreationProps): JSX.Element | null => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();

  const {
    selectors: { getActiveFollowedSideEffects },
  } = useFollowUp();
  const { addNote } = useNotes();
  const {
    selectors: { sideEffectById },
  } = useSideEffect();
  const {
    selectors: { tasksById },
  } = useTasks();
  const {
    selectors: { medicalChildrenByGamerId },
  } = useChildren();

  const emptyFormState: NoteFormState = {
    title: "",
    text: "",
    date: Tools.Time.Dates.getMidnightDate(),
    sideEffectId: null,
    routineCategoryId: null,
    skillId: null,
  };

  const [formState, setFormState] = useState<NoteFormState>(emptyFormState);
  const [loading, setLoading] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const followedSideEffects = useMemo(() => {
    const followedSideEffectsForChild = getActiveFollowedSideEffects(
      gamerChild.id
    );

    return followedSideEffectsForChild.map((se) => se.sideEffectId);
  }, [gamerChild]);

  const myRoutineCategoryOptions: string[] = useMemo(() => {
    const categories: string[] = [];

    Object.keys(tasksById).forEach((tid) => {
      const category =
        tasksById[tid].category ?? catmap[tasksById[tid].icon.toFixed(0)]["0"];

      if (!categories.includes(category)) {
        categories.push(category);
      }
    });

    return categories;
  }, [tasksById, language]);

  const resetForm = () => {
    setFormState(emptyFormState);
  };

  const onComplete = async (state: NoteFormState) => {
    if (!state.date) {
      return;
    }

    setLoading(true);

    try {
      addNote({
        noteId: v4(),
        title: state.title,
        text: state.text,
        eventDate: Tools.Time.Strings.getDateStringFromDate(state.date),
        eventTime: undefined,
        sideEffectId: state.sideEffectId ?? undefined,
        sideEffectData: null,
        routineCategoryId: state.routineCategoryId ?? undefined,
        gamerChildId: gamerChild.id,
        needId: state.skillId ?? null,
      }).then(() => {
        resetForm();
        onClose();
      });
    } catch (err: unknown) {
      handleUnknownError(err);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    resetForm();
    setShowCancelDialog(false);
    onClose();
  };

  const targetedSkills = (): string[] => {
    const output = medicalChildrenByGamerId[gamerChild.id]?.needs;
    if (output !== undefined) {
      return output;
    }
    return [];
  };

  return open ? (
    <>
      <NoteFormPage
        formState={formState}
        setFormState={setFormState}
        onComplete={onComplete}
        onCancel={() => setShowCancelDialog(true)}
        language={language}
        sideEffectsById={sideEffectById}
        followedSideEffects={followedSideEffects}
        safeAreas={SAFE_AREAS}
        loading={loading}
        taskCategoryLoc={taskCategoryLoc}
        userTaskCategoryIds={myRoutineCategoryOptions}
        childName={gamerChild.name}
        childIconSrc={ChildIcons[gamerChild.icon]}
        coachImageSrc={CoachAssets.CoachDynamic}
        targetedSkills={targetedSkills()}
        skillIds={orderedNeedKeys}
      />
      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        title={t("journal.notes.cancel.title")}
        text={t("journal.notes.cancel.text")}
        onPositiveAction={onCancel}
        onNegativeAction={() => setShowCancelDialog(false)}
        imgSrc={DialogAssets.CancelTag}
        hideIrreversibleWarning
      />
    </>
  ) : null;
};

export default NoteCreation;
