import { Box } from "@mui/material";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Journal, { JournalProgress, JOURNAL_PROGRESS_TOTAL } from "..";
import useFTUE from "useFTUE";

export interface JournalFillNoteProps extends PropsWithChildren {
  disabled: boolean;
  onSave: VoidFunction;
}

const JournalFillNote: React.FC<JournalFillNoteProps> = ({
  disabled = false,
  onSave,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setJournalProgress },
  } = useFTUE();

  const journalProgress = progress[FTUEFlow.Journal].progress;

  const onNext = () => {
    setJournalProgress(JournalProgress.FillNote);
  };

  const onQuit = () => {
    setJournalProgress(JournalProgress.JournalFTUEFinished, true);
  };

  return (
    // Needs to envelop FTUE Hint so that the hint also has the offset
    <Box
      sx={{
        ...(journalProgress === JournalProgress.AddNote && {
          marginTop: "5em",
        }),
      }}
    >
      <FTUEHint
        condition={
          journalProgress === JournalProgress.AddNote &&
          canDisplayFlow(FTUEFlow.Journal)
        }
        hint={t("ftue.journal.fillNote.text")}
        onNext={() => {
          onSave();
          onNext();
        }}
        nextText={t("ftue.journal.fillNote.button")}
        quit={onQuit}
        quitText={t("ftue.validation.end")}
        centered
        nextDisabled={disabled}
        currentStep={Journal.Logic.calculateProgress(JournalProgress.AddNote)}
        maxStep={JOURNAL_PROGRESS_TOTAL}
      >
        {journalProgress === JournalProgress.AddNote &&
        canDisplayFlow(FTUEFlow.Journal) ? (
          <Box
            sx={{
              "& .MuiOutlinedInput-input": {
                color: "#fff",
              },
              "& .MuiTypography-root": {
                color: "#fff",
              },
            }}
          >
            {props.children}
          </Box>
        ) : (
          props.children
        )}
      </FTUEHint>
    </Box>
  );
};

export default JournalFillNote;
