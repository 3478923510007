import Calendar from "./subscription_calendar.png";
import Cancel from "./subscription_cancel.png";
import Decoration from "./subscription_decoration.png";

const SubscriptionAssets = {
  Calendar,
  Cancel,
  Decoration,
};

export default SubscriptionAssets;
