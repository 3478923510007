import { Box, Card, CardContent, Typography } from "@mui/material";

interface FTUECardProps {
  title: string;
  text: JSX.Element;
  imageSrc: string;
}

const FTUECard = ({ title, text, imageSrc }: FTUECardProps): JSX.Element => {
  return (
    <Card
      sx={{
        width: "90vw",
        maxWidth: "500px",
        zIndex: 10003,
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          height: "350px",
          maxHeight: "50vh",
        }}
      >
        <Typography variant="h2" textAlign="center">
          {title}
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            flexShrink: 1,
            marginY: 2,
            boxSizing: "border-box",
            img: {
              height: "100%",
              maxHeight: "150px",
              maxWidth: "100%",
              objectFit: "contain",
            },
          }}
        >
          <img src={imageSrc} alt="" />
        </Box>
        <Box flexGrow={1}>{text}</Box>
      </CardContent>
    </Card>
  );
};

export default FTUECard;
