import { Box, Typography } from "@mui/material";
import Gauge, { GaugeProps } from "./Gauge";

export interface GaugeLabel {
  value: number;
  text: string;
}

interface LabelledGaugeProps extends GaugeProps {
  labels: GaugeLabel[];
  fontScale?: number;
}

const LabelledGauge = ({
  labels,
  fontScale = 1,
  ...gaugeProps
}: LabelledGaugeProps): JSX.Element => {
  const labelWidthRem = 3 * fontScale;

  const labelPositions: {
    text: string;
    normalisedValue: number;
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  }[] = labels.map((label) => {
    const normalisedValue = label.value / (gaugeProps.max - gaugeProps.min);

    let angleRads = 0;

    const xPosition = 50;

    // If right-hand-side then calculate angle from rhs.
    // Else from lhs.
    if (normalisedValue >= 0.5) {
      angleRads = Math.PI * (1 - normalisedValue);
    } else {
      angleRads = Math.PI * normalisedValue;
    }

    const sinOfAngle = Math.sin(angleRads);

    const top = 100 - 100 * sinOfAngle;

    const cosOfAngle = Math.cos(angleRads);

    const x = 50 + xPosition * cosOfAngle;

    if (normalisedValue >= 0.5) {
      return {
        left: `calc(${x}% - ${0.5 * labelWidthRem}rem)`,
        top: `calc(${top}% - ${1.1 * fontScale}rem)`,
        text: label.text,
        normalisedValue,
      };
    } else {
      return {
        right: `calc(${x}% - ${0.5 * labelWidthRem}rem)`,
        top: `calc(${top}% - ${1.1 * fontScale}rem)`,
        text: label.text,
        normalisedValue,
      };
    }
  });

  return (
    <Box
      sx={{
        paddingX: `${0.6 * labelWidthRem}rem`,
        marginX: `${0.5 * labelWidthRem}rem`,
        paddingTop: `${fontScale * 0.6}rem`,
        position: "relative",
      }}
    >
      <Gauge {...gaugeProps} />
      {labelPositions.map(({ text, normalisedValue, ...position }, i) => (
        <Box
          sx={{
            position: "absolute",
            width: `${labelWidthRem}rem`,
            ...position,
          }}
          key={i}
        >
          <Typography
            // Adjust alignment by position to account for different text lengths.
            textAlign={
              normalisedValue < 0.33
                ? "right"
                : normalisedValue > 0.66
                ? "left"
                : "center"
            }
            fontWeight={800}
            fontSize={`${fontScale}rem`}
          >
            {text}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default LabelledGauge;
