import React, {
  SetStateAction,
  Dispatch,
  useState,
  PropsWithChildren,
  useEffect,
} from "react";
import {
  FirestoreCollection,
  FirestoreMedicationDocument,
  Medication,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import useAuth from "../auth/useAuth";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
} from "firebase/firestore";

export type MedicationById = {
  [key: string]: Medication;
};
interface MedicationData {
  medicationIds: string[] | undefined;
  medicationById: MedicationById | undefined;
  setMedicationIds: Dispatch<SetStateAction<string[] | undefined>>;
  setMedicationById: Dispatch<SetStateAction<MedicationById | undefined>>;
  resetMedication: () => void;
}

const [useMedicationContext, MedicationContextProvider] =
  Tools.Context.createGenericContext<MedicationData>(__filename);

const MedicationProvider: React.FC<PropsWithChildren> = (props) => {
  const [medicationIds, setMedicationIds] = useState<string[] | undefined>(
    undefined
  );
  const [medicationById, setMedicationById] = useState<
    MedicationById | undefined
  >(undefined);

  const { user, tenantId } = useAuth();

  useEffect(() => {
    const initializeData = async (): Promise<void> => {
      if (user && tenantId) {
        const db = getFirestore();

        const medicationRef = collection(db, FirestoreCollection.Medications);

        const publicMedQuery = query(
          medicationRef,
          where("tenantId", "==", "00000000-0000-0000-0000-000000000000")
        );
        const privateMedQuery = query(
          medicationRef,
          where("userId", "==", user.uid),
          where("tenantId", "==", tenantId)
        );
        await Promise.all([
          getDocs(publicMedQuery),
          getDocs(privateMedQuery),
        ]).then(async (response) => {
          const medications: Medication[] = [];
          response.forEach((r) => {
            r.docs.forEach((doc) => {
              const data = doc.data() as FirestoreMedicationDocument;
              medications.push({ id: doc.id, ...data });
            });
          });

          const medicationIds: string[] = medications.map((m) => {
            return m.id;
          });
          const medicationById: MedicationById = {};

          medications.forEach((m) => {
            medicationById[m.id] = m;
          });

          setMedicationIds(medicationIds);
          setMedicationById(medicationById);
        });
      }
    };
    initializeData();
  }, []);

  const resetMedication = () => {
    setMedicationIds(undefined);
    setMedicationById(undefined);
  };

  return (
    <MedicationContextProvider
      value={{
        medicationIds,
        medicationById,
        setMedicationIds,
        setMedicationById,
        resetMedication,
      }}
    >
      {props.children}
    </MedicationContextProvider>
  );
};

export { useMedicationContext, MedicationProvider };
