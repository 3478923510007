import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import * as Sentry from "@sentry/react";
import { useAnalytics } from "@neurosolutionsgroup/analytics";
import {
  ConfigString,
  WebviewsFeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import { ExternalLink, UniWebViewActions } from "./UniWebViewActions";
import { GuideExternalLink } from "@neurosolutionsgroup/parentguide";
import {
  AppInfo,
  DeviceData,
  Language,
  PriceInfo,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";

interface ParametersData {
  os: "android" | "ios";
  setOS: React.Dispatch<SetStateAction<"android" | "ios">>;
  version: string | undefined;
  setVersion: React.Dispatch<SetStateAction<string | undefined>>;
  buildNumber: string | undefined;
  setBuildNumber: React.Dispatch<SetStateAction<string | undefined>>;
  patch: number | undefined;
  setPatch: React.Dispatch<SetStateAction<number | undefined>>;
  minor: number | undefined;
  setMinor: React.Dispatch<SetStateAction<number | undefined>>;
  major: number | undefined;
  setMajor: React.Dispatch<SetStateAction<number | undefined>>;
  isHalloweenVersion: boolean;
  isWinterVersion: boolean;
  isTest: boolean;
  setIsTest: React.Dispatch<SetStateAction<boolean>>;
  priceInfo?: PriceInfo;
  passSubscriptionInfo: (info: string) => void;
  passAppInfo: (info: AppInfo) => void;
  deviceData: DeviceData | undefined;
}

const [useParametersContext, ParametersContextProvider] =
  Tools.Context.createGenericContext<ParametersData>(__filename);

export const isPriceInfo = (obj: unknown): obj is PriceInfo => {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "monthly" in obj &&
    "annual" in obj
  );
};

export const ParametersProvider: React.FC<PropsWithChildren> = (props) => {
  const [os, setOS] = useState<"android" | "ios">("android");
  const [deviceData, setDeviceData] = useState<DeviceData | undefined>(
    undefined
  );
  const [version, setVersion] = useState<string | undefined>(undefined);
  const [buildNumber, setBuildNumber] = useState<string | undefined>(undefined);
  const [patch, setPatch] = useState<number | undefined>(undefined);
  const [minor, setMinor] = useState<number | undefined>(undefined);
  const [major, setMajor] = useState<number | undefined>(undefined);
  const [isHalloweenVersion, setIsHalloweenVersion] = useState(false);
  const [isWinterVersion, setIsWinterVersion] = useState(false);
  const [isTest, setIsTest] = useState(false);
  const [priceInfo, setPriceInfo] = useState<PriceInfo | undefined>(undefined);

  const { setAppData, setDeviceData: setAnalyticsDeviceData } = useAnalytics();

  const passSubscriptionInfo = (infoJson: string) => {
    try {
      const info = JSON.parse(infoJson);

      if (isPriceInfo(info)) {
        setPriceInfo(info);
      }
    } catch (e) {
      console.error(
        "[passSubscriptionInfo] Error encountered parsing subscription info from Unity.",
        e
      );
    }
  };

  const passAppInfo = (appInfo: AppInfo): void => {
    setVersion(appInfo.version);
    setOS(appInfo.os === "android" ? "android" : "ios");
    setBuildNumber(appInfo.build);
    setDeviceData({
      os: appInfo.os === "android" ? "android" : "ios",
      osVersion: appInfo.osVersion,
      device: appInfo.device,
      model: appInfo.model,
      deviceId: appInfo.deviceId,
    });

    setAppData({
      version: appInfo.version,
      build: appInfo.build,
      webBuild: Tools.Environment.webviewsEnvTools.getBuildNumber(),
    });

    setAnalyticsDeviceData({
      os: appInfo.os,
      osVersion: appInfo.osVersion,
      device: appInfo.device,
      model: appInfo.model,
    });
  };

  useEffect(() => {
    if (version && buildNumber && os) {
      Sentry.setTags({
        version: version,
        build: buildNumber,
        os: os,
      });
    } else {
      const urlString = `uniwebview://${UniWebViewActions.GetAppInfo}`;

      if (
        process.env.NODE_ENV === "development" ||
        Tools.Environment.isDevBuild()
      ) {
        console.log(urlString);
      } else {
        window.location.href = urlString;
      }
    }
  }, [version, buildNumber, os]);

  useEffect(() => {
    const envVersion =
      Tools.Environment.getEnvironmentVariable("REACT_APP_VERSION");

    if (envVersion) {
      setVersion(envVersion);
    }

    const envOs = Tools.Environment.getEnvironmentVariable("REACT_APP_OS");

    if (envOs) {
      setOS(envOs === "android" ? "android" : "ios");
    }

    const envOsVersion = Tools.Environment.getEnvironmentVariable(
      "REACT_APP_OS_VERSION"
    );

    if (envOsVersion) {
      setDeviceData({
        osVersion: envOsVersion,
      });
    }
  }, []);

  useEffect(() => {
    if (version) {
      const _patch = version.split(".")[2];
      const _minor = version.split(".")[1];
      const _major = version.split(".")[0];

      setPatch(parseInt(_patch));
      setMinor(parseInt(_minor));
      setMajor(parseInt(_major));

      setIsHalloweenVersion(
        _major === "1" && !!_minor && ["23"].includes(_minor)
      );

      setIsWinterVersion(
        _major === "1" && !!_minor && ["14", "15", "16"].includes(_minor)
      );
    } else {
      setIsHalloweenVersion(false);
      setIsWinterVersion(false);
    }
  }, [version]);

  return (
    <ParametersContextProvider
      value={{
        os,
        setOS,
        version,
        setVersion,
        buildNumber,
        setBuildNumber,
        isHalloweenVersion,
        isWinterVersion,
        patch,
        setPatch,
        minor,
        setMinor,
        major,
        setMajor,
        isTest,
        setIsTest,
        passAppInfo,
        deviceData,
        priceInfo,
        passSubscriptionInfo,
      }}
    >
      {props.children}
    </ParametersContextProvider>
  );
};

interface useParametersResult extends ParametersData {
  useDevelopmentFunctionality: boolean;
  sendMessageToUnity: (action: string, serializedData?: string) => void;
  linkHandler: (link: ExternalLink | GuideExternalLink, url: string) => void;
  openExternalLink: (link: ExternalLink | GuideExternalLink) => void;
  openExternalLinkGeneric: (url: string) => void;
  openPrivacyPolicy: (language: Language) => void;
  openTerms: (language: Language) => void;
  addLoadingProgress: (percent: number) => void;
}

const useParameters = (): useParametersResult => {
  const {
    os,
    setOS,
    version,
    setVersion,
    buildNumber,
    setBuildNumber,
    patch,
    setPatch,
    minor,
    setMinor,
    major,
    setMajor,
    isHalloweenVersion,
    isWinterVersion,
    isTest,
    setIsTest,
    passAppInfo,
    deviceData,
    priceInfo,
    passSubscriptionInfo,
  } = useParametersContext();

  const { checkFeatureFlagVersion, getRemoteConfigValue } = useRemoteConfig();

  const useDevelopmentFunctionality = useMemo((): boolean => {
    return (
      process.env.NODE_ENV === "development" || Tools.Environment.isDevBuild()
    );
  }, [version]);

  /**
   * A function to send a message to UniWebView. When in development only logs url to console.
   * @param action
   * @param serializedData
   */
  const sendMessageToUnity = (
    action: string,
    serializedData?: string
  ): void => {
    const urlString =
      `uniwebview://${action}` + (serializedData ? `?${serializedData}` : "");

    if (useDevelopmentFunctionality) {
      console.log(urlString);
    } else {
      window.location.href = urlString;
    }
  };

  const openPrivacyPolicy = (language: Language) => {
    linkHandler(
      Tools.Language.languageSwitch<ExternalLink>(language, {
        en: ExternalLink.PrivacyEN,
        fr: ExternalLink.PrivacyFR,
        de: ExternalLink.PrivacyDE,
      }),
      Tools.Language.languageSwitch<string>(language, {
        en: getRemoteConfigValue(ConfigString.PrivacyEN).asString(),
        fr: getRemoteConfigValue(ConfigString.PrivacyFR).asString(),
        de: getRemoteConfigValue(ConfigString.PrivacyDE).asString(),
      })
    );
  };

  const openTerms = (language: Language) => {
    linkHandler(
      Tools.Language.languageSwitch<ExternalLink>(language, {
        en: ExternalLink.TermsEN,
        fr: ExternalLink.TermsFR,
        de: ExternalLink.TermsDE,
      }),
      Tools.Language.languageSwitch<string>(language, {
        en: getRemoteConfigValue(ConfigString.TermsEN).asString(),
        fr: getRemoteConfigValue(ConfigString.TermsFR).asString(),
        de: getRemoteConfigValue(ConfigString.TermsDE).asString(),
      })
    );
  };

  const linkHandler = (link: ExternalLink | GuideExternalLink, url: string) => {
    checkFeatureFlagVersion(WebviewsFeatureFlag.GenericLinks, version)
      ? openExternalLinkGeneric(url)
      : openExternalLink(link);
  };

  const openExternalLink = (link: ExternalLink | GuideExternalLink) => {
    sendMessageToUnity("externallink", `link=${link}`);
  };

  const openExternalLinkGeneric = (url: string) => {
    sendMessageToUnity("externallink", `link=${encodeURIComponent(url)}`);
  };

  const addLoadingProgress = (percent: number) => {
    sendMessageToUnity(
      UniWebViewActions.AddLoadingProgress,
      `percent=${percent}`
    );
  };

  return {
    os,
    setOS,
    version,
    setVersion,
    buildNumber,
    setBuildNumber,
    patch,
    setPatch,
    minor,
    setMinor,
    major,
    setMajor,
    isHalloweenVersion,
    isWinterVersion,
    isTest,
    setIsTest,
    useDevelopmentFunctionality,
    sendMessageToUnity,
    linkHandler,
    openExternalLink,
    openExternalLinkGeneric,
    openPrivacyPolicy,
    openTerms,
    addLoadingProgress,
    passAppInfo,
    deviceData,
    priceInfo,
    passSubscriptionInfo,
  };
};

export default useParameters;
