import React, { useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import useAuth from "common/hooks/auth/useAuth";
import { Loader } from "@neurosolutionsgroup/components";
import useNavigation from "common/hooks/navigation/useNavigation";
import { useAppInitializationContext } from "common/hooks/AppInitializationContext";
import { useAnalytics } from "@neurosolutionsgroup/analytics";
import ParentSectionNavigator from "ParentSectionNavigator";
import { MainPage } from "pages";

const AppNavigator: React.FC = () => {
  const {
    functions: { pageView },
  } = useAnalytics();
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    selectors: { landingPagePath, parentSectionOpen },
    actions: { onParentSectionBack },
  } = useNavigation();

  const { dataInitiated, userInitiated } = useAppInitializationContext();

  useLayoutEffect(() => {
    if (userInitiated) {
      pageView(`webviews${location.pathname}`);
    }
  }, [location.pathname, userInitiated]);

  useEffect(() => {
    if (!user) {
      if (location.pathname !== "/" && location.pathname !== "/intro") {
        navigate("/login");
      } else {
        navigate(landingPagePath);
      }
    }
  }, [user]);

  // TFS-168 (TBF-539 before) - Idle timeout will not be implemented yet.
  /* const onIdle = () => {
    onParentSectionBack();
  };

  const redirectionTime = parseInt(
    process.env.REACT_APP_FEATURE_VARIABLE_REDIRECTION_TIMER ?? "60000"
  );

  const { start } = useIdleTimer({
    onIdle,
    timeout: redirectionTime,
    throttle: 500,
    startOnMount: false,
    startManually: true,
    immediateEvents: ["visibilitychange"],
    stopOnIdle: true,
  });

  useEffect(() => {
    if (parentSectionOpen) {
      start();
    }
  }, [parentSectionOpen]); */

  return !(userInitiated && dataInitiated) ? (
    <Loader />
  ) : parentSectionOpen ? (
    <ParentSectionNavigator onBack={() => onParentSectionBack()} />
  ) : (
    <MainPage />
  );
};

export default AppNavigator;
