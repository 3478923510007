export enum CoachNotificationID {
  DiagnosisGuide = "DiagnosisGuide",
  DifficultTaskSucceeded = "DifficultTaskSucceeded",
  EveningScreenTask = "EveningScreenTask",
  FirstObservationsComplete = "FirstObservationsComplete",
  FirstRoutineDeactivated = "FirstRoutineDeactivated",
  FirstTaskCreated = "FirstTaskCreated",
  // Removed for now as we are no longer refactoring this code.
  /* FollowUpDataChangeWarning = "FollowUpDataChangeWarning",
  FollowUpDataExport = "FollowUpDataExport", */
  HomeworkTaskAssigned = "HomeworkTaskAssigned",
  HomeworkTaskFailed = "HomeworkTaskFailed",
  //would be nice to change but some user already have this in there data
  KairosUsage1 = "KairosUsage1",
  KairosUsage2 = "KairosUsage2",
  KairosUsage3 = "KairosUsage3",
  KairosUsage4 = "KairosUsage4",
  MedicationAddedRoutine = "MedicationAddedRoutine",
  MorningTaskFailed = "MorningTaskFailed",
  PositiveReinforcement = "PositiveReinforcement",
  ObservationsAvailable = "ObservationsAvailable",
  SleepTaskFailed = "SleepTaskFailed",
  TaskNameEdited = "TaskNameEdited",
  TenthRoutineValidated = "TenthRoutineValidated",
  ThirdRoutineActivated = "ThirdRoutineActivated",
  ValidationReminder = "ValidationReminder",
}

export enum CoachNotificationFrequency {
  Once,
  Weekly,
  Monthly,
  EveryTime,
}

export enum CoachNotificationType {
  Information,
  PositiveReinforcement,
  FullScreen,
  Dialog,
}

export type CoachNotification = {
  id: CoachNotificationID;
  priority: number;
  frequency: CoachNotificationFrequency;
  type: CoachNotificationType;
  customAction?: boolean;
  secondaryAction?: boolean;
};

type CoachNotificationDefinitions = {
  [key in CoachNotificationID]: CoachNotification;
};

export const coachNotificationDefinitions: CoachNotificationDefinitions = {
  [CoachNotificationID.DiagnosisGuide]: {
    id: CoachNotificationID.DiagnosisGuide,
    priority: 4,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.ObservationsAvailable]: {
    id: CoachNotificationID.ObservationsAvailable,
    priority: 2,
    frequency: CoachNotificationFrequency.Weekly,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.DifficultTaskSucceeded]: {
    id: CoachNotificationID.DifficultTaskSucceeded,
    priority: 6,
    frequency: CoachNotificationFrequency.EveryTime,
    type: CoachNotificationType.PositiveReinforcement,
  },
  [CoachNotificationID.EveningScreenTask]: {
    id: CoachNotificationID.EveningScreenTask,
    priority: 4,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.FirstRoutineDeactivated]: {
    id: CoachNotificationID.FirstRoutineDeactivated,
    priority: 4,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.FirstTaskCreated]: {
    id: CoachNotificationID.FirstTaskCreated,
    priority: 2,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  // Removed for now as we are no longer refactoring this code.
  /* [CoachNotificationID.FollowUpDataChangeWarning]: {
    id: CoachNotificationID.FollowUpDataChangeWarning,
    priority: 1,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Dialog,
  },
  [CoachNotificationID.FollowUpDataExport]: {
    id: CoachNotificationID.FollowUpDataExport,
    priority: 1,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Dialog,
    secondaryAction: true,
  }, */
  [CoachNotificationID.HomeworkTaskAssigned]: {
    id: CoachNotificationID.HomeworkTaskAssigned,
    priority: 3,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.HomeworkTaskFailed]: {
    id: CoachNotificationID.HomeworkTaskFailed,
    priority: 3,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.FirstObservationsComplete]: {
    id: CoachNotificationID.FirstObservationsComplete,
    priority: 4,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
    customAction: true,
  },
  [CoachNotificationID.KairosUsage1]: {
    id: CoachNotificationID.KairosUsage1,
    priority: 5,
    frequency: CoachNotificationFrequency.Weekly,
    type: CoachNotificationType.PositiveReinforcement,
  },
  [CoachNotificationID.KairosUsage2]: {
    id: CoachNotificationID.KairosUsage2,
    priority: 5,
    frequency: CoachNotificationFrequency.Weekly,
    type: CoachNotificationType.PositiveReinforcement,
  },
  [CoachNotificationID.KairosUsage3]: {
    id: CoachNotificationID.KairosUsage3,
    priority: 5,
    frequency: CoachNotificationFrequency.Weekly,
    type: CoachNotificationType.PositiveReinforcement,
  },
  [CoachNotificationID.KairosUsage4]: {
    id: CoachNotificationID.KairosUsage4,
    priority: 5,
    frequency: CoachNotificationFrequency.Weekly,
    type: CoachNotificationType.PositiveReinforcement,
  },
  [CoachNotificationID.MedicationAddedRoutine]: {
    id: CoachNotificationID.MedicationAddedRoutine,
    priority: 2,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.MorningTaskFailed]: {
    id: CoachNotificationID.MorningTaskFailed,
    priority: 3,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.PositiveReinforcement]: {
    id: CoachNotificationID.PositiveReinforcement,
    priority: 3,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
    secondaryAction: true,
  },
  [CoachNotificationID.SleepTaskFailed]: {
    id: CoachNotificationID.SleepTaskFailed,
    priority: 4,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.TaskNameEdited]: {
    id: CoachNotificationID.TaskNameEdited,
    priority: 2,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.TenthRoutineValidated]: {
    id: CoachNotificationID.TenthRoutineValidated,
    priority: 4,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
    customAction: true,
  },
  [CoachNotificationID.ThirdRoutineActivated]: {
    id: CoachNotificationID.ThirdRoutineActivated,
    priority: 2,
    frequency: CoachNotificationFrequency.Once,
    type: CoachNotificationType.Information,
  },
  [CoachNotificationID.ValidationReminder]: {
    id: CoachNotificationID.ValidationReminder,
    priority: 1,
    frequency: CoachNotificationFrequency.Weekly,
    type: CoachNotificationType.FullScreen,
  },
};
