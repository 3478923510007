import { Box, Button, Typography, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { DoseUnits } from "@neurosolutionsgroup/models";
import { TextInput } from "common/Components";
import React from "react";

interface CustomDosagePickerProps {
  unit: DoseUnits;
  doseValue: number;
  updateDoseValue: (dose: number) => void;
  setOpen: (value: boolean) => void;
}

const CustomDosagePicker: React.FC<CustomDosagePickerProps> = ({
  unit,
  doseValue,
  updateDoseValue,
  setOpen,
}) => {
  const { palette } = useTheme();
  return (
    <Box display="flex">
      <Box pr={0.5} sx={{ width: "50%" }}>
        <Button
          endIcon={
            <Icons.ArrowIcon
              arrowDirection="down"
              color={palette.secondary.main}
            />
          }
          onClick={() => {
            setOpen(true);
          }}
          sx={{ width: "100%", borderRadius: "0.75em" }}
        >
          <Typography variant="h3" sx={{ witdh: "100%" }}>
            {unit}
          </Typography>
        </Button>
      </Box>
      <Box
        className="custom-med--dose-input__container"
        pl={0.5}
        sx={{ width: "50%" }}
      >
        <TextInput
          placeholder="0.00"
          value={doseValue ? doseValue.toString() : 0}
          onChange={(e) => updateDoseValue(parseInt(e.currentTarget.value))}
          inputProps={{ pattern: "d*" }}
        />
      </Box>
    </Box>
  );
};

export default CustomDosagePicker;
