import { useAnalytics } from "@neurosolutionsgroup/analytics";
import useChildren from "common/hooks/children/useChildren";
import useRoutines from "common/hooks/routines/useRoutines";
import { useEffect } from "react";
import AnalyticsLogic from "./AnalyticsLogic";

/**
 * A component who's pupose is to listen to states from contexts and send analytics events accordingly.
 * Should be placed immediately beneath all state providers.
 */
const AnalyticsStateListener = (): null => {
  const {
    selectors: { tasksToValidate },
  } = useChildren();
  const {
    selectors: { routinesById },
  } = useRoutines();
  const {
    handleEvent,
    functions: { setProfileProperties },
  } = useAnalytics();

  useEffect(() => {
    const event = AnalyticsLogic.allTodaysValidationsDone(
      tasksToValidate,
      routinesById
    );

    if (event) {
      handleEvent(event);
    }
  }, [tasksToValidate]);

  useEffect(() => {
    setProfileProperties(
      AnalyticsLogic.calculateActiveRoutinesByDay(routinesById)
    );
  }, [routinesById]);

  return null;
};

export default AnalyticsStateListener;
