export enum FirestoreCollection {
  ActivationCodes = "ActivationCodes",
  ActivityLogs = "activityLogs",
  Challenges = "challenges",
  ChallengeRewards = "challengeRewards",
  ChallengeRewardsArchive = "challengeRewardsArchive",
  ChatbotConversations = "chatbotConversations",
  ChatbotMessages = "chatbotMessages",
  ChatbotResponses = "chatbotResponses",
  CoachMessages = "coachMessages",
  FollowUps = "followUps",
  FollowedSideEffects = "followedSideEffects",
  FTUEMessages = "ftueMessages",
  GamerChildren = "gamerChildren",
  GroupAccessLists = "groupAccessLists",
  Invoices = "invoices",
  Mail = "mail",
  MedicalChildren = "medicalChildren",
  Medications = "medications",
  Notes = "medicalNotes",
  NotesSummary = "medicalNotesSummaries",
  ParentInvites = "parentInvites",
  PortalOrganisations = "portalOrganisations",
  Prescriptions = "prescriptions",
  RecordSummaries = "recordSummaries",
  Records = "records",
  RoutineRewards = "routineRewards",
  RoutineRewardsArchive = "routineRewardsArchive",
  Routines = "routines",
  Sessions = "sessions",
  SideEffect = "sideEffects",
  TaskHistoriesSummary = "taskHistoriesSummaries",
  TaskHistoriesSummaryTask = "taskSummaries",
  TaskHistory = "taskHistories",
  TaskHistoryArchives = "taskHistoryArchives",
  TaskHistoryBackUps = "taskHistoryBackups",
  Tasks = "tasks",
  Tenant = "tenants",
  UserDeleteInfo = "userDeleteInfo",
  UserSubscriptions = "userSubscriptions",
  Users = "users",
  ValidationEmptyStateAdviceMessages = "validationEmptyStateAdviceMessages",
}
