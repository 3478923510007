import { Box, CircularProgress } from "@mui/material";
import {
  SideEffect,
  Record as MedRecord,
  TaskHistory,
  Challenge,
  FirestoreChallenge,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { Page } from "common/Components";
import useChildren from "common/hooks/children/useChildren";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useTasks from "common/hooks/routines/useTasks";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import { getUnixTime, startOfDay, sub } from "date-fns";
import { groupBy } from "lodash";
import React, { useEffect, useMemo, useRef, useState, version } from "react";
import { useTranslation } from "react-i18next";
import { v4 } from "uuid";
import useLanguage from "common/hooks/Parameters/useLanguage";
import {
  AllObservationsValidated,
  ObservationOpened,
  ObservationValidated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { useCoach } from "common/hooks/messaging/Coach";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import NotificationOptInPrompt from "pages/Settings/NotificationSettings/NotificationOptInPrompt";
import useNotifications from "common/hooks/notifications/useNotifications";
import PullToRefresh from "pulltorefreshjs";
import ReactDOMServer from "react-dom/server";
import CategoryFilter, { FilterValue } from "./Components/CategoryFilter";
import ValidationEmptyState from "./ValidationEmptyState";
import RoutineSection from "./Components/Routine/RoutineSection";
import ObservationSection from "./Components/Observation/ObservationSection";
import ObservationsPage from "./Components/Observation/ObservationsPage";
import ChallengeSection from "./Components/Challenge/ChallengeSection";
import useChallenges from "common/hooks/challenges/useChallenges";
import {
  FeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import ChallengeFinishedDialog from "./Components/Challenge/ChallengeFinishedDialog";
import { Dialogs } from "@neurosolutionsgroup/components";
import EndOfChallengeAsset from "assets/challenge";

export interface ValidationTaskHistory extends Omit<TaskHistory, "status"> {
  status: boolean | null | undefined;
  childStatus: boolean | null;
}

export interface TasksToValidateByDateAndRoutine {
  date: string;
  routineId: string;
  history: ValidationTaskHistory[];
}

export type ValidationStorage = Record<string, boolean | null | undefined>;

const Validation: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { dateLocale } = useLanguage();
  const { handleEvent } = useAnalytics();
  const { handleUnknownError } = useErrorsContext();
  const { onObservationsComplete } = useCoach();

  const { checkFeatureFlagVersion } = useRemoteConfig();

  const [filter, setFilter] = useState<FilterValue[]>([FilterValue.All]);
  const [selectedSideEffect, setSelectedSideEffect] = useState<SideEffect>();
  const [showNotificationPrompt, setShowNotificationPrompt] =
    useState<boolean>(false);
  const [showFinishedDialog, setShowFinishedDialog] = useState<boolean>(false);
  const [finishedChallenge, setFinishedChallenge] =
    useState<FirestoreChallenge | null>(null);
  const [finishedChallengeStatus, setFinishedChallengeStatus] = useState<
    "failed" | "partial" | "successful" | "unfinished"
  >("unfinished");

  const {
    selectors: { selectedChild, tasksToValidate, childrenById },
    actions: { refreshChildren },
  } = useChildren();

  const {
    selectors: { taskRoutineMap },
  } = useTasks();

  const {
    selectors: { challengeReadyToValidateForSelectedChild },
  } = useChallenges();

  const {
    selectors: { getActivePrescriptionsForSelectedChild, getObservationsToDo },
    actions: { createRecords },
  } = useFollowUp();

  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const { optInLastSeen } = useNotifications();

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    PullToRefresh.init({
      mainElement: "#validations-page",
      triggerElement: "#validations-page",
      onRefresh: refreshChildren,
      distMax: 100,
      distReload: 100,
      iconRefreshing: " ",
      iconArrow: " ",
      instructionsPullToRefresh: " ",
      instructionsReleaseToRefresh: " ",
      instructionsRefreshing: ReactDOMServer.renderToString(
        <CircularProgress
          size="1.5rem"
          style={{
            color: "#fff",
          }}
        />
      ),
      shouldPullToRefresh: () => scrollRef.current?.scrollTop === 0,
    });

    return () => {
      PullToRefresh.destroyAll();
    };
  }, []);

  const tasksToValidateByRoutine =
    useMemo((): TasksToValidateByDateAndRoutine[] => {
      if (selectedChild && tasksToValidate[selectedChild]) {
        const ungroupedHistory = tasksToValidate[selectedChild].map((h) => ({
          ...h,
          status: h.status ?? undefined,
        }));

        const dateGroupedHistory = groupBy(ungroupedHistory, (h) =>
          getUnixTime(startOfDay(h.dueTime * 1000))
        );

        const groupedHistory: TasksToValidateByDateAndRoutine[] = [];

        Object.keys(dateGroupedHistory).forEach((date) => {
          const routineGroupedHistory = groupBy(
            dateGroupedHistory[date],
            (h) => taskRoutineMap[h.task]
          );

          Object.keys(routineGroupedHistory).forEach((rid) => {
            groupedHistory.push({
              date,
              routineId: rid,
              history: routineGroupedHistory[rid],
            });
          });
        });

        groupedHistory.sort(
          (a, b) =>
            (b.history[0] ? b.history[0].dueTime : 0) -
            (a.history[0] ? a.history[0].dueTime : 0)
        );

        return groupedHistory;
      } else {
        return [];
      }
    }, [selectedChild, tasksToValidate]);

  const [localValidationStatus, setLocalValidationStatus] =
    useState<ValidationStorage>({});

  const routinesValidationCount = useMemo((): number => {
    return selectedChild && tasksToValidate[selectedChild]
      ? tasksToValidate[selectedChild].length
      : 0;
  }, [selectedChild, tasksToValidate]);

  const observationsValidationCount = useMemo((): number => {
    return getObservationsToDo().length;
  }, [getObservationsToDo]);

  const challengesValidationCount = useMemo((): number => {
    return challengeReadyToValidateForSelectedChild.length;
  }, [challengeReadyToValidateForSelectedChild]);

  const totalValidationCount = useMemo((): number => {
    return (
      routinesValidationCount +
      observationsValidationCount +
      challengesValidationCount
    );
  }, [
    routinesValidationCount,
    observationsValidationCount,
    challengesValidationCount,
  ]);

  const shouldShowChallenges = useMemo((): boolean => {
    // flag check
    if (!checkFeatureFlagVersion(FeatureFlag.Challenges, version)) {
      return false;
    }
    // real check
    return (
      filter.includes(FilterValue.Challenges) ||
      (filter.includes(FilterValue.All) && challengesValidationCount > 0)
    );
  }, [filter, totalValidationCount]);

  const shouldShowObservations = useMemo((): boolean => {
    return (
      filter.includes(FilterValue.Observations) ||
      (filter.includes(FilterValue.All) && totalValidationCount > 0)
    );
  }, [filter, totalValidationCount]);
  const shouldShowRoutines = useMemo((): boolean => {
    return (
      filter.includes(FilterValue.Routines) ||
      (filter.includes(FilterValue.All) && totalValidationCount > 0)
    );
  }, [filter, totalValidationCount]);
  const shouldShowGlobalEmptyState = useMemo((): boolean => {
    return filter.includes(FilterValue.All) && totalValidationCount === 0;
  }, [filter, totalValidationCount]);

  const onObservationClick = (sideEffect: SideEffect): void => {
    const event: ObservationOpened = {
      name: "Observation Opened",
      eventProperties: {
        "Kid ID": selectedChild ?? "",
        "Side Effect Tracked": sideEffect.name.en,
      },
    };

    handleEvent(event);

    setSelectedSideEffect(sideEffect);
  };

  const onObservationSubmit = async (
    frequency?: number,
    intensity?: number
  ): Promise<void> => {
    const lastObservation = observationsValidationCount === 1;

    const records: MedRecord[] = [];

    if (selectedSideEffect && selectedChild) {
      const frequencyQuestionId = selectedSideEffect.questions.find(
        (q) => q.answerType === "frequency"
      )?.questionId;
      const intensityQuestionId = selectedSideEffect.questions.find(
        (q) => q.answerType === "intensity"
      )?.questionId;

      if (frequencyQuestionId) {
        records.push({
          recordId: v4(),
          answer: frequency ?? null,
          weekStartDate: Tools.Time.Strings.getDateStringFromDate(
            sub(Tools.Time.Dates.getPreviousMonday(), { days: 7 })
          ),
          questionId: frequencyQuestionId,
          sideEffectId: selectedSideEffect.id,
          prescriptionIds: getActivePrescriptionsForSelectedChild().map(
            (p) => p.prescriptionId
          ),
        });
      }
      if (intensityQuestionId) {
        records.push({
          recordId: v4(),
          answer: intensity ?? null,
          weekStartDate: Tools.Time.Strings.getDateStringFromDate(
            sub(Tools.Time.Dates.getPreviousMonday(), { days: 7 })
          ),
          questionId: intensityQuestionId,
          sideEffectId: selectedSideEffect.id,
          prescriptionIds: getActivePrescriptionsForSelectedChild().map(
            (p) => p.prescriptionId
          ),
        });
      }

      try {
        await createRecords(selectedChild, records);
      } catch (err: unknown) {
        handleUnknownError(err);
        setSelectedSideEffect(undefined);
        return;
      }

      const event: ObservationValidated = {
        name: "Observation Validated",
        eventProperties: {
          "Kid ID": selectedChild,
          "Side Effect Tracked": selectedSideEffect.name.en,
          "Intensity": intensity
            ? t(
                "dashboard.med.graph.intensity.yAxis.ticks." +
                  intensity.toFixed(),
                { lng: "en" }
              )
            : null,
          "Number of days": frequency ?? null,
          "Skipped": !intensity || !frequency,
        },
      };

      handleEvent(event);

      if (lastObservation) {
        onObservationsComplete();

        const event: AllObservationsValidated = {
          name: "All Observations Validated",
          eventProperties: {
            "Kid ID": selectedChild,
          },
        };

        handleEvent(event);
      }

      setSelectedSideEffect(undefined);
    }
  };

  const onRoutineValidated = () => {
    if (!optInLastSeen) {
      setShowNotificationPrompt(true);
    }
  };

  const onChallengeValidationComplete = (challenge: FirestoreChallenge) => {
    setFinishedChallenge(challenge);
    setShowFinishedDialog(true);
  };
  const onChallengeV2ValidationComplete = (
    challenge: FirestoreChallenge,
    status: "failed" | "partial" | "successful" | "unfinished"
  ) => {
    setFinishedChallenge(challenge);
    setFinishedChallengeStatus(status);
    setShowFinishedDialog(true);
  };

  return (
    <Page className="home-page" id="validations-page" ref={scrollRef}>
      {showNotificationPrompt ? (
        <NotificationOptInPrompt
          open={showNotificationPrompt}
          onClose={() => setShowNotificationPrompt(false)}
        />
      ) : null}

      <Box mb={4} display="flex" flexDirection="column" flex={1}>
        <CategoryFilter
          filter={filter}
          setFilter={setFilter}
          observationsToDo={observationsValidationCount}
          validationsToDo={routinesValidationCount}
          challengesToDo={challengesValidationCount}
        />

        <FTUEFlowDefinitions.ValidationFTUEFlow.Hints.ValidationEnd />

        <Box
          className="home-page__validation-container"
          pb={6}
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          {shouldShowChallenges && (
            <ChallengeSection
              showTitle={filter.includes(FilterValue.All)}
              challenges={challengeReadyToValidateForSelectedChild}
              onChallengeValidationComplete={onChallengeValidationComplete}
              onChallengeV2ValidationComplete={onChallengeV2ValidationComplete}
            />
          )}
          {shouldShowRoutines && (
            <Box mt={2}>
              <RoutineSection
                showTitle={filter.includes(FilterValue.All)}
                validationCount={routinesValidationCount}
                tasksToValidateByRoutine={tasksToValidateByRoutine}
                localValidationStatus={localValidationStatus}
                setLocalValidationStatus={setLocalValidationStatus}
                onRoutineValidated={onRoutineValidated}
              />
            </Box>
          )}
          {shouldShowObservations && (
            <Box mt={2}>
              <ObservationSection
                showTitle={filter.includes(FilterValue.All)}
                observationsCount={observationsValidationCount}
                observationToDo={getObservationsToDo()}
                dateLocale={dateLocale}
                sideEffectById={sideEffectById}
                onObservationSubmit={onObservationSubmit}
                language={language}
                onObservationClick={onObservationClick}
              />
            </Box>
          )}

          <ObservationsPage
            sideEffect={selectedSideEffect}
            onClose={() => setSelectedSideEffect(undefined)}
            onSubmit={onObservationSubmit}
          />
          {shouldShowGlobalEmptyState && (
            <ValidationEmptyState totalValidationtoDo={totalValidationCount} />
          )}
          {selectedChild && finishedChallenge !== null && showFinishedDialog ? (
            Tools.Data.Challenges.V2.isChallengeV2(finishedChallenge) ? (
              <Dialogs.ChallengeEndDialog
                open={finishedChallengeStatus !== "unfinished"}
                onClose={() => setShowFinishedDialog(false)}
                childName={childrenById[selectedChild].name}
                rewardText={finishedChallenge.reward.text}
                failedChallengeimgSrc={EndOfChallengeAsset.Failed}
                partialChallengeimgSrc={EndOfChallengeAsset.Partial}
                successfullChallengeimgSrc={EndOfChallengeAsset.Successful}
                onClick={() => setShowFinishedDialog(false)}
                dialogState={finishedChallengeStatus}
              />
            ) : (
              <ChallengeFinishedDialog
                challenge={finishedChallenge as Challenge}
                onClose={() => setShowFinishedDialog(false)}
                open={showFinishedDialog}
              />
            )
          ) : null}
        </Box>
      </Box>
    </Page>
  );
};

export default Validation;
