export enum FirstRoutineProgress {
  None = "none",
  Later = "FTUE_FirstRoutine_Later",
  Intro = "FTUE_FirstRoutine_Intro",
  TemplateChosen = "FTUE_FirstRoutine_Template",
  TaskOrder = "FTUE_FirstRoutine_TaskOrder",
  AddTask = "FTUE_FirstRoutine_AddTask",
  TaskIcon = "FTUE_FirstRoutine_TaskIcon",
  TaskComplete = "FTUE_FirstRoutine_TaskComplete",
  RoutineComplete = "FTUE_FirstRoutine_RoutineComplete",
  RoutineConfirmed = "FTUE_FirstRoutine_RoutineConfirmed",
  RoutineDisplay = "FTUE_FirstRoutine_RoutineDisplay",
  RoutineFTUEFinished = "FTUE_FirstRoutine_Finished",
}

export const FIRST_ROUTINE_PROGRESS_TOTAL = 4;
