import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import {
  BackgroundHexagons,
  HexagonPlacement,
} from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";

interface ParentSectionBoxProps extends PropsWithChildren {
  safeAreas: SafeAreas;
}

const ParentSectionBox = ({
  safeAreas,
  ...props
}: ParentSectionBoxProps): JSX.Element => {
  const hexagons: HexagonPlacement[] = [
    { size: 100, rotation: -25, top: -40, left: 30 },
    { size: 100, rotation: -25, top: -50, right: -50 },
    { size: 60, rotation: 15, top: "65%", left: -20 },
    { size: 120, rotation: 30, bottom: -40, right: -40 },
  ];

  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: "66vh",
        paddingTop: safeAreas.top,
        backgroundColor: theme.palette.secondary.main,
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      })}
    >
      <BackgroundHexagons hexagons={hexagons} />
      {props.children}
    </Box>
  );
};

export default ParentSectionBox;
