import FTUEValidationScreen from "./FullScreen";
import ValidationFTUEHints from "./Hints";
import FTUEValidationLogic from "./ValidationLogic";
import {
  VALIDATION_PROGRESS_TOTAL,
  ValidationProgress,
} from "./ValidationProgress";

export { VALIDATION_PROGRESS_TOTAL, ValidationProgress };

const ValidationFTUEFlow = {
  Hints: ValidationFTUEHints,
  Logic: FTUEValidationLogic,
  Screen: FTUEValidationScreen,
};

export default ValidationFTUEFlow;
