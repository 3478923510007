import { Grid, Typography } from "@mui/material";
import { Tools } from "@neurosolutionsgroup/tools";
import MiscAssets from "assets/misc";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React from "react";
import { useTranslation } from "react-i18next";

const IntroWelcome: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      width="100%"
      height="100%"
      p={1}
      flexWrap="nowrap"
    >
      <Grid
        item
        flex="1 1 auto"
        sx={{
          img: {
            height: 0,
            minHeight: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            objectFit: "contain",
          },
        }}
        display="flex"
        justifyContent="center"
        p={1}
      >
        <img
          src={Tools.Language.languageSwitch(language, {
            en: MiscAssets.IntroWelcomeEN,
            fr: MiscAssets.IntroWelcomeFR,
          })}
          alt={Tools.Environment.getProductName()}
        />
      </Grid>
      <Grid item>
        <Typography variant="h4" color="#fff" textAlign="center">
          {t("navigation.intro.welcome.title")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="#fff" textAlign="center">
          {t("navigation.intro.welcome.text")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default IntroWelcome;
