import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { PageSection } from "common/Components/PageSection";
import { TaskButton } from "common/Components";
import NotificationSettingsPrompt from "./NotificationSettingPrompt";

export const NotificationSettings: React.FC = () => {
  const { t } = useTranslation();

  const [promptOpen, setPromptOpen] = useState<boolean>(false);

  const goToNotificationSettings = () => setPromptOpen(true);

  return (
    <PageSection title={t("settings.notifications")} showBottomBorder>
      <TaskButton onClick={goToNotificationSettings}>
        {t("settings.manageNotifications")}
      </TaskButton>
      <NotificationSettingsPrompt
        open={promptOpen}
        onClose={() => setPromptOpen(false)}
      />
    </PageSection>
  );
};
