import { Drawer } from "common/Components";
import React from "react";
import { DrawerProps } from "common/Components/Drawer/Drawer";
import { useTranslation } from "react-i18next";
import { Box, Button, Grid, Typography } from "@mui/material";
import CoachAssets from "assets/coach";
import { CoachNotificationType } from "./CoachNotifications";
import { Icons } from "@neurosolutionsgroup/components";

interface CoachDrawerProps extends DrawerProps {
  onConfirm: VoidFunction;
  coachTitle: JSX.Element | null;
  type: CoachNotificationType;
  action?: string;
  showInfoIcon?: boolean;
  secondaryActionText?: string;
  onSecondaryActionClicked?: VoidFunction;
}

const CoachDrawer: React.FC<CoachDrawerProps> = ({
  onConfirm,
  coachTitle,
  children,
  type,
  action,
  secondaryActionText,
  onSecondaryActionClicked,
  showInfoIcon = true,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Drawer {...props} open={true} onClose={onConfirm}>
      <Box p={1}>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid
            item
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            {showInfoIcon ? (
              <Box
                sx={{
                  svg: {
                    width: "3em",
                    height: "3em",
                  },
                }}
              >
                <Icons.InformationIcon variant="outline" />
              </Box>
            ) : null}
            <Typography
              textAlign="center"
              variant="h4"
              sx={(theme) => ({
                marginX: theme.spacing(1),
              })}
            >
              {coachTitle}
            </Typography>
            {showInfoIcon ? <Box visibility="hidden" width="3em"></Box> : null}
          </Grid>
          <Grid
            item
            my="auto"
            sx={(theme) => ({
              img: {
                width: "auto",
                maxWidth: "300px",
                maxHeight: "150px",
                marginBottom: theme.spacing(1),
              },
            })}
          >
            <img
              src={
                type === CoachNotificationType.Information
                  ? CoachAssets.CoachDynamic
                  : CoachAssets.CoachCategory4
              }
              alt=""
            />
          </Grid>

          <Grid item>{children}</Grid>
          <Grid
            item
            container
            direction="row"
            alignItems="center"
            justifyContent="space-around"
          >
            {secondaryActionText ? (
              <Button
                onClick={onSecondaryActionClicked}
                color="secondary"
                variant="outlined"
              >
                {t(secondaryActionText)}
              </Button>
            ) : null}
            <Button
              onClick={onConfirm}
              color="secondary"
              variant="contained"
              data-cy="coach-understood"
            >
              {t(
                action ?? type === CoachNotificationType.Information
                  ? "general.understood"
                  : "general.actions.continuer"
              )}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default CoachDrawer;
