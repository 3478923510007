import { Box, Typography } from "@mui/material";
import ChildIcons from "assets/child-icons";
import React from "react";

interface ChildDisplayProps {
  icon: number;
  name: string;
}

const ChildDisplay = ({ icon, name }: ChildDisplayProps): JSX.Element => {
  return (
    <Box
      px={1}
      sx={(theme) => ({
        height: "3em",
        boxSizing: "border-box",
        borderRadius: "1.5em",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: theme.palette.secondary.main,
        display: "flex",
        alignItems: "center",
        img: {
          height: "2em",
          marginRight: "0.5em",
        },
      })}
    >
      <img src={ChildIcons[icon]} alt={`Child Icon ${icon}`} />
      <Typography fontWeight="bold" fontSize="0.9rem" whiteSpace="nowrap">
        {name}
      </Typography>
    </Box>
  );
};

export default ChildDisplay;
