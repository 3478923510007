import { RoutineIconsDefinition } from "@neurosolutionsgroup/models";
import RoutineIcon0 from "./routine_icon_0.png";
import RoutineIcon1 from "./routine_icon_1.png";
import RoutineIcon2 from "./routine_icon_2.png";
import RoutineIcon3 from "./routine_icon_3.png";
import RoutineIcon4 from "./routine_icon_4.png";

const RoutineTemplateIcons: RoutineIconsDefinition = {
  0: RoutineIcon0,
  1: RoutineIcon1,
  2: RoutineIcon2,
  3: RoutineIcon3,
  4: RoutineIcon4,
};

export default RoutineTemplateIcons;
