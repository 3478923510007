import { Dispatch } from "react";
import { HookResult } from "../HookResult";
import { useNavigatonContext } from "./NavigationContext";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import useParameters from "../Parameters/useParameters";

export interface useNavigationSelectors {
  landingPagePath: string;
  menuPageOpen: boolean;
  parentSectionOpen: boolean;
  shorterFtue: boolean;
  templateRedirect?: string;
}
export interface useNavigationActions {
  setMenuPageOpen: Dispatch<boolean>;
  setParentSectionOpen: Dispatch<boolean>;
  onParentSectionBack: VoidFunction;
  setTemplateRedirect: Dispatch<string | undefined>;
}

const useNavigation = (): HookResult<
  useNavigationSelectors,
  useNavigationActions
> => {
  const {
    landingPagePath,
    menuPageOpen,
    setMenuPageOpen,
    parentSectionOpen,
    setParentSectionOpen,
    onParentSectionBack,
    templateRedirect,
    setTemplateRedirect,
  } = useNavigatonContext();

  const { version } = useParameters();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  const shorterFtue = checkFeatureFlagVersion(
    WebviewsFeatureFlag.ShorterFTUE,
    version
  );

  return {
    selectors: {
      landingPagePath,
      menuPageOpen,
      parentSectionOpen,
      shorterFtue,
      templateRedirect,
    },
    actions: {
      setMenuPageOpen,
      setParentSectionOpen,
      onParentSectionBack,
      setTemplateRedirect,
    },
  };
};

export default useNavigation;
