import { Box } from "@mui/material";
import { Hexagon } from "../Assets";

export interface HexagonPlacement {
  size: number;
  rotation: number;
  top?: number | string;
  left?: number | string;
  right?: number | string;
  bottom?: number | string;
}

interface BackgroundHexagonsProps {
  hexagons?: HexagonPlacement[];
  zIndex?: number;
}

const defaultHexagons: HexagonPlacement[] = [
  { size: 70, rotation: 35, top: -30, right: 30 },
  { size: 40, rotation: 20, top: "10%", right: "35%" },
  { size: 150, rotation: 10, top: "15%", left: -30 },
  { size: 70, rotation: 0, top: "30%", right: -5 },
  { size: 40, rotation: 15, top: "40%", right: "35%" },
  { size: 70, rotation: 30, top: "50%", left: "30%" },
  { size: 80, rotation: 10, bottom: -30, left: -20 },
  { size: 70, rotation: 20, bottom: "25%", right: "10%" },
  { size: 90, rotation: 30, bottom: "15%", left: -20 },
  { size: 150, rotation: 10, bottom: -50, right: 20 },
];

const BackgroundHexagons = ({
  hexagons = defaultHexagons,
  zIndex = 0,
}: BackgroundHexagonsProps): JSX.Element => {
  return (
    <>
      {hexagons.map(({ size, rotation, ...position }, i) => (
        <Box
          key={i}
          sx={{
            position: "absolute",
            zIndex,
            ...position,
          }}
        >
          <Hexagon color="white" size={size} rotation={rotation} />
        </Box>
      ))}
    </>
  );
};

export default BackgroundHexagons;
