import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import {
  DateTextInput,
  InformationButton,
  TextInput,
} from "@neurosolutionsgroup/components";
import { Trans, useTranslation } from "react-i18next";
import ChildIconPicker from "./Components/ChildIconPicker";
import GenderPicker from "./Components/GenderSelection";
import ChildAgeWarning from "./Components/ChildAgeWarning";
import {
  ChildIconsDefintion,
  ChildSecurityOption,
  Gender,
} from "@neurosolutionsgroup/models";
import ChildSecuritySelection from "./Components/ChildSecuritySelection";

export interface AddChildGenericInfoProps {
  name: string;
  setName: (value: string) => void;
  icon: number | null;
  setIcon: (value: number | null) => void;
  gender: Gender | null | undefined;
  setGender: (value: Gender | null) => void;
  date: number | null;
  setDate: (value: number | null) => void;
  showDobWarning: boolean;
  childSecurity: ChildSecurityOption | null;
  setChildSecurity: (value: ChildSecurityOption) => void;
}

export interface AddChildFormProps {
  genericInfoProps: AddChildGenericInfoProps;
  onConfirm: () => void;
  confirmText: string;
  isValid: boolean;
  childIcons: ChildIconsDefintion;
  infoChildPasswordSrc: string;
  infoIconImageSrc: string;
  placeholderName?: string;
}

const AddChildForm: React.FC<AddChildFormProps> = ({
  genericInfoProps,
  childIcons,
  infoChildPasswordSrc,
  infoIconImageSrc,
  placeholderName,
}) => {
  const { t } = useTranslation();

  const [nameInputPlaceholder, setNameInputPlaceholder] = useState<
    string | undefined
  >(placeholderName);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography>
          <label htmlFor="child-name">{t("settings.child.name")}</label>
        </Typography>
        <TextInput
          id="child-name"
          data-cy="child-name"
          placeholder={nameInputPlaceholder}
          onClick={() => {
            setNameInputPlaceholder(undefined);
          }}
          value={genericInfoProps.name}
          onChange={(e) => genericInfoProps.setName(e.currentTarget.value)}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <label>{t("settings.child.icon")}</label>
        </Typography>
        <ChildIconPicker
          selectedIcon={genericInfoProps.icon}
          setIcon={genericInfoProps.setIcon}
          childIcons={childIcons}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <label>{t("settings.child.gender")}</label>
        </Typography>
        <GenderPicker
          gender={genericInfoProps.gender}
          setGender={genericInfoProps.setGender}
          paddingX={1}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          <label>{t("settings.child.birthDate")}</label>
        </Typography>
        <DateTextInput
          date={genericInfoProps.date}
          setDate={genericInfoProps.setDate}
        />
        <ChildAgeWarning
          show={genericInfoProps.showDobWarning}
          sx={{ marginTop: 1 }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography mb={1}>
          <Trans i18nKey={"settings.child.pin.label"} />{" "}
          <InformationButton
            infoTitle={t("settings.child.pin.label")}
            infoText={t("settings.child.pin.info.text")}
            infoIconImage={infoIconImageSrc}
            coachImage={infoChildPasswordSrc}
            inline
          />
        </Typography>
        <ChildSecuritySelection
          value={genericInfoProps.childSecurity}
          onChange={genericInfoProps.setChildSecurity}
          paddingX={1}
        />
      </Grid>
    </Grid>
  );
};
export default AddChildForm;
