import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Dashboard, { DashboardProgress, DASHBOARD_PROGRESS_TOTAL } from "..";
import { FTUEHint } from "components";
import { FTUEFlow } from "models";
import useFTUE from "useFTUE";

const DashboardRoutines: React.FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setDashboardProgress },
  } = useFTUE();

  const dashboardProgress = progress[FTUEFlow.Dashboard].progress;

  const onNext = () => {
    setDashboardProgress(DashboardProgress.Routines);
  };

  const onQuit = () => {
    setDashboardProgress(DashboardProgress.DashboardFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        dashboardProgress === DashboardProgress.Filters &&
        canDisplayFlow(FTUEFlow.Dashboard)
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.dashboard.routines.text")}
      currentStep={Dashboard.Logic.calculateProgress(DashboardProgress.Filters)}
      maxStep={DASHBOARD_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default DashboardRoutines;
