import { Box, Typography } from "@mui/material";
import {
  DaysPicker,
  InformationButton,
  InputError,
  InputLabel,
  ResetButton,
  TimeRangePicker,
} from "@neurosolutionsgroup/components";
import { Language } from "@neurosolutionsgroup/models";
import { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";

interface ChallengeMoreSectionProps {
  days: number;
  setDays: React.Dispatch<React.SetStateAction<number>>;
  startTime: number | null;
  setStartTime: React.Dispatch<React.SetStateAction<number | null>>;
  endTime: number | null;
  setEndTime: React.Dispatch<React.SetStateAction<number | null>>;
  language: Language;
  coachInfoIconImgSrc: string;
  daysInfoImgSrc: string;
}

const ChallengeMoreSection = ({
  days,
  setDays,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
  language,
  coachInfoIconImgSrc,
  daysInfoImgSrc,
}: ChallengeMoreSectionProps): JSX.Element => {
  const { t } = useTranslation();

  useEffect(() => {
    if (startTime && !endTime) {
      setEndTime(startTime + 3600);
    }
  }, [startTime]);

  useEffect(() => {
    if (endTime && !startTime) {
      setStartTime(endTime > 3600 ? endTime - 3600 : 0);
    }
  }, [endTime]);

  const timeError = useMemo(() => {
    if (!(startTime && endTime)) {
      return false;
    }

    return endTime - startTime < 15 * 60;
  }, [startTime, endTime]);

  return (
    <>
      <Box mb={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <InputLabel>
            {`${t("routine.challenge.inputs.days.label")} (${t(
              "general.optional"
            )}) `}
            <InformationButton
              infoTitle={t("routine.challenge.inputs.days.label")}
              infoText={
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  mb={2}
                >
                  <Box
                    mb={2}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      img: {
                        width: "60%",
                        maxWidth: "300px",
                        objectFit: "contain",
                      },
                    }}
                  >
                    <img src={daysInfoImgSrc} alt="" />
                  </Box>
                  <Typography fontSize="0.9rem">
                    <Trans
                      i18nKey={"routine.challenge.inputs.days.info.text"}
                    />
                  </Typography>
                </Box>
              }
              infoIconImage={coachInfoIconImgSrc}
              coachImage={null}
              inline
            />
          </InputLabel>

          <ResetButton
            onClick={() => {
              setDays(0);
            }}
          />
        </Box>

        <DaysPicker value={days} setValue={setDays} multiple />
      </Box>

      <Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <InputLabel>{`${t("routine.challenge.inputs.time.label")} (${t(
            "general.optional"
          )}) `}</InputLabel>

          <ResetButton
            onClick={() => {
              setStartTime(null);
              setEndTime(null);
            }}
          />
        </Box>

        <TimeRangePicker
          start={startTime}
          setStart={setStartTime}
          end={endTime}
          setEnd={setEndTime}
          language={language}
          error={timeError}
        />

        {timeError ? (
          <InputError
            sx={{
              marginTop: 0.5,
            }}
          >
            {t("routine.challenge.inputs.time.error")}
          </InputError>
        ) : null}
      </Box>
    </>
  );
};

export default ChallengeMoreSection;
