import { useCallback, useState } from "react";
import {
  ButtonBase,
  drawerClasses,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import { CypressProps, SafeAreas } from "@neurosolutionsgroup/models";
import ControlledDrawer from "../../ControlledDrawer/ControlledDrawer";
import DrawerMenuSection from "../../ControlledDrawer/DrawerMenuSection";
import SelectInputButton from "./SelectInputButton";

export interface SelectOption<U extends number | string | null> {
  value: U;
  label?: string | JSX.Element;
  disabled?: boolean;
  display?: string | JSX.Element;
  premiumLock?: boolean;
}

export interface SelectClasses {
  root?: string;
  drawer?: string;
  option?: string;
  button?: string;
}

export interface SelectInputProps<T extends number | string | null>
  extends CypressProps {
  value: T | null;
  options: SelectOption<T>[];
  onChange: (option: SelectOption<T>) => void;
  label: string;
  placeholder?: string | JSX.Element;
  hideIndicator?: boolean;
  indicator?: JSX.Element;
  leftIndicator?: JSX.Element;
  id?: string;
  classes?: SelectClasses;
  truncateOnOverflow?: boolean;
  fullWidth?: boolean;
  variant?: "text" | "outlined" | "contained";
  safeAreas?: SafeAreas;
  sx?: SxProps<Theme>;
  hasPremiumLock?: boolean;
  onPremiumOptionClick?: (option: SelectOption<T>) => void;
}

const SelectInput = <T extends number | string | null>({
  value,
  options,
  onChange,
  label,
  placeholder,
  hideIndicator = false,
  indicator,
  leftIndicator,
  id,
  classes,
  truncateOnOverflow = false,
  fullWidth = false,
  variant = "text",
  safeAreas,
  sx,
  hasPremiumLock = false,
  onPremiumOptionClick,
  ...props
}: SelectInputProps<T>): JSX.Element => {
  const ROOT_CLASS = "select-input";

  const [drawerOpen, setDrawerOpen] = useState(false);

  /**
   * Update label on options or value change.
   */
  const getValueLabel = useCallback((): string | JSX.Element | null => {
    if (value === null) {
      return placeholder ?? null;
    }

    const option = options.find((o) => value === o.value);

    if (!option) {
      return placeholder ?? null;
    }

    return option.display ?? option.label ?? `${option.value}`;
  }, [options, value]);

  /**
   * Close drawer on selection.
   */
  const onOptionClick = (option: SelectOption<T>) => {
    onChange(option);
    setDrawerOpen(false);
  };

  const onPremiumClick = (option: SelectOption<T>) => {
    setDrawerOpen(false);
    onPremiumOptionClick && onPremiumOptionClick(option);
  };

  return (
    <>
      <SelectInputButton
        onClick={() => setDrawerOpen(true)}
        disabled={options.length === 0}
        color="secondary"
        variant={variant}
        fullWidth={fullWidth}
        hideIndicator={hideIndicator}
        indicator={indicator}
        leftIndicator={leftIndicator}
        truncateOnOverflow={truncateOnOverflow}
        placeholderStyle={value === null}
        className={clsx(ROOT_CLASS + "__button")}
        data-cy={props["data-cy"]}
      >
        {getValueLabel()}
      </SelectInputButton>
      <ControlledDrawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
        classes={{
          paper: clsx(ROOT_CLASS + "__drawer"),
        }}
        sx={{
          [`& .${drawerClasses.paper}`]: {
            maxHeight: "calc(90vh)",
          },
        }}
        isPremiumLocked={hasPremiumLock}
        safeAreas={safeAreas}
      >
        <DrawerMenuSection variant="header">
          <Typography variant="h4">{label}</Typography>
        </DrawerMenuSection>
        {options
          .filter((o) => !hasPremiumLock || !o.premiumLock)
          .map((o, i) => (
            <DrawerMenuSection
              className={clsx(ROOT_CLASS + "__option", classes?.option, {
                [ROOT_CLASS + "__option--active"]: o.value === value,
              })}
              premiumLock={false}
              key={i}
            >
              <ButtonBase
                data-cy={`${props["data-cy"]}-${i}`}
                onClick={(e) => {
                  e.stopPropagation();
                  onOptionClick(o);
                }}
                sx={{
                  opacity: o.disabled ? 0.5 : 1,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingX: 1,
                }}
                disabled={o.disabled}
              >
                <Typography>{o.label ?? o.value}</Typography>
              </ButtonBase>
            </DrawerMenuSection>
          ))}
        {hasPremiumLock
          ? options
              .filter((o) => o.premiumLock)
              .map((o, i) => (
                <DrawerMenuSection
                  className={clsx(ROOT_CLASS + "__option", classes?.option, {
                    [ROOT_CLASS + "__option--active"]: o.value === value,
                  })}
                  key={i}
                  premiumLock={true}
                  onPremiumLockClick={() => onPremiumClick(o)}
                  hidePremiumTag={i !== 0}
                >
                  <ButtonBase
                    data-cy={`${props["data-cy"]}-${i}`}
                    onClick={(e) => {
                      e.stopPropagation();
                      onOptionClick(o);
                    }}
                    sx={{
                      opacity: o.disabled ? 0.5 : 1,
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    disabled={o.disabled}
                  >
                    <Typography>{o.label ?? o.value}</Typography>
                  </ButtonBase>
                </DrawerMenuSection>
              ))
          : null}
      </ControlledDrawer>
    </>
  );
};

export default SelectInput;
