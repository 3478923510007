import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const SubtractIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="subtract" {...props}>
      <path
        d="m 20,11.428571 h -8.571429 v 0 H 8.5714286 v 0 H 0 V 8.5714286 h 8.5714286 v 0 h 2.8571424 v 0 H 20 Z"
        fill={color}
        id="path985"
      />
    </BaseIcon>
  );
};

export default SubtractIcon;
