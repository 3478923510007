import { ObservationProgress } from "..";
import Intro from "./Screens/Intro";
import { FTUEFlow } from "models";
import { FullScreenFTUEProps } from "components";
import useFTUE from "useFTUE";
import ObservationScreenComponent from "./ObservationScreenComponent";
import { useNavigate } from "react-router-dom";

const FTUEObservationScreen = (
  props: FullScreenFTUEProps
): JSX.Element | null => {
  const {
    selectors: { assets, progress },
    actions: { canDisplayFlow, setObservationProgress },
  } = useFTUE();
  const navigate = useNavigate();

  const observationProgress = progress[FTUEFlow.Observation].progress;

  const showFullScreen = () => {
    const fullScreenSteps = [ObservationProgress.None];

    return fullScreenSteps.includes(observationProgress);
  };

  const onIntroContinue = () => {
    navigate("/follow-ups");
    setObservationProgress(ObservationProgress.Intro);
  };

  const onIntroCancel = () => {
    setObservationProgress(ObservationProgress.Later);
  };

  const renderFTUESection = () => {
    switch (observationProgress) {
      case ObservationProgress.None:
        return (
          <Intro
            safeAreas={props.safeAreas}
            assets={assets}
            onCancel={onIntroCancel}
            onContinue={onIntroContinue}
          />
        );
      default:
        return null;
    }
  };

  return canDisplayFlow(FTUEFlow.Observation) && showFullScreen() ? (
    <ObservationScreenComponent safeAreas={props.safeAreas}>
      {renderFTUESection()}
    </ObservationScreenComponent>
  ) : null;
};

export default FTUEObservationScreen;
