import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

export interface CloseIconProps extends IconProps {
  withCircle?: boolean;
  color?: string;
}

const CloseIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  withCircle = false,
  ...props
}: CloseIconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="close" {...props}>
      {withCircle ? (
        <path
          fill={color}
          d="M 10 2 A 8 8 90 0 0 2 10 A 8 8 90 0 0 10 18 A 8 8 90 0 0 18 10 A 8 8 90 0 0 10 2 z M 6.7156 4.5594 L 10.5531 8.5547 L 14.1547 4.9938 L 15.5797 6.3 L 11.9984 10.0594 L 14.8672 13.0469 L 13.9969 15.0062 L 10.4016 11.7375 L 6.8734 15.4406 L 5.5672 13.4828 L 8.8031 10.2844 L 4.4203 6.3 L 6.7156 4.5594 z"
        />
      ) : (
        <path
          d="m 10.708834,13.192726 6.353564,6.007748 1.538281,-3.60014 L 13.531313,10.11019 19.859008,3.1995435 17.342215,0.79951985 10.976905,7.3437282 4.19605,2.5e-7 0.14099175,3.2000392 7.8849973,10.522616 2.1684458,16.39986 4.4759077,20 Z"
          fill={color}
        />
      )}
    </BaseIcon>
  );
};

export default CloseIcon;
