import {
  CoachNotificationRecord,
  MessageRecord,
} from "@neurosolutionsgroup/models";

const migrateOldMessageRecords = (
  oldCoachNotifications: CoachNotificationRecord[]
): MessageRecord[] => {
  return oldCoachNotifications.map((notif) => {
    const { id, lastTime, abTest, occurrences, template } = notif;

    if (abTest || occurrences || template) {
      const extraData: Record<string, string | number | boolean> = {};

      if (abTest) {
        extraData.abTest = abTest;
      }

      if (occurrences) {
        extraData.occurrences = occurrences;
      }

      if (template) {
        extraData.template = template;
      }

      return {
        id,
        lastSeen: lastTime,
        extraData,
      };
    }
    return {
      id,
      lastSeen: lastTime,
    };
  });
};

export default migrateOldMessageRecords;
