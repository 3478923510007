import { Box, Grid, Typography } from "@mui/material";
import { Prescription } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import MiscAssets from "assets/misc";
import { AccordionCard } from "common/Components";
import useChildren from "common/hooks/children/useChildren";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useMedication from "common/hooks/medications/useMedication";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { add, format } from "date-fns";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

const MedicationReminderCard = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { language, dateLocale } = useLanguage();

  const { selectedChild } = useChildren().selectors;
  const {
    selectors: { prescriptionsByGamerChildId },
  } = useFollowUp();
  const {
    selectors: { medicationById },
  } = useMedication();

  const [expanded, setExpanded] = useState(false);

  const prescriptions: Prescription[] = useMemo(() => {
    if (!!selectedChild && !!prescriptionsByGamerChildId[selectedChild]) {
      // Get prescriptions without end date.
      const prescriptions = prescriptionsByGamerChildId[selectedChild].filter(
        (p) => p.endDate === null || p.endDate === undefined
      );

      setExpanded(prescriptions.length === 0);

      return prescriptions;
    }

    setExpanded(true);

    return [];
  }, [selectedChild, prescriptionsByGamerChildId]);

  return prescriptions.length > 0 ? (
    <AccordionCard
      expanded={expanded}
      setExpanded={setExpanded}
      title={t("dashboard.med.reminder.current")}
    >
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={4} sm={3}>
          <Box
            sx={{
              img: {
                height: "6rem",
                maxWidth: "100%",
                objectFit: "contain",
              },
            }}
          >
            <img src={MiscAssets.MedReminderIcon} alt="" />
          </Box>
        </Grid>
        <Grid item xs={8} sm={9}>
          {prescriptions.length === 0 ? (
            <Box>
              <Typography color="secondary" fontSize="0.83rem">
                {t("dashboard.med.reminder.noFollowUp")}
              </Typography>
            </Box>
          ) : null}
          {prescriptions.map((p) => (
            <Box key={p.prescriptionId} mb={1}>
              <Typography fontSize="0.83rem" color="secondary">
                {t("dashboard.med.reminder.since")}&nbsp;
                {format(
                  add(new Date(p.startDate), {
                    minutes: new Date().getTimezoneOffset(),
                  }),
                  "PP",
                  {
                    locale: dateLocale,
                  }
                )}
              </Typography>
              {p.drug.doses.length === 1 ? (
                <Typography fontSize="0.83rem" color="secondary">
                  {medicationById &&
                    medicationById[p.drug.drugId].name[language]}
                  &nbsp;
                  {p.drug.doses[0].quantity}
                  {p.drug.doses[0].units ?? "mg"}
                </Typography>
              ) : (
                p.drug.doses.map((d) => (
                  <Typography fontSize="0.83rem" color="secondary" key={d.time}>
                    {medicationById &&
                      medicationById[p.drug.drugId].name[language]}
                    &nbsp;
                    {d.quantity}mg&nbsp;{t("dashboard.med.reminder.at")}&nbsp;
                    {Tools.Time.Strings.localizedTimeFromSeconds(
                      d.time,
                      language
                    )}
                  </Typography>
                ))
              )}
            </Box>
          ))}
        </Grid>
      </Grid>
    </AccordionCard>
  ) : null;
};

export default MedicationReminderCard;
