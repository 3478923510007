import { SxProps, Theme } from "@mui/material";

const flexCenter: SxProps<Theme> = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const noScroll: SxProps<Theme> = {
  "&::-webkit-scrollbar": {
    display: "none",
  },
  "msOverflowStyle": "none" /* IE and Edge */,
  "scrollbarWidth": "none" /* Firefox */,
};

const square = (
  size: number | string
): { width: number | string; height: number | string } => ({
  width: size,
  height: size,
});

const Layout = {
  flexCenter,
  noScroll,
  square,
};

export default Layout;
