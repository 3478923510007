import {
  CreateMedicalChildBody,
  MedicalChild,
  UpdateMedicalChildBody,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import { executeAuthedGet, getHeaders, getURL, handleApiError } from "../utils";

const getMedicalChildren = async (): Promise<MedicalChild[]> => {
  const PATH = "/api/medicalChild";

  return executeAuthedGet(PATH, { "accept-version": "v2" });
};

const createMedicalChild = async (
  body: CreateMedicalChildBody
): Promise<MedicalChild> => {
  const URL = getURL() + "/api/medicalChild";

  const headers = await getHeaders(true, "createMedicalChild");

  if (headers) {
    headers["accept-version"] = "v2";
  }

  try {
    const result = await axios.post<MedicalChild>(URL, body, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const updateMedicalChild = async (
  body: UpdateMedicalChildBody
): Promise<void> => {
  const URL = getURL() + `/api/medicalChild/${body.medicalChildId}`;

  const headers = await getHeaders(true, "updateMedicalChild");

  if (headers) {
    headers["accept-version"] = "v2";
  }

  try {
    return await axios.put(URL, body, { headers });
  } catch (err) {
    return handleApiError(err);
  }
};

const deleteMedicalChild = async (medicalChildId: string): Promise<void> => {
  const URL = getURL() + `/api/medicalChild/${medicalChildId}`;

  const headers = await getHeaders(true, "deleteMedicalChild");

  if (headers) {
    headers["accept-version"] = "v2";
  }

  try {
    return await axios.delete(URL, { headers });
  } catch (err) {
    return handleApiError(err);
  }
};

const Child = {
  getMedicalChildren,
  createMedicalChild,
  updateMedicalChild,
  deleteMedicalChild,
};

export default Child;
