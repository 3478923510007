import { Box, Grid, Typography } from "@mui/material";
import { ChallengeV2 } from "@neurosolutionsgroup/models";
import { useMemo } from "react";
import ChallengeProgressBar from "./ChallengeProgressBar";
import { useTranslation } from "react-i18next";

interface ChallengeDisplayCardHistoryProps {
  challenge: ChallengeV2;
  currentChallengeWeek?: number;
}

const ChallengeDisplayCardHistory = ({
  challenge,
  currentChallengeWeek,
}: ChallengeDisplayCardHistoryProps): JSX.Element => {
  const { t } = useTranslation();

  const challengeWeeks: { count: number; percentage: number }[] =
    useMemo(() => {
      const weeks = [];

      for (let weekIndex = 1; weekIndex <= challenge.duration; weekIndex++) {
        const completedChallengeCount = challenge.history.filter(
          (history) =>
            history.week === weekIndex && history.parentStatus === true
        ).length;

        weeks.push({
          count: completedChallengeCount,
          percentage: (completedChallengeCount / challenge.frequency) * 100,
        });
      }

      return weeks;
    }, [challenge]);

  return (
    <Box data-cy="challenge-history-list">
      {challengeWeeks.map((week, i) => (
        <Grid
          container
          alignItems="center"
          mb={1}
          key={i}
          data-cy={`challenge-history-week-${i + 1}`}
        >
          <Grid item xs={12} display="flex">
            <Typography fontSize="0.8rem">
              {t("general.time.week")}&nbsp;{i + 1}
              {challenge.cancelledWeek && challenge.cancelledWeek <= i + 1 ? (
                <i> - {t("general.cancelled")}</i>
              ) : null}
            </Typography>
          </Grid>
          <Grid item xs={3} sm={2}>
            <Typography fontSize="0.85rem" fontWeight={800}>
              {currentChallengeWeek && i + 1 === currentChallengeWeek ? (
                <>{t("dashboard.skills.currentChallenge.ongoing")}</>
              ) : currentChallengeWeek && i + 1 > currentChallengeWeek ? (
                <>{t("dashboard.skills.currentChallenge.toCome")}</>
              ) : (
                <>
                  {week.count}/{challenge.frequency}
                </>
              )}
            </Typography>
          </Grid>
          <Grid item xs={9} sm={10}>
            <ChallengeProgressBar
              value={week.percentage}
              allowEmpty={
                !!challenge.cancelledWeek && challenge.cancelledWeek <= i + 1
              }
              inProgress={
                !!currentChallengeWeek && i + 1 === currentChallengeWeek
              }
              future={
                !!currentChallengeWeek &&
                i + 1 > currentChallengeWeek &&
                !challenge.cancelledWeek
              }
            />
          </Grid>
        </Grid>
      ))}
    </Box>
  );
};

export default ChallengeDisplayCardHistory;
