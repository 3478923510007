import { FirestoreRecordSummaryDocument } from "@neurosolutionsgroup/models";
import startOfISOWeek from "date-fns/startOfISOWeek";
import sub from "date-fns/sub";
import { v4 } from "uuid";

export const constructRecordSummaries = (
  userId: string,
  tenantId: string,
  medicalChildId: string,
  sideEffectId: string,
  questionId: string,
  prescriptionIds: string[],
  startDate: Date,
  endDate: Date,
  hasObservationsToDo: boolean,
  logger?: (log: string) => void
): FirestoreRecordSummaryDocument[] => {
  const frequency = questionId === "39f391aa-faa7-4c75-abbd-a49bcff2b2e0";

  const summaries: FirestoreRecordSummaryDocument[] = [];

  let lastDate = startOfISOWeek(endDate);

  if (hasObservationsToDo) {
    lastDate = sub(lastDate, { days: 14 });
  }

  logger &&
    logger(
      `Generating records between ${
        startDate.toISOString().split("T")[0]
      } and ${lastDate.toISOString().split("T")[0]}`
    );

  let currentDate = lastDate;
  let currentYear = currentDate.getFullYear();

  summaries.push({
    userId,
    tenantId,
    medicalChildId,
    sideEffectId,
    questionId,
    year: currentYear,
    records: [],
  });

  while (currentDate.getTime() >= startDate.getTime()) {
    currentYear = currentDate.getFullYear();

    const currentSummaryYear = summaries[summaries.length - 1].year;

    if (currentYear !== currentSummaryYear) {
      summaries.push({
        userId,
        tenantId,
        medicalChildId,
        sideEffectId,
        questionId,
        year: currentYear,
        records: [],
      });
    }

    summaries[summaries.length - 1].records.push({
      answer: getRandomAnswer(frequency),
      prescriptionIds,
      recordId: v4(),
      weekStartDate: currentDate.toISOString().split("T")[0],
    });

    currentDate = sub(currentDate, { days: 7 });
  }

  return summaries;
};

const getRandomAnswer = (frequency: boolean): number => {
  let min = 0;
  let max = 3;

  if (frequency) {
    min = 0;
    max = 7;
  }

  return Math.floor(Math.random() * (max - min + 1)) + min;
};
