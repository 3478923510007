import { Grid } from "@mui/material";
import SkillsInShort from "./SkillsInShort";
import { ChallengeV2, SkillShortData } from "@neurosolutionsgroup/models";
import CurrentChallengeCard from "./CurrentChallengeCard/CurrentChallengeCard";
import { PremiumLock } from "@neurosolutionsgroup/components";
import { IconManager } from "@neurosolutionsgroup/tools";

interface SkillsDashboardProps {
  challengeIconImgSrc: string;
  currentChallenges: ChallengeV2[];
  coachInfoIconSrc: string;
  dateLocale: Locale;
  shortInfoImgSrc: string;
  skills: SkillShortData[];
  taskIconAssets: Record<number, string>;
  iconManager: IconManager;
  premiumLock?: boolean;
  onPremiumLockClick?: VoidFunction;
  hasV1Challenges?: boolean;
}

const SkillsDashboard = ({
  challengeIconImgSrc,
  currentChallenges,
  coachInfoIconSrc,
  dateLocale,
  shortInfoImgSrc,
  skills,
  taskIconAssets,
  iconManager,
  premiumLock = false,
  onPremiumLockClick,
  hasV1Challenges = false,
}: SkillsDashboardProps): JSX.Element => {
  return (
    <Grid container spacing={2}>
      {currentChallenges.length > 0 ? (
        currentChallenges.map((challenge) => (
          <Grid item xs={12} key={challenge.id}>
            <CurrentChallengeCard
              challenge={challenge}
              challengeIconImgSrc={challengeIconImgSrc}
              dateLocale={dateLocale}
              taskIconAssets={taskIconAssets}
              iconManager={iconManager}
            />
          </Grid>
        ))
      ) : !hasV1Challenges ? (
        <Grid item xs={12}>
          <PremiumLock
            active={premiumLock}
            onClick={() => onPremiumLockClick && onPremiumLockClick()}
            variant="long"
            display="block"
            blur
            blurRadius="2px"
            borderRadius="15px"
            backgroundColor="none"
          >
            <CurrentChallengeCard
              challenge={null}
              challengeIconImgSrc={challengeIconImgSrc}
              taskIconAssets={taskIconAssets}
              iconManager={iconManager}
              dateLocale={dateLocale}
              defaultOpen
            />
          </PremiumLock>
        </Grid>
      ) : null}
      <Grid item xs={12} mt={1}>
        <SkillsInShort
          coachInfoIconSrc={coachInfoIconSrc}
          infoImgSrc={shortInfoImgSrc}
          skills={skills}
        />
      </Grid>
    </Grid>
  );
};

export default SkillsDashboard;
