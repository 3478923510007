import { FirestoreCollection, Language } from "@neurosolutionsgroup/models";
import {
  getAdditionalUserInfo,
  getAuth,
  getRedirectResult,
  onAuthStateChanged,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  User,
} from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import * as Sentry from "@sentry/react";
import useParameters from "../Parameters/useParameters";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import {
  AppLogin,
  AppRegistration,
  AuthType,
  AnalyticsAccountCreated,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { Tools } from "@neurosolutionsgroup/tools";
import useLanguage from "../Parameters/useLanguage";
import useNavigation from "../navigation/useNavigation";
import { useAppInitializationContext } from "../AppInitializationContext";
import { UniWebViewActions } from "../Parameters/UniWebViewActions";
import {
  WebviewsFeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";

interface AuthData {
  user?: User;
  tenantId?: string;
  setupAuthFromCustomToken: (token?: string) => void;
  trackRegistration: (
    userId: string,
    type: AuthType,
    language: Language,
    email?: string,
    name?: string
  ) => void;
  trackLogin: (type: AuthType, userId: string) => void;
  resetAuth: () => void;
}

const [useAuthContext, AuthContextProvider] =
  Tools.Context.createGenericContext<AuthData>(__filename);

export const AuthProvider: React.FC<PropsWithChildren> = (props) => {
  const [user, setUser] = useState<User>();
  const [tenantId, setTenantId] = useState<string>();
  const [unityMessageReceived, setUnityMessageReceived] = useState(false);
  const [thirdPartyAuthCheckComplete, setThirdPartyAuthCheckComplete] =
    useState(false);

  const {
    handleEvent,
    handleEvents,
    functions: { onUserLogin },
    analyticsInitializationComplete,
  } = useAnalytics();
  const { language } = useLanguage();
  const {
    actions: { setParentSectionOpen },
    selectors: { shorterFtue },
  } = useNavigation();
  const { authNoUserConfirmed, setAuthNoUserConfirmed, setUserInitiated } =
    useAppInitializationContext();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  const { sendMessageToUnity, addLoadingProgress, version } = useParameters();

  const auth = useMemo(() => getAuth(), []);

  const getUserTenantId = async (userId: string): Promise<string> => {
    const db = getFirestore();

    const userDocRef = doc(db, FirestoreCollection.Users, userId);

    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists) {
      return Promise.reject(
        new Error(`User doc not found for authenticated user: ${userId}.`)
      );
    }

    return userDoc.data()?.tenantId;
  };

  const setupAuthFromCustomToken = async (token?: string) => {
    setUnityMessageReceived(true);

    // If Unity was authed, auth the webview, otherwise signout.
    if (token) {
      const userCredential = await signInWithCustomToken(auth, token);

      // Must calculate whether this auth was a registration as Unity SDK does not provide this.
      const userCreation = userCredential.user.metadata.creationTime;

      const isNewUser =
        userCreation &&
        Tools.Time.Dates.getTimeStamp() -
          Tools.Time.Dates.getTimeStamp(new Date(userCreation)) <
          60;

      if (isNewUser === true) {
        trackRegistration(
          userCredential.user.uid,
          userCredential.providerId === "google.com" ? "google" : "apple",
          language,
          userCredential.user.email ?? undefined,
          userCredential.user.displayName ?? undefined
        );

        if (
          checkFeatureFlagVersion(
            WebviewsFeatureFlag.DefaultChildCreation,
            version
          )
        ) {
          await FirebaseAPI.Child.setupDefaultChild(language);
        }

        await FirebaseAPI.Account.putUserInfo({
          firstName: userCredential.user.displayName ?? "",
          lastName: "",
          city: "None",
          termsAccepted: true,
          email: userCredential.user.email ?? "",
        });

        if (shorterFtue) {
          setParentSectionOpen(true);
        }

        await getUserTenantId(userCredential.user.uid).then((tenantId) => {
          setTenantId(tenantId);
        });
      } else {
        trackLogin(
          userCredential.providerId === "google.com" ? "google" : "apple",
          userCredential.user.uid
        );
      }
    } else {
      // Don't sign out straightaway as could be a callback from 3rd party.
      setAuthNoUserConfirmed(true);
    }
  };

  useEffect(() => {
    if (user && tenantId) {
      setUserInitiated(true);
    } else {
      setUserInitiated(false);
    }
  }, [user, tenantId]);

  useEffect(() => {
    if (
      !checkFeatureFlagVersion(
        WebviewsFeatureFlag.NativeThirdPartyAuth,
        version
      )
    )
      getRedirectResult(auth).then(async (result) => {
        if (result) {
          const additionalInfo = getAdditionalUserInfo(result);

          if (additionalInfo?.isNewUser) {
            trackRegistration(
              result.user.uid,
              result.providerId === "google.com" ? "google" : "apple",
              language,
              result.user.email ?? undefined,
              result.user.displayName ?? undefined
            );

            await FirebaseAPI.Account.putUserInfo({
              firstName: result.user.displayName ?? "",
              lastName: "",
              city: "None",
              termsAccepted: true,
              email: result.user.email ?? "",
            });

            if (
              checkFeatureFlagVersion(
                WebviewsFeatureFlag.DefaultChildCreation,
                version
              )
            ) {
              await FirebaseAPI.Child.setupDefaultChild(language);
            }

            if (shorterFtue) {
              setParentSectionOpen(true);
            }

            await getUserTenantId(result.user.uid).then((tenantId) => {
              setTenantId(tenantId);
            });
          } else {
            trackLogin(
              result.providerId === "google.com" ? "google" : "apple",
              result.user.uid
            );
          }
        } else {
          setThirdPartyAuthCheckComplete(true);
        }
      });

    onAuthStateChanged(auth, async (authedUser) => {
      if (authedUser) {
        setUser(authedUser);

        Sentry.setUser({
          id: authedUser.uid,
          email: authedUser.email ?? undefined,
        });

        try {
          const tenantId = await getUserTenantId(authedUser.uid);

          setTenantId(tenantId);
        } catch {
          // Retry tenant get, then handle error.
          try {
            const tenantId = await getUserTenantId(authedUser.uid);

            setTenantId(tenantId);
          } catch (err) {
            console.error(err);
          }
        }
      } else {
        setUser(undefined);
        setTenantId(undefined);
        setParentSectionOpen(false);

        // In development allow loading to continue without Unity message.
        if (Tools.Environment.isDevBuild()) {
          setAuthNoUserConfirmed(true);
        }
      }
    });

    const devAuth = Tools.Environment.webviewsEnvTools.getDevAuth();

    if (devAuth && !user) {
      console.log("Logging in with dev auth info.");
      signInWithEmailAndPassword(auth, devAuth.email, devAuth.password);
    }
  }, []);

  useEffect(() => {
    // Given Unity message received, and Unity was not authed when auth changed: then update auth in Unity.
    // i.e. If authenticated via webview (sign in or sign up).
    const authenticateUnity = async () => {
      if (user) {
        try {
          const token = await FirebaseAPI.Account.getCustomToken();

          if (token) {
            sendMessageToUnity(
              UniWebViewActions.PassAuthToken,
              `token=${token}`
            );
          } else {
            console.error(
              "Unity authentication failed after token returned empty."
            );
          }
        } catch (err) {
          console.error(
            "Unity authentication failed after webview authentication: " + err
          );
        }
      }
    };

    if (user || authNoUserConfirmed) {
      authenticateUnity();
    }
  }, [user, authNoUserConfirmed]);

  useEffect(() => {
    // Wait to have received message from Unity (with token or not) before releasing loading.
    if (
      unityMessageReceived &&
      ((authNoUserConfirmed && thirdPartyAuthCheckComplete) ||
        (user && tenantId))
    ) {
      if (user) {
        addLoadingProgress(20);
      } else {
        setAuthNoUserConfirmed(true);
        addLoadingProgress(100);
      }
    }
  }, [unityMessageReceived, authNoUserConfirmed, user, tenantId]);

  useEffect(() => {
    if (user && analyticsInitializationComplete) {
      const created = user.metadata.creationTime
        ? Tools.Time.Dates.getTimeStamp(new Date(user.metadata.creationTime))
        : undefined;

      onUserLogin(user.uid, language, user.email ?? undefined, created);
    }
  }, [user, analyticsInitializationComplete]);

  const trackRegistration = (
    userId: string,
    type: AuthType,
    language: Language,
    email?: string,
    name?: string
  ) => {
    // AppsFlyer.
    sendMessageToUnity(
      UniWebViewActions.CompletedRegistration,
      `userId=${userId}&method=${type}`
    );

    // Analytics.
    const date = new Date().toISOString();

    const analyticsAccountCreatedEvent: AnalyticsAccountCreated = {
      name: "Analytics Account Created",
      eventProperties: {
        "source": "App",
        "Email Captured By": "App",
      },
      setProperties: {
        email,
        language,
      },
      setOnceProperties: {
        "Email Captured On": date,
        "Email Captured By": "App",
      },
    };

    const registrationEvent: AppRegistration = {
      name: "App Registration",
      eventProperties: {
        Type: type,
      },
      setProperties: {
        language,
        name,
      },
      setOnceProperties: {
        "User ID": userId,
        "Email Captured On": date,
        "Email Captured By": "App",
        "Account Created On": date,
        "Account Completed On": date,
        "First Time Open On": date,
        "FirstName": name,
      },
    };

    handleEvents([analyticsAccountCreatedEvent, registrationEvent]);
  };

  const trackLogin = (type: AuthType, userId: string) => {
    const date = new Date().toISOString();

    const event: AppLogin = {
      name: "App Login",
      eventProperties: {
        Type: type,
      },
      setOnceProperties: {
        "First Login On": date,
        "User ID": userId,
      },
    };

    handleEvent(event);
  };

  const resetAuth = () => {
    setAuthNoUserConfirmed(true);
  };

  return (
    <AuthContextProvider
      value={{
        user,
        tenantId,
        setupAuthFromCustomToken,
        trackRegistration,
        trackLogin,
        resetAuth,
      }}
    >
      {props.children}
    </AuthContextProvider>
  );
};

interface UseAuthResult extends AuthData {
  logOut: () => Promise<void>;
}

const useAuth = (): UseAuthResult => {
  const authContext = useAuthContext();

  const {
    actions: { setMenuPageOpen, setParentSectionOpen },
  } = useNavigation();
  const { sendMessageToUnity } = useParameters();

  const logOut = async (): Promise<void> => {
    const auth = getAuth();

    await auth.signOut();

    authContext.resetAuth();

    setMenuPageOpen(false);
    setParentSectionOpen(false);

    sendMessageToUnity(UniWebViewActions.LogOut);
  };

  return {
    ...authContext,
    logOut,
  };
};

export default useAuth;
