import { Medication, MedicationCategory } from "@neurosolutionsgroup/models";
import { v4 } from "uuid";
import { HookResult } from "../HookResult";
import { MedicationById, useMedicationContext } from "./MedicationContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

export interface useMedicationSelector {
  medicationIds: string[] | undefined;
  medicationById: MedicationById | undefined;
}

export interface useMedicationActions {
  setMedicationIds: (value: string[] | undefined) => void;
  setMedicationById: (value: MedicationById | undefined) => void;
  createNewMedication: (medicationName: string) => Medication;
}

const useMedication = (): HookResult<
  useMedicationSelector,
  useMedicationActions
> => {
  const { medicationIds, setMedicationIds, medicationById, setMedicationById } =
    useMedicationContext();

  const createNewMedication = (medicationName: string): Medication => {
    const newMedication: Medication = {
      id: v4(),
      name: { en: medicationName, fr: medicationName, de: medicationName },
      dosage: [],
      category: MedicationCategory.MyMedication,
    };
    setMedicationIds((current) => {
      if (current) {
        const clone = [...current];
        clone.push(newMedication.id);
        return clone;
      }
    });
    setMedicationById((current) => {
      if (current) {
        const clone = { ...current };
        clone[newMedication.id] = newMedication;
        return clone;
      }
    });
    FirebaseAPI.Medical.Medication.createNewMedication(newMedication);
    return newMedication;
  };

  return {
    selectors: { medicationIds, medicationById },
    actions: {
      setMedicationIds,
      setMedicationById,
      createNewMedication,
    },
  };
};

export default useMedication;
