import { Box, Button, Typography } from "@mui/material";
import BackgroundAssets from "assets/background";
import CoachAssets from "assets/coach";
import { useCoach } from "common/hooks/messaging/Coach";
import { CoachNotificationRecord } from "common/Services/Coach";
import { useProfileContext } from "common/hooks/account/UserProfileContext";
import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREA_BOTTOM, SAFE_AREA_TOP } from "stylesheets";
import { useFTUE } from "@neurosolutionsgroup/webviews-ftue";

export enum InAppNotification {
  TemporalChest = "in_app_notif_temporal_chest",
}

const inAppNotificationKeys: string[] = [
  InAppNotification.TemporalChest as string,
];

const InAppNotificationsManager: React.FC = () => {
  const { coachNotifications, profileInitializationComplete } =
    useProfileContext();
  const { t } = useTranslation();
  const { updateCoachNotificationRecord } = useCoach();
  const {
    selectors: { firstPageFTUEIsRunning },
  } = useFTUE();

  const [inAppNotificationsQueue, setInAppNotificationsQueue] = useState<
    InAppNotification[]
  >([]);

  const inAppNotificationRecords: CoachNotificationRecord[] = useMemo(
    () =>
      coachNotifications.filter((notif) =>
        inAppNotificationKeys.includes(notif.id)
      ),
    [coachNotifications]
  );

  useEffect(() => {
    const queue: InAppNotification[] = [];

    return setInAppNotificationsQueue((current) => [...current, ...queue]);
  }, [
    inAppNotificationRecords,
    profileInitializationComplete,
    firstPageFTUEIsRunning,
  ]);

  const onDismiss = (notification: InAppNotification) => {
    setInAppNotificationsQueue((current) => current.slice(1));

    updateCoachNotificationRecord(notification);
  };

  return (
    <>
      {inAppNotificationsQueue.length > 0 ? (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
            backgroundColor: "#00BAC4",
            backgroundImage: `url(${BackgroundAssets.RedBackground})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            paddingTop: SAFE_AREA_TOP,
            paddingBottom: SAFE_AREA_BOTTOM,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          p={1}
        >
          <Box
            mt="auto"
            flexGrow={1}
            flexShrink={1}
            sx={{
              boxSizing: "border-box",
              maxHeight: "80vw",
              maxWidth: "80vw",
              img: {
                height: 0,
                minHeight: "100%",
                maxWidth: "100%",
              },
            }}
          >
            <img src={CoachAssets.RobotChest} alt="" />
          </Box>

          <Typography
            color="#fff"
            variant="h3"
            align="center"
            mt="auto"
            maxWidth="500px"
          >
            {t(`coach.inApp.${inAppNotificationsQueue[0]}.title`)}
          </Typography>

          <Typography
            color="#fff"
            align="center"
            mt={3}
            fontSize="0.9rem"
            maxWidth="500px"
          >
            {t(`coach.inApp.${inAppNotificationsQueue[0]}.text`)}
          </Typography>

          <Box mt="auto" mb={4} alignSelf="stretch">
            <Button
              fullWidth
              onClick={() => onDismiss(inAppNotificationsQueue[0])}
            >
              {t("general.understood")}
            </Button>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default InAppNotificationsManager;
