import DashboardPrescriptionsFrequencyGraph from "./DashboardPrescriptionsFrequencyGraph";
import DashboardPrescriptionsIntensityGraph from "./DashboardPrescriptionsIntensityGraph";
import DashboardPrescriptionsReminder from "./DashboardPrescriptionsReminder";
import DashboardPrescriptionsSideEffectsTimePeriod from "./DashboardPrescriptionsSideEffectsTimePeriod";

const DashboardPrescriptionFTUEHints = {
  DashboardPrescriptionsFrequencyGraph,
  DashboardPrescriptionsIntensityGraph,
  DashboardPrescriptionsReminder,
  DashboardPrescriptionsSideEffectsTimePeriod,
};

export default DashboardPrescriptionFTUEHints;
