import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

interface BounceEffectProps extends PropsWithChildren {
  active: boolean;
  sx?: SxProps<Theme>;
}

const BounceEffect = ({
  active,
  sx,
  ...props
}: BounceEffectProps): JSX.Element => {
  return (
    <Box
      sx={[
        {
          "display": "inline-flex",
          "animation": active ? "bounce3 2s ease infinite" : "none",
          "@keyframes bounce3": {
            "0%, 20%, 50%, 80%, 100%": { transform: "scale(1,1)" },
            "40%": { transform: "scale(1.05, 1.05)" },
            "60%": { transform: "scale(1.025, 1.025)" },
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {props.children}
    </Box>
  );
};

export default BounceEffect;
