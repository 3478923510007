import { Tools } from "@neurosolutionsgroup/tools";
import { useEffect, useState } from "react";
import { UniWebViewActions } from "../Parameters/UniWebViewActions";
import { CustomWindow } from "custom.window";
import { useErrorsContext } from "../errors/ErrorContext";

declare let window: CustomWindow;

interface UseDownloadResult {
  cancelDownload: () => void;
  downloadFile: (fileName: string, url: string) => void;
  loading: boolean;
}

const useDownload = (): UseDownloadResult => {
  const { handleUnknownError } = useErrorsContext();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Setup Unity response function.
    window.download = {
      complete: (status: boolean) => {
        if (status) {
          setLoading(false);
        } else {
          setLoading(false);
          handleUnknownError(new Error("Download failed"));
        }
      },
    };
  }, []);

  const cancelDownload = () => {
    const urlString = `uniwebview://${UniWebViewActions.CancelDownload}`;

    if (
      process.env.NODE_ENV === "development" ||
      Tools.Environment.isDevBuild()
    ) {
      console.log(urlString);
    } else {
      window.location.href = urlString;
    }

    setLoading(false);
  };

  const downloadFile = (fileName: string, url: string) => {
    setLoading(true);

    const urlString = `uniwebview://${
      UniWebViewActions.DownloadFile
    }?fileName=${encodeURIComponent(fileName)}&url=${encodeURIComponent(url)}`;

    if (
      process.env.NODE_ENV === "development" ||
      Tools.Environment.isDevBuild()
    ) {
      console.log(urlString);
    } else {
      window.location.href = urlString;
    }
  };

  return {
    cancelDownload,
    downloadFile,
    loading,
  };
};

export default useDownload;
