import {
  Box,
  Button,
  Divider,
  drawerClasses,
  Grid,
  Radio,
  Typography,
} from "@mui/material";
import {
  ControlledDrawer,
  Filter,
  SelectInputButton,
} from "@neurosolutionsgroup/components";
import { needsLoc } from "@neurosolutionsgroup/localization";
import { Language, SafeAreas } from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface SkillPickerProps {
  language: Language;
  mySkillOptions: string[];
  value: string | null;
  onChange: React.Dispatch<React.SetStateAction<string | null>>;
  safeAreas: SafeAreas;
}

const enum FilterValue {
  Mine,
  All,
}

const SkillPicker = ({
  language,
  mySkillOptions,
  value,
  onChange,
  safeAreas,
}: SkillPickerProps): JSX.Element => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState<FilterValue[]>([FilterValue.Mine]);

  const [selectedSkill, setSelectedSkill] = useState<string | null>(null);

  useEffect(() => {
    if (value) {
      setSelectedSkill(value);
    }
  }, [value]);

  useEffect(() => {
    if (mySkillOptions.length === 0) {
      setFilter([FilterValue.All]);
    } else {
      setFilter([FilterValue.Mine]);
    }
  }, [mySkillOptions]);

  const displayValue = value
    ? t(`settings.child.needs.needs.${value}`)
    : t("routine.challenge.inputs.skill.placeholder");

  const orderedMySkillOptions = mySkillOptions.sort((a, b) =>
    needsLoc[language].needs[a].localeCompare(needsLoc[language].needs[b])
  );

  const orderedNeeds = Object.entries(needsLoc[language].needs)
    .sort((a, b) => a[1].localeCompare(b[1]))
    .filter((entry) => entry[0] !== "other")
    .map((entry) => entry[0]);

  const onSkillPicked = (value: string, checked: boolean) => {
    if (checked) {
      setSelectedSkill(value);
    } else {
      setSelectedSkill(null);
    }
  };

  const onConfirm = () => {
    onChange(selectedSkill);

    setOpen(false);
  };

  return (
    <>
      <SelectInputButton
        onClick={() => setOpen(true)}
        color="secondary"
        fullWidth
        placeholderStyle={!value}
        data-cy="challenge-skill-selector"
      >
        {displayValue}
      </SelectInputButton>
      <ControlledDrawer
        anchor="bottom"
        open={open}
        onClose={() => setOpen(false)}
        sx={{
          [`& .${drawerClasses.paper}`]: {
            maxHeight: "calc(90vh)",
          },
        }}
        safeAreas={safeAreas}
      >
        <Box p={2}>
          <Typography variant="h4" textAlign="center">
            {t("routine.challenge.inputs.skill.label")}
          </Typography>
        </Box>

        <Box my={1}>
          <Divider />
        </Box>

        <Box>
          <Filter
            options={
              mySkillOptions.length === 0
                ? [
                    {
                      id: FilterValue.All,
                      label: t("routine.challenge.inputs.skill.all"),
                      count: orderedNeeds.length,
                    },
                  ]
                : [
                    {
                      id: FilterValue.Mine,
                      label: t("journal.notes.form.subject.filter.mine"),
                      count: mySkillOptions.length,
                    },
                    {
                      id: FilterValue.All,
                      label: t("routine.challenge.inputs.skill.all"),
                      count: orderedNeeds.length,
                    },
                  ]
            }
            value={filter}
            onChange={setFilter}
            single
            sx={{
              width: "calc(100vw + 1em)",
            }}
          />
        </Box>

        {filter.includes(FilterValue.Mine) ? (
          <Grid container sx={{ overflowY: "auto" }}>
            {orderedMySkillOptions.map((skill) => (
              <Grid item xs={12} key={skill}>
                <Box px={2} display="flex" alignItems="center">
                  <Typography
                    flexGrow={10}
                    sx={{
                      label: {
                        paddingY: 1.25,
                        display: "block",
                        width: "100%",
                      },
                    }}
                  >
                    <label htmlFor={`skill-check-${skill}`}>
                      {t(`settings.child.needs.needs.${skill}`)}
                    </label>
                  </Typography>
                  <Radio
                    id={`skill-check-${skill}`}
                    value={skill}
                    checked={selectedSkill === skill}
                    color="secondary"
                    onChange={(e) => onSkillPicked(skill, e.target.checked)}
                  />
                </Box>
                <Divider />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Grid
            container
            sx={{ overflowY: "auto", ...SharedStyles.Layout.noScroll }}
          >
            {orderedNeeds.map((skill) => (
              <Grid item xs={12} key={skill}>
                <Box px={2} display="flex" alignItems="center">
                  <Typography
                    flexGrow={10}
                    sx={{
                      label: {
                        paddingY: 1.25,
                        display: "block",
                        width: "100%",
                      },
                    }}
                  >
                    <label htmlFor={`skill-check-${skill}`}>
                      {t(`settings.child.needs.needs.${skill}`)}
                    </label>
                  </Typography>
                  <Radio
                    id={`skill-check-${skill}`}
                    value={skill}
                    checked={selectedSkill === skill}
                    color="secondary"
                    onChange={(e) => onSkillPicked(skill, e.target.checked)}
                  />
                </Box>
                <Divider />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Box px={2} display="flex" alignItems="center">
                <Typography
                  flexGrow={10}
                  sx={{
                    label: {
                      display: "block",
                      width: "100%",
                    },
                  }}
                >
                  <label htmlFor={`skill-check-other`}>
                    {t("settings.child.needs.needs.other")}
                  </label>
                </Typography>
                <Radio
                  id={`skill-check-other`}
                  value="other"
                  checked={selectedSkill === "other"}
                  color="secondary"
                  onChange={(e) => onSkillPicked("other", e.target.checked)}
                />
              </Box>
            </Grid>
          </Grid>
        )}
        <Box mt="auto" px={2} py={3} display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="secondary"
            onClick={onConfirm}
            disabled={!selectedSkill}
            data-cy="skill-picker-confirm"
          >
            {t("general.actions.confirm")}
          </Button>
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default SkillPicker;
