import { Box } from "@mui/material";
import { Loader } from "@neurosolutionsgroup/components";
import BackgroundAssets from "assets/background";
import useParameters from "common/hooks/Parameters/useParameters";
import React from "react";

interface FullScreenLoaderProps {
  progress?: number;
}

const FullScreenLoader: React.FC<FullScreenLoaderProps> = () => {
  const { isHalloweenVersion } = useParameters();

  return (
    <Box
      sx={(theme) => ({
        "position": "fixed",
        "top": 0,
        "left": 0,
        "right": 0,
        "bottom": 0,
        "zIndex": 10000,
        "backgroundColor": isHalloweenVersion
          ? "#1a2634"
          : theme.palette.primary.main,
        "backgroundImage": `url(${
          isHalloweenVersion
            ? BackgroundAssets.HalloweenBackground
            : BackgroundAssets.RedBackground
        })`,
        "backgroundSize": "cover",
        "backgroundRepeat": "no-repeat",
        "display": "flex",
        "flexDirection": "column",
        "justifyContent": "center",
        "alignItems": "center",
        "padding": theme.spacing(2),
        "& .loader__background": {
          backgroundColor: "#000",
          opacity: 0.4,
        },
      })}
      data-cy="fullscreen-loader"
    >
      <Box mb="auto">
        <Loader />
      </Box>
    </Box>
  );
};

export default FullScreenLoader;
