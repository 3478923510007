import { FTUEFlow } from "models";
import { PropsWithChildren } from "react";
import useFTUE from "useFTUE";
import FTUEHighlightComponent from "./FTUEHighlightComponent";

export interface FTUEHighlightProps extends PropsWithChildren {
  flow?: FTUEFlow;
  step?: string;
  disabled?: boolean;
}

const FTUEHighlight = ({
  flow,
  step,
  disabled = false,
  ...props
}: FTUEHighlightProps): JSX.Element => {
  const {
    selectors: { progress },
  } = useFTUE();

  if (!flow || !step) {
    return <>{props.children}</>;
  }

  const active = progress[flow].progress === step && !disabled;

  return (
    <FTUEHighlightComponent active={active}>
      {props.children}
    </FTUEHighlightComponent>
  );
};

export default FTUEHighlight;
