import axios from "axios";

const createTicket = async (
  comment: string,
  isPurchaseFeedback: boolean,
  email?: string,
  name?: string,
  test?: boolean
): Promise<void> => {
  const URL = "https://kairosgame.zendesk.com/api/v2/tickets.json";

  const body = {
    ticket: {
      comment: {
        body: comment,
      },
      subject:
        "In App Review Feedback - " + isPurchaseFeedback
          ? "30 days after purchase."
          : "10 days into free trial." + test
          ? " [TEST]"
          : "",
      tags: ["in-app-feedback", ...(test ? ["test"] : [])],
      requester: {
        email,
        name,
      },
    },
  };

  const headers = {
    "Content-Type": "application/json",
    "Authorization":
      "Basic ZWdhcmNlYXVAbmV1cm9zb2x1dGlvbnNncm91cC5jb20vdG9rZW46MVpQbXVHWElFRVBkWmVDNmR2a2NZUDNQVlg0N0JKbVJldXJKU1g3ag==",
  };

  return axios.post(URL, body, { headers });
};

const ZendeskService = {
  createTicket,
};

export default ZendeskService;
