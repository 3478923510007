import clsx from "clsx";
import { Drawer } from "common/Components";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { TextInput } from "common/Components";
import React, { useCallback, useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import AMPMSelector from "./AMPMSelector/AMPMSelector";
import { parseInt } from "lodash";
import { useTranslation } from "react-i18next";
import { CypressProps, Language } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";

interface TimePickerClasses {
  root?: string;
  drawer?: string;
}

interface TimePickerProps extends CypressProps {
  time: number | null;
  setTime: (value: number) => void;
  label?: string;
  language: Language;
  min?: number;
  max?: number;
  classes?: TimePickerClasses;
  showLabel?: boolean;
  validateTime?: (time: number) => string | undefined;
}

const TimePicker: React.FC<TimePickerProps> = ({
  time,
  setTime,
  label,
  language,
  min,
  max,
  classes,
  showLabel = true,
  validateTime,
  ...props
}) => {
  const ROOT_CLASS = "time-picker";
  const { t } = useTranslation();

  const getInitialIsAM = useCallback((): boolean => {
    const initialHours = time ? Math.floor(time / 3600) : new Date().getHours();

    if (language === "en" && initialHours >= 12) {
      return false;
    } else {
      return true;
    }
  }, [language, time]);

  /**
   * Calculate the minutes to display initially,
   * either from the time passed into the component or the current time.
   */
  const getInitialMinutes = useCallback((): string => {
    return (
      time ? Math.floor((time % 3600) / 60) : new Date().getMinutes()
    ).toFixed(0);
  }, [time]);

  /**
   * Calculate the hours to display initially,
   * either from the time passed into the component or the current time.
   */
  const getInitialHours = useCallback((): string => {
    let initialHours = time ? Math.floor(time / 3600) : new Date().getHours();

    if (language === "en" && initialHours >= 12) {
      initialHours = initialHours - 12;
    }

    return initialHours > 0 ? initialHours.toFixed(0) : "12";
  }, [language, time]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  // Setup inital hours and mins from state.
  const [minutes, setMinutes] = useState<string>(getInitialMinutes());
  const [hours, setHours] = useState<string>(getInitialHours());
  const [isAM, setIsAM] = useState(getInitialIsAM());
  const [hoursError, setHoursError] = useState(false);
  const [minutesError, setMinutesError] = useState(false);
  const [validationError, setValidationError] = useState(false);

  useEffect(() => {
    setHours(getInitialHours());
    setMinutes(getInitialMinutes());
    setIsAM(getInitialIsAM());
  }, [time]);

  useEffect(() => {
    // If language changes to english or time changes, calculate if new time is AM.
    if (language === "en" && time) {
      setIsAM(Math.floor(time / 3600) < 12);
    }
  }, [language, time]);

  /**
   * Set new time on draw close via confirmation.
   */
  const onConfirm = () => {
    const hoursNumber =
      language === "en" && hours === "12" ? 0 : parseInt(hours);
    const minutesNumber = parseInt(minutes);

    setTime(hoursNumber * 3600 + minutesNumber * 60 + (isAM ? 0 : 43200));
    setDrawerOpen(false);
  };

  const resetTime = () => {
    setMinutes(getInitialMinutes());
    setHours(getInitialHours());
    if (language === "en" && time) {
      setIsAM(Math.floor(time / 3600) < 12);
    }
  };

  /**
   * Generate the formatted, localized time to display.
   */
  const getDisplayValue = useCallback((): string => {
    if (time) {
      return Tools.Time.Strings.localizedTimeFromSeconds(time, language);
    } else {
      return "--:--";
    }
  }, [language, time]);

  /**
   * Validate time values.
   */
  useEffect(() => {
    let hoursNum = parseInt(hours);
    const minsNum = parseInt(minutes);

    if (isNaN(hoursNum)) {
      setHoursError(true);
    } else if (isNaN(minsNum)) {
      setMinutesError(true);
    } else {
      const isEnAfternoon = language === "en" && !isAM;

      if (language === "en") {
        if (hoursNum >= 1 && hoursNum <= 12) {
          setHoursError(false);
        } else {
          setHoursError(true);
        }
      } else {
        if (hoursNum >= 0 && hoursNum <= 23) {
          setHoursError(false);
        } else {
          setHoursError(true);
        }
      }

      if (minsNum >= 0 && minsNum <= 59) {
        setMinutesError(false);
      } else {
        setMinutesError(true);
      }

      const minTime = min ?? 0;
      const maxTime = max ?? 24 * 3600;

      if (language === "en") {
        hoursNum = hoursNum === 12 ? 0 : hoursNum;
      }

      const timeSecs = isEnAfternoon
        ? hoursNum * 3600 + 12 * 3600
        : hoursNum * 3600 + minsNum * 60;

      if (timeSecs < minTime || timeSecs > maxTime) {
        setValidationError(true);
      } else {
        setValidationError(false);
      }
    }
  }, [minutes, hours, language, isAM]);

  const setHoursWithValidation = (newHours: string) => {
    if (
      (newHours.length <= 2 && newHours === "") ||
      parseInt(newHours) <= (language === "en" ? 12 : 23)
    ) {
      setHours(newHours);
    }
  };

  const setMinutesWithValidation = (newMinutes: string) => {
    if (newMinutes.length <= 2) {
      setMinutes(newMinutes);
    }
  };

  const externalValidation = useCallback((): string | undefined => {
    if (!validateTime) {
      return undefined;
    }

    const hoursNumber =
      language === "en" && hours === "12" ? 0 : parseInt(hours);

    return validateTime(
      hoursNumber * 60 * 60 + parseInt(minutes) * 60 + (isAM ? 0 : 12 * 60 * 60)
    );
  }, [hours, minutes, validateTime, isAM]);

  return (
    <div className={clsx(ROOT_CLASS, classes?.root)}>
      {showLabel && <span className={ROOT_CLASS + "__label"}>{label}</span>}
      <Button
        data-cy={props["data-cy"]}
        onClick={() => setDrawerOpen(true)}
        variant="text"
        color="secondary"
        className={ROOT_CLASS + "__button"}
      >
        {getDisplayValue()}
      </Button>
      <Drawer
        anchor="bottom"
        open={drawerOpen}
        onClose={() => {
          resetTime();
          setDrawerOpen(false);
        }}
        classes={{
          paper: clsx(ROOT_CLASS + "__drawer", classes?.drawer),
        }}
      >
        <DrawerSection variant="header">
          <Typography variant="h4">{label}</Typography>
        </DrawerSection>

        {externalValidation() ? (
          <Box px={2} pb={1}>
            <Typography
              color="primary"
              textAlign="center"
              mb={1}
              fontSize="0.9rem"
            >
              {externalValidation()}
            </Typography>
          </Box>
        ) : null}
        <Box px={2} pb={2}>
          <Grid
            container
            direction="column"
            className={ROOT_CLASS + "__drawer-body"}
          >
            <Box mb={4}>
              {language === "en" ? (
                <Grid item xs={12}>
                  <Box mb={2}>
                    <AMPMSelector isAM={isAM} setIsAM={setIsAM} />
                  </Box>
                </Grid>
              ) : null}
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  spacing={2}
                >
                  <Grid item xs={6} justifyContent="center">
                    <h4>{t("general.time.hours")}</h4>
                    <TextInput
                      data-cy="time-hour-input"
                      type="text"
                      inputProps={{
                        pattern: "[0-9]*",
                      }}
                      value={hours}
                      onChange={(e) =>
                        setHoursWithValidation(e.currentTarget.value)
                      }
                      error={hoursError || validationError}
                      className={ROOT_CLASS + "__input"}
                      onFocus={() => setHours("")}
                    />
                  </Grid>
                  <Grid item xs={6} justifyContent="center">
                    <h4>{t("general.time.minutes")}</h4>
                    <TextInput
                      data-cy="time-minutes-input"
                      type="text"
                      inputProps={{
                        pattern: "[0-9]*",
                      }}
                      value={minutes}
                      onChange={(e) =>
                        setMinutesWithValidation(e.currentTarget.value)
                      }
                      error={minutesError || validationError}
                      className={ROOT_CLASS + "__input"}
                      onFocus={() => setMinutes("")}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box m={"auto"}>
              <Button
                data-cy="time-confirmation"
                variant="contained"
                color="secondary"
                onClick={onConfirm}
                disabled={hoursError || minutesError || validationError}
              >
                {t("general.actions.confirm")}
              </Button>
            </Box>
          </Grid>
        </Box>
      </Drawer>
    </div>
  );
};

export default TimePicker;
