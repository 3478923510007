import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const PictureIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="picture" {...props}>
      <path
        d="M 3.7781 2 C 3.1854 2.5927 2.5927 3.1854 2 3.7781 c 0 4.1479 0 8.2958 0 12.4438 c 0.5927 0.5927 1.1854 1.1854 1.7781 1.7781 c 4.1479 0 8.2958 0 12.4438 0 c 0.5927 -0.5927 1.1854 -1.1854 1.7781 -1.7781 c 0 -4.1479 0 -8.2958 0 -12.4438 C 17.4073 3.1854 16.8146 2.5927 16.2219 2 c -4.1479 0 -8.2958 0 -12.4438 0 z "
        fill={color}
      />
      <path
        d="M 0 1.7781 c 4.1479 0 8.2958 0 12.4438 0 c 0 4.1479 0 8.2958 0 12.4438 c -4.1479 0 -8.2958 0 -12.4438 0 c 0 -4.1479 0 -8.2958 0 -12.4438 z M 4.6667 14.5689 C 5.0459 13.7659 5.4252 12.963 5.8044 12.16 C 6.3141 11.8193 6.8237 11.4785 7.3333 11.1378 C 7.9674 11.9052 8.6015 12.6726 9.2356 13.44 C 9.597 12.6726 9.9585 11.9052 10.32 11.1378 C 10.8474 10.7585 11.3748 10.3793 11.9022 10 c 0.9511 0.7615 1.9022 1.523 2.8533 2.2844 c 0.1926 0.7615 0.3852 1.523 0.5778 2.2845 c -3.5556 0 -7.1111 0 -10.6667 0 z"
        fill={color}
      />
    </BaseIcon>
  );
};

export default PictureIcon;
