import { ButtonBase, useTheme } from "@mui/material";
import React from "react";
import Icons from "../Icons";
import { SharedStyles } from "@neurosolutionsgroup/themes";

interface ChatScrollButtonProps {
  onClick: VoidFunction;
  show: boolean;
}

const ChatScrollButton = ({
  onClick,
  show,
}: ChatScrollButtonProps): JSX.Element => {
  const theme = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      sx={(theme) => ({
        display: show ? "flex" : "none",
        position: "absolute",
        bottom: "6px",
        left: "calc(50% - 1rem)",
        width: "2rem",
        height: "2rem",
        boxSizing: "border-box",
        backgroundColor: "#fff",
        borderRadius: "1rem",
        borderColor: theme.palette.secondary.main,
        borderWidth: "3px",
        borderStyle: "solid",
        svg: {
          width: "1.2rem",
          height: "1.2rem",
        },
        boxShadow: SharedStyles.Shadows.defaultShadow,
      })}
    >
      <Icons.ArrowIcon
        arrowDirection="down"
        arrowType="full"
        color={theme.palette.secondary.main}
      />
    </ButtonBase>
  );
};

export default ChatScrollButton;
