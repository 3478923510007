import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link as MuiLink,
  Typography,
} from "@mui/material";
import { PageHeader } from "common/Components/Headers";
import { Validation, Routines, Medication, Dashboard } from "pages";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useChildren from "common/hooks/children/useChildren";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import { Coach, useCoach } from "common/hooks/messaging/Coach";
import { ErrorBoundary } from "@sentry/react";
import useParameters from "common/hooks/Parameters/useParameters";
import useAuth from "common/hooks/auth/useAuth";
import {
  FTUEFlowDefinitions,
  JournalProgress,
  FTUEFlow,
  FTUEHighlight,
} from "@neurosolutionsgroup/webviews-ftue";
import { ChatPage, Loader } from "@neurosolutionsgroup/components";
import NPSController from "common/FTUE/NPS/NPSController";
import useUserProfile from "common/hooks/account/useUserProfile";
import { SAFE_AREAS } from "stylesheets";
import useNotifications from "common/hooks/notifications/useNotifications";
import InAppNotificationsManager from "common/InAppNotifications/InAppNotificationsManager";
import { useAppInitializationContext } from "common/hooks/AppInitializationContext";
import useChallenges from "common/hooks/challenges/useChallenges";
import {
  ExternalLink,
  UniWebViewActions,
} from "common/hooks/Parameters/UniWebViewActions";
import {
  ConfigString,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import FullScreenLoader from "common/Components/Loader/FullScreenLoader";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { NotificationPage } from "@neurosolutionsgroup/webviews-pages";
import {
  NotificationCategory,
  NotificationCategorySettings,
} from "@neurosolutionsgroup/models";
import {
  ChatbotOpened,
  NotificationsAccepted,
  NotificationsLaterClicked,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import ChatBotButton from "common/Components/ChatBot/ChatBotButton";
import CoachAssets from "assets/coach";
import useChatBot from "common/Components/ChatBot/useChatBot";
import Journal from "pages/Journal/Journal";
import useSubscription from "common/hooks/subscription/useSubscription";
import DowngradeDialogController from "common/hooks/subscription/DowngradeDialogController";
import { AppBottomNavigator } from "common/Components";
import useRoutines from "common/hooks/routines/useRoutines";

const Guide = React.lazy(() => import("./pages/Guide/Guide"));
const Settings = React.lazy(() => import("./pages/Settings/Settings"));

interface ParentSectionNavigatorProps {
  onBack: () => void;
}

const ParentSectionNavigator: React.FC<ParentSectionNavigatorProps> = ({
  onBack,
}) => {
  // App data.
  const { handleEvent } = useAnalytics();
  const { canDisplayChatbot, loading, messages, onChatSubmit } = useChatBot();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { sendMessageToUnity, linkHandler, openPrivacyPolicy, version } =
    useParameters();
  const { getRemoteConfigValue } = useRemoteConfig();

  // Messaging.
  const { onTaskValidationAvailable } = useCoach();

  // User data.
  const { dataInitiated } = useAppInitializationContext();
  const { user, logOut } = useAuth();
  const {
    selectors: { totalChallengesToValidate },
  } = useChallenges();
  const {
    selectors: {
      selectedChild,
      tasksToValidate,
      childIds,
      anyChildHasTaskTovalidate,
    },
  } = useChildren();
  const {
    selectors: { anyChildHasObservation },
  } = useFollowUp();
  const {
    ftueNotificationOpen,
    setFtueNotificationOpen,
    setOptInLastSeen,
    updateSettings,
  } = useNotifications();
  const {
    selectors: { routineIds },
  } = useRoutines();
  const { onPremiumFeatureClick, permissions } = useSubscription();
  const {
    selectors: { userProfile },
    actions: { onUserRegistration },
  } = useUserProfile();

  const [ftueLoadingComplete, setFtueLoadingComplete] = useState(false);
  const [openChatBot, setOpenChatBot] = useState(false);

  const onChatBotClick = () => {
    setOpenChatBot(true);

    const event: ChatbotOpened = {
      name: "Chatbot Opened",
    };

    handleEvent(event);
  };

  useEffect(() => {
    if (dataInitiated && userProfile) {
      // TODO: TFS-1476 - Check if default child flag active.
      if (
        childIds.length === 0 &&
        !userProfile.masterSettings?.settings.creationVersion
      ) {
        onUserRegistration(version);
      }
      setFtueLoadingComplete(true);
    }
  }, [userProfile, childIds, dataInitiated]);

  // Objectives screen should be the default until an objective has been created.
  useEffect(() => {
    if (dataInitiated && routineIds.length === 0) {
      navigate("/objectives");
    }
  }, [dataInitiated]);

  useEffect(() => {
    if (
      !!selectedChild &&
      !!tasksToValidate[selectedChild] &&
      tasksToValidate[selectedChild].length !== 0
    ) {
      onTaskValidationAvailable();
    }
  }, [tasksToValidate]);

  const showValidationsBadge = useCallback((): boolean => {
    return (
      !!selectedChild &&
      (anyChildHasTaskTovalidate ||
        anyChildHasObservation ||
        totalChallengesToValidate > 0)
    );
  }, [
    selectedChild,
    tasksToValidate,
    anyChildHasObservation,
    totalChallengesToValidate,
  ]);

  const onErrorBoundaryClose = () => {
    sendMessageToUnity(UniWebViewActions.Close);
  };

  const onLogOut = async () => {
    await logOut();
  };

  const saveNotification = async (wantNotification: boolean) => {
    setOptInLastSeen();

    if (wantNotification) {
      const event: NotificationsAccepted = {
        name: "Notifications Accepted",
      };

      handleEvent(event);
    } else {
      const event: NotificationsLaterClicked = {
        name: "Notifications Later Clicked",
      };

      handleEvent(event);
    }

    const settings: NotificationCategorySettings = {
      [NotificationCategory.Routine]: wantNotification,
      [NotificationCategory.General]: wantNotification,
      [NotificationCategory.Validation]: wantNotification,
      [NotificationCategory.Observation]: wantNotification,
      [NotificationCategory.Coach]: wantNotification,
      [NotificationCategory.News]: wantNotification,
      [NotificationCategory.Promotion]: wantNotification,
    };

    // Don't block the user if error.
    try {
      await updateSettings(wantNotification, settings);
    } finally {
      setFtueNotificationOpen(false);
    }
  };

  return (
    <ErrorBoundary
      fallback={
        // TODO: this could be a seperate component
        <Dialog open={true} onClose={() => onErrorBoundaryClose()}>
          <DialogTitle>{t("navigation.error.title")}</DialogTitle>
          <DialogContent>
            <Typography mb={1} textAlign="center">
              {t("navigation.error.notify")}
            </Typography>
            <Typography textAlign="center">
              {t("navigation.error.support")}&nbsp;
              <MuiLink
                onClick={() => {
                  linkHandler(
                    ExternalLink.Support,
                    getRemoteConfigValue(ConfigString.SupportLink).asString()
                  );
                }}
              >
                {t("navigation.error.supportLink")}
              </MuiLink>
            </Typography>
          </DialogContent>
          <DialogActions>
            {user ? (
              <Button onClick={onLogOut}>{t("general.actions.logout")}</Button>
            ) : null}
            <Button
              onClick={onErrorBoundaryClose}
              variant="contained"
              color="secondary"
            >
              {t("navigation.error.back")}
            </Button>
          </DialogActions>
        </Dialog>
      }
    >
      {!(user && ftueLoadingComplete && dataInitiated) ? (
        <FullScreenLoader />
      ) : null}
      {user && ftueLoadingComplete && dataInitiated ? (
        <Box
          id="parent-section-navigator"
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            flexDirection: "column",
            paddingTop: SAFE_AREAS.top,
            backgroundColor: "primary.dark",
          }}
        >
          <NotificationPage
            open={ftueNotificationOpen}
            onAccept={() => saveNotification(true)}
            onRefuse={() => saveNotification(false)}
            safeAreas={SAFE_AREAS}
          />
          <FTUEFlowDefinitions.ValidationFTUEFlow.Screen
            safeAreas={SAFE_AREAS}
          />
          <FTUEFlowDefinitions.ObservationFTUEFlow.Screen
            safeAreas={SAFE_AREAS}
            language={language}
          />

          <DowngradeDialogController />
          <NPSController />
          <Coach />
          <PageHeader onClickLeft={onBack} shadow />
          <InAppNotificationsManager />
          <Routes>
            <Route path="/follow-ups" element={<Validation />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/journal" element={<Journal />} />
            <Route path="/objectives" element={<Routines />} />
            <Route path="/medication" element={<Medication />} />
            <Route
              path="/guide"
              element={
                <Suspense fallback={<Loader />}>
                  <Guide />
                </Suspense>
              }
            />
            <Route
              path="/settings"
              element={
                <Suspense fallback={<Loader />}>
                  <Settings />
                </Suspense>
              }
            />
            <Route path="/*" element={<Navigate to="/dashboard" />} />
          </Routes>
          <FTUEFlowDefinitions.ValidationFTUEFlow.Hints.ValidationNav>
            <FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationNav>
              <FTUEHighlight
                flow={FTUEFlow.Journal}
                step={JournalProgress.None}
              >
                <AppBottomNavigator
                  showFollowUpsBadge={showValidationsBadge()}
                />
              </FTUEHighlight>
            </FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationNav>
          </FTUEFlowDefinitions.ValidationFTUEFlow.Hints.ValidationNav>
          {canDisplayChatbot ? (
            <ChatBotButton
              onClick={onChatBotClick}
              locked={!permissions.chatbot}
              onLockClick={() => onPremiumFeatureClick(PremiumFeature.Chatbot)}
            />
          ) : null}
          <ChatPage
            open={openChatBot}
            onBack={() => setOpenChatBot(false)}
            onPrivacyClick={() => openPrivacyPolicy(language)}
            onSupportClick={() =>
              linkHandler(
                ExternalLink.Support,
                getRemoteConfigValue(ConfigString.SupportLink).asString()
              )
            }
            messages={messages}
            onChatSubmit={onChatSubmit}
            loading={loading}
            avatarImgSrc={CoachAssets.ChatAvatar}
            safeAreas={SAFE_AREAS}
          />
        </Box>
      ) : null}
    </ErrorBoundary>
  );
};

export default ParentSectionNavigator;
