import { CustomWindow } from "custom.window";

declare let window: CustomWindow;

export const preloadAssets = (assets: string[]): void => {
  const images = assets.map((asset) => {
    const img = new Image();

    img.src = asset;

    return img;
  });

  window.preloadedAssets = [...(window.preloadedAssets ?? []), ...images];
};
