import { JournalProgress } from "./JournalProgress";

const calculateProgress = (step: JournalProgress): number => {
  return (
    Object.values(JournalProgress).findIndex((v) => v === step.toString()) - 2
  );
};

const getProgress = (recordIds: string[]): JournalProgress => {
  let latestJournalStep = JournalProgress.None;

  if (recordIds.includes(JournalProgress.JournalFTUEFinished.toString())) {
    latestJournalStep = JournalProgress.JournalFTUEFinished;
  } else {
    Object.values(JournalProgress).forEach((v) => {
      if (recordIds.includes(v)) {
        latestJournalStep = v;
      }
    });
  }

  const stepsToRollback = [JournalProgress.Intro, JournalProgress.DaysPicker];

  if (stepsToRollback.includes(latestJournalStep)) {
    latestJournalStep = JournalProgress.Later;
  }

  return latestJournalStep;
};

const isComplete = (progress: JournalProgress): boolean => {
  return progress === JournalProgress.JournalFTUEFinished;
};

const FTUEJournalLogic = {
  calculateProgress,
  getProgress,
  isComplete,
};

export default FTUEJournalLogic;
