import { Box, Grid } from "@mui/material";
import {
  Challenge,
  ChallengeV2,
  FirestoreChallenge,
} from "@neurosolutionsgroup/models";
import React from "react";
import DynamismAssets from "assets/dynamism";
import { useTranslation } from "react-i18next";
import ValidationSection from "../ValidationSection";
import ChallengeValidationCard from "./ChallengeValidationCard";
import { Tools } from "@neurosolutionsgroup/tools";
import ChallengeValidationCardV2Wrapper from "./ChallengeValidationCardV2Wrapper";

export interface ChallengeSectionProps {
  showTitle: boolean;
  challenges: FirestoreChallenge[];
  onChallengeValidationComplete: (challenge: FirestoreChallenge) => void;
  onChallengeV2ValidationComplete: (
    challenge: FirestoreChallenge,
    status: "failed" | "partial" | "successful" | "unfinished"
  ) => void;
}

const ChallengeSection: React.FC<ChallengeSectionProps> = ({
  showTitle,
  challenges,
  onChallengeValidationComplete,
  onChallengeV2ValidationComplete,
}) => {
  const { t } = useTranslation();

  return (
    <ValidationSection
      title={t("validation.challenge.title")}
      showTitle={showTitle}
    >
      {challenges.length > 0 ? (
        <Box data-cy="challenge-validation-list">
          {challenges.map((challenge) => (
            <Box mb={1} key={challenge.id}>
              {Tools.Data.Challenges.V2.isChallengeV2(challenge) ? (
                <ChallengeValidationCardV2Wrapper
                  challenge={challenge as ChallengeV2}
                  onChallengeCompleted={onChallengeV2ValidationComplete}
                />
              ) : (
                <ChallengeValidationCard
                  challenge={challenge as Challenge}
                  onChallengeValidationComplete={(challenge) => {
                    onChallengeValidationComplete(challenge);
                  }}
                />
              )}
            </Box>
          ))}
        </Box>
      ) : (
        <ChallengeEmptyState />
      )}
    </ValidationSection>
  );
};
export default ChallengeSection;

const ChallengeEmptyState: React.FC = () => {
  return (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        img: {
          height: "150px",
          maxHeight: "100%",
        },
        height: "150px",
        transitionProperty: "height",
        transitionDelay: "1s",
        transitionDuration: "0s",
      }}
    >
      <img src={DynamismAssets.Routine_Validation} alt="" />
    </Grid>
  );
};
