import { FirestoreUserDoc, Language } from "@neurosolutionsgroup/models";
import Time from "../../Time";

const NAME = "FirstName";

export const constructUserDoc = (
  tenantId: string,
  email: string,
  creation: number,
  language: Language = "en",
  extraCoachNotifs?: Record<string, unknown>[],
  currentDate?: number,
  highestVersion?: string,
  hasDoneFTUE = true,
  isSubscribed = true
): FirestoreUserDoc => ({
  tenantId,
  email,
  firstName: NAME,
  lastName: null,
  playFabId: null,
  city: null,
  language,
  isSubscribed,
  referralData: null,
  latestReceipt: null,
  lastDowngradeExecution: null,
  masterSettings: {
    settings: {
      FreeTrialStartedDate: creation,
      highestVersion: highestVersion ?? "1.0.0",
      lastTermsAccepted: creation,
      nip: "0ffe1abd1a08215353c233d6e009613e95eec4253832a761af28ff37ac5a150c",
      sounds: false,
      language:
        language === "en" ? "English" : language === "fr" ? "French" : "German",
      CoachNotifications: JSON.stringify({
        coachNotifications: hasDoneFTUE
          ? [
              {
                id: "FTUE_FirstRoutine_Finished",
                lastTime: creation,
              },
              {
                id: "FTUE_Dashboard_Finished",
                lastTime: creation,
              },
              {
                id: "FTUE_Dashboard_Prescriptions_Finished",
                lastTime: creation,
              },
              {
                id: "FTUE_Journal_Finished",
                lastTime: creation,
              },
              {
                id: "FTUE_Observations_Finished",
                lastTime: creation,
              },
              {
                id: "FTUE_Prescription_Finished",
                lastTime: creation,
              },
              {
                id: "FTUE_Validation_Finished",
                lastTime: creation,
              },
              {
                id: "ValidationReminder",
                lastTime: currentDate ?? Time.Dates.getTimeStamp(),
                occurrences: 3,
              },
              ...(extraCoachNotifs ?? []),
            ]
          : extraCoachNotifs ?? [],
      }),
      notificationSettings: {
        globalOptIn: true,
        optInLastSeen: creation,
      },
    },
    version: 0,
  },
  organisations: null,
});
