import { ChallengeV2 } from "@neurosolutionsgroup/models";

export interface ChallengeCompleteResult {
  active: boolean;
  successful: boolean | null;
}

const isChallengeComplete = (
  challenge: ChallengeV2,
  provisional = false
): ChallengeCompleteResult => {
  let allWeeksComplete = true;
  let allWeeksSuccessful = true;

  for (let weekIndex = 1; weekIndex <= challenge.duration; weekIndex++) {
    const completedHistoryCount = challenge.history.filter(
      (instance) =>
        instance.week === weekIndex &&
        ((instance.parentStatus === true && instance.canBeRetried !== true) ||
          (provisional
            ? instance.parentStatus === null && instance.childStatus === true
            : false))
    ).length;

    const failedFinalHistoryCount = challenge.history.filter(
      (instance) =>
        instance.week === weekIndex &&
        instance.parentStatus === false &&
        instance.canBeRetried !== true
    ).length;

    if (completedHistoryCount + failedFinalHistoryCount < challenge.frequency) {
      allWeeksComplete = false;
    }

    if (completedHistoryCount < challenge.frequency) {
      allWeeksSuccessful = false;
    }
  }

  return {
    active: !allWeeksComplete,
    successful: provisional
      ? null
      : allWeeksComplete
      ? allWeeksSuccessful
      : null,
  };
};

export default isChallengeComplete;
