import { Box } from "@mui/material";
import { Prescription } from "@neurosolutionsgroup/models";
import { StickyButtons } from "common/Components";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import React, { useState } from "react";
import CorrectPrescription from "./CorrectPrescription";
import CorrectSideEffects from "./CorrectSideEffects";
import {
  PrescriptionCard,
  SideEffectsCard,
} from "@neurosolutionsgroup/webviews-pages";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import CoachAssets from "assets/coach";
import MiscAssets from "assets/misc";
import useMedication from "common/hooks/medications/useMedication";
import { SAFE_AREAS } from "stylesheets";

export interface ConfirmationFormProps {
  childName: string;
  medicalChildId: string;
  prescriptions: Prescription[];
  setPrescriptions: (values: Prescription[]) => void;
  sideEffects: string[];
  setSideEffects: (values: string[]) => void;
  setTakesAnotherMedication: (value: boolean) => void;
  onSubmit: () => void;
  onCancel: () => void;
  onBack: () => void;
}

const ConfirmationForm: React.FC<ConfirmationFormProps> = ({
  childName,
  medicalChildId,
  prescriptions,
  setPrescriptions,
  sideEffects,
  setSideEffects,
  setTakesAnotherMedication,
  onSubmit,
  onCancel,
  onBack,
}) => {
  const { language } = useLanguage();

  const {
    selectors: { sideEffectById },
  } = useSideEffect();
  const {
    selectors: { medicationById },
  } = useMedication();

  const [showEditPrescription, setShowEditPrescription] =
    useState<boolean>(false);
  const [showEditSideEffect, setShowEditSideEffect] = useState<boolean>(false);

  return (
    <Box pb="4rem">
      <Box display="flex" flexDirection="column">
        <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.Confirmation
          handleOnNext={onSubmit}
          handleOnBack={onBack}
        >
          {medicationById ? (
            <PrescriptionCard
              childName={childName}
              prescriptions={prescriptions}
              onEdit={() => setShowEditPrescription(true)}
              language={language}
              medicationById={medicationById}
              safeAreas={SAFE_AREAS}
              infoIconImage={CoachAssets.CoachInfoHead}
              infoGraphImage={MiscAssets.FTUEPrescriptionGraphENFR}
            />
          ) : null}
          <SideEffectsCard
            language={language}
            sideEffects={sideEffects}
            sideEffectById={sideEffectById}
            onEdit={() => setShowEditSideEffect(true)}
            infoIconImage={CoachAssets.CoachInfoHead}
            infoGraphImage={MiscAssets.FTUEPrescriptionGraph}
            safeAreas={SAFE_AREAS}
          />
        </FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.Confirmation>
      </Box>
      <CorrectPrescription
        open={showEditPrescription}
        onConfirm={(p, tom) => {
          setPrescriptions([p]);
          setTakesAnotherMedication(tom);
        }}
        onClose={() => setShowEditPrescription(false)}
        initialPrescription={prescriptions[0]}
        takeOtherMedication={false}
        medicalChildId={medicalChildId}
      />
      <CorrectSideEffects
        open={showEditSideEffect}
        onConfirm={setSideEffects}
        onClose={() => setShowEditSideEffect(false)}
        sideEffects={sideEffects}
      />
      <StickyButtons
        onCancel={onCancel}
        onConfirm={onSubmit}
        disabled={false}
      />
    </Box>
  );
};
export default ConfirmationForm;
