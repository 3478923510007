import { Box, Grid, Typography } from "@mui/material";
import {
  Challenge,
  FirestoreChallenge,
  GamerChild,
} from "@neurosolutionsgroup/models";
import { Card, CharacterCount, TextInput } from "common/Components";
import { CardMenuElement } from "common/Components/Card/Card";
import useChildren from "common/hooks/children/useChildren";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ChallengeDetails from "./ChallengeDetails";
import { Dialogs, Icons } from "@neurosolutionsgroup/components";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { Tools } from "@neurosolutionsgroup/tools";
import useChallenges from "common/hooks/challenges/useChallenges";
import ChallengeProgress from "./ChallengeProgress";
import ChildIcons from "assets/child-icons";
import useTasks from "common/hooks/routines/useTasks";

interface ChallengeCardProps {
  challenge: FirestoreChallenge;
  onDelete?: VoidFunction;
}

const ChallengeCard = ({
  challenge,
  onDelete,
}: ChallengeCardProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [detailsOpen, setDetailsOpen] = useState<boolean>(false);
  const [rejectionCommentOpen, setRejectionCommentOpen] =
    useState<boolean>(false);
  const [rejectionComment, setRejectionComment] = useState("");

  const REJECTION_COMMENT_MAXLENGTH = 250;

  const progress: {
    progress: number;
    total: number;
    finished: boolean;
  } = useMemo(() => {
    const completed = challenge.history.filter(
      (history) => history.parentStatus === true
    ).length;

    return {
      progress: completed,
      total: challenge.occurrences ?? 1,
      finished: challenge.successful !== null,
    };
  }, [challenge]);

  const {
    selectors: { childrenById },
  } = useChildren();

  const {
    actions: { approveChallenge, childValidation },
  } = useChallenges();

  const { iconManager } = useTasks().selectors;

  const madeByParent = !challenge.createdById;

  const toApprove =
    challenge.requiresApproval && challenge.approvalStatus === null;

  const days = challenge.days;

  const timeString =
    challenge.startTime && challenge.endTime
      ? Tools.Time.Strings.localizedTimePeriodFromSeconds(
          challenge.startTime,
          challenge.endTime,
          language
        )
      : null;

  const child: GamerChild | undefined = childrenById[challenge.childId];

  const onApproval = (approvalStatus: boolean) => {
    if (approvalStatus) {
      approveChallenge(challenge.id, approvalStatus);
    } else {
      setIsSelected(false);
      setRejectionCommentOpen(true);
    }
  };

  const onRejectionCommentComplete = () => {
    approveChallenge(challenge.id, false, rejectionComment ?? undefined);
  };

  const menuOptions = [
    <CardMenuElement
      text={t("general.information")}
      icon={<Icons.SearchIcon />}
      onClick={() => {
        setIsSelected(false);
        setDetailsOpen(true);
      }}
      data-cy="challenge-info-button"
    />,
    ...(madeByParent && onDelete
      ? [
          <CardMenuElement
            text={t("general.actions.delete")}
            icon={<Icons.DeleteIcon />}
            onClick={() => {
              setIsSelected(false);
              onDelete();
            }}
            data-cy="challenge-delete-button"
          />,
        ]
      : []),
    ...(toApprove
      ? [
          <CardMenuElement
            text={t("general.actions.approve")}
            icon={<Icons.CheckMarkIcon checkStyle="kairos" />}
            onClick={() => {
              setIsSelected(false);
              onApproval(true);
            }}
            data-cy="challenge-approve-button"
          />,
          <CardMenuElement
            text={t("general.actions.reject")}
            icon={<Icons.CloseIcon withCircle />}
            onClick={() => {
              setIsSelected(false);
              onApproval(false);
            }}
            data-cy="challenge-reject-button"
          />,
        ]
      : []),
    ...(Tools.Environment.isStaging()
      ? [
          <CardMenuElement
            text="Test - Validate as Child"
            icon={<Icons.CheckMarkIcon checkStyle="kairos" />}
            onClick={() => {
              childValidation(challenge, true);
              setIsSelected(false);
            }}
          />,
          <CardMenuElement
            text="Test - Validate as Child"
            icon={<Icons.CloseIcon withCircle />}
            onClick={() => {
              childValidation(challenge, false);
              setIsSelected(false);
            }}
          />,
        ]
      : []),
  ];

  return (
    <>
      <Card
        menuOpen={isSelected}
        setMenuOpen={setIsSelected}
        menuElements={menuOptions}
        data-cy="challenge-card"
      >
        <Grid container spacing={1}>
          {toApprove ? (
            <Grid item xs={12}>
              <Typography textAlign="center" color="primary" fontWeight="bold">
                {t("routine.challenge.approval.text", {
                  profName: challenge.createdByName,
                })}
              </Typography>
            </Grid>
          ) : null}
          {child ? (
            <Grid item xs={challenge.createdByName ? 6 : 12}>
              <Box
                display="flex"
                alignItems="center"
                sx={{
                  img: {
                    height: "2em",
                    marginRight: "0.5em",
                  },
                }}
              >
                <img
                  src={ChildIcons[child.icon]}
                  alt={`Child Icon ${child.icon}`}
                />
                <Typography
                  fontWeight="bold"
                  fontSize="0.9rem"
                  data-cy="challenge-card-child"
                >
                  {child.name}
                </Typography>
              </Box>
            </Grid>
          ) : null}
          {challenge.createdByName ? (
            <Grid
              item
              xs={6}
              ml="auto"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                wordWrap: "break-word",
              }}
            >
              <Typography
                fontWeight="bold"
                fontSize="0.9rem"
                data-cy="challenge-card-professional"
              >
                {challenge.createdByName}
              </Typography>
            </Grid>
          ) : null}
          <Grid item xs={12} />
          <Grid
            item
            flexGrow={1}
            sx={{
              img: {
                maxHeight: "3rem",
              },
            }}
          >
            <Box
              sx={{
                wordWrap: "break-word",
              }}
            >
              <img src={iconManager.getIcon(challenge.icon)} alt="" />
              <Typography variant="h3" data-cy="challenge-card-title">
                {challenge.title}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            ml="auto"
            flexShrink={1}
            display="flex"
            flexDirection="column"
            alignItems="flex-end"
          >
            {days
              ? Tools.Time.Days.dayIndexes.map((day) => (
                  <Typography
                    key={day}
                    fontSize="0.8rem"
                    ml={1}
                    display={
                      Tools.Time.Days.dayInDays(day, days) ? "block" : "none"
                    }
                  >
                    {t(`general.time.days.short.${day}`)}&nbsp;
                  </Typography>
                ))
              : null}
            {timeString ? (
              <Typography fontSize="0.8rem">{timeString}</Typography>
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <ChallengeProgress {...progress} />
          </Grid>
        </Grid>

        <ChallengeDetails
          challenge={challenge as Challenge}
          open={detailsOpen}
          onClose={() => setDetailsOpen(false)}
        />
      </Card>

      <Dialogs.AlertDialog
        open={rejectionCommentOpen}
        onClose={() => setRejectionCommentOpen(false)}
        positiveAction={{
          "text": t("general.actions.reject"),
          "action": () => onRejectionCommentComplete(),
          "data-cy": "challenge-rejection-confirm",
        }}
        negativeAction={{
          "text": t("general.actions.cancel"),
          "action": () => setRejectionCommentOpen(false),
          "data-cy": "challenge-rejection-cancel",
        }}
        text={
          <Box>
            <Typography mb={1}>{t("routine.challenge.reject.text")}</Typography>
            <TextInput
              placeholder={t("routine.challenge.reject.placeholder")}
              value={rejectionComment}
              onChange={(e) => {
                setRejectionComment(e.currentTarget.value);
              }}
              fullWidth
              inputProps={{
                maxLength: REJECTION_COMMENT_MAXLENGTH,
              }}
              data-cy="challenge-edition-title-input"
              multiline
              minRows={2}
            />
            <CharacterCount
              text={rejectionComment}
              maxLength={REJECTION_COMMENT_MAXLENGTH}
            />
          </Box>
        }
        data-cy={"challenge-rejection-dialog"}
      />
    </>
  );
};

export default ChallengeCard;
