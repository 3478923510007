import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const ProfileIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="save" {...props}>
      <g id="g185" transform="matrix(0.83284906,0,0,0.82973083,0,-0.41486542)">
        <ellipse
          cx="12"
          cy="7.5526299"
          rx="6.1111102"
          ry="6.0526299"
          stroke="#31737c"
          strokeWidth="2"
          id="ellipse173"
        />
        <path
          d="m 23,24.4999 c 0,-5.3485 -4.9249,-9.6842 -11,-9.6842 -6.07513,0 -11,4.3357 -11,9.6842"
          stroke="#31737c"
          strokeWidth="2"
          id="path175"
        />
      </g>
    </BaseIcon>
  );
};

export default ProfileIcon;
