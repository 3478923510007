import { Box } from "@mui/material";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Journal, { JournalProgress, JOURNAL_PROGRESS_TOTAL } from "..";
import useFTUE from "useFTUE";
import { CalendarIcon } from "components";

const JournalDaysPicker: React.FC<PropsWithChildren> = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setJournalProgress },
  } = useFTUE();

  const journalProgress = progress[FTUEFlow.Journal].progress;

  const onNext = () => {
    setJournalProgress(JournalProgress.DaysPicker);
  };

  const onQuit = () => {
    setJournalProgress(JournalProgress.JournalFTUEFinished, true);
  };

  return (
    <FTUEHint
      condition={
        journalProgress === JournalProgress.Intro &&
        canDisplayFlow(FTUEFlow.Journal)
      }
      hint={
        <Box
          component="span"
          sx={{
            svg: {
              height: "1.5rem",
              width: "1.5rem",
              marginBottom: "-0.25rem",
            },
          }}
        >
          {t("ftue.journal.daysPicker.text")}&nbsp;
          <CalendarIcon />
        </Box>
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      currentStep={Journal.Logic.calculateProgress(JournalProgress.Intro)}
      maxStep={JOURNAL_PROGRESS_TOTAL}
      dataCy="ftue-journay-days-hint"
    >
      {journalProgress === JournalProgress.Intro &&
      canDisplayFlow(FTUEFlow.Journal) ? (
        <Box
          sx={{
            "& .icon-menu path": {
              fill: "#fff",
            },
          }}
        >
          {props.children}
        </Box>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};

export default JournalDaysPicker;
