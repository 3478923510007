import DashboardFTUEFlow, { DashboardProgress } from "./Dashboard";
import DashboardPrescriptionsFTUEFlow, {
  DashboardPrescriptionsProgress,
} from "./DashboardPrescription";
import FirstRoutineFTUEFlow, { FirstRoutineProgress } from "./FirstRoutine";
import JournalFTUEFlow, { JournalProgress } from "./Journal";
import ObservationFTUEFlow, { ObservationProgress } from "./Observation";
import PrescriptionsFTUEFlow, { PrescriptionsProgress } from "./Prescription";
import ValidationFTUEFlow, { ValidationProgress } from "./Validation";
import StartOfFTUEDialog from "./StartOfFTUEDialog";

const FTUEFlowDefinitions = {
  DashboardFTUEFlow,
  DashboardPrescriptionsFTUEFlow,
  FirstRoutineFTUEFlow,
  JournalFTUEFlow,
  ObservationFTUEFlow,
  PrescriptionsFTUEFlow,
  ValidationFTUEFlow,
};

export {
  StartOfFTUEDialog,
  DashboardProgress,
  DashboardPrescriptionsProgress,
  FirstRoutineProgress,
  JournalProgress,
  ObservationProgress,
  PrescriptionsProgress,
  ValidationProgress,
  FTUEFlowDefinitions,
};
