import JournalAddNote from "./JournalAddNote";
import JournalDaysPicker from "./JournalDaysPicker";
import JournalDisplayNote from "./JournalDisplayNote";
import JournalFillNote from "./JournalFillNote";

const JournalFTUEHints = {
  JournalAddNote,
  JournalDaysPicker,
  JournalDisplayNote,
  JournalFillNote,
};

export default JournalFTUEHints;
