import { ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import Icons from "../../Icons";
import { FaceState } from "../../Icons/FaceIcon";

export interface IntensityInputProps {
  value: number | null;
  onChange: (newValue: number) => void;
  labelColor?: string;
  size?: string;
  dataCy?: string;
}

interface IntensityChoice {
  choiceValue: number;
  labelKey: string;
  iconState: FaceState;
}

const IntensityInput = ({
  value,
  onChange,
  labelColor,
  size = "80px",
  dataCy,
}: IntensityInputProps): JSX.Element => {
  const { t } = useTranslation();
  const theme = useTheme();

  const choices: IntensityChoice[] = [
    {
      choiceValue: 1,
      labelKey: "journal.observations.question.intensity.low",
      iconState: "happy",
    },
    {
      choiceValue: 2,
      labelKey: "journal.observations.question.intensity.moderate",
      iconState: "neutral",
    },
    {
      choiceValue: 3,
      labelKey: "journal.observations.question.intensity.high",
      iconState: "sad",
    },
  ];

  const renderIntensityChoice = ({
    choiceValue,
    labelKey,
    iconState,
  }: IntensityChoice) => (
    <Grid
      item
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexBasis={size}
      flexShrink={1}
      key={choiceValue}
    >
      <ButtonBase
        onClick={() => onChange(choiceValue)}
        sx={(theme) => ({
          backgroundColor:
            value === choiceValue ? theme.palette.secondary.main : "#fff",
          borderRadius: `calc(${size} * 0.25)`,
          borderWidth: "3px",
          borderStyle: "solid",
          borderColor: theme.palette.secondary.main,
          width: size,
          height: size,
          marginBottom: 1,
          svg: {
            width: `calc(${size} * 0.35)`,
            height: `calc(${size} * 0.35)`,
          },
        })}
        data-cy={dataCy + "-" + choiceValue}
      >
        <Icons.FaceIcon
          state={iconState}
          color={value === choiceValue ? "#fff" : theme.palette.secondary.main}
        />
      </ButtonBase>
      <Typography textAlign="center" color={labelColor}>
        {t(labelKey)}
      </Typography>
    </Grid>
  );

  return (
    <Grid
      container
      flexWrap="nowrap"
      spacing={1}
      justifyContent="center"
      data-cy={dataCy}
    >
      {choices.map((choice) => renderIntensityChoice(choice))}
    </Grid>
  );
};

export default IntensityInput;
