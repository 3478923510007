import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import DashboardPrescription, {
  DashboardPrescriptionsProgress,
  DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL,
} from "..";
import { FTUEHint } from "components";
import { FTUEFlow } from "models";
import useFTUE from "useFTUE";

const DashboardPrescriptionsIntensityGraph: React.FC<PropsWithChildren> = (
  props
) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setDashboardPrescriptionsProgress },
  } = useFTUE();

  const dashboardPrescriptionsProgress =
    progress[FTUEFlow.DashboardPrescriptions].progress;

  const onNext = () => {
    setDashboardPrescriptionsProgress(
      DashboardPrescriptionsProgress.IntensityGraph
    );
  };

  const onQuit = () => {
    setDashboardPrescriptionsProgress(
      DashboardPrescriptionsProgress.DashboardPrescriptionsFTUEFinished
    );
  };

  return (
    <FTUEHint
      condition={
        dashboardPrescriptionsProgress ===
          DashboardPrescriptionsProgress.SideEffectsTimePeriod &&
        canDisplayFlow(FTUEFlow.DashboardPrescriptions)
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.dashboardPrescriptions.intensityGraph.text")}
      scrollPosition="start"
      currentStep={DashboardPrescription.Logic.calculateProgress(
        DashboardPrescriptionsProgress.SideEffectsTimePeriod
      )}
      maxStep={DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL}
    >
      {dashboardPrescriptionsProgress ===
        DashboardPrescriptionsProgress.SideEffectsTimePeriod &&
      canDisplayFlow(FTUEFlow.DashboardPrescriptions) ? (
        <Box sx={{ backgroundColor: "#fff" }}>{props.children}</Box>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};

export default DashboardPrescriptionsIntensityGraph;
