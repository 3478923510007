import FTUEDashboardPrescriptionLogic from "./DashboardPrescriptionLogic";
import {
  DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL,
  DashboardPrescriptionsProgress,
} from "./DashboardPrescriptionsProgress";
import DashboardPrescriptionFTUEHints from "./Hints";

export {
  DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL,
  DashboardPrescriptionsProgress,
};

const DashboardPrescriptionsFTUEFlow = {
  Hints: DashboardPrescriptionFTUEHints,
  Logic: FTUEDashboardPrescriptionLogic,
};

export default DashboardPrescriptionsFTUEFlow;
