import React, { SetStateAction, useCallback, useState } from "react";
import clsx from "clsx";
import { Box, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledDrawer from "../../ControlledDrawer/ControlledDrawer";
import DrawerMenuSection from "../../ControlledDrawer/DrawerMenuSection";
import { PremiumLock } from "../../Premium";
import TaskIconPickerDisplay from "./TaskIconPickerDisplay";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import TaskIconPickerDisplayMini from "./TaskIconPickerDisplayMini";
import TaskIconPickerDisplayChallenge from "./TaskIconPickerDisplayChallenge";

export interface TaskIconPickerProps {
  orderedIcons: number[];
  iconAssets: Record<number, string>;
  icon: number;
  setIcon: React.Dispatch<SetStateAction<number>>;
  lockPremiumIcons: boolean;
  onPremiumIconClicked: (icon: number) => void;
  safeAreas: SafeAreas;
  emptyStateImage?: string;
  freeIcons?: number[];
  showEmptyState?: boolean;
  variant?: "default" | "mini" | "challenge";
}

const TaskIconPicker = ({
  orderedIcons,
  iconAssets,
  icon,
  setIcon,
  lockPremiumIcons,
  onPremiumIconClicked,
  safeAreas,
  emptyStateImage,
  freeIcons,
  showEmptyState = true,
  variant = "default",
}: TaskIconPickerProps) => {
  const rootClass = "task-icon-picker";

  const { t } = useTranslation();

  const { palette } = useTheme();

  const numberOfIcons = 118;

  const [showIconDrawer, setShowIconDrawer] = useState(false);

  const canShowIcon = (icon: number): boolean => {
    if (lockPremiumIcons) {
      return true;
    } else {
      return freeIcons ? freeIcons.includes(icon) : true;
    }
  };

  const onIconClick = () => {
    setShowIconDrawer(true);
  };

  const onIconChoiceClicked = (icon: number) => {
    setShowIconDrawer(false);
    setIcon(icon);
  };

  const onPremiumIconClick = (icon: number) => {
    setShowIconDrawer(false);
    onPremiumIconClicked(icon);
  };

  // Calculate number of "filler" icons to add at the end of the icons grid.
  // This will break if the number of icons per row ever changes.
  // For now icons scale with screen size so always 5.
  const getFillerIcons = useCallback((): number[] => {
    return Array.from(Array(5 - (numberOfIcons % 5)).keys());
  }, [numberOfIcons]);

  const handleVariant = () => {
    switch (variant) {
      case "mini":
        return (
          <TaskIconPickerDisplayMini
            icon={icon}
            iconAssets={iconAssets}
            onClick={onIconClick}
          />
        );
      case "challenge":
        return (
          <TaskIconPickerDisplayChallenge
            icon={icon}
            iconAssets={iconAssets}
            onClick={onIconClick}
          />
        );
      default:
        return (
          <>
            <Typography
              fontSize="0.8rem"
              textAlign="center"
              className={rootClass + "__label"}
            >
              {t(
                `routine.task.inputs.icon.label.${icon ? "modify" : "choose"}`
              )}
            </Typography>
            <TaskIconPickerDisplay
              icon={icon}
              iconAssets={iconAssets}
              onClick={onIconClick}
              showEmptyState={showEmptyState}
              emptyStateImage={emptyStateImage}
            />
          </>
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className={clsx(rootClass)}
      >
        {handleVariant()}
      </Box>
      <ControlledDrawer
        classes={{ paper: rootClass + "__icon-drawer" }}
        open={showIconDrawer}
        onClose={() => setShowIconDrawer(false)}
        safeAreas={safeAreas}
      >
        <DrawerMenuSection variant="header">
          <Typography
            variant="h4"
            className={clsx(rootClass + "__icon-drawer-title")}
          >
            {t("routine.task.inputs.icon.iconDrawer.title")}
          </Typography>
        </DrawerMenuSection>

        <Box
          px={2}
          pb={2}
          sx={{
            overflowY: "auto",
            ...SharedStyles.Layout.noScroll,
          }}
        >
          <Grid
            className={clsx(rootClass + "__icon-drawer-body")}
            container
            spacing={2}
            justifyContent="space-between"
          >
            {orderedIcons.map((i) => {
              return (
                <Grid
                  key={i}
                  sx={{
                    flex: "0 0 70px",
                  }}
                  item
                >
                  <PremiumLock
                    active={!canShowIcon(i)}
                    variant="icon-button"
                    borderRadius="10px"
                    onClick={() => onPremiumIconClick(i)}
                  >
                    <ButtonBase
                      data-cy={"selected-icon-button-" + i}
                      sx={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        borderWidth: "2px",
                        borderStyle: "solid",
                        borderColor:
                          icon === i ? palette.secondary.main : "#fff",
                        boxShadow: SharedStyles.Shadows.defaultShadow,
                        img: {
                          width: "100%",
                          height: "100%",
                        },
                      }}
                      onClick={() => {
                        onIconChoiceClicked(i);
                      }}
                    >
                      <img src={iconAssets[i]} alt={`Task Icon ${i}`} />
                    </ButtonBase>
                  </PremiumLock>
                </Grid>
              );
            })}
            {getFillerIcons().map((n) => (
              <Grid
                key={numberOfIcons + n}
                item
                sx={{
                  flex: "0 0 70px",
                }}
              ></Grid>
            ))}
          </Grid>
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default TaskIconPicker;
