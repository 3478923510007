import { SubscriptionStatus } from "@neurosolutionsgroup/models";
import { executeAuthedGet } from "../utils";

const getSubscriptionStatus = async (): Promise<SubscriptionStatus> => {
  const PATH = "/billing/subscription-status";

  return executeAuthedGet(PATH);
};

const Subscription = {
  getSubscriptionStatus,
};

export default Subscription;
