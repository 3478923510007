import {
  Box,
  CircularProgress,
  circularProgressClasses,
  Typography,
} from "@mui/material";

interface ChallengeProgressGaugeProps {
  challengeIconImgSrc: string;
  doneCount: number;
  total: number;
  size?: number;
  empty?: boolean;
}

const ChallengeProgressGauge = ({
  challengeIconImgSrc,
  doneCount,
  total,
  size = 80,
  empty = false,
}: ChallengeProgressGaugeProps): JSX.Element => {
  const value = (doneCount / total) * 100;

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        sx={{
          display: "flex",
          position: "relative",
          img: {
            width: 0.625 * size,
            position: "absolute",
            top: 0.1875 * size,
            left: 0.1875 * size,
          },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        >
          <svg viewBox="0 0 80 80" fill="none" width={size}>
            <circle
              cx="40"
              cy="40"
              r="31"
              strokeWidth={18.5}
              stroke="#F5EFE7"
            />
          </svg>
        </Box>
        <Box>
          <CircularProgress
            variant="determinate"
            value={value}
            thickness={10}
            size={size}
            sx={{
              [`& .${circularProgressClasses.circleDeterminate}`]: {
                color: "#BBF533",
              },
            }}
          />
        </Box>
        <img src={challengeIconImgSrc} alt="" />
      </Box>
      <Typography fontWeight={800}>
        {empty ? (
          "--"
        ) : (
          <>
            {doneCount}/{total}
          </>
        )}
      </Typography>
    </Box>
  );
};

export default ChallengeProgressGauge;
