import { Box, Card, Divider, Grid, Typography, useTheme } from "@mui/material";
import TierComparisonCardRow from "./TierComparisonCardRow";
import {
  AccessAlarmOutlined as AccessAlarmOutlinedIcon,
  BarChartOutlined as BarChartOutlinedIcon,
  ChatOutlined as ChatOutlinedIcon,
  Checklist as ChecklistIcon,
  GroupsOutlined as GroupsOutlinedIcon,
  InterestsOutlined as InterestsOutlinedIcon,
  Share as ShareIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import TierComparisonCardIcon from "./TierComparisonCardIcon";
import { Icons } from "@neurosolutionsgroup/components";

const TierComparisonCard = (): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const CheckIcon = () => (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Icons.CheckMarkIcon color={palette.success.main} checkStyle="kairos" />
    </Box>
  );

  const GridDivider = () => (
    <Divider
      sx={{
        marginY: 0.5,
        borderBottomWidth: "2px",
        borderColor: "background.default",
      }}
    />
  );

  return (
    <Card>
      <Grid container alignItems="center">
        <Grid item xs={7}></Grid>
        <Grid item xs={2.5}>
          <Typography
            fontWeight={800}
            textAlign="center"
            fontSize={"0.85rem"}
            mt={0.3}
          >
            {t("navigation.subscription.tiers.free")}
          </Typography>
        </Grid>
        <Grid item xs={2.5}>
          <Typography
            fontFamily={"Ander Hedge"}
            fontSize={"1.25rem"}
            color="success.main"
            textAlign="center"
            letterSpacing=".05rem"
          >
            {t("navigation.subscription.tiers.premium")}
          </Typography>
        </Grid>
      </Grid>

      <TierComparisonCardRow
        icon={<TierComparisonCardIcon icon={<GroupsOutlinedIcon />} />}
        text={t(
          "navigation.subscription.screenOne.comparisonCard.childProfiles"
        )}
        freeContent={"1"}
        premiumContent={"5"}
      />

      <GridDivider />

      <TierComparisonCardRow
        icon={<TierComparisonCardIcon icon={<AccessAlarmOutlinedIcon />} />}
        text={t("navigation.subscription.screenOne.comparisonCard.routines")}
        freeContent={"1"}
        premiumContent={"20"}
      />

      <GridDivider />

      <TierComparisonCardRow
        icon={<TierComparisonCardIcon icon={<InterestsOutlinedIcon />} />}
        text={t("navigation.subscription.screenOne.comparisonCard.icons")}
        freeContent={"-"}
        premiumContent={<CheckIcon />}
      />

      <GridDivider />

      <TierComparisonCardRow
        icon={<TierComparisonCardIcon icon={<ChecklistIcon />} />}
        text={t("navigation.subscription.screenOne.comparisonCard.challenges")}
        freeContent={"-"}
        premiumContent={<CheckIcon />}
      />

      <GridDivider />

      <TierComparisonCardRow
        icon={<TierComparisonCardIcon icon={<BarChartOutlinedIcon />} />}
        text={t("navigation.subscription.screenOne.comparisonCard.development")}
        freeContent={"-"}
        premiumContent={<CheckIcon />}
      />

      <GridDivider />

      <TierComparisonCardRow
        icon={<TierComparisonCardIcon icon={<ShareIcon />} />}
        text={t("navigation.subscription.screenOne.comparisonCard.share")}
        freeContent={"-"}
        premiumContent={<CheckIcon />}
      />

      <GridDivider />

      <TierComparisonCardRow
        icon={<TierComparisonCardIcon icon={<ChatOutlinedIcon />} />}
        text={t("navigation.subscription.screenOne.comparisonCard.chatbot")}
        freeContent={"-"}
        premiumContent={<CheckIcon />}
      />
    </Card>
  );
};

export default TierComparisonCard;
