import { Link, LinkProps } from "@mui/material";
import React from "react";

export interface WebviewLinkProps extends LinkProps {
  onClick: VoidFunction;
}

const WebviewLink = ({ children, ...props }: WebviewLinkProps): JSX.Element => {
  return (
    <Link
      component="button"
      {...props}
      onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        props.onClick();
      }}
    >
      {children}
    </Link>
  );
};

export default WebviewLink;
