import { ChallengeV2 } from "@neurosolutionsgroup/models";
import calculateChallengeWeekIndex from "./calculateChallengeWeekIndex";
import Time from "../../../Time";
import generateMissedChallengeWeekValidations from "./generateMissedChallengeWeekValidations";

const challengeNeedsValidation = (
  challenge: ChallengeV2,
  childIsDisabled: boolean
): boolean => {
  if (childIsDisabled) {
    return false;
  }

  // Challenge from professional not yet approved by parent.
  if (challenge.requiresApproval) {
    return false;
  }

  // Challenge already complete.
  if (challenge.successful !== null) {
    return false;
  }

  // Any history has not been validated.
  if (challenge.history.some((history) => history.parentStatus === null)) {
    return true;
  }

  // Weeks with missing validations.
  const currentChallengeWeek = calculateChallengeWeekIndex(
    challenge.startDate,
    Time.Dates.getTimeStamp()
  );

  let weeksWithoutFullHistory = 0;

  // Count all weeks except current week or weeks over the challenge duration.
  for (
    let weekIndex = 1;
    weekIndex < currentChallengeWeek && weekIndex < challenge.duration + 1;
    weekIndex++
  ) {
    const missingHistory = generateMissedChallengeWeekValidations(
      challenge,
      weekIndex
    );

    if (missingHistory.length > 0) {
      weeksWithoutFullHistory++;
    }
  }

  if (weeksWithoutFullHistory > 0) {
    return true;
  }

  return false;
};

export default challengeNeedsValidation;
