import React, { useCallback } from "react";
import format from "date-fns/format";
import differenceInMinutes from "date-fns/differenceInMinutes";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { Box, ButtonBase, Grid, Typography } from "@mui/material";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";

interface DateOptionProps {
  date: Date;
  selected: boolean;
  onClick: VoidFunction;
  className?: string;
  tagged?: boolean;
}

const DateOption: React.FC<DateOptionProps> = ({
  date,
  selected,
  onClick,
  tagged = false,
}) => {
  const { dateLocale: locale } = useLanguage();

  return (
    <ButtonBase
      onClick={onClick}
      sx={(theme) => ({
        backgroundColor: selected
          ? theme.palette.secondary.main
          : theme.palette.background.paper,
        color: selected ? "#fff" : theme.palette.secondary.main,
        width: "100%",
        height: "100%",
        borderRadius: "10px",
      })}
    >
      {tagged && (
        <Box
          sx={(theme) => ({
            height: "1em",
            width: "1em",
            borderRadius: "999px",
            backgroundColor: theme.palette.secondary.main,
            position: "absolute",
            top: "-0.5em",
            right: "-0.5em",
            border: "1px solid white",
          })}
        />
      )}
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography
          fontSize={"0.9em"}
          sx={(theme) => ({
            color: selected
              ? theme.palette.secondary.contrastText
              : theme.palette.secondary.main,
          })}
        >
          {format(date, "LLL", { locale })}
        </Typography>
        <Typography
          fontSize={"0.9em"}
          sx={(theme) => ({
            color: selected
              ? theme.palette.secondary.contrastText
              : theme.palette.secondary.main,
          })}
        >
          {format(date, "d", { locale })}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

interface DateOptionsProps {
  rootDate: Date;
  setDate: (value: Date) => void;
  pastDays: number;
  date: Date;
  className?: string;
  taggedDates?: Date[];
}

const DateOptions: React.FC<DateOptionsProps> = ({
  rootDate,
  setDate,
  pastDays,
  date,
  taggedDates,
}) => {
  const isSelected = useCallback(
    (d: Date): boolean => differenceInMinutes(d, date) === 0,
    [date]
  );

  // Render carousel of dates.
  const renderDates = useCallback(() => {
    // Get date n number of days before state date.
    const getOffsetDate = (offset: number): Date => {
      const dateWithOffset = new Date(rootDate);

      dateWithOffset.setDate(dateWithOffset.getDate() + offset);

      return dateWithOffset;
    };

    const onDateSelection = (newDate: Date) => {
      setDate(newDate);
    };

    const dateIsTagged = (dateArray: Date[], d: Date): boolean => {
      return dateArray.find((td) => td.getTime() === d.getTime()) !== undefined;
    };

    const dates: JSX.Element[] = [];

    for (let i = 0; i < pastDays; i++) {
      const d = getOffsetDate(0 - i);
      dates.push(
        <Grid item xs={2.2} key={i} flexShrink={0}>
          {taggedDates && dateIsTagged(taggedDates, d) ? (
            // We do not want to show multiple background overlays.
            <FTUEFlowDefinitions.JournalFTUEFlow.Hints.JournalDisplayNote
              showHint={false}
              isDateOption={true} // Background will already be there from Journal Notes list.
            >
              <DateOption
                date={d}
                selected={isSelected(d)}
                onClick={() => onDateSelection(d)}
                tagged={taggedDates ? dateIsTagged(taggedDates, d) : false}
              />
            </FTUEFlowDefinitions.JournalFTUEFlow.Hints.JournalDisplayNote>
          ) : (
            <DateOption
              date={d}
              selected={isSelected(d)}
              onClick={() => onDateSelection(d)}
              tagged={taggedDates ? dateIsTagged(taggedDates, d) : false}
            />
          )}
        </Grid>
      );
    }
    return dates;
  }, [rootDate, setDate, pastDays, isSelected, taggedDates]);

  return (
    <Grid
      container
      spacing={1}
      height="19vw"
      flexDirection="row-reverse"
      flexWrap="nowrap"
      sx={{
        "overflowX": "auto",
        "boxSizing": "border-box",
        "paddingBottom": "1em",
        "paddingLeft": "1em",
        "paddingRight": "1em",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      {renderDates()}
    </Grid>
  );
};

export default DateOptions;
