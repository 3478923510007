import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Observation, {
  ObservationProgress,
  OBSERVATION_PROGRESS_TOTAL,
} from "..";
import useFTUE from "useFTUE";

const ObservationDisplay: React.FC<PropsWithChildren> = ({ ...props }) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setObservationProgress },
  } = useFTUE();

  const observationProgress = progress[FTUEFlow.Observation].progress;

  const onNext = () => {
    setObservationProgress(ObservationProgress.Display);
  };

  const onQuit = () => {
    setObservationProgress(ObservationProgress.ObservationsFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        observationProgress === ObservationProgress.Intro &&
        canDisplayFlow(FTUEFlow.Observation)
      }
      hint={t("ftue.observation.display.text")}
      onClick={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      currentStep={Observation.Logic.calculateProgress(
        ObservationProgress.Intro
      )}
      maxStep={OBSERVATION_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default ObservationDisplay;
