//know what adapter to call when an action is taken

import {
  NotificationCategorySettings,
  NotificationCategory,
  NotificationsSettings,
} from "@neurosolutionsgroup/models";
import FirebaseSettingsGetterAdapter from "./Adapters/SettingsGetter/FirebaseSettingsGetterAdapter";
import FirebaseSettingsSetterAdapter from "./Adapters/SettingsSetter/FirebaseSettingsSetterAdapter";
import UnityAdapter from "./Adapters/Unity/UnityAdapter";
import CustomerIOSettingsGetterAdapter from "./Adapters/SettingsGetter/CustomerIOSettingsGetterAdapter";
import CustomerIOSettingsSetterAdapter from "./Adapters/SettingsSetter/CustomerIoSettingsSetterAdapter";
import MockSettingsSetterAdapter from "./Adapters/SettingsSetter/MockSettingsSetterAdapter";
import MockSettingsGetterAdapter from "./Adapters/SettingsGetter/MockSettingsGetterAdapter";

interface NotificationsServiceAdapter {
  getSettings: () => Promise<NotificationsSettings>;
  updateSettings: (
    globalOptIn: boolean,
    categories: NotificationCategorySettings
  ) => Promise<NotificationsSettings>;
  requestNativePermission: () => Promise<boolean | null>;
  setOptInLastSeen: () => Promise<void>;
  toggleRoutineNotifications: (active: boolean) => Promise<boolean | null>;
}

class NotificationsManager implements NotificationsServiceAdapter {
  getSettings: () => Promise<NotificationsSettings>;
  updateSettings: (
    globalOptIn: boolean,
    categories: NotificationCategorySettings
  ) => Promise<NotificationsSettings>;
  setOptInLastSeen: () => Promise<void>;

  constructor(customerIOActive: boolean, dev?: boolean) {
    this.getSettings = dev
      ? MockSettingsGetterAdapter.getSettings
      : customerIOActive
      ? CustomerIOSettingsGetterAdapter.getSettings
      : FirebaseSettingsGetterAdapter.getSettings;

    this.updateSettings = async (
      globalOptIn: boolean,
      categories: NotificationCategorySettings
    ): Promise<NotificationsSettings> => {
      await UnityAdapter.toggleRoutineNotifications(
        categories[NotificationCategory.Routine],
        1000
      );

      return dev
        ? MockSettingsSetterAdapter.updateSettings(globalOptIn, categories)
        : customerIOActive
        ? CustomerIOSettingsSetterAdapter.updateSettings(
            globalOptIn,
            categories
          )
        : FirebaseSettingsSetterAdapter.updateSettings(globalOptIn, categories);
    };

    this.setOptInLastSeen = dev
      ? MockSettingsSetterAdapter.setOptInLastSeen
      : CustomerIOSettingsSetterAdapter.setOptInLastSeen;
  }

  requestNativePermission = UnityAdapter.requestNativePermission;
  toggleRoutineNotifications = async (active: boolean) =>
    UnityAdapter.toggleRoutineNotifications(active, 5000);
}

export default NotificationsManager;
