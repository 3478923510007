import clsx from "clsx";
import React, { forwardRef } from "react";
import { createPortal } from "react-dom";
import Page, { PageProps } from "../Page/Page";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { PAGE_HEADER_HEIGHT } from "../Headers/PageHeader/PageHeader";
import { useTranslation } from "react-i18next";

interface CloseablePageProps extends PageProps {
  isOpen: boolean;
  onClose: VoidFunction;
  closeElement?: JSX.Element;
  overrideNavHeader?: boolean;
  navigationText?: boolean;
  withCoach?: boolean;
}

const portalRoot = document.getElementById("modal");

const CloseablePage = forwardRef<
  HTMLDivElement,
  CloseablePageProps & React.HTMLProps<HTMLDivElement>
>(
  (
    {
      isOpen,
      onClose,
      closeElement,
      header,
      overrideNavHeader = false,
      navigationText,
      withCoach = false,
      ...props
    },
    ref
  ) => {
    const ROOT_CLASS = "closeable-page";

    const { t } = useTranslation();

    return isOpen && portalRoot
      ? createPortal(
          <Box className={ROOT_CLASS}>
            {!overrideNavHeader ? (
              <Box
                className={clsx(ROOT_CLASS + "__nav-header")}
                sx={(theme) => ({
                  backgroundColor: props.color
                    ? props.color === "secondary"
                      ? theme.palette.secondary.main
                      : props.color
                    : undefined,
                  position: "relative",
                  zIndex: 1,
                  boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                  height: PAGE_HEADER_HEIGHT,
                  top: 0,
                })}
              >
                <ButtonBase
                  onClick={onClose}
                  className={ROOT_CLASS + "__close-element"}
                  sx={{
                    height: PAGE_HEADER_HEIGHT,
                    paddingX: "1em",
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {closeElement ?? (
                    <Box display="flex" alignItems="center">
                      <Icons.ArrowIcon arrowDirection="left" color={"#fff"} />
                      <Typography variant="h3" color="#fff" fontWeight={700}>
                        {navigationText ?? t("navigation.menu")}
                      </Typography>
                    </Box>
                  )}
                </ButtonBase>
              </Box>
            ) : null}
            <Page
              header={header}
              {...props}
              className={clsx(ROOT_CLASS + "__page", props.className, {
                [ROOT_CLASS + "__page--with-header"]: !overrideNavHeader,
              })}
              withCoach={withCoach}
              ref={ref}
            >
              {props.children}
            </Page>
          </Box>,
          portalRoot
        )
      : null;
  }
);

export default CloseablePage;
