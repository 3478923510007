import {
  FirestoreCollection,
  MessageFlags,
  MessageRecord,
} from "@neurosolutionsgroup/models";
import { CoachNotificationRecord } from "common/Services/Coach";
import { getFirestore, setDoc, doc, updateDoc } from "firebase/firestore";
import { CoachNotificationID } from "../Coach/CoachNotifications";

/**
 * This function update the messeging flags for a particular collection
 * @param userId The current user ID
 * @param collection The FirestoreCollection in which to update the flags
 * @param flags The id & value of the flags. If it's suppose to be a nested flags the complete path to the value
 */
export const updateMessageFlag = async (
  userId: string,
  collection: FirestoreCollection,
  flags: MessageFlags
): Promise<void> => {
  const db = getFirestore();

  const flagValues = Object.entries(flags).map(([id, value]) => ({
    id,
    value,
  }));

  let flagUpdates = {};

  flagValues.forEach((flag) => {
    flagUpdates = {
      ...flagUpdates,
      [`flags.${flag.id}`]: flag.value,
    };
  });

  await updateDoc(doc(db, collection, userId), flagUpdates);
};

export const createMessageDoc = async (
  collection: FirestoreCollection,
  userId: string,
  tenantId: string,
  initialData?: MessageRecord[]
): Promise<void> => {
  const db = getFirestore();

  let messages = {};

  if (initialData) {
    initialData.forEach((message) => {
      messages = {
        ...messages,
        [message.id]: message,
      };
    });
  }

  await setDoc(doc(db, collection, userId), {
    userId: userId,
    tenantId,
    messages: messages,
  });
};

export const updateMessageDoc = async (
  collection: FirestoreCollection,
  userId: string,
  messages: MessageRecord[]
): Promise<void> => {
  const db = getFirestore();

  const docRef = doc(db, collection, userId);

  let updateData = {};

  messages.forEach((message) => {
    if (message.extraData) {
      updateData = {
        ...updateData,
        [`messages.${message.id}.id`]: message.id,
        [`messages.${message.id}.lastSeen`]: message.lastSeen,
        [`messages.${message.id}.extraData`]: message.extraData,
      };
    } else {
      updateData = {
        ...updateData,
        [`messages.${message.id}.id`]: message.id,
        [`messages.${message.id}.lastSeen`]: message.lastSeen,
      };
    }
  });

  await updateDoc(docRef, updateData);
};

export const migrateOldMessageRecords = (
  oldCoachNotifications: CoachNotificationRecord[]
): MessageRecord[] => {
  return oldCoachNotifications.map((notif) => {
    const { id, lastTime, abTest, occurrences, template } = notif;

    if (abTest || occurrences || template) {
      const extraData: Record<string, string | number | boolean> = {};

      if (abTest) {
        extraData.abTest = abTest;
      }

      if (occurrences) {
        extraData.occurrences = occurrences;
      }

      if (template) {
        extraData.template = template;
      }

      return {
        id,
        lastSeen: lastTime,
        extraData,
      };
    }
    return {
      id,
      lastSeen: lastTime,
    };
  });
};

export const isCoachNotificationId = (id: string): boolean => {
  return Object.values(CoachNotificationID).includes(id as CoachNotificationID);
};

export const isFtueRecord = (id: string): boolean => {
  return id.startsWith("FTUE_");
};
