import { Box, Button } from "@mui/material";
import { AddPhotoAlternateOutlined } from "@mui/icons-material";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { IconManager } from "@neurosolutionsgroup/tools";

interface TaskIconPickerDisplayChallengeProps {
  icon: number;
  iconManager: IconManager;
  onClick: VoidFunction;
}

const TaskIconPickerDisplayChallenge = ({
  icon,
  iconManager,
  onClick,
}: TaskIconPickerDisplayChallengeProps): JSX.Element => {
  return (
    <Button
      data-cy="add-icon-button"
      onClick={onClick}
      size="small"
      sx={(theme) => ({
        ...SharedStyles.Layout.square("3em"),
        ...SharedStyles.Layout.flexCenter,
        "minWidth": 0,
        "minHeight": 0,
        "maxWidth": "3em",
        "height": "3em",
        "padding": 0,
        "zIndex": 0,
        "borderRadius": "10px",
        "& .icon-picker__add-container": {
          ...SharedStyles.Layout.square("2em"),
          zIndex: 1,
          borderRadius: "0.6em",
          ...SharedStyles.Layout.flexCenter,
          minHeight: 0,
          maxHeight: "2em",
        },
        "& .icon": {
          ...SharedStyles.Layout.square("2.5em"),
          path: {
            fill: "#fff",
          },
        },
        "& .icon-picker__icon": {
          marginLeft: "-1px",
          ...SharedStyles.Layout.square("2.5em"),
        },
        "& .icon-picker__icon__default": {
          marginLeft: "1px",
          marginBottom: "1px",
        },
      })}
    >
      <Box className="icon-picker__add-container">
        {icon ? (
          <img
            key={icon}
            className="icon-picker__icon"
            src={iconManager.getIcon(icon)}
            alt={`Task Icon ${icon}`}
          />
        ) : (
          <AddPhotoAlternateOutlined
            className={"icon-picker__icon__default"}
            fontSize="small"
            color={"secondary"}
          />
        )}
      </Box>
    </Button>
  );
};

export default TaskIconPickerDisplayChallenge;
