import {
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { AppGroupAccessList } from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";

interface GroupsEditionProps {
  childId: string;
}

const GroupsEdition = ({ childId }: GroupsEditionProps): JSX.Element => {
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();

  const [groups, setGroups] = useState<AppGroupAccessList[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>("");

  useEffect(() => {
    const getGroups = async () => {
      const apiGroups = await FirebaseAPI.Child.getChildGroups(childId);

      setGroups(apiGroups);
      setLoading(false);
    };

    getGroups();
  }, []);

  const onDelete = (groupId: string) => {
    setSelectedGroup(groupId);
  };

  const onDeleteConfirm = async () => {
    if (!selectedGroup) {
      return;
    }

    setLoading(true);

    const groupId = selectedGroup;

    setSelectedGroup(undefined);

    try {
      await FirebaseAPI.Child.removeChildFromGroup(childId, groupId);
      setGroups((current) => [
        ...current.filter((group) => group.id !== groupId),
      ]);

      setLoading(false);
    } catch (err) {
      handleUnknownError(err);

      setLoading(false);
    }
  };

  return (
    <Box>
      <Typography variant="h3">{t("settings.group.title")}</Typography>
      <Typography>{t("settings.group.subtitle")}</Typography>
      {loading ? (
        <Skeleton variant="rectangular" width="100%" height={150} />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography fontWeight="bold">
                  {t("settings.group.name")}
                </Typography>
              </TableCell>
              <TableCell width="20%" />
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((group) => (
              <TableRow key={group.id}>
                <TableCell>
                  <Typography>{group.name}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Button color="error" onClick={() => onDelete(group.id)}>
                    {t("general.actions.remove")}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
            {groups.length === 0 ? (
              <TableRow>
                <TableCell colSpan={2}>{t("settings.group.empty")}</TableCell>
              </TableRow>
            ) : null}
          </TableBody>
        </Table>
      )}

      <Dialogs.ConfirmationDialog
        open={!!selectedGroup}
        onPositiveAction={() => onDeleteConfirm()}
        onNegativeAction={() => setSelectedGroup(undefined)}
        imgSrc={DialogAssets.CancelTag}
        title={t("settings.group.confirm.title")}
        text={t("settings.group.confirm.text")}
      />
    </Box>
  );
};

export default GroupsEdition;
