import { Grid, Typography } from "@mui/material";

interface TierComparisonCardRowProps {
  icon: JSX.Element;
  text: string;
  freeContent: string | JSX.Element;
  premiumContent: string | JSX.Element;
}

const TierComparisonCardRow = ({
  icon,
  text,
  freeContent,
  premiumContent,
}: TierComparisonCardRowProps): JSX.Element => {
  return (
    <Grid container alignItems="center" minHeight="2.5rem">
      <Grid item xs={7} display="flex" alignItems="center">
        {icon}
        <Typography ml={1} textAlign="left" fontWeight={600} fontSize="0.8rem">
          {text}
        </Typography>
      </Grid>
      <Grid item xs={2.5}>
        {typeof freeContent === "string" ? (
          <Typography textAlign="center" fontWeight={600} fontSize="0.8rem">
            {freeContent}
          </Typography>
        ) : (
          freeContent
        )}
      </Grid>
      <Grid item xs={2.5}>
        {typeof premiumContent === "string" ? (
          <Typography
            textAlign="center"
            fontWeight="bold"
            color="success.main"
            fontSize="0.9rem"
          >
            {premiumContent}
          </Typography>
        ) : (
          premiumContent
        )}
      </Grid>
    </Grid>
  );
};

export default TierComparisonCardRow;
