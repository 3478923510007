import { initializeApp, FirebaseApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import {
  collection,
  getDocs,
  getFirestore,
  query,
  where,
  QueryConstraint,
  initializeFirestore,
} from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import {
  FirestoreDocumentWithId,
  FirestoreCollection,
  FirestorePrivateDocument,
} from "@neurosolutionsgroup/models";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_CONFIG_API_KEY,
  authDomain: process.env.REACT_APP_WEBVIEWS_FIREBASE_CONFIG_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_CONFIG_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_CONFIG_MESSEGING_SENDER_ID,
  appId: process.env.REACT_APP_WEBVIEWS_FIREBASE_CONFIG_APP_ID,
};

const initializeFirebaseApp = (): FirebaseApp => {
  const fbApp = initializeApp(firebaseConfig);

  const auth = getAuth(fbApp);

  if (process.env.NODE_ENV === "development") {
    connectAuthEmulator(auth, "http://127.0.0.1:9099");

    initializeFirestore(fbApp, {
      experimentalForceLongPolling: true,
      host: "127.0.0.1:8080",
      ssl: false,
    });
  }

  if (process.env.NODE_ENV !== "development") {
    getPerformance(fbApp);
  }

  return fbApp;
};

const getCollection = async (
  collectionToFetch: FirestoreCollection
): Promise<{ [key: string]: FirestoreDocumentWithId }> => {
  const output: { [key: string]: FirestoreDocumentWithId } = {};
  const getQuery = query(collection(getFirestore(), collectionToFetch));
  const documents = (await getDocs(getQuery)).docs;
  documents.forEach((d) => {
    output[d.id] = { id: d.id, ...d.data() };
  });
  return output;
};

const getOwnedDocFromCollection = async (
  collectionToFetchFrom: FirestoreCollection,
  constraint?: QueryConstraint
): Promise<{ [key: string]: FirestorePrivateDocument }> => {
  const output: { [key: string]: FirestorePrivateDocument } = {};
  const user = getAuth().currentUser;
  const getQuery = constraint
    ? query(
        collection(getFirestore(), collectionToFetchFrom),
        where("userId", "==", user?.uid),
        constraint
      )
    : query(
        collection(getFirestore(), collectionToFetchFrom),
        where("userId", "==", user?.uid)
      );
  const documents = (await getDocs(getQuery)).docs;
  documents.forEach((d) => {
    output[d.id] = {
      id: d.id,
      userId: d.data().userId,
      tenantId: d.data().tenantId,
      ...d.data(),
    };
  });
  return output;
};

const getDocFromFollowUpSubcollection = async (
  subCollection: FirestoreCollection,
  followUpId: string
): Promise<{ [key: string]: FirestorePrivateDocument }> => {
  const output: { [key: string]: FirestorePrivateDocument } = {};
  const user = getAuth().currentUser;
  const getQuery = query(
    collection(
      getFirestore(),
      FirestoreCollection.FollowUps,
      followUpId,
      subCollection
    ),
    where("userId", "==", user?.uid),
    where("tenantId", "==", user?.tenantId)
  );
  const documents = (await getDocs(getQuery)).docs;
  documents.forEach((d) => {
    output[d.id] = {
      id: d.id,
      userId: d.data().userId,
      tenantId: d.data().tenantId,
      ...d.data(),
    };
  });
  return output;
};

const firebase = {
  tools: {
    initializeFirebaseApp,
    getCollection,
    getOwnedDocFromCollection,
    getDocFromFollowUpSubcollection,
  },
};

export default firebase;
