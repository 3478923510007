import { Loader, Page } from "common/Components";
import useRoutines from "common/hooks/routines/useRoutines";
import useChildren from "common/hooks/children/useChildren";
import React, { useCallback, useEffect, useState } from "react";
import RoutineCard from "./component/Routine/RoutineCard";
import { FirestoreChallenge, Routine } from "@neurosolutionsgroup/models";
import {
  ChildSelector,
  Dialogs,
  Filter,
  Icons,
  IconButton,
  PremiumLock,
  EmptyStateAddButton,
  CoachMessageBanner,
} from "@neurosolutionsgroup/components";
import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import ChildIcons from "assets/child-icons";
import {
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import DynamismAssets from "assets/dynamism";
import useChallenges from "common/hooks/challenges/useChallenges";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import ChallengeSection from "./component/ChallengeSection";
import {
  ChallengeSectionVisited,
  DefaultChildEditionStarted,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { SAFE_AREAS } from "stylesheets";
import {
  ConfigNumber,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import DialogAssets from "assets/dialogs";
import useSubscription from "common/hooks/subscription/useSubscription";
import ChildCreation from "pages/Settings/Child/ChildCreation";
import { ObjectivesNavState } from "../ObjectivesSection";
import CoachAssets from "assets/coach";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";

interface RoutinesAndChallengesProps {
  navState: ObjectivesNavState[];
  setNavState: React.Dispatch<React.SetStateAction<ObjectivesNavState[]>>;
  setSelectedRoutine: (routine: Routine) => void;
  addRoutine: VoidFunction;
  editRoutine: (routine: Routine) => void;
  copyRoutine: (routine: Routine) => void;
  deleteRoutine: (routine: Routine) => void;
  activateRoutine: (routine: Routine, isActive: boolean) => void;
}

const Objectives: React.FC<RoutinesAndChallengesProps> = ({
  navState,
  setNavState,
  setSelectedRoutine,
  addRoutine,
  editRoutine,
  copyRoutine,
  deleteRoutine,
  activateRoutine,
}) => {
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();
  const { handleEvent } = useAnalytics();

  const [selectedChild, setSelectedChild] = useState<string | null>("all");
  const [routineToDelete, setRoutineToDelete] = useState<Routine | undefined>();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [challengeToDelete, setChallengeToDelete] = useState<
    FirestoreChallenge | undefined
  >();
  const [showChallengeDeleteDialog, setShowChallengeDeleteDialog] =
    useState(false);
  const [openChildCreation, setOpenChildCreation] = useState(false);

  const {
    selectors: { childrenById },
  } = useChildren();
  const {
    selectors: { routinesById, loading: routineLoading },
  } = useRoutines();
  const {
    selectors: { challengeFeatureFlagActive, loading: challengeLoading },
    actions: { deleteChallenge },
  } = useChallenges();
  const { getNumberConfig } = useRemoteConfig();
  const {
    actions: { onRoutineAddClicked },
  } = useFTUE();
  const { onPremiumFeatureClick, permissions } = useSubscription();

  useEffect(() => {
    if (Object.keys(childrenById).length === 1) {
      setSelectedChild(Object.keys(childrenById)[0]);
    }
  }, [childrenById]);

  useEffect(() => {
    if (navState.includes(ObjectivesNavState.Challenges)) {
      const event: ChallengeSectionVisited = {
        name: "Challenge Section Visited",
      };

      handleEvent(event);
    }
  }, [navState]);

  const onDeleteClick = (routine: Routine) => {
    setRoutineToDelete(routine);
    setShowDeleteDialog(true);
  };

  const onChallengeDelete = (challenge: FirestoreChallenge) => {
    setChallengeToDelete(challenge);
    setShowChallengeDeleteDialog(true);
  };

  const onChallengeDeleteConfirmed = async (challenge: FirestoreChallenge) => {
    try {
      await deleteChallenge(challenge);
    } catch (err) {
      handleUnknownError(err);
    }
  };

  const getRoutineIds = useCallback((): string[] => {
    if (routinesById) {
      return Object.keys(routinesById)
        .filter(
          (r) =>
            !routinesById[r].deleted &&
            (Object.keys(childrenById).length === 1 ||
              selectedChild === null ||
              selectedChild === "all" ||
              routinesById[r].users.includes(selectedChild))
        )
        .sort(
          (a, b) =>
            routinesById[a].start +
            (routinesById[a].locked ? 1000000000 : 0) -
            (routinesById[b].start + (routinesById[b].locked ? 1000000000 : 0))
        );
    } else {
      return [];
    }
  }, [routinesById, selectedChild]);

  const hasDefaultChild =
    selectedChild && childrenById[selectedChild]?.isDefault;

  const onEditDefaultChildClicked = () => {
    setOpenChildCreation(true);

    const event: DefaultChildEditionStarted = {
      name: "Default Child Edition Started",
    };

    handleEvent(event);
  };

  const renderEditDefaultChild = () => {
    return hasDefaultChild ? (
      <IconButton
        onClick={onEditDefaultChildClicked}
        iconSize="1.25rem"
        variant="solid"
        bouncing={true}
        children={<Icons.EditIcon color="#31737c" />}
        sx={{ marginLeft: 1 }}
        data-cy="edit-default_child-button"
      />
    ) : undefined;
  };

  const renderChildSelector = () => {
    return getNumberConfig(ConfigNumber.ChildLimit) > 1 ? (
      <div className="routine-list__header">
        <ChildSelector
          childList={Object.values(childrenById)}
          childIcons={ChildIcons}
          selectedChildId={selectedChild}
          onChildSelected={(childId) => setSelectedChild(childId)}
          safeAreas={SAFE_AREAS}
          selectAllOptionText={t("routine.selection.selectDefaultValue")}
          allowSelectAll={Object.keys(childrenById).length > 1}
          label={t("navigation.childSelector.label")}
          premiumLockDisabledChildren={!permissions.childCreation}
          onPremiumLockedChildClick={() =>
            onPremiumFeatureClick(PremiumFeature.LockedChild)
          }
        />
        {renderEditDefaultChild()}
      </div>
    ) : undefined;
  };

  const onAddFirstRoutine = () => {
    const shouldContinue = onRoutineAddClicked();

    if (shouldContinue) {
      addRoutine();
    }
  };

  return (
    <Page
      className="routine-list"
      header={renderChildSelector()}
      sxBody={[
        getRoutineIds().length > 0
          ? {
              backgroundImage: `url(${DynamismAssets.Routine})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "80%",
              backgroundPosition: "bottom left",
            }
          : null,
        {
          marginBottom: STICKY_BUTTONS_PAGE_PADDING,
        },
      ]}
    >
      {challengeLoading || routineLoading ? <Loader /> : null}
      {challengeFeatureFlagActive ? (
        <Filter
          options={[
            {
              id: ObjectivesNavState.Routines,
              label: t("routine.routine.title"),
            },
            {
              id: ObjectivesNavState.Challenges,
              label: t("routine.challenge.title"),
              premiumLock: !permissions.challenges,
            },
          ]}
          onPremiumLockClick={() =>
            onPremiumFeatureClick(PremiumFeature.ChallengeTab)
          }
          value={navState}
          onChange={setNavState}
          single
          data-cy="objectives-page-filter"
        />
      ) : null}

      {navState.includes(ObjectivesNavState.Challenges) ? (
        <ChallengeSection
          selectedChild={selectedChild === "all" ? null : selectedChild}
          onChallengeCreate={onAddFirstRoutine}
          onChallengeDelete={onChallengeDelete}
        />
      ) : null}

      {navState.includes(ObjectivesNavState.Routines) ? (
        <>
          <Typography variant="h2" my={2}>
            {t("routine.routine.title")}
          </Typography>

          <EmptyStateAddButton
            text={t("routine.routine.add")}
            onClick={onAddFirstRoutine}
            data-cy="create-routine-button"
          />

          {getRoutineIds().length > 0 ? (
            getRoutineIds().map((id, i) => {
              const routine = routinesById[id];

              const onSelect = () => {
                setSelectedRoutine(routine);
              };

              return (
                <Box mt={i === 0 ? 2 : 0} mb={2} key={id}>
                  <FTUEFlowDefinitions.FirstRoutineFTUEFlow.Hints.RoutineDisplay
                    isFirst={i === 0}
                  >
                    <PremiumLock
                      active={
                        !permissions.multipleRoutines && routine.locked === true
                      }
                      onClick={() =>
                        onPremiumFeatureClick(PremiumFeature.RoutineCustom)
                      }
                      variant={"long"}
                      sx={{
                        width: "100%",
                      }}
                      buttonSx={{
                        borderRadius: "15px",
                      }}
                    >
                      <RoutineCard
                        id={"routine-card-" + i}
                        onClick={onSelect}
                        key={id}
                        routine={routine}
                        onEditClick={() => editRoutine(routine)}
                        onCopyClick={() => copyRoutine(routine)}
                        onDeleteClick={() => {
                          onDeleteClick(routine);
                        }}
                        onActivateClick={(isDisabled) => {
                          activateRoutine(routine, isDisabled);
                        }}
                      />
                    </PremiumLock>
                  </FTUEFlowDefinitions.FirstRoutineFTUEFlow.Hints.RoutineDisplay>
                </Box>
              );
            })
          ) : (
            <CoachMessageBanner
              coachImgSrc={CoachAssets.CoachRoutine}
              coachImagePosition="left"
              bannerContent={<Trans i18nKey="routine.routine.empty" />}
              sx={{ marginTop: 2, marginX: "-1rem" }}
            />
          )}
        </>
      ) : null}

      <ChildCreation
        open={openChildCreation}
        onClose={() => setOpenChildCreation(false)}
        onBack={() => setOpenChildCreation(false)}
        setSelectedChildOnComplete
        placeholderName={
          selectedChild ? childrenById[selectedChild]?.name : undefined
        }
        codeStyle={0}
      />

      <Dialogs.ConfirmationDialog
        open={showDeleteDialog}
        onClose={() => setShowDeleteDialog(false)}
        title={t("routine.delete.title")}
        text={t("routine.delete.text")}
        onPositiveAction={() => {
          routineToDelete && deleteRoutine(routineToDelete);
          setShowDeleteDialog(false);
        }}
        onNegativeAction={() => {
          setRoutineToDelete(undefined);
          setShowDeleteDialog(false);
        }}
        imgSrc={DialogAssets.CancelTag}
      />
      <Dialogs.ConfirmationDialog
        open={showChallengeDeleteDialog}
        onClose={() => setShowChallengeDeleteDialog(false)}
        title={t("routine.challenge.delete.title")}
        text={t("routine.challenge.delete.text")}
        onPositiveAction={() => {
          challengeToDelete && onChallengeDeleteConfirmed(challengeToDelete);
          setShowChallengeDeleteDialog(false);
        }}
        onNegativeAction={() => {
          setChallengeToDelete(undefined);
          setShowChallengeDeleteDialog(false);
        }}
        imgSrc={DialogAssets.CancelTag}
        data-cy="delete-challenge-dialog"
      />
    </Page>
  );
};

export default Objectives;
