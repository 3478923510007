import {
  Box,
  SwipeableDrawer,
  SwipeableDrawerProps,
  drawerClasses,
} from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { PropsWithChildren } from "react";

export interface ControlledDrawerProps
  extends Omit<SwipeableDrawerProps, "onOpen" | "children">,
    PropsWithChildren {
  safeAreas?: SafeAreas;
  isPremiumLocked?: boolean;
}

const ControlledDrawer = ({
  children,
  safeAreas,
  sx,
  isPremiumLocked = false,
  ...props
}: ControlledDrawerProps): JSX.Element => {
  return (
    <SwipeableDrawer
      {...props}
      anchor={props.anchor ?? "bottom"}
      disableSwipeToOpen={true}
      onOpen={() => {}}
      sx={[
        {
          [`& .${drawerClasses.paper}`]: {
            maxHeight: safeAreas?.top
              ? `calc(95vh - ${safeAreas?.top})`
              : "90vh",
            ...SharedStyles.Layout.noScroll,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
      <Box
        sx={{
          height: `calc(1rem + ${safeAreas?.bottom ?? "0px"})`,
          backgroundColor: isPremiumLocked
            ? "rgba(0, 0, 0, 0.2)"
            : "rgba(0, 0, 0, 0)",
        }}
      />
    </SwipeableDrawer>
  );
};

export default ControlledDrawer;
