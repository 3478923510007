import { Box, Button } from "@mui/material";
import FtueDialog from "./FtueDialog";
import { useTranslation } from "react-i18next";

export interface FtueIntroDialogProps {
  open: boolean;
  title: string;
  imgSrc: string;
  content: JSX.Element;
  onLaterClick: VoidFunction;
  actionText?: string;
  backdropContent?: JSX.Element;
  dataCy?: string;
}

const FtueIntroDialog = ({
  open,
  title,
  imgSrc,
  content,
  onLaterClick,
  actionText,
  backdropContent,
  dataCy,
}: FtueIntroDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const dialogContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        img: {
          maxHeight: "200px",
          maxWidth: "60%",
          marginBottom: 2,
        },
      }}
    >
      <img src={imgSrc} alt="" />
      <Box mb={2}>{content}</Box>
      <Button
        color="secondary"
        variant="contained"
        sx={{
          marginTop: "auto",
        }}
        data-cy="ftue-intro-later"
      >
        {actionText ?? t("general.actions.later")}
      </Button>
    </Box>
  );

  return (
    <FtueDialog
      open={open}
      title={title}
      content={dialogContent}
      backdropContent={backdropContent}
      dataCy={dataCy}
      onClose={onLaterClick}
    />
  );
};

export default FtueIntroDialog;
