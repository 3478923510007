import { filterNotesBySubject } from "./filterNotesBySubject";
import { sortNotesByEventTime } from "./sortNotesByEventTime";
import { createNoteFilterOptions } from "./createNoteFilterOptions/createNoteFilterOptions";

const Notes = {
  sortNotesByEventTime,
  filterNotesBySubject,
  createNoteFilterOptions,
};

export default Notes;
