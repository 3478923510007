import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Prescriptions, {
  PrescriptionsProgress,
  PRESCRIPTIONS_PROGRESS_TOTAL,
} from "..";
import useFTUE from "useFTUE";

export interface ConfirmationProps extends PropsWithChildren {
  handleOnNext: () => void;
  handleOnBack: () => void;
}

const Confirmation: React.FC<ConfirmationProps> = ({
  handleOnNext,
  handleOnBack,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setPrescriptionsProgress },
  } = useFTUE();

  const prescriptionsProgress = progress[FTUEFlow.Prescriptions].progress;

  const onNext = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Confirmation);
    handleOnNext();
  };

  const onBack = () => {
    setPrescriptionsProgress(PrescriptionsProgress.OtherMedication);
    handleOnBack();
  };

  const onQuit = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Finished, true);
  };

  return (
    <FTUEHint
      condition={
        prescriptionsProgress === PrescriptionsProgress.SelectSideEffects &&
        canDisplayFlow(FTUEFlow.Prescriptions)
      }
      hint={t("ftue.prescription.confirmation.text")}
      nextText={t("general.actions.confirm")}
      onNext={onNext}
      onBack={onBack}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      currentStep={Prescriptions.Logic.calculateProgress(
        PrescriptionsProgress.SelectSideEffects
      )}
      maxStep={PRESCRIPTIONS_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};
export default Confirmation;
