import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const DeleteIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="delete" {...props}>
      <path
        fill={color}
        d="M 7.0996094 0 L 5.9394531 1.1113281 L 3.734375 1.7773438 L 3.2011719 2.9667969 L 3.0390625 3.3339844 L 16.960938 3.3339844 L 16.101562 1.7773438 L 14.060547 1.1113281 L 12.900391 0 L 7.0996094 0 z M 5.359375 4.4453125 L 3.0390625 6.6660156 L 3.0390625 17.777344 L 5.359375 20 L 14.640625 20 L 16.960938 17.777344 L 16.960938 6.6660156 L 14.640625 4.4453125 L 5.359375 4.4453125 z M 5.359375 6.6660156 L 14.640625 6.6660156 L 14.640625 17.777344 L 5.359375 17.777344 L 5.359375 6.6660156 z "
      />
    </BaseIcon>
  );
};

export default DeleteIcon;
