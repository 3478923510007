import {
  FirestoreCollection,
  MessageRecord,
  MessagingDocument,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import useAuth from "common/hooks/auth/useAuth";
import { Unsubscribe, doc, getFirestore, onSnapshot } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { PropsWithChildren, useMemo } from "react";
import { Vesa } from "./messages";
import { calculateNextAdviceMessage } from "./ValidationEmptyStateAdviceLogic";
import { createMessageDoc } from "../tools";

export interface ValidationEmptyStateAdviceData {
  latestAdvice: Vesa;
}

const [
  useValidationEmptyStateAdviceContext,
  ValidationEmptyStateAdviceContextProvider,
] =
  Tools.Context.createGenericContext<ValidationEmptyStateAdviceData>(
    __filename
  );

const ValidationEmptyStateAdviceProvider = (
  props: PropsWithChildren
): JSX.Element => {
  const { user, tenantId } = useAuth();

  const [messages, setMessages] = useState<MessageRecord[] | undefined>(
    undefined
  );

  useEffect(() => {
    if (user && tenantId) {
      let unsub: Unsubscribe | null = null;

      const loadMessages = async () => {
        const db = getFirestore();
        const collection =
          FirestoreCollection.ValidationEmptyStateAdviceMessages;

        unsub = onSnapshot(doc(db, collection, user.uid), (docSnap) => {
          let messages: MessageRecord[] | undefined = undefined;

          if (docSnap.exists()) {
            const data = docSnap.data() as MessagingDocument;

            messages = Object.values(data.messages);

            setMessages(messages);
          } else {
            setMessages(undefined);

            createMessageDoc(collection, user.uid, tenantId);
          }
        });
      };

      loadMessages();

      return () => {
        if (unsub) {
          unsub();
        }
      };
    }
  }, [user]);

  const latestAdvice: Vesa = useMemo(
    () => calculateNextAdviceMessage(messages),
    [messages]
  );

  return (
    <ValidationEmptyStateAdviceContextProvider value={{ latestAdvice }}>
      {props.children}
    </ValidationEmptyStateAdviceContextProvider>
  );
};

export {
  useValidationEmptyStateAdviceContext,
  ValidationEmptyStateAdviceProvider,
};
