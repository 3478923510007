import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const MedicationIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="medication" {...props}>
      <path
        d="M 8.3388334,13.333333 H 5.5702222 V 10 H 8.3388334 V 7.2222223 H 11.661167 V 10 h 2.768611 v 3.333333 h -2.768611 v 2.777778 H 8.3388334 Z M 17.752111,5.5555555 V 17.777778 C 17.752111,19 16.755445,20 15.537222,20 H 4.4627779 C 3.244589,20 2.247889,19 2.247889,17.777778 V 5.5555555 c 0,-1.2222221 0.9967,-2.2222222 2.2148889,-2.2222222 H 15.537222 c 1.218223,0 2.214889,1.0000001 2.214889,2.2222222 z m -2.214889,0 H 4.4627779 V 17.777778 H 15.537222 Z M 16.644667,0 H 3.3553334 V 2.2222222 H 16.644667 Z"
        fill={color}
        id="path824"
      />
    </BaseIcon>
  );
};

export default MedicationIcon;
