import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

export interface FullScreenIconProps extends IconProps {
  reverse?: boolean;
}

const FullScreenIcon = ({
  reverse = false,
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: FullScreenIconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="full-screen" {...props}>
      {reverse ? (
        <path
          d="M 0,15.714286 H 4.2857142 V 20 H 7.1428571 V 12.857143 H 0 Z M 4.2857142,4.2857143 H 0 V 7.1428571 H 7.1428571 V 0 H 4.2857142 Z M 12.857143,20 h 2.857143 V 15.714286 H 20 V 12.857143 H 12.857143 Z M 15.714286,4.2857143 V 0 H 12.857143 V 7.1428571 H 20 V 4.2857143 Z"
          fill={color}
        />
      ) : (
        <path
          d="M 2.8571429,12.857143 H 0 V 20 H 7.142857 V 17.142857 H 2.8571429 Z M 0,7.142857 H 2.8571429 V 2.8571429 H 7.142857 V 0 H 0 Z m 17.142857,10 H 12.857143 V 20 H 20 V 12.857143 H 17.142857 Z M 12.857143,0 v 2.8571429 h 4.285714 V 7.142857 H 20 V 0 Z"
          fill={color}
        />
      )}
    </BaseIcon>
  );
};

export default FullScreenIcon;
