import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { FirstRoutineProgress } from "..";
import useFTUE from "useFTUE";

const AddTask: React.FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();

  const {
    selectors: { progress },
    actions: { canDisplayFlow, setFirstRoutineProgress },
  } = useFTUE();

  const firstRoutineProgress = progress[FTUEFlow.FirstRoutine].progress;

  const onNext = () => {
    setFirstRoutineProgress(FirstRoutineProgress.RoutineComplete);
  };

  return (
    <FTUEHint
      hint={t("ftue.firstRoutine.addTask.hint")}
      condition={
        firstRoutineProgress === FirstRoutineProgress.TaskComplete &&
        canDisplayFlow(FTUEFlow.FirstRoutine)
      }
      onNext={onNext}
    >
      {props.children}
    </FTUEHint>
  );
};

export default AddTask;
