import { ObservationProgress } from "./ObservationProgress";

const calculateProgress = (step: ObservationProgress): number => {
  return (
    Object.values(ObservationProgress).findIndex((v) => v === step.toString()) -
    2
  );
};

const getProgress = (recordIds: string[]): ObservationProgress => {
  let latestObservationStep = ObservationProgress.None;

  if (
    recordIds.includes(ObservationProgress.ObservationsFTUEFinished.toString())
  ) {
    latestObservationStep = ObservationProgress.ObservationsFTUEFinished;
  } else {
    Object.values(ObservationProgress).forEach((v) => {
      if (recordIds.includes(v)) {
        latestObservationStep = v;
      }
    });
  }

  return latestObservationStep;
};

const isComplete = (progress: ObservationProgress): boolean => {
  return progress === ObservationProgress.ObservationsFTUEFinished;
};

const meetsCriteria = (observationsToDoCount: number): boolean => {
  return observationsToDoCount > 0;
};

const FTUEObservationLogic = {
  calculateProgress,
  getProgress,
  isComplete,
  meetsCriteria,
};

export default FTUEObservationLogic;
