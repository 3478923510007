import { Badge, badgeClasses, Box, Typography } from "@mui/material";
import { format, isSameDay, sub } from "date-fns";

interface WeekNotesDisplayProps {
  weekEndDate: Date;
  dateTags: {
    date: Date;
    count: number;
  }[];
  dateLocale: Locale;
}

const WeekNotesDisplay = ({
  weekEndDate,
  dateTags,
  dateLocale,
}: WeekNotesDisplayProps): JSX.Element => {
  const dates: {
    date: Date;
    count: number;
  }[] = new Array(7);

  for (let i = 0; i < 7; i++) {
    const date = sub(weekEndDate, { days: i });

    const countDate = Object.values(dateTags).find((value) =>
      isSameDay(value.date, date)
    );

    const count = countDate?.count ?? 0;

    dates[7 - i] = { date, count };
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "stretch",
        alignItems: "stretch",
        backgroundColor: "#fff",
        padding: 1,
        borderRadius: "15px",
      }}
    >
      {dates.map((date, i) => (
        <Box key={i} flexGrow={1} display="flex" justifyContent="center">
          <Badge
            color="secondary"
            badgeContent={date.count}
            sx={{
              [`& .${badgeClasses.badge}`]: {
                fontSize: "0.5rem",
                height: "15px",
                width: "15px",
                minWidth: "15px",
                padding: 0,
                right: "-8px",
              },
            }}
          >
            <Box
              sx={{
                fontSize: "0.8rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                lineHeight: 1.2,
              }}
            >
              <Box>
                <Typography
                  fontSize="inherit"
                  lineHeight="inherit"
                  fontWeight="bold"
                  sx={{
                    opacity: 0.7,
                  }}
                >
                  {format(date.date, "eeeee", { locale: dateLocale })}
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontSize="inherit"
                  lineHeight="inherit"
                  fontWeight="bold"
                >
                  {date.date.getDate()}
                </Typography>
              </Box>
            </Box>
          </Badge>
        </Box>
      ))}
    </Box>
  );
};

export default WeekNotesDisplay;
