import mixpanel from "mixpanel-browser";
import {
  AnalyticService,
  OnUserLoginFunction,
  PageViewFunction,
} from "@neurosolutionsgroup/analytics";

/** Initialization **/

const init = (token: string): void => {
  if (token) {
    mixpanel.init(token);
  } else {
    throw new Error("Missing Mixpanel config.");
  }
};

/** Functions **/

const onUserLogin: OnUserLoginFunction = (distinctId, language, email) => {
  mixpanel.identify(distinctId);
  mixpanel.people.set({ language, $email: email });
};

const pageView: PageViewFunction = (path) => {
  console.log("[Analytics] pageView not implemented for Mixpanel.");
};

const trackEvent = (
  eventName: string,
  properties?: { [key: string]: unknown }
): void => {
  mixpanel.track(eventName, {
    ...properties,
  });
};

const setProfileProperties = (properties: { [key: string]: unknown }): void => {
  mixpanel.people.set(properties);
};

const setOnceProfileProperties = (properties: {
  [key: string]: unknown;
}): void => {
  mixpanel.people.set_once(properties);
};

const functions = {
  onUserLogin,
  pageView,
  trackEvent,
  setProfileProperties,
  setOnceProfileProperties,
};

/** Data **/

export enum MixpanelReservedProfileProperties {
  Email = "$email",
  DistinctID = "$distinct_id",
  FirstName = "$first_name",
  LastName = "$last_name",
  Name = "$name",
  City = "$city",
}

/** Exports **/

export const getMixpanelService = (token: string): AnalyticService => {
  return {
    name: "Mixpanel",
    init: () => init(token),
    functions,
    data: { MixpanelReservedProfileProperties },
  };
};
