import { Box, Typography } from "@mui/material";
import { ChallengeV2, FirestoreChallenge } from "@neurosolutionsgroup/models";
import { ChallengeDisplayCard } from "@neurosolutionsgroup/webviews-pages";
import ChallengeInfoDrawer from "pages/Objectives/ChallengeInfoDrawer/ChallengeInfoDrawer";
import React from "react";
import { useTranslation } from "react-i18next";
import ChallengeCard from "./ChallengeCard";
import { Tools } from "@neurosolutionsgroup/tools";
import ChildIcons from "assets/child-icons";
import useChildren from "common/hooks/children/useChildren";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useChallenges from "common/hooks/challenges/useChallenges";
import useTasks from "common/hooks/routines/useTasks";

export interface ActiveChallengesProps {
  activeChallenges: FirestoreChallenge[];
  onChallengeDelete: (c: FirestoreChallenge) => void;
}

const ActiveChallenges: React.FC<ActiveChallengesProps> = ({
  activeChallenges,
  onChallengeDelete,
}) => {
  const {
    actions: { childValidation },
  } = useChallenges();
  const {
    selectors: { childrenById },
  } = useChildren();
  const { dateLocale, language } = useLanguage();
  const { t } = useTranslation();

  const { iconManager } = useTasks().selectors;

  const childList = Object.values(childrenById);

  const showDebug = Tools.Environment.isStaging();

  const onDebugValidate = (challenge: FirestoreChallenge, done: boolean) => {
    childValidation(challenge, done);
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mt={2}
      >
        <Typography variant="h2" my={1}>
          {t("routine.challenge.titleActive")}
        </Typography>
        <ChallengeInfoDrawer />
      </Box>
      <Box data-cy="active-challenge-list">
        {activeChallenges
          .sort((a, b) => (b.createdDate ?? 0) - (a.createdDate ?? 0))
          .map((challenge, i) => (
            <Box key={challenge.id} mt={i !== 0 ? 2 : 0}>
              {Tools.Data.Challenges.V2.isChallengeV2(challenge) ? (
                <ChallengeDisplayCard
                  challenge={challenge as ChallengeV2}
                  childIcons={ChildIcons}
                  childList={childList}
                  dateLocale={dateLocale}
                  // not certain how to manage that without passing the iconManager
                  iconManager={iconManager}
                  language={language}
                  showDebug={showDebug}
                  onApproval={() => {}}
                  onDelete={() => onChallengeDelete(challenge)}
                  onValidate={(done) => onDebugValidate(challenge, done)}
                />
              ) : (
                <ChallengeCard
                  challenge={challenge}
                  onDelete={() => onChallengeDelete(challenge)}
                />
              )}
            </Box>
          ))}
      </Box>
    </>
  );
};
export default ActiveChallenges;
