import {
  Accordion,
  accordionClasses,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Theme,
  useTheme,
} from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";
import Icons from "../../Icons";

export interface ExpandableCardProps extends CypressProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  summaryComponent: JSX.Element;
  detailsComponent: JSX.Element;
  hideExpandIcon?: boolean;
  sx?: SxProps<Theme>;
}

const ExpandableCard = ({
  open,
  setOpen,
  summaryComponent,
  detailsComponent,
  hideExpandIcon = false,
  sx,
  ...props
}: ExpandableCardProps) => {
  const { palette } = useTheme();

  return (
    <Accordion
      expanded={open}
      sx={[
        {
          [`&.${accordionClasses.root}`]: {
            borderRadius: "15px",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-cy={props["data-cy"]}
    >
      <AccordionSummary
        sx={{
          "&.Mui-expanded": {
            borderRadius: "15px",
            boxShadow: "0px 4px 13px 3px rgba(0, 0, 0, 0.15)",
          },
        }}
        expandIcon={
          !hideExpandIcon ? (
            <Icons.ArrowIcon
              arrowDirection="down"
              color={palette.secondary.main}
            />
          ) : undefined
        }
        onClick={() => setOpen((current) => !current)}
      >
        {summaryComponent}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingTop: 2,
        }}
      >
        {detailsComponent}
      </AccordionDetails>
    </Accordion>
  );
};
export default ExpandableCard;
