import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Journal, { JournalProgress, JOURNAL_PROGRESS_TOTAL } from "..";
import useFTUE from "useFTUE";

export interface JournalDisplayNoteProps extends PropsWithChildren {
  showHint: boolean;
  isDateOption: boolean;
}

const JournalDisplayNote: React.FC<JournalDisplayNoteProps> = ({
  showHint = true,
  isDateOption = false,
  ...props
}) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setJournalProgress },
  } = useFTUE();

  const journalProgress = progress[FTUEFlow.Journal].progress;

  const onNext = () => {
    setJournalProgress(JournalProgress.JournalFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        journalProgress === JournalProgress.FillNote &&
        canDisplayFlow(FTUEFlow.Journal)
      }
      hint={showHint ? t("ftue.journal.displayNote.text") : undefined}
      onNext={
        isDateOption
          ? undefined
          : () => {
              onNext();
            }
      }
      nextText={t("ftue.journal.displayNote.button")}
      centered
      backgroundOverlay={!isDateOption}
      floating
      overrideHeight={isDateOption ? "100%" : undefined}
      currentStep={Journal.Logic.calculateProgress(JournalProgress.FillNote)}
      maxStep={JOURNAL_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default JournalDisplayNote;
