import { Box } from "@mui/material";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { FirstRoutineProgress } from "..";
import useFTUE from "useFTUE";

export interface AddIconProps extends PropsWithChildren {
  icon: number;
  onQuit: VoidFunction;
}

const AddIcon: React.FC<AddIconProps> = ({ icon, onQuit, ...props }) => {
  const { t } = useTranslation();

  const {
    selectors: { progress },
    actions: { canDisplayFlow, setFirstRoutineProgress },
  } = useFTUE();

  const firstRoutineProgress = progress[FTUEFlow.FirstRoutine].progress;

  const onNext = () => {
    setFirstRoutineProgress(FirstRoutineProgress.TaskIcon);
  };

  return (
    <FTUEHint
      hint={t("ftue.firstRoutine.taskIcon.hint")}
      condition={
        firstRoutineProgress === FirstRoutineProgress.AddTask &&
        canDisplayFlow(FTUEFlow.FirstRoutine)
      }
      onNext={onNext}
      nextDisabled={!icon}
      quit={() => {
        onQuit();
        setFirstRoutineProgress(FirstRoutineProgress.TaskComplete);
      }}
    >
      {firstRoutineProgress === FirstRoutineProgress.AddTask ? (
        <Box
          sx={(theme) => ({
            "& .icon-picker__add-container": {
              backgroundColor: "#fff !important",
            },
            "& .icon-add": {
              path: {
                fill: theme.palette.secondary.main + " !important",
              },
            },
            "& .icon-picker__label": {
              color: "#fff",
            },
          })}
        >
          {props.children}
        </Box>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};

export default AddIcon;
