import { Grid } from "@mui/material";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import React, { useMemo, useState } from "react";
import TaskAnalysis from "./TaskAnalysis";
import TodaysRoutinesCard from "./TodaysRoutinesCard";
import ProgressAnalysis from "./ProgressAnalysis";
import useRoutineDashboard from "./useRoutineData";
import { GraphTools } from "@neurosolutionsgroup/graphs";
import useChallenges from "common/hooks/challenges/useChallenges";
import { Loader } from "common/Components";
import ChallengesForApproval from "pages/Objectives/Objectives/component/Challenge/ChallengesForApproval";
import useChildren from "common/hooks/children/useChildren";
import { FirestoreChallenge } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";

const RoutineDashboard: React.FC = () => {
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();
  const {
    loading,
    period,
    periodText,
    setPeriod,
    periodOptions,
    selectedCategory,
    setSelectedCategory,
    data,
  } = useRoutineDashboard();

  const {
    selectors: {
      challengeFeatureFlagActive,
      challengesForApproval,
      loading: challengeLoading,
    },
    actions: { deleteChallenge },
  } = useChallenges();

  const {
    selectors: { selectedChild },
  } = useChildren();

  const [challengeToDelete, setChallengeToDelete] = useState<
    FirestoreChallenge | undefined
  >();
  const [showChallengeDeleteDialog, setShowChallengeDeleteDialog] =
    useState(false);

  const filteredChallengesForApproval = useMemo(() => {
    if (selectedChild) {
      return challengesForApproval.filter((c) => c.childId === selectedChild);
    } else {
      return challengesForApproval;
    }
  }, [challengesForApproval, selectedChild]);

  const onChallengeDelete = (challenge: FirestoreChallenge) => {
    setChallengeToDelete(challenge);
    setShowChallengeDeleteDialog(true);
  };

  const onChallengeDeleteConfirmed = async (challenge: FirestoreChallenge) => {
    try {
      await deleteChallenge(challenge);
    } catch (err) {
      handleUnknownError(err);
    }
  };

  return (
    <Grid container spacing={2}>
      {challengeFeatureFlagActive &&
      filteredChallengesForApproval.length > 0 ? (
        <Grid item xs={12}>
          {challengeLoading ? (
            <Loader />
          ) : (
            <ChallengesForApproval
              filteredChallengesForApproval={filteredChallengesForApproval}
              onChallengeDelete={onChallengeDelete}
            />
          )}
        </Grid>
      ) : null}
      <Grid item xs={12}>
        <FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardRoutines>
          <TodaysRoutinesCard />
        </FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardRoutines>
      </Grid>
      <Grid item xs={12}>
        <FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardRoutinesGraph>
          <TaskAnalysis
            loading={loading}
            period={period}
            periodText={periodText}
            setPeriod={setPeriod}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            periodOptions={periodOptions}
            data={data}
          />
        </FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardRoutinesGraph>
      </Grid>
      <Grid item xs={12}>
        <ProgressAnalysis
          loading={loading}
          period={period}
          periodText={periodText}
          setPeriod={setPeriod}
          periodOptions={periodOptions}
          data={data}
          periodStart={GraphTools.calculateRoutinePeriod(period).periodStart}
          weeks={GraphTools.progressGraphInWeeks(period)}
        />
      </Grid>
      <Dialogs.ConfirmationDialog
        open={showChallengeDeleteDialog}
        onClose={() => setShowChallengeDeleteDialog(false)}
        imgSrc={DialogAssets.CancelTag}
        title={t("routine.challenge.delete.title")}
        text={t("routine.challenge.delete.text")}
        onPositiveAction={() => {
          challengeToDelete && onChallengeDeleteConfirmed(challengeToDelete);
          setShowChallengeDeleteDialog(false);
        }}
        onNegativeAction={() => {
          setChallengeToDelete(undefined);
          setShowChallengeDeleteDialog(false);
        }}
      />
    </Grid>
  );
};

export default RoutineDashboard;
