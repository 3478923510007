import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  SxProps,
  Theme,
  Typography,
  accordionSummaryClasses,
  useTheme,
} from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import React, { PropsWithChildren, SetStateAction } from "react";

interface AccordionCardProps extends PropsWithChildren {
  title: string | JSX.Element;
  expanded: boolean;
  setExpanded: React.Dispatch<SetStateAction<boolean>>;
  detailsStyle?: SxProps<Theme>;
  sx?: SxProps<Theme>;
}

const AccordionCard = ({
  title,
  children,
  expanded,
  setExpanded,
  detailsStyle,
  sx,
}: AccordionCardProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
      style={{
        borderRadius: "10px",
        position: "relative",
      }}
      sx={sx}
    >
      <AccordionSummary
        sx={{
          paddingRight: 2,
          paddingLeft: 2,
          [`&.${accordionSummaryClasses.expanded}`]: {
            minHeight: "48px",
            [`& .${accordionSummaryClasses.content}`]: {
              marginTop: 1,
              marginBottom: 1,
            },
          },
          svg: {
            width: "1rem",
            height: "1rem",
          },
        }}
        expandIcon={
          <Icons.ArrowIcon
            arrowDirection="down"
            color={palette.secondary.main}
          />
        }
      >
        {typeof title === "string" ? (
          <Typography color="secondary" fontWeight="bold">
            {title}
          </Typography>
        ) : (
          title
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          paddingTop: 0,
          paddingBottom: 1,
          ...detailsStyle,
        }}
      >
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionCard;
