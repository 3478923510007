import {
  Button,
  Dialog,
  DialogActions,
  dialogClasses,
  DialogContent,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import BackgroundAssets from "assets/background";
import React, { ReactNode } from "react";

export interface NPSDialogProps
  extends Omit<DialogProps, "title" | "onSubmit"> {
  title: string | JSX.Element;
  onSubmit: VoidFunction;
  onSkip: VoidFunction;
  children: ReactNode;
  skipText: string;
  submitText: string;
}

const NPSDialog: React.FC<NPSDialogProps> = ({
  title,
  onSubmit,
  onSkip,
  children,
  skipText,
  submitText,
  ...props
}) => {
  return (
    <Dialog
      {...props}
      sx={{
        [`& .${dialogClasses.paper}`]: {
          marginX: "8px",
          flexGrow: 1,
          maxWidth: "500px",
        },
      }}
    >
      <DialogTitle
        sx={(theme) => ({
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `url(${BackgroundAssets.RedBackgroundRoutine})`,
          backgroundSize: "cover",
          backgroundOrigin: "center top",
          backgroundRepeat: "no-repeat",
          maxWidth: "100%",
          margin: 0,
          color: theme.palette.primary.contrastText,
          fontSize: "1rem",
        })}
      >
        {title}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onSkip}>{skipText}</Button>
        <Button onClick={onSubmit} variant="contained" color="secondary">
          {submitText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NPSDialog;
