import { SkillShortData } from "@neurosolutionsgroup/models";

const orderSkillShortData = (
  skills: SkillShortData[],
  skillIdTrans: (skillId: string) => string
) => {
  return skills.sort((skillA, skillB) => {
    if (skillA.ongoingChallenge && !skillB.ongoingChallenge) {
      return -1;
    } else if (!skillA.ongoingChallenge && skillB.ongoingChallenge) {
      return 1;
    }

    if (
      skillA.successPercentage !== null ||
      skillB.successPercentage !== null
    ) {
      return (
        (skillA.successPercentage !== null ? skillA.successPercentage : 100) -
        (skillB.successPercentage !== null ? skillB.successPercentage : 100)
      );
    } else {
      return skillIdTrans(skillA.skillId).localeCompare(
        skillIdTrans(skillB.skillId)
      );
    }
  });
};

export default orderSkillShortData;
