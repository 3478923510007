export enum PrescriptionsProgress {
  None = "none",
  Later = "FTUE_Prescriptions_Later",
  Intro = "FTUE_Prescriptions_Intro",
  DatePicker = "FTUE_Prescription_DatePicker",
  TrigerMedication = "FTUE_Prescription_Trigger_Medication",
  MedicationSelection = "FTUE_Prescription_Medication_Selection",
  Dosage = "FTUE_Prescription_Dosage",
  OtherMedication = "FTUE_Prescription_Other_Medication",
  SelectSideEffects = "FTUE_Prescription_Select_Side_Effects",
  Confirmation = "FTUE_Prescription_Confirmation",
  Finished = "FTUE_Prescription_Finished",
}

export const PRESCRIPTIONS_PROGRESS_TOTAL = 8;
