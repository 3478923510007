const ArrowIcon = (): JSX.Element => {
  return (
    <svg
      width="28"
      height="53"
      viewBox="0 0 28 53"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 12.3604,0 18.4144,2.52381 17.1532,41.1381 22.7027,31.8 H 28 L 15.8919,53 0,38.1095 5.54955,35.5857 13.1524,40.7848 Z"
        fill="#ffffff"
        id="path824"
      />
    </svg>
  );
};

export default ArrowIcon;
