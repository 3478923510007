import AddIcon from "./AddIcon";
import AlertIcon from "./AlertIcon";
import ArchiveIcon from "./ArchiveIcon";
import ArrowIcon from "./ArrowIcon";
import BackSpaceIcon from "./BackSpaceIcon";
import BarChartIcon from "./BarChartIcon";
import BurgerMenuIcon from "./BurgerMenuIcon";
import ChatIcon from "./ChatIcon";
import CheckBoxIcon from "./CheckBoxIcon";
import CheckMarkIcon from "./CheckMarkIcon";
import CircledCheckMarkIcon from "./CircledCheckMarkIcon";
import CloseIcon from "./CloseIcon";
import CopyIcon from "./CopyIcon";
import DashboardIcon from "./DashboardIcon";
import DeleteIcon from "./DeleteIcon";
import DragIcon from "./DragIcon";
import EditIcon from "./EditIcon";
import ExportIcon from "./ExportIcon";
import ExternalLinkIcon from "./ExternalLinkIcon";
import EyeIcon from "./EyeIcon";
import FaceIcon from "./FaceIcon";
import FullScreenIcon from "./FullScreenIcon";
import IconButton from "./IconButton";
import InformationIcon from "./InformationIcon";
import JournalIcon from "./JournalIcon";
import MedalIcon from "./MedalIcon";
import MedicationIcon from "./MedicationIcon";
import MenuIcon from "./MenuIcon";
import NotificationIcon from "./NotificationIcon";
import PictureIcon from "./PictureIcon";
import ProfileIcon from "./ProfileIcon";
import SaveIcon from "./SaveIcon";
import SearchIcon from "./SearchIcon";
import SubtractIcon from "./SubtractIcon";

const Icons = {
  AddIcon,
  AlertIcon,
  ArchiveIcon,
  ArrowIcon,
  BackSpaceIcon,
  BarChartIcon,
  BurgerMenuIcon,
  ChatIcon,
  CheckBoxIcon,
  CheckMarkIcon,
  CircledCheckMarkIcon,
  CloseIcon,
  CopyIcon,
  DashboardIcon,
  DeleteIcon,
  DragIcon,
  EditIcon,
  ExportIcon,
  ExternalLinkIcon,
  EyeIcon,
  FaceIcon,
  FullScreenIcon,
  InformationIcon,
  JournalIcon,
  MedalIcon,
  MedicationIcon,
  MenuIcon,
  NotificationIcon,
  PictureIcon,
  ProfileIcon,
  SaveIcon,
  SearchIcon,
  SubtractIcon,
};

export { IconButton };

export default Icons;
