import { isMonday, nextMonday, previousMonday } from "date-fns";
import Time from "../../../Time";

const calculateChallengeStartDate = (
  creationDateSeconds: number,
  startThisWeek: boolean,
  approval = false
): string => {
  const creationDateMS = creationDateSeconds * 1000;

  const creationIsMonday = isMonday(creationDateMS);

  if (approval && creationIsMonday) {
    const previousMondayDate = new Date(creationDateMS);

    // Set to midnight on monday.
    previousMondayDate.setHours(0, 0, 0);

    return Time.Strings.getDateStringFromDate(previousMondayDate);
  }

  if (startThisWeek) {
    const previousMondayDate = creationIsMonday
      ? new Date(creationDateMS)
      : previousMonday(creationDateMS);

    // Set to midnight on monday.
    previousMondayDate.setHours(0, 0, 0);

    return Time.Strings.getDateStringFromDate(previousMondayDate);
  } else {
    const nextMondayAfterCreation = nextMonday(creationDateMS);

    // Set to midnight on monday.
    nextMondayAfterCreation.setHours(0, 0, 0);

    return Time.Strings.getDateStringFromDate(nextMondayAfterCreation);
  }
};

export default calculateChallengeStartDate;
