import { Typography, TypographyProps } from "@mui/material";
import React from "react";

interface CharacterCountProps extends TypographyProps {
  text: string;
  maxLength: number;
}

const CharacterCount = ({
  text,
  maxLength,
  ...props
}: CharacterCountProps): JSX.Element => {
  return (
    <Typography mt={1} ml={1} sx={{ fontSize: "0.75 rem" }} {...props}>
      {text.length}/{maxLength}
    </Typography>
  );
};

export default CharacterCount;
