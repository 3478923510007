import {
  Box,
  Button,
  ButtonBase,
  Drawer,
  Grid,
  Typography,
} from "@mui/material";
import { StaticWeekPicker } from "@neurosolutionsgroup/components";
import { Tools } from "@neurosolutionsgroup/tools";
import MiscAssets from "assets/misc";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import DateOptions from "./DateOptions";

interface SimpleDatePickerProps {
  date: Date;
  setDate: React.Dispatch<SetStateAction<Date>>;
  pastDays?: number;
  taggedDates?: Date[];
  label: string;
  withTitle?: boolean;
  minDate?: Date;
  maxDate?: Date;
  disableFuture?: boolean;
}

const SimpleDatePicker: React.FC<SimpleDatePickerProps> = ({
  date,
  setDate,
  pastDays = 7,
  taggedDates,
  label,
  withTitle,
  minDate,
  maxDate,
}) => {
  // The "first" (latest) date to be shown in the carousel.
  const [rootDate, setRootDate] = useState(date);
  const [openCalendarDrawer, setOpenCalendarDrawer] = useState(false);
  const [calendarDate, setCalendarDate] = useState(rootDate);

  const { t } = useTranslation();

  useEffect(() => {
    setCalendarDate(rootDate);
  }, [rootDate]);

  const setDateFromUTC = useCallback(
    (value: Date): void => {
      setDate(value);
    },
    [setDate]
  );

  const onDatePickerClose = () => {
    setCalendarDate(rootDate);
    setOpenCalendarDrawer(false);
  };

  const onDatePickerChange = (date: unknown) => {
    if (!(date instanceof Date)) {
      return;
    }

    setCalendarDate(Tools.Time.Dates.getMidnightDate(date));
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent={withTitle ? "space-between" : "flex-end"}
        alignItems="center"
        sx={{ marginBottom: "0.5em" }}
      >
        {withTitle && <h3>{label}</h3>}

        <ButtonBase
          onClick={() => setOpenCalendarDrawer(true)}
          sx={{
            margin: "0.25rem",
            padding: "0.25rem",
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            img: {
              height: "2rem",
              width: "2rem",
            },
          }}
        >
          <img src={MiscAssets.CalendarIcon} alt="Calendar" />
        </ButtonBase>
      </Box>
      <DateOptions
        rootDate={rootDate}
        setDate={setDateFromUTC}
        pastDays={pastDays}
        date={date}
        taggedDates={taggedDates}
      />
      <Drawer
        anchor="bottom"
        open={openCalendarDrawer}
        onClose={onDatePickerClose}
      >
        <Box p={2}>
          <DrawerSection variant="header">
            <Typography variant="h4">{label}</Typography>
          </DrawerSection>
          <Grid container justifyContent="center" spacing={2}>
            <Grid
              item
              xs={12}
              sx={(theme) => ({
                "& .Mui-selected": {
                  backgroundColor: theme.palette.secondary.main + " !important",
                },
              })}
            >
              <StaticWeekPicker
                date={calendarDate}
                onDatePickerChange={onDatePickerChange}
                minDate={minDate}
                maxDate={maxDate}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="secondary"
                disableElevation={true}
                onClick={() => {
                  setDate(calendarDate);
                  setRootDate(calendarDate);
                  setOpenCalendarDrawer(false);
                }}
              >
                {t("general.actions.confirm")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
    </>
  );
};

export default SimpleDatePicker;
