import { PaletteOptions } from "@mui/material/styles/createPalette";

export const palette: PaletteOptions = {
  primary: {
    main: "#dd4f4b",
    dark: "#ef4044",
    contrastText: "#fff",
  },
  secondary: {
    main: "#31737c",
    contrastText: "#fff",
  },
  error: {
    main: "#ef4145",
  },
  success: {
    main: "#2cd25a",
    light: "#a6da2d",
    contrastText: "#fff",
  },
  white: {
    main: "#fff",
  },
  background: { default: "#f5efe7", paper: "#fff" },
};
