import { CloseablePage, TextInput } from "common/Components";
import useRoutines from "common/hooks/routines/useRoutines";
import useChildren from "common/hooks/children/useChildren";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import { RoutineOperation } from "../ObjectivesSection";
import ChildPicker from "./ChildPicker/ChildPicker";
import DayPicker from "./DayPicker/DayPicker";
import TaskDisplay from "./TaskDisplay/TaskDisplay";
import { useCoach } from "common/hooks/messaging/Coach";
import { Box, Grid } from "@mui/material";
import { PageSection } from "common/Components/PageSection";
import RoutineTimePicker, {
  RoutinePeriod,
} from "./TimePicker/RoutineTimePicker";
import { TimeRangePicker } from "common/Components/TimePicker";
import {
  NotificationCategory,
  Routine,
  Task,
} from "@neurosolutionsgroup/models";
import { Trans, useTranslation } from "react-i18next";
import CoachErrorDialog, {
  CoachError,
  MAXIMUM_ACTIVE_ROUTINES,
} from "common/hooks/messaging/Coach/CoachErrorDialog";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import RoutineEditionLogic from "./RoutineEditionLogic";
import {
  calculateStickyButtonPagePadding,
  Dialogs,
  Icons,
  Loader,
  SelectInput,
  StickyButtons,
} from "@neurosolutionsgroup/components";
import NotificationOptInPrompt, {
  NotifOptInPromptOnCloseArgs,
} from "pages/Settings/NotificationSettings/NotificationOptInPrompt";
import useNotifications from "common/hooks/notifications/useNotifications";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import DialogAssets from "assets/dialogs";
import { SAFE_AREAS } from "stylesheets";
import useSubscription from "common/hooks/subscription/useSubscription";
import { PremiumFeature } from "@neurosolutionsgroup/analytics";

interface RoutineEditionProps {
  show: boolean;
  setShow: React.Dispatch<SetStateAction<boolean>>;
  routine?: Routine;
  operation: RoutineOperation;
}

const RoutineEdition: React.FC<RoutineEditionProps> = ({
  show,
  setShow,
  routine,
  operation,
}) => {
  const ROOT_CLASS = "routine-edit";

  // Hooks.
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();
  const { onRoutineActivated } = useCoach();
  const { globalOptIn, notificationsSettings, optInLastSeen } =
    useNotifications();
  const {
    selectors: { ftueRunning },
  } = useFTUE();
  const { onPremiumFeatureClick, permissions } = useSubscription();

  const {
    selectors: { childIds, childrenById },
  } = useChildren();

  const {
    selectors: { loading },
    actions: { updateRoutine, getActiveRoutines },
  } = useRoutines();

  // Form state.
  const [name, setName] = useState("");
  const [days, setDays] = useState(0);
  const [children, setChildren] = useState<string[]>([]);
  const [period, setPeriod] = useState<RoutinePeriod | null>(null);
  const [start, setStart] = useState<number>(0);
  const [end, setEnd] = useState<number>(900);
  const [notification, setNotification] = useState<number | null>(0);
  const [tasks, setTasks] = useState<Task[]>([]);

  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [modified, setModified] = useState(false);
  const [showActiveRoutineLimit, setShowActiveRoutineLimit] = useState(false);
  const [showOverlappingRoutinesWarning, setShowOverlappingRoutinesWarning] =
    useState(false);
  const [showNotificationOptIn, setShowNotificationOptIn] =
    useState<boolean>(false);

  const resetForm = () => {
    setName("");
    setDays(0);
    setChildren([]);
    setPeriod(null);
    setStart(0);
    setEnd(900);
    setNotification(0);
    setTasks([]);
  };

  // Initial setup on reception of routine.
  useEffect(() => {
    if (routine) {
      setName(routine.name);
      setDays(routine.days);
      // Filter children that have been deleted since last edition.
      setChildren(routine.users.filter((uid) => !!childrenById[uid]));
      setStart(routine.start);
      setEnd(routine.end);
      if (
        (ftueRunning && optInLastSeen === undefined) ||
        (globalOptIn && notificationsSettings[NotificationCategory.Routine])
      ) {
        setNotification(routine.alert);
      } else {
        setNotification(null);
      }
      setTasks(
        routine.tasks.map((t) => ({
          ...t,
          creation: t.creation ?? Tools.Time.Dates.getTimeStamp(),
        }))
      );

      if (routine.icon !== 0) {
        setPeriod(
          routine.templateId && routine.templateId > 0
            ? routine.templateId
            : null
        );
      } else {
        setPeriod(null);
      }
    }
  }, [routine]);

  // Detect modification to form state.
  useEffect(() => {
    if (routine && !modified && name.length > 0) {
      if (
        name !== routine.name ||
        days !== routine.days ||
        children !== routine.users ||
        start !== routine.start ||
        end !== routine.end ||
        notification !== routine.alert ||
        tasks !== routine.tasks
      ) {
        setModified(true);
      }
    }
  });

  // Remove reminders when routine time changed.
  const onSetStart = (newStart: number): void => {
    if (newStart !== start) {
      setTasks((current) => {
        const newTasks = current;

        newTasks.forEach((t) => {
          t.reminder = 0;
        });

        return newTasks;
      });
    }
    setStart(newStart);
  };

  // Remove reminders when routine time changed.
  const onSetEnd = (newEnd: number): void => {
    if (newEnd !== end) {
      setTasks((current) => {
        const newTasks = current;

        newTasks.forEach((t) => {
          t.reminder = 0;
        });

        return newTasks;
      });
    }
    setEnd(newEnd);
  };

  // Check if routine length warning should be shown.
  const validatePeriod = (
    newStart: number,
    newEnd: number
  ): string | undefined => {
    if (newEnd - newStart > 90 * 60) {
      return t("routine.edit.inputs.time.lengthError");
    }

    return undefined;
  };

  // Validate form data.
  const isSubmittable = useCallback((): boolean => {
    return (
      name.length > 0 &&
      days > 0 &&
      children.length > 0 &&
      tasks.filter((t) => !t.deleted).length > 0
    );
  }, [name, days, children, tasks]);

  const onSubmit = async () => {
    if (
      routine &&
      !RoutineEditionLogic.validateActiveRoutinesCount(
        children,
        days,
        getActiveRoutines,
        routine.id,
        MAXIMUM_ACTIVE_ROUTINES
      )
    ) {
      setShowActiveRoutineLimit(true);
      return;
    }

    if (
      routine &&
      !RoutineEditionLogic.validateRoutinesDontOverlap(
        children,
        getActiveRoutines,
        routine.id,
        days,
        start,
        end
      )
    ) {
      setShowOverlappingRoutinesWarning(true);
      return;
    }

    updateEditedRoutine();
  };

  const onActiveRoutineLimitConfirmed = async () => {
    setShowActiveRoutineLimit(false);
    updateEditedRoutine(true);
  };

  const onOverlappingRoutineConfirmed = async () => {
    setShowOverlappingRoutinesWarning(false);
    updateEditedRoutine(true);
  };

  const updateEditedRoutine = async (disabled?: boolean) => {
    if (!routine) {
      return;
    }

    const orderedTasks = tasks.map((t, index) => ({ ...t, index }));

    const routineToSubmit: Routine = {
      id: routine.id,
      templateId: routine.templateId,
      icon: routine.icon,
      name: name,
      version: routine.version,
      disabled: disabled !== undefined ? disabled : routine.disabled ?? false,
      deleted: false,
      locked: null,
      days: days,
      start: start,
      end: end,
      alert: notification,
      tasks: orderedTasks,
      users: children,
      inactivePeriods: routine.inactivePeriods,
      lastDisabledDate: routine.lastDisabledDate,
      deletedDate: null,
    };

    return updateRoutine(routineToSubmit)
      .then(() => {
        onRoutineActivated(routineToSubmit);
        setShow(false);
      })
      .catch((err) => {
        handleUnknownError(err);
      });
  };

  const onClose = () => {
    modified ? setShowCancelDialog(true) : setShow(false);
  };

  const getNotificationOptions = useCallback(() => {
    return [
      {
        label: t("routine.edit.inputs.notification.options.none"),
        value: null,
      },
      {
        label: t("routine.edit.inputs.notification.options.0"),
        value: 0,
      },
      {
        label: t("routine.edit.inputs.notification.options.300"),
        value: 300,
      },
      {
        label: t("routine.edit.inputs.notification.options.600"),
        value: 600,
      },
      {
        label: t("routine.edit.inputs.notification.options.900"),
        value: 900,
      },
      {
        label: t("routine.edit.inputs.notification.options.1200"),
        value: 1200,
      },
      {
        label: t("routine.edit.inputs.notification.options.1500"),
        value: 1500,
      },
      {
        label: t("routine.edit.inputs.notification.options.1800"),
        value: 1800,
      },
    ];
  }, [language]);

  const handleNotificationChange = (newValue: number | null) => {
    if (newValue === null) {
      setNotification(newValue);
      return;
    }
    if (ftueRunning || optInLastSeen) {
      setNotification(newValue);
      return;
    }
    if (!globalOptIn || !notificationsSettings[NotificationCategory.Routine]) {
      setShowNotificationOptIn(true);
    }
    setNotification(newValue);
  };

  const handleOptInClose = (
    notifSettings: NotifOptInPromptOnCloseArgs | undefined
  ) => {
    if (!notifSettings || !notifSettings.childNotif) {
      setNotification(null);
    }
    setShowNotificationOptIn(false);
  };

  return show && routine ? (
    <CloseablePage
      isOpen={show && !!routine}
      onClose={onClose}
      closeElement={<></>}
      className={ROOT_CLASS}
      header={
        <h2 className={ROOT_CLASS + "__title"}>
          {operation === RoutineOperation.Edit
            ? t("routine.edit.title.edit")
            : operation === RoutineOperation.Copy
            ? t("routine.edit.title.copy")
            : t("routine.edit.title.add")}
        </h2>
      }
      withCoach
    >
      {loading ? <Loader /> : null}
      {showNotificationOptIn ? (
        <NotificationOptInPrompt
          open={showNotificationOptIn}
          onClose={(value) => handleOptInClose(value)}
        />
      ) : null}
      <FTUEFlowDefinitions.FirstRoutineFTUEFlow.Hints.RoutineConfirmation
        onContinue={onSubmit}
        continueDisabled={!isSubmittable()}
      >
        <Grid
          container
          className={ROOT_CLASS + "__page-container"}
          sx={{
            paddingBottom: calculateStickyButtonPagePadding(SAFE_AREAS),
          }}
        >
          <Grid item xs={12} container direction="row" spacing={2}>
            <Grid item xs={6}>
              <PageSection
                title={t("routine.edit.inputs.title.label")}
                showBottomBorder={false}
              >
                <TextInput
                  data-cy="routine-title"
                  placeholder={t("routine.edit.inputs.title.placeholder")}
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                />
              </PageSection>
            </Grid>
            <Grid item xs={6}>
              <PageSection
                title={t("routine.edit.inputs.notification.label")}
                showBottomBorder={false}
                info={t("routine.edit.inputs.notification.info")}
              >
                <SelectInput
                  data-cy="notification-selector"
                  label={t("routine.edit.inputs.notification.label")}
                  leftIndicator={<Icons.NotificationIcon color="#31737c" />}
                  hideIndicator
                  placeholder={t(
                    "routine.edit.inputs.notification.options.none"
                  )}
                  classes={{
                    root: "routine-edit__input--notification",
                  }}
                  options={getNotificationOptions()}
                  value={notification}
                  onChange={(o) =>
                    handleNotificationChange(o.value as number | null)
                  }
                  truncateOnOverflow
                  fullWidth
                  safeAreas={SAFE_AREAS}
                />
              </PageSection>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <PageSection
              title={t("routine.edit.inputs.days.label")}
              showBottomBorder={false}
            >
              <DayPicker value={days} setValue={setDays} />
            </PageSection>
          </Grid>
          <Grid item xs={12}>
            <PageSection
              title={t("routine.edit.inputs.time.label")}
              showBottomBorder={false}
            >
              {operation === RoutineOperation.Add ? (
                <RoutineTimePicker
                  period={period}
                  setPeriod={setPeriod}
                  start={start}
                  setStart={onSetStart}
                  end={end}
                  setEnd={onSetEnd}
                  language={language}
                  validatePeriod={validatePeriod}
                />
              ) : (
                <TimeRangePicker
                  start={start}
                  setStart={onSetStart}
                  end={end}
                  setEnd={onSetEnd}
                  language={language}
                  validatePeriod={validatePeriod}
                />
              )}
            </PageSection>
          </Grid>
          <Grid item xs={12}>
            <PageSection
              title={t("routine.edit.inputs.children.label")}
              info={[
                t("routine.edit.inputs.children.info.one"),
                t("routine.edit.inputs.children.info.two"),
                t("routine.edit.inputs.children.info.three"),
              ]}
            >
              <ChildPicker
                children={childIds.map((u) => {
                  return childrenById[u];
                })}
                pickedChildren={children}
                setPickedChildren={setChildren}
                lockDisabledChildren={!permissions.childCreation}
                onLockedChildClick={() =>
                  onPremiumFeatureClick(PremiumFeature.LockedChild)
                }
              />
            </PageSection>
          </Grid>
          <Grid item xs={12}>
            <PageSection
              title={t("routine.edit.inputs.tasks.label")}
              showBottomBorder={false}
              info={
                <Box
                  sx={{
                    svg: {
                      width: "1rem",
                      height: "1rem",
                      marginBottom: "-0.2rem",
                      marginX: "0.2rem",
                    },
                  }}
                >
                  <Trans i18nKey="routine.edit.inputs.tasks.info">
                    You can create new tasks with the + or simply press on a
                    task to edit it. Press and hold&nbsp;
                    <Icons.DragIcon color="#31737c" /> to move a task.
                  </Trans>
                </Box>
              }
            >
              <TaskDisplay
                tasks={tasks}
                setTasks={setTasks}
                routine={routine}
                routineStart={start}
                routineEnd={end}
              />
            </PageSection>
          </Grid>
        </Grid>
      </FTUEFlowDefinitions.FirstRoutineFTUEFlow.Hints.RoutineConfirmation>
      <StickyButtons
        onCancel={onClose}
        onConfirm={onSubmit}
        disabled={!isSubmittable()}
      />

      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        title={
          operation === RoutineOperation.Edit
            ? t("general.cancel.title")
            : t("routine.edit.cancel.title")
        }
        text={
          operation === RoutineOperation.Edit
            ? t("general.cancel.generic")
            : t("routine.edit.cancel.text")
        }
        imgSrc={DialogAssets.CancelTag}
        onPositiveAction={() => {
          setShowCancelDialog(false);
          setShow(false);
          resetForm();
        }}
        onNegativeAction={() => {
          setShowCancelDialog(false);
        }}
        hideIrreversibleWarning
      />

      <CoachErrorDialog
        open={showActiveRoutineLimit}
        onConfirm={onActiveRoutineLimitConfirmed}
        onClose={() => setShowActiveRoutineLimit(false)}
        error={CoachError.ActiveRoutines}
        extraText={
          <>
            {t(`coach.errors.${CoachError.ActiveRoutines}.editText`)}
            <br />
            <br />
            {t(`coach.errors.${CoachError.ActiveRoutines}.editTextAction`)}
          </>
        }
      />
      <CoachErrorDialog
        open={showOverlappingRoutinesWarning}
        onConfirm={onOverlappingRoutineConfirmed}
        onClose={() => setShowOverlappingRoutinesWarning(false)}
        error={CoachError.RoutinesOverlap}
        overwriteTitle={t(
          `coach.errors.${CoachError.RoutinesOverlap}.titleEdit`
        )}
        overwriteText={
          <>
            {t(`coach.errors.${CoachError.RoutinesOverlap}.editText`)}
            <br />
            <br />
            {t(`coach.errors.${CoachError.RoutinesOverlap}.editTextAction`)}
          </>
        }
      />
    </CloseablePage>
  ) : null;
};

export default RoutineEdition;
