import { FirestoreChallenge } from "@neurosolutionsgroup/models";

/**
 * Coalesce new challenge properties on old challenges if they did not exist.
 * @param challenge - Challenge from Firestore.
 * @returns - FirestoreChallenge with all properties.
 */
export const coalesceFirestoreChallengeToV2 = (
  challenge: FirestoreChallenge
): FirestoreChallenge => {
  return {
    ...challenge,
    lastChildExecution: challenge.lastChildExecution ?? null,
    childNotificationsCount: challenge.childNotificationsCount ?? null,
    weeklyNotificationsCount: challenge.weeklyNotificationsCount ?? null,
    occurrences: challenge.occurrences ?? null,
    skillId: challenge.skillId ?? null,
    version: challenge.version ?? null,
    frequency: challenge.frequency ?? null,
    duration: challenge.duration ?? null,
  };
};
