import { PropsWithChildren } from "react";
import {
  Box,
  Drawer,
  SxProps,
  Theme,
  drawerClasses,
  useTheme,
} from "@mui/material";

export interface FullScreenDrawerPageProps extends PropsWithChildren {
  open: boolean;
  anchor?: "bottom" | "left" | "right" | "top";
  sx?: SxProps<Theme>;
}

const FullScreenDrawerPage = ({
  open,
  anchor = "bottom",
  sx,
  ...props
}: FullScreenDrawerPageProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Drawer
      open={open}
      anchor={anchor}
      sx={[
        {
          [`& .${drawerClasses.paper}`]: [
            {
              height: "100%",
              maxHeight: "100%",
              borderRadius: 0,
              border: "none",
              overflow: "hidden",
            },
          ],
        },
      ]}
      variant="persistent"
    >
      <Box
        sx={[
          {
            position: "relative",
            width: "100%",
            height: "100%",
            backgroundColor: palette.background.default,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {props.children}
      </Box>
    </Drawer>
  );
};

export default FullScreenDrawerPage;
