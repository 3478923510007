import {
  Backdrop,
  Card,
  DialogContent,
  DialogTitle,
  SxProps,
  Theme,
} from "@mui/material";

interface FtueDialogProps {
  open: boolean;
  title: string;
  content: JSX.Element;
  backdropContent?: JSX.Element;
  dataCy?: string;
  onClose?: VoidFunction;
  sx?: SxProps<Theme>;
  withoutPadding?: boolean;
}

const FtueDialog = ({
  open,
  title,
  content,
  backdropContent,
  dataCy,
  onClose,
  sx,
  withoutPadding,
}: FtueDialogProps): JSX.Element => {
  return (
    <>
      <Backdrop
        open={open}
        onClick={onClose}
        sx={{
          zIndex: 100,
        }}
      >
        <Card
          sx={[
            {
              minWidth: "80vw",
              backgroundColor: "#fff",
              position: "relative",
              zIndex: 1,
              margin: 2,
            },
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
          data-cy={dataCy}
        >
          <DialogTitle
            textTransform="uppercase"
            sx={{ fontSize: "1.25rem", paddingX: 1 }}
          >
            {title}
          </DialogTitle>
          <DialogContent
            sx={{
              padding: withoutPadding ? 0 : undefined,
              overflow: "visible",
            }}
          >
            {content}
          </DialogContent>
        </Card>
        {backdropContent}
      </Backdrop>
    </>
  );
};

export default FtueDialog;
