import { Box, Button, Divider, Typography } from "@mui/material";
import {
  NotificationCategorySettings,
  NotificationCategory,
} from "@neurosolutionsgroup/models";
import BackgroundAssets from "assets/background";
import DynamismAssets from "assets/dynamism";
import { Loader } from "common/Components";
import useNotifications from "common/hooks/notifications/useNotifications";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import NotificationSwitch from "./NotificationSwitch";
import { SAFE_AREA_BOTTOM, SAFE_AREA_TOP } from "stylesheets";

export interface NotifOptInPromptOnCloseArgs {
  childNotif: boolean;
  parentNotif: boolean;
  newsNotif: boolean;
}

export interface NotificationOptInPromptProps {
  open: boolean;
  onClose: (value?: NotifOptInPromptOnCloseArgs) => void;
  variant?: "default" | "2actions";
}

const NotificationOptInPrompt: React.FC<NotificationOptInPromptProps> = ({
  open,
  onClose,
  variant = "default",
}) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "settings.notificationsPrompt.optIn",
  });

  const { updateSettings, requiresNativePermission, setOptInLastSeen } =
    useNotifications();

  //#region state
  const [childNotif, setChildNotif] = useState<boolean>(true);
  const [parentNotif, setParentNotif] = useState<boolean>(true);
  const [newsNotif, setNewsNotif] = useState<boolean>(true);
  const [loading, setLoading] = useState(false);
  //#endregion

  useEffect(() => {
    if (open && !requiresNativePermission) {
      onSave();
    }
  }, [open]);

  //#region OptIn groups props
  const childGroupProps: OptInGroupProps = {
    title: t("child.title"),
    description: t("child.description"),
    state: childNotif,
    onChange: () => setChildNotif(!childNotif),
  };
  const parentGroupProps: OptInGroupProps = {
    title: t("parent.title"),
    description: t("parent.description"),
    state: parentNotif,
    onChange: () => setParentNotif(!parentNotif),
  };
  const newsGroupProps: OptInGroupProps = {
    title: t("news.title"),
    description: t("news.description"),
    state: newsNotif,
    onChange: () => setNewsNotif(!newsNotif),
  };
  //#endregion

  const onSave = async () => {
    setLoading(true);
    setOptInLastSeen();

    const globalOptIn: boolean = childNotif || parentNotif || newsNotif;
    if (!globalOptIn) {
      onClose();
      return;
    }
    const settings: NotificationCategorySettings = {
      [NotificationCategory.Routine]: childNotif,
      [NotificationCategory.General]: parentNotif,
      [NotificationCategory.Validation]: parentNotif,
      [NotificationCategory.Observation]: parentNotif,
      [NotificationCategory.Coach]: parentNotif,
      [NotificationCategory.News]: newsNotif,
      [NotificationCategory.Promotion]: newsNotif,
    };

    try {
      await updateSettings(globalOptIn, settings);
      setLoading(false);
      onClose({ childNotif, parentNotif, newsNotif });
    } catch (err) {
      // Don't block the user if error.
      setLoading(false);
      onClose({ childNotif: false, parentNotif: false, newsNotif: false });
    }
  };

  const onCancel = () => {
    onClose();
  };

  const shouldShowPrompt = open && requiresNativePermission;

  return shouldShowPrompt ? (
    <Box
      sx={{
        position: "fixed",
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "#1FB9B9",
        backgroundImage: `url(${BackgroundAssets.OptInBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        overflowY: "auto",
      }}
    >
      {loading ? <Loader /> : null}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        sx={{
          minHeight: "100%",
          paddingTop: SAFE_AREA_TOP,
          paddingBottom: SAFE_AREA_BOTTOM,
        }}
      >
        <Box
          flexGrow={2}
          marginTop={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            img: { maxWidth: "30vh" },
          }}
        >
          <img src={DynamismAssets.NotificationOptIn} alt="" />
        </Box>
        <Typography
          textAlign="center"
          variant="h2"
          color="#fff"
          sx={{ mx: 1, mb: 1, mt: "0.5" }}
        >
          {t("title")}
        </Typography>
        <Box display="flex" flexDirection="column" flexGrow={1} sx={{ mx: 1 }}>
          <OptInGroup
            title={childGroupProps.title}
            description={childGroupProps.description}
            state={childGroupProps.state}
            onChange={childGroupProps.onChange}
          />
          <Divider sx={{ marginX: 1, marginY: 0.5 }} />
          <OptInGroup
            title={parentGroupProps.title}
            description={parentGroupProps.description}
            state={parentGroupProps.state}
            onChange={parentGroupProps.onChange}
          />
          <Divider sx={{ marginX: 1, marginY: 0.5 }} />
          <OptInGroup
            title={newsGroupProps.title}
            description={newsGroupProps.description}
            state={newsGroupProps.state}
            onChange={newsGroupProps.onChange}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          marginTop={1}
          marginBottom={2}
          marginX={1}
        >
          <Button
            data-cy="notification-save"
            variant="text"
            onClick={onSave}
            sx={{ mb: 1 }}
          >
            <Typography fontWeight="bold">{t("buttons.save")}</Typography>
          </Button>
          {variant === "2actions" ? (
            <Button variant="text" onClick={onCancel}>
              <Typography fontWeight="bold">{t("buttons.notNow")}</Typography>
            </Button>
          ) : null}
        </Box>
      </Box>
    </Box>
  ) : null;
};
export default NotificationOptInPrompt;

export interface OptInGroupProps {
  title: string;
  description: string;
  state: boolean;
  onChange: VoidFunction;
}

const OptInGroup: React.FC<OptInGroupProps> = ({
  title,
  description,
  state,
  onChange,
}) => {
  const handleChange = () => {
    onChange();
  };
  return (
    <Box display="flex" justifyContent="space-between" sx={{ m: 1 }}>
      <Box sx={{ width: "80%" }}>
        <Typography fontWeight="bold" color="#fff">
          {title}
        </Typography>
        <Box>
          <Typography color="#fff">{description}</Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" onClick={handleChange}>
        <NotificationSwitch checked={state} />
      </Box>
    </Box>
  );
};
