import { Box, Typography } from "@mui/material";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";
import { CloseablePage } from "common/Components";
import SideEffectForm, {
  SideEffectFormState,
} from "pages/Medication/SideEffectForm/SideEffectForm";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ROOT_CLASS = "edit-side-effect";

export interface CorrectSideEffectsProps {
  open: boolean;
  sideEffects: string[];
  onConfirm: (values: string[]) => void;
  onClose: VoidFunction;
}

const CorrectSideEffects: React.FC<CorrectSideEffectsProps> = ({
  open,
  sideEffects,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [sideEffectsState, setSideEffectsState] =
    useState<string[]>(sideEffects);
  const [showCancelDialog, setShowCancelDialog] = useState<boolean>(false);

  return (
    <CloseablePage
      isOpen={open}
      onClose={onClose}
      closeElement={<></>}
      className={ROOT_CLASS}
      header={
        <Box
          className={ROOT_CLASS + "--title title-header"}
          display="flex"
          justifyContent="start"
        >
          <Typography variant="h1" color="#fff">
            {t("general.sideEffect")}
          </Typography>
        </Box>
      }
    >
      <SideEffectForm
        selectedSideEffects={sideEffectsState}
        setSelectedSideEffects={setSideEffectsState}
        onSubmit={() => {
          onConfirm(sideEffectsState);
          onClose();
        }}
        onCancel={() => setShowCancelDialog(true)}
        formState={SideEffectFormState.edition}
      />

      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        onPositiveAction={onClose}
        onNegativeAction={() => setShowCancelDialog(false)}
        imgSrc={DialogAssets.ModificationTag}
      />
    </CloseablePage>
  );
};
export default CorrectSideEffects;
