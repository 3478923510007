import { Divider, Typography } from "@mui/material";
import { Drawer } from "common/Components";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import React, { useEffect, useState } from "react";
import { IconPinDisplay, IconPinInput } from "@neurosolutionsgroup/components";
import PinAssets from "assets/pin-icons";

interface IconPinDrawerInputProps {
  label: string;
  onChange: (value: string) => void;
  password: string | null;
}

const IconPinDrawerInput: React.FC<IconPinDrawerInputProps> = ({
  label,
  onChange,
  password,
}) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(password ?? "");

  useEffect(() => {
    setValue(password ?? "");
  }, [password]);

  return (
    <>
      <IconPinDisplay
        clickable={true}
        clearable={false}
        value={value}
        setValue={setValue}
        onClick={() => setOpen(true)}
        pinIcons={PinAssets.PinIcons}
      />
      <Drawer open={open} onClose={() => setOpen(false)} anchor="bottom">
        <DrawerSection variant="header">
          <Typography variant="h4">{label}</Typography>
        </DrawerSection>
        <Divider
          sx={(theme) => ({ borderColor: theme.palette.secondary.main })}
        />
        <IconPinInput
          value={value}
          setValue={setValue}
          onConfirm={(newValue) => {
            onChange(newValue);
            setOpen(false);
          }}
          pinIcons={PinAssets.PinIcons}
        />
      </Drawer>
    </>
  );
};

export default IconPinDrawerInput;
