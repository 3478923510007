import { Box, Button, Typography } from "@mui/material";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import Toast from "common/Components/Toast/Toast";
import useChildren from "common/hooks/children/useChildren";
import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

interface ClearAllValidationsProps {
  validationsCount: number;
}

const ClearAllValidations = ({
  validationsCount,
}: ClearAllValidationsProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { selectedChild },
    actions: { clearAllValidations },
  } = useChildren();

  const [openWarning, setOpenWarning] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openCoach, setOpenCoach] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    // Allow clear all if enough validations, and persist state throughout session.
    setDisabled((current) => {
      if (validationsCount >= 100) {
        return false;
      } else if (validationsCount === 0) {
        return true;
      } else {
        return current;
      }
    });
  }, [validationsCount]);

  const onClearAllValidations = () => {
    setOpenWarning(true);
  };

  const onClearAllValidationsConfirmed = () => {
    if (selectedChild) {
      clearAllValidations(selectedChild);

      setOpenSuccess(true);
    }

    setOpenWarning(false);
  };

  return (
    <Box my={1} ml="auto" display="flex" justifyContent="flex-end">
      <Button
        onClick={onClearAllValidations}
        disabled={disabled}
        variant="text"
        color="primary"
        sx={(theme) => ({
          borderRadius: "5px",
          paddingX: theme.spacing(1),
          minWidth: 0,
        })}
      >
        {t("validation.routines.clearall.button")}
      </Button>

      <Dialogs.AlertDialog
        open={openWarning}
        onClose={() => setOpenWarning(false)}
        title={t("validation.routines.clearall.title")}
        text={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              img: {
                maxWidth: "100px",
              },
            }}
            px={2}
          >
            <img src={DialogAssets.ArchiveTag} alt="archive" />
            <Typography textAlign="center" fontSize="0.8rem" mt={1}>
              {t("general.cancel.sure")}
            </Typography>
            <Typography textAlign="center" fontSize="0.8rem">
              {t("validation.routines.clearall.text")}
            </Typography>
          </Box>
        }
        positiveAction={{
          text: t("general.actions.yes"),
          action: () => onClearAllValidationsConfirmed(),
        }}
        negativeAction={{
          text: t("general.actions.no"),
          action: () => setOpenWarning(false),
        }}
      />
      <Toast
        open={openSuccess}
        onClose={() => setOpenSuccess(false)}
        timeout={4000}
        title={t("validation.routines.clearall.success.title")}
        text={t("validation.routines.clearall.success.text")}
      />
      <InfoDrawer
        open={openCoach}
        onClose={() => setOpenCoach(false)}
        title={t("validation.routines.clearall.coach.title")}
        text={
          <Trans
            i18nKey="validation.routines.clearall.coach.text"
            components={{ bold: <strong /> }}
          />
        }
      />
    </Box>
  );
};

export default ClearAllValidations;
