import { RefreshOutlined as RefreshOutlinedIcon } from "@mui/icons-material";
import { ButtonBase, SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ResetButtonProps {
  onClick: VoidFunction;
  sx?: SxProps<Theme>;
  text?: string;
}

const ResetButton = ({ onClick, sx, text }: ResetButtonProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <ButtonBase
      onClick={onClick}
      sx={[
        {
          padding: 1,
          borderRadius: "10px",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <RefreshOutlinedIcon color="secondary" fontSize="small" />
      <Typography fontSize="0.75rem" ml={0.5}>
        {text ?? t("general.actions.reset")}
      </Typography>
    </ButtonBase>
  );
};

export default ResetButton;
