import { Box, useTheme } from "@mui/material";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Prescriptions, {
  PrescriptionsProgress,
  PRESCRIPTIONS_PROGRESS_TOTAL,
} from "..";
import { Icons } from "@neurosolutionsgroup/components";
import useFTUE from "useFTUE";

export interface DosageProps extends PropsWithChildren {
  disableNext: boolean;
  handleOnBack: () => void;
  isMedicationPrivate: boolean;
}

const Dosage: React.FC<DosageProps> = ({
  disableNext,
  handleOnBack,
  isMedicationPrivate,
  ...props
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setPrescriptionsProgress },
  } = useFTUE();

  const prescriptionsProgress = progress[FTUEFlow.Prescriptions].progress;

  const onNext = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Dosage);
  };

  const onBack = () => {
    setPrescriptionsProgress(PrescriptionsProgress.TrigerMedication);
    handleOnBack();
  };

  const onQuit = () => {
    setPrescriptionsProgress(PrescriptionsProgress.Finished, true);
  };

  return (
    <FTUEHint
      condition={
        prescriptionsProgress === PrescriptionsProgress.MedicationSelection &&
        canDisplayFlow(FTUEFlow.Prescriptions)
      }
      hint={
        isMedicationPrivate ? (
          <>
            {t("ftue.prescription.dosage.newMedication.text")}
            <Icons.AddIcon color={palette.primary.main} />
          </>
        ) : (
          <>
            {t("ftue.prescription.dosage.existingMedication.text")}
            <Icons.AddIcon color={palette.primary.main} />
          </>
        )
      }
      onNext={onNext}
      onBack={onBack}
      nextDisabled={disableNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      floating={true}
      currentStep={Prescriptions.Logic.calculateProgress(
        PrescriptionsProgress.MedicationSelection
      )}
      maxStep={PRESCRIPTIONS_PROGRESS_TOTAL}
    >
      {prescriptionsProgress === PrescriptionsProgress.MedicationSelection &&
      canDisplayFlow(FTUEFlow.Prescriptions) ? (
        <Box
          sx={{
            h3: {
              color: "#fff !important",
            },
          }}
        >
          {props.children}
        </Box>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};
export default Dosage;
