/**
 * Get the localized label for a period in a day of a dose as defined by Kairos Med.
 */
export const getDoseTimeOfDay = (time: number): string => {
  //14_400 = 04:00 h
  //43_200 = 12:00 h
  //64_800 = 18:00 h
  //75_600 = 21:00 h
  return time >= 14_400 && time <= 43_200
    ? "general.timeOfDay.morning"
    : time > 43_200 && time <= 64_800
    ? "general.timeOfDay.afternoon"
    : time > 64_800 && time <= 75_600
    ? "general.timeOfDay.evening"
    : "general.timeOfDay.night";
};
