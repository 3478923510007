import { Link, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export interface ChatDisclaimerMessageProps {
  onPrivacyClick: VoidFunction;
  onSupportClick: VoidFunction;
}

const ChatDisclaimerMessage = ({
  onPrivacyClick,
  onSupportClick,
}: ChatDisclaimerMessageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <Typography component="span" variant="h4" color="inherit">
        {t("coach.chatbot.disclaimer.title")}
      </Typography>
      <br />
      <br />
      <Trans i18nKey={"coach.chatbot.disclaimer.textOne"}>
        While using Kairos generative AI experimental features, you consent to
        its privacy policies (see section 3.4.3 in our{" "}
        <Link
          color="inherit"
          onClick={(e) => {
            e.preventDefault();
            onPrivacyClick();
          }}
          href="#"
        >
          Privacy Policy
        </Link>
        ).
      </Trans>
      <br />
      <br />
      <span>{t("coach.chatbot.disclaimer.textTwo")}</span>
      <br />
      <br />
      <Trans i18nKey={"coach.chatbot.disclaimer.textThree"}>
        For support using the application, visit our
        <Link
          color="inherit"
          onClick={(e) => {
            e.preventDefault();
            onSupportClick();
          }}
          href="#"
        >
          Help Center
        </Link>
        . If you feel overwhelmed, do not hesitate to consult a professional and
        get all the support you need.
      </Trans>
    </>
  );
};

export default ChatDisclaimerMessage;
