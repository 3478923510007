import { Box, Button, Grid, Typography, drawerClasses } from "@mui/material";
import { sub } from "date-fns";
import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import DateTextInput from "../Inputs/DateTextInput/DateTextInput";
import { SafeAreas } from "@neurosolutionsgroup/models";
import ControlledDrawer from "../ControlledDrawer/ControlledDrawer";

interface AgeGateProps {
  show: boolean;
  onComplete: VoidFunction;
  onClose: VoidFunction;
  safeAreas: SafeAreas;
}

const AgeGateV2: React.FC<AgeGateProps> = ({
  show,
  onComplete,
  onClose,
  safeAreas,
}) => {
  const { t } = useTranslation();

  const [dateMS, setDateMS] = useState<number | null>(null);

  const onConfirm = () => {
    if (dateMS === null) {
      return;
    }

    const maxDateMS = sub(new Date(), { years: 18 }).getTime();

    setDateMS(null);

    if (dateMS < maxDateMS) {
      onComplete();
    } else {
      onClose();
    }
  };

  const onDrawerClose = () => {
    setDateMS(null);
    onClose();
  };

  return (
    <ControlledDrawer
      open={show}
      anchor="bottom"
      onClose={onDrawerClose}
      disableSwipeToOpen={true}
      sx={(theme) => ({
        [`.${drawerClasses.paper}`]: {
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      <Box mt={4} pb={safeAreas.bottom} mb={1} mx={2}>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item mb={2} textAlign="center">
            <Typography variant="h4">
              <Trans
                i18nKey={"navigation.agegate.title"}
                components={{ italic: <i /> }}
              >
                Enter <i>your</i> date of birth
              </Trans>
            </Typography>
            <Grid item textAlign="center">
              <Typography mt={2} fontSize="0.8rem">
                {t("navigation.agegate.information")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item mb={2}>
            {show ? (
              <DateTextInput date={dateMS} setDate={setDateMS} autoFocus />
            ) : null}
          </Grid>
          <Grid item>
            <Button
              onClick={onConfirm}
              data-cy="age-gate-confirm"
              color="secondary"
              variant="contained"
            >
              {t("general.actions.confirm")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </ControlledDrawer>
  );
};

export default AgeGateV2;
