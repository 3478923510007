import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Validation, { ValidationProgress, VALIDATION_PROGRESS_TOTAL } from "..";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import useFTUE from "useFTUE";

const RoutineValidation = (props: PropsWithChildren): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setValidationProgress },
  } = useFTUE();

  const validationProgress = progress[FTUEFlow.Validation].progress;

  const onNext = () => {
    setValidationProgress(ValidationProgress.Display);
  };

  const onQuit = () => {
    setValidationProgress(ValidationProgress.ValidationFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        validationProgress === ValidationProgress.Intro &&
        canDisplayFlow(FTUEFlow.Validation)
      }
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.validation.display.text")}
      onClick={onNext}
      currentStep={Validation.Logic.calculateProgress(ValidationProgress.Intro)}
      maxStep={VALIDATION_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default RoutineValidation;
