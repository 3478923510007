import { Box, Card, SxProps, Theme, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import LabelledGauge, { GaugeLabel } from "../Gauge/LabelledGauge";
import EmptyStateGauge from "../Gauge/EmptyStateGauge";

interface SkillsCardProps {
  lastChallengeWeeks: number | null;
  skillId: string;
  successPercentage: number | null;
  ongoingChallenge: boolean;
  sx?: SxProps<Theme>;
}

const SkillsCard = ({
  lastChallengeWeeks,
  skillId,
  successPercentage,
  ongoingChallenge,
  sx,
}: SkillsCardProps): JSX.Element => {
  const { t } = useTranslation();

  const getStatus = (): { color: string; text: string } => {
    if (successPercentage === null) {
      return {
        color: "#757575",
        text: t("dashboard.skills.status.noData"),
      };
    }

    if (successPercentage < 33) {
      return {
        color: "#ff233a",
        text: t("dashboard.skills.status.novice"),
      };
    } else if (successPercentage > 66) {
      if (successPercentage > 90) {
        return {
          color: "#2cd25a",
          text: t("dashboard.skills.status.prodigy"),
        };
      } else {
        return {
          color: "#2cd25a",
          text: t("dashboard.skills.status.experienced"),
        };
      }
    } else {
      return {
        color: "#fe8026",
        text: t("dashboard.skills.status.apprentice"),
      };
    }
  };

  const status = getStatus();

  const labels: GaugeLabel[] =
    successPercentage !== null
      ? [
          {
            value: successPercentage,
            text: `${successPercentage.toFixed(0)}%`,
          },
        ]
      : [];

  return (
    <Card
      sx={[
        { paddingX: 0, boxSizing: "border-box", minHeight: "225px" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-cy="skills-card-short"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <Box
          sx={{
            backgroundColor: status.color,
            borderRadius: 999,
            paddingX: 2,
            paddingY: 0.25,
          }}
        >
          <Typography
            color="#fff"
            textAlign="center"
            fontWeight={700}
            fontSize="0.9rem"
          >
            {status.text}
          </Typography>
        </Box>

        <Typography
          fontSize="0.75rem"
          textAlign="center"
          color="#9d9d9d"
          mt={0.5}
          mb={2}
        >
          {ongoingChallenge ? (
            t("dashboard.skills.ongoing")
          ) : lastChallengeWeeks !== null ? (
            lastChallengeWeeks === 0 ? (
              t("dashboard.skills.lastChallengeThisWeek")
            ) : (
              <Trans
                i18nKey={"dashboard.skills.lastChallenge"}
                values={{ count: lastChallengeWeeks }}
              />
            )
          ) : (
            t("dashboard.skills.noChallenge")
          )}
        </Typography>

        <Box
          sx={{
            maxWidth: "200px",
          }}
        >
          {successPercentage === null ? (
            <EmptyStateGauge />
          ) : (
            <LabelledGauge
              value={successPercentage}
              min={0}
              max={100}
              labels={labels}
              fontScale={0.7}
            />
          )}
        </Box>

        <Typography fontWeight={700} textAlign="center" mx={1} mt={1}>
          {t(`settings.child.needs.needs.${skillId}`)}
        </Typography>
      </Box>
    </Card>
  );
};

export default SkillsCard;
