import { Box, Button, Typography } from "@mui/material";
import { SetStateAction } from "react";
import { PinIconsDefintion } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import IconPinKeyboard from "./Components/IconPinKeyboard";
import IconPinDisplay from "./Components/IconPinDisplay";

export interface IconPinInputProps {
  value: string;
  setValue: React.Dispatch<SetStateAction<string>>;
  onConfirm: (value: string) => void;
  pinIcons: PinIconsDefintion;
  error?: boolean;
  errorMessage?: string;
}

const IconPinInput = ({
  value,
  setValue,
  onConfirm,
  pinIcons,
  error,
  errorMessage,
}: IconPinInputProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column">
      <IconPinDisplay
        value={value}
        clickable={false}
        clearable={true}
        setValue={setValue}
        pinIcons={pinIcons}
        error={error}
      />
      {error ? (
        <Typography fontSize="0.9rem" color="error" textAlign="center">
          {errorMessage}
        </Typography>
      ) : null}
      <IconPinKeyboard setValue={setValue} pinIcons={pinIcons} />
      <Box my={2} display="flex" justifyContent="center">
        <Button
          color="secondary"
          variant="contained"
          onClick={() => {
            onConfirm(value);
          }}
          disabled={value.length === 0}
        >
          {t("general.actions.confirm")}
        </Button>
      </Box>
    </Box>
  );
};

export default IconPinInput;
