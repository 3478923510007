import { Language } from "@neurosolutionsgroup/models";
import { Value } from "firebase/remote-config";
import semverGte from "semver/functions/gte";
import semverValid from "semver/functions/valid";
import { ConfigNumber, FeatureFlag } from "../remoteConfigKeys";
import { useRemoteConfigContext } from "./remoteConfigContext";

interface UseRemoteConfigResult {
  checkFeatureFlagVersion: (key: string, version?: string) => boolean;
  getActivatedLanguages: () => Language[];
  getNumberConfig: (key: ConfigNumber) => number;
  getRemoteConfigValue: (key: string) => Value;
}

const useRemoteConfig = (): UseRemoteConfigResult => {
  const { getRemoteConfigValue } = useRemoteConfigContext();

  const getNumberConfig = (key: ConfigNumber): number => {
    return getRemoteConfigValue(key).asNumber();
  };

  const checkFeatureFlagVersion = (key: string, version?: string): boolean => {
    const value = getRemoteConfigValue(key).asString();

    if (!value || value === "false") {
      return false;
    }

    if (value === "true") {
      return true;
    }

    if (version && semverValid(value)) {
      return semverGte(version, value);
    }

    return false;
  };

  const getActivatedLanguages = (): Language[] => {
    const languages: Language[] = [];

    if (getRemoteConfigValue(FeatureFlag.LanguageEN).asString() !== "false") {
      languages.push("en");
    }

    if (getRemoteConfigValue(FeatureFlag.LanguageFR).asString() !== "false") {
      languages.push("fr");
    }

    if (getRemoteConfigValue(FeatureFlag.LanguageDE).asString() !== "false") {
      languages.push("de");
    }

    return languages;
  };

  return {
    checkFeatureFlagVersion,
    getActivatedLanguages,
    getNumberConfig,
    getRemoteConfigValue,
  };
};

export default useRemoteConfig;
