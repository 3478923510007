import React, { useMemo } from "react";
import { ButtonBase, ButtonBaseProps } from "@mui/material";
import clsx from "clsx";
import { Icons } from "@neurosolutionsgroup/components";

interface InformationButtonProps extends ButtonBaseProps {
  color?: "secondary" | "white" | "high-vis";
  iconVariant?: "contained" | "outline";
}

const InformationButton = ({
  color = "secondary",
  iconVariant = "contained",
  ...props
}: InformationButtonProps): JSX.Element => {
  const { iconColor, secondaryColor } = useMemo((): {
    iconColor: string;
    secondaryColor?: string;
  } => {
    switch (color) {
      case "secondary":
        return {
          iconColor: "#31737c",
          secondaryColor: "#fff",
        };
      case "white":
        return {
          iconColor: "#fff",
          secondaryColor: "#31737c",
        };
      case "high-vis": {
        return {
          iconColor: "#fff",
          secondaryColor: "#dd4f4b",
        };
      }
    }
  }, [color, iconVariant]);

  return (
    <ButtonBase
      {...props}
      className={clsx(
        "information-button",
        `information-button--${color}`,
        props.className
      )}
    >
      <Icons.InformationIcon
        variant={iconVariant}
        color={iconColor}
        secondaryColor={secondaryColor}
        highVis={color === "high-vis"}
      />
    </ButtonBase>
  );
};

export default InformationButton;
