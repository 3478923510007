import React, { useEffect, useState, SetStateAction } from "react";
import { Tools } from "@neurosolutionsgroup/tools";
import store from "store2";
import { AnalyticsEvent } from "./Events";
import { AnalyticService } from "./models";
import { DeviceData } from "@neurosolutionsgroup/models";
import { v4 } from "uuid";

interface AnalyticsData {
  services: AnalyticService[];
  setServices: React.Dispatch<SetStateAction<AnalyticService[]>>;
  initializationComplete: boolean;
  appData: AppData;
  setAppData: React.Dispatch<SetStateAction<AppData>>;
  deviceData: DeviceData;
  setDeviceData: React.Dispatch<SetStateAction<DeviceData>>;
  language: string;
  setLanguage: React.Dispatch<SetStateAction<string>>;
  eventQueue: AnalyticsEvent[];
  setEventQueue: React.Dispatch<SetStateAction<AnalyticsEvent[]>>;
}

const [useAnalyticsContext, AnalyticsContextProvider] =
  Tools.Context.createGenericContext<AnalyticsData>("AnalyticsContext");

interface AnalyticsProviderProps {
  services: AnalyticService[];
  children?: React.ReactNode;
  includeAppData?: boolean;
}

export interface AppData {
  version?: string;
  build?: string;
  webBuild?: string;
}

const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  services: initialServices,
  includeAppData = true,
  ...props
}) => {
  const [initializationComplete, setInitializationComplete] = useState(false);
  const [appData, setAppData] = useState<AppData>({});
  const [deviceData, setDeviceData] = useState<DeviceData>({});
  const [language, setLanguage] = useState<string>("en");
  const [eventQueue, setEventQueue] = useState([]);
  const [services, setServices] = useState<AnalyticService[]>(initialServices);

  useEffect(() => {
    console.info(
      "Initializing services.",
      services.map((service) => service.name)
    );
    services.forEach((service) => {
      try {
        service.init();
      } catch (err) {
        console.error(
          `[Analytics] Error initializing service: ${service.name}.`,
          err
        );
      }
    });

    if (includeAppData) {
      const storedData = store.namespace("analytics").getAll();

      if (storedData["appData"]) {
        setAppData(storedData["appData"]);
      }

      if (storedData["deviceData"]) {
        // Generate device ID if we don't have one.
        // So parent session tracking still works.
        // Should be overwritten by value passed from Unity.
        if (!storedData["deviceData"].deviceId) {
          storedData["deviceData"].deviceId = v4();
        }

        setDeviceData(storedData["deviceData"]);
      }
    }

    setInitializationComplete(true);
  }, [services]);

  useEffect(() => {
    const handleEventsInternal = (
      events: AnalyticsEvent[],
      curentAppData: AppData,
      currentDeviceData: DeviceData,
      currentLanguage: string
    ): void => {
      if (!initializationComplete) {
        throw new Error(
          "[Analytics] handleAnalyticsEvent called before analytics initialized, check all calls are within AnalyticsProvider."
        );
      }

      try {
        services.forEach((service) => {
          events.forEach((e) => {
            const properties = {
              ...e.eventProperties,
              $app_version_string: curentAppData.version,
              $app_build_number: `${curentAppData.build}.${curentAppData.webBuild}`,
              $os: currentDeviceData.os,
              $os_version: currentDeviceData.osVersion,
              $device: currentDeviceData.device,
              $model: currentDeviceData.model,
              language: currentLanguage,
            };

            if (service.name === "CustomerIO") {
              service.handleEvent(e);
            } else {
              service.functions.trackEvent(e.name, properties);

              if (e.setProperties) {
                service.functions.setProfileProperties(e.setProperties);
              }

              if (e.setOnceProperties) {
                service.functions.setOnceProfileProperties(e.setOnceProperties);
              }
            }
          });
        });
      } catch (err) {
        console.error("Error encountered in analytics.", err);
      }
    };

    if (initializationComplete && eventQueue.length > 0) {
      const events = eventQueue;

      setEventQueue([]);

      handleEventsInternal(events, appData, deviceData, language);
    }
  }, [initializationComplete, eventQueue]);

  const storeAppData = (appData: AppData) => {
    store.namespace("analytics").set("appData", appData);

    setAppData(appData);
  };

  const storeDeviceData = (deviceData: DeviceData) => {
    store.namespace("analytics").set("deviceData", deviceData);

    setDeviceData(deviceData);
  };

  const storeLanguage = (language: string) => {
    store.namespace("analytics").set("language", language);

    setLanguage(language);
  };

  return (
    <AnalyticsContextProvider
      value={{
        services,
        setServices,
        initializationComplete,
        appData,
        setAppData: storeAppData,
        deviceData,
        setDeviceData: storeDeviceData,
        language,
        setLanguage: storeLanguage,
        eventQueue,
        setEventQueue,
      }}
    >
      {initializationComplete ? props.children : null}
    </AnalyticsContextProvider>
  );
};

export { useAnalyticsContext, AnalyticsProvider };
