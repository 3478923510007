import { Box, ButtonBase } from "@mui/material";
import { Icons, PremiumLock } from "@neurosolutionsgroup/components";
import React from "react";
import { SAFE_AREA_BOTTOM } from "stylesheets";

interface ChatBotButtonProps {
  locked: boolean;
  onClick: VoidFunction;
  onLockClick: VoidFunction;
}

const ChatBotButton = ({
  locked,
  onClick,
  onLockClick,
}: ChatBotButtonProps): JSX.Element => {
  const verticalPadding = "73px";

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: `calc(${SAFE_AREA_BOTTOM} + ${verticalPadding})`,
        right: "12px",
      }}
    >
      <PremiumLock
        active={locked}
        onClick={onLockClick}
        variant="icon-button"
        top="-.2rem"
      >
        <ButtonBase
          onClick={onClick}
          sx={{
            width: "56px",
            height: "56px",
            backgroundColor: "primary.main",
            borderRadius: "999px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            svg: {
              width: "30px",
              height: "30px",
            },
          }}
        >
          <Icons.ChatIcon color="#fff" />
        </ButtonBase>
      </PremiumLock>
    </Box>
  );
};

export default ChatBotButton;
