import { Box, Grid, Typography } from "@mui/material";

interface TimelineStepProps {
  title: string;
  text: string;
  isLast?: boolean;
}

const TimelineStep = ({
  title,
  text,
  isLast = false,
}: TimelineStepProps): JSX.Element => {
  return (
    <Box display="flex" flexDirection="row" width="100%" height="100%">
      <Box
        width="10%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Box
          flexGrow={0}
          borderRadius={100}
          bgcolor="success.main"
          width="15px"
          height="15px"
          zIndex={10}
          mt="2px"
        />
        {!isLast && (
          <Box
            flexGrow={1}
            bgcolor="secondary.main"
            color="secondary.main"
            width="2px"
            my={-1}
          />
        )}
      </Box>
      <Grid container direction="column" pb={2}>
        <Grid item>
          <Typography
            color="success.main"
            fontFamily="Ander Hedge"
            letterSpacing=".1rem"
            textAlign="left"
            variant="h4"
          >
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography textAlign="left" fontSize="0.8rem" fontWeight={600}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TimelineStep;
