import { Box, Grid } from "@mui/material";

export interface FTUEProgressProps {
  currentStep: number;
  maxStep: number;
}

const FTUEProgress = ({
  currentStep,
  maxStep,
}: FTUEProgressProps): JSX.Element => {
  const renderProgressTag = (step: number) => (
    <Grid item flexGrow={1} key={step}>
      <Box
        sx={{
          height: "0.5em",
          width: "100%",
          borderRadius: "999px",
          backgroundColor: currentStep >= step ? "#a6da2d" : "#fff",
        }}
      />
    </Grid>
  );

  return (
    <Grid container spacing={1}>
      {Array.from({ length: maxStep }, (_, i) => i).map((i) =>
        renderProgressTag(i)
      )}
    </Grid>
  );
};

export default FTUEProgress;
