import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Validation, { ValidationProgress, VALIDATION_PROGRESS_TOTAL } from "..";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import useFTUE from "useFTUE";

const StatusPrefill = (props: PropsWithChildren): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setValidationProgress },
  } = useFTUE();

  const validationProgress = progress[FTUEFlow.Validation].progress;

  const onNext = () => {
    setValidationProgress(ValidationProgress.Prefill);
  };

  const onQuit = () => {
    setValidationProgress(ValidationProgress.ValidationFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        validationProgress === ValidationProgress.Display &&
        canDisplayFlow(FTUEFlow.Validation)
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.validation.prefill.text")}
      scroll={false}
      currentStep={Validation.Logic.calculateProgress(
        ValidationProgress.Display
      )}
      maxStep={VALIDATION_PROGRESS_TOTAL}
    >
      {validationProgress === ValidationProgress.Display &&
      canDisplayFlow(FTUEFlow.Validation) ? (
        <Box sx={{ "& .MuiTypography-root": { color: "#fff" } }}>
          {props.children}
        </Box>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};

export default StatusPrefill;
