import { Grid, Typography } from "@mui/material";
import { Challenge } from "@neurosolutionsgroup/models";
import useTasks from "common/hooks/routines/useTasks";
import React from "react";

export interface ChallengeAccordionSummaryProps {
  challenge: Challenge;
}

const ChallengeAccordionSummary: React.FC<ChallengeAccordionSummaryProps> = ({
  challenge,
}) => {
  const { iconManager } = useTasks().selectors;

  return (
    <>
      <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
        <Grid
          item
          sx={{
            "& img": {
              width: "4em",
              objectFit: "contain",
            },
          }}
          flexGrow={0}
        >
          <img
            src={iconManager.getIcon(challenge.icon)}
            alt={`Challenge Icon ${challenge.icon}`}
          />
        </Grid>
        <Grid item flexGrow={1}>
          <Typography fontWeight="bold">{challenge.title}</Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ChallengeAccordionSummary;
