import { Box } from "@mui/material";
import { PropsWithChildren } from "react";

interface FTUEHighlightComponentProps extends PropsWithChildren {
  active: boolean;
}

const FTUEHighlightComponent = ({
  active,
  ...props
}: FTUEHighlightComponentProps): JSX.Element => {
  return (
    <Box
      sx={
        active
          ? {
              position: "relative",
              zIndex: 101,
            }
          : undefined
      }
    >
      {props.children}
    </Box>
  );
};

export default FTUEHighlightComponent;
