import RoutineValidation from "./RoutineValidation";
import StatusPrefill from "./StatusPrefill";
import TaskValidation from "./TaskValidation";
import ValidationEnd from "./ValidationEnd";
import ValidationNav from "./ValidationNav";

const ValidationFTUEHints = {
  RoutineValidation,
  StatusPrefill,
  TaskValidation,
  ValidationEnd,
  ValidationNav,
};

export default ValidationFTUEHints;
