import { AllTodaysValidationsDone } from "@neurosolutionsgroup/analytics";
import { Tools } from "@neurosolutionsgroup/tools";
import { TasksToValidate } from "common/hooks/children/ChildrenContext";
import { RoutinesById } from "common/hooks/routines/RoutinesContext";
import { getISODay } from "date-fns";

const allTodaysValidationsDone = (
  tasksToValidate: TasksToValidate,
  routinesById: RoutinesById
): AllTodaysValidationsDone | undefined => {
  if (Object.values(routinesById).length === 0) {
    return undefined;
  }

  const validationsToDoCount = Object.values(tasksToValidate).reduce(
    (prev, curr) => prev + curr.length,
    0
  );

  if (validationsToDoCount === 0) {
    const routinesLeftToDoToday = Object.values(routinesById).filter(
      (routine) =>
        !routine.deleted &&
        !routine.disabled &&
        Tools.Time.Days.dayInDays(
          Tools.Time.Days.dayIndexes[getISODay(new Date()) - 1],
          routine.days
        ) &&
        Tools.Time.Dates.getTimeOfDaySeconds() < routine.end &&
        routine.end <= 19 * 60 * 60
    );

    if (routinesLeftToDoToday.length === 0) {
      return {
        name: "All Today's Validations Done",
      };
    }
  }

  return undefined;
};

type ActiveRoutinesByDay = {
  activeRoutinesMonday: number;
  activeRoutinesTuesday: number;
  activeRoutinesWednesday: number;
  activeRoutinesThursday: number;
  activeRoutinesFriday: number;
  activeRoutinesSaturday: number;
  activeRoutinesSunday: number;
};

const calculateActiveRoutinesByDay = (
  routinesById: RoutinesById
): ActiveRoutinesByDay => {
  const DAY_END_TIME = 19 * 60 * 60;
  const dayCounts: number[] = [0, 0, 0, 0, 0, 0, 0];

  Tools.Time.Days.dayIndexes.forEach((day, i) => {
    const todaysRoutines = Object.values(routinesById)
      .filter(
        (routine) =>
          !routine.deleted &&
          !routine.disabled &&
          Tools.Time.Days.dayInDays(day, routine.days) &&
          routine.end <= DAY_END_TIME
      )
      .reduce((prev, curr) => prev + curr.users.length, 0);

    const tomorrowsRoutines = Object.values(routinesById)
      .filter(
        (routine) =>
          !routine.deleted &&
          !routine.disabled &&
          Tools.Time.Days.dayInDays(day, routine.days) &&
          routine.end > DAY_END_TIME
      )
      .reduce((prev, curr) => prev + curr.users.length, 0);

    dayCounts[i] += todaysRoutines;
    dayCounts[i < 6 ? i + 1 : 0] += tomorrowsRoutines;
  });

  return {
    activeRoutinesMonday: dayCounts[0],
    activeRoutinesTuesday: dayCounts[1],
    activeRoutinesWednesday: dayCounts[2],
    activeRoutinesThursday: dayCounts[3],
    activeRoutinesFriday: dayCounts[4],
    activeRoutinesSaturday: dayCounts[5],
    activeRoutinesSunday: dayCounts[6],
  };
};

const AnalyticsLogic = {
  allTodaysValidationsDone,
  calculateActiveRoutinesByDay,
};

export default AnalyticsLogic;
