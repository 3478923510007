import React, { useCallback, useEffect } from "react";
import TimePicker from "./TimePicker";
import { Grid } from "@mui/material";
import { Language } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";

export interface TimeRangePickerProps {
  start: number | null;
  setStart: (value: number) => void;
  end: number | null;
  setEnd: (value: number) => void;
  language: Language;
  startMin?: number;
  startMax?: number;
  endMin?: number;
  endMax?: number;
  validatePeriod?: (start: number, end: number) => string | undefined;
}

const TimeRangePicker: React.FC<TimeRangePickerProps> = ({
  start,
  setStart,
  end,
  setEnd,
  language,
  startMin,
  startMax,
  endMin,
  endMax,
  validatePeriod,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    // Don't let end be earlier than start.
    if (start && end && start > end) {
      // Last end time possible 23h59.
      setEnd(start === 85500 ? 86340 : start + 900);
    }
  }, [start]);

  const externalValidationStart = useCallback(
    (newStart: number): string | undefined => {
      if (!validatePeriod || !end) {
        return undefined;
      }

      return validatePeriod(newStart, end);
    },
    [end, validatePeriod]
  );

  const externalValidationEnd = useCallback(
    (newEnd: number): string | undefined => {
      if (!validatePeriod || !start) {
        return undefined;
      }

      return validatePeriod(start, newEnd);
    },
    [start, validatePeriod]
  );

  return (
    <div className="time-range-picker">
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TimePicker
            data-cy="time-piker-start"
            label={t("general.start")}
            time={start}
            setTime={setStart}
            language={language}
            min={startMin}
            max={startMax}
            validateTime={externalValidationStart}
          />
        </Grid>
        <Grid item xs={6}>
          <TimePicker
            data-cy="time-piker-end"
            label={t("general.end")}
            time={end}
            setTime={setEnd}
            language={language}
            min={endMin}
            max={endMax}
            validateTime={externalValidationEnd}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TimeRangePicker;
