import {
  CreateFollowedSideEffectsBody,
  FollowedSideEffect as FollowedSideEffectModel,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import { getHeaders, getURL, handleApiError } from "../utils";

const updateFollowedSideEffects = async (
  medicalChildId: string,
  body: CreateFollowedSideEffectsBody
): Promise<FollowedSideEffectModel[]> => {
  const URL =
    getURL() + `/api/medicalChild/${medicalChildId}/followedSideEffect`;

  const headers = await getHeaders(true, "updateFollowedSideEffects");

  try {
    const result = await axios.put<FollowedSideEffectModel[]>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const FollowedSideEffect = {
  updateFollowedSideEffects,
};

export default FollowedSideEffect;
