import { AccessTime as AccessTimeIcon } from "@mui/icons-material";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { ChallengeV2 } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import CurrentChallengeCardSummaryEmptyState from "./CurrentChallengeCardSummaryEmptyState";
import ChallengeProgressGauge from "../Components/ChallengeProgressGauge";
import { format, Locale } from "date-fns";
import { Tools } from "@neurosolutionsgroup/tools";

interface CurrentChallengeCardSummaryProps {
  challenge: ChallengeV2 | null;
  challengeIconImgSrc: string;
  currentChallengeWeek: number;
  open: boolean;
  taskIconAssets: Record<number, string>;
  dateLocale: Locale;
}

const CurrentChallengeCardSummary = ({
  challenge,
  challengeIconImgSrc,
  currentChallengeWeek,
  open,
  taskIconAssets,
  dateLocale,
}: CurrentChallengeCardSummaryProps): JSX.Element => {
  const theme = useTheme();
  const { t } = useTranslation();

  const challengeProgressThisWeek =
    challenge?.history.filter(
      (history) =>
        history.week === currentChallengeWeek &&
        history.parentStatus === true &&
        history.validationDate !== null
    ).length ?? 0;

  return (
    <Box width="100%">
      <Grid container alignItems="center" spacing={1} rowSpacing={1}>
        <Grid
          item
          xs={11}
          sm={11.5}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography fontWeight={700}>
            {currentChallengeWeek > 0
              ? t("dashboard.skills.currentChallenge.title")
              : t("dashboard.skills.currentChallenge.titleToCome")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={1}
          sm={0.5}
          display="flex"
          alignItems="center"
          justifyContent="end"
          sx={{
            svg: {
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            },
          }}
        >
          <Icons.ArrowIcon
            arrowType="empty"
            arrowDirection="down"
            color={theme.palette.secondary.main}
          />
        </Grid>

        {challenge && currentChallengeWeek < 0 ? (
          <Grid item xs={12} display="flex" alignItems="center">
            <AccessTimeIcon color="secondary" fontSize="small" />
            <Typography fontSize="0.85rem" ml={0.5}>
              <i>
                {t("dashboard.skills.currentChallenge.startDate")}{" "}
                {format(
                  Tools.Time.Dates.parseDateStringToJsDate(challenge.startDate),
                  "d MMM yyyy",
                  { locale: dateLocale }
                )}
              </i>
            </Typography>
          </Grid>
        ) : null}

        {challenge ? (
          <Grid item xs={12} display="flex" mt={1}>
            <ChallengeProgressGauge
              doneCount={challengeProgressThisWeek}
              total={challenge.frequency}
              challengeIconImgSrc={taskIconAssets[challenge.icon]}
              empty={currentChallengeWeek < 0}
            />

            <Box
              ml={2}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              {challenge ? (
                <Box
                  sx={{
                    backgroundColor: "#31737C1A",
                    borderRadius: "10px",
                    paddingY: 0.5,
                    paddingX: 1,
                    marginBottom: 1,
                  }}
                >
                  <Typography
                    fontSize="0.85rem"
                    fontWeight={600}
                    textAlign="center"
                  >
                    {t(`settings.child.needs.needs.${challenge.skillId}`)}
                  </Typography>
                </Box>
              ) : null}

              {challenge.duration > 1 && currentChallengeWeek > 0 ? (
                <Typography fontSize="0.9rem">
                  {t("dashboard.skills.currentChallenge.weekCount", {
                    count: currentChallengeWeek,
                    duration: challenge.duration,
                  })}
                </Typography>
              ) : null}

              <Typography fontSize="0.9rem" fontWeight={700}>
                {challenge.title}
              </Typography>
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12} mt={1}>
            <CurrentChallengeCardSummaryEmptyState
              challengeIconImgSrc={challengeIconImgSrc}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default CurrentChallengeCardSummary;
