import axios, { AxiosRequestConfig } from "axios";
import { getURL } from "./getURL";
import { getHeaders } from "./getHeaders";
import { handleApiError } from "./handleApiError";

export const executeAuthedGet = async <T>(
  path: string,
  extraHeaders?: AxiosRequestConfig["headers"]
): Promise<T> => {
  const URL = getURL() + path;

  const headers = { ...(await getHeaders(undefined, path)), ...extraHeaders };

  try {
    const response = await axios.get<T>(URL, { headers });

    return response.data;
  } catch (err) {
    return handleApiError(err);
  }
};
