import { Grid } from "@mui/material";
import React, { PropsWithChildren } from "react";
import {
  FullScreenDrawerPage,
  PageContentContainer,
} from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";

export interface AuthPageContainerProps extends PropsWithChildren {
  open: boolean;
  safeAreas: SafeAreas;
}

const AuthPageContainer: React.FC<AuthPageContainerProps> = ({
  open,
  safeAreas,
  children,
}) => {
  return (
    <FullScreenDrawerPage
      open={open}
      sx={{
        form: {
          height: "100%",
        },
      }}
    >
      <PageContentContainer safeAreas={safeAreas}>
        <form>
          <Grid
            container
            direction="column"
            justifyContent={"space-between"}
            sx={{ height: "100%" }}
            flexWrap="nowrap"
          >
            {children}
          </Grid>
        </form>
      </PageContentContainer>
    </FullScreenDrawerPage>
  );
};
export default AuthPageContainer;
