import { formatISO } from "date-fns";
import { Timestamp } from "firebase/firestore";

const firestoreTimestampToDateString = (timestamp: Timestamp): string => {
  const date = timestamp.toDate();

  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate()
  );

  return formatISO(utcDate, { representation: "date" });
};

const mapFirestoreDateToString = (date: Timestamp | string): string => {
  if (typeof date === "object") {
    return firestoreTimestampToDateString(date);
  } else {
    return date;
  }
};

const mapOptionalFirestoreDateToString = (
  date: Timestamp | string | null
): string | undefined => {
  if (typeof date === "object" && date !== null) {
    return firestoreTimestampToDateString(date);
  } else {
    return date ?? undefined;
  }
};

const FirestoreTools = {
  firestoreTimestampToDateString,
  mapFirestoreDateToString,
  mapOptionalFirestoreDateToString,
};

export default FirestoreTools;
