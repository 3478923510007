import { Button, ButtonBase, Grid, Typography } from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import NotificationSVG from "./NotificationSVG";
import {
  FullscreenPage,
  PageContentContainer,
} from "@neurosolutionsgroup/components";

export interface NotificationPageProps {
  open: boolean;
  onAccept: VoidFunction;
  onRefuse: VoidFunction;
  safeAreas: SafeAreas;
}

const NotificationPage: React.FC<NotificationPageProps> = ({
  open,
  onAccept,
  onRefuse,
  safeAreas,
}) => {
  const { t } = useTranslation();
  return open ? (
    <FullscreenPage>
      <PageContentContainer safeAreas={safeAreas}>
        <Grid
          container
          direction={"column"}
          spacing={4}
          justifyContent={"space-around"}
          height={"100%"}
        >
          <Grid item>
            <Grid
              container
              direction={"column"}
              spacing={4}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Grid container direction="column" spacing={1} px={2}>
                  <Grid item>
                    <Typography fontWeight={"bold"} textAlign={"justify"}>
                      {t("settings.notificationsPrompt.optIn.texts.intro")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography textAlign={"justify"}>
                      {t("settings.notificationsPrompt.optIn.texts.first")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography textAlign={"justify"}>
                      {t("settings.notificationsPrompt.optIn.texts.second")}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container justifyContent={"center"}>
                  <NotificationSVG />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" spacing={1} px={2}>
              <Grid item>
                <Button
                  data-cy={"accept-notification-button"}
                  onClick={onAccept}
                  color="secondary"
                  variant="text"
                  fullWidth
                >
                  {t("settings.notificationsPrompt.optIn.buttons.activate")}
                </Button>
              </Grid>
              <Grid item>
                <ButtonBase
                  onClick={onRefuse}
                  data-cy="refuse-notification-button"
                  sx={{
                    width: "100%",
                    padding: 1,
                    borderRadius: 999,
                  }}
                >
                  <Typography textAlign={"center"}>
                    <Trans
                      i18nKey="settings.notificationsPrompt.optIn.buttons.no"
                      components={{ bold: <strong /> }}
                    />
                  </Typography>
                </ButtonBase>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </PageContentContainer>
    </FullscreenPage>
  ) : null;
};

export default NotificationPage;
