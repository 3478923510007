import { FTUEPrescriptionScreen } from "./FullScreen";
import PrescriptionsHints from "./Hints";
import FTUEPrescriptionsLogic from "./PrescriptionLogic";
import {
  PRESCRIPTIONS_PROGRESS_TOTAL,
  PrescriptionsProgress,
} from "./PrescriptionsProgress";

export { PRESCRIPTIONS_PROGRESS_TOTAL, PrescriptionsProgress };

const PrescriptionsFTUEFlow = {
  Logic: FTUEPrescriptionsLogic,
  Hints: PrescriptionsHints,
  Screen: FTUEPrescriptionScreen,
};

export default PrescriptionsFTUEFlow;
