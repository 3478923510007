import {
  CreatePrescriptionsBody,
  Prescription as PrescriptionModel,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import { getHeaders, getURL, handleApiError } from "../utils";

const createPrescriptions = async (
  medicalChildId: string,
  body: CreatePrescriptionsBody
): Promise<PrescriptionModel[]> => {
  const URL = getURL() + `/api/medicalChild/${medicalChildId}/prescription`;

  const headers = await getHeaders(true, "createPrescriptions");

  try {
    const result = await axios.post<PrescriptionModel[]>(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const updatePrescription = async (
  medicalChildId: string,
  prescription: PrescriptionModel
): Promise<string> => {
  const URL =
    getURL() +
    `/api/medicalChild/${medicalChildId}/prescription/${prescription.prescriptionId}`;

  const headers = await getHeaders(true, "updatePrescription");

  try {
    const result = await axios.put<string>(URL, prescription, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const Prescription = {
  createPrescriptions,
  updatePrescription,
};

export default Prescription;
