import {
  ButtonBase,
  Drawer,
  drawerClasses,
  Grid,
  Typography,
} from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import BackgroundAssets from "assets/background";
import DialogAssets from "assets/dialogs";
import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { SAFE_AREA_TOP } from "stylesheets";
import StickyButtons from "../Buttons/StickyButtons";

export interface VisitChildSectionPromptProps {
  open: boolean;
  onClose: () => void;
  visitAsChild: () => void;
  visitAsParent: () => void;
}

const VisitChildSectionPrompt: React.FC<VisitChildSectionPromptProps> = ({
  open,
  onClose,
  visitAsChild,
  visitAsParent,
}) => {
  const { t } = useTranslation();

  return (
    <Drawer
      open={open}
      anchor="bottom"
      sx={(theme) => ({
        [`& .${drawerClasses.paper}`]: {
          height: "100vh",
          borderRadius: 0,
          backgroundColor: theme.palette.secondary.main,
          backgroundImage: `url(${BackgroundAssets.RedBackground})`,
          backgroundSize: "cover",
        },
      })}
    >
      <Grid
        container
        direction="column"
        height="100%"
        flexWrap="nowrap"
        mt={SAFE_AREA_TOP}
      >
        <Grid item>
          <ButtonBase
            onClick={onClose}
            sx={(theme) => ({
              padding: theme.spacing(1),
              borderRadius: "20px",
              svg: {
                width: "2rem",
                height: "2rem",
              },
            })}
          >
            <Icons.CloseIcon color="#fff" />
          </ButtonBase>
        </Grid>
        <Grid item>
          <Typography fontWeight="bold" color="#fff" textAlign="center">
            {t("ftue.visitChildSection.title")}
          </Typography>
        </Grid>
        <Grid
          item
          m="auto"
          sx={{
            img: {
              width: "90vw",
              maxWidth: "400px",
            },
          }}
        >
          <img src={DialogAssets.VisitChildSection} alt="" />
        </Grid>
        <Grid
          item
          sx={{
            mb: 20,
          }}
        >
          <Typography color="#fff" textAlign="center">
            <Trans
              i18nKey={"ftue.visitChildSection.text"}
              components={{ bold: <strong /> }}
            ></Trans>
          </Typography>
        </Grid>
      </Grid>
      <StickyButtons
        cancelColor="secondary"
        cancelText={t("ftue.visitChildSection.buttons.asChild")}
        onCancel={visitAsChild}
        cancelVariant="contained"
        confirmColor="secondary"
        confirmText={t("ftue.visitChildSection.buttons.asParent")}
        onConfirm={visitAsParent}
        confirmVariant="contained"
      />
    </Drawer>
  );
};
export default VisitChildSectionPrompt;
