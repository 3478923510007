import { ButtonBase, SxProps, Theme } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";
import { PropsWithChildren } from "react";

export interface ValidationButtonProps extends CypressProps, PropsWithChildren {
  active: boolean;
  onClick: VoidFunction;
  disabled?: boolean;
  mini?: boolean;
  sx?: SxProps<Theme>;
}

export const ValidationButton = ({
  onClick,
  active,
  disabled = false,
  mini = false,
  sx,
  ...props
}: ValidationButtonProps): JSX.Element => {
  return (
    <ButtonBase
      onClick={onClick}
      disabled={disabled}
      data-cy={props["data-cy"]}
      sx={[
        (theme) => ({
          "width": "3em",
          "height": "2.5em",
          "borderWidth": disabled ? 0 : "1px",
          "borderStyle": "solid",
          "borderColor": theme.palette.secondary.main,
          "borderRadius": "5px",
          "padding": "0.25em",
          "backgroundColor": disabled
            ? "#e0e0e0"
            : active
            ? theme.palette.secondary.main
            : "#fff",
          "color": active
            ? theme.palette.secondary.contrastText
            : theme.palette.secondary.main,
          "fontSize": mini ? "0.6rem" : "1rem",
          "& .icon": {
            height: "2em",
            width: "2em",
          },
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {props.children}
    </ButtonBase>
  );
};

export default ValidationButton;
