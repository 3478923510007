import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { FirstRoutineProgress } from "..";
import useFTUE from "useFTUE";
import { Card, Typography } from "@mui/material";

export interface RoutineDisplayProps extends PropsWithChildren {
  isFirst: boolean;
}

const RoutineDisplay = ({
  isFirst,
  ...props
}: RoutineDisplayProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    selectors: { progress },
    actions: { canDisplayFlow, setFirstRoutineProgress },
  } = useFTUE();

  const firstRoutineProgress = progress[FTUEFlow.FirstRoutine].progress;

  const onNext = () => {
    setFirstRoutineProgress(FirstRoutineProgress.RoutineDisplay);
  };

  return isFirst ? (
    <FTUEHint
      hint={
        <Card
          sx={{
            padding: 2,
          }}
        >
          <Typography textAlign="center">
            {t("ftue.firstRoutine.routineDisplay.hint")}
          </Typography>
        </Card>
      }
      floating
      hintAfter
      condition={
        firstRoutineProgress === FirstRoutineProgress.RoutineConfirmed &&
        canDisplayFlow(FTUEFlow.FirstRoutine)
      }
      onNext={onNext}
    >
      {props.children}
    </FTUEHint>
  ) : (
    <>{props.children}</>
  );
};

export default RoutineDisplay;
