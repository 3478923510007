import {
  FirestoreChallenge,
  GamerChild,
  Routine,
  SubscriptionStatus,
} from "@neurosolutionsgroup/models";

const userHasPremiumFetures = (
  children: GamerChild[],
  routines: Routine[],
  challenges: FirestoreChallenge[]
): boolean => {
  const activeChildCount = children.filter((child) => !child.isDisabled).length;

  if (activeChildCount > 1) {
    return true;
  }

  const activeRoutines = routines.filter(
    (routine) => !routine.deleted && !routine.disabled && !routine.locked
  );

  const routineCount = activeRoutines.length;

  if (routineCount > 1) {
    return true;
  }

  const hasPremiumRoutines = activeRoutines.some(
    (routine) => routine.tasks.filter((task) => !task.deleted).length > 5
  );

  if (hasPremiumRoutines) {
    return true;
  }

  const activeChallenges = challenges.filter((challenge) => challenge.active);

  return activeChallenges.length > 0;
};

const userMustDowngrade = (
  subscriptionStatus: SubscriptionStatus,
  children: GamerChild[],
  routines: Routine[],
  challenges: FirestoreChallenge[]
): boolean => {
  if (subscriptionStatus.isSubscribed) {
    return false;
  } else {
    if (userHasPremiumFetures(children, routines, challenges)) {
      return true;
    }

    if (!subscriptionStatus.hasPreviousSubscription) {
      return false;
    }

    return !subscriptionStatus.lastDowngradeExecution;
  }
};

export default userMustDowngrade;
