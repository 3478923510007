const unityCallbackHandler = async (
  callbackFlag: boolean,
  setCallbackFlag: VoidFunction,
  timeout: number
): Promise<void> => {
  setTimeout(() => {
    setCallbackFlag();
  }, timeout);

  const timeoutAsync = async (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const checkCallback = async (): Promise<void> => {
    if (callbackFlag === false) {
      await timeoutAsync(500);
      checkCallback();
    }
  };

  await checkCallback();
};

const UnityTools = {
  unityCallbackHandler,
};

export default UnityTools;
