import { Box, SxProps, Theme, Typography, useTheme } from "@mui/material";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import ChatLoader from "./ChatLoader";
import { useMemo } from "react";

interface ChatMessageCardProps {
  message: string | JSX.Element | null;
  isUserMessage: boolean;
  sx?: SxProps<Theme>;
  highlight?: boolean;
  error?: boolean;
}

const ChatMessageCard = ({
  message,
  isUserMessage,
  sx,
  highlight = false,
  error,
}: ChatMessageCardProps): JSX.Element => {
  const theme = useTheme();

  const textColor = error
    ? "error"
    : highlight
    ? "primary.contrastText"
    : isUserMessage
    ? "secondary.contrastText"
    : "secondary";

  const baseStyle: SxProps<Theme> = (theme) => ({
    backgroundColor: highlight
      ? theme.palette.primary.main
      : isUserMessage
      ? theme.palette.secondary.main
      : theme.palette.background.paper,
    paddingX: 2,
    paddingY: 1,
    borderRadius: 5,
    borderBottomLeftRadius: isUserMessage ? undefined : theme.spacing(0.5),
    borderBottomRightRadius: isUserMessage ? theme.spacing(0.5) : undefined,
    boxShadow: SharedStyles.Shadows.defaultShadow,
    color: textColor,
  });

  const displayLoading = useMemo(() => {
    if (message === null) {
      return true;
    }

    if (typeof message === "string" && message.length === 0) {
      return true;
    }

    return false;
  }, [message]);

  return (
    <Box sx={[...(Array.isArray(sx) ? sx : [sx]), baseStyle]} display="flex">
      {displayLoading ? (
        <ChatLoader color={theme.palette.secondary.main} width="3rem" />
      ) : (
        <Typography
          color={textColor}
          sx={{
            wordBreak: "break-word",
          }}
        >
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default ChatMessageCard;
