import { Box } from "@mui/material";

interface TierComparisonCardIconProps {
  icon: JSX.Element;
}

const TierComparisonCardIcon = ({
  icon,
}: TierComparisonCardIconProps): JSX.Element => {
  return (
    <Box
      sx={{
        backgroundColor: "success.main",
        padding: 0.75,
        borderRadius: "999px",
        color: "success.contrastText",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {icon}
    </Box>
  );
};

export default TierComparisonCardIcon;
