import { Box, Drawer, Typography } from "@mui/material";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import React from "react";
import { useTranslation } from "react-i18next";

interface UnitPickerProps {
  setValue: (value: "mg" | "ml") => void;
  open: boolean;
  setOpen: (value: boolean) => void;
}

const UnitPicker: React.FC<UnitPickerProps> = ({ setValue, open, setOpen }) => {
  const { t } = useTranslation();
  return (
    <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
      <DrawerSection variant="header">
        <Typography variant="h2" className="routine-template-selection__title">
          {t("general.unit")}
        </Typography>
      </DrawerSection>
      <DrawerSection>
        <Box
          sx={{ height: "100%" }}
          onClick={() => {
            setValue("mg");
            setOpen(false);
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h2"
            className="routine-template-selection__title"
          >
            mg
          </Typography>
        </Box>
      </DrawerSection>
      <DrawerSection>
        <Box
          sx={{ height: "100%" }}
          onClick={() => {
            setValue("ml");
            setOpen(false);
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h2"
            className="routine-template-selection__title"
          >
            ml
          </Typography>
        </Box>
      </DrawerSection>
    </Drawer>
  );
};

export default UnitPicker;
