import React from "react";
import RoutineIcon from "assets/routines/templates/index";
import useLanguage from "common/hooks/Parameters/useLanguage";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { Typography } from "@mui/material";
import { Template } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import { templateLoc } from "@neurosolutionsgroup/localization";
import TemplateMenuChoice from "./TemplateMenuChoice";

interface TemplateSelectionProps {
  lockedTemplates: Template[];
  multipleRoutinesWarning: boolean;
  onClick: (template: Template) => void;
  onPremiumLockClick: (template: Template) => void;
}

const TemplateSelection: React.FC<TemplateSelectionProps> = ({
  lockedTemplates,
  multipleRoutinesWarning,
  onClick,
  onPremiumLockClick,
}) => {
  const { language } = useLanguage();
  const { t } = useTranslation();

  return (
    <>
      <DrawerSection variant="header">
        <Typography variant="h4" className="routine-template-selection__title">
          {t("routine.selection.template")}
        </Typography>
      </DrawerSection>

      <TemplateMenuChoice
        data-cy="template-morning-button"
        text={templateLoc[language][Template.Morning]}
        imageSrc={RoutineIcon[1]}
        onClick={() => {
          onClick(Template.Morning);
        }}
        onPremiumLockClick={() => onPremiumLockClick(Template.Morning)}
        premiumLock={lockedTemplates.includes(Template.Morning)}
      />
      {multipleRoutinesWarning ? (
        <Typography color="error" fontSize="0.8rem" ml={1} mt={-1}>
          {t("routine.selection.multipleRoutinesWarning")}
        </Typography>
      ) : null}
      <TemplateMenuChoice
        data-cy="template-midday-button"
        text={templateLoc[language][Template.Midday]}
        imageSrc={RoutineIcon[2]}
        onClick={() => {
          onClick(Template.Midday);
        }}
        onPremiumLockClick={() => onPremiumLockClick(Template.Midday)}
        premiumLock={lockedTemplates.includes(Template.Midday)}
      />
      <TemplateMenuChoice
        data-cy="template-afternoon-button"
        text={templateLoc[language][Template.Afternoon]}
        imageSrc={RoutineIcon[3]}
        onClick={() => {
          onClick(Template.Afternoon);
        }}
        onPremiumLockClick={() => onPremiumLockClick(Template.Afternoon)}
        premiumLock={lockedTemplates.includes(Template.Afternoon)}
      />
      <TemplateMenuChoice
        data-cy="template-evening-button"
        text={templateLoc[language][Template.Evening]}
        imageSrc={RoutineIcon[4]}
        onClick={() => {
          onClick(Template.Evening);
        }}
        onPremiumLockClick={() => onPremiumLockClick(Template.Evening)}
        premiumLock={lockedTemplates.includes(Template.Evening)}
      />
      <TemplateMenuChoice
        data-cy="template-other-button"
        text={templateLoc[language][Template.Other]}
        imageSrc={RoutineIcon[0]}
        onClick={() => {
          onClick(Template.Other);
        }}
        onPremiumLockClick={() => onPremiumLockClick(Template.Other)}
        premiumLock={lockedTemplates.includes(Template.Other)}
      />
    </>
  );
};

export default TemplateSelection;
