import { ChatUserMessage } from "./ChatUserMessage";

export class ChatConversation {
  id: string;
  messages: ChatUserMessage[] = [];
  createdDate: string;
  version: number;

  constructor(
    id: string,
    messages: ChatUserMessage[],
    createdDate: string,
    version: number
  ) {
    this.id = id;
    this.messages = messages;
    this.createdDate = createdDate;
    this.version = version;
  }

  getHistory(maxConversationHistory: number): string | undefined {
    if (this.messages.length === 0) {
      return undefined;
    }

    let output = "";

    // Take last N messages from history
    // to prevent using too many token
    const validMessages = this.messages
      .slice(-maxConversationHistory)
      .filter((m) => m.response !== undefined);

    for (const message of validMessages) {
      output += `User: "${message.content}"\nAssistant: "${message.response?.content}"\n`;
    }

    return output;
  }
}
