import { Box, ButtonBase } from "@mui/material";
import OptionPage from "pages/optionPage/OptionPage";
import React from "react";
import useNavigation from "common/hooks/navigation/useNavigation";
import { Icons } from "@neurosolutionsgroup/components";
import useParameters from "common/hooks/Parameters/useParameters";
import { UniWebViewActions } from "common/hooks/Parameters/UniWebViewActions";

interface PageHeaderProps {
  leftElement?: JSX.Element;
  centralElement?: JSX.Element;
  rightElement?: JSX.Element;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  className?: string;
  shadow?: boolean;
}

export const PAGE_HEADER_HEIGHT = "50px";

const PageHeader: React.FC<PageHeaderProps> = ({
  leftElement,
  centralElement,
  rightElement,
  onClickLeft,
  onClickRight,
  shadow = false,
  ...props
}) => {
  const ROOT_CLASS = "page-header";
  const {
    actions: { setMenuPageOpen },
  } = useNavigation();
  const { sendMessageToUnity } = useParameters();

  const onClickLeftHandler = () => {
    if (onClickLeft) {
      onClickLeft();
    } else {
      sendMessageToUnity(UniWebViewActions.Close);
    }
  };

  const onClickRightHandler = () => {
    if (onClickRight) {
      onClickRight();
    } else {
      setMenuPageOpen(true);
    }
  };

  const leftDefaultElement: JSX.Element = (
    <ButtonBase
      onClick={onClickLeftHandler}
      sx={{
        height: PAGE_HEADER_HEIGHT,
        width: PAGE_HEADER_HEIGHT,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        svg: {
          width: "1.5em",
          height: "1.5em",
        },
      }}
      data-cy="page-header-back"
    >
      <Icons.ArrowIcon arrowDirection="left" color={"#fff"} />
    </ButtonBase>
  );

  const centralDefaultElement: JSX.Element = <></>;

  const rightDefaultElement: JSX.Element = (
    <>
      <ButtonBase
        onClick={onClickRightHandler}
        data-cy={"menu-hamburger"}
        sx={{
          width: "4em",
          height: "4em",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "10px",
          svg: {
            width: "1.5em",
            height: "1.5em",
          },
        }}
      >
        <Icons.BurgerMenuIcon color="#fff" data-cy="burger-menu-icon" />
      </ButtonBase>
      <OptionPage />
    </>
  );

  return (
    <Box
      className={props.className ? props.className : ROOT_CLASS}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={(theme) => ({
        backgroundColor: theme.palette.primary.dark,
        height: PAGE_HEADER_HEIGHT,
        position: "relative",
        boxShadow: shadow ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : undefined,
      })}
      {...props}
    >
      <>
        <Box display="flex">{leftElement ?? leftDefaultElement}</Box>
        <Box display="flex">{centralElement ?? centralDefaultElement}</Box>
        <Box display="flex" alignItems="center">
          {rightElement ?? rightDefaultElement}
        </Box>
      </>
    </Box>
  );
};

export default PageHeader;
