import { Loader } from "@neurosolutionsgroup/components";
import {
  ChallengeV2,
  FirestoreChallenge,
  SkillShortData,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { SkillsDashboard } from "@neurosolutionsgroup/webviews-pages";
import RoutineAssets from "assets/routines";
import CoachAssets from "assets/coach";
import DialogAssets from "assets/dialogs";
import TaskAssets from "assets/task-icons";
import useChallenges from "common/hooks/challenges/useChallenges";
import useChildren from "common/hooks/children/useChildren";
import React, { useEffect, useMemo, useState } from "react";
import useSubscription from "common/hooks/subscription/useSubscription";
import { PremiumFeature } from "@neurosolutionsgroup/analytics";
import useLanguage from "common/hooks/Parameters/useLanguage";
import ChallengeCard from "pages/Objectives/Objectives/component/Challenge/ChallengeCard";
import { Box } from "@mui/material";
import { add, isBefore } from "date-fns";
import useTasks from "common/hooks/routines/useTasks";

const SkillsDashboardWrapper = (): JSX.Element => {
  const {
    selectors: { challenges },
    actions: { getAllChallengesForChild },
  } = useChallenges();
  const {
    selectors: { medicalChildrenByGamerId, selectedChild },
  } = useChildren();
  const { dateLocale } = useLanguage();
  const { permissions, onPremiumFeatureClick } = useSubscription();

  const { iconManager } = useTasks().selectors;

  const [loading, setLoading] = useState(false);
  const [skills, setSkills] = useState<SkillShortData[]>([]);

  const followedSkillIds: string[] = useMemo(() => {
    if (
      !selectedChild ||
      !medicalChildrenByGamerId[selectedChild] ||
      (medicalChildrenByGamerId[selectedChild].needs?.length ?? 0) < 1
    ) {
      return [];
    } else {
      const skillIds = medicalChildrenByGamerId[selectedChild].needs ?? [];

      return skillIds;
    }
  }, [medicalChildrenByGamerId, selectedChild]);

  const onGoingChallenges: ChallengeV2[] = challenges.filter((challenge) => {
    if (
      !(
        challenge.childId === selectedChild &&
        Tools.Data.Challenges.V2.isChallengeV2(challenge)
      )
    ) {
      return false;
    } else {
      const challengeV2 = challenge as ChallengeV2;

      const startDate = Tools.Time.Dates.parseDateStringToJsDate(
        challengeV2.startDate
      );

      const endDate = add(startDate, { weeks: challengeV2.duration, days: -1 });

      return isBefore(new Date(), endDate);
    }
  }) as ChallengeV2[];

  const v1OngoingChallenges: FirestoreChallenge[] = challenges.filter(
    (challenge) =>
      challenge.childId === selectedChild &&
      !Tools.Data.Challenges.V2.isChallengeV2(challenge)
  );

  useEffect(() => {
    const getSkills = async (childId: string) => {
      setLoading(true);

      const allChallenges = await getAllChallengesForChild(childId);

      const challengesBySkill = Tools.Objects.groupBy(
        allChallenges,
        (challenge) => challenge.skillId ?? "other"
      );

      const formattedSkills = Object.entries(challengesBySkill).map(
        ([skillId, challenges]) =>
          Tools.Data.Challenges.V2.formatShortChallengeData(
            skillId,
            challenges as ChallengeV2[]
          )
      );

      followedSkillIds.forEach((skillId) => {
        if (!formattedSkills.find((skill) => skill.skillId === skillId)) {
          formattedSkills.push({
            skillId,
            lastChallengeWeeks: null,
            successPercentage: null,
            ongoingChallenge: false,
          });
        }
      });

      setSkills(formattedSkills);

      setLoading(false);
    };

    if (selectedChild) {
      getSkills(selectedChild);
    }
  }, [followedSkillIds]);

  return loading ? (
    <Loader />
  ) : (
    // Extra padding so text is not blocked by chat button.
    <Box pb="70px">
      {v1OngoingChallenges.length > 0 && permissions.challenges ? (
        <Box mb={1}>
          {v1OngoingChallenges.map((challenge) => (
            <ChallengeCard challenge={challenge} />
          ))}
        </Box>
      ) : null}
      <SkillsDashboard
        challengeIconImgSrc={RoutineAssets.ChallengeIcon}
        currentChallenges={onGoingChallenges}
        coachInfoIconSrc={CoachAssets.CoachInfoHead}
        dateLocale={dateLocale}
        shortInfoImgSrc={DialogAssets.DashboardInfoSkills}
        skills={skills}
        premiumLock={!permissions.challenges}
        onPremiumLockClick={() => {
          onPremiumFeatureClick(PremiumFeature.ChallengeDashboard);
        }}
        taskIconAssets={TaskAssets.TaskIcons}
        iconManager={iconManager}
        hasV1Challenges={
          v1OngoingChallenges.length > 0 && permissions.challenges
        }
      />
    </Box>
  );
};

export default SkillsDashboardWrapper;
