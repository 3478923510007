import { Note } from "@neurosolutionsgroup/models";

export const filterNotesBySubject = (
  notes: Note[],
  filters: string[]
): Note[] => {
  if (filters.length === 0) {
    return notes;
  }
  return notes.filter((note) => {
    if (note.routineCategoryId && filters.includes(note.routineCategoryId)) {
      return true;
    }

    if (note.sideEffectId && filters.includes(note.sideEffectId)) {
      return true;
    }

    if (note.needId && filters.includes(note.needId)) {
      return true;
    }

    return false;
  });
};
