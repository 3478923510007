import { DashboardPrescriptionsProgress } from "./DashboardPrescriptionsProgress";

const calculateProgress = (step: DashboardPrescriptionsProgress): number => {
  return Object.values(DashboardPrescriptionsProgress).findIndex(
    (v) => v === step.toString()
  );
};

const getProgress = (recordIds: string[]): DashboardPrescriptionsProgress => {
  let latestDashboardPrescriptionsStep = DashboardPrescriptionsProgress.None;

  if (
    recordIds.includes(
      DashboardPrescriptionsProgress.DashboardPrescriptionsFTUEFinished.toString()
    )
  ) {
    latestDashboardPrescriptionsStep =
      DashboardPrescriptionsProgress.DashboardPrescriptionsFTUEFinished;
  } else {
    Object.values(DashboardPrescriptionsProgress).forEach((v) => {
      if (recordIds.includes(v)) {
        latestDashboardPrescriptionsStep = v;
      }
    });
  }

  return latestDashboardPrescriptionsStep;
};

const isComplete = (progress: DashboardPrescriptionsProgress): boolean => {
  return (
    progress ===
    DashboardPrescriptionsProgress.DashboardPrescriptionsFTUEFinished
  );
};

const meetsCriteria = (hasRecords: boolean): boolean => {
  return hasRecords;
};

const FTUEDashboardPrescriptionLogic = {
  calculateProgress,
  getProgress,
  isComplete,
  meetsCriteria,
};

export default FTUEDashboardPrescriptionLogic;
