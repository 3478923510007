import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";
import React from "react";

export const BurgerMenuIcon = ({
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon classModifier={"burger"} {...props}>
      <path
        d="M 0,16.701414 H 20 V 14.4676 H 0 Z M 0,11.116901 H 20 V 8.8830977 H 0 Z M 0,3.2985855 v 2.233802 h 20 v -2.233802 z"
        fill={color}
        id="path20"
      />
    </BaseIcon>
  );
};

export default BurgerMenuIcon;
