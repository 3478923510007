import React from "react";
import AlertDialog from "./AlertDialog";
import { Box, Typography } from "@mui/material";
import { Trans, useTranslation } from "react-i18next";

export interface ChallengeEndDialogProps {
  childName: string;
  rewardText: string;
  open: boolean;
  failedChallengeimgSrc: string;
  successfullChallengeimgSrc: string;
  partialChallengeimgSrc: string;
  dialogState: "failed" | "partial" | "successful" | "unfinished";
  onClick: VoidFunction;
  onClose: VoidFunction;
}

const ChallengeEndDialog = ({
  childName,
  rewardText,
  open,
  failedChallengeimgSrc,
  successfullChallengeimgSrc,
  partialChallengeimgSrc,
  dialogState,
  onClick,
  onClose,
}: ChallengeEndDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const dialogTitle = (): string => {
    switch (dialogState) {
      case "failed":
        return t("routine.challenge.endOfChallenge.failed.title");
      case "partial":
        return t("routine.challenge.endOfChallenge.partial.title");
      case "successful":
        return t("routine.challenge.endOfChallenge.successful.title");
      default:
        return "error";
    }
  };

  const failedChallengeContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        img: {
          maxWidth: "100px",
        },
      }}
      data-cy="challenge-end-dialog-fail"
    >
      <img src={failedChallengeimgSrc} alt="" />
      <Typography textAlign="center" fontSize="0.8rem" mt={1}>
        {t("routine.challenge.endOfChallenge.failed.text.result", {
          childName,
        })}
      </Typography>
      <Typography textAlign="center" fontSize="0.8rem" mt={1}>
        {t("routine.challenge.endOfChallenge.failed.text.extra")}
      </Typography>
    </Box>
  );
  const partialChallengeContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        img: {
          maxWidth: "300px",
        },
      }}
      data-cy="challenge-end-dialog-partial"
    >
      <img src={partialChallengeimgSrc} alt="" />
      <Typography textAlign="center" fontSize="0.8rem" mt={1}>
        <Trans
          i18nKey="routine.challenge.endOfChallenge.partial.text.result"
          values={{ childName }}
        />
      </Typography>
      <Typography textAlign="center" fontSize="0.8rem" mt={1}>
        {t("routine.challenge.endOfChallenge.partial.text.extra")}
      </Typography>
      <Typography textAlign="center" fontSize="0.8rem" mt={1}>
        <Trans
          i18nKey="routine.challenge.endOfChallenge.partial.text.reward"
          components={{ bold: <strong /> }}
          values={{ rewardText }}
        />
      </Typography>
    </Box>
  );
  const successfullChallengeContent = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        img: {
          maxWidth: "100px",
        },
      }}
      data-cy="challenge-end-dialog-success"
    >
      <img src={successfullChallengeimgSrc} alt="" />
      <Typography textAlign="center" fontSize="0.8rem" mt={1}>
        {t("routine.challenge.endOfChallenge.successful.text.result", {
          childName,
        })}
      </Typography>
      <Typography textAlign="center" fontSize="0.8rem" mt={1}>
        <Trans
          i18nKey="routine.challenge.endOfChallenge.successful.text.reward"
          values={{ rewardText }}
        />
      </Typography>
    </Box>
  );

  const dialogContent = (): JSX.Element => {
    switch (dialogState) {
      case "failed":
        return failedChallengeContent;
      case "partial":
        return partialChallengeContent;
      case "successful":
        return successfullChallengeContent;
      default:
        return <></>;
    }
  };

  return (
    <AlertDialog
      open={open}
      onClose={onClose}
      title={dialogTitle()}
      text={dialogContent()}
      positiveAction={{
        "text": t("general.understood"),
        "action": onClick,
        "color": "secondary",
        "variant": "contained",
        "data-cy": "challenge-end-dialog-action",
      }}
      data-cy="challenge-end-dialog"
    />
  );
};
export default ChallengeEndDialog;
