import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Dashboard, { DashboardProgress, DASHBOARD_PROGRESS_TOTAL } from "..";
import { FTUEFlow } from "models";
import { FTUEHint } from "components";
import useFTUE from "useFTUE";

export interface DashboardTimePeriodProps extends PropsWithChildren {
  onNext: VoidFunction;
}

const DashboardTimePeriod = ({
  onNext,
  ...props
}: DashboardTimePeriodProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setDashboardProgress },
  } = useFTUE();

  const dashboardProgress = progress[FTUEFlow.Dashboard].progress;

  const onQuit = () => {
    onNext();
    setDashboardProgress(DashboardProgress.DashboardFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        dashboardProgress === DashboardProgress.RoutinesGraph &&
        canDisplayFlow(FTUEFlow.Dashboard)
      }
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      onNext={onQuit}
      nextText={t("ftue.dashboard.close.text")}
      hint={t("ftue.dashboard.timePeriod.text")}
      scrollPosition="center"
      hintAfter={true}
      floating={true}
      currentStep={Dashboard.Logic.calculateProgress(
        DashboardProgress.RoutinesGraph
      )}
      maxStep={DASHBOARD_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default DashboardTimePeriod;
