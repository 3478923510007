import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { FullScreenDrawerPage, PageContentContainer } from "../Page";
import ChatInput from "./ChatInput";
import { Box, Grid } from "@mui/material";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import ChatHeader from "./ChatHeader";
import ChatScrollButton from "./ChatScrollButton";
import ChatMessageWrapper from "./ChatMessageWrapper";
import { FullScreenDrawerPageProps } from "../Page/FullScreenDrawerPage";
import { ChatMessage, SafeAreas } from "@neurosolutionsgroup/models";
import ChatDisclaimerMessage from "./ChatDisclaimerMessage";

export interface ChatPageProps extends FullScreenDrawerPageProps {
  messages: ChatMessage[];
  onChatSubmit: (message: string) => void;
  loading: boolean;
  avatarImgSrc: string;
  onBack: VoidFunction;
  onPrivacyClick: VoidFunction;
  onSupportClick: VoidFunction;
  safeAreas: SafeAreas;
}

const ChatPage = ({
  messages,
  onChatSubmit,
  loading,
  avatarImgSrc,
  onBack,
  onPrivacyClick,
  onSupportClick,
  ...props
}: ChatPageProps): JSX.Element => {
  const [message, setMessage] = useState("");
  const [showScrollButton, setShowScrollButton] = useState(false);

  const scrollAnchorRef = useRef<HTMLDivElement>();
  const scrollContainerRef = useRef<HTMLDivElement>();

  useLayoutEffect(() => {
    scrollAnchorRef.current?.scrollIntoView({ block: "end" });
  }, [messages]);

  const onScrollClick = () => {
    scrollAnchorRef.current?.scrollIntoView({ block: "end" });
  };

  const onScroll = () => {
    const scrollHeight = scrollContainerRef.current?.scrollHeight ?? 0;

    const scrollPosition =
      (scrollContainerRef.current?.scrollTop ?? 0) +
      (scrollContainerRef.current?.clientHeight ?? 0);

    const isScrolledToBottom = scrollHeight - scrollPosition < 20;

    setShowScrollButton(!isScrolledToBottom);
  };

  useEffect(() => {
    scrollContainerRef.current?.addEventListener("scroll", onScroll);

    return () => {
      scrollContainerRef.current?.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <FullScreenDrawerPage {...props}>
      <PageContentContainer
        safeAreas={props.safeAreas}
        sx={{
          scrollY: "none",
          overflowY: "hidden",
        }}
      >
        <Box
          height="100%"
          width="100%"
          display="flex"
          flexDirection="column"
          flexWrap="nowrap"
        >
          <Box
            flex="1"
            display="flex"
            overflow="auto"
            sx={{
              maxWidth: "100%",
              flex: 1,
              display: "flex",
              position: "relative",
            }}
          >
            <ChatHeader onBack={onBack} safeAreas={props.safeAreas} />
            <ChatScrollButton onClick={onScrollClick} show={showScrollButton} />
            <Box
              sx={{
                minHeight: "min-content",
                width: "100%",
                marginLeft: 1,
                // Padding for header gradient.
                paddingTop: "90px",
                overflowY: "auto",
                overflowX: "hidden",
                ...SharedStyles.Layout.noScroll,
              }}
              ref={scrollContainerRef}
            >
              <Grid container spacing={1} width="100%">
                <ChatMessageWrapper
                  avatarImgSrc={avatarImgSrc}
                  isUserMessage={false}
                  message={
                    <ChatDisclaimerMessage
                      onPrivacyClick={onPrivacyClick}
                      onSupportClick={onSupportClick}
                    />
                  }
                  showAvatar={false}
                  highlight
                />
                {messages.map((message, i) => (
                  <ChatMessageWrapper
                    key={i}
                    isUserMessage={message.isUserMessage}
                    message={message.message}
                    showAvatar={!message.isUserMessage}
                    avatarImgSrc={avatarImgSrc}
                  />
                ))}
                {loading ? (
                  <ChatMessageWrapper
                    showAvatar={true}
                    avatarImgSrc={avatarImgSrc}
                    message={null}
                    isUserMessage={false}
                  />
                ) : null}
                <Grid item>
                  <Box
                    ref={scrollAnchorRef}
                    sx={{
                      overflowAnchor: "auto",
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box flexShrink={0} px={1} pb={1}>
            <ChatInput
              value={message}
              onChange={(e) => setMessage(e.currentTarget.value)}
              onSubmit={() => {
                onChatSubmit(message);
                setMessage("");
              }}
              fullWidth
              sx={{
                marginTop: "auto",
              }}
            />
          </Box>
        </Box>
      </PageContentContainer>
    </FullScreenDrawerPage>
  );
};

export default ChatPage;
