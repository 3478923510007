import { TextField, TextFieldProps } from "@mui/material";
import React from "react";

interface TextInputProps {
  "data-cy"?: string;
}

const TextInput: React.FC<TextInputProps & TextFieldProps> = ({
  "data-cy": dataCy,
  ...props
}) => {
  return (
    <TextField
      {...props}
      inputProps={{
        ...props.inputProps,
        "data-cy": dataCy,
      }}
      color="secondary"
      sx={{
        input: {
          fontSize: "0.875rem",
          lineHeight: 1.75,
          fontWeight: "normal",
          padding: "8px 10px",
        },
        textarea: {
          fontSize: "0.875rem",
          lineHeight: 1.75,
          fontWeight: "normal",
        },
        fieldset: {
          boxShadow: "0 0 1px 1px rgba(0, 0, 0, 0.15) inset",
          borderWidth: 0,
        },
      }}
    />
  );
};

export default TextInput;
