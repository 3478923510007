/**
 * Try to get an environment variable matching the variable key passed.
 * If variable is not found, returns undefined.
 */
const getEnvironmentVariable = (variable: string): string | undefined => {
  try {
    return process.env[variable];
  } catch {
    console.warn(
      `Application tried to access missing environment variable: ${variable}`
    );
    return undefined;
  }
};

/**
 * Try to get an environment variable that is required by the application.
 * If variable is not found, throws error.
 */
const getRequiredEnvironmentVariable = (variable: string): string => {
  try {
    const envVar = process.env[variable];

    if (envVar) {
      return envVar;
    }

    throw new Error();
  } catch {
    console.error(
      `Application tried to access missing required environment variable: ${variable}`
    );
    throw new Error(`Missing environment required variable ${variable}.`);
  }
};

const parseEnvVarNumber = (variable: string): number | undefined => {
  const value = getEnvironmentVariable(variable);

  if (!value) {
    return undefined;
  }

  const parsedValue = parseFloat(value);

  return isNaN(parsedValue) ? undefined : parsedValue;
};

const isDevBuild = (): boolean => {
  return process.env.REACT_APP_DEV_BUILD === "true";
};

/**
 * Detect whether the application is deployed to a staging environment.
 */
const isStaging = (): boolean => {
  return (
    process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID === "kairos-dev-c03e6" ||
    process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID === "nexus-fokus-staging"
  );
};

const isProduction = (): boolean => {
  return (
    process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID === "kairos-7066a" ||
    process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID === "nexus-fokus"
  );
};

const isKairos = (): boolean => {
  return (
    process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID === "kairos-dev-c03e6" ||
    process.env.REACT_APP_FIREBASE_CONFIG_PROJET_ID === "kairos-7066a"
  );
};

const getProductName = (): string => {
  return isKairos() ? "Kairos" : "Nexus Fokus";
};

const webviewsEnvironmentVariables = {
  FunctionsURL: "REACT_APP_FIREBASE_FUNCTIONS_URL",
  BuildNumber: "REACT_APP_BUILD_NUMBER",
  DevBuild: "REACT_APP_DEV_BUILD",
  FirstSubscriptionReminderStart: "REACT_APP_FIRST_SUBSCRIPTION_REMINDER_START",
  FirstSubscriptionReminderEnd: "REACT_APP_FIRST_SUBSCRIPTION_REMINDER_END",
  SecondSubscriptionReminderStart:
    "REACT_APP_SECOND_SUBSCRIPTION_REMINDER_START",
  SecondSubscriptionReminderEnd: "REACT_APP_SECOND_SUBSCRIPTION_REMINDER_END",
  SentryDsn: "REACT_APP_SENTRY_DSN",
};

export interface DevAuth {
  email: string;
  password: string;
}

const getDevAuth = (): DevAuth | null => {
  if (
    Environment.isStaging() &&
    !!process.env.REACT_APP_FIREBASE_DEVELOPMENT_AUTH_EMAIL &&
    !!process.env.REACT_APP_FIREBASE_DEVELOPMENT_AUTH_PASSWORD
  ) {
    return {
      email: process.env.REACT_APP_FIREBASE_DEVELOPMENT_AUTH_EMAIL,
      password: process.env.REACT_APP_FIREBASE_DEVELOPMENT_AUTH_PASSWORD,
    };
  } else {
    return null;
  }
};

const getBuildNumber = (): string | undefined => {
  return getRequiredEnvironmentVariable(
    webviewsEnvironmentVariables.BuildNumber
  );
};

const getFirebaseAPIURL = (): string | undefined => {
  return getRequiredEnvironmentVariable(
    webviewsEnvironmentVariables.FunctionsURL
  );
};

const webviewsEnvTools = {
  getBuildNumber,
  getDevAuth,
  getFirebaseAPIURL,
  webviewsEnvironmentVariables,
};

const Environment = {
  getEnvironmentVariable,
  getProductName,
  getRequiredEnvironmentVariable,
  isDevBuild,
  isKairos,
  isProduction,
  isStaging,
  parseEnvVarNumber,
  webviewsEnvTools,
};

export default Environment;
