import {
  isBefore,
  isSameWeek,
  startOfWeek,
  sub,
  differenceInDays,
} from "date-fns";
import { CoachNotificationID } from "./CoachNotifications";
import {
  CoachFlags,
  MessageRecord,
  Routine,
  TaskHistory,
  TasksById,
  Template,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { DifficultTaskResult } from "./CoachContext";

const handleConsecutiveWeeksUseMessage = async (
  coachFlags: CoachFlags,
  timestamp: number,
  seenValidationFTUE: number | undefined,
  histories: TaskHistory[],
  displayNotification: (id: CoachNotificationID) => void,
  updateFlags: (flags: CoachFlags) => Promise<void>
): Promise<void> => {
  let nbrOfWeekSinceFirstValidation = 0;
  if (
    (!coachFlags.lastValidationTimestamp ||
      isNaN(coachFlags.lastValidationTimestamp)) &&
    seenValidationFTUE &&
    histories.length > 0
  ) {
    const lastMonday = Tools.Time.Dates.getTimeStamp(
      startOfWeek(timestamp * 1000, { weekStartsOn: 1 })
    );

    const oneWeeksBeforeLastMonday = Tools.Time.Dates.getTimeStamp(
      sub(lastMonday * 1000, { weeks: 1 })
    );

    const twoWeeksBeforeLastMonday = Tools.Time.Dates.getTimeStamp(
      sub(lastMonday * 1000, { weeks: 2 })
    );

    const hasValidatedTaskInLastWeeks =
      histories.filter(
        (h) =>
          h.confirmTime &&
          h.confirmTime < lastMonday &&
          h.confirmTime > oneWeeksBeforeLastMonday
      ).length > 0;

    const hasValidatedTaskInSecondToLastWeeks =
      histories.filter(
        (h) =>
          h.confirmTime &&
          h.confirmTime < oneWeeksBeforeLastMonday &&
          h.confirmTime > twoWeeksBeforeLastMonday
      ).length > 0;

    if (hasValidatedTaskInLastWeeks || hasValidatedTaskInSecondToLastWeeks) {
      const daysBetweenFTUEandValidation = differenceInDays(
        startOfWeek(timestamp * 1000, { weekStartsOn: 1 }),
        startOfWeek(seenValidationFTUE * 1000, { weekStartsOn: 1 })
      );

      nbrOfWeekSinceFirstValidation = Math.ceil(
        daysBetweenFTUEandValidation / 7
      );
    }
  }

  // Is last validation this week?
  if (
    coachFlags.lastValidationTimestamp &&
    isSameWeek(timestamp * 1000, coachFlags.lastValidationTimestamp * 1000, {
      weekStartsOn: 1,
    })
  ) {
    return;
  }

  // Is last validation older than last week?
  const startOfLastWeek = startOfWeek(sub(timestamp * 1000, { weeks: 1 }), {
    weekStartsOn: 1,
  });

  if (
    coachFlags.lastValidationTimestamp &&
    coachFlags.consecutiveWeeksValidation &&
    isBefore(coachFlags.lastValidationTimestamp * 1000, startOfLastWeek)
  ) {
    const startOfWeekBeforeLast = startOfWeek(
      sub(startOfLastWeek, { weeks: 1 }),
      {
        weekStartsOn: 1,
      }
    );

    // Does 1 weeks grace apply?
    if (
      isBefore(coachFlags.lastValidationTimestamp * 1000, startOfWeekBeforeLast)
    ) {
      const flags: CoachFlags = {
        lastValidationTimestamp: timestamp,
        consecutiveWeeksValidation: 1,
      };

      return await updateFlags(flags);
    } else {
      const flags: CoachFlags = {
        lastValidationTimestamp: timestamp,
        consecutiveWeeksValidation: coachFlags.consecutiveWeeksValidation,
      };

      return await updateFlags(flags);
    }
  }

  let consecutiveWeeksValidation =
    coachFlags.consecutiveWeeksValidation ?? nbrOfWeekSinceFirstValidation;

  consecutiveWeeksValidation++;

  const eventWeeks = [2, 4, 8, 12];
  const qualifiedEventWeeks = eventWeeks.filter(
    (weeks) => weeks === consecutiveWeeksValidation - 1
  );

  if (qualifiedEventWeeks.length > 0) {
    const biggestEvent = qualifiedEventWeeks[qualifiedEventWeeks.length - 1];

    switch (biggestEvent) {
      case 2:
        displayNotification(CoachNotificationID.KairosUsage1);
        break;
      case 4:
        displayNotification(CoachNotificationID.KairosUsage2);
        break;
      case 8:
        displayNotification(CoachNotificationID.KairosUsage3);
        break;
      case 12:
        displayNotification(CoachNotificationID.KairosUsage4);
        break;
    }
  }

  const flags: CoachFlags = {
    lastValidationTimestamp: timestamp,
    consecutiveWeeksValidation,
  };

  try {
    await updateFlags(flags);
  } catch (err) {
    console.error("Error updating flags", err);

    return Promise.reject(err);
  }
};

const handleDifficultTaskValidation = (
  childId: string,
  taskId: string,
  status: boolean | null,
  dueTime: number,
  coachFlags: CoachFlags
): DifficultTaskResult => {
  let newCount = undefined;
  let newLatestValidation = undefined;
  let taskShouldDisplay = false;

  if (
    coachFlags.failedTasks &&
    coachFlags.failedTasks[childId] &&
    coachFlags.failedTasks[childId][taskId]
  ) {
    const flag = coachFlags.failedTasks[childId][taskId];

    if (!flag.latestValidation || flag.latestValidation < dueTime) {
      if (status === true) {
        newCount = 0;

        if (flag.occurrences >= 3) {
          taskShouldDisplay = true;
        }
      } else {
        newCount = flag.occurrences + 1;
      }

      newLatestValidation = dueTime;
    }
  } else if (status === false) {
    newCount = 1;
    newLatestValidation = dueTime;
  }

  return {
    taskId,
    newCount,
    newLatestValidation,
    taskShouldDisplay,
    dueTime,
  };
};

const morningTaskFailed = (
  routine: Routine,
  taskHistory: TaskHistory[]
): boolean => {
  return (
    routine.templateId === Template.Morning &&
    taskHistory.some((th) => th.status === false)
  );
};

const homeworkTaskFailed = (
  routine: Routine,
  taskHistory: TaskHistory[],
  tasksById: TasksById
): boolean => {
  return (
    routine.templateId === Template.Afternoon &&
    taskHistory.some(
      (th) =>
        th.status === false &&
        tasksById[th.task] &&
        (tasksById[th.task].icon === 15 || tasksById[th.task].icon === 17)
    )
  );
};

const sleepTaskFailed = (
  taskHistory: TaskHistory[],
  tasksById: TasksById
): boolean => {
  return taskHistory.some(
    (th) =>
      th.status === false &&
      tasksById[th.task] &&
      tasksById[th.task].icon === 26
  );
};

const nbrOfRoutineValidated = (
  coachFlags: CoachFlags,
  coachRecords: MessageRecord[]
): number => {
  let occurrences = 0;
  if (coachFlags.validationCount) {
    occurrences = coachFlags.validationCount;
  } else {
    // Support old location of occurrences during migration.
    const index = coachRecords.findIndex(
      (cr) => cr.id === CoachNotificationID.ValidationReminder
    );

    if (index !== -1) {
      occurrences = (coachRecords[index].extraData?.occurrences as number) ?? 0;
    }
  }

  return occurrences;
};

const CoachLogic = {
  handleConsecutiveWeeksUseMessage,
  handleDifficultTaskValidation,
  homeworkTaskFailed,
  morningTaskFailed,
  sleepTaskFailed,
  nbrOfRoutineValidated,
};

export default CoachLogic;
