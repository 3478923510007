import Failed from "./end_of_challenge_failed.png";
import Partial from "./end_of_challenge_partial.png";
import Successful from "./end_of_challenge_succesful.png";

const EndOfChallengeAsset = {
  Failed,
  Partial,
  Successful,
};

export default EndOfChallengeAsset;
