import React from "react";

export enum FaceState {
  Sad = "sad",
  Neutral = "neutral",
  Happy = "happy",
}
interface FaceIconProps {
  state: FaceState;
  onClick?: () => void;
}

const FaceIcon = ({ state }: FaceIconProps): JSX.Element => {
  const path = () => {
    switch (state) {
      case FaceState.Sad:
        return (
          <>
            <ellipse
              cx="4.3477788"
              cy="8.0935011"
              rx="4.3477778"
              ry="4.3704333"
              fill="#31737c"
              id="ellipse156"
            />
            <ellipse
              cx="35.652222"
              cy="8.0935011"
              rx="4.3477778"
              ry="4.3704333"
              fill="#31737c"
              id="ellipse158"
            />
            <path
              d="m 32.753893,34.607387 c 0,-7.866726 -24.0577524,-9.032235 -24.0577524,0"
              stroke="#31737c"
              strokeWidth="3.33909"
              strokeLinecap="round"
              id="path160"
            />
          </>
        );
      case FaceState.Happy:
        return (
          <>
            <ellipse
              cx="4.3478346"
              cy="8.1257915"
              rx="4.3478341"
              ry="4.3704901"
              fill="#31737c"
              id="ellipse84"
            />
            <ellipse
              cx="35.652164"
              cy="8.1257915"
              rx="4.3478341"
              ry="4.3704901"
              fill="#31737c"
              id="ellipse86"
            />
            <path
              d="m 8.6955187,28.230023 c 0,7.866829 24.0579483,9.032353 24.0579483,0"
              stroke="#31737c"
              strokeWidth="3.33913"
              strokeLinecap="round"
              id="path88"
            />
          </>
        );
      case FaceState.Neutral:
      default:
        return (
          <>
            <ellipse
              cx="3.7532005"
              cy="8.6990232"
              rx="3.7532001"
              ry="3.7727573"
              fill="#31737c"
              id="ellipse120"
            />
            <ellipse
              cx="30.776264"
              cy="8.6990232"
              rx="3.7532001"
              ry="3.7727573"
              fill="#31737c"
              id="ellipse122"
            />
            <path
              d="M18.510815 33.629593, L38.561691 21.536369"
              stroke="#31737c"
              strokeWidth="2.88244"
              strokeLinecap="round"
              id="line124"
            />
          </>
        );
    }
  };

  return (
    <svg
      className="face-icon"
      width="30"
      height="30"
      viewBox="0 0 40 40"
      fill="none"
    >
      {path()}
    </svg>
  );
};

export default FaceIcon;
