import { Grid, SxProps, Theme } from "@mui/material";
import React, { SetStateAction } from "react";
import { CypressProps, FilterOptionDef } from "@neurosolutionsgroup/models";
import FilterOption from "./FilterOption";

export interface FilterProps<T extends number | string> extends CypressProps {
  options: FilterOptionDef<T>[];
  value: T[];
  onChange: React.Dispatch<SetStateAction<T[]>>;
  sx?: SxProps<Theme>;
  single?: boolean;
  onPremiumLockClick?: VoidFunction;
}

const Filter = <T extends number | string>({
  options,
  value,
  onChange,
  sx,
  single = false,
  onPremiumLockClick,
  ...props
}: FilterProps<T>): JSX.Element => {
  const onOptionClick = (id: T) => {
    if (single) {
      onChange([id]);
    } else {
      onChange((current) => {
        if (current.includes(id)) {
          const clone = [...current];

          if (clone.length > 1) {
            const i = clone.indexOf(id);
            clone.splice(i, 1);
          } else {
            return [];
          }

          return clone;
        } else {
          return [...current, id];
        }
      });
    }
  };

  return (
    <Grid
      container
      spacing={1}
      marginLeft="-1em"
      width="100vw"
      sx={{
        "padding": "1em",
        "overflowX": "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        ...sx,
      }}
      flexWrap="nowrap"
      data-cy={props["data-cy"]}
    >
      {options.map((option) => (
        <Grid item key={option.id}>
          <FilterOption
            label={option.label}
            icon={option.icon}
            active={value.includes(option.id)}
            onClick={() => onOptionClick(option.id)}
            disabled={option.disabled}
            count={option.count}
            premiumLock={option.premiumLock}
            onPremiumLockClick={onPremiumLockClick}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Filter;
