import FTUEDashboardLogic from "./DashboardLogic";
import {
  DASHBOARD_PROGRESS_TOTAL,
  DashboardProgress,
} from "./DashboardProgress";
import FTUEDashboardScreen from "./FullScreen/FTUEDashboardScreen";
import DashboardFTUEHints from "./Hints";

export { DASHBOARD_PROGRESS_TOTAL, DashboardProgress };

const DashboardFTUEFlow = {
  Hints: DashboardFTUEHints,
  Logic: FTUEDashboardLogic,
  Screen: FTUEDashboardScreen,
};

export default DashboardFTUEFlow;
