import { ButtonBase, SxProps, Theme, useTheme } from "@mui/material";
import Icons from "../Icons";

export interface BackButtonProps {
  onClick: VoidFunction;
  sx?: SxProps<Theme>;
  color?: string;
  size?: string;
  rippleColor?: string;
}

const BackButton = ({
  onClick,
  sx,
  color,
  size = "2rem",
  rippleColor = "#ffffff",
}: BackButtonProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      onClick={onClick}
      sx={[
        {
          "padding": 1,
          "borderRadius": "10px",
          "svg": {
            width: size,
            height: size,
          },
          ".MuiTouchRipple-child": {
            backgroundColor: rippleColor,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Icons.ArrowIcon
        arrowDirection="left"
        color={color ?? palette.primary.main}
      />
    </ButtonBase>
  );
};

export default BackButton;
