import { Button } from "@mui/material";
import { PremiumLock } from "@neurosolutionsgroup/components";
import { GamerChild } from "@neurosolutionsgroup/models";
import ChildIcons from "assets/child-icons";
import clsx from "clsx";
import React, { SetStateAction } from "react";

interface ChildPickerProps {
  children: GamerChild[];
  lockDisabledChildren?: boolean;
  onLockedChildClick?: VoidFunction;
  pickedChildren: string[];
  setPickedChildren: React.Dispatch<SetStateAction<string[]>>;
  multiple?: boolean;
  isChildDisabled?: (childId: string) => boolean;
}

const ChildPicker: React.FC<ChildPickerProps> = ({
  children,
  lockDisabledChildren,
  onLockedChildClick,
  pickedChildren,
  setPickedChildren,
  multiple = true,
  isChildDisabled,
}) => {
  const ROOT_CLASS = "child-picker";

  const onChildClick = (childId: string) => {
    setPickedChildren((current) => {
      if (current.includes(childId)) {
        return current.filter((cid) => cid !== childId);
      } else {
        if (!multiple) {
          return [childId];
        } else {
          return current.concat([childId]);
        }
      }
    });
  };

  const renderChild = (child: GamerChild, idx: number) => (
    <PremiumLock
      active={!!lockDisabledChildren && !!child.isDisabled}
      onClick={() => onLockedChildClick && onLockedChildClick()}
      variant="button-long"
      top={"-.5rem"}
      key={idx}
    >
      <Button
        className={clsx(`${ROOT_CLASS}__button`, {
          [`${ROOT_CLASS}__button--active`]: pickedChildren.includes(child.id),
        })}
        key={idx}
        onClick={() => onChildClick(child.id)}
        disabled={isChildDisabled && isChildDisabled(child.id)}
        data-cy={`child-picker-button-${idx}`}
      >
        <img
          className={`${ROOT_CLASS}__icon`}
          src={ChildIcons[child.icon]}
          alt={`Child Icon ${child.icon}`}
        />
        {child.name}
      </Button>
    </PremiumLock>
  );

  const renderChildren = () =>
    children.map((child, idx) => renderChild(child, idx));

  return (
    <div className={ROOT_CLASS}>
      <div className={ROOT_CLASS + "__scroll-container"}>
        {renderChildren()}
      </div>
    </div>
  );
};

export default ChildPicker;
