import {
  FirestoreCollection,
  NotificationCategory,
  NotificationsSettings,
} from "@neurosolutionsgroup/models";
import NotificationCategorySettingsGetterService from "./NotificationSettingsGetter";
import { getAuth } from "firebase/auth";
import { doc, getDoc, getFirestore } from "firebase/firestore";

const getSettingsMock = async (): Promise<NotificationsSettings> => {
  const auth = getAuth();

  const userId = auth.currentUser?.uid;

  if (!userId) {
    return Promise.reject(new Error("User not authenticated."));
  }

  const db = getFirestore();

  const docRef = doc(db, FirestoreCollection.Users, userId);

  const userDoc = await getDoc(docRef);

  if (!userDoc.exists()) {
    return Promise.reject(new Error("User doc not found."));
  }

  const userData = userDoc.data();

  if (userData.masterSettings?.settings?.notificationSettings) {
    const globalOptIn =
      userData.masterSettings.settings.notificationSettings.globalOptIn;

    const categories = userData.masterSettings.settings.notificationSettings
      .categories ?? {
      [NotificationCategory.Routine]: false,
      [NotificationCategory.General]: false,
      [NotificationCategory.Validation]: false,
      [NotificationCategory.Observation]: false,
      [NotificationCategory.Coach]: false,
      [NotificationCategory.News]: false,
      [NotificationCategory.Promotion]: false,
    };

    const optInLastSeen =
      userData.masterSettings.settings.notificationSettings.optInLastSeen;

    return {
      globalOptIn,
      categories,
      optInLastSeen,
    };
  } else {
    return {
      globalOptIn: false,
      categories: {
        [NotificationCategory.Routine]: false,
        [NotificationCategory.General]: false,
        [NotificationCategory.Validation]: false,
        [NotificationCategory.Observation]: false,
        [NotificationCategory.Coach]: false,
        [NotificationCategory.News]: false,
        [NotificationCategory.Promotion]: false,
      },
    };
  }
};

const MockSettingsGetterAdapter: NotificationCategorySettingsGetterService = {
  getSettings: getSettingsMock,
};

export default MockSettingsGetterAdapter;
