import { needsLoc, taskCategoryLoc } from "@neurosolutionsgroup/localization";
import {
  FilterCategory,
  Language,
  Note,
  SideEffectById,
} from "@neurosolutionsgroup/models";
import { Tools } from "../../..";
import { getFilterSeed } from "./getFilterSeed";

export const createNoteFilterOptions = (
  notes: Note[],
  sideEffectById: SideEffectById,
  language: Language,
  skillfiltersTitle: string,
  sideEffectFiltersTitle: string,
  taskFiltersTitle: string
): FilterCategory<string>[] => {
  if (notes.length === 0) {
    return [];
  }

  const seeds = getFilterSeed(notes);

  const taskCategoryFilters: FilterCategory<string>[] = [];

  if (seeds.task.ids.length > 0) {
    taskCategoryFilters.push({
      label: taskFiltersTitle,
      options: seeds.task.ids
        .sort((a, b) =>
          (taskCategoryLoc[language][a] ?? a).localeCompare(
            taskCategoryLoc[language][b] ?? b
          )
        )
        .map((id) => {
          return {
            id,
            label: taskCategoryLoc[language][id] ?? id,
            count: seeds.task.counts[id],
          };
        }),
    });
  }

  const sideEffectFilters: FilterCategory<string>[] = [];

  if (seeds.sideEffect.ids.length > 0) {
    sideEffectFilters.push({
      label: sideEffectFiltersTitle,
      options: seeds.sideEffect.ids
        .sort((a, b) =>
          (sideEffectById[a]?.name[language] ?? a).localeCompare(
            sideEffectById[b]?.name[language] ?? b
          )
        )
        .map((sid) => ({
          id: sid,
          label: sideEffectById[sid]?.name[language] ?? sid,
          count: seeds.sideEffect.counts[sid] ?? 0,
        })),
    });
  }

  const skillFilters: FilterCategory<string>[] = [];

  if (seeds.skill.ids.length > 0) {
    skillFilters.push({
      label: skillfiltersTitle,
      options: Tools.Data.Skills.sortAlphabetically(
        seeds.skill.ids,
        true,
        language
      ).map((s) => ({
        id: s,
        label: needsLoc[language].needs[s] ?? s,
        count: seeds.skill.counts[s] ?? 0,
      })),
    });
  }

  return [...sideEffectFilters, ...skillFilters, ...taskCategoryFilters];
};
