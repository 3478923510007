import { Box, CircularProgress, Divider, Typography } from "@mui/material";
import {
  CoachMessageBanner,
  DrawerFilter,
  EmptyStateAddButton,
  NoteCard,
  WeekNotesDisplay,
  WeekPicker,
} from "@neurosolutionsgroup/components";
import { needsLoc, taskCategoryLoc } from "@neurosolutionsgroup/localization";
import { FilterCategory, GamerChild, Note } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import CoachAssets from "assets/coach";
import { Page } from "common/Components";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useChildren from "common/hooks/children/useChildren";
import { NotesProvider } from "common/hooks/notes/NoteContext";
import useNotes from "common/hooks/notes/useNotes";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import { format } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREAS } from "stylesheets";
import NoteCreation from "./NoteCreation";
import NoteEdition from "./NoteEdition";

const JournalComponent = (): JSX.Element | null => {
  const { language, dateLocale } = useLanguage();
  const { t } = useTranslation();
  const {
    actions: { onJournalAddClicked },
  } = useFTUE();

  const {
    selectors: { selectedChild, childrenById },
  } = useChildren();
  const {
    currentDate,
    loading,
    noteDates,
    noteDateCounts,
    notesForWeek,
    setCurrentDate,
  } = useNotes();
  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const [filters, setFilters] = useState<string[]>([]);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [openAddNoteForm, setOpenAddNoteForm] = useState(false);
  const [openEditNoteForm, setOpenEditNoteForm] = useState(false);

  const gamerChild: GamerChild | undefined = selectedChild
    ? childrenById[selectedChild]
    : undefined;

  const showNotesEmptyState = !Object.values(notesForWeek).some(
    (notes) => notes.length > 0
  );

  const filterOptions: FilterCategory<string>[] = useMemo(() => {
    if (sideEffectById === undefined) {
      return [];
    }
    const skillfiltersTitle = t("dashboard.skills.nav");
    const sideEffectFiltersTitle = t("journal.filters.symptoms");
    const taskFiltersTitle = t("journal.filters.taskCategories");
    const notes = Object.values(notesForWeek).flat();
    return Tools.Data.Notes.createNoteFilterOptions(
      notes,
      sideEffectById,
      language,
      skillfiltersTitle,
      sideEffectFiltersTitle,
      taskFiltersTitle
    );
  }, [language, notesForWeek, sideEffectById]);

  useEffect(() => {
    // Remove filter from values if it is no longer in options.
    filters.forEach((filter) => {
      const optionExists = filterOptions.some((category) =>
        category.options.some((option: { id: string }) => option.id === filter)
      );

      if (!optionExists) {
        setFilters((current) => {
          const clone = [...current];

          return clone.filter((value) => value !== filter);
        });
      }
    });
  }, [filterOptions, filters]);

  const onAddNote = () => {
    onJournalAddClicked();
    setOpenAddNoteForm(true);
  };

  const onNoteClick = (note: Note) => {
    setSelectedNote(note);
    setOpenEditNoteForm(true);
  };

  return gamerChild ? (
    <Page
      sxBody={{
        paddingBottom: "62px",
      }}
    >
      <FTUEFlowDefinitions.JournalFTUEFlow.Screen />
      <Box display="flex" justifyContent="flex-end">
        <WeekPicker
          date={currentDate}
          onChange={setCurrentDate}
          language={language}
          locale={dateLocale}
          taggedDates={noteDates}
        />
      </Box>
      <Box mt={2}>
        <WeekNotesDisplay
          weekEndDate={currentDate}
          dateTags={noteDateCounts.map(({ date, count }) => ({
            date: Tools.Time.Dates.parseDateStringToJsDate(date),
            count,
          }))}
          dateLocale={dateLocale}
        />
      </Box>
      <Divider
        sx={{
          marginY: 1,
        }}
      />
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" minHeight="1.5rem">
          <Typography variant="h4">{t("journal.notes.title")} </Typography>
          {loading ? (
            <CircularProgress
              size="1.5rem"
              color="secondary"
              sx={{ marginLeft: 1 }}
            />
          ) : null}
        </Box>

        <DrawerFilter
          options={filterOptions}
          value={filters}
          onChange={setFilters}
          disabled={filterOptions.length === 0}
          safeAreas={SAFE_AREAS}
        />
      </Box>

      <EmptyStateAddButton
        onClick={onAddNote}
        text={t("journal.notes.add")}
        data-cy="add-note"
        sx={{
          marginTop: 2,
        }}
      />

      {showNotesEmptyState ? (
        <CoachMessageBanner
          coachImgSrc={CoachAssets.CoachJournal}
          coachImagePosition="left"
          bannerContent={t("journal.notes.empty")}
          sx={{
            marginTop: 2,
            marginX: "-1rem",
          }}
        />
      ) : null}

      <Box mt={2}>
        {Object.entries(notesForWeek).map(([date, notes]) => {
          const filteredNotes = Tools.Data.Notes.filterNotesBySubject(
            notes,
            filters
          );

          return filteredNotes.length > 0 ? (
            <Box key={date} mt={1} mb={5}>
              <Typography>
                {format(
                  Tools.Time.Dates.parseDateStringToJsDate(date),
                  "d MMMM yyyy",
                  {
                    locale: dateLocale,
                  }
                )}
              </Typography>
              {Tools.Data.Notes.sortNotesByEventTime(filteredNotes).map(
                (note) => (
                  <NoteCard
                    key={note.noteId}
                    language={language}
                    note={note}
                    onClick={() => onNoteClick(note)}
                    sx={{
                      marginTop: 1,
                    }}
                    taskCategoryLoc={taskCategoryLoc}
                    sideEffectsById={sideEffectById}
                    skillLoc={needsLoc}
                  />
                )
              )}
            </Box>
          ) : null;
        })}
      </Box>

      <NoteCreation
        open={openAddNoteForm}
        onClose={() => setOpenAddNoteForm(false)}
        gamerChild={gamerChild}
      />
      <NoteEdition
        open={openEditNoteForm}
        onClose={() => {
          setOpenEditNoteForm(false);
          setSelectedNote(null);
        }}
        note={selectedNote}
        gamerChild={gamerChild}
      />
    </Page>
  ) : null;
};

const Journal = (): JSX.Element => {
  return (
    <NotesProvider>
      <JournalComponent />
    </NotesProvider>
  );
};

export default Journal;
