import { Box } from "@mui/material";
import Logos from "assets/logo";
import { SAFE_AREAS } from "stylesheets";
import useAuth from "common/hooks/auth/useAuth";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useParameters from "common/hooks/Parameters/useParameters";
import {
  getAuth,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithEmailAndPassword,
  signInWithRedirect,
} from "firebase/auth";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import Toast from "common/Components/Toast/Toast";
import { useAppInitializationContext } from "common/hooks/AppInitializationContext";
import { Tools } from "@neurosolutionsgroup/tools";
import { AgeGateV2 } from "@neurosolutionsgroup/components";
import {
  ExternalLink,
  UnityAuthAction,
  UnityAuthProvider,
  UniWebViewActions,
} from "common/hooks/Parameters/UniWebViewActions";
import { CustomWindow } from "custom.window";
import {
  ConfigString,
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import { useAnalytics } from "@neurosolutionsgroup/analytics";
import { FirebaseError } from "firebase/app";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { LoginPage } from "@neurosolutionsgroup/webviews-pages";

declare let window: CustomWindow;

const Login = (): JSX.Element => {
  const {
    functions: { pageView },
  } = useAnalytics();
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { checkFeatureFlagVersion, getRemoteConfigValue } = useRemoteConfig();
  const { linkHandler, os, sendMessageToUnity, version } = useParameters();
  const { handleFirebaseError } = useErrorsContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { trackLogin, setupAuthFromCustomToken } = useAuth();
  const { userInitiated } = useAppInitializationContext();

  const [authError, setAuthError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openAgeGate, setOpenAgeGate] = useState(false);
  const [openForgottenPasswordAgeGate, setOpenForgottenPasswordAgeGate] =
    useState(false);
  const [openRedirectToast, setOpenRedirectToast] = useState(false);

  const allowThirdPartyAuth: boolean =
    getRemoteConfigValue(ConfigString.ThirdPartyAuth).asString() === "true";

  const logoImageString = Tools.Language.languageSwitch(language, {
    en: Logos.KairosIllustratedEN,
    fr: Logos.KairosIllustratedFR,
  });

  window.nativeAuthResult = async (
    successful: boolean,
    customToken?: string,
    _isNewUser?: boolean,
    error?: string
  ) => {
    if (error && error === "noAccount") {
      setLoading(false);
      navigate("/signup");
      return;
    }
    if (!successful || !customToken) {
      setLoading(false);
      return;
    }

    await setupAuthFromCustomToken(customToken);
  };

  useEffect(() => {
    pageView("login");
  }, []);

  const initEmail: string | undefined = useMemo(() => {
    if (state && state.email) {
      setOpenRedirectToast(true);

      return initEmail;
    } else {
      return undefined;
    }
  }, [state]);

  useEffect(() => {
    if (userInitiated) {
      navigate("/");
    }
  }, [userInitiated]);

  const onContinueGoogle = () => {
    setLoading(true);

    if (
      checkFeatureFlagVersion(WebviewsFeatureFlag.NativeThirdPartyAuth, version)
    ) {
      sendMessageToUnity(
        UniWebViewActions.NativeAuthenication,
        `provider=${UnityAuthProvider.Google}&language=${language}&flow=${UnityAuthAction.Login}`
      );
    } else {
      const provider = new GoogleAuthProvider();

      provider.addScope("https://www.googleapis.com/auth/userinfo.email");
      provider.addScope("https://www.googleapis.com/auth/userinfo.profile");

      const auth = getAuth();

      auth.languageCode = language;

      signInWithRedirect(auth, provider);
    }
  };

  const onContinueApple = () => {
    setLoading(true);

    if (
      os === "ios" &&
      checkFeatureFlagVersion(WebviewsFeatureFlag.NativeThirdPartyAuth, version)
    ) {
      sendMessageToUnity(
        UniWebViewActions.NativeAuthenication,
        `provider=${UnityAuthProvider.Apple}&language=${language}&flow=${UnityAuthAction.Login}`
      );
    } else {
      const provider = new OAuthProvider("apple.com");

      provider.addScope("email");
      provider.addScope("name");

      const auth = getAuth();

      provider.setCustomParameters({
        locale: language,
      });

      signInWithRedirect(auth, provider);
    }
  };

  const onSubmit = async (email: string, password: string) => {
    setLoading(true);

    const auth = getAuth();

    auth.languageCode = language;

    try {
      const userCred = await signInWithEmailAndPassword(auth, email, password);

      trackLogin("email", userCred.user.uid);
    } catch (err) {
      const userAuthErrors = [
        "auth/user-not-found",
        "auth/invalid-password",
        "auth/invalid-email",
        "auth/wrong-password",
      ];

      if (err instanceof FirebaseError && userAuthErrors.includes(err.code)) {
        setAuthError(true);
      } else {
        handleFirebaseError(err);
      }

      setLoading(false);
    }
  };

  const onSignUpClick = () => {
    setOpenAgeGate(true);
  };

  const handleForgottenPasswordLinkClicked = () => {
    setOpenForgottenPasswordAgeGate(true);
  };

  const onForgottenPasswordAgeGateComplete = () => {
    setOpenForgottenPasswordAgeGate(false);
    linkHandler(
      ExternalLink.WebAppForgottenPassword,
      getRemoteConfigValue(ConfigString.ForgottenPasswordLink).asString()
    );
  };

  return (
    <Box>
      <LoginPage
        allowThirdPartyAuth={allowThirdPartyAuth}
        authError={authError}
        clearError={() => setAuthError(false)}
        loading={loading}
        logoImageString={logoImageString}
        onContinueApple={onContinueApple}
        onContinueGoogle={onContinueGoogle}
        onForgottenPasswordLinkClick={handleForgottenPasswordLinkClicked}
        onSignUpClick={onSignUpClick}
        onSubmit={onSubmit}
        open={true}
        os={os}
        productName={Tools.Environment.getProductName()}
        safeAreas={SAFE_AREAS}
        initEmail={initEmail}
      />
      <AgeGateV2
        show={openAgeGate}
        onClose={() => setOpenAgeGate(false)}
        onComplete={() => {
          navigate("/signup?skip-intro=true");
        }}
        safeAreas={SAFE_AREAS}
      />
      <AgeGateV2
        show={openForgottenPasswordAgeGate}
        onClose={() => setOpenForgottenPasswordAgeGate(false)}
        onComplete={() => {
          onForgottenPasswordAgeGateComplete();
        }}
        safeAreas={SAFE_AREAS}
      />
      <Toast
        open={openRedirectToast}
        onClose={() => setOpenRedirectToast(false)}
        timeout={6000}
        title={t("auth.redirect.title")}
        text={t("auth.redirect.text")}
        transparent
      />
    </Box>
  );
};

export default Login;
