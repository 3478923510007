import { Box, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import MedicalAssets from "assets/medical";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import React, { PropsWithChildren } from "react";

interface ObservationCardProps {
  date: string;
  label: string;
  onClick: VoidFunction;
  ftueCard?: boolean;
}

const ObservationCard: React.FC<ObservationCardProps> = ({
  date,
  label,
  onClick,
  ftueCard = false,
}) => {
  const { palette } = useTheme();

  return (
    <ObservationFTUECard ftueCard={ftueCard}>
      <ButtonBase
        onClick={onClick}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "15px",
          padding: "1em",
          width: "100%",
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Box
              sx={{
                width: "3em",
                height: "4em",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                img: {
                  maxHeight: "100%",
                  maxWidth: "100%",
                },
              }}
            >
              <img src={MedicalAssets.ObservationIcon} alt="" />
            </Box>
          </Grid>
          <Grid item flexGrow={1}>
            <Typography textAlign="left" fontSize={"0.85rem"}>
              {date}
            </Typography>
            <Typography textAlign="left" fontWeight="bold">
              {label}
            </Typography>
          </Grid>
          <Grid
            item
            sx={(theme) => ({
              "& .icon-arrow": {
                height: "2em",
                width: "2em",
                path: {
                  fill: theme.palette.secondary.main,
                },
              },
            })}
          >
            <Icons.ArrowIcon
              arrowDirection="right"
              color={palette.secondary.main}
            />
          </Grid>
        </Grid>
      </ButtonBase>
    </ObservationFTUECard>
  );
};

export default ObservationCard;

interface ObservationFTUECardProps extends PropsWithChildren {
  ftueCard: boolean;
}

const ObservationFTUECard: React.FC<ObservationFTUECardProps> = ({
  ftueCard,
  ...props
}) => {
  return ftueCard ? (
    <FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationDisplay>
      {props.children}
    </FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationDisplay>
  ) : (
    <>{props.children}</>
  );
};
