import { differenceInWeeks, isBefore } from "date-fns";
import Time from "../../../Time";

const calculateChallengeWeekIndex = (
  challengeStartDateString: string,
  dateSeconds: number
): number => {
  const challengeStartDate = Time.Dates.parseDateStringToJsDate(
    challengeStartDateString
  );

  if (isBefore(dateSeconds * 1000, challengeStartDate)) {
    return differenceInWeeks(dateSeconds * 1000, challengeStartDate) - 1;
  }

  const diffInWeeks = differenceInWeeks(dateSeconds * 1000, challengeStartDate);

  return diffInWeeks + 1;
};

export default calculateChallengeWeekIndex;
