import { PropsWithChildren } from "react";
import { SafeAreas } from "@neurosolutionsgroup/models";
import useFTUE from "useFTUE";
import FTUEHintComponent from "./FTUEHintComponent";

export interface FTUEHintProps extends PropsWithChildren {
  condition: boolean;
  currentStep?: number;
  maxStep?: number;
  hint?: string | JSX.Element;
  nextDisabled?: boolean;
  onNext?: VoidFunction;
  nextText?: string;
  onBack?: VoidFunction;
  backText?: string;
  onClick?: VoidFunction;
  quit?: VoidFunction;
  quitText?: string;
  scrollPosition?: ScrollLogicalPosition;
  floating?: boolean;
  scroll?: boolean;
  centered?: boolean;
  backgroundOverlay?: boolean;
  overrideHeight?: string;
  hintAfter?: boolean;
  hintMarginTop?: string;
  hintMarginX?: boolean;
  safeAreas?: SafeAreas;
  dataCy?: string;
}

/**
 * A generic FTUE hint that highlights it's child component when active. Displays child as normal when inactive.
 */
const FTUEHint = (props: FTUEHintProps): JSX.Element => {
  const {
    selectors: { blockFTUE },
  } = useFTUE();

  return <FTUEHintComponent {...props} blockFTUE={blockFTUE} />;
};

export default FTUEHint;
