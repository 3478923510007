import { Box, Typography } from "@mui/material";
import {
  MedicationSectionVisited,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import {
  CoachMessageBanner,
  Dialogs,
  EmptyStateAddButton,
} from "@neurosolutionsgroup/components";
import { Prescription } from "@neurosolutionsgroup/models";

import {
  FTUEFlow,
  FTUEFlowDefinitions,
  PrescriptionsProgress,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import {
  PrescriptionCard,
  SideEffectsCard,
} from "@neurosolutionsgroup/webviews-pages";
import CoachAssets from "assets/coach";

import FTUEAssets from "assets/ftue";
import MiscAssets from "assets/misc";
import { CloseablePage, Page } from "common/Components";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useChildren from "common/hooks/children/useChildren";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import useMedication from "common/hooks/medications/useMedication";
import { useCoach } from "common/hooks/messaging/Coach";
import useSideEffect from "common/hooks/sideEffect/useSideEffect";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREAS } from "stylesheets";
import ChangeTakeMedicationDrawer from "./ChangeTakeMedicationDrawer/ChangeTakeMedicationDrawer";
import EditPrescription from "./EditPrescription/EditPrescription";
import EditSideEffect from "./EditSideEffect/EditSideEffect";
import EndPrescriptionDialog from "./EndPrescriptionDialog/EndPrescriptionDialog";
import FollowUpCreation from "./FollowUpCreation/FollowUpCreation";
import PrescriptionForm, {
  PrescriptionFormState,
} from "./prescriptionForm/PrescriptionForm";

const Medication: React.FC = () => {
  const { language } = useLanguage();
  const { t } = useTranslation();
  const { handleEvent } = useAnalytics();
  const { handleUnknownError } = useErrorsContext();
  const { onMedicationAdded } = useCoach();
  const {
    selectors: { progress },
    actions: { onPrescriptionAddClicked },
  } = useFTUE();

  const {
    selectors: {
      getActiveFollowedSideEffects,
      getActivePrescriptionsForSelectedChild,
      prescriptionsByGamerChildId,
    },
    actions: { createPrescription, terminatePrescription },
  } = useFollowUp();
  const {
    selectors: { selectedChild, childrenById, medicalChildrenByGamerId },
    actions: { selectedChildTakesMedication, updateChild },
  } = useChildren();
  const {
    selectors: { medicationById },
  } = useMedication();
  const {
    selectors: { sideEffectById },
  } = useSideEffect();

  const [selectedChildPrescription, setSelectedChildPrescription] = useState<
    Prescription | undefined
  >(undefined);
  const [showFollowUpCreation, setShowFollowUpCreation] =
    useState<boolean>(false);
  const [showEditPrescription, setShowEditPrescription] =
    useState<boolean>(false);
  const [showEditSideEffect, setShowEditSideEffect] = useState<boolean>(false);
  const [perscriptionToEdit, setPrescriptionToEdit] = useState<
    Prescription | undefined
  >(undefined);
  const [endPresciptionDialogOpen, setEndPrescriptionDialogOpen] =
    useState<boolean>(false);
  const [takesMedicationDrawerOpen, setTakesMedicationDrawerOpen] =
    useState<boolean>(false);
  const [showTakesMedicationDialog, setShowTakesMedicationDialog] =
    useState<boolean>(false);
  const [showAddSecondMedication, setShowAddSecondMedication] =
    useState<boolean>(false);

  useEffect(() => {
    const event: MedicationSectionVisited = {
      name: "Medication Section Visited",
    };

    handleEvent(event);
  }, []);

  useEffect(() => {
    if (selectedChild) {
      if (Object.keys(prescriptionsByGamerChildId).includes(selectedChild)) {
        const selectedChildPrescriptions =
          prescriptionsByGamerChildId[selectedChild];

        setSelectedChildPrescription(
          selectedChildPrescriptions.find((p) => !p.endDate)
        );
      } else {
        setSelectedChildPrescription(undefined);
      }
    }
  }, [selectedChild, prescriptionsByGamerChildId]);

  useEffect(() => {
    if (
      progress[FTUEFlow.Prescriptions].progress === PrescriptionsProgress.Intro
    ) {
      onAddFirstMedication();
    }
  }, [progress]);

  const onAddFirstMedication = () => {
    onPrescriptionAddClicked();

    if (selectedChildTakesMedication()) {
      setShowFollowUpCreation(true);
    } else {
      setTakesMedicationDrawerOpen(true);
    }
  };

  const onSubmitSecondMedication = (p: Prescription) => {
    createPrescription(p);
    setShowAddSecondMedication(false);
  };

  const handleMedicationStatusChange = async (medicationStatus: number) => {
    if (selectedChild && (medicationStatus === 1 || medicationStatus === 2)) {
      const child = childrenById[selectedChild];
      const medChild = medicalChildrenByGamerId[selectedChild];
      const diagnosis = medChild ? medChild.diagnosis : [];
      try {
        await updateChild(child, undefined, diagnosis, medicationStatus);
        setTakesMedicationDrawerOpen(false);
        setShowFollowUpCreation(true);
      } catch (err: unknown) {
        handleUnknownError(err);
      }
    }
    if (medicationStatus === 0) {
      setShowTakesMedicationDialog(true);
      setTakesMedicationDrawerOpen(false);
    }
  };

  const showPrescriptionEmptyState =
    selectedChild &&
    medicationById &&
    getActivePrescriptionsForSelectedChild().length === 0;

  return (
    <Page className="medication__page">
      <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Screen
        introImageSrc={FTUEAssets.PrescriptionIntro}
        safeAreas={SAFE_AREAS}
      />
      <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.PrescriptionEnd
        safeAreas={SAFE_AREAS}
        onNext={() => {
          onMedicationAdded();
        }}
      />
      {showFollowUpCreation ? (
        <FollowUpCreation
          isOpen={showFollowUpCreation}
          onClose={() => {
            setShowFollowUpCreation(false);
          }}
        />
      ) : !showPrescriptionEmptyState ? (
        <Box display="flex" flexDirection="column">
          <PrescriptionCard
            prescriptions={getActivePrescriptionsForSelectedChild()}
            onCreate={() => setShowFollowUpCreation(true)}
            onEdit={(p) => {
              setPrescriptionToEdit(p);
              setShowEditPrescription(true);
            }}
            onEnd={() => {
              setEndPrescriptionDialogOpen(true);
            }}
            withMenu={true}
            childName={
              selectedChild && childrenById[selectedChild]
                ? childrenById[selectedChild].name
                : ""
            }
            language={language}
            medicationById={medicationById || {}}
            onAddSecondMedication={() => setShowAddSecondMedication(true)}
            safeAreas={SAFE_AREAS}
            infoIconImage={CoachAssets.CoachInfoHead}
            infoGraphImage={MiscAssets.FTUEPrescriptionGraphENFR}
          />
          {showAddSecondMedication && selectedChild && (
            <CloseablePage
              isOpen={showAddSecondMedication}
              onClose={() => setShowAddSecondMedication(false)}
            >
              <PrescriptionForm
                medicalChildId={selectedChild}
                takesOtherMedication={false}
                setTakesOtherMedication={() => {
                  return;
                }}
                onCancel={() => setShowAddSecondMedication(false)}
                onSubmit={onSubmitSecondMedication}
                formState={PrescriptionFormState.creationSecond}
              />
            </CloseablePage>
          )}
          {selectedChildPrescription && perscriptionToEdit && (
            <EditPrescription
              open={showEditPrescription}
              onClose={() => {
                setShowEditPrescription(false);
              }}
              initialPrescription={perscriptionToEdit}
            />
          )}

          <SideEffectsCard
            language={language}
            sideEffects={getActiveFollowedSideEffects().map((fse) => {
              return fse.sideEffectId;
            })}
            sideEffectById={sideEffectById}
            onEdit={() => setShowEditSideEffect(true)}
            withMenu
            disabled={selectedChildPrescription === undefined}
            infoIconImage={CoachAssets.CoachInfoHead}
            infoGraphImage={MiscAssets.FTUEPrescriptionGraph}
            safeAreas={SAFE_AREAS}
          />

          {selectedChildPrescription && (
            <EditSideEffect
              open={showEditSideEffect}
              onClose={() => setShowEditSideEffect(false)}
              sideEffects={getActiveFollowedSideEffects().map((fse) => {
                return fse.sideEffectId;
              })}
            />
          )}
        </Box>
      ) : (
        <Box>
          <Box
            display="flex"
            width={"100%"}
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h2" my={2}>
              {t("medication.followUp.emptyState.title")}
            </Typography>
          </Box>
          <EmptyStateAddButton
            onClick={onAddFirstMedication}
            text={t("medication.followUp.emptyState.button")}
            data-cy="prescription-empty-state-add"
          />
          <CoachMessageBanner
            coachImgSrc={CoachAssets.CoachPrescription}
            coachImagePosition="right"
            bannerContent={t("medication.followUp.emptyState.banner")}
            sx={{
              marginTop: 2,
              marginX: "-1rem",
            }}
          />
        </Box>
      )}

      {selectedChild && (
        <EndPrescriptionDialog
          open={endPresciptionDialogOpen}
          onClose={() => setEndPrescriptionDialogOpen(false)}
          prescriptions={getActivePrescriptionsForSelectedChild()}
          onConfirm={(prescriptions: string[]) => {
            setEndPrescriptionDialogOpen(false);
            terminatePrescription(prescriptions);
          }}
        />
      )}

      <Dialogs.AlertDialog
        title={t("medication.takesMedication.dialog.title")}
        text={
          <Box
            className="cancel-modal__body"
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            alignItems="center"
            my={1}
          >
            <Box className="cancel-modal__graphic" pr={1}></Box>
            <Typography>
              {t("medication.takesMedication.dialog.text")}
            </Typography>
          </Box>
        }
        open={showTakesMedicationDialog}
        positiveAction={{
          text: t("general.understood"),
          action: () => {
            setShowTakesMedicationDialog(false);
          },
        }}
      />

      <ChangeTakeMedicationDrawer
        open={takesMedicationDrawerOpen}
        onClose={() => setTakesMedicationDrawerOpen(false)}
        onAnswerSelected={(i) => handleMedicationStatusChange(i)}
      />
    </Page>
  );
};

export default Medication;
