import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  calculateStickyButtonPagePadding,
  StickyButtons,
} from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { FTUEAssets } from "models";

export interface IntroProps {
  safeAreas: SafeAreas;
  onContinue: VoidFunction;
  onCancel: VoidFunction;
  assets: FTUEAssets;
}

const Intro = ({
  safeAreas,
  onCancel,
  onContinue,
  assets,
}: IntroProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      minHeight="100%"
      flexWrap="nowrap"
    >
      <Grid item marginTop="auto">
        <Typography textAlign="center" color="#fff" fontWeight="bold">
          {t("ftue.observation.intro.title")}
        </Typography>
      </Grid>
      <Grid item mx="auto">
        <Box
          sx={{
            img: {
              maxWidth: "100%",
              maxHeight: "500px",
            },
          }}
        >
          <img src={assets.observationIntro} alt="" />
        </Box>
      </Grid>
      <Grid
        item
        marginBottom="auto"
        paddingBottom={calculateStickyButtonPagePadding(safeAreas)}
      >
        <Typography textAlign="center" color="#fff">
          {t("ftue.observation.intro.text")}
        </Typography>
      </Grid>
      <StickyButtons
        onConfirm={onContinue}
        onCancel={onCancel}
        confirmText={t("ftue.observation.intro.fill")}
        cancelText={t("general.actions.later")}
        safeAreas={safeAreas}
      />
    </Grid>
  );
};

export default Intro;
