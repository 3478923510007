import { FirestoreChallenge } from "@neurosolutionsgroup/models";

const isChallengeV2 = (challenge: FirestoreChallenge): boolean => {
  return (
    !!challenge.version &&
    typeof challenge.version === "number" &&
    challenge.version === 2
  );
};

export default isChallengeV2;
