import { Box, Button, Checkbox, useTheme } from "@mui/material";
import useMedication from "common/hooks/medications/useMedication";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MedicationPicker from "./MedicationPicker/MedicationPicker";
import { useTranslation } from "react-i18next";
import {
  Dose,
  DoseUnits,
  MedicationCategory,
  Prescription,
} from "@neurosolutionsgroup/models";
import DosagePicker from "./dosagePicker/DosagePicker";
import useChildren from "common/hooks/children/useChildren";
import { Icons } from "@neurosolutionsgroup/components";
import { endOfYear, formatISO, parseISO, sub } from "date-fns";
import { v4 } from "uuid";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import { SimpleDatePicker, StickyButtons } from "common/Components";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";

const ROOT_CLASS = "prescription-form";

export enum PrescriptionFormState {
  creationCompleteFirst,
  creationCompleteSecond,
  creationSecond,
  edition,
}

export interface PrescriptionFormProps {
  medicalChildId: string;
  prescription?: Prescription;
  takesOtherMedication: boolean;
  setTakesOtherMedication: (value: boolean) => void;
  onCancel: () => void;
  onSubmit: (p: Prescription) => void;
  formState: PrescriptionFormState;
}

const PrescriptionForm: React.FC<PrescriptionFormProps> = ({
  medicalChildId,
  prescription,
  takesOtherMedication,
  setTakesOtherMedication,
  onCancel,
  onSubmit,
  formState,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { palette } = useTheme();

  const {
    selectors: { medicationById },
  } = useMedication();

  const {
    selectors: { childrenById, selectedChild },
  } = useChildren();

  const getInitialTime = (): number => {
    const now = new Date();
    return now.getSeconds() + now.getMinutes() * 60 + now.getHours() * 3600;
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const [date, setDate] = useState(
    prescription?.startDate ? parseISO(prescription?.startDate) : new Date()
  );
  const [medicationSelected, setMedicationSelected] = useState<
    string | undefined
  >(prescription?.drug.drugId);
  const [doses, setDoses] = useState<Dose[]>(
    prescription?.drug.doses ?? [
      {
        quantity: 0,
        time: getInitialTime(),
        units: "mg",
      },
    ]
  );

  useEffect(() => {
    setDate(
      prescription?.startDate ? parseISO(prescription?.startDate) : new Date()
    );
    setMedicationSelected(prescription?.drug.drugId);
    setDoses(
      prescription?.drug.doses ?? [
        {
          quantity: 0,
          time: getInitialTime(),
        },
      ]
    );
  }, [prescription]);

  // Check if submission should be disabled.
  const submitDisabled = useCallback((): boolean => {
    if (!medicationSelected) {
      return true;
    }

    if (doses.length > 0) {
      return doses.some((d) => d.quantity < 1 || isNaN(d.quantity));
    } else {
      return true;
    }
  }, [medicationSelected, doses]);

  const addDose = () => {
    const temp: Dose[] = [...doses];
    temp.push({
      quantity: 0,
      time: getInitialTime(),
      units:
        medicationById && medicationSelected
          ? medicationById[medicationSelected].unit ?? "mg"
          : "mg",
    });
    setDoses(temp);
  };

  const removeDose = (i: number) => {
    if (doses[i]) {
      setDoses((current) => {
        const temp: Dose[] = [...current];

        temp.splice(i, 1);

        return temp;
      });
    }
  };

  const onMedicationSelection = (newMedicationId: string) => {
    if (medicationById) {
      setMedicationSelected(newMedicationId);
      setDoses([
        {
          quantity: 0,
          time: getInitialTime(),
          units: medicationById[newMedicationId].unit ?? "mg",
        },
      ]);
    }
  };

  const onFormSubmit = () => {
    if (medicationSelected) {
      onSubmit({
        prescriptionId: prescription ? prescription.prescriptionId : v4(),
        drug: {
          drugId: medicationSelected,
          doses,
        },
        startDate: formatISO(date, { representation: "date" }),
        medicalChildId,
        takesOtherMedication,
      });
    }
  };

  const medicationPickerButtonText = (): string => {
    if (medicationSelected && medicationById) {
      return medicationById[medicationSelected].name[language];
    } else {
      return t("medication.followUp.edit.selectMedication");
    }
  };

  const isMedicationPrivate = useMemo((): boolean => {
    if (
      medicationById &&
      medicationSelected &&
      medicationById[medicationSelected]
    ) {
      return (
        medicationById[medicationSelected].category ===
        MedicationCategory.MyMedication
      );
    }
    return false;
  }, [medicationById, medicationSelected]);

  return (
    <Box className={ROOT_CLASS}>
      <Box
        sx={{
          marginLeft: "calc(-1em + 8px)",
          marginRight: "-1em",
        }}
      >
        <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.DatePicker>
          <SimpleDatePicker
            date={date}
            setDate={setDate}
            label={t("medication.followUp.edit.medicationStartDate")}
            withTitle={true}
            minDate={sub(new Date(), { years: 22 })}
            maxDate={endOfYear(new Date())}
          />
        </FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.DatePicker>
      </Box>
      <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.MedicationPicker>
        <Box
          className={ROOT_CLASS + "__select-medication"}
          borderRadius={15}
          display="flex"
        >
          <Button
            endIcon={
              <Icons.ArrowIcon
                arrowDirection="down"
                color={palette.secondary.main}
              />
            }
            onClick={() => {
              setIsDrawerOpen(true);
            }}
          >
            {medicationPickerButtonText()}
          </Button>
        </Box>
      </FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.MedicationPicker>
      {medicationSelected && (
        <Box
          sx={{
            paddingBottom: STICKY_BUTTONS_PAGE_PADDING,
          }}
        >
          <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.Dosage
            disableNext={submitDisabled()}
            handleOnBack={() => {
              setMedicationSelected(undefined);
              setIsDrawerOpen(true);
            }}
            isMedicationPrivate={isMedicationPrivate}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="start"
              pb={1}
            >
              <h3>{t("medication.followUp.edit.addDosage")}</h3>
              {doses.map((d, i) => {
                return (
                  <Box pb={2} key={i}>
                    <DosagePicker
                      dose={d}
                      updateTime={(time: number) => {
                        setDoses((current) => {
                          const clone = [...current];

                          clone[i] = { ...clone[i], time };

                          return clone;
                        });
                      }}
                      updateDoseValue={(dose: number) => {
                        setDoses((current) => {
                          const clone = [...current];

                          clone[i] = { ...clone[i], quantity: dose };

                          return clone;
                        });
                      }}
                      updateDoseUnits={(units: DoseUnits) => {
                        setDoses((current) => {
                          const clone = [...current];

                          clone[i] = { ...clone[i], units };

                          return clone;
                        });
                      }}
                      removeDose={() => removeDose(i)}
                      medication={medicationSelected}
                      label={t("medication.followUp.edit.dosage" + (i + 1))}
                    />
                  </Box>
                );
              })}
              {doses.length < 3 && (
                <Button
                  className={ROOT_CLASS + "__add-dose--button"}
                  onClick={addDose}
                >
                  <Icons.AddIcon color={palette.secondary.main} />
                </Button>
              )}
            </Box>
          </FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.Dosage>
          {formState === PrescriptionFormState.creationCompleteFirst ? (
            <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.OtherMedication
              handleOnNext={onFormSubmit}
            >
              <>
                <hr />
                <Box pt={1}>
                  <h3>
                    {t("medication.followUp.edit.takesOtherMedication.title")}
                  </h3>
                  <Box
                    px={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <label htmlFor="otherMedication">
                      {t(
                        "medication.followUp.edit.takesOtherMedication.question",
                        {
                          name:
                            selectedChild &&
                            childrenById &&
                            childrenById[selectedChild]?.name,
                        }
                      )}
                    </label>
                    <Checkbox
                      id="otherMedication"
                      checked={takesOtherMedication}
                      onChange={(e) =>
                        setTakesOtherMedication(e.target.checked)
                      }
                      color="secondary"
                    />
                  </Box>
                </Box>
              </>
            </FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.OtherMedication>
          ) : null}
        </Box>
      )}
      <MedicationPicker
        medicationSelected={medicationSelected ?? null}
        onSubmit={onMedicationSelection}
        open={isDrawerOpen}
        setOpen={setIsDrawerOpen}
      />
      <StickyButtons
        onCancel={onCancel}
        onConfirm={onFormSubmit}
        confirmText={
          formState === PrescriptionFormState.creationCompleteFirst ||
          formState === PrescriptionFormState.creationCompleteSecond
            ? t("general.actions.next")
            : undefined
        }
        disabled={submitDisabled()}
      />
    </Box>
  );
};

export default PrescriptionForm;
