import {
  Box,
  Chip,
  Divider,
  Grid,
  Typography,
  chipClasses,
} from "@mui/material";
import { AccordionCard, InformationButton } from "common/Components";
import React, { SetStateAction, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import ProgressAnalysisGraph from "./ProgressAnalysisGraph";
import { TaskCount } from "./useRoutineData";
import { GraphTools } from "@neurosolutionsgroup/graphs";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import useLanguage from "common/hooks/Parameters/useLanguage";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import useChildren from "common/hooks/children/useChildren";
import {
  DashboardProgressCategoryToggled,
  DashboardProgressGraphOpened,
  DashboardRoutineTimePeriodSelected,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import useSubscription from "common/hooks/subscription/useSubscription";
import {
  PeriodOption,
  PeriodPicker,
  PremiumLock,
} from "@neurosolutionsgroup/components";
import { RoutinePeriod } from "@neurosolutionsgroup/models";
import { SAFE_AREAS } from "stylesheets";

export interface ProgressAnalysisProps {
  loading: boolean;
  period: RoutinePeriod;
  periodText: string;
  setPeriod: React.Dispatch<SetStateAction<RoutinePeriod>>;
  periodOptions: PeriodOption[];
  data: TaskCount;
  periodStart: number;
  weeks: boolean;
}

const ProgressAnalysis = ({
  loading,
  period,
  periodText,
  setPeriod,
  periodOptions,
  data,
  periodStart,
  weeks,
}: ProgressAnalysisProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const {
    selectors: { selectedChild, childrenById },
  } = useChildren();
  const { handleEvent } = useAnalytics();

  const { onPremiumFeatureClick, permissions } = useSubscription();

  const [expanded, setExpanded] = useState(true);
  const [categories, setCategories] = useState<string[]>([]);
  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);

  const onPeriodChange = (period: number) => {
    const event: DashboardRoutineTimePeriodSelected = {
      name: "Dashboard Routine Time Period Selected",
      eventProperties: {
        "Selected Time Period": t("dashboard.period.options." + period, {
          lng: "en",
        }),
      },
    };

    handleEvent(event);

    setPeriod(period);
  };

  useEffect(() => {
    setCategories(data.categories.slice(0, 1));
  }, [data]);

  const onCategoriesClick = (categoryId: string) => {
    const event: DashboardProgressCategoryToggled = {
      name: "Dashboard Progress Category Toggled",
      eventProperties: {
        "Task Category": taskCategoryLoc.en[categoryId],
        "Task Category ID": categoryId,
        "Activated": !categories.includes(categoryId),
      },
    };
    handleEvent(event);

    setCategories((current) => {
      if (current.includes(categoryId)) {
        return [...current.filter((c) => c !== categoryId)];
      } else {
        return [...current, categoryId].sort((a, b) => a.localeCompare(b));
      }
    });
  };

  const onExpanded = (action: SetStateAction<boolean>) => {
    if (action.valueOf()) {
      const event: DashboardProgressGraphOpened = {
        name: "Dashboard Progress Graph Opened",
      };

      handleEvent(event);
    }

    setExpanded(action);
  };

  return (
    <AccordionCard
      expanded={expanded}
      setExpanded={onExpanded}
      title={
        <Box display="flex" alignItems="center">
          <Typography variant="h4" mr={1}>
            {t("dashboard.progress.title", {
              childName: selectedChild ? childrenById[selectedChild].name : "",
            })}
          </Typography>
          <Box ml={1} mr={1}>
            <InformationButton
              iconVariant="outline"
              onClick={(e) => {
                e.stopPropagation();
                setOpenInfoDrawer(true);
              }}
            />
          </Box>
        </Box>
      }
      detailsStyle={{
        padding: 0,
      }}
    >
      <PremiumLock
        active={!permissions.progressGraph}
        onClick={() => onPremiumFeatureClick(PremiumFeature.GraphProgress)}
        variant="long"
        sx={{
          width: "100%",
        }}
        buttonSx={{
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
        blur
      >
        <Divider
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main,
          })}
        />

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          py={1}
        >
          <Typography variant="h5">{t("dashboard.period.label")}</Typography>
          <PeriodPicker
            options={periodOptions}
            value={period}
            onChange={onPeriodChange}
            loading={loading}
            hasPremiumLock={!permissions.allRoutinePeriods}
            onPremiumOptionClick={(option) =>
              onPremiumFeatureClick(PremiumFeature.GraphPeriodTaskAnalysis, {
                period: t(
                  "dashboard.period.options." + option.value.toString(),
                  {
                    lng: "en",
                  }
                ),
              })
            }
            safeAreas={SAFE_AREAS}
          />
        </Box>

        <Divider
          sx={(theme) => ({
            borderColor: theme.palette.secondary.main,
          })}
        />

        <Box display="flex" alignItems="center" px={2} py={1.5}>
          <Typography variant="h5" mr={"auto"} textAlign={"left"}>
            {t("dashboard.progress.subtitle", {
              unit: weeks ? t("general.week_one") : t("general.day_one"),
              period: periodText,
            })}
          </Typography>
        </Box>

        <ProgressAnalysisGraph
          data={data.progressData}
          categories={categories}
          periodStart={periodStart}
          weeks={weeks}
        />

        <Grid container direction="row" spacing={1} p={1} pt={0}>
          {data.categories
            .sort((a, b) =>
              taskCategoryLoc[language][a].localeCompare(
                taskCategoryLoc[language][b]
              )
            )
            .map((cat) => (
              <Grid item key={cat}>
                <Chip
                  color="secondary"
                  variant={categories.includes(cat) ? "filled" : "outlined"}
                  sx={{
                    [`& .${chipClasses.avatar}`]: {
                      background: "none",
                    },
                  }}
                  avatar={
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      {GraphTools.getGraphIcon(parseInt(cat))}
                    </Box>
                  }
                  label={taskCategoryLoc[language][cat]}
                  onClick={() => onCategoriesClick(cat)}
                />
              </Grid>
            ))}
        </Grid>
      </PremiumLock>

      <InfoDrawer
        open={openInfoDrawer}
        onClose={() => setOpenInfoDrawer(false)}
        title={t("dashboard.progress.title", {
          childName: selectedChild ? childrenById[selectedChild].name : "",
        })}
        text={
          <Trans
            i18nKey={"dashboard.progress.info.text"}
            components={{ bold: <strong /> }}
          />
        }
      />
    </AccordionCard>
  );
};

export default ProgressAnalysis;
