import {
  ChallengeValidationAvailable,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import {
  Challenge,
  ChallengeV2,
  FirestoreChallenge,
  FirestoreCollection,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  Unsubscribe,
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import useAuth from "../auth/useAuth";
import { useChildrenContext } from "../children/ChildrenContext";
import {
  FeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import useParameters from "../Parameters/useParameters";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

export interface ChallengeContextData {
  challengeFeatureFlagActive: boolean;
  challenges: FirestoreChallenge[];
  setChallenges: Dispatch<SetStateAction<FirestoreChallenge[]>>;
  challengesForApproval: FirestoreChallenge[];
  setChallengesForApproval: Dispatch<SetStateAction<FirestoreChallenge[]>>;
  challengeReadyToValidateForSelectedChild: FirestoreChallenge[];
  deactivatedChallenges: string[];
  setDeactivatedChallenges: Dispatch<SetStateAction<string[]>>;
  totalChallengesToValidate: number;
  challengeLimitMet: boolean;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

const [useChallengeContext, ChallengeContextProvider] =
  Tools.Context.createGenericContext<ChallengeContextData>(__filename);

const ChallengeProvider = (props: PropsWithChildren): JSX.Element => {
  const { handleEvent } = useAnalytics();
  const { user, tenantId } = useAuth();
  const { selectedChild, childrenById, childIds } = useChildrenContext();
  const { version } = useParameters();
  const { checkFeatureFlag } = useRemoteConfig();

  const [challenges, setChallenges] = useState<FirestoreChallenge[]>([]);
  const [challengesForApproval, setChallengesForApproval] = useState<
    FirestoreChallenge[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [deactivatedChallenges, setDeactivatedChallenges] = useState<string[]>(
    []
  );

  const challengeLimitMet = useMemo(() => {
    return childIds.every((childId) =>
      challenges.some(
        (challenge) => challenge.childId === childId && !challenge.createdById
      )
    );
  }, [challenges, childIds]);

  const challengeFeatureFlagActive = useMemo(
    () => checkFeatureFlag(FeatureFlag.Challenges, version),
    [version]
  );

  const challengeReadyToValidateForSelectedChild =
    useMemo((): FirestoreChallenge[] => {
      if (!selectedChild) {
        return [];
      }

      const challengesToValidate: FirestoreChallenge[] = [];

      challenges
        .filter((challenge) => challenge.childId === selectedChild)
        .forEach((challenge) => {
          const isV2 = Tools.Data.Challenges.V2.isChallengeV2(challenge);

          const challengeChildIsDisabled =
            childrenById[challenge.childId]?.isDisabled === true;

          if (isV2) {
            const challengeV2 = challenge as ChallengeV2;

            const needsValidations =
              Tools.Data.Challenges.V2.challengeNeedsValidation(
                challengeV2,
                challengeChildIsDisabled
              );

            if (needsValidations) {
              // Do not generate challenges for this week, only past weeks.
              const currentChallengeWeek =
                Tools.Data.Challenges.V2.calculateChallengeWeekIndex(
                  challengeV2.startDate,
                  Tools.Time.Dates.getTimeStamp()
                );

              for (
                let weekIndex = 1;
                weekIndex < currentChallengeWeek &&
                weekIndex < challengeV2.duration + 1;
                weekIndex++
              ) {
                const missedHistoriesForWeek =
                  Tools.Data.Challenges.V2.generateMissedChallengeWeekValidations(
                    challengeV2,
                    weekIndex
                  );

                challenge.history.push(...missedHistoriesForWeek);
              }

              challengesToValidate.push(challenge);
            }
          } else {
            const challengeV1 = challenge as Challenge;

            const needsValidations =
              Tools.Data.Challenges.V1.challengeNeedsValidation(
                challengeV1,
                challengeChildIsDisabled
              );

            if (needsValidations) {
              const missedHistories =
                Tools.Data.Challenges.V1.generateMissedChallengeValidations(
                  challengeV1
                );

              challenge.history.push(...missedHistories);

              const challengeHasFullHistory =
                challenge.history.filter(
                  (history) => history.validationDate !== null
                ).length >= (challenge.occurrences ?? 1);

              if (!challengeHasFullHistory) {
                challengesToValidate.push(challenge);
              }
            }
          }
        });

      return challengesToValidate;
    }, [challenges, selectedChild]);

  const totalChallengesToValidate = useMemo((): number => {
    return challenges.filter((c) =>
      Tools.Data.Challenges.V2.isChallengeV2(c)
        ? Tools.Data.Challenges.V2.challengeNeedsValidation(
            c as ChallengeV2,
            childrenById[c.childId]?.isDisabled === true
          )
        : Tools.Data.Challenges.V1.challengeNeedsValidation(
            c as Challenge,
            childrenById[c.childId]?.isDisabled === true
          )
    ).length;
  }, [challenges]);

  useEffect(() => {
    if (totalChallengesToValidate > 0) {
      const event: ChallengeValidationAvailable = {
        name: "Challenge Validation Available",
        eventProperties: {
          "Number of validations": totalChallengesToValidate,
        },
      };

      handleEvent(event);
    }
  }, [totalChallengesToValidate]);

  useEffect(() => {
    let unsub: Unsubscribe | null = null;

    if (user && tenantId) {
      const getChallenges = async () => {
        // Check for expired challenges before getting.
        try {
          await FirebaseAPI.Challenge.terminateExpiredChallenges();
        } catch (err) {
          console.error(
            "Error in API call to terminate expired challenges.",
            err
          );
        }

        const db = getFirestore();

        const q = query(
          collection(db, FirestoreCollection.Challenges),
          where("userId", "==", user.uid),
          where("tenantId", "==", tenantId),
          where("deleted", "==", false),
          where("successful", "==", null)
        );

        unsub = onSnapshot(q, (snapshot) => {
          const docs = snapshot.docs.map((doc) =>
            Tools.Data.Challenges.V2.coalesceFirestoreChallengeToV2({
              ...doc.data(),
              id: doc.id,
            } as FirestoreChallenge)
          );

          const approved: FirestoreChallenge[] = [];
          const forApproval: FirestoreChallenge[] = [];

          docs.forEach((doc) => {
            if (doc.requiresApproval && doc.approvalStatus === null) {
              if (
                // Expired challenges created by a professional are not shown to user.
                !(doc.endDate && doc.endDate < Tools.Time.Dates.getTimeStamp())
              ) {
                forApproval.push(doc);
              }
            } else if (doc.approvalStatus !== false) {
              // Don't show expired challenges that have been done max times.
              if (
                !(
                  doc.endDate &&
                  doc.endDate < Tools.Time.Dates.getTimeStamp() &&
                  doc.history.filter(
                    (history) => history.validationDate !== null
                  ).length >= (doc.occurrences ?? 1)
                )
              ) {
                approved.push(doc);
              }
            }
          });

          setChallenges(approved);

          setChallengesForApproval(forApproval);
        });
      };

      getChallenges();
    }

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [user]);

  return (
    <ChallengeContextProvider
      value={{
        challengeFeatureFlagActive,
        challenges,
        setChallenges,
        challengesForApproval,
        setChallengesForApproval,
        challengeReadyToValidateForSelectedChild,
        deactivatedChallenges,
        setDeactivatedChallenges,
        totalChallengesToValidate,
        challengeLimitMet,
        loading,
        setLoading,
      }}
    >
      {props.children}
    </ChallengeContextProvider>
  );
};

export { useChallengeContext, ChallengeProvider };
