import { SxProps, Theme, Typography } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";
import React, { PropsWithChildren } from "react";

interface InputErrorProps extends PropsWithChildren, CypressProps {
  sx?: SxProps<Theme>;
}

const InputError = ({
  sx,
  children,
  ...props
}: InputErrorProps): JSX.Element => {
  return (
    <Typography
      color="error"
      fontSize="0.875rem"
      sx={sx}
      data-cy={props["data-cy"]}
    >
      {children}
    </Typography>
  );
};

export default InputError;
