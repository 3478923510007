import { Box, SxProps, Theme, Typography } from "@mui/material";
import {
  BackgroundOpacity,
  FTUECard,
  FTUECardCenterWrap,
} from "@neurosolutionsgroup/components";
import FTUEAssets from "assets/ftue";
import clsx from "clsx";
import useUserProfile from "common/hooks/account/useUserProfile";
import { Coach } from "common/hooks/messaging/Coach";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { PageTitle } from "../Headers";

export interface PageProps {
  header?: JSX.Element;
  color?: "primary" | "secondary" | "plain";
  withCoach?: boolean;
  hideBodyBackground?: boolean;
  sxBody?: SxProps<Theme>;
  ftue?: boolean;
}

const Page = forwardRef<
  HTMLDivElement,
  PageProps & React.HTMLProps<HTMLDivElement>
>(
  (
    {
      header,
      color = "primary",
      withCoach = false,
      hideBodyBackground = false,
      sxBody,
      ftue = false,
      ...props
    },
    ref
  ) => {
    const ROOT_CLASS = "web-view-page";

    const { t } = useTranslation();
    const {
      selectors: { userProfile },
    } = useUserProfile();

    return (
      <div
        {...props}
        className={clsx(ROOT_CLASS, ROOT_CLASS + `--${color}`, props.className)}
        ref={ref}
      >
        {withCoach && <Coach />}
        {header && <div className={ROOT_CLASS + "__header"}>{header}</div>}
        {!header && (
          <div className={ROOT_CLASS + "__header"}>
            <BackgroundOpacity active={ftue}>
              {ftue ? (
                <FTUECardCenterWrap>
                  <FTUECard
                    title={t(
                      userProfile?.firstName
                        ? "ftue.parentsection.titleName"
                        : "ftue.parentsection.title",
                      { userName: userProfile?.firstName }
                    )}
                    text={
                      <Typography textAlign="center">
                        {t("ftue.parentsection.text")}
                      </Typography>
                    }
                    imageSrc={FTUEAssets.ParentSectionFTUE}
                  />
                </FTUECardCenterWrap>
              ) : null}
              <PageTitle ftue={ftue} />
            </BackgroundOpacity>
          </div>
        )}
        <Box
          className={clsx(ROOT_CLASS + "__body", {
            [ROOT_CLASS + "__body--no-background"]: hideBodyBackground,
          })}
          sx={sxBody}
        >
          {!ftue ? props.children : null}
        </Box>
      </div>
    );
  }
);

export default Page;
