import {
  Box,
  ButtonBase,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import { GraphModels, GraphTools } from "@neurosolutionsgroup/graphs";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  LabelList,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

interface TaskAnalysisGraphProps {
  data: GraphModels.RoutineDatum[];
  onCategoryClick: (category: string) => void;
  fullscreen?: boolean;
}

const TaskAnalysisGraph: React.FC<TaskAnalysisGraphProps> = ({
  data,
  onCategoryClick,
  fullscreen = false,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const { breakpoints, palette } = useTheme();

  const smUp = useMediaQuery(breakpoints.up("sm"));

  return (
    <Box
      sx={
        fullscreen
          ? {
              height: "calc(100vw - 3em)",
              overflowY: "auto",
            }
          : undefined
      }
    >
      <Box
        height={(smUp ? 50 : 40) + (data.length > 0 ? 60 * data.length : 120)}
      >
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            margin={{
              top: 0,
              left: 10,
              right: 35,
              bottom: smUp ? 15 : 15,
            }}
            data={data}
            layout="vertical"
            barGap={2}
          >
            <CartesianGrid horizontal={false} stroke="#eee6dc" />
            <XAxis
              type="number"
              allowDecimals={false}
              tick={{ fill: palette.secondary.main }}
              tickLine={false}
              ticks={data.length === 0 ? [0, 5, 10] : undefined}
              tickMargin={0}
              stroke="#eee6dc"
              fontSize="1rem"
            >
              <Label
                value={t("dashboard.task.graph.xLabel").toString()}
                position="left"
                fill={palette.secondary.main}
                fontSize="0.83rem"
                width={smUp ? 140 : 90}
                style={{
                  textAlign: "left",
                  direction: "rtl",
                }}
                dy={smUp ? 0 : 15}
                dx={smUp ? -135 : -85}
              />
            </XAxis>
            <YAxis
              type="category"
              dataKey="category"
              tick={(props) =>
                data.length > 0 ? (
                  <foreignObject
                    x={0}
                    y={props.y - 30}
                    width={smUp ? 150 : 100}
                    height={60}
                  >
                    <Box
                      width={smUp ? "150px" : "100px"}
                      height="60px"
                      display="flex"
                      alignItems="center"
                      justifyContent="stretch"
                    >
                      <ButtonBase
                        onClick={() => {
                          const categoryId = Object.keys(
                            taskCategoryLoc[language]
                          ).find(
                            (k) =>
                              taskCategoryLoc[language][k] ===
                              props.payload.value
                          );

                          if (categoryId) {
                            onCategoryClick(categoryId);
                          }
                        }}
                        sx={(theme) => ({
                          height: "calc(100% - 16px)",
                          paddingX: "6px",
                          fontSize: "0.8rem",
                          borderRadius: "10px",
                          boxShadow: "0px 2px 8px 1px rgba(0, 0, 0, 0.25)",
                          flexGrow: 1,
                          marginX: "4px",
                          color: theme.palette.secondary.main,
                        })}
                      >
                        <Typography fontSize={smUp ? "0.8rem" : "0.6rem"}>
                          {props.payload.value}
                        </Typography>
                      </ButtonBase>
                    </Box>
                  </foreignObject>
                ) : (
                  <></>
                )
              }
              width={smUp ? 140 : 90}
              tickLine={false}
              tickMargin={0}
              stroke="#eee6dc"
              style={{
                borderBottom: "solid 1px black",
              }}
            />
            <Bar
              dataKey="notDone"
              fill="#EF4044"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              shape={(props: any) =>
                GraphTools.getRoutineBarSVG(
                  props.x,
                  props.y,
                  props.width,
                  props.height,
                  props.fill,
                  false,
                  false
                )
              }
            >
              <LabelList
                dataKey="notDone"
                content={(props) => (
                  <g>
                    {props.value !== 0 && (
                      <text
                        x={(props.x as number) + (props.width as number) + 5}
                        y={(props.y as number) + 16}
                        fill="#EF4044"
                        fontWeight="bold"
                        fontSize="12"
                      >
                        {props.value}
                      </text>
                    )}
                  </g>
                )}
              />
            </Bar>
            <Bar
              dataKey="done"
              fill="#A6DA2D"
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              shape={(props: any) =>
                GraphTools.getRoutineBarSVG(
                  props.x,
                  props.y,
                  props.width,
                  props.height,
                  props.fill,
                  true,
                  false
                )
              }
            >
              <LabelList
                dataKey="done"
                content={(props) => (
                  <g>
                    {props.value !== 0 && (
                      <text
                        x={(props.x as number) + (props.width as number) + 5}
                        y={(props.y as number) + 16}
                        fill="#A6DA2D"
                        fontWeight="bold"
                        fontSize="12"
                      >
                        {props.value}
                      </text>
                    )}
                  </g>
                )}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
};

export default TaskAnalysisGraph;
