import React from "react";
import { Box, ButtonBase } from "@mui/material";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { CypressProps } from "@neurosolutionsgroup/models";
import { PremiumLock } from "@neurosolutionsgroup/components";

interface TemplateChoiceProps extends CypressProps {
  text: string;
  imageSrc: string;
  onClick: VoidFunction;
  disabled?: boolean;
  onPremiumLockClick: VoidFunction;
  premiumLock?: boolean;
}

const TemplateMenuChoice: React.FC<TemplateChoiceProps> = ({
  text,
  imageSrc,
  onClick,
  disabled = false,
  onPremiumLockClick,
  premiumLock = false,
  ...props
}) => {
  return (
    <DrawerSection variant="blank">
      <Box m={1}>
        <PremiumLock
          active={premiumLock}
          onClick={onPremiumLockClick}
          variant="long"
          display="block"
          borderRadius="10px"
        >
          <Box width="100%" height="100%" display="flex">
            <ButtonBase
              data-cy={props["data-cy"]}
              sx={(theme) => ({
                flexGrow: 1,
                border: "3px solid #f5efe7",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingX: theme.spacing(2),
                paddingY: theme.spacing(0.5),
                boxShadow: "-2px 2px 13px rgba(196, 196, 196, 0.15)",
                opacity: disabled ? 0.5 : 1,
              })}
              onClick={onClick}
              disabled={disabled}
            >
              <Box
                height="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{
                  img: {
                    // Must set image height or safari tries to render whole image width.
                    height: "9vh",
                    objectFit: "cover",
                  },
                }}
              >
                <img src={imageSrc} alt="" />
              </Box>
              <h3 className="routine-template-selection__choice-text">
                {text}
              </h3>
            </ButtonBase>
          </Box>
        </PremiumLock>
      </Box>
    </DrawerSection>
  );
};

export default TemplateMenuChoice;
