import ObservationDisplay from "./ObservationDisplay";
import ObservationFrequency from "./ObservationFrequency";
import ObservationIntensity from "./ObservationIntensity";
import ObservationNav from "./ObservationNav";

const ObservationFTUEHints = {
  ObservationDisplay,
  ObservationFrequency,
  ObservationIntensity,
  ObservationNav,
};

export default ObservationFTUEHints;
