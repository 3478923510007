import { ChallengeHistory, ChallengeV2 } from "@neurosolutionsgroup/models";
import Objects from "../../../Objects";

type GroupKey = "done" | "notDone" | "notDoneRetry" | "unvalidated";

const generateMissedChallengeWeekValidations = (
  challenge: ChallengeV2,
  weekIndex: number
): ChallengeHistory[] => {
  const weekHistories = challenge.history.filter(
    (history) => history.week === weekIndex
  );

  const groupedHistory = Objects.groupBy<ChallengeHistory, GroupKey>(
    weekHistories,
    (history) => {
      if (history.parentStatus === true) {
        return "done";
      } else if (history.parentStatus === false) {
        if (history.canBeRetried) {
          return "notDoneRetry";
        } else {
          return "notDone";
        }
      } else {
        return "unvalidated";
      }
    }
  );

  const doneHistoryCount = groupedHistory.done?.length ?? 0;
  const notDoneHistoryCount = groupedHistory.notDone?.length ?? 0;
  const unvalidatedHistoryCount = groupedHistory.unvalidated?.length ?? 0;

  const historiesThatCountTowardsFrequency =
    doneHistoryCount + unvalidatedHistoryCount + notDoneHistoryCount;

  const missingHistoryCount =
    challenge.frequency - historiesThatCountTowardsFrequency;

  // If enough potential validations to complete challenge week, no validations necessary.
  if (missingHistoryCount <= 0) {
    return [];
  }

  const missingHistory: ChallengeHistory = {
    childExecutionDate: null,
    childStatus: null,
    parentStatus: null,
    validationDate: null,
    day: null,
    week: weekIndex,
    canBeRetried: null,
  };

  return Array(missingHistoryCount).fill(missingHistory);
};

export default generateMissedChallengeWeekValidations;
