import { Box, Button, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChallengeValidationWeekComponent from "./ChallengeValidationWeekComponent";
import { useEffect, useState } from "react";
import { ChallengeValidationWeek } from "@neurosolutionsgroup/models";
interface ChallengeValidationBodyProps {
  onValidation: (validations: ChallengeValidationWeek[]) => Promise<void>;
  initialValidations: ChallengeValidationWeek[];
  weekIndexToString: (weekIndex: number) => string;
  weekDayToString: (dayIndex: number | null) => string;
}

const ChallengeValidationBody = ({
  onValidation,
  initialValidations,
  weekIndexToString,
  weekDayToString,
}: ChallengeValidationBodyProps): JSX.Element => {
  const { t } = useTranslation();

  const [validationState, setValidationState] = useState<
    ChallengeValidationWeek[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Prefill validation that were done by the child.
    const updatedInitialValidations = initialValidations.map((week) => {
      return {
        ...week,
        validations: week.validations.map((validation) => {
          if (
            validation.parentStatus !== null ||
            validation.childStatus === null
          ) {
            return validation;
          } else {
            return {
              ...validation,
              parentStatus: validation.childStatus,
            };
          }
        }),
      };
    });

    setValidationState(updatedInitialValidations);
  }, [initialValidations]);

  const onValidationStateChange = (
    weekIndex: number,
    validationIndex: number,
    value: boolean
  ) => {
    setValidationState((current) => {
      const clone = [...current];

      clone[weekIndex].validations[validationIndex] = {
        ...clone[weekIndex].validations[validationIndex],
        parentStatus: value,
      };

      return clone;
    });
  };

  const onValidationClick = () => {
    setLoading(true);

    onValidation(validationState).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Box>
      {validationState.map((validationWeek, i) => (
        <Box mb={2} key={i}>
          <ChallengeValidationWeekComponent
            validationWeek={validationWeek}
            onChange={(validationIndex, value) =>
              onValidationStateChange(i, validationIndex, value)
            }
            weekString={weekIndexToString(validationWeek.weekIndex)}
            weekDayToString={weekDayToString}
          />
        </Box>
      ))}
      <Box display="flex" justifyContent="end">
        <Button
          onClick={onValidationClick}
          variant="contained"
          color="secondary"
          disabled={loading}
          data-cy="challenge-validation-action"
        >
          {loading ? (
            <CircularProgress size="1.5rem" color="secondary" />
          ) : (
            t("general.actions.validate")
          )}
        </Button>
      </Box>
    </Box>
  );
};

export default ChallengeValidationBody;
