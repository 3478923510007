import ActivityBookPDFEN from "./activity-book-en.pdf";
import ActivityBookEN from "./activity-book-en.png";
import ActivityBookPDFFR from "./activity-book-fr.pdf";
import ActivityBookFR from "./activity-book-fr.png";
import BehaviorChartEN from "./behavior-chart-en.png";
import BehaviorChartPDFEN from "./behavior-chart-en.pdf";
import BehaviorChartFR from "./behavior-chart-fr.png";
import BehaviorChartPDFFR from "./behavior-chart-fr.pdf";
import EmotionWheelEN from "./emotion-wheel-en.png";
import EmotionWheelPDFEN from "./emotion-wheel-en.pdf";
import EmotionWheelFR from "./emotion-wheel-fr.png";
import EmotionWheelPDFFR from "./emotion-wheel-fr.pdf";
import RewardChartEN from "./reward-chart-en.png";
import RewardChartPDFEN from "./reward-chart-en.pdf";
import RewardChartFR from "./reward-chart-fr.png";
import RewardChartPDFFR from "./reward-chart-fr.pdf";

const PrintableToolAssets = {
  images: {
    ActivityBookEN,
    ActivityBookFR,
    BehaviorChartEN,
    BehaviorChartFR,
    EmotionWheelEN,
    EmotionWheelFR,
    RewardChartEN,
    RewardChartFR,
  },
  pdfs: {
    ActivityBookPDFEN,
    ActivityBookPDFFR,
    BehaviorChartPDFEN,
    BehaviorChartPDFFR,
    EmotionWheelPDFEN,
    EmotionWheelPDFFR,
    RewardChartPDFEN,
    RewardChartPDFFR,
  },
};

export default PrintableToolAssets;
