import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const SaveIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="save" {...props}>
      <path
        d="M 19.488889,2.4777778 17.944395,0.61111111 C 17.644444,0.23333333 17.18884,0 16.666667,0 H 3.3333333 C 2.8111111,0 2.3555556,0.23333333 2.0444444,0.61111111 L 0.51111111,2.4777778 C 0.18888889,2.8555556 0,3.3555556 0,3.8888889 V 17.777778 C 0,19 1,20 2.2222222,20 H 17.777778 C 19,20 20,19 20,17.777778 V 3.8888889 C 20,3.3555556 19.81116,2.8555556 19.488889,2.4777778 Z M 3.6,2.2222222 H 16.4 L 17.300049,3.3 H 2.7111111 Z M 2.2222222,17.777778 V 5.5555556 H 17.777778 V 17.777778 Z M 11.611062,7.7777778 H 8.3888889 V 11.111111 H 5.5555556 L 10,15.555556 14.444444,11.111111 h -2.833382 z"
        fill={color}
      />
    </BaseIcon>
  );
};

export default SaveIcon;
