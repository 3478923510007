import { Box } from "@mui/material";
import React, { PropsWithChildren } from "react";

interface GraphContainerProps extends PropsWithChildren {
  dataLength: number;
  dataWidth?: string;
  marginBottom?: number;
  height?: string;
  showLeftBorder?: boolean;
}

const GraphContainer = ({
  dataLength,
  dataWidth = "50px",
  marginBottom = 10,
  children,
  height = "16em",
  showLeftBorder = true,
}: GraphContainerProps): JSX.Element => {
  return (
    <>
      {showLeftBorder ? (
        <Box
          sx={{
            position: "absolute",
            background: "rgb(238, 230, 220)",
            width: "1px",
            height: `calc(${height} - ${40 + marginBottom}px)`,
            left: 0,
            top: "10px",
          }}
        />
      ) : null}
      <Box
        sx={{
          "position": "relative",
          "flexGrow": 1,
          "overflowX": "auto",
          "direction": "ltr",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        mb={0.5}
      >
        <Box
          height={height}
          sx={{
            overflow: "hidden",
            width: `calc(${dataWidth} * ${dataLength})`,
            minWidth: "100%",
            direction: "ltr",
          }}
        >
          {children}
        </Box>
      </Box>
    </>
  );
};

export default GraphContainer;
