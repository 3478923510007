import { ApiError, isApiError } from "@neurosolutionsgroup/models";
import axios from "axios";
import { FirebaseError } from "firebase/app";

export interface ErrorDefinition {
  userMessageKey: string;
  code: string;
  errorDetails?: unknown;
  action?: {
    actionFunction: VoidFunction;
    actionTextKey: string;
  };
}

const knownErrorCodes = [40900];

const buildErrorDefinitionFromApiError = (
  apiError: ApiError
): ErrorDefinition => {
  if (
    apiError.context.errorCode &&
    knownErrorCodes.includes(apiError.context.errorCode)
  ) {
    return {
      userMessageKey: `navigation.error.dialog.code.${apiError.context.errorCode}`,
      code: apiError.context.errorCode.toFixed(0),
      errorDetails: apiError,
    };
  }

  return {
    userMessageKey: "navigation.error.dialog.code.500",
    code: apiError.code.toFixed(0),
    errorDetails: apiError,
  };
};

export const buildErrorFromRequestError = (err: unknown): ErrorDefinition => {
  if (isApiError(err)) {
    return buildErrorDefinitionFromApiError(err);
  } else if (axios.isAxiosError(err)) {
    if (err.response && isApiError(err.response?.data)) {
      return buildErrorDefinitionFromApiError(err.response.data);
    }

    return {
      userMessageKey: "navigation.error.dialog.code.500",
      code: err.code ?? "500",
      errorDetails: err,
    };
  } else {
    return {
      userMessageKey: "navigation.error.dialog.code.500",
      code: "500",
      errorDetails: err,
    };
  }
};

const knownFirebaseErrorCodes: string[] = [
  "auth/user-not-found",
  "auth/wrong-password",
  "auth/email-already-in-use",
];

export const buildErrorFromFirebaseError = (err: unknown): ErrorDefinition => {
  if (err instanceof FirebaseError) {
    if (knownFirebaseErrorCodes.includes(err.code)) {
      return {
        userMessageKey: `navigation.error.dialog.code.${err.code}`,
        code: "400",
        errorDetails: err,
      };
    } else {
      return {
        userMessageKey: "navigation.error.dialog.code.500",
        code: "500",
        errorDetails: err,
      };
    }
  } else {
    return {
      userMessageKey: "navigation.error.dialog.code.500",
      code: "500",
      errorDetails: err,
    };
  }
};
