import {
  NotificationCategorySettings,
  NotificationsSettings,
} from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import NotificationCategorySettingsSetterService from "./NotificationSettingsSetter";

const updateSettingsFirebase = async (
  globalOptIn: boolean,
  categories: NotificationCategorySettings
): Promise<NotificationsSettings> => {
  return FirebaseAPI.Account.updateNotificationSettings({
    globalOptIn,
    categories,
  });
};

const FirebaseSettingsSetterAdapter: NotificationCategorySettingsSetterService =
  {
    updateSettings: updateSettingsFirebase,
  };

export default FirebaseSettingsSetterAdapter;
