import { PropsWithChildren, useLayoutEffect, useRef } from "react";
import { Box, Button, ButtonBase, Typography } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { useTranslation } from "react-i18next";
import FTUEProgress from "./FTUEProgress";
import { FTUEHintProps } from "./FTUEHint";

interface FTUEHintComponentProps extends PropsWithChildren, FTUEHintProps {
  blockFTUE: boolean;
}

/**
 * A generic FTUE hint that highlights it's child component when active. Displays child as normal when inactive.
 */
const FTUEHintComponent = ({
  hint,
  currentStep,
  maxStep,
  condition,
  nextDisabled,
  onNext,
  nextText,
  onBack,
  backText,
  onClick,
  quit,
  quitText,
  scrollPosition = "center",
  floating = false,
  scroll = true,
  centered = false,
  backgroundOverlay = true,
  overrideHeight,
  hintAfter,
  hintMarginTop,
  hintMarginX = false,
  safeAreas,
  dataCy,
  blockFTUE,
  ...props
}: FTUEHintComponentProps): JSX.Element => {
  const { t } = useTranslation();

  const componentRef = useRef<HTMLDivElement>(null);

  // Scroll to component when hint active.
  useLayoutEffect(() => {
    if (condition && scroll) {
      // Use short timeout to handle any UI changes.
      setTimeout(() => {
        componentRef.current?.scrollIntoView({
          behavior: "smooth",
          block: scrollPosition,
          inline: "nearest",
        } as ScrollIntoViewOptions);
      }, 200);
    }
  }, [condition, scroll]);

  return (
    <>
      {condition && !blockFTUE ? (
        <>
          <Box
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 10002,
              marginTop: safeAreas?.top,
            }}
            pt={0.5}
          >
            {quit ? (
              <Box
                p={1}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonBase
                  onClick={quit}
                  sx={{
                    padding: "0.5em",
                    borderRadius: "10px",
                    svg: {
                      width: "1.5em",
                      height: "1.5em",
                    },
                  }}
                  data-cy="ftue-hint-quit"
                >
                  {quitText ? (
                    <Typography color="#fff">{quitText}</Typography>
                  ) : (
                    <Icons.CloseIcon color="#fff" />
                  )}
                </ButtonBase>
              </Box>
            ) : null}
            {currentStep !== undefined && maxStep ? (
              <Box p={1}>
                <FTUEProgress currentStep={currentStep} maxStep={maxStep} />
              </Box>
            ) : null}
          </Box>
          <Box
            sx={{
              position: "relative",
              zIndex: 10001,
              height: overrideHeight ? overrideHeight : "auto",
            }}
            onClick={onClick}
          >
            {hint ? (
              <Box
                my={1}
                mx={hintMarginX ? 1 : 0}
                marginTop={hintMarginTop}
                className="ftue__hint"
                ref={componentRef}
                sx={
                  floating
                    ? {
                        position: "absolute",
                        left: 0,
                        right: 0,
                        top: hintAfter ? "100%" : "auto",
                        bottom: hintAfter ? "auto" : "100%",
                      }
                    : {}
                }
                data-cy={dataCy}
              >
                <Typography
                  color="#fff"
                  fontWeight="bold"
                  sx={{
                    textAlign: centered ? "center" : "inherit",
                  }}
                >
                  {hint}
                </Typography>
              </Box>
            ) : null}
            {props.children}
          </Box>
          {onNext || onBack ? (
            <Box
              p={1}
              sx={(theme) => ({
                position: "fixed",
                bottom: 0,
                left: 0,
                right: 0,
                display: "flex",
                justifyContent: "center",
                backgroundColor: theme.palette.primary.main,
                borderTopLeftRadius: "15px",
                borderTopRightRadius: "15px",
                paddingBottom: `calc(${theme.spacing(1)} + ${
                  safeAreas?.bottom ?? "0px"
                })`,
                zIndex: 10010,
              })}
            >
              {onBack && (
                <Box pr={onNext ? 1 : 0}>
                  <Button onClick={onBack} color="secondary">
                    {backText ?? t("general.actions.previous")}
                  </Button>
                </Box>
              )}
              {onNext && (
                <Box pl={onBack ? 1 : 0}>
                  <Button
                    data-cy="next-button-ftue"
                    onClick={onNext}
                    color="secondary"
                    disabled={nextDisabled}
                  >
                    {nextText ?? t("general.actions.next")}
                  </Button>
                </Box>
              )}
            </Box>
          ) : null}
          {backgroundOverlay ? (
            <Box
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                opacity: 0.7,
                backgroundColor: "#000",
                zIndex: 10000,
              }}
            />
          ) : null}
        </>
      ) : (
        props.children
      )}
    </>
  );
};

export default FTUEHintComponent;
