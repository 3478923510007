import { Box, Button } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";

interface MainPageButtonProps extends CypressProps {
  content: JSX.Element | string;
  imgSrc: string;
  onClick: VoidFunction;
}

const MainPageButton = ({
  content,
  imgSrc,
  onClick,
  ...props
}: MainPageButtonProps): JSX.Element => {
  return (
    <Button
      onClick={onClick}
      color="secondary"
      sx={{
        "width": "70vw",
        "height": "3rem",
        "img": {
          height: "2.5rem",
          width: "2.5rem",
          marginRight: 1,
          objectFit: "contain",
        },
        ".icon-spacer": {
          minWidth: "2.5rem",
          marginLeft: 1,
        },
        "display": "flex",
        "justifyContent": "center",
        "alignItems": "center",
        "paddingX": 3,
      }}
      data-cy={props["data-cy"]}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "350px",
        }}
      >
        <img src={imgSrc} alt=""></img>
        {content}
        <div className="icon-spacer" />
      </Box>
    </Button>
  );
};

export default MainPageButton;
