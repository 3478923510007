import { accordionSummaryClasses } from "@mui/material";
import { ExpandableCard } from "@neurosolutionsgroup/components";
import {
  ChallengeV2,
  ChildIconsDefintion,
  CypressProps,
  GamerChild,
  Language,
} from "@neurosolutionsgroup/models";
import { useEffect, useState } from "react";
import ChallengeDisplayCardSummary from "./ChallengeDisplayCardSummary";
import ChallengeDisplayCardDetails from "./ChallengeDisplayCardDetails";
import ChallengeRejectionDialog from "./ChallengeRejectionDialog";
import { IconManager, Tools } from "@neurosolutionsgroup/tools";

interface ChallengeDisplayCardProps extends CypressProps {
  challenge: ChallengeV2;
  childIcons: ChildIconsDefintion;
  childList: GamerChild[];
  dateLocale: Locale;
  language: Language;
  showDebug: boolean;
  onApproval: (approved: boolean, comment?: string) => void;
  onDelete: VoidFunction;
  onValidate: (done: boolean) => void;
  iconManager: IconManager;
}

const ChallengeDisplayCard = ({
  challenge,
  childIcons,
  childList,
  dateLocale,
  language,
  showDebug,
  onApproval,
  onDelete,
  onValidate,
  iconManager,
  ...props
}: ChallengeDisplayCardProps): JSX.Element => {
  const [open, setOpen] = useState(false);
  const [challengeRejectionDialogOpen, setChallengeRejectionDialogOpen] =
    useState(false);

  const currentChallengeWeek = challenge
    ? Tools.Data.Challenges.V2.calculateChallengeWeekIndex(
        challenge.startDate,
        Tools.Time.Dates.getTimeStamp()
      )
    : 0;

  const challengeEnded = challenge.successful !== null;

  const toApprove =
    !!challenge.requiresApproval &&
    challenge.approvalStatus !== false &&
    challenge.approvalStatus !== true;

  useEffect(() => {
    if (toApprove) {
      setOpen(true);
    }
  }, [toApprove]);

  const onApprovalClicked = (approved: boolean) => {
    if (approved) {
      onApproval(true);
    } else {
      setChallengeRejectionDialogOpen(true);
    }
  };

  const onRejectionCommentComplete = (comment: string) => {
    onApproval(false, comment ?? undefined);
    setChallengeRejectionDialogOpen(false);
  };

  return (
    <>
      <ExpandableCard
        open={open}
        setOpen={setOpen}
        summaryComponent={
          <ChallengeDisplayCardSummary
            open={open}
            challenge={challenge}
            childIcons={childIcons}
            childList={childList}
            currentChallengeWeek={currentChallengeWeek}
            dateLocale={dateLocale}
            iconManager={iconManager}
            toApprove={toApprove}
          />
        }
        detailsComponent={
          <ChallengeDisplayCardDetails
            challenge={challenge}
            challengeEnded={challengeEnded}
            showDebug={showDebug}
            dateLocale={dateLocale}
            language={language}
            onApproval={onApprovalClicked}
            onDelete={onDelete}
            onValidate={onValidate}
            toApprove={toApprove}
          />
        }
        hideExpandIcon
        sx={{
          [`& .${accordionSummaryClasses.root}.${accordionSummaryClasses.expanded}`]:
            {
              paddingY: 0,
              boxShadow: "none",
              [`& .${accordionSummaryClasses.content}`]: {
                marginTop: 2,
                marginBottom: 0,
              },
            },
        }}
        data-cy={props["data-cy"] ?? "challenge-display-card"}
      />

      <ChallengeRejectionDialog
        open={challengeRejectionDialogOpen}
        onClose={() => setChallengeRejectionDialogOpen(false)}
        onRejectionCommentComplete={onRejectionCommentComplete}
      />
    </>
  );
};

export default ChallengeDisplayCard;
