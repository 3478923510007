import React, {
  SetStateAction,
  Dispatch,
  useState,
  useEffect,
  PropsWithChildren,
} from "react";
import { SideEffectById } from "@neurosolutionsgroup/models";
import SideEffectQueries from "common/Services/SideEffect/SideEffectQueries";
import { Tools } from "@neurosolutionsgroup/tools";

interface SideEffectData {
  sideEffectIds: string[] | undefined;
  sideEffectById: SideEffectById | undefined;
  setSideEffectIds: Dispatch<SetStateAction<string[] | undefined>>;
  setSideEffectById: Dispatch<SetStateAction<SideEffectById | undefined>>;
  resetSideEffects: () => void;
}

const [useSideEffectContext, SideEffectContextProvider] =
  Tools.Context.createGenericContext<SideEffectData>(__filename);

const SideEffectProvider: React.FC<PropsWithChildren> = (props) => {
  const [sideEffectIds, setSideEffectIds] = useState<string[] | undefined>(
    undefined
  );
  const [sideEffectById, setSideEffectById] = useState<
    SideEffectById | undefined
  >(undefined);

  useEffect(() => {
    SideEffectQueries.getAll().then((sideEffects) => {
      setSideEffectById(sideEffects);
      setSideEffectIds(
        Object.entries(sideEffects).map(([key]) => {
          return key;
        })
      );
    });
  }, []);

  const resetSideEffects = () => {
    setSideEffectIds(undefined);
    setSideEffectById(undefined);
  };

  return (
    <SideEffectContextProvider
      value={{
        sideEffectIds,
        sideEffectById,
        setSideEffectIds,
        setSideEffectById,
        resetSideEffects,
      }}
    >
      {props.children}
    </SideEffectContextProvider>
  );
};

export { useSideEffectContext, SideEffectProvider };
