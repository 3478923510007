import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

interface BackgroundOpacityProps extends PropsWithChildren {
  active?: boolean;
  sx?: SxProps<Theme>;
}

const BackgroundOpacity = ({
  active = true,
  sx,
  ...props
}: BackgroundOpacityProps): JSX.Element => {
  return active ? (
    <>
      <Box
        sx={[
          {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 10000,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      ></Box>
      <Box
        sx={{
          position: "relative",
          zIndex: 10002,
        }}
      >
        {props.children}
      </Box>
    </>
  ) : (
    <>{props.children}</>
  );
};

export default BackgroundOpacity;
