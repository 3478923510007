import React, { useState } from "react";
import {
  BounceEffect,
  ChildSelector,
  IconButton,
  Icons,
} from "@neurosolutionsgroup/components";
import useChildren from "common/hooks/children/useChildren";
import ChildIcons from "assets/child-icons";
import { SAFE_AREAS } from "stylesheets";
import {
  ConfigNumber,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import { Box } from "@mui/material";
import ChildCreation from "pages/Settings/Child/ChildCreation";
import {
  ChildAccountCreationStarted,
  DefaultChildEditionStarted,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import useSubscription from "common/hooks/subscription/useSubscription";
import DefaultChildEditDialog from "common/Components/Dialogs/DefaultChildEditDialog";

interface PageTitleProps {
  ftue?: boolean;
}

const PageTitle = ({ ftue = false }: PageTitleProps): JSX.Element => {
  const { handleEvent } = useAnalytics();
  const {
    selectors: { childrenById, selectedChild },
    actions: { setSelectedChild },
  } = useChildren();
  const { getNumberConfig } = useRemoteConfig();
  const { onPremiumFeatureClick, permissions } = useSubscription();

  const [openChildCreation, setOpenChildCreation] = useState(false);
  const [defaultChildWarningDialog, setDefaultChildWarningDialog] =
    useState<boolean>(false);

  const onChildCreate = () => {
    if (selectedChild && childrenById[selectedChild].isDefault) {
      setDefaultChildWarningDialog(true);
    } else {
      setOpenChildCreation(true);

      const event: ChildAccountCreationStarted = {
        name: "Kid Account Creation Started",
      };

      handleEvent(event);
    }
  };

  const hasDefaultChild =
    selectedChild && childrenById[selectedChild]?.isDefault;

  const onEditDefaultChildClicked = () => {
    setOpenChildCreation(true);

    const event: DefaultChildEditionStarted = {
      name: "Default Child Edition Started",
    };

    handleEvent(event);
  };

  const renderEditDefaultChild = () => {
    return hasDefaultChild ? (
      <IconButton
        onClick={onEditDefaultChildClicked}
        iconSize="1.25rem"
        variant="solid"
        bouncing={true}
        children={<Icons.EditIcon color="#31737c" />}
        sx={{ marginLeft: 1 }}
        data-cy="edit-default_child-button"
      />
    ) : undefined;
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <BounceEffect active={ftue}>
        <ChildSelector
          childList={Object.values(childrenById)}
          childIcons={ChildIcons}
          selectedChildId={selectedChild}
          onChildCreate={() => {
            onChildCreate();
          }}
          onChildSelected={(childId) => setSelectedChild(childId)}
          maxChildren={getNumberConfig(ConfigNumber.ChildLimit)}
          premiumLockCreate={!permissions.childCreation}
          onPremiumCreateLockClick={() =>
            onPremiumFeatureClick(PremiumFeature.Child)
          }
          premiumLockDisabledChildren={!permissions.childCreation}
          onPremiumLockedChildClick={() =>
            onPremiumFeatureClick(PremiumFeature.LockedChild)
          }
          safeAreas={SAFE_AREAS}
        />
      </BounceEffect>
      <ChildCreation
        open={openChildCreation}
        onClose={() => setOpenChildCreation(false)}
        onBack={() => setOpenChildCreation(false)}
        setSelectedChildOnComplete
        placeholderName={
          selectedChild && childrenById[selectedChild].isDefault
            ? childrenById[selectedChild]?.name
            : undefined
        }
        codeStyle={
          selectedChild && childrenById[selectedChild].isDefault ? 0 : undefined
        }
      />
      <DefaultChildEditDialog
        onCancel={() => {
          setDefaultChildWarningDialog(false);
        }}
        onEdit={() => {
          setDefaultChildWarningDialog(false);
          setOpenChildCreation(true);
        }}
        open={defaultChildWarningDialog}
      />
      {renderEditDefaultChild()}
    </Box>
  );
};

export default PageTitle;
