import { Box, Typography } from "@mui/material";

interface TextDividerProps {
  text: string;
}

const TextDivider = ({ text }: TextDividerProps): JSX.Element => {
  return (
    <Box
      sx={{
        width: "40%",
        display: "flex",
        justifyContent: "center",
        overflowX: "hidden",
      }}
    >
      <Typography
        className="signup__divider--text"
        fontWeight="bold"
        fontSize="0.8rem"
        sx={{
          "display": "inline-block",
          "position": "relative",
          "&::before, &::after": {
            content: '""',
            position: "absolute",
            top: "50%",
            width: "9999px",
            height: "1px",
            background: "#31737c",
          },
          "&::before": {
            right: "100%",
            marginRight: "15px",
          },
          "&::after": {
            left: "100%",
            marginLeft: "15px",
          },
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default TextDivider;
