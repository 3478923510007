import { Box } from "@mui/material";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { FirstRoutineProgress } from "..";
import useFTUE from "useFTUE";

export interface TaskCreationProps extends PropsWithChildren {
  onComplete: VoidFunction;
  onQuit: VoidFunction;
}

const TaskCreation: React.FC<TaskCreationProps> = ({
  onComplete,
  onQuit,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    selectors: { progress },
    actions: { canDisplayFlow, setFirstRoutineProgress },
  } = useFTUE();

  const firstRoutineProgress = progress[FTUEFlow.FirstRoutine].progress;

  const onNext = () => {
    onComplete();
    setFirstRoutineProgress(FirstRoutineProgress.RoutineComplete);
  };

  return (
    <FTUEHint
      hint=""
      condition={
        firstRoutineProgress === FirstRoutineProgress.TaskIcon &&
        canDisplayFlow(FTUEFlow.FirstRoutine)
      }
      onNext={onNext}
      quit={() => {
        onQuit();
        setFirstRoutineProgress(FirstRoutineProgress.TaskComplete);
      }}
    >
      {firstRoutineProgress === FirstRoutineProgress.TaskIcon ? (
        <Box
          sx={{
            "& .icon-picker__label": {
              color: "#fff",
            },
            "h2": {
              color: "#fff",
            },
            "& .task-edition__category": {
              color: "#fff",
            },
            "& .icon-information": {
              path: {
                fill: "#fff !important",
              },
            },
            "& .icon-picker": {
              "&:after": {
                color: "#fff",
                fontWeight: "bold",
                fontSize: "1rem",
                textAlign: "left",
                marginTop: "1em",
                marginBottom: "1em",
                content: `"${t("ftue.firstRoutine.taskCreation.hint")}"`,
              },
            },
          }}
        >
          {props.children}
        </Box>
      ) : (
        props.children
      )}
    </FTUEHint>
  );
};

export default TaskCreation;
