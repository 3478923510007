export enum Currency {
  CAD = "cad",
  USD = "usd",
  EUR = "eur",
}

export type LocalizationInfo = {
  currency: Currency;
  country: string;
  countryCode: string;
  continent: string;
  region: string;
  ipAddress: string;
};
