import {
  NotificationCategorySettings,
  NotificationsSettings,
} from "@neurosolutionsgroup/models";
import axios from "axios";
import { getHeaders, getURL, getUserId, handleApiError } from "./utils";
import { Tools } from "@neurosolutionsgroup/tools";

const getSettings = async (): Promise<NotificationsSettings> => {
  const URL = getURL() + "/analytics/customerio/notification-settings";

  const headers = await getHeaders(true, "CustomerIo.getSettings");

  try {
    const result = await axios.get<NotificationsSettings>(URL, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const updateSettings = async (
  globalOptIn: boolean,
  categories: NotificationCategorySettings
): Promise<NotificationsSettings> => {
  const userId = await getUserId();

  const URL = getURL() + "/analytics/customerio/identify";

  try {
    const result = await axios.post(URL, {
      userId,
      userProperties: { "notif-global": globalOptIn, ...categories },
    });
    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const setOptInLastSeen = async (): Promise<void> => {
  const userId = await getUserId();

  const URL = getURL() + "/analytics/customerio/identify";

  const body = {
    userId,
    userProperties: { "notif-optin-lastseen": Tools.Time.Dates.getTimeStamp() },
  };

  try {
    const result = await axios.post(URL, body);
    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const Analytics = {
  CustomerIO: {
    getSettings,
    updateSettings,
    setOptInLastSeen,
  },
};

export default Analytics;
