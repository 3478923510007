import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

export interface PageFooterProps extends PropsWithChildren {
  sx?: SxProps<Theme>;
}

const PageFooter = ({ sx, ...props }: PageFooterProps): JSX.Element => {
  return (
    <Box
      sx={[
        {
          marginTop: "auto",
          boxSizing: "border-box",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {props.children}
    </Box>
  );
};

export default PageFooter;
