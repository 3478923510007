import React, { SetStateAction, useCallback, useState } from "react";
import clsx from "clsx";
import { Drawer } from "common/Components";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { Box, ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import DynamismAssets from "assets/dynamism";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { useTranslation } from "react-i18next";
import {
  autonomyIconsOrder,
  familyIconsOrder,
  healthIconsOrder,
  householdIconsOrder,
  hygieneIconsOrder,
  instructionsIconsOrder,
  tidyingIconsOrder,
} from "./IconOrders";
import TaskAssets from "assets/task-icons";
import { Icons, PremiumLock } from "@neurosolutionsgroup/components";
import useSubscription from "common/hooks/subscription/useSubscription";
import { freeIcons } from "common/hooks/subscription/SubscriptionContext";
import { PremiumFeature } from "@neurosolutionsgroup/analytics";

interface IconPickerProps {
  icon: number;
  setIcon: React.Dispatch<SetStateAction<number>>;
  showEmptyState?: boolean;
}

const IconPicker: React.FC<IconPickerProps> = ({
  icon,
  setIcon,
  showEmptyState = true,
}) => {
  const rootClass = "icon-picker";

  const { t } = useTranslation();
  const { palette } = useTheme();

  const { onPremiumFeatureClick, permissions } = useSubscription();

  const numberOfIcons = 118;

  const [showIconDrawer, setShowIconDrawer] = useState(false);

  const canShowIcon = (icon: number): boolean => {
    if (permissions.allIcons) {
      return true;
    } else {
      return freeIcons.includes(icon);
    }
  };

  const onIconClick = () => {
    setShowIconDrawer(true);
  };

  const onIconChoiceClicked = (icon: number) => {
    setShowIconDrawer(false);
    setIcon(icon);
  };

  const onPremiumIconClick = (icon: number) => {
    setShowIconDrawer(false);
    onPremiumFeatureClick(PremiumFeature.TaskIcon, { iconId: icon });
  };

  /**
   * Generate array of icon IDs ordered by category of first title associated.
   */
  const getIcons = useCallback((): number[] => {
    return autonomyIconsOrder.concat(
      hygieneIconsOrder,
      instructionsIconsOrder,
      householdIconsOrder,
      tidyingIconsOrder,
      healthIconsOrder,
      familyIconsOrder
    );
  }, []);

  // Calculate number of "filler" icons to add at the end of the icons grid.
  // This will break if the number of icons per row ever changes.
  // For now icons scale with screen size so always 5.
  const getFillerIcons = useCallback((): number[] => {
    return Array.from(Array(5 - (numberOfIcons % 5)).keys());
  }, [numberOfIcons]);

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className={clsx(rootClass)}
      >
        <Typography
          fontSize="0.8rem"
          textAlign="center"
          className={rootClass + "__label"}
        >
          {t(`routine.task.inputs.icon.label.${icon ? "modify" : "choose"}`)}
        </Typography>
        <ButtonBase
          data-cy="add-icon-button"
          onClick={onIconClick}
          sx={(theme) => ({
            ...SharedStyles.Layout.square("8em"),
            "borderRadius": "1em",
            "zIndex": 0,
            ...SharedStyles.Layout.flexCenter,
            "& .icon-picker__add-container": {
              ...SharedStyles.Layout.square("8em"),
              zIndex: 1,
              borderRadius: "1em",
              backgroundColor: icon ? "#fff" : theme.palette.secondary.main,
              ...SharedStyles.Layout.flexCenter,
            },
            "& .icon": {
              ...SharedStyles.Layout.square("6em"),
              path: {
                fill: "#fff",
              },
            },
            "& .icon-picker__icon": {
              ...SharedStyles.Layout.square("8em"),
            },
            "&:after":
              !icon && showEmptyState
                ? {
                    content: "''",
                    backgroundImage: `url(${DynamismAssets.TaskIcon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    position: "absolute",
                    zIndex: -1,
                    ...SharedStyles.Layout.square("80vw"),
                    maxWidth: "350px",
                    maxHeight: "350px",
                    top: "50%",
                  }
                : { display: "none" },
          })}
        >
          <Box className="icon-picker__add-container">
            {icon ? (
              <img
                key={icon}
                className="icon-picker__icon"
                src={TaskAssets.TaskIcons[icon]}
                alt={`Task Icon ${icon}`}
              />
            ) : (
              <Icons.AddIcon color={palette.primary.main} />
            )}
          </Box>
        </ButtonBase>
      </Box>
      <Drawer
        classes={{ paper: rootClass + "__icon-drawer" }}
        open={showIconDrawer}
        onClose={() => setShowIconDrawer(false)}
      >
        <DrawerSection variant="header">
          <Typography
            variant="h4"
            className={clsx(rootClass + "__icon-drawer-title")}
          >
            {t("routine.task.inputs.icon.iconDrawer.title")}
          </Typography>
        </DrawerSection>

        <Grid
          className={clsx(rootClass + "__icon-drawer-body")}
          container
          spacing={2}
          justifyContent="space-between"
        >
          {getIcons().map((i) => {
            return (
              <Grid
                key={i}
                className={clsx(rootClass + "__icon-drawer-icon-wrapper")}
                item
              >
                <PremiumLock
                  active={!canShowIcon(i)}
                  variant="icon-button"
                  borderRadius="10px"
                  onClick={() => onPremiumIconClick(i)}
                >
                  <ButtonBase
                    data-cy={"selected-icon-button-" + i}
                    className={clsx(rootClass + "__icon-drawer-icon", {
                      [rootClass + "__icon-drawer-icon--selected"]: i === icon,
                    })}
                    onClick={() => {
                      onIconChoiceClicked(i);
                    }}
                  >
                    <img src={TaskAssets.TaskIcons[i]} alt={`Task Icon ${i}`} />
                  </ButtonBase>
                </PremiumLock>
              </Grid>
            );
          })}
          {getFillerIcons().map((n) => (
            <Grid
              key={numberOfIcons + n}
              className={clsx(rootClass + "__icon-drawer-icon-wrapper")}
              item
            >
              <div className={clsx(rootClass + "__icon-drawer-icon-filler")} />
            </Grid>
          ))}
        </Grid>
      </Drawer>
    </>
  );
};

export default IconPicker;
