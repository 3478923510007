import { ButtonBase, Grid } from "@mui/material";

interface IconPinButtonProps {
  icon: JSX.Element;
  onClick: VoidFunction;
  last?: boolean;
}

const IconPinButton: React.FC<IconPinButtonProps> = ({
  icon,
  onClick,
  last,
}) => {
  return (
    <Grid
      item
      xs={4}
      display="flex"
      justifyContent="center"
      ml={last ? "auto" : undefined}
      mr={last ? "auto" : undefined}
    >
      <ButtonBase
        onClick={onClick}
        sx={{
          "height": "4em",
          "width": "5em",
          "padding": "0.5em",
          "display": "flex",
          "justifyContent": "center",
          "alignItems": "center",
          "backgroundColor": "#fff",
          "borderRadius": "10px",
          "boxShadow": "0px 2px 13px 1px rgba(0, 0, 0, 0.25)",
          "& img": {
            height: "100%",
          },
          "svg": {
            width: "2em",
            height: "2em",
          },
        }}
      >
        {icon}
      </ButtonBase>
    </Grid>
  );
};

export const IconPinButtonPlaceholder = (): JSX.Element => {
  return <Grid item xs={4} />;
};

export default IconPinButton;
