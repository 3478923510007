import startOfISOWeek from "date-fns/startOfISOWeek";

/**
 * Get Date representing local midnight.
 */
const getMidnightDate = (date?: Date): Date => {
  const midnightDate = date ? new Date(date) : new Date();

  midnightDate.setHours(0, 0, 0, 0);

  return midnightDate;
};

/**
 * Get the date at midnight of the first monday before a date.
 * @param {Date} [date=now] date
 * @returns {Date}
 */
const getPreviousMonday = (date?: Date): Date => {
  return startOfISOWeek(date ?? new Date());
};

/**
 * Get the UNIX epoch timestamp of a date in seconds, if not date passed returns current time.
 * @param {Date} date
 * @returns {number}
 */
const getTimeStamp = (date?: Date): number => {
  return Math.floor((date ? date.getTime() : Date.now()) / 1000);
};

/**
 * Get the UNIX epoch timestamp of a date in milliseconds, if not date passed returns current time.
 * @param {Date} date
 * @returns {number}
 */
const getTimeStampMS = (date?: Date): number => {
  return date ? date.getTime() : Date.now();
};

/**
 * Get the time in seconds since midnight of a date, if no date passed get current time.
 * @param {Date} date
 * @returns {number}
 */
const getTimeOfDaySeconds = (date: Date = new Date()): number => {
  return (date.getHours() * 60 + date.getMinutes()) * 60;
};

/**
 * Get the machine's timezone in region format.
 * @returns {String} - The timezone in region format, e.g. "America/Toronto".
 */
const getTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Create a local midnight date from a date string "YYYY-MM-DD".
 */
const parseDateStringToJsDate = (dateString: string): Date => {
  // Parse date string.
  const date = new Date(dateString);
  // Translate UTC date to local midnight.
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());

  return date;
};

const Dates = {
  constantsSeconds: {
    MINUTE: 60,
    HOUR: 3600,
    DAY: 24 * 3600,
    WEEK: 7 * 24 * 3600,
  },
  getMidnightDate,
  getPreviousMonday,
  getTimeStamp,
  getTimeStampMS,
  getTimeOfDaySeconds,
  getTimezone,
  parseDateStringToJsDate,
};

export default Dates;
