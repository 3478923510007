import { Tools } from "@neurosolutionsgroup/tools";
import { FirebaseApp } from "firebase/app";
import {
  RemoteConfig,
  Value,
  fetchAndActivate,
  getRemoteConfig,
  getValue,
} from "firebase/remote-config";
import React, { PropsWithChildren, useEffect, useState } from "react";
import kairosDefaultValues from "../defaultValue/kairosDefaultValues.json";
import nfDefaultValues from "../defaultValue/nfDefaultValues.json";
import devDefaultValues from "../defaultValue/devDefaultValues.json";
import devNFDefaultValues from "../defaultValue/devNFDefaultValues.json";
import { Product } from "@neurosolutionsgroup/models";

interface RemoteConfigData {
  getRemoteConfigValue: (key: string) => Value;
}

const [useRemoteConfigContext, RemoteConfigContextProvider] =
  Tools.Context.createGenericContext<RemoteConfigData>("remote config context");

export enum ProjectType {
  WEBVIEWS,
  WEBAPP,
  NEXUSFOCUS,
  ADMIN_DASHBOARD,
}

interface RemoteConfigProviderProps extends PropsWithChildren {
  isDevEnv: boolean;
  project: ProjectType;
  product: Product;
  app: FirebaseApp;
  loader?: JSX.Element;
}

const RemoteConfigProvider: React.FC<RemoteConfigProviderProps> = (props) => {
  const [remoteConfig, setRemoteConfig] = useState<RemoteConfig>();
  const [configInitComplete, setConfigInitComplete] = useState(false);

  useEffect(() => {
    const rc = getRemoteConfig(props.app);

    setRemoteConfig(rc);

    rc.defaultConfig =
      props.product === "NexusFokus"
        ? props.isDevEnv
          ? devNFDefaultValues
          : nfDefaultValues
        : props.isDevEnv
        ? devDefaultValues
        : kairosDefaultValues;

    // if testing remote value uncomment this line to fetch more often
    // rc.settings.minimumFetchIntervalMillis = 60000;

    if (!props.isDevEnv) {
      fetchAndActivate(rc)
        .catch((err) => {
          console.error("Failed to fetch remote config from Firebase.", err);
        })
        .finally(() => {
          setConfigInitComplete(true);
        });
    } else {
      setConfigInitComplete(true);
    }
  }, []);

  const getRemoteConfigValue = (key: string): Value => {
    if (remoteConfig) {
      return getValue(remoteConfig, key);
    } else {
      throw new Error("Remote config not finished init.");
    }
  };

  return (
    <RemoteConfigContextProvider
      value={{
        getRemoteConfigValue,
      }}
    >
      {configInitComplete ? props.children : props.loader ? props.loader : null}
    </RemoteConfigContextProvider>
  );
};

export { useRemoteConfigContext, RemoteConfigProvider };
