import { firestore } from "firebase-admin";

export type Timestamp = firestore.Timestamp;

export const PUBLIC_TENANT = "00000000-0000-0000-0000-000000000000";

export interface FirestoreDocument {
  tenantId: string;
  [x: string]: unknown;
}

export interface FirestorePrivateDocument extends FirestoreDocument {
  userId: string;
}

export interface FirestoreDocumentWithId {
  id: string;
}
