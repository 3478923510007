import { Note } from "@neurosolutionsgroup/models";

export const sortNotesByEventTime = (notes: Note[]): Note[] => {
  return notes.sort((a, b) => {
    if (a.eventTime) {
      if (b.eventTime) {
        return a.eventTime - b.eventTime;
      } else {
        return 1;
      }
    } else {
      if (b.eventTime) {
        return -1;
      } else {
        return a.title.localeCompare(b.title);
      }
    }
  });
};
