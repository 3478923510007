import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const EditIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="edit" {...props}>
      <path
        id="path824"
        fill={color}
        d="M 13.652344 4.2402344 L 11.486328 4.5703125 L 0 15.533203 L 0 19.65625 L 4.3203125 19.65625 L 16.001953 8.5058594 L 16.001953 6.4824219 L 13.652344 4.2402344 z M 12.777344 6.1738281 L 14.078125 7.4160156 C 10.458741 10.80134 6.8400872 14.186945 3.2207031 17.572266 C 2.8189065 17.661616 2.3506567 17.583435 1.9238281 17.609375 L 1.9238281 16.3125 L 12.777344 6.1738281 z "
      />
      <path
        d="M 12.00472,3.8309266 C 13.31424,2.7532637 14.62376,1.6756008 15.93328,0.59793782 16.397946,0.53195855 16.862614,0.46597927 17.32728,0.4 c 0.890934,0.8064155 1.781866,1.6128311 2.6728,2.4192466 -0.04304,0.4639335 0.0058,0.9639379 -0.09358,1.4057591 C 18.843214,5.4718833 17.779927,6.7187609 16.71664,7.9656386 16.72816,7.30218 16.73968,6.6387213 16.7512,5.9752626 15.887146,5.1541853 15.023094,4.333108 14.15904,3.5120306 13.440934,3.6183293 12.722826,3.724628 12.00472,3.8309266 Z"
        fill={color}
        id="path828"
      />
    </BaseIcon>
  );
};

export default EditIcon;
