import { JournalNoteSaved, useAnalytics } from "@neurosolutionsgroup/analytics";
import {
  FirestoreCollection,
  FirestoreNoteDocument,
  Note,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  deleteDoc,
  doc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { SetStateAction } from "react";
import { useAppDataContext } from "../AppDataContext";
import useAuth from "../auth/useAuth";
import { NoteDateCount, NoteDocsByDate, useNotesContext } from "./NoteContext";

interface UseNotesResult {
  noteDates: Date[];
  noteDateCounts: NoteDateCount[];
  currentDate: Date;
  setCurrentDate: React.Dispatch<SetStateAction<Date>>;
  notesForWeek: NoteDocsByDate;
  loading: boolean;
  addNote: (note: Note) => Promise<boolean>;
  editNote: (note: Note) => Promise<void>;
  deleteNote: (noteId: string, date: string) => Promise<void>;
}

const useNotes = (): UseNotesResult => {
  const {
    currentDate,
    loading,
    noteDateCounts,
    noteDates,
    notesForWeek,
    setCurrentDate,
    setNoteDates,
    setNoteDateCounts,
    setNotesByDate,
    setNotesForWeek,
  } = useNotesContext();

  const { user, tenantId } = useAuth();

  const { setLoading } = useAppDataContext();

  const { handleEvent } = useAnalytics();

  const addNote = async (note: Note): Promise<boolean> => {
    if (user && tenantId) {
      setLoading(true);

      const { noteId, ...noteData } = note;

      const event: JournalNoteSaved = {
        name: "Journal Note Saved",
      };

      handleEvent(event);

      const noteDoc: FirestoreNoteDocument = {
        ...noteData,
        sideEffectId: noteData.sideEffectId ?? null,
        routineCategoryId: noteData.routineCategoryId ?? null,
        eventTime: noteData.eventTime ?? null,
        userId: user.uid,
        tenantId: tenantId,
      };

      const db = getFirestore();

      try {
        await setDoc(doc(db, FirestoreCollection.Notes, noteId), noteDoc);

        // Optimistic update of notes and other states.
        setNoteDates((current) => {
          const noteDate = Tools.Time.Dates.parseDateStringToJsDate(
            note.eventDate
          );

          if (current.includes(noteDate)) {
            return current;
          } else {
            return [...current, noteDate];
          }
        });

        setNoteDateCounts((current) => {
          const clone = [...current];

          const index = clone.findIndex(
            (noteDateCount) => noteDateCount.date === note.eventDate
          );

          if (index > -1) {
            clone[index].count = clone[index].count + 1;
          } else {
            clone.push({
              date: note.eventDate,
              count: 1,
            });
          }

          return clone;
        });

        setNotesByDate((current) => {
          if (current[note.eventDate]) {
            return {
              ...current,
              [note.eventDate]: [...current[note.eventDate], noteId],
            };
          } else {
            return { ...current, [note.eventDate]: [noteId] };
          }
        });

        setNotesForWeek((current) => {
          if (current[note.eventDate]) {
            return {
              ...current,
              [note.eventDate]: [...current[note.eventDate], note],
            };
          } else {
            return { ...current, [note.eventDate]: [note] };
          }
        });
      } finally {
        setLoading(false);
      }

      return true;
    }

    return false;
  };

  const editNote = async (note: Note): Promise<void> => {
    setLoading(true);

    const db = getFirestore();

    const noteDocRef = doc(db, FirestoreCollection.Notes, note.noteId);

    try {
      await updateDoc(noteDocRef, {
        title: note.title,
        text: note.text,
        eventDate: note.eventDate,
        eventTime: note.eventTime ?? null,
        sideEffectId: note.sideEffectId ?? null,
        sideEffectData: note.sideEffectData,
        routineCategoryId: note.routineCategoryId ?? null,
        needId: note.needId,
      });

      setCurrentDate(Tools.Time.Dates.parseDateStringToJsDate(note.eventDate));
    } finally {
      setLoading(false);
    }
  };

  const deleteNote = async (noteId: string, date: string): Promise<void> => {
    setLoading(true);

    const db = getFirestore();

    const noteDocRef = doc(db, FirestoreCollection.Notes, noteId);

    try {
      await deleteDoc(noteDocRef);

      // Optimistic update.
      setNotesForWeek((current) => {
        const clone: { [dateKey: string]: Note[] } = {};

        Object.entries(current).forEach(([dateKey, notes]) => {
          clone[dateKey] = notes.filter((note) => note.noteId !== noteId);
        });

        return clone;
      });

      setNoteDateCounts((current) => {
        const clone = [...current];

        const noteDateEntry = clone.find((noteDate) => noteDate.date === date);

        if (noteDateEntry !== undefined) {
          if (noteDateEntry?.count > 1) {
            noteDateEntry.count = noteDateEntry.count - 1;

            return [
              ...clone.filter((noteDate) => noteDate.date !== date),
              noteDateEntry,
            ];
          } else {
            return clone.filter((noteDate) => noteDate.date !== date);
          }
        }

        return clone;
      });
    } finally {
      setLoading(false);
    }
  };

  return {
    noteDates,
    noteDateCounts,
    currentDate,
    setCurrentDate,
    notesForWeek,
    loading,
    addNote,
    editNote,
    deleteNote,
  };
};

export default useNotes;
