import { ChallengeV2 } from "@neurosolutionsgroup/models";
import isChallengeComplete from "./isChallengeComplete";

const calculateFinishedChallengeStatus = (
  challenge: ChallengeV2
): "failed" | "partial" | "successful" | "unfinished" => {
  const history = challenge.history;
  const challengeResult = isChallengeComplete(challenge);

  // check if challenge is finished
  if (challengeResult.active) {
    return "unfinished";
  }

  if (challengeResult.successful) {
    return "successful";
  }

  // check if history contains both succesful and failed history
  if (
    history.some((h) => !h.parentStatus) &&
    history.some((h) => h.parentStatus)
  ) {
    return "partial";
  }

  return "failed";
};

export default calculateFinishedChallengeStatus;
