import AddIcon from "./AddIcon";
import AddTask from "./AddTask";
import RoutineConfirmation from "./RoutineConfirmation";
import RoutineDisplay from "./RoutineDisplay";
import TaskCreation from "./TaskCreation";

const FirstRoutineFTUEHints = {
  AddIcon,
  AddTask,
  RoutineConfirmation,
  RoutineDisplay,
  TaskCreation,
};

export default FirstRoutineFTUEHints;
