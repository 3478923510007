import {
  Box,
  LinearProgress,
  Typography,
  linearProgressClasses,
  useTheme,
} from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import React from "react";

interface ChallengeProgressProps {
  progress: number;
  total: number;
  finished: boolean;
}

const ChallengeProgress = ({
  progress,
  total,
  finished,
}: ChallengeProgressProps): JSX.Element | null => {
  const theme = useTheme();

  const successful = progress === total;

  return (
    <Box data-cy="challenge-progress">
      <Box
        mb={0.5}
        sx={{
          display: "flex",
          alignItems: "center",
          svg: {
            width: "1.5rem",
            height: "1.5rem",
          },
        }}
      >
        <Typography
          data-cy="challenge-progress-text"
          color={finished ? (successful ? "success" : "error") : undefined}
          fontWeight="bold"
        >
          {progress}/{total}&nbsp;
        </Typography>
        {finished ? (
          successful ? (
            <Icons.CheckMarkIcon
              color={theme.palette.success.main}
              checkStyle="kairos"
            />
          ) : (
            <Icons.CloseIcon color={theme.palette.error.main} />
          )
        ) : null}
      </Box>
      <LinearProgress
        variant="determinate"
        color={finished ? (successful ? "success" : "error") : "secondary"}
        value={(progress / total) * 100}
        sx={{
          borderRadius: "999px",
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: "999px",
          },
        }}
      />
    </Box>
  );
};

export default ChallengeProgress;
