import { Box, Typography } from "@mui/material";
import { Prescription } from "@neurosolutionsgroup/models";
import { CloseablePage } from "common/Components";
import PrescriptionForm, {
  PrescriptionFormState,
} from "pages/Medication/prescriptionForm/PrescriptionForm";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

const ROOT_CLASS = "edit-prescription";

export interface CorrectPrescriptionProps {
  open: boolean;
  onConfirm: (p: Prescription, tom: boolean) => void;
  onClose: VoidFunction;
  initialPrescription: Prescription;
  takeOtherMedication: boolean;
  medicalChildId: string;
}

const CorrectPrescription: React.FC<CorrectPrescriptionProps> = ({
  open,
  onClose,
  onConfirm,
  takeOtherMedication,
  initialPrescription,
  medicalChildId,
}) => {
  const { t } = useTranslation();

  const [takesOtherMedication, setTakesOtherMedication] =
    useState<boolean>(takeOtherMedication);
  return (
    <CloseablePage
      isOpen={open}
      onClose={onClose}
      closeElement={<></>}
      className={ROOT_CLASS}
      header={
        <Box
          className={ROOT_CLASS + "--title title-header"}
          display="flex"
          justifyContent="start"
        >
          <Typography variant="h1" color="#fff">
            {t("general.medication")}
          </Typography>
        </Box>
      }
    >
      <PrescriptionForm
        medicalChildId={medicalChildId}
        onCancel={onClose}
        onSubmit={(p) => {
          onConfirm(p, takesOtherMedication);
          onClose();
        }}
        takesOtherMedication={takesOtherMedication}
        setTakesOtherMedication={setTakesOtherMedication}
        prescription={initialPrescription}
        formState={PrescriptionFormState.edition}
      />
    </CloseablePage>
  );
};
export default CorrectPrescription;
