import { FollowedSideEffect, Prescription } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { isAfter, isBefore, isSameDay, parseISO, sub } from "date-fns";
import { RecordsSummariesByFollowedSideEffect } from "./FollowUpContext";

const getFollowedSideEffectStatusLastWeek = (
  fuse: FollowedSideEffect
): boolean => {
  // Filter any changes after period in question.
  const statusChanges = fuse.activeStatusChanges.filter((asc) =>
    isBefore(parseISO(asc), Tools.Time.Dates.getPreviousMonday())
  );

  // If number of active status changes was even the side effect was active.
  return (
    statusChanges.length % 2 === 0 &&
    isBefore(parseISO(fuse.startDate), Tools.Time.Dates.getPreviousMonday())
  );
};

const getLastWeeksPrescriptions = (
  prescriptions: Prescription[]
): Prescription[] => {
  const monday = Tools.Time.Dates.getPreviousMonday();
  const lastWeekMonday = sub(monday, {
    days: 7,
  });

  // Get all prescriptions that were active all of last week.
  return prescriptions.filter(
    (p) =>
      isBefore(parseISO(p.startDate), lastWeekMonday) &&
      p.endDate &&
      isAfter(parseISO(p.endDate), lastWeekMonday)
  );
};

const getObservationsToDo = (
  followedSideEffects: FollowedSideEffect[],
  recordSummaries: RecordsSummariesByFollowedSideEffect
): FollowedSideEffect[] => {
  const monday = Tools.Time.Dates.getPreviousMonday();
  const lastWeekMonday = sub(monday, {
    days: 7,
  });

  // Check if summary contains relevant record.
  const filteredFollowUps = followedSideEffects.filter(
    (fuse) =>
      getFollowedSideEffectStatusLastWeek(fuse) &&
      (!recordSummaries[fuse.sideEffectId] ||
        !recordSummaries[fuse.sideEffectId].some(
          (rs) =>
            rs.medicalChildId === fuse.medicalChildId &&
            rs.records.some((r) =>
              isSameDay(parseISO(r.weekStartDate), lastWeekMonday)
            )
        ))
  );

  return filteredFollowUps;
};

const FollowUpLogic = {
  getFollowedSideEffectStatusLastWeek,
  getLastWeeksPrescriptions,
  getObservationsToDo,
};

export default FollowUpLogic;
