import { ChildrenById } from "@neurosolutionsgroup/models";
import { getUnixTime, startOfWeek } from "date-fns";
import { DashboardProgress } from "./DashboardProgress";

const calculateProgress = (step: DashboardProgress): number => {
  return (
    Object.values(DashboardProgress).findIndex((v) => v === step.toString()) - 1
  );
};

const getProgress = (recordIds: string[]): DashboardProgress => {
  let latestDashboardStep = DashboardProgress.None;

  if (recordIds.includes(DashboardProgress.DashboardFTUEFinished.toString())) {
    latestDashboardStep = DashboardProgress.DashboardFTUEFinished;
  } else {
    Object.values(DashboardProgress).forEach((v) => {
      if (recordIds.includes(v)) {
        latestDashboardStep = v;
      }
    });
  }

  return latestDashboardStep;
};

const isComplete = (progress: DashboardProgress): boolean => {
  return progress === DashboardProgress.DashboardFTUEFinished;
};

const meetsInitialCriteria = (
  childrenById: ChildrenById,
  selectedChild: string | null
): boolean => {
  if (!selectedChild || !childrenById[selectedChild]) {
    return false;
  }

  // Check data exists for child for default time period (this week).
  const periodStart = getUnixTime(startOfWeek(new Date()));
  const periodEnd = getUnixTime(new Date());

  return childrenById[selectedChild].history.some(
    (h) =>
      h.dueTime > periodStart && h.dueTime < periodEnd && h.confirmTime !== null
  );
};

const FTUEDashboardLogic = {
  calculateProgress,
  getProgress,
  isComplete,
  meetsInitialCriteria,
};

export default FTUEDashboardLogic;
