import { Box } from "@mui/material";
import {
  AnalyticsEvent,
  ConsoleTrackerDebugger,
} from "@neurosolutionsgroup/analytics";
import { CustomWindow } from "custom.window";
import React, { useState } from "react";

declare let window: CustomWindow;

const ConsoleDebugger = (): JSX.Element | null => {
  const [showDebugger, setShowDebugger] = useState(false);

  window.toggleAnalyticsDebugger = () => {
    setShowDebugger((current) => !current);
  };

  return showDebugger ? (
    <Box
      sx={{
        pointerEvents: "none",
        zIndex: 100000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        color: "#000",
        padding: 1,
        fontSize: "0.8rem",
        code: {
          backgroundColor: "#ffffff99",
          padding: 0.5,
        },
      }}
    >
      <p>
        <strong>Analytics Debugger</strong>
      </p>

      <p>
        <strong>Init:</strong>{" "}
        {ConsoleTrackerDebugger.initialised ? "true" : "false"}
      </p>

      <p>
        <strong>Events {ConsoleTrackerDebugger.events.length}</strong>
      </p>

      <code>
        {ConsoleTrackerDebugger.events.map(
          (event: AnalyticsEvent, i: number) => (
            <span key={i}>
              {event.name}
              <br />
            </span>
          )
        )}
      </code>
    </Box>
  ) : null;
};

export default ConsoleDebugger;
