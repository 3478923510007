import { Input } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";
import { useEffect, useRef } from "react";

interface AgeGateDigitProps extends CypressProps {
  id?: string;
  index: number;
  value: string;
  onChange: (value: string) => void;
  focus: boolean;
  onFocus: VoidFunction;
  error: boolean;
  onDelete: VoidFunction;
  autoFocus?: boolean;
}

const AgeGateDigit: React.FC<AgeGateDigitProps> = ({
  id,
  index,
  value,
  onChange,
  focus,
  onFocus,
  error,
  onDelete,
  autoFocus,
  ...props
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (focus) {
      ref.current?.focus();
      ref.current?.click();
    } else {
      ref.current?.blur();
    }
  }, [focus]);

  return (
    <Input
      id={id}
      data-cy={props["data-cy"]}
      value={value.length > index ? value[index] : ""}
      onChange={(e) => onChange(e.currentTarget.value)}
      onClick={() => {
        ref.current?.focus();
        onFocus();
      }}
      autoFocus={autoFocus}
      inputRef={ref}
      error={error}
      inputProps={{
        pattern: "\\d*",
        inputMode: "numeric",
      }}
      disableUnderline={true}
      sx={(theme) => ({
        border: "1px solid",
        borderColor: error
          ? theme.palette.error.main
          : focus
          ? theme.palette.secondary.main
          : "#d2cac1",
        borderRadius: "5px",
        height: "4rem",
        width: "4rem",
        padding: "6px",
        input: {
          textAlign: "center",
        },
      })}
      onKeyDown={(e) => {
        if (e.key === "Backspace") {
          onDelete();
        }
      }}
    />
  );
};

export default AgeGateDigit;
