import { Box, Chip, chipClasses, Typography, useTheme } from "@mui/material";
import React from "react";
import FilterTag from "./FilterTag";
import { PremiumLock } from "../Premium";

interface FilterOptionProps {
  label: string;
  count?: number;
  icon?: (color: string) => JSX.Element;
  active: boolean;
  onClick: VoidFunction;
  disabled?: boolean;
  premiumLock?: boolean;
  onPremiumLockClick?: VoidFunction;
}

const FilterOption: React.FC<FilterOptionProps> = ({
  label,
  count,
  icon,
  active,
  onClick,
  disabled = false,
  premiumLock,
  onPremiumLockClick,
}) => {
  const theme = useTheme();

  return (
    <PremiumLock
      active={premiumLock ?? false}
      variant="button"
      onClick={() => onPremiumLockClick && onPremiumLockClick()}
      top={"-1.1rem"}
      right={"-0.5rem"}
    >
      <Chip
        disabled={disabled}
        label={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            {icon ? (
              <Box
                mr={1}
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  svg: {
                    width: "1rem",
                    height: "1rem",
                  },
                }}
              >
                {icon(active ? "#fff" : theme.palette.secondary.main)}
              </Box>
            ) : null}
            <Typography color={active ? "#fff" : "secondary"} fontSize="0.8rem">
              {label}
            </Typography>
            {count !== undefined && <FilterTag count={count} active={active} />}
          </Box>
        }
        onClick={onClick}
        sx={(theme) => ({
          "backgroundColor": active ? theme.palette.secondary.main : "#fff",
          "padding": "0.25rem",
          "boxShadow": "-3px 1px 14px -3px rgba(0, 0, 0, 0.25)",
          "&:hover": {
            backgroundColor: active ? theme.palette.secondary.main : "#fff",
          },
          [`& .${chipClasses.label}`]: {
            paddingRight: count !== undefined ? 0 : 1,
            paddingLeft: 1,
          },
        })}
      />
    </PremiumLock>
  );
};

export default FilterOption;
