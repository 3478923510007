import { Grid, Typography } from "@mui/material";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

interface CoachErrorDialogProps {
  open: boolean;
  onClose: VoidFunction;
  onConfirm?: VoidFunction;
  error: CoachError;
  extraText?: JSX.Element;
  overwriteText?: JSX.Element;
  overwriteTitle?: string;
}

const CoachErrorDialog: React.FC<CoachErrorDialogProps> = ({
  open,
  onClose,
  onConfirm,
  error,
  extraText,
  overwriteText,
  overwriteTitle,
}) => {
  const { t } = useTranslation();

  return (
    <Dialogs.InputErrorDialog
      open={open}
      onClose={onClose}
      title={overwriteTitle ?? t(`coach.errors.${error}.title`)}
      text={
        <Grid container spacing={1} direction="column" alignItems="center">
          <Grid
            item
            xs={4}
            sx={{
              img: {
                maxWidth: "100%",
                maxHeight: "100px",
              },
            }}
          >
            <img src={DialogAssets.Tag} alt="" />
          </Grid>
          <Grid item xs={8}>
            <Typography fontSize="0.85rem" textAlign="center">
              {overwriteText ?? (
                <>
                  <Trans i18nKey={`coach.errors.${error}.text`} />
                  &nbsp;
                  {extraText}
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
      }
      onConfirm={{
        text: t("general.understood"),
        action: onConfirm ? onConfirm : onClose,
      }}
    />
  );
};

export default CoachErrorDialog;

export enum CoachError {
  RoutineTotal = "routineTotal",
  ActiveRoutines = "activeRoutines",
  TasksPerRoutine = "tasksPerRoutine",
  RoutinesOverlap = "routinesOverlap",
}

export const MAXIMUM_ROUTINES = 20;
export const MAXIMUM_ACTIVE_ROUTINES = 4;
export const TASKS_PER_ROUTINE = 9;
