import {
  Box,
  Grid,
  Typography,
  Divider,
  ButtonBase,
  Drawer,
  drawerClasses,
  useTheme,
} from "@mui/material";
import { taskCategoryLoc } from "@neurosolutionsgroup/localization";
import InformationButton from "common/Components/Buttons/InformationButton";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CategoryDetailDrawer from "./CategoryDetailDrawer";
import TaskAnalysisGraph from "./TaskAnalysisGraph";
import {
  DashboardTaskCategoryExpanded,
  DashboardRoutineTimePeriodSelected,
  useAnalytics,
  DashboardTaskAnalysisGraphOpened,
  PremiumFeature,
} from "@neurosolutionsgroup/analytics";
import { TaskCount } from "./useRoutineData";
import {
  IconButton,
  Icons,
  PeriodOption,
  PeriodPicker,
  PremiumLock,
} from "@neurosolutionsgroup/components";
import useLanguage from "common/hooks/Parameters/useLanguage";
import useChildren from "common/hooks/children/useChildren";
import usePDFExport from "../usePDFExport";
import Toast from "common/Components/Toast/Toast";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { RoutinePeriod, isApiError } from "@neurosolutionsgroup/models";
import useParameters from "common/hooks/Parameters/useParameters";
import { AccordionCard, Loader } from "common/Components";
import { useLocation } from "react-router-dom";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import { Tools } from "@neurosolutionsgroup/tools";
import {
  DashboardProgress,
  FTUEFlow,
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import useSubscription from "common/hooks/subscription/useSubscription";
import { SAFE_AREAS } from "stylesheets";

export interface TaskAnalysisProps {
  loading: boolean;
  period: RoutinePeriod;
  periodText: string;
  setPeriod: React.Dispatch<SetStateAction<RoutinePeriod>>;
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<SetStateAction<string>>;
  periodOptions: PeriodOption[];
  data: TaskCount;
}

const TaskAnalysis = ({
  loading,
  period,
  periodText,
  setPeriod,
  selectedCategory,
  setSelectedCategory,
  periodOptions,
  data,
}: TaskAnalysisProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const { palette } = useTheme();
  const { handleEvent } = useAnalytics();
  const { handleUnknownError } = useErrorsContext();
  const { onExportRoutine } = usePDFExport();
  const { version } = useParameters();
  const { hash } = useLocation();
  const { checkFeatureFlagVersion } = useRemoteConfig();
  const {
    selectors: { progress },
    actions: { canDisplayFlow },
  } = useFTUE();

  const { onPremiumFeatureClick, permissions } = useSubscription();

  const [openInfoDrawer, setOpenInfoDrawer] = useState(false);
  const [fullscreen, setFullscreen] = useState(false);
  const [openCategoryDetailDrawer, setOpenCategoryDetailDrawer] =
    useState(false);
  const [noDataError, setNoDataError] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const canExportData = Object.keys(data.historyByCategory).length > 0;

  useEffect(() => {
    if (
      (progress[FTUEFlow.Dashboard].progress === DashboardProgress.Routines ||
        progress[FTUEFlow.Dashboard].progress ===
          DashboardProgress.RoutinesGraph) &&
      canDisplayFlow(FTUEFlow.Dashboard)
    ) {
      setExpanded(true);
    }
  }, [progress]);

  const {
    selectors: { selectedChild },
  } = useChildren();

  useEffect(() => {
    if (hash && hash === "#category-details" && data.graphData.length > 0) {
      const categoryId = Object.keys(taskCategoryLoc[language]).find(
        (k) => taskCategoryLoc[language][k] === data.graphData[0].category
      );

      if (categoryId) {
        setSelectedCategory(categoryId);
        setOpenCategoryDetailDrawer(true);
      }
    }
  }, [hash, data]);

  const onExpanded = (action: SetStateAction<boolean>) => {
    if (action.valueOf()) {
      const event: DashboardTaskAnalysisGraphOpened = {
        name: "Dashboard Task Analysis Graph Opened",
      };

      handleEvent(event);
    }

    setExpanded(action);
  };

  const onCategoryClick = (categoryId: string) => {
    const event: DashboardTaskCategoryExpanded = {
      name: "Dashboard Task Category Expanded",
      eventProperties: {
        "Task Category": taskCategoryLoc.en[categoryId],
        "Task Category ID": categoryId,
      },
    };

    handleEvent(event);

    setSelectedCategory(categoryId);
    setFullscreen(false);
    setOpenCategoryDetailDrawer(true);
  };

  const onPeriodChange = (period: number) => {
    const event: DashboardRoutineTimePeriodSelected = {
      name: "Dashboard Routine Time Period Selected",
      eventProperties: {
        "Selected Time Period": t("dashboard.period.options." + period, {
          lng: "en",
        }),
      },
    };

    handleEvent(event);

    setPeriod(period);
  };

  const exportRoutineClicked = async () => {
    if (!selectedChild) {
      return;
    }

    setExportLoading(true);

    try {
      await onExportRoutine(
        selectedChild,
        period,
        language,
        Tools.Environment.isDevBuild()
      );
    } catch (err) {
      if (isApiError(err) && err.code === 404) {
        setNoDataError(true);
      } else {
        handleUnknownError(err);
      }
    } finally {
      setExportLoading(false);
    }
  };

  return (
    <AccordionCard
      expanded={expanded}
      setExpanded={onExpanded}
      title={
        <Box display="flex" alignItems="center">
          <Typography variant="h4" mr={1}>
            {t("dashboard.task.title")}
          </Typography>
          <Box ml={1}>
            <InformationButton
              iconVariant="outline"
              onClick={(e) => {
                e.stopPropagation();
                setOpenInfoDrawer(true);
              }}
            />
          </Box>
        </Box>
      }
      detailsStyle={{
        padding: 0,
      }}
    >
      <FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardTimePeriod
        onNext={() => setExpanded(false)}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          px={2}
          mt={2}
        >
          <Typography variant="h5">{t("dashboard.period.label")}</Typography>
          <Box display="flex" alignItems="center">
            {version &&
            checkFeatureFlagVersion(
              WebviewsFeatureFlag.DashboardExport,
              version
            ) ? (
              <PremiumLock
                active={!permissions.export}
                variant="icon-button"
                onClick={() =>
                  onPremiumFeatureClick(PremiumFeature.ExportTaskAnalysis)
                }
              >
                <IconButton
                  onClick={exportRoutineClicked}
                  disabled={!canExportData}
                >
                  {exportLoading ? <Loader /> : null}
                  <Icons.ExportIcon color={palette.secondary.main} />
                </IconButton>
              </PremiumLock>
            ) : null}
            <Box ml={1}>
              <PeriodPicker
                options={periodOptions}
                value={period}
                onChange={onPeriodChange}
                loading={loading}
                hasPremiumLock={!permissions.allRoutinePeriods}
                onPremiumOptionClick={(option) =>
                  onPremiumFeatureClick(
                    PremiumFeature.GraphPeriodTaskAnalysis,
                    {
                      period: t(
                        "dashboard.period.options." + option.value.toString(),
                        {
                          lng: "en",
                        }
                      ),
                    }
                  )
                }
                safeAreas={SAFE_AREAS}
              />
            </Box>
          </Box>
        </Box>
      </FTUEFlowDefinitions.DashboardFTUEFlow.Hints.DashboardTimePeriod>

      <Box p={2}>
        <Grid container spacing={2} justifyContent="space-around">
          <Grid item xs={5}>
            <Grid container alignItems="center">
              <Grid item mr={2}>
                <Typography fontSize="2rem" fontWeight="bold">
                  {data.done}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  "filter": "drop-shadow(0px 0px 10px #A6DA2D)",
                  "& .icon-check": {
                    width: "3em",
                    height: "3em",
                  },
                }}
                display="flex"
                alignItems="center"
              >
                <Icons.CheckMarkIcon color="#A6DA2D" checkStyle="kairos" />
              </Grid>
            </Grid>
            <Typography fontSize="0.8rem">
              {t("dashboard.task.done", { count: data.done })}
            </Typography>
          </Grid>
          <Grid item xs={5}>
            <Grid container alignItems="center">
              <Grid item mr={2}>
                <Typography fontSize="2rem" fontWeight="bold">
                  {data.notDone}
                </Typography>
              </Grid>
              <Grid
                item
                sx={{
                  "filter": "drop-shadow(0px 0px 10px #EF4044)",
                  "& .icon-close": {
                    width: "3em",
                    height: "3em",
                  },
                }}
                display="flex"
                alignItems="center"
              >
                <Icons.CloseIcon color="#EF4044" />
              </Grid>
            </Grid>
            <Typography fontSize="0.8rem">
              {t("dashboard.task.notDone", {
                count: data.notDone,
              })}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Divider
        sx={(theme) => ({
          borderColor: theme.palette.secondary.main,
        })}
      />

      <Box display="flex" alignItems="center" px={1} py={1.5}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight="bold" fontSize="0.8rem">
            {t("dashboard.task.graph.yLabel")}
          </Typography>
        </Box>

        <Typography ml="auto" fontSize="0.75rem">
          {periodText}
        </Typography>
      </Box>

      <Divider
        sx={(theme) => ({
          borderColor: theme.palette.secondary.main,
        })}
      />

      <TaskAnalysisGraph
        data={data.graphData}
        onCategoryClick={onCategoryClick}
      />

      <InfoDrawer
        open={openInfoDrawer}
        onClose={() => setOpenInfoDrawer(false)}
        title={t("dashboard.task.title")}
        text={t("dashboard.task.info.text")}
      />

      <Drawer
        anchor="bottom"
        open={fullscreen}
        onClose={() => setFullscreen(false)}
        sx={{
          [`& .${drawerClasses.paper}`]: {
            height: "100vh",
            width: "100vw",
            maxWidth: "100vw",
            borderRadius: 0,
            overflowX: "hidden",
          },
        }}
      >
        <Box
          sx={{
            width: "100vh",
            height: "100vw",
            transform: "rotate(90deg)",
            transformOrigin: "50vw 50vw",
          }}
        >
          <Box display="flex" justifyContent="flex-end">
            <ButtonBase
              onClick={() => setFullscreen(false)}
              sx={{
                svg: {
                  width: "2em",
                  height: "2em",
                  padding: "0.5em",
                  borderRadius: "10px",
                },
              }}
            >
              <Icons.CloseIcon color={palette.secondary.main} />
            </ButtonBase>
          </Box>
          <Box p={1}>
            <TaskAnalysisGraph
              data={data.graphData}
              onCategoryClick={onCategoryClick}
              fullscreen
            />
          </Box>
        </Box>
      </Drawer>

      <CategoryDetailDrawer
        category={selectedCategory}
        open={openCategoryDetailDrawer}
        onClose={() => setOpenCategoryDetailDrawer(false)}
        period={period}
        history={data.historyByCategory[selectedCategory] ?? []}
      />
      <Toast
        open={noDataError}
        onClose={() => setNoDataError(false)}
        timeout={3000}
        title={t("dashboard.export.error.title")}
        text={t("dashboard.export.error.textRoutine")}
      />
    </AccordionCard>
  );
};

export default TaskAnalysis;
