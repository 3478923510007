import { Button, Grid, Typography } from "@mui/material";
import { PageFooter } from "@neurosolutionsgroup/components";
import { FirstRoutineProgress } from "flows/FirstRoutine";
import { useTranslation } from "react-i18next";
import useFTUE from "useFTUE";

interface FtueRoutineEndScreenProps {
  onFtueEnd: VoidFunction;
}

const EndScreen = ({ onFtueEnd }: FtueRoutineEndScreenProps): JSX.Element => {
  const { t } = useTranslation();

  const {
    selectors: { assets },
    actions: { setFirstRoutineProgress },
  } = useFTUE();

  const onContinue = async () => {
    setFirstRoutineProgress(
      FirstRoutineProgress.RoutineFTUEFinished,
      undefined,
      true
    ).then(() => {
      onFtueEnd();
    });
  };

  return (
    <>
      <Grid
        container
        direction="column"
        spacing={1}
        flexWrap="nowrap"
        height="100%"
      >
        <Grid item marginTop={2} marginBottom={3}>
          <Typography textAlign="center" color="#fff" fontWeight="bold">
            {t("ftue.firstRoutine.end.title")}
          </Typography>
        </Grid>
        <Grid
          item
          mx="auto"
          marginBottom={2}
          flexGrow={1}
          sx={{
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
            boxSizing: "border-box",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            img: {
              maxWidth: "90%",
              height: 0,
              minHeight: "100%",
              maxHeight: "500px",
              objectFit: "contain",
            },
          }}
        >
          <img src={assets.ftueEnd} alt="" />
        </Grid>
      </Grid>
      <PageFooter sx={{ paddingX: 1, paddingBottom: 2 }}>
        <Button
          data-cy="end-button-ftue"
          fullWidth
          color="secondary"
          onClick={onContinue}
        >
          {t("ftue.firstRoutine.end.action")}
        </Button>
      </PageFooter>
    </>
  );
};

export default EndScreen;
