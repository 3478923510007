import { Tools } from "@neurosolutionsgroup/tools";
import {
  CoachFlags,
  FirestoreCollection,
  MessageFlags,
  Routine,
  Task,
  TaskHistory,
  Template,
} from "@neurosolutionsgroup/models";
import { useMemo } from "react";
import {
  CoachMessage,
  DifficultTaskResult,
  useCoachContext,
} from "./CoachContext";
import {
  CoachNotificationID,
  coachNotificationDefinitions,
  CoachNotificationFrequency,
  CoachNotification,
} from "./CoachNotifications";
import { sub } from "date-fns";
import useRoutines from "common/hooks/routines/useRoutines";
import useTasks from "common/hooks/routines/useTasks";
import useAuth from "common/hooks/auth/useAuth";
import { updateMessageDoc, updateMessageFlag } from "../tools";
import CoachLogic from "./CoachLogic";
import useChildren from "common/hooks/children/useChildren";
import {
  ValidationProgress,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import useLanguage from "common/hooks/Parameters/useLanguage";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";

export interface ValidationRecord {
  taskId: string;
  status: boolean | null;
}

interface UseCoachResult {
  coachCanBeDisplayed: boolean;
  coachMessage: CoachMessage | undefined;
  pushCoachNotification: (
    notification: CoachNotificationID,
    data: Record<string, number | string>
  ) => void;
  onCoachDismissal: VoidFunction;
  onRoutineDeactivated: () => void;
  onRoutineActivated: (routine: Routine) => void;
  onTaskCreated: (tasks: Task[]) => void;
  onTaskNameChange: VoidFunction;
  onObservationsAvailable: VoidFunction;
  onObservationsComplete: VoidFunction;
  onMedicationAdded: VoidFunction;
  onTaskValidationAvailable: VoidFunction;
  onTaskValidation: (
    taskHistory: TaskHistory[],
    routine: Routine,
    isComplete: boolean,
    isLast: boolean
  ) => void;
  onGuideEnter: (childrenWithDiagnosis: number) => void;
  currentCoachMessageDefintion: CoachNotification | undefined;
  updateCoachNotificationRecord: (key: string) => Promise<void>;
  sendFollowUpsExportemail: VoidFunction;
  shouldSeeFollowUpDataChangeWarning: () => boolean;
  shouldSeeFollowUpDataExport: () => boolean;
}

const useCoach = (): UseCoachResult => {
  const {
    coachRecordsInitializationComplete,
    coachRecords,
    setCoachRecords,
    coachFlags,
    coachMessage,
    setCoachMessage,
    setCoachMessageQueue,
    coachCanBeDisplayed,
    failedTaskByChildId,
    setFailedTaskByChildId,
  } = useCoachContext();

  const { user } = useAuth();

  const {
    selectors: { childrenById, selectedChild },
  } = useChildren();

  const {
    actions: { getCompletedDate },
  } = useFTUE();

  const {
    actions: { getActiveRoutines },
  } = useRoutines();

  const {
    selectors: { tasksById },
  } = useTasks();
  const { language } = useLanguage();

  const {
    selectors: { childrenWithFilledFollowUp },
  } = useFollowUp();

  const { checkFeatureFlagVersion } = useRemoteConfig();

  const pushCoachNotification = (
    notification: CoachNotificationID,
    data?: Record<string, number | string | string[]>
  ): void => {
    if (coachRecordsInitializationComplete && canDisplayMessage(notification)) {
      setCoachMessageQueue((current) => {
        const clone = [...current];

        clone.push({ id: notification, data });

        return clone;
      });
    }
  };

  const canDisplayMessage = (notification: CoachNotificationID): boolean => {
    if (!coachCanBeDisplayed) {
      return false;
    }

    if (!coachRecordsInitializationComplete) {
      return false;
    }

    const lastDisplay: number | undefined = coachRecords.find(
      (cr) => cr.id === notification
    )?.lastSeen;

    if (lastDisplay) {
      switch (coachNotificationDefinitions[notification].frequency) {
        case CoachNotificationFrequency.EveryTime:
          return true;
        case CoachNotificationFrequency.Once:
          return false;
        case CoachNotificationFrequency.Monthly: {
          const date = new Date();
          date.setMonth(date.getMonth() - 1);
          return lastDisplay < Tools.Time.Dates.getTimeStamp(date);
        }
        case CoachNotificationFrequency.Weekly: {
          return (
            lastDisplay <
            Tools.Time.Dates.getTimeStamp(sub(new Date(), { weeks: 1 }))
          );
        }
      }
    } else {
      return true;
    }
  };

  const onCoachDismissal = async (): Promise<void> => {
    if (user) {
      const coachMessageToUpdate = coachMessage?.id as string;

      const lastSeen = Tools.Time.Dates.getTimeStamp();

      updateMessageDoc(FirestoreCollection.CoachMessages, user.uid, [
        {
          id: coachMessageToUpdate,
          lastSeen,
        },
      ]);

      // TODO: Check if we can remove this now we have the snapshot.

      setCoachRecords((current) => {
        const clone = [...current];

        const index = clone.findIndex((cr) => cr.id === coachMessageToUpdate);

        if (index !== -1) {
          clone[index].lastSeen = lastSeen;
        } else {
          clone.push({ id: coachMessageToUpdate, lastSeen });
        }

        return clone;
      });

      // Clear messages when coach dismissed.
      setCoachMessage(undefined);
      setCoachMessageQueue([]);
    }
  };

  const currentCoachMessageDefintion = useMemo(() => {
    return coachMessage
      ? coachNotificationDefinitions[coachMessage.id]
      : undefined;
  }, [coachMessage]);

  const onRoutineDeactivated = () => {
    pushCoachNotification(CoachNotificationID.FirstRoutineDeactivated);
  };

  const onRoutineActivated = (routine: Routine): void => {
    const activeRoutines = getActiveRoutines();

    let isThirdRoutine = false;

    // Check if routine is third routine for any of the children associated.
    routine.users.forEach((u) => {
      const activeRoutinesForChild = activeRoutines.filter((r) =>
        r.users.includes(u)
      );

      if (activeRoutinesForChild.length === 2) {
        isThirdRoutine = true;
      }
    });

    if (isThirdRoutine) {
      pushCoachNotification(CoachNotificationID.ThirdRoutineActivated);
    }

    if (routine.tasks.some((t) => t && (t.icon === 15 || t.icon === 17))) {
      pushCoachNotification(CoachNotificationID.HomeworkTaskAssigned);
    }

    if (
      routine.templateId === Template.Evening &&
      routine.tasks.some((t) => t && t.icon === 44)
    ) {
      pushCoachNotification(CoachNotificationID.EveningScreenTask);
    }
  };

  const onTaskCreated = () => {
    pushCoachNotification(CoachNotificationID.FirstTaskCreated);
  };

  const onTaskNameChange = (): void => {
    pushCoachNotification(CoachNotificationID.TaskNameEdited);
  };

  const onObservationsAvailable = (): void => {
    pushCoachNotification(CoachNotificationID.ObservationsAvailable);
  };

  const onObservationsComplete = (): void => {
    pushCoachNotification(CoachNotificationID.FirstObservationsComplete);
  };

  const onMedicationAdded = () => {
    pushCoachNotification(CoachNotificationID.MedicationAddedRoutine);
  };

  const onTaskValidationAvailable = () => {
    pushCoachNotification(CoachNotificationID.ValidationReminder);
  };

  const onTaskValidation = async (
    taskHistory: TaskHistory[],
    routine: Routine,
    isComplete: boolean,
    isLast: boolean
  ): Promise<void> => {
    if (user && selectedChild) {
      // Validation done so push reminder by a week.
      const lastSeen = Tools.Time.Dates.getTimeStamp();

      updateMessageDoc(FirestoreCollection.CoachMessages, user.uid, [
        {
          id: CoachNotificationID.ValidationReminder,
          lastSeen,
        },
      ]);

      // Routine validation count.
      let occurrences = CoachLogic.nbrOfRoutineValidated(
        coachFlags,
        coachRecords
      );

      occurrences++;

      if (occurrences >= 2) {
        pushCoachNotification(CoachNotificationID.PositiveReinforcement);
      }

      if (occurrences >= 10) {
        pushCoachNotification(CoachNotificationID.TenthRoutineValidated);
      }

      // Move validation count to flag.
      updateMessageFlag(user.uid, FirestoreCollection.CoachMessages, {
        validationCount: occurrences,
      });

      // Morning task failure.
      if (CoachLogic.morningTaskFailed(routine, taskHistory)) {
        pushCoachNotification(CoachNotificationID.MorningTaskFailed);
      }

      // Homework task failure.
      if (CoachLogic.homeworkTaskFailed(routine, taskHistory, tasksById)) {
        pushCoachNotification(CoachNotificationID.HomeworkTaskFailed);
      }

      // Sleep task failure.
      if (CoachLogic.sleepTaskFailed(taskHistory, tasksById)) {
        let sleepTasksFailed = 0;

        if (coachFlags.sleepTasksFailed) {
          sleepTasksFailed = coachFlags.sleepTasksFailed;
        } else {
          // Support old location of occurrences during migration.
          const index = coachRecords.findIndex(
            (cr) => cr.id === CoachNotificationID.SleepTaskFailed
          );

          if (index !== -1) {
            sleepTasksFailed =
              (coachRecords[index].extraData?.occurrences as number) ?? 0;
          }
        }

        if (sleepTasksFailed === 0 || sleepTasksFailed === 1) {
          updateMessageFlag(user.uid, FirestoreCollection.CoachMessages, {
            sleepTasksFailed,
          });

          if (sleepTasksFailed === 1) {
            pushCoachNotification(CoachNotificationID.SleepTaskFailed);
          }
        }
      }

      // Call logic function for each task.
      const updatedTaskCounts = taskHistory.map((th) =>
        CoachLogic.handleDifficultTaskValidation(
          selectedChild,
          th.task,
          th.status,
          th.dueTime,
          coachFlags
        )
      );

      // Update flags that need updating.
      const messageFlags: MessageFlags = {};

      updatedTaskCounts.forEach((o) => {
        if (o.newCount) {
          messageFlags[`failedTasks.${selectedChild}.${o.taskId}.occurrences`] =
            o.newCount;
        }
        if (o.newLatestValidation) {
          messageFlags[
            `failedTasks.${selectedChild}.${o.taskId}.latestValidation`
          ] = o.newLatestValidation;
        }
      });

      updateMessageFlag(
        user.uid,
        FirestoreCollection.CoachMessages,
        messageFlags
      );

      if (updatedTaskCounts.filter((o) => o.taskShouldDisplay).length > 0) {
        // Save tasks that should be shown at end of validation.
        setFailedTaskByChildId((current) => ({
          ...current,
          [selectedChild]: current[selectedChild]
            ? [
                ...current[selectedChild],
                ...updatedTaskCounts.filter((o) => o.taskShouldDisplay),
              ]
            : updatedTaskCounts.filter((o) => o.taskShouldDisplay),
        }));
      }

      if (isComplete && isLast) {
        onAllTasksValidated({
          ...failedTaskByChildId,
          [selectedChild]: failedTaskByChildId[selectedChild]
            ? [
                ...failedTaskByChildId[selectedChild],
                ...updatedTaskCounts.filter((o) => o.taskShouldDisplay),
              ]
            : updatedTaskCounts.filter((o) => o.taskShouldDisplay),
        });
      }
    }
  };

  const onAllTasksValidated = async (tasksToDisplay: {
    [childId: string]: DifficultTaskResult[];
  }): Promise<void> => {
    if (!user) {
      return;
    }

    const histories = Object.values(childrenById).flatMap((c) => {
      return c.history;
    });

    const updateFlags = (flags: CoachFlags) =>
      updateMessageFlag(user.uid, FirestoreCollection.CoachMessages, flags);

    CoachLogic.handleConsecutiveWeeksUseMessage(
      coachFlags,
      Tools.Time.Dates.getTimeStamp(),
      getCompletedDate(ValidationProgress.ValidationFTUEFinished),
      histories,
      pushCoachNotification,
      updateFlags
    );

    if (selectedChild && childrenById[selectedChild]) {
      const failedTasks = tasksToDisplay[selectedChild];

      if (failedTasks && failedTasks.length > 0) {
        const childName = childrenById[selectedChild].name;

        const recentTaskNames = failedTasks
          .filter((failedTask) => !!tasksById[failedTask.taskId])
          .sort((a, b) => b.dueTime - a.dueTime)
          .slice(0, 3)
          .map((failedTask) => tasksById[failedTask.taskId].name);

        pushCoachNotification(CoachNotificationID.DifficultTaskSucceeded, {
          name: childName,
          taskString: recentTaskNames,
        });
      }
    }
  };

  const onGuideEnter = (childrenWithDiagnosis: number) => {
    if (childrenWithDiagnosis > 0) {
      pushCoachNotification(CoachNotificationID.DiagnosisGuide);
    }
  };

  const updateCoachNotificationRecord = async (key: string): Promise<void> => {
    if (user) {
      const lastSeen = Tools.Time.Dates.getTimeStamp();

      updateMessageDoc(FirestoreCollection.CoachMessages, user.uid, [
        {
          id: key,
          lastSeen,
        },
      ]);

      setCoachRecords((current) => {
        const clone = [...current];

        const index = clone.findIndex((cr) => cr.id === key);

        if (index !== -1) {
          clone[index].lastSeen = lastSeen;
        } else {
          clone.push({ id: key, lastSeen: lastSeen });
        }

        return clone;
      });
    }
  };

  // Removed for now as we are no longer refactoring this code.
  /* const onParentSectionOpen = () => {
    if (shouldSeeFollowUpDataChangeWarning()) {
      pushCoachNotification(CoachNotificationID.FollowUpDataChangeWarning);
    } else if (shouldSeeFollowUpDataExport()) {
      pushCoachNotification(CoachNotificationID.FollowUpDataExport);
    }
  }; */

  const sendFollowUpsExportemail = async () => {
    if (user) {
      const flags: CoachFlags = {
        followUpdataExported: true,
      };
      await updateMessageFlag(
        user.uid,
        FirestoreCollection.CoachMessages,
        flags
      );
      await FirebaseAPI.DataExport.sendFollowUpsExportemail(
        childrenWithFilledFollowUp,
        language
      );
    }
  };

  const shouldSeeFollowUpDataChangeWarning = (): boolean => {
    if (
      !checkFeatureFlagVersion(WebviewsFeatureFlag.FollowUpDataChangeWarning)
    ) {
      return false;
    }
    if (
      coachRecords.find(
        (r) => r.id === CoachNotificationID.FirstObservationsComplete
      )
    ) {
      return false;
    }
    return false;
  };

  const shouldSeeFollowUpDataExport = (): boolean => {
    if (!checkFeatureFlagVersion(WebviewsFeatureFlag.FollowUpDataExport)) {
      return false;
    }
    if (
      coachRecords.find(
        (r) => r.id === CoachNotificationID.FirstObservationsComplete
      )
    ) {
      return true;
    }
    return false;
  };

  return {
    coachCanBeDisplayed,
    coachMessage,
    currentCoachMessageDefintion,
    pushCoachNotification,
    onCoachDismissal,
    onRoutineDeactivated,
    onRoutineActivated,
    onTaskCreated,
    onTaskNameChange,
    onObservationsAvailable,
    onObservationsComplete,
    onMedicationAdded,
    onTaskValidationAvailable,
    onTaskValidation,
    onGuideEnter,
    updateCoachNotificationRecord,
    sendFollowUpsExportemail,
    shouldSeeFollowUpDataChangeWarning,
    shouldSeeFollowUpDataExport,
  };
};

export default useCoach;
