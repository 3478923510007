import { Routine } from "@neurosolutionsgroup/models";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { TaskRoutineMap, TasksById, useTasksContext } from "./TasksContext";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import useParameters from "../Parameters/useParameters";
import { Tools } from "@neurosolutionsgroup/tools";

export type RoutinesById = {
  [key: string]: Routine;
};

export interface RoutineContextData {
  routineIds: string[];
  setRoutineIds: Dispatch<SetStateAction<string[]>>;
  routinesById: RoutinesById;
  setRoutinesById: Dispatch<SetStateAction<RoutinesById>>;
  selectedRoutineId: string | undefined;
  setSelectedRoutineId: Dispatch<SetStateAction<string | undefined>>;
  loading: boolean;
  setLoading: (value: boolean) => void;
  routineInitializationComplete: boolean;
  resetRoutine: () => void;
  refreshRoutines: () => Promise<void>;
}

const [useRoutineContext, RoutineContextProvider] =
  Tools.Context.createGenericContext<RoutineContextData>(__filename);

const RoutineProvider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const [loading, setLoading] = useState(false);
  const [routineIds, setRoutineIds] = useState<string[]>([]);
  const [routinesById, setRoutinesById] = useState<RoutinesById>({});
  const [selectedRoutineId, setSelectedRoutineId] = useState<
    string | undefined
  >(undefined);
  const [routineInitializationComplete, setRoutineInitializationComplete] =
    useState(false);

  const { setTasksById, setTaskRoutineMap } = useTasksContext();
  const { addLoadingProgress } = useParameters();

  const refreshRoutines = async (): Promise<void> => {
    const apiRoutines = await FirebaseAPI.Routine.getRoutines();

    // Parse API routine data.
    setRoutineIds(apiRoutines.map((r) => r.id));

    const apiRoutinesById: RoutinesById = {};
    const apiTasksById: TasksById = {};
    const apiTaskRoutineMap: TaskRoutineMap = {};

    apiRoutines.forEach((r) => {
      apiRoutinesById[r.id] = r;
      r.tasks.forEach((t) => {
        apiTasksById[t.id] = t;
        apiTaskRoutineMap[t.id] = r.id;
      });
    });

    setRoutinesById(apiRoutinesById);
    setTasksById(apiTasksById);
    setTaskRoutineMap(apiTaskRoutineMap);
  };

  useEffect(() => {
    const getRoutines = async (): Promise<void> => {
      await refreshRoutines();

      setRoutineInitializationComplete(true);
      addLoadingProgress(20);
    };

    getRoutines();
  }, []);

  const resetRoutine = () => {
    setLoading(false);
    setRoutineIds([]);
    setRoutinesById({});
    setSelectedRoutineId(undefined);
    setRoutineInitializationComplete(false);
  };

  return (
    <RoutineContextProvider
      value={{
        routineIds,
        setRoutineIds,
        routinesById,
        setRoutinesById,
        selectedRoutineId,
        setSelectedRoutineId,
        loading,
        setLoading,
        routineInitializationComplete,
        resetRoutine,
        refreshRoutines,
      }}
    >
      {props.children}
    </RoutineContextProvider>
  );
};

export { useRoutineContext, RoutineProvider };
