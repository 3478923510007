import { Box, Typography } from "@mui/material";

interface FilterTagProps {
  count: number;
  active: boolean;
  color?: string;
}

const FilterTag = ({ count, active, color }: FilterTagProps): JSX.Element => {
  return (
    <Box
      sx={(theme) => ({
        marginLeft: "0.5rem",
        paddingX: "0.5rem",
        backgroundColor: active
          ? "#fff"
          : color ?? theme.palette.secondary.main,
        borderRadius: "999px",
        color: active ? color ?? theme.palette.secondary.main : "#fff",
      })}
    >
      <Typography fontWeight="bold" color="inherit">
        {count}
      </Typography>
    </Box>
  );
};

export default FilterTag;
