import { getAuth } from "firebase/auth";
import {
  ParentSectionOpened,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { Tools } from "@neurosolutionsgroup/tools";
import React, { useEffect, useMemo } from "react";
import { Dispatch, PropsWithChildren, useState } from "react";
import useParameters from "../Parameters/useParameters";
import { CustomWindow } from "custom.window";
import {
  FeatureFlag,
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";

declare let window: CustomWindow;

export enum SelectedHomeSectionState {
  VALIDATION = "validation",
  DASHBOARD = "dashboard",
  JOURNAL = "journal",
}

export enum PageSelectorState {
  HOME = "home",
  MEDICATION = "medication",
  ROUTINES = "routines",
}

export interface NavigationContextData {
  landingPagePath: string;
  menuPageOpen: boolean;
  setMenuPageOpen: Dispatch<boolean>;
  parentSectionOpen: boolean;
  setParentSectionOpen: Dispatch<boolean>;
  onParentSectionBack: VoidFunction;
  templateRedirect?: string;
  setTemplateRedirect: Dispatch<string | undefined>;
}

const [useNavigatonContext, NavigationContextProvider] =
  Tools.Context.createGenericContext<NavigationContextData>(__filename);

const NavigationProvider: React.FC<PropsWithChildren<unknown>> = (props) => {
  const { handleEvent } = useAnalytics();
  const { version } = useParameters();
  const { trackSession } = useAnalytics();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  const [menuPageOpen, setMenuPageOpen] = useState<boolean>(false);
  const [parentSectionOpen, setParentSectionOpen] = useState<boolean>(false);
  const [templateRedirect, setTemplateRedirect] = useState<string>();

  const landingPagePath = useMemo(() => {
    if (checkFeatureFlagVersion(WebviewsFeatureFlag.ShorterFTUE, version)) {
      return "/signup";
    } else {
      return "/intro";
    }
  }, [version]);

  window.applicationResumed = () => {
    const auth = getAuth();
    const userId = auth.currentUser?.uid;

    if (userId !== undefined && parentSectionOpen) {
      if (checkFeatureFlagVersion(FeatureFlag.SessionTracking, version)) {
        trackSession(
          "SessionStart.Parent",
          userId,
          Tools.Time.Dates.getTimeStamp()
        );
      }
    }
  };

  useEffect(() => {
    if (version && parentSectionOpen) {
      const event: ParentSectionOpened = {
        name: "Parent Section Opened",
      };

      handleEvent(event);
    }
  }, [version, parentSectionOpen]);

  const onParentSectionBack = () => {
    setParentSectionOpen(false);
  };

  return (
    <NavigationContextProvider
      value={{
        landingPagePath,
        menuPageOpen,
        setMenuPageOpen,
        parentSectionOpen,
        setParentSectionOpen,
        onParentSectionBack,
        templateRedirect,
        setTemplateRedirect,
      }}
    >
      {props.children}
    </NavigationContextProvider>
  );
};

export { useNavigatonContext, NavigationProvider };
