import { Language } from "@neurosolutionsgroup/models";
import { AnalyticsEvent, AnalyticsType, AppStore, AuthType } from ".";

export interface AnalyticsAccountCreated extends AnalyticsEvent {
  name: "Analytics Account Created";
  eventProperties: {
    source: AnalyticsType;
    ["Email Captured By"]: AnalyticsType;
  };
  setProperties: {
    email?: string;
    language: Language;
  };
  setOnceProperties: {
    ["Email Captured On"]: string;
    ["Email Captured By"]: AnalyticsType;
  };
}

export interface AppLogin extends AnalyticsEvent {
  name: "App Login";
  eventProperties: {
    Type: AuthType;
  };
  setOnceProperties: {
    ["First Login On"]: string;
    ["User ID"]: string;
  };
}

export interface AppRegistration extends AnalyticsEvent {
  name: "App Registration";
  eventProperties: {
    Type: AuthType;
  };
  setProperties: {
    language: Language;
    email?: string;
    name?: string;
  };
  setOnceProperties: {
    ["User ID"]: string;
    ["Email Captured On"]: string;
    ["Email Captured By"]: AnalyticsType;
    ["Account Created On"]: string;
    ["Account Completed On"]: string;
    ["First Time Open On"]: string;
    ["FirstName"]?: string;
  };
}

export interface LandingPageRegistration extends AnalyticsEvent {
  name: "Landing Page Registration";
  eventProperties: {
    language: Language;
    Type: AuthType;
    partner: string;
    program: string;
  };
  setProperties: {
    language: Language;
    email?: string;
    name?: string;
    partner: string;
    program: string;
  };
  setOnceProperties: {
    ["User ID"]: string;
    ["Email Captured On"]: string;
    ["Email Captured By"]: AnalyticsType;
    ["Account Created On"]: string;
    ["Account Created By"]: AnalyticsType;
    ["Account Completed On"]: string;
    ["First Time Open On"]: string;
    ["FirstName"]?: string;
  };
}

export interface AppRegistrationSubmitted extends AnalyticsEvent {
  name: "App Registration Submitted";
}

export interface ThirdPartyAppRegistrationSubmitted extends AnalyticsEvent {
  name: "Third Party Registration Started";
  eventProperties: {
    provider: AuthType;
  };
}

export interface DownloadLinkOpened extends AnalyticsEvent {
  name: "Download Link Opened";
  eventProperties: {
    store: AppStore;
  };
}

export interface EmailConfirmed extends AnalyticsEvent {
  name: "Email Confirmed";
}

export interface FreeTrialStarted extends AnalyticsEvent {
  name: "Free Trial Started";
  setProperties: {
    ["Free Trial Expires On"]: number;
  };
  setOnceProperties: {
    ["Free Trial Started On"]: number;
  };
}

export interface LanguageChanged extends AnalyticsEvent {
  name: "Language Changed";
  eventProperties: {
    language: Language;
  };
}

export interface NotificationsAccepted extends AnalyticsEvent {
  name: "Notifications Accepted";
}

export interface NotificationsLaterClicked extends AnalyticsEvent {
  name: "Notifications Later Clicked";
}

export interface ParentSectionOpened extends AnalyticsEvent {
  name: "Parent Section Opened";
}

export interface PasswordReset extends AnalyticsEvent {
  name: "Password Reset";
}

export interface PasswordResetRequested extends AnalyticsEvent {
  name: "Password Reset Requested";
}

export interface PoliciesAccepted extends AnalyticsEvent {
  name: "Policies Accepted";
}

export interface ProfileInfoUpdated extends AnalyticsEvent {
  name: "Profile Info Updated";
}

export interface SharedReferLink extends AnalyticsEvent {
  name: "Shared Refer Link";
  eventProperties: {
    Method: "link" | "facebook" | "whatsapp" | "twitter" | "email";
  };
}

export interface SubscriptionScreenDisplayed extends AnalyticsEvent {
  name: "Subscription Screen Displayed";
}

interface ABTestRegistered extends AnalyticsEvent {
  name: "A/B Test Registered";
  eventProperties: {
    abKey: string;
  };
}

export const createABTestRegistered = (
  abKey: string,
  userProfileKey: string,
  cohort: string
): ABTestRegistered => {
  return {
    name: "A/B Test Registered",
    eventProperties: {
      abKey,
    },
    setProperties: {
      [userProfileKey]: cohort,
    },
  };
};
