export enum NotificationCategory {
  Routine = "Notif-Routine",
  General = "Notif-General",
  Validation = "Notif-Validation",
  Observation = "Notif-Observation",
  Coach = "Notif-Coach",
  News = "Notif-News",
  Promotion = "Notif-Promotion",
}

export type NotificationCategorySettings = Record<
  NotificationCategory,
  boolean
>;

export interface NotificationsSettings {
  globalOptIn: boolean;
  categories: NotificationCategorySettings;
  optInLastSeen?: number;
}
