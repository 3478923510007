import { Grid } from "@mui/material";
import { PinIconsDefintion } from "@neurosolutionsgroup/models";
import { SetStateAction } from "react";
import IconPinButton, { IconPinButtonPlaceholder } from "./IconPinButton";
import Icons from "../../../Icons";

interface IconPinKeyboardProps {
  setValue: React.Dispatch<SetStateAction<string>>;
  pinIcons: PinIconsDefintion;
}

const IconPinKeyboard = ({
  setValue,
  pinIcons,
}: IconPinKeyboardProps): JSX.Element => {
  const onButtonClick = (value: string) => {
    setValue((current) => (current.length < 4 ? current + value : current));
  };

  const onDeleteClick = () => {
    setValue((current) =>
      current.length === 0 ? current : current.slice(0, -1)
    );
  };

  return (
    <Grid container spacing={2} p={2}>
      {Array.from(Array(9).keys()).map((_, i) => (
        <IconPinButton
          key={i + 1}
          icon={<img src={pinIcons[i + 1]} alt={(i + 1).toFixed(0)} />}
          onClick={() => onButtonClick((i + 1).toFixed(0))}
        />
      ))}
      <IconPinButtonPlaceholder />
      <IconPinButton
        icon={<img src={pinIcons[0]} alt="0" />}
        onClick={() => onButtonClick("0")}
      />
      <IconPinButton
        icon={<Icons.BackSpaceIcon color="#000" />}
        onClick={onDeleteClick}
        last
      />
    </Grid>
  );
};

export default IconPinKeyboard;
