import { Box, Typography } from "@mui/material";
import { ChallengeV2, FirestoreChallenge } from "@neurosolutionsgroup/models";
import React from "react";
import { useTranslation } from "react-i18next";
import ChallengeCard from "./ChallengeCard";
import { Tools } from "@neurosolutionsgroup/tools";
import { ChallengeDisplayCard } from "@neurosolutionsgroup/webviews-pages";
import ChildIcons from "assets/child-icons";
import TaskAssets from "assets/task-icons";
import useChallenges from "common/hooks/challenges/useChallenges";
import useChildren from "common/hooks/children/useChildren";
import useLanguage from "common/hooks/Parameters/useLanguage";

export interface ChallengesForApprovalProps {
  filteredChallengesForApproval: FirestoreChallenge[];
  onChallengeDelete: (c: FirestoreChallenge) => void;
}

const ChallengesForApproval: React.FC<ChallengesForApprovalProps> = ({
  filteredChallengesForApproval,
  onChallengeDelete,
}) => {
  const {
    actions: { approveChallenge },
  } = useChallenges();
  const {
    selectors: { childrenById },
  } = useChildren();
  const { dateLocale, language } = useLanguage();
  const { t } = useTranslation();

  const childList = Object.values(childrenById);

  const onApproval = (
    challenge: FirestoreChallenge,
    approved: boolean,
    message?: string
  ) => {
    approveChallenge(challenge.id, approved, message);
  };

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h2" my={1}>
          {t("routine.challenge.titleApproval")}
        </Typography>
      </Box>
      <Box data-cy="for-approval-challenge-list">
        {filteredChallengesForApproval
          .sort((a, b) => (b.createdDate ?? 0) - (a.createdDate ?? 0))
          .map((challenge) => (
            <Box key={challenge.id} pb={2}>
              {Tools.Data.Challenges.V2.isChallengeV2(challenge) ? (
                <ChallengeDisplayCard
                  challenge={challenge as ChallengeV2}
                  childIcons={ChildIcons}
                  childList={childList}
                  dateLocale={dateLocale}
                  taskIconAssets={TaskAssets.TaskIcons}
                  language={language}
                  showDebug={false}
                  onApproval={(approved, comment) =>
                    onApproval(challenge, approved, comment)
                  }
                  onDelete={() => onChallengeDelete(challenge)}
                  onValidate={() => {}}
                />
              ) : (
                <ChallengeCard
                  challenge={challenge}
                  onDelete={() => onChallengeDelete(challenge)}
                />
              )}
            </Box>
          ))}
      </Box>
    </>
  );
};
export default ChallengesForApproval;
