import { Grid } from "@mui/material";
import { useCoach } from "common/hooks/messaging/Coach";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import React, { useEffect } from "react";
import MedicationGraphCard from "./MedicationGraphCard";
import MedicationReminderCard from "./MedicationReminderCard";

const MedicationDashboard: React.FC = () => {
  const {
    selectors: { getObservationsToDo },
  } = useFollowUp();

  const { onObservationsAvailable } = useCoach();

  useEffect(() => {
    if (getObservationsToDo() && getObservationsToDo().length > 0) {
      onObservationsAvailable();
    }
  }, [getObservationsToDo]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsReminder>
          <MedicationReminderCard />
        </FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Hints.DashboardPrescriptionsReminder>
      </Grid>
      <Grid item xs={12}>
        <MedicationGraphCard />
      </Grid>
    </Grid>
  );
};

export default MedicationDashboard;
