import { Box } from "@mui/material";
import { ChallengeV2 } from "@neurosolutionsgroup/models";
import CurrentChallengeCardDetailsEmptyState from "./CurrentChallengeCardDetailsEmptyState";
import ChallengeDisplayCardHistory from "../../../Components/Challenge/ChallengeDisplayCard/ChallengeDisplayCardHistory";

interface CurrentChallengeCardDetailsProps {
  challenge: ChallengeV2 | null;
  currentChallengeWeek: number;
}

const CurrentChallengeCardDetails = ({
  challenge,
  currentChallengeWeek,
}: CurrentChallengeCardDetailsProps): JSX.Element => {
  return (
    <Box>
      {challenge ? (
        <ChallengeDisplayCardHistory
          challenge={challenge}
          currentChallengeWeek={currentChallengeWeek}
        />
      ) : (
        <CurrentChallengeCardDetailsEmptyState />
      )}
    </Box>
  );
};

export default CurrentChallengeCardDetails;
