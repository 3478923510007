import {
  Language,
  MedicationById,
  Prescription,
  SafeAreas,
} from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import {
  ControlledDrawer,
  DrawerMenuSection,
  Icons,
} from "@neurosolutionsgroup/components";
import { SxProps, Theme, Typography, useTheme } from "@mui/material";

export interface PrescriptionCardMenuProps {
  isOpen: boolean;
  language: Language;
  medicationById: MedicationById;
  onAddSecond: () => void;
  onEdit: (p: Prescription) => void;
  onEnd: () => void;
  prescriptions: Prescription[];
  safeAreas: SafeAreas;
  setIsOpen: (value: boolean) => void;
}

const PrescriptionCardMenu: React.FC<PrescriptionCardMenuProps> = ({
  isOpen,
  language,
  medicationById,
  onAddSecond,
  onEdit,
  onEnd,
  prescriptions,
  safeAreas,
  setIsOpen,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const sectionStyle: SxProps<Theme> = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    svg: {
      height: "1.5rem",
      width: "1.5rem",
    },
  };

  return (
    <ControlledDrawer
      open={isOpen}
      anchor="bottom"
      onClose={() => setIsOpen(false)}
      safeAreas={safeAreas}
    >
      <DrawerMenuSection variant="header">
        <Typography variant="h4">
          {t("medication.prescriptionCard.prescriptionOption.title")}
        </Typography>
      </DrawerMenuSection>
      {prescriptions.map((p) => {
        return medicationById[p.drug.drugId] ? (
          <DrawerMenuSection
            key={p.prescriptionId}
            onClick={() => onEdit(p)}
            sx={sectionStyle}
          >
            <Icons.EditIcon color={palette.secondary.main} />
            <Typography pl={2}>
              {t(
                "medication.prescriptionCard.prescriptionOption.editPrescription",
                {
                  medication: medicationById[p.drug.drugId].name[language],
                }
              )}
            </Typography>
          </DrawerMenuSection>
        ) : null;
      })}
      {prescriptions.length < 2 && (
        <DrawerMenuSection
          onClick={() => {
            onAddSecond();
            setIsOpen(false);
          }}
          sx={sectionStyle}
        >
          <Icons.AddIcon color={palette.secondary.main} />
          <Typography pl={2}>
            {t(
              "medication.prescriptionCard.prescriptionOption.addPrescription"
            )}
          </Typography>
        </DrawerMenuSection>
      )}
      <DrawerMenuSection onClick={() => onEnd()} sx={sectionStyle}>
        <Icons.SaveIcon color={palette.secondary.main} />
        <Typography pl={2}>
          {t("medication.prescriptionCard.prescriptionOption.endPrescription")}
        </Typography>
      </DrawerMenuSection>
    </ControlledDrawer>
  );
};
export default PrescriptionCardMenu;
