import { Box, Drawer, DrawerProps, Typography } from "@mui/material";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import React from "react";
import { useTranslation } from "react-i18next";

export interface ChangeTakeMedicationDrawerProps extends DrawerProps {
  onAnswerSelected: (value: number) => void;
}

const ChangeTakeMedicationDrawer: React.FC<ChangeTakeMedicationDrawerProps> = ({
  onAnswerSelected,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Drawer anchor="bottom" {...props}>
      <DrawerSection variant="header">
        <Typography fontWeight="bold">
          {t("medication.takesMedication.drawer.title")}
        </Typography>
      </DrawerSection>
      <Box px={1} pb={2}>
        <Typography textAlign="center">
          {t("medication.takesMedication.drawer.text")}
        </Typography>
      </Box>
      <DrawerSection
        className="drawer-section--centered"
        onClick={() => onAnswerSelected(1)}
      >
        <Typography>
          {t("medication.takesMedication.drawer.option1")}
        </Typography>
      </DrawerSection>
      <DrawerSection
        className="drawer-section--centered"
        onClick={() => onAnswerSelected(0)}
      >
        <Typography>
          {t("medication.takesMedication.drawer.option2")}
        </Typography>
      </DrawerSection>
      <DrawerSection
        className="drawer-section--centered"
        onClick={() => onAnswerSelected(2)}
      >
        <Typography>
          {t("medication.takesMedication.drawer.option3")}
        </Typography>
      </DrawerSection>
    </Drawer>
  );
};
export default ChangeTakeMedicationDrawer;
