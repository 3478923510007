import MedReminderIcon from "./med_reminder_icon.png";
import TodaysRoutineIcon from "./today_routine_icon.png";
import RoutineCompleteBadge from "./routine_complete_badge.svg";
import ErrorTag from "./error_tag.png";
import FTUEWelcome from "./ftue_welcome_image.svg";
import FTUEEnd from "./ftue_end_image.png";
import FTUEExistingUserIntro from "./ftue_existing_user_intro.png";
import FTUEObservationIntro from "./ftue_observation_intro.png";
import FTUEValidationIntro from "./ftue_validation_intro.png";
import FTUEDashboardIntroStart from "./ftue_dashboard_intro_start.png";
import FTUEDashboardRoutinesEn from "./ftue_dashboard_routines_en.png";
import FTUEDashboardRoutinesFr from "./ftue_dashboard_routines_fr.png";
import FTUEDashboardIntensityEn from "./ftue_dashboard_prescriptions_intensity_en.png";
import FTUEDashboardIntensityFr from "./ftue_dashboard_prescriptions_intensity_fr.png";
import FTUEDashboardFrequencyEn from "./ftue_dashboard_prescriptions_frequency_en.png";
import FTUEDashboardFrequencyFr from "./ftue_dashboard_prescriptions_frequency_fr.png";
import FTUEPrescriptionGraph from "./ftue_prescription_graph.png";
import FTUEPrescriptionGraphENFR from "./ftue_prescription_graph-enfr.png";
import FTUEPrescriptionIntro from "./ftue_prescription_intro.png";
import FTUEJournalIntroFr from "./ftue_journal_intro_fr.png";
import FTUEJournalIntroEn from "./ftue_journal_intro_en.png";
import Arrow from "./arrow.svg";
import ClockIcon from "./clock_icon.svg";
import RoutineCheckIcon from "./routine_check_icon.svg";
import CalendarIcon from "./calendar_icon.svg";
import IntroCoach from "./intro_coach.png";
import IntroGame from "./intro_game.png";
import IntroPrescription from "./intro_prescription.png";
import IntroRoutines from "./intro_routines.png";
import IntroWelcomeEN from "./intro_welcome_en.png";
import IntroWelcomeFR from "./intro_welcome_fr.png";
import IntroValidationChild from "./intro_validation_child.png";
import IntroValidationParent from "./intro_validation_parent.png";
import SubscriptionHeader from "./subscription_header.png";

const MiscAssets = {
  Arrow,
  ErrorTag,
  FTUEWelcome,
  FTUEEnd,
  FTUEExistingUserIntro,
  FTUEObservationIntro,
  FTUEValidationIntro,
  FTUEDashboardIntroStart,
  FTUEDashboardRoutinesEn,
  FTUEDashboardRoutinesFr,
  FTUEDashboardIntensityEn,
  FTUEDashboardIntensityFr,
  FTUEDashboardFrequencyEn,
  FTUEDashboardFrequencyFr,
  FTUEPrescriptionGraph,
  FTUEPrescriptionGraphENFR,
  FTUEPrescriptionIntro,
  FTUEJournalIntroFr,
  FTUEJournalIntroEn,
  IntroCoach,
  IntroGame,
  IntroPrescription,
  IntroRoutines,
  IntroValidationChild,
  IntroValidationParent,
  IntroWelcomeEN,
  IntroWelcomeFR,
  MedReminderIcon,
  TodaysRoutineIcon,
  RoutineCompleteBadge,
  ClockIcon,
  RoutineCheckIcon,
  CalendarIcon,
  SubscriptionHeader,
};

export default MiscAssets;
