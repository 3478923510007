import { needsLoc } from "@neurosolutionsgroup/localization";
import { Language } from "@neurosolutionsgroup/models";

export const sortAlphabetically = (
  skills: string[],
  withOtherAtTheEnd: boolean,
  language: Language
): string[] => {
  const output = skills.sort((a, b) =>
    (needsLoc[language].needs[a] ?? a).localeCompare(
      needsLoc[language].needs[b] ?? b
    )
  );

  const index = skills.findIndex((s) => s === "other");
  if (withOtherAtTheEnd && index >= 0) {
    output.splice(index, 1);
    output.push("other");
  }

  return output;
};
