import {
  FirestoreCollection,
  MessageRecord,
} from "@neurosolutionsgroup/models";
import { doc, getFirestore, updateDoc } from "firebase/firestore";

const updateMessageDoc = async (
  collection: FirestoreCollection,
  userId: string,
  messages: MessageRecord[]
): Promise<void> => {
  const db = getFirestore();

  const docRef = doc(db, collection, userId);

  let updateData = {};

  messages.forEach((message) => {
    if (message.extraData) {
      updateData = {
        ...updateData,
        [`messages.${message.id}.id`]: message.id,
        [`messages.${message.id}.lastSeen`]: message.lastSeen,
        [`messages.${message.id}.extraData`]: message.extraData,
      };
    } else {
      updateData = {
        ...updateData,
        [`messages.${message.id}.id`]: message.id,
        [`messages.${message.id}.lastSeen`]: message.lastSeen,
      };
    }
  });

  await updateDoc(docRef, updateData);
};

export default updateMessageDoc;
