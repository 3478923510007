import { Box, Grid, Typography } from "@mui/material";
import { Tools } from "@neurosolutionsgroup/tools";
import DynamismAssets from "assets/dynamism";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React from "react";
import { useTranslation } from "react-i18next";

interface RoutineValidationEmptyStateProps {
  routinesToValidate: number;
  small: boolean;
}

const RoutineValidationEmptyState = ({
  routinesToValidate,
  small,
}: RoutineValidationEmptyStateProps): JSX.Element => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  return small ? (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "center",
        img: {
          height: "150px",
          maxHeight: "100%",
        },
        height: routinesToValidate !== 0 ? 0 : "150px",
        transitionProperty: "height",
        transitionDelay: "1s",
        transitionDuration: "0s",
      }}
    >
      <img src={DynamismAssets.Routine_Validation} alt="" />
    </Grid>
  ) : (
    <Grid
      item
      xs={12}
      sx={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          "position": "relative",
          "overflow": "hidden",
          "img": {
            width: "100%",
            height: "100%",
            objectFit: "contain",
          },
          "& .validation-empty-state__text": {
            position: "absolute",
            top: "15%",
            right: "0",
            left: "62.5%",
            overflow: "hidden",
          },
          "height": routinesToValidate !== 0 ? 0 : "225px",
          "maxWidth": "450px",
          "transitionProperty": "height",
          "transitionDelay": "1s",
          "transitionDuration": "0s",
        }}
      >
        <Box className="validation-empty-state__text">
          <Typography fontSize="9px" fontWeight="bold" textAlign="left">
            {t("validation.routines.emptyStateImage")}
          </Typography>
        </Box>
        <img
          src={Tools.Language.languageSwitch(language, {
            en: DynamismAssets.ValidationEmptyEN,
            fr: DynamismAssets.ValidationEmptyFR,
            de: DynamismAssets.ValidationEmptyDE,
          })}
          alt=""
        />
      </Box>
    </Grid>
  );
};

export default RoutineValidationEmptyState;
