export type Language = "en" | "fr" | "de";
export type SettingsLanguage = "English" | "French" | "German";

/**
 * A localized string containing translations in english and french.
 */
export interface LocalizedString {
  en: string;
  fr: string;
  de: string;
}

export * from "./LocalizationInfo";
