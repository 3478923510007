import { Grid } from "@mui/material";
import {
  FollowedSideEffect,
  Language,
  SideEffect,
  SideEffectById,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { sub, startOfWeek, endOfWeek } from "date-fns";
import React from "react";
import { useTranslation } from "react-i18next";
import ObservationCard from "./ObservationCard";
import ObservationEmptyState from "./ObservationEmptyState";
import ValidationSection from "../ValidationSection";

export interface ObservationSectionProps {
  showTitle: boolean;
  observationsCount: number;
  observationToDo: FollowedSideEffect[];
  dateLocale: Locale;
  sideEffectById: SideEffectById | undefined;
  language: Language;
  onObservationClick: (sideEffect: SideEffect) => void;
  onObservationSubmit: (
    frequency?: number | undefined,
    intensity?: number | undefined
  ) => Promise<void>;
}

const ObservationSection: React.FC<ObservationSectionProps> = ({
  showTitle,
  observationsCount,
  observationToDo,
  dateLocale,
  sideEffectById,
  language,
  onObservationClick,
}) => {
  const { t } = useTranslation();

  return (
    <ValidationSection
      title={t("validation.observations.title")}
      showTitle={showTitle}
    >
      <Grid container spacing={1}>
        {observationsCount === 0 ? (
          <ObservationEmptyState small={showTitle} />
        ) : null}

        {observationToDo.map((otd, i) => (
          <Grid item xs={12} key={i}>
            <ObservationCard
              date={Tools.Time.Strings.localizedPeriodFromDates(
                sub(startOfWeek(new Date(), { weekStartsOn: 1 }), {
                  weeks: 1,
                }),
                sub(endOfWeek(new Date(), { weekStartsOn: 1 }), {
                  weeks: 1,
                }),
                dateLocale
              )}
              label={
                sideEffectById
                  ? sideEffectById[otd.sideEffectId].name[language]
                  : ""
              }
              onClick={() =>
                sideEffectById &&
                onObservationClick(sideEffectById[otd.sideEffectId])
              }
              ftueCard={i === 0}
            />
          </Grid>
        ))}
      </Grid>
    </ValidationSection>
  );
};
export default ObservationSection;
