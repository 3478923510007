import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const NotificationIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="notification" {...props}>
      <path
        d="m 10,20 c 1.128206,0 2.051282,-0.923077 2.051282,-2.051282 H 7.9487181 C 7.9487181,19.076923 8.8717947,20 10,20 Z m 6.153845,-6.153846 V 8.7179488 c 0,-3.148718 -1.671794,-5.7846154 -4.615384,-6.4820514 V 1.5384615 C 11.538462,0.6871795 10.851282,0 10,0 9.1487179,0 8.4615386,0.6871795 8.4615386,1.5384615 V 2.2358974 C 5.5282052,2.9333334 3.8461539,5.5589743 3.8461539,8.7179488 v 5.1282052 l -2.051282,2.051282 v 1.025641 H 18.205128 v -1.025641 z m -2.051281,1.025641 H 5.8974359 V 8.7179488 c 0,-2.5435898 1.548718,-4.6153847 4.1025641,-4.6153847 2.553846,0 4.102564,2.0717949 4.102564,4.6153847 z"
        fill={color}
      />
    </BaseIcon>
  );
};

export default NotificationIcon;
