import { NotificationsSettings } from "@neurosolutionsgroup/models";
import NotificationCategorySettingsGetterService from "./NotificationSettingsGetter";
import FirebaseAPI from "@neurosolutionsgroup/api-client";

const getSettingsCustomerIO = async (): Promise<NotificationsSettings> => {
  return FirebaseAPI.Analytics.CustomerIO.getSettings();
};

const CustomerIOSettingsGetterAdapter: NotificationCategorySettingsGetterService =
  {
    getSettings: getSettingsCustomerIO,
  };

export default CustomerIOSettingsGetterAdapter;
