import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from "@mui/material";

export interface InputErrorDialogProps extends DialogProps {
  title: string;
  text: JSX.Element;
  onConfirm: {
    text: string;
    action: VoidFunction;
  };
}

const InputErrorDialog = ({
  title,
  text,
  onConfirm,
  ...props
}: InputErrorDialogProps): JSX.Element => {
  return (
    <Dialog {...props}>
      <DialogTitle
        sx={{
          fontSize: "1rem",
          textTransform: "uppercase",
        }}
        color="primary"
      >
        {title}
      </DialogTitle>
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="center">
          <Grid item flexGrow={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={onConfirm.action}
              fullWidth
            >
              {onConfirm.text}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default InputErrorDialog;
