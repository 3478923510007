import { Language, SettingsLanguage } from "@neurosolutionsgroup/models";

const isLanguage = (value: string): value is Language => {
  const knownLanguages = ["en", "fr", "de"];

  return knownLanguages.includes(value);
};

const languageCodeToKnownLanguage = (langCode: string): Language => {
  if (isLanguage(langCode)) {
    return langCode;
  } else {
    return "en";
  }
};

const languageToSettingsLanguage = (language: Language): SettingsLanguage => {
  switch (language) {
    case "en":
      return "English";
    case "fr":
      return "French";
    case "de":
      return "German";
  }
};

const settingsLanguageToLanguageCode = (settingsLanguage: string): Language => {
  switch (settingsLanguage) {
    case "French":
      return "fr";
    case "German":
      return "de";
    case "English":
    default:
      return "en";
  }
};

const languageSwitch = <T>(
  language: Language,
  options: {
    en: T;
    fr?: T;
    de?: T;
  },
  defaultLanguage?: Language
): T => {
  switch (language) {
    case "fr":
      return options.fr ?? options[defaultLanguage ?? "en"] ?? options.en;
    case "de":
      return options.de ?? options[defaultLanguage ?? "en"] ?? options.en;
    case "en":
    default:
      return options.en;
  }
};

const Languages = {
  languageCodeToKnownLanguage,
  languageSwitch,
  languageToSettingsLanguage,
  settingsLanguageToLanguageCode,
};

export default Languages;
