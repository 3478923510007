import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  dialogClasses,
  DialogProps,
  Typography,
} from "@mui/material";
import { ChildSelector } from "@neurosolutionsgroup/components";
import {
  ChildIconsDefintion,
  GamerChild,
  Language,
  Routine,
  RoutineIconsDefinition,
  SafeAreas,
} from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DowngradeDialogRoutinePicker from "./DowngradeDialogRoutinePicker";

interface DowngradeDialogProps extends DialogProps {
  childIcons: ChildIconsDefintion;
  childrenChoices: GamerChild[];
  coachWarningImgSrc: string;
  language: Language;
  loading: boolean;
  onDowngrade: (
    selectedChildId: string | null,
    selectedRoutineId: string | null
  ) => void;
  onUpgrade: VoidFunction;
  routineIcons: RoutineIconsDefinition;
  routines: Routine[];
  safeAreas: SafeAreas;
}

const DowngradeDialog = ({
  childIcons,
  childrenChoices,
  coachWarningImgSrc,
  language,
  loading,
  onDowngrade,
  onUpgrade,
  routineIcons,
  routines,
  safeAreas,
  ...props
}: DowngradeDialogProps): JSX.Element => {
  const { t } = useTranslation();

  const [selectedChild, setSelectedChild] = useState<string | null>(
    childrenChoices.length === 1 ? childrenChoices[0].id : null
  );
  const [selectedRoutine, setSelectedRoutine] = useState<string | null>(null);

  useEffect(() => {
    setSelectedRoutine(null);
  }, [selectedChild]);

  const childName = useMemo(() => {
    return (
      childrenChoices.find((child) => child.id === selectedChild)?.name ?? ""
    );
  }, [selectedChild]);

  const routinesForSelectedChild: Routine[] = useMemo(
    () =>
      selectedChild
        ? routines.filter((r) => r.users.includes(selectedChild) && !r.deleted)
        : [],
    [selectedChild]
  );

  const disabled: boolean = useMemo(() => {
    if (selectedChild === null) {
      return true;
    }

    if (routinesForSelectedChild.length === 0) {
      return false;
    }

    return selectedRoutine === null;
  }, [selectedChild, selectedRoutine, routinesForSelectedChild]);

  const showNoRoutinesText =
    selectedChild !== null && routinesForSelectedChild.length === 0;

  const showTaskCountWarning =
    selectedRoutine !== null &&
    (routines.find((routine) => routine.id === selectedRoutine)?.tasks.length ??
      0) > 5;

  const onConfirm = () => {
    onDowngrade(selectedChild, selectedRoutine);
  };

  return (
    <Dialog
      {...props}
      sx={[
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        {
          [`& .${dialogClasses.paper}`]: {
            background: "#fff",
            padding: 1.5,
            zIndex: 1000,
            ...SharedStyles.Layout.noScroll,
          },
        },
      ]}
    >
      <Typography
        variant="h2"
        textAlign="center"
        textTransform="uppercase"
        mt={1}
      >
        {t("navigation.subscription.downgrade.title")}
      </Typography>

      <Box
        sx={{
          marginY: 1,
          display: "flex",
          justifyContent: "center",
          img: {
            maxWidth: "150px",
            maxHeight: "150px",
          },
        }}
      >
        <img src={coachWarningImgSrc} alt="" />
      </Box>

      <Typography fontWeight="bold" textAlign="center" my={1}>
        {t("navigation.subscription.downgrade.subtitle")}
      </Typography>

      <Typography my={1}>
        {t("navigation.subscription.downgrade.selectChild.label")}
      </Typography>

      <Box mb={2}>
        <ChildSelector
          childList={childrenChoices}
          childIcons={childIcons}
          selectedChildId={selectedChild}
          onChildSelected={setSelectedChild}
          safeAreas={safeAreas}
          variant="light"
          fullWidth
          drawerZIndex={10000}
          placeholder={t(
            "navigation.subscription.downgrade.selectChild.placeholder"
          )}
          label={t("navigation.subscription.downgrade.selectChild.drawerTitle")}
          dataCy="downgrade-child-selector"
        />
      </Box>

      <Typography mt={1}>
        {t("navigation.subscription.downgrade.selectRoutine.label")}
      </Typography>

      {showNoRoutinesText ? (
        <Typography fontSize="0.85rem" fontStyle="italic">
          {t("navigation.subscription.downgrade.selectRoutine.empty")}
        </Typography>
      ) : null}

      <Box my={1}>
        <DowngradeDialogRoutinePicker
          childName={childName}
          disabled={selectedChild === null}
          language={language}
          onChange={setSelectedRoutine}
          routineIcons={routineIcons}
          routines={routinesForSelectedChild}
          safeAreas={safeAreas}
          value={selectedRoutine}
          zIndex={10000}
          data-cy="downgrade-routine-selector"
        />
      </Box>

      {showTaskCountWarning ? (
        <Typography fontSize="0.85rem" fontStyle="italic" mb={2}>
          {t("navigation.subscription.downgrade.selectRoutine.text")}
        </Typography>
      ) : null}

      <Box display="flex" justifyContent={"center"} mt={1}>
        <Button
          onClick={onUpgrade}
          disabled={loading}
          variant="outlined"
          color="success"
          sx={{
            marginRight: 2,
            paddingX: 1,
          }}
        >
          {t("navigation.subscription.downgrade.reactivate")}
        </Button>

        <Button
          onClick={onConfirm}
          variant="contained"
          color="secondary"
          disabled={disabled || loading}
        >
          {loading ? (
            <CircularProgress size="1.5rem" color="inherit" />
          ) : (
            t("general.actions.confirm")
          )}
        </Button>
      </Box>
    </Dialog>
  );
};

export default DowngradeDialog;
