import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const JournalIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="journal" {...props}>
      <path
        d="m 12.132878,7.2425136 c 0,0.6066513 -0.496296,1.1029919 -1.102962,1.1029919 H 1.1029916 C 0.49634628,8.3455047 6.25e-8,7.8491649 6.25e-8,7.2425136 6.25e-8,6.6358696 0.49634628,6.1395294 1.1029916,6.1395294 h 9.9269244 c 0.606666,0 1.102962,0.4963402 1.102962,1.1029842 z M 6.25e-8,2.8305546 c 0,0.6066439 0.4963462175,1.1029916 1.1029915375,1.1029916 h 9.9269244 c 0.606666,0 1.102962,-0.4963477 1.102962,-1.1029916 0,-0.6066462 -0.496296,-1.1029916 -1.102962,-1.1029916 H 1.1029916 C 0.49634628,1.727563 6.25e-8,2.2239084 6.25e-8,2.8305546 Z M 7.720956,11.654517 c 0,-0.606666 -0.4963626,-1.103036 -1.1030139,-1.103036 H 1.1029916 c -0.60664532,0 -1.1029915375,0.49637 -1.1029915375,1.103036 0,0.606592 0.4963462175,1.102962 1.1029915375,1.102962 h 5.5149505 c 0.6066513,0 1.1030139,-0.49637 1.1030139,-1.102962 z m 8.834955,-2.3494051 0.78311,-0.7831256 c 0.430148,-0.4301696 1.125036,-0.4301696 1.555184,0 l 0.783184,0.7831256 c 0.430148,0.4301471 0.430148,1.1250361 0,1.5551831 L 18.894205,11.64348 Z M 15.7728,10.088222 10.081324,15.779698 c -0.099259,0.09926 -0.1543703,0.23163 -0.1543703,0.386 v 1.555258 c 0,0.308814 0.2425933,0.551481 0.5514813,0.551481 h 1.555184 c 0.143407,0 0.286814,-0.05518 0.386073,-0.165482 l 5.691403,-5.691401 z"
        fill={color}
      />
    </BaseIcon>
  );
};

export default JournalIcon;
