import { Box, Grid, SwipeableDrawer, Typography } from "@mui/material";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import AgeGateDigit from "./AgeGateDigit";

interface AgeGateProps {
  onClose: VoidFunction;
  onComplete: VoidFunction;
  os: "android" | "ios";
  show: boolean;
}

const AgeGate: React.FC<AgeGateProps> = ({ onComplete, onClose, os, show }) => {
  const { t } = useTranslation();

  const [year, setYear] = useState("");
  const [focusIndex, setFocusIndex] = useState(0);

  const onChange = (index: number, value: string): void => {
    // Check value is a number.
    if (!isValidDigit(value)) {
      return;
    }
    if (!(year.length === index)) {
      return;
    }

    setYear((current) => current + value);

    if (index !== 3) {
      setFocusIndex(index + 1);
      return;
    }

    const currentYear = new Date().getFullYear();
    const yearNumber = parseInt(year + value);

    if (!isYearValid(yearNumber, currentYear)) {
      onDrawerClose();
      resetAgeGate();
      return;
    }

    if (!isOldEnough(yearNumber, currentYear)) {
      onDrawerClose();
      resetAgeGate();
      return;
    }

    onComplete && onComplete();
    resetAgeGate();
  };

  const isYearValid = (value: number, currentYear: number): boolean => {
    const oldestAcceptedDate = currentYear - 100;
    return value > oldestAcceptedDate && value <= currentYear;
  };

  const isValidDigit = (value: string): boolean => {
    return new RegExp("^[0-9]$").test(value);
  };

  const isOldEnough = (value: number, currentYear: number): boolean => {
    return currentYear - value >= 18;
  };

  const resetAgeGate = () => {
    setYear("");
    setFocusIndex(0);
  };

  const onKeyDelete = () => {
    setYear((current) => (current.length <= 1 ? "" : current.slice(0, -1)));
    setFocusIndex((current) => (current <= 1 ? 0 : current - 1));
  };

  const onFocus = (index: number) => {
    if (index < year.length) {
      setFocusIndex(index);
      setYear((current) => current.slice(0, index));
    } else {
      setFocusIndex(year.length);
    }
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const onDrawerClose = () => {
    setYear("");
    onFocus(0);
    onClose();
  };

  return (
    <SwipeableDrawer
      open={show}
      anchor="bottom"
      onClose={onDrawerClose}
      disableSwipeToOpen={true}
      onOpen={() => undefined}
    >
      <Box mt={4} mb={2} mx={2}>
        <Grid container direction="column" alignItems="center" spacing={1}>
          <Grid item mb={2}>
            <Typography variant="h4">
              {t("navigation.agegate.title")}
            </Typography>
          </Grid>
          {/** Hidden on Play Store for now as it fails review. */}
          {os === "ios" ? (
            <Grid item mb={1} textAlign="center">
              <Typography fontSize="0.8rem">
                {t("navigation.agegate.explication", {
                  store: os === "ios" ? " App Store" : " Google Play Store",
                })}
              </Typography>
            </Grid>
          ) : null}
          <Grid item textAlign="center">
            <Typography fontSize="0.8rem">
              <Trans i18nKey="navigation.agegate.text" />
            </Typography>
          </Grid>
          <Grid item alignSelf="stretch" mt={2}>
            <form onSubmit={(e) => onSubmit(e)}>
              <Grid
                container
                spacing={1}
                justifyContent="space-between"
                width="100%"
                maxWidth="30rem"
                mx="auto"
              >
                {[0, 1, 2, 3].map((i) => (
                  <Grid item key={i}>
                    <AgeGateDigit
                      id={"age-digit-" + i}
                      data-cy={"age-digit-" + i}
                      index={i}
                      value={year}
                      onChange={(v) => onChange(i, v)}
                      focus={i === focusIndex && show}
                      error={false}
                      onDelete={onKeyDelete}
                      onFocus={() => onFocus(i)}
                      autoFocus={i === 0}
                    />
                  </Grid>
                ))}
              </Grid>
              <button type="submit" style={{ visibility: "hidden" }}>
                Submit
              </button>
            </form>
          </Grid>
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};

export default AgeGate;
