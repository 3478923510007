import AlertDialog from "./AlertDialog";
import ConfirmationDialog from "./ConfirmDialog";
import ChallengeEndDialog from "./ChallengeEndDialog";
import InputErrorDialog from "./InputErrorDialog";

const Dialogs = {
  AlertDialog,
  ConfirmationDialog,
  ChallengeEndDialog,
  InputErrorDialog,
};

export default Dialogs;
