import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
} from "@mui/material";

export interface ActionButtonProps {
  "text": string;
  "action": VoidFunction;
  "data-cy"?: string;
  "variant"?: "contained" | "text";
  "color"?: "primary" | "secondary" | "error";
}

export interface AlertDialogProps extends DialogProps {
  title?: string;
  text: JSX.Element;
  negativeAction?: ActionButtonProps;
  positiveAction: ActionButtonProps;
}

const AlertDialog = ({
  title,
  text,
  negativeAction,
  positiveAction,
  ...props
}: AlertDialogProps): JSX.Element => {
  return (
    <Dialog {...props}>
      {title ? (
        <DialogTitle
          sx={{
            fontSize: "1rem",
          }}
        >
          {title}
        </DialogTitle>
      ) : null}
      <DialogContent>{text}</DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="center">
          {negativeAction && (
            <Grid item>
              <Button
                id="negative-button"
                variant={negativeAction.variant ?? "contained"}
                color={negativeAction.color ?? "secondary"}
                onClick={negativeAction.action}
                data-cy={negativeAction["data-cy"]}
              >
                {negativeAction.text}
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button
              id="positive-button"
              variant={positiveAction.variant ?? "text"}
              color={positiveAction.color ?? "error"}
              onClick={positiveAction.action}
              data-cy={positiveAction["data-cy"]}
            >
              {positiveAction.text}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
