import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

export interface CheckMarkIconProps extends IconProps {
  checkStyle: "standard" | "kairos";
}

const CheckMarkIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  checkStyle = "standard",
  ...props
}: CheckMarkIconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="check" {...props}>
      {checkStyle === "standard" ? (
        <path
          d="M 20,3.6399998 7.6,17.787778 0,11.101111 1.4655556,9.445555 7.4,14.652222 l 10.961111,-12.44 z"
          fill={color}
        />
      ) : (
        <path
          d="M 14.9655 4.1913 L 7.2889 11.868 L 3.9303 8.5094 L 2.491 10.4286 L 7.7687 15.7063 L 17.3645 5.1509 Z"
          fill={color}
          stroke={color}
        />
      )}
    </BaseIcon>
  );
};

export default CheckMarkIcon;
