import ArchiveTag from "./archive_tag.png";
import CancelTag from "./cancel_tag.png";
import ChallengeInfoDays from "./challenge_info_days.png";
import ChallengeInfoFrequency from "./challenge_info_frequency.png";
import ChallengeInfoReward from "./challenge_info_reward.png";
import ChallengeInfoSkills from "./challenge_info_skills.png";
import DashboardInfoSkills from "./dashboard_info_skills.png";
import DefaultChildDialog from "./default_child_dialog-fs8.png";
import DeleteTag from "./delete_tag.png";
import ModificationTag from "./modification_tag.png";
import VisitChildSection from "./visit_child_section.png";
import Tag from "./tag.png";

const DialogAssets = {
  ArchiveTag,
  CancelTag,
  ChallengeInfoDays,
  ChallengeInfoFrequency,
  ChallengeInfoReward,
  ChallengeInfoSkills,
  DashboardInfoSkills,
  DefaultChildDialog,
  DeleteTag,
  ModificationTag,
  VisitChildSection,
  Tag,
};

export default DialogAssets;
