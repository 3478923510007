import Dates from "./Dates";
import Days from "./Days";
import Strings from "./Strings";

const Time = {
  Dates,
  Days,
  Strings,
};

export default Time;
