import { Box, Typography, useTheme } from "@mui/material";
import {
  getDoseTimeOfDay,
  Language,
  MedicationById,
  Prescription,
} from "@neurosolutionsgroup/models";
import React from "react";
import { useTranslation } from "react-i18next";
import { Tools } from "@neurosolutionsgroup/tools";
import { Icons } from "@neurosolutionsgroup/components";

export interface PrescriptionDescriptionProps {
  childName: string;
  isAlone: boolean;
  isLast: boolean;
  isOpen: boolean;
  language: Language;
  medicationById: MedicationById;
  prescription: Prescription;
  setSelectedPrescription: (state: string | undefined) => void;
}

const PrescriptionDescription: React.FC<PrescriptionDescriptionProps> = ({
  childName,
  isAlone,
  isLast,
  isOpen,
  language,
  medicationById,
  prescription,
  setSelectedPrescription,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const px = 4;

  return (
    <Box
      onClick={() => {
        setSelectedPrescription(
          !isOpen && !isAlone ? prescription.prescriptionId : undefined
        );
      }}
    >
      <Box
        borderBottom={isOpen ? 1 : 0}
        px={px}
        py={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" flexDirection="row" alignItems="center">
          <Icons.MedicationIcon color="#31737c" />
          <Typography fontWeight="bold" ml={1}>
            {medicationById[prescription.drug.drugId]?.name[language]}
          </Typography>
        </Box>
        {!isAlone ? (
          <Icons.ArrowIcon
            arrowDirection={isOpen ? "up" : "down"}
            color={palette.secondary.main}
            onClick={() => {
              setSelectedPrescription(
                !isOpen ? prescription.prescriptionId : undefined
              );
            }}
          />
        ) : (
          <></>
        )}
      </Box>
      <Box
        borderBottom={1}
        py={2}
        px={px}
        display={isOpen ? "flex" : "none"}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">
          {t("medication.prescriptionCard.startDate")}
        </Typography>
        <Typography fontSize="0.9rem">{prescription.startDate}</Typography>
      </Box>
      <Box
        borderBottom={isLast ? 0 : 1}
        display={isOpen ? "flex" : "none"}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box
          borderRight={1}
          py={1}
          px={px}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Typography variant="h4" mb={0.5}>
            {t("medication.prescriptionCard.moment")}
          </Typography>
          {prescription.drug.doses.map((d) => {
            return (
              <Typography fontSize="0.9rem" key={d.time}>
                {t(getDoseTimeOfDay(d.time))}
              </Typography>
            );
          })}
        </Box>
        <Box
          borderRight={1}
          py={1}
          flexGrow={1}
          display="flex"
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Typography variant="h4" mb={0.5}>
            {t("medication.prescriptionCard.dosage")}
          </Typography>
          {prescription.drug.doses.map((d) => {
            return (
              <Typography fontSize="0.9rem" key={d.time}>
                {d.quantity} {d.units ?? "mg"}
              </Typography>
            );
          })}
        </Box>
        <Box
          display="flex"
          py={1}
          px={px}
          flexDirection="column"
          justifyContent="space-around"
          alignItems="center"
        >
          <Typography variant="h4" mb={0.5}>
            {t("medication.prescriptionCard.hour")}
          </Typography>
          {prescription.drug.doses.map((d) => {
            return (
              <Typography fontSize="0.9rem" key={d.time}>
                {Tools.Time.Strings.localizedTimeFromSeconds(d.time, language)}
              </Typography>
            );
          })}
        </Box>
      </Box>
      <Box display={isAlone ? "flex" : "none"} py={1} px={px} borderTop={1}>
        <Typography fontSize="0.9rem">
          {t(
            `medication.prescriptionCard.takesOtherMedication.${!!prescription.takesOtherMedication}`,
            {
              name: childName,
            }
          )}
        </Typography>
      </Box>
    </Box>
  );
};

export default PrescriptionDescription;
