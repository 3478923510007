import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import { ExpandableCard, Icons, Loader } from "@neurosolutionsgroup/components";
import { Tools } from "@neurosolutionsgroup/tools";
import RoutineTemplateIcons from "assets/routines/templates";
import useRoutines from "common/hooks/routines/useRoutines";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { format } from "date-fns";
import React, {
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import TaskValidation, { LoadingStatus } from "./TaskValidation";
import {
  ValidationTaskHistory,
  ValidationStorage,
} from "pages/Validation/Validation";
import useParameters from "common/hooks/Parameters/useParameters";
import { Language, RoutinesById } from "@neurosolutionsgroup/models";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";

interface RoutineValidationCardProps {
  routineId: string;
  date: Date;
  tasksToValidate: ValidationTaskHistory[];
  ftueCard?: boolean;
  localValidationStatus: ValidationStorage;
  setLocalValidationStatus: React.Dispatch<SetStateAction<ValidationStorage>>;
  onValidation: () => void;
  isLast: boolean;
}

const RoutineValidationCard: React.FC<RoutineValidationCardProps> = ({
  routineId,
  date,
  tasksToValidate,
  ftueCard = false,
  localValidationStatus,
  setLocalValidationStatus,
  onValidation,
  isLast,
}) => {
  const { dateLocale, language } = useLanguage();
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { version } = useParameters();

  const {
    selectors: { routinesById },
  } = useRoutines();

  const [open, setOpen] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.None);
  const [validationDone, setValidationDone] = useState(false);

  useEffect(() => {
    if (loadingStatus !== LoadingStatus.None) {
      setOpen(false);
    }
  }, [loadingStatus]);

  const renderLoading = useCallback(() => {
    const icon = () => {
      switch (loadingStatus) {
        case LoadingStatus.Loading:
          return (
            <Box height="4em" display="flex" alignItems="center">
              <CircularProgress color="secondary" />
            </Box>
          );
        case LoadingStatus.Success:
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                height: "4em",
                svg: {
                  width: "2.5em",
                  height: "2.5em",
                },
              }}
            >
              <Icons.CircledCheckMarkIcon color={palette.secondary.main} />
              <Typography fontSize="0.8rem">
                {t("validation.routines.done")}
              </Typography>
            </Box>
          );
        case LoadingStatus.Error:
          return (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                height: "4em",
                svg: {
                  width: "2.5em",
                  height: "2.5em",
                },
              }}
            >
              <Box>
                <Icons.AlertIcon color={palette.error.main} />
              </Box>
              <Typography color="error" fontSize="0.8rem">
                {t("validation.routines.error")}
              </Typography>
            </Box>
          );
      }
    };

    return (
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {icon()}
      </Box>
    );
  }, [loadingStatus]);

  return (
    <RoutineValidationFTUECard
      ftueCard={ftueCard}
      validationDone={validationDone}
    >
      {version === "1.24.0" && loadingStatus === LoadingStatus.Loading ? (
        <Loader />
      ) : null}
      <ExpandableCard
        open={open}
        setOpen={setOpen}
        summaryComponent={
          loadingStatus === LoadingStatus.None ? (
            <RoutineAccordionSummary
              routinesById={routinesById}
              routineId={routineId}
              date={date}
              dateLocale={dateLocale}
              language={language}
              tasksToValidate={tasksToValidate}
            />
          ) : (
            renderLoading()
          )
        }
        detailsComponent={
          <TaskValidation
            initialHistory={tasksToValidate}
            setLoadingStatus={setLoadingStatus}
            routine={routinesById[routineId]}
            setValidationDone={setValidationDone}
            localValidationStatus={localValidationStatus}
            setLocalValidationStatus={setLocalValidationStatus}
            onValidation={onValidation}
            isLast={isLast}
          />
        }
        data-cy="routine-validation-card"
      />
    </RoutineValidationFTUECard>
  );
};

export default RoutineValidationCard;

interface RoutineValidationCardFTUEProps extends PropsWithChildren {
  ftueCard: boolean;
  validationDone: boolean;
}

const RoutineValidationFTUECard: React.FC<RoutineValidationCardFTUEProps> = ({
  ftueCard,
  validationDone,
  ...props
}) => {
  return ftueCard ? (
    <>
      <FTUEFlowDefinitions.ValidationFTUEFlow.Hints.RoutineValidation>
        <FTUEFlowDefinitions.ValidationFTUEFlow.Hints.TaskValidation
          validationDone={validationDone}
        >
          {props.children}
        </FTUEFlowDefinitions.ValidationFTUEFlow.Hints.TaskValidation>
      </FTUEFlowDefinitions.ValidationFTUEFlow.Hints.RoutineValidation>
    </>
  ) : (
    <>{props.children}</>
  );
};

interface RoutineAccordeonSummaryProps {
  routinesById: RoutinesById;
  routineId: string;
  date: Date;
  dateLocale: Locale;
  language: Language;
  tasksToValidate: ValidationTaskHistory[];
}

const RoutineAccordionSummary: React.FC<RoutineAccordeonSummaryProps> = ({
  routinesById,
  routineId,
  date,
  dateLocale,
  language,
  tasksToValidate,
}) => {
  return (
    <Grid container alignItems="center" spacing={1} flexWrap="nowrap">
      <Grid
        item
        sx={{
          "& img": {
            width: "4em",
            objectFit: "contain",
          },
        }}
        flexGrow={0}
      >
        <img
          src={
            RoutineTemplateIcons[
              (routinesById[routineId]?.icon as 0 | 1 | 2 | 3 | 4 | null) ?? 0
            ]
          }
          alt={`Routine Icon ${
            (routinesById[routineId]?.icon as 0 | 1 | 2 | 3 | 4 | null) ?? 0
          }`}
        />
      </Grid>
      <Grid item flexGrow={1}>
        <Typography>{format(date, "PPP", { locale: dateLocale })}</Typography>
        <Box display="flex">
          <Typography fontWeight="bold">
            {routinesById[routineId].name}
          </Typography>
          <Typography sx={{ marginLeft: "auto", marginTop: "auto" }}>
            {Tools.Time.Strings.localizedTimeFromSeconds(
              routinesById[routineId].start,
              language
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Box
          sx={(theme) => ({
            borderWidth: "1px",
            borderStyle: "solid",
            borderColor: theme.palette.secondary.main,
            borderRadius: "5px",
            paddingX: "0.75em",
            marginRight: "0.5em",
          })}
        >
          <Typography fontWeight="bold">{tasksToValidate.length}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
