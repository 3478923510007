import { Tools } from "@neurosolutionsgroup/tools";
import React from "react";
import { PropsWithChildren, SetStateAction, useState } from "react";

export interface NPSContextData {
  shouldShowInAppReview: boolean;
  setShouldShowInAppReview: React.Dispatch<SetStateAction<boolean>>;
  debugInAppReview: boolean;
  setDebugInAppReview: React.Dispatch<SetStateAction<boolean>>;
  shouldShowPurchaseReview: boolean;
  setShouldShowPurchaseReview: React.Dispatch<SetStateAction<boolean>>;
  debugPurchaseReview: boolean;
  setDebugPurchaseReview: React.Dispatch<SetStateAction<boolean>>;
}

const [useNPSContext, NPSContextProvider] =
  Tools.Context.createGenericContext<NPSContextData>(__filename);

const NPSProvider = (props: PropsWithChildren): JSX.Element => {
  const [shouldShowInAppReview, setShouldShowInAppReview] = useState(false);
  const [debugInAppReview, setDebugInAppReview] = useState(false);
  const [shouldShowPurchaseReview, setShouldShowPurchaseReview] =
    useState(false);
  const [debugPurchaseReview, setDebugPurchaseReview] = useState(false);

  return (
    <NPSContextProvider
      value={{
        shouldShowInAppReview,
        setShouldShowInAppReview,
        debugInAppReview,
        setDebugInAppReview,
        shouldShowPurchaseReview,
        setShouldShowPurchaseReview,
        debugPurchaseReview,
        setDebugPurchaseReview,
      }}
    >
      {props.children}
    </NPSContextProvider>
  );
};

export { useNPSContext, NPSProvider };
