import React, { SetStateAction, useCallback } from "react";
import clsx from "clsx";
import {
  ButtonBase,
  Grid2,
  ListSubheader,
  Typography,
  useTheme,
} from "@mui/material";
import { PremiumLock } from "../../Premium";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { IconManager } from "@neurosolutionsgroup/tools";

export interface TaskIconPickerCategoryProps {
  categoryTitle: string;
  orderedIcons: number[];
  iconManager: IconManager;
  icon: number;
  setIcon: React.Dispatch<SetStateAction<number>>;
  setShowIconDrawer: (show: boolean) => void;
  lockPremiumIcons: boolean;
  onPremiumIconClicked: (icon: number) => void;
  emptyStateImage?: string;
  freeIcons?: number[];
  showEmptyState?: boolean;
  variant?: "default" | "mini" | "challenge";
}

const TaskIconPickerCategory = ({
  categoryTitle,
  orderedIcons,
  iconManager,
  icon,
  setIcon,
  setShowIconDrawer,
  lockPremiumIcons,
  onPremiumIconClicked,
  freeIcons,
}: TaskIconPickerCategoryProps) => {
  const rootClass = "task-icon-picker";

  const { palette } = useTheme();

  const numberOfIcons = orderedIcons.length;

  const canShowIcon = (icon: number): boolean => {
    if (lockPremiumIcons) {
      return true;
    } else {
      return freeIcons ? freeIcons.includes(icon) : true;
    }
  };

  const onIconChoiceClicked = (icon: number) => {
    setShowIconDrawer(false);
    setIcon(icon);
  };

  const onPremiumIconClick = (icon: number) => {
    setShowIconDrawer(false);
    onPremiumIconClicked(icon);
  };

  // Calculate number of "filler" icons to add at the end of the icons grid.
  const getFillerIcons = useCallback((): number[] => {
    const lastRowCount = numberOfIcons % 5; // Assuming 5 icons per row but it works even when the number of icons is not a multiple of 5
    return lastRowCount === 0 ? [] : Array.from(Array(5 - lastRowCount).keys());
  }, [numberOfIcons]);

  return (
    <>
      <ListSubheader sx={{ pb: 0.75 }}>
        <Typography color="text.primary" fontWeight="bold">
          {categoryTitle}
        </Typography>
      </ListSubheader>
      <Grid2
        className={clsx(rootClass + "__icon-drawer-body")}
        container
        spacing={2}
        px={1}
        py={2}
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, 65px)",
          gap: "16px",
          justifyContent: "space-between",
        }}
      >
        {orderedIcons.map((i) => {
          return (
            <Grid2
              key={i}
              sx={{
                flex: "0 0 65px",
              }}
            >
              <PremiumLock
                active={!canShowIcon(i)}
                variant="icon-button"
                borderRadius="10px"
                onClick={() => onPremiumIconClick(i)}
              >
                <ButtonBase
                  data-cy={"selected-icon-button-" + i}
                  sx={[
                    {
                      width: "100%",
                      height: "100%",
                      borderRadius: "10px",
                      borderWidth: "2px",
                      borderStyle: "solid",
                      borderColor: icon === i ? palette.secondary.main : "#fff",
                      boxShadow: SharedStyles.Shadows.defaultShadow,
                      img: {
                        width: "100%",
                        height: "100%",
                      },
                    },
                    (theme) =>
                      theme.applyStyles("dark", {
                        borderColor: icon === i ? "#fff" : "transparent",
                        backgroundColor: palette.background.paper,
                      }),
                  ]}
                  onClick={() => {
                    onIconChoiceClicked(i);
                  }}
                >
                  <img src={iconManager.getIcon(i)} alt={`Task Icon ${i}`} />
                </ButtonBase>
              </PremiumLock>
            </Grid2>
          );
        })}
        {getFillerIcons().map((n) => (
          <Grid2
            key={numberOfIcons + n}
            sx={{
              flex: "0 0 65px",
            }}
          ></Grid2>
        ))}
      </Grid2>
    </>
  );
};

export default TaskIconPickerCategory;
