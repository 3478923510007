import React from "react";
import BaseIcon, { IconProps } from "./BaseIcon";

export interface CheckBoxIconProps extends IconProps {
  isChecked: boolean;
}

const CheckBoxIcon = ({
  onClick,
  isChecked,
  color = "#b7d20f",
  ...props
}: CheckBoxIconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="checkbox" {...props}>
      {isChecked ? (
        <g
          id="g1256"
          transform="matrix(0.68965517,0,0,0.68965517,-0.68965514,-0.34482755)"
        >
          <rect
            x="1"
            y="0.5"
            width="29"
            height="29"
            rx="4.5"
            fill={color}
            stroke="none"
            id="rect1151"
          />
          <path
            d="M 23.967746,5.322553 11.064483,18.225816 5.4193524,12.580685 3,15.806438 11.870984,24.677447 28,6.9354546 Z"
            fill="#ffffff"
            stroke="none"
            id="path1153"
          />
        </g>
      ) : (
        <path
          id="rect824"
          d="M 3.5535703,0.65267704 H 16.44643 c 1.607095,0 2.900893,1.29379836 2.900893,2.90089326 V 16.44643 c 0,1.607095 -1.293798,2.900893 -2.900893,2.900893 H 3.5535703 c -1.6070949,0 -2.90089326,-1.293798 -2.90089326,-2.900893 V 3.5535703 c 0,-1.6070949 1.29379836,-2.90089326 2.90089326,-2.90089326 z"
          fill="#ffffff"
          stroke="#9eabab"
          strokeWidth={1.305}
        />
      )}
    </BaseIcon>
  );
};

export default CheckBoxIcon;
