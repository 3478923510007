import { Box, Button, Grid, Typography } from "@mui/material";
import {
  InformationButton,
  SkillsCard,
  SkillsEmptyStateCard,
} from "@neurosolutionsgroup/components";
import { SkillShortData } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

interface SkillsInShortProps {
  coachInfoIconSrc: string;
  infoImgSrc: string;
  skills: SkillShortData[];
}

const SkillsInShort = ({
  coachInfoIconSrc,
  infoImgSrc,
  skills,
}: SkillsInShortProps): JSX.Element => {
  const { t } = useTranslation();

  const [displayAll, setDisplayAll] = useState(false);

  const orderedSkills = Tools.Data.Challenges.V2.orderSkillShortData(
    skills,
    (skillId: string) => t(`settings.child.needs.needs.${skillId}`)
  );

  const skillsToDisplay =
    !displayAll && orderedSkills.length > 0
      ? orderedSkills.slice(0, 2)
      : orderedSkills;

  const showLoadMore = !displayAll && orderedSkills.length > 2;

  return (
    <Box>
      <Typography fontWeight={700} fontSize="0.9rem">
        {t("dashboard.skills.inshort.title")}
      </Typography>
      <Typography fontSize="0.9rem">
        <i>{t("dashboard.skills.inshort.subtitle")}</i>
        <InformationButton
          infoText={
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              sx={{
                img: {
                  width: "70vw",
                  maxHeight: "200px",
                  objectFit: "contain",
                },
              }}
            >
              <img src={infoImgSrc} alt="" />
              <Typography mt={3} mb={2} mx={1} maxWidth="500px">
                <Trans i18nKey="dashboard.skills.inshort.info.text" />
              </Typography>
            </Box>
          }
          infoTitle={t("dashboard.skills.inshort.info.title")}
          infoIconImage={coachInfoIconSrc}
          coachImage={null}
        />
      </Typography>

      <Box mt={1}>
        {skills.length === 0 ? (
          <SkillsEmptyStateCard />
        ) : (
          <Grid
            container
            spacing={1}
            alignItems="stretch"
            data-cy="skills-dashboard-inshort-container"
          >
            {skillsToDisplay.map((skill) => (
              <Grid item xs={6} key={skill.skillId}>
                <SkillsCard {...skill} sx={{ height: "100%" }} />
              </Grid>
            ))}
          </Grid>
        )}
        {showLoadMore ? (
          <Box display="flex" justifyContent="center" mt={2}>
            <Button color="secondary" onClick={() => setDisplayAll(true)}>
              {t("general.seeAll")}
            </Button>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default SkillsInShort;
