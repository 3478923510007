import useLanguage from "common/hooks/Parameters/useLanguage";
import { useTranslation } from "react-i18next";
import React, { SetStateAction, useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { StickyButtons, TextInput } from "common/Components";
import { TaskOperation } from "../TaskDisplay/TaskDisplay";
import { useCoach } from "common/hooks/messaging/Coach";
import { PageSection } from "common/Components/PageSection";
import CloseablePage from "common/Components/CloseablePage/CloseablePage";
import { Box, ButtonBase, Typography } from "@mui/material";
import InformationButton from "common/Components/Buttons/InformationButton";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import { IconManager, Tools } from "@neurosolutionsgroup/tools";
import {
  NotificationCategory,
  Task,
  TaskCategory,
  prefilledTaskCategoriesMap,
} from "@neurosolutionsgroup/models";
import {
  PremiumFeature,
  TaskCreated,
  TaskNameEdited,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import useNotifications from "common/hooks/notifications/useNotifications";
import NotificationOptInPrompt, {
  NotifOptInPromptOnCloseArgs,
} from "pages/Settings/NotificationSettings/NotificationOptInPrompt";
import { taskCategoryLoc, taskLoc } from "@neurosolutionsgroup/localization";
import CategorySelector from "./CategorySelector/CategorySelector";
import {
  Icons,
  SelectInput,
  SelectOption,
  TaskIconPicker,
} from "@neurosolutionsgroup/components";
import { useFTUE } from "@neurosolutionsgroup/webviews-ftue";
import { SAFE_AREAS } from "stylesheets";
import { freeIcons } from "common/hooks/subscription/SubscriptionContext";
import useSubscription from "common/hooks/subscription/useSubscription";
import useTasks from "common/hooks/routines/useTasks";
import {
  FeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import useParameters from "common/hooks/Parameters/useParameters";

interface TaskEditionProps {
  task: Task;
  operation: TaskOperation;
  setTask: (task: Task) => void;
  routineStart: number;
  routineEnd: number;
  routineId: string;
  open: boolean;
  onClose: VoidFunction;
}

const TaskEdition: React.FC<TaskEditionProps> = ({
  task,
  operation,
  setTask,
  routineStart,
  routineEnd,
  routineId,
  open,
  onClose,
}) => {
  const rootClass = "task-edition";

  const { language } = useLanguage();
  const { t } = useTranslation();
  const { handleEvent } = useAnalytics();
  const { version } = useParameters();
  const { checkFeatureFlag } = useRemoteConfig();
  const { onPremiumFeatureClick, permissions } = useSubscription();

  const { globalOptIn, notificationsSettings, optInLastSeen } =
    useNotifications();
  const {
    selectors: { ftueRunning },
  } = useFTUE();

  const { iconManager } = useTasks().selectors;

  const [icon, setIcon] = useState<number>(0);
  const [nameIndex, setNameIndex] = useState<number>(0);
  const [name, setName] = useState<string>("");
  const [reminder, setReminder] = useState<number>(0);
  const [category, setCategory] = useState<TaskCategory | null>(
    task.category ? (task.category as TaskCategory) : null
  );

  const [modified, setModified] = useState(false);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [categoryError, setCategoryError] = useState(false);

  const [showNotificationOptIn, setShowNotificationOptIn] =
    useState<boolean>(false);

  const { onTaskNameChange } = useCoach();

  /**
   * When base task is changed update values.
   */
  useEffect(() => {
    setIcon(task.icon);
    setName(task.name);
    // This could be done with "personalised" tag on task.
    if (task.icon) {
      setNameIndex(
        Object.values(taskLoc[language][task.icon.toString()]).findIndex(
          (n) => n.text === task.name
        )
      );
    } else {
      setNameIndex(-1);
    }

    if (globalOptIn && notificationsSettings[NotificationCategory.Routine]) {
      setReminder(task.reminder);
    } else {
      setReminder(0);
    }
  }, [task]);

  /**
   * Detect if any changes have been made in form.
   */
  useEffect(() => {
    if (!modified && icon) {
      if (
        icon !== task.icon ||
        name !== task.name ||
        reminder !== task.reminder
      ) {
        setModified(true);
      }
    }
  }, [name, icon, reminder]);

  /**
   * Change name when new name select is changed.
   */
  useEffect(() => {
    if (nameIndex >= 0 && icon) {
      setName(taskLoc[language][icon.toString()][nameIndex].text);
    }
  }, [nameIndex, language]);

  const onPremiumIconClick = (icon: number) => {
    onPremiumFeatureClick(PremiumFeature.TaskIcon, { iconId: icon });
  };

  const categorizedIcons = IconManager.getCategorizedIcons(
    (id) => t(`routine.iconCategories.${id}`),
    checkFeatureFlag(FeatureFlag.IconsV202503, version)
  );

  /**
   * Get options for reminder times to display in select.
   */
  const getReminderOptions = useCallback((): SelectOption<number>[] => {
    const options: SelectOption<number>[] = [
      {
        value: 0,
        label: t("routine.task.inputs.reminder.noreminder"),
      },
    ];

    for (let i = routineStart; i <= routineEnd; i += 900) {
      options.push({
        value: i,
        label: Tools.Time.Strings.localizedTimeFromSeconds(i, language),
      });
    }

    return options;
  }, [language, task, routineStart, routineEnd]);

  /**
   * Get task name options to display in select.
   * Get's all possible names from localisation + a personalised option.
   */
  const getNameOptions = useCallback((): SelectOption<number>[] => {
    const nameOptions: SelectOption<number>[] = [];

    if (icon) {
      Object.values(taskLoc[language][icon.toString()]).forEach((n, i) => {
        nameOptions.push({
          value: i,
          label: n.text,
        });
      });
    }

    return [
      ...nameOptions,
      {
        value: -1,
        label: t("routine.task.inputs.name.custom"),
      },
    ];
  }, [icon, language]);

  /**
   * Get localised name of category of task name chosen.
   */
  const getCategory = useCallback((): string => {
    if (icon) {
      const nameIndexOrDefault = nameIndex === -1 ? 0 : nameIndex;

      return taskCategoryLoc[language][
        prefilledTaskCategoriesMap[icon?.toString()][nameIndexOrDefault]
      ];
    } else {
      return "None";
    }
  }, [icon, nameIndex, language]);

  const onSubmit = () => {
    const categoryRequired = nameIndex === -1;

    if (categoryRequired && category === null) {
      setCategoryError(true);
      return;
    }

    if (operation === TaskOperation.Edit && nameIndex === -1) {
      const event: TaskNameEdited = {
        name: "Task Name Edited",
        eventProperties: {
          "Type": "App",
          "Routine ID": routineId,
          "Custom text added": task.name,
        },
      };
      handleEvent(event);
    } else {
      const event: TaskCreated = {
        name: "Task Created",
        eventProperties: {
          "Type": "App",
          "Routine ID": routineId,
        },
      };
      handleEvent(event);
    }

    setTask({
      ...task,
      icon: icon ?? 0,
      name: name,
      reminder: reminder,
      category:
        nameIndex >= 0
          ? prefilledTaskCategoriesMap[icon ?? 0][nameIndex]
          : category,
    });
  };

  const onCancel = () => {
    onClose();
  };

  const setIconProxy = (action: SetStateAction<number>) => {
    if (action?.valueOf()) {
      // Reset name index when icon changed.
      setNameIndex(0);
      setName(taskLoc[language][action?.valueOf().toString()][0].text);
    }
    return setIcon(action);
  };

  const handleReminderChange = (newValue: number) => {
    if (newValue === null) {
      setReminder(newValue);
      return;
    }
    if (ftueRunning || optInLastSeen) {
      setReminder(newValue);
      return;
    }
    if (!globalOptIn || !notificationsSettings[NotificationCategory.Routine]) {
      setShowNotificationOptIn(true);
    }
    setReminder(newValue);
  };

  const handleOptInClose = (
    notifSettings: NotifOptInPromptOnCloseArgs | undefined
  ) => {
    if (!notifSettings || !notifSettings.childNotif) {
      setReminder(0);
    }
    setShowNotificationOptIn(false);
  };

  return (
    <CloseablePage
      className={rootClass}
      header={
        <>
          <ButtonBase onClick={onCancel} className={rootClass + "__close"}>
            <Icons.CloseIcon color={"#fff"} />
          </ButtonBase>
          <h2 className={clsx(rootClass + "__title")}>
            {operation === TaskOperation.Edit
              ? t("routine.task.title.edit")
              : operation === TaskOperation.Copy
              ? t("routine.task.title.copy")
              : t("routine.task.title.add")}
          </h2>
        </>
      }
      overrideNavHeader
      color="secondary"
      isOpen={open}
      onClose={onCancel}
      withCoach
    >
      {showNotificationOptIn ? (
        <NotificationOptInPrompt
          open={showNotificationOptIn}
          onClose={(value) => handleOptInClose(value)}
        />
      ) : null}
      <div className={clsx(rootClass + "__input", rootClass + "__input--icon")}>
        <TaskIconPicker
          categorizedIcons={categorizedIcons}
          iconManager={iconManager}
          icon={icon}
          setIcon={setIconProxy}
          lockPremiumIcons={permissions.allIcons}
          onPremiumIconClicked={onPremiumIconClick}
          safeAreas={SAFE_AREAS}
          freeIcons={freeIcons}
          showEmptyState={false}
        />
      </div>

      {icon ? (
        <>
          <PageSection
            title={t("routine.task.inputs.name.label")}
            showBottomBorder={false}
          >
            <SelectInput
              data-cy="title-task-select"
              label={t("routine.task.inputs.name.label")}
              options={getNameOptions()}
              value={nameIndex}
              onChange={(o) => o.value && setNameIndex(o.value)}
              classes={{
                root: clsx(
                  rootClass + "__select",
                  rootClass + "__select--name"
                ),
              }}
              truncateOnOverflow
              fullWidth
              safeAreas={SAFE_AREAS}
            />
            {nameIndex === -1 ? (
              <Box mt={2}>
                <TextInput
                  id="task-name"
                  className={rootClass + "__name-input"}
                  value={name}
                  onChange={(e) => setName(e.currentTarget.value)}
                  onBlur={() => onTaskNameChange()}
                  fullWidth
                />
              </Box>
            ) : null}
          </PageSection>
          <PageSection
            title={
              <div className={rootClass + "__category-title"}>
                <h2>{t("routine.task.inputs.name.category")}</h2>
                <InformationButton
                  className={rootClass + "__information"}
                  onClick={() => setInfoDrawerOpen(true)}
                />
              </div>
            }
            showBottomBorder={false}
          >
            {nameIndex === -1 ? (
              <>
                <CategorySelector
                  value={category}
                  onChange={(action) => {
                    setCategoryError(false);
                    setCategory(action);
                  }}
                />
                {categoryError ? (
                  <Typography
                    sx={(theme) => ({
                      textAlign: "left",
                      color: theme.palette.error.main,
                      marginTop: theme.spacing(1),
                      fontSize: "0.9rem",
                    })}
                  >
                    {t("routine.task.inputs.category.error")}
                  </Typography>
                ) : null}
              </>
            ) : (
              <span className={rootClass + "__category"}>{getCategory()}</span>
            )}
          </PageSection>
          <PageSection
            title={t("routine.task.inputs.reminder.label")}
            showBottomBorder={false}
          >
            <SelectInput
              data-cy="notification-task-select"
              label={t("routine.task.inputs.reminder.label")}
              indicator={<Icons.NotificationIcon color="#31737c" />}
              placeholder="Select"
              options={getReminderOptions()}
              value={reminder}
              onChange={(o) => o.value && handleReminderChange(o.value)}
              classes={{
                root: clsx(
                  rootClass + "__select",
                  rootClass + "__select--reminder"
                ),
              }}
              truncateOnOverflow
              fullWidth
              safeAreas={SAFE_AREAS}
            />
          </PageSection>
        </>
      ) : null}
      <InfoDrawer
        open={infoDrawerOpen}
        onClose={() => setInfoDrawerOpen(false)}
        text={t("routine.task.coach.category")}
      />
      <StickyButtons
        data-cy="submit-task"
        onConfirm={onSubmit}
        disabled={!icon || !name}
      />
    </CloseablePage>
  );
};

export default TaskEdition;
