import React from "react";
import { GenericPageProps } from "../genericPageProps";
import AuthPageContainer from "./components/AuthPageContainer";
import { Button, Grid, Typography } from "@mui/material";
import AuthpageHeader from "./components/AuthPageHeader";
import { useTranslation } from "react-i18next";
import AuthIntroSVG from "./components/AuthIntroSVG";

export interface AuthIntroPageProps extends GenericPageProps {
  onSignup: VoidFunction;
  onLogin: VoidFunction;
  open: boolean;
  logoImageString: string;
  productName: string;
}

const AuthIntroPage: React.FC<AuthIntroPageProps> = ({
  onSignup,
  onLogin,
  open,
  safeAreas,
  logoImageString,
  productName,
}) => {
  const { t } = useTranslation();
  return (
    <AuthPageContainer open={open} safeAreas={safeAreas}>
      <Grid item container spacing={4} justifyContent={"center"}>
        <AuthpageHeader
          logoImageString={logoImageString}
          imageAlt={productName}
        />
        <Grid item>
          <Grid
            container
            direction="column"
            spacing={2}
            px={2}
            alignItems={"center"}
          >
            <Grid item textAlign={"center"}>
              <Typography variant="h4">
                {t("auth.signupOrLogin.title")}
              </Typography>
            </Grid>
            <Grid item>
              <AuthIntroSVG />
            </Grid>
            <Grid item textAlign={"center"}>
              <Typography>{t("auth.signupOrLogin.text")}</Typography>
            </Grid>
            <Grid item sx={{ width: "100%" }}>
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={onSignup}
                data-cy="auth-intro-button"
              >
                <Typography color="#fff" textAlign={"center"}>
                  {t("auth.signupOrLogin.buttons.create", {
                    productName: productName,
                  })}
                </Typography>
              </Button>
            </Grid>
            <Grid item onClick={onLogin}>
              <Typography textAlign={"center"} fontWeight={"bold"}>
                {t("auth.signupOrLogin.buttons.login", {
                  productName: productName,
                })}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </AuthPageContainer>
  );
};

export default AuthIntroPage;
