import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const AddIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="add" {...props}>
      <path
        d="M 8.1486839,12.490574 8.5210164,20 11.614215,18.803332 11.731062,12.39618 19.762333,12.184552 19.626879,9.1978496 11.789923,9.1676645 11.946222,0.59719364 7.5293654,0 7.983187,9.1529751 0.94502154,9.1258748 0.237667,12.699055 Z"
        fill={color}
        strokeWidth={0}
      />
    </BaseIcon>
  );
};

export default AddIcon;
