import TaskIcon1 from "./task_icon_1.png";
import TaskIcon2 from "./task_icon_2.png";
import TaskIcon3 from "./task_icon_3.png";
import TaskIcon4 from "./task_icon_4.png";
import TaskIcon5 from "./task_icon_5.png";
import TaskIcon6 from "./task_icon_6.png";
import TaskIcon7 from "./task_icon_7.png";
import TaskIcon8 from "./task_icon_8.png";
import TaskIcon9 from "./task_icon_9.png";
import TaskIcon10 from "./task_icon_10.png";
import TaskIcon11 from "./task_icon_11.png";
import TaskIcon12 from "./task_icon_12.png";
import TaskIcon13 from "./task_icon_13.png";
import TaskIcon14 from "./task_icon_14.png";
import TaskIcon15 from "./task_icon_15.png";
import TaskIcon16 from "./task_icon_16.png";
import TaskIcon17 from "./task_icon_17.png";
import TaskIcon18 from "./task_icon_18.png";
import TaskIcon19 from "./task_icon_19.png";
import TaskIcon20 from "./task_icon_20.png";
import TaskIcon21 from "./task_icon_21.png";
import TaskIcon22 from "./task_icon_22.png";
import TaskIcon23 from "./task_icon_23.png";
import TaskIcon24 from "./task_icon_24.png";
import TaskIcon25 from "./task_icon_25.png";
import TaskIcon26 from "./task_icon_26.png";
import TaskIcon27 from "./task_icon_27.png";
import TaskIcon28 from "./task_icon_28.png";
import TaskIcon29 from "./task_icon_29.png";
import TaskIcon30 from "./task_icon_30.png";
import TaskIcon31 from "./task_icon_31.png";
import TaskIcon32 from "./task_icon_32.png";
import TaskIcon33 from "./task_icon_33.png";
import TaskIcon34 from "./task_icon_34.png";
import TaskIcon35 from "./task_icon_35.png";
import TaskIcon36 from "./task_icon_36.png";
import TaskIcon37 from "./task_icon_37.png";
import TaskIcon38 from "./task_icon_38.png";
import TaskIcon39 from "./task_icon_39.png";
import TaskIcon40 from "./task_icon_40.png";
import TaskIcon41 from "./task_icon_41.png";
import TaskIcon42 from "./task_icon_42.png";
import TaskIcon43 from "./task_icon_43.png";
import TaskIcon44 from "./task_icon_44.png";
import TaskIcon45 from "./task_icon_45.png";
import TaskIcon46 from "./task_icon_46.png";
import TaskIcon47 from "./task_icon_47.png";
import TaskIcon48 from "./task_icon_48.png";
import TaskIcon49 from "./task_icon_49.png";
import TaskIcon50 from "./task_icon_50.png";
import TaskIcon51 from "./task_icon_51.png";
import TaskIcon52 from "./task_icon_52.png";
import TaskIcon53 from "./task_icon_53.png";
import TaskIcon54 from "./task_icon_54.png";
import TaskIcon55 from "./task_icon_55.png";
import TaskIcon56 from "./task_icon_56.png";
import TaskIcon57 from "./task_icon_57.png";
import TaskIcon58 from "./task_icon_58.png";
import TaskIcon59 from "./task_icon_59.png";
import TaskIcon60 from "./task_icon_60.png";
import TaskIcon61 from "./task_icon_61.png";
import TaskIcon62 from "./task_icon_62.png";
import TaskIcon63 from "./task_icon_63.png";
import TaskIcon64 from "./task_icon_64.png";
import TaskIcon65 from "./task_icon_65.png";
import TaskIcon66 from "./task_icon_66.png";
import TaskIcon67 from "./task_icon_67.png";
import TaskIcon68 from "./task_icon_68.png";
import TaskIcon69 from "./task_icon_69.png";
import TaskIcon70 from "./task_icon_70.png";
import TaskIcon71 from "./task_icon_71.png";
import TaskIcon72 from "./task_icon_72.png";
import TaskIcon73 from "./task_icon_73.png";
import TaskIcon74 from "./task_icon_74.png";
import TaskIcon75 from "./task_icon_75.png";
import TaskIcon76 from "./task_icon_76.png";
import TaskIcon77 from "./task_icon_77.png";
import TaskIcon78 from "./task_icon_78.png";
import TaskIcon79 from "./task_icon_79.png";
import TaskIcon80 from "./task_icon_80.png";
import TaskIcon81 from "./task_icon_81.png";
import TaskIcon82 from "./task_icon_82.png";
import TaskIcon83 from "./task_icon_83.png";
import TaskIcon84 from "./task_icon_84.png";
import TaskIcon85 from "./task_icon_85.png";
import TaskIcon86 from "./task_icon_86.png";
import TaskIcon87 from "./task_icon_87.png";
import TaskIcon88 from "./task_icon_88.png";
import TaskIcon89 from "./task_icon_89.png";
import TaskIcon90 from "./task_icon_90.png";
import TaskIcon91 from "./task_icon_91.png";
import TaskIcon92 from "./task_icon_92.png";
import TaskIcon93 from "./task_icon_93.png";
import TaskIcon94 from "./task_icon_94.png";
import TaskIcon95 from "./task_icon_95.png";
import TaskIcon96 from "./task_icon_96.png";
import TaskIcon97 from "./task_icon_97.png";
import TaskIcon98 from "./task_icon_98.png";
import TaskIcon99 from "./task_icon_99.png";
import TaskIcon100 from "./task_icon_100.png";
import TaskIcon101 from "./task_icon_101.png";
import TaskIcon102 from "./task_icon_102.png";
import TaskIcon103 from "./task_icon_103.png";
import TaskIcon104 from "./task_icon_104.png";
import TaskIcon105 from "./task_icon_105.png";
import TaskIcon106 from "./task_icon_106.png";
import TaskIcon107 from "./task_icon_107.png";
import TaskIcon108 from "./task_icon_108.png";
import TaskIcon109 from "./task_icon_109.png";
import TaskIcon110 from "./task_icon_110.png";
import TaskIcon111 from "./task_icon_111.png";
import TaskIcon112 from "./task_icon_112.png";
import TaskIcon113 from "./task_icon_113.png";
import TaskIcon114 from "./task_icon_114.png";
import TaskIcon115 from "./task_icon_115.png";
import TaskIcon116 from "./task_icon_116.png";
import TaskIcon117 from "./task_icon_117.png";
import TaskIcon118 from "./task_icon_118.png";
import TaskIcon119 from "./task_icon_119.png";

const TaskIcons: Record<number, string> = {
  1: TaskIcon1,
  2: TaskIcon2,
  3: TaskIcon3,
  4: TaskIcon4,
  5: TaskIcon5,
  6: TaskIcon6,
  7: TaskIcon7,
  8: TaskIcon8,
  9: TaskIcon9,
  10: TaskIcon10,
  11: TaskIcon11,
  12: TaskIcon12,
  13: TaskIcon13,
  14: TaskIcon14,
  15: TaskIcon15,
  16: TaskIcon16,
  17: TaskIcon17,
  18: TaskIcon18,
  19: TaskIcon19,
  20: TaskIcon20,
  21: TaskIcon21,
  22: TaskIcon22,
  23: TaskIcon23,
  24: TaskIcon24,
  25: TaskIcon25,
  26: TaskIcon26,
  27: TaskIcon27,
  28: TaskIcon28,
  29: TaskIcon29,
  30: TaskIcon30,
  31: TaskIcon31,
  32: TaskIcon32,
  33: TaskIcon33,
  34: TaskIcon34,
  35: TaskIcon35,
  36: TaskIcon36,
  37: TaskIcon37,
  38: TaskIcon38,
  39: TaskIcon39,
  40: TaskIcon40,
  41: TaskIcon41,
  42: TaskIcon42,
  43: TaskIcon43,
  44: TaskIcon44,
  45: TaskIcon45,
  46: TaskIcon46,
  47: TaskIcon47,
  48: TaskIcon48,
  49: TaskIcon49,
  50: TaskIcon50,
  51: TaskIcon51,
  52: TaskIcon52,
  53: TaskIcon53,
  54: TaskIcon54,
  55: TaskIcon55,
  56: TaskIcon56,
  57: TaskIcon57,
  58: TaskIcon58,
  59: TaskIcon59,
  60: TaskIcon60,
  61: TaskIcon61,
  62: TaskIcon62,
  63: TaskIcon63,
  64: TaskIcon64,
  65: TaskIcon65,
  66: TaskIcon66,
  67: TaskIcon67,
  68: TaskIcon68,
  69: TaskIcon69,
  70: TaskIcon70,
  71: TaskIcon71,
  72: TaskIcon72,
  73: TaskIcon73,
  74: TaskIcon74,
  75: TaskIcon75,
  76: TaskIcon76,
  77: TaskIcon77,
  78: TaskIcon78,
  79: TaskIcon79,
  80: TaskIcon80,
  81: TaskIcon81,
  82: TaskIcon82,
  83: TaskIcon83,
  84: TaskIcon84,
  85: TaskIcon85,
  86: TaskIcon86,
  87: TaskIcon87,
  88: TaskIcon88,
  89: TaskIcon89,
  90: TaskIcon90,
  91: TaskIcon91,
  92: TaskIcon92,
  93: TaskIcon93,
  94: TaskIcon94,
  95: TaskIcon95,
  96: TaskIcon96,
  97: TaskIcon97,
  98: TaskIcon98,
  99: TaskIcon99,
  100: TaskIcon100,
  101: TaskIcon101,
  102: TaskIcon102,
  103: TaskIcon103,
  104: TaskIcon104,
  105: TaskIcon105,
  106: TaskIcon106,
  107: TaskIcon107,
  108: TaskIcon108,
  109: TaskIcon109,
  110: TaskIcon110,
  111: TaskIcon111,
  112: TaskIcon112,
  113: TaskIcon113,
  114: TaskIcon114,
  115: TaskIcon115,
  116: TaskIcon116,
  117: TaskIcon117,
  118: TaskIcon118,
  119: TaskIcon119,
};

const TaskAssets = {
  TaskIcons,
};

export default TaskAssets;
