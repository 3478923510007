import { Box, Button } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { CypressProps } from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";

interface EmptyStateButtonProps extends CypressProps {
  imgSrc: string;
  onClick: VoidFunction;
  text: string;
  icon?: JSX.Element;
  imgSize?: string;
}

const EmptyStateButton = ({
  onClick,
  imgSrc,
  text,
  icon,
  imgSize,
  ...props
}: EmptyStateButtonProps): JSX.Element => {
  return (
    <Box
      sx={{
        ...SharedStyles.Layout.flexCenter,
        flexDirection: "column",
        position: "relative",
        zIndex: 0,
        width: "100%",
        height: "50%",
      }}
    >
      <Button
        onClick={onClick}
        color="primary"
        variant="contained"
        sx={{
          "&:before": {
            marginTop: "100px",
            content: "''",
            backgroundImage: `url(${imgSrc})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
            position: "absolute",
            zIndex: -1,
            ...SharedStyles.Layout.square(imgSize ?? "80vw"),
            maxWidth: "450px",
            maxHeight: "300px",
          },
          "& .icon": {
            height: "1.5em",
            width: "1.5em",
            marginRight: "0.5em",
          },
        }}
        data-cy={props["data-cy"]}
      >
        {icon ?? <Icons.AddIcon color="#fff" />}
        {text}
      </Button>
    </Box>
  );
};

export default EmptyStateButton;
