import { ProviderProps, Tools } from "@neurosolutionsgroup/tools";
import { captureException } from "@sentry/react";
import React from "react";
import { useState } from "react";
import {
  buildErrorFromFirebaseError,
  buildErrorFromRequestError,
  ErrorDefinition,
} from "./ErrorDefinition";
import ErrorDialog from "./ErrorDialog";

interface ErrorsData {
  addError: (error: ErrorDefinition) => void;
  handleUnknownError: (err: unknown) => void;
  handleFirebaseError: (err: unknown) => void;
}

const [useErrorsContext, ErrorsContextProvider] =
  Tools.Context.createGenericContext<ErrorsData>(__filename);

const ErrorsProvider: React.FC<ProviderProps> = (props) => {
  const [errorsQueue, setErrorsQueue] = useState<ErrorDefinition[]>([]);

  const addError = (error: ErrorDefinition): void => {
    setErrorsQueue((current) => [...current, error]);
  };

  const handleUnknownError = (err: unknown) => {
    addError(buildErrorFromRequestError(err));
    captureException(err);
  };

  const handleFirebaseError = (err: unknown) => {
    addError(buildErrorFromFirebaseError(err));
    captureException(err);
  };

  const onErrorDismiss = () => {
    setErrorsQueue((current) => {
      const clone = [...current];
      clone.shift();
      return clone;
    });
  };

  return (
    <ErrorsContextProvider
      value={{
        addError,
        handleUnknownError,
        handleFirebaseError,
      }}
    >
      {errorsQueue.length > 0 && (
        <ErrorDialog
          open={errorsQueue.length > 0}
          onClose={onErrorDismiss}
          error={errorsQueue[0]}
        />
      )}
      {props.children}
    </ErrorsContextProvider>
  );
};

export { useErrorsContext, ErrorsProvider };
