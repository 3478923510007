import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { Dialogs } from "@neurosolutionsgroup/components";
import { Prescription } from "@neurosolutionsgroup/models";
import DialogAssets from "assets/dialogs";
import useMedication from "common/hooks/medications/useMedication";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export interface EndPrescriptionDialogProps extends DialogProps {
  prescriptions: Prescription[];
  onConfirm: (prescriptions: string[]) => void;
  onClose: () => void;
}

const EndPrescriptionDialog: React.FC<EndPrescriptionDialogProps> = ({
  prescriptions,
  onConfirm,
  onClose,
  ...props
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const {
    selectors: { medicationById },
  } = useMedication();

  const [selectedPrescriptions, setSelectedPrescriptions] = useState<string[]>(
    []
  );

  const beforeClose = () => {
    setSelectedPrescriptions([]);
    onClose();
  };

  return prescriptions.length === 1 ? (
    <Dialogs.ConfirmationDialog
      {...props}
      title={t("medication.endPrescriptionDialog.title")}
      text={t("medication.endPrescriptionDialog.text")}
      onClose={beforeClose}
      onPositiveAction={() => {
        onConfirm(prescriptions.map((p) => p.prescriptionId));
      }}
      onNegativeAction={beforeClose}
      imgSrc={DialogAssets.CancelTag}
    />
  ) : (
    <Dialog {...props} onClose={beforeClose}>
      <DialogTitle
        sx={{
          fontSize: "1rem",
          maxWidth: "100%",
        }}
      >
        {t("medication.endPrescriptionDialog.title")}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            img: {
              maxWidth: "100px",
            },
          }}
        >
          <img src={DialogAssets.ArchiveTag} alt="" />
          <Typography textAlign="center" fontSize="0.8rem" my={1}>
            {t("medication.endPrescriptionDialog.textMultiple")}
          </Typography>
          {prescriptions.map((p) => {
            return medicationById ? (
              <Button
                key={p.prescriptionId}
                onClick={() => {
                  setSelectedPrescriptions([p.prescriptionId]);
                }}
                variant={
                  selectedPrescriptions.length === 1 &&
                  selectedPrescriptions[0] === p.prescriptionId
                    ? "contained"
                    : "text"
                }
                color="secondary"
                fullWidth
                sx={{
                  marginBottom: "6px",
                }}
              >
                {medicationById[p.drug.drugId].name[language]}
              </Button>
            ) : null;
          })}
          <Button
            onClick={() => {
              setSelectedPrescriptions(
                prescriptions.map((p) => p.prescriptionId)
              );
            }}
            color="secondary"
            variant={
              selectedPrescriptions.length === prescriptions.length
                ? "contained"
                : "text"
            }
            fullWidth
          >
            {t("medication.endPrescriptionDialog.endMultiple.endAll")}
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={2} justifyContent="center">
          <Grid item>
            <Button
              variant={"contained"}
              color={"secondary"}
              onClick={beforeClose}
            >
              {t("general.actions.cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={"text"}
              color={"primary"}
              onClick={() => onConfirm(selectedPrescriptions)}
              disabled={selectedPrescriptions.length === 0}
            >
              {t("general.actions.confirm")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};
export default EndPrescriptionDialog;
