import { Button, Grid, Typography } from "@mui/material";
import CoachAssets from "assets/coach";
import useChildren from "common/hooks/children/useChildren";
import {
  AdviceType,
  VesaActionType,
} from "common/hooks/messaging/validationEmptyStateAdvice/messages";
import useValidationEmptyStateAdvice from "common/hooks/messaging/validationEmptyStateAdvice/useValidationEmptyStateAdvice";
import React, { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

interface ValidationEmptyStateProps {
  totalValidationtoDo: number;
}

const ValidationEmptyState = ({
  totalValidationtoDo,
}: ValidationEmptyStateProps): JSX.Element | null => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    selectors: { selectedChild, childrenById },
  } = useChildren();

  const { latestAdvice, onAdviceShown } = useValidationEmptyStateAdvice();

  useEffect(() => {
    if (totalValidationtoDo === 0) {
      onAdviceShown(latestAdvice.id);
    }
  }, [totalValidationtoDo]);

  const imageSource = useMemo(() => {
    switch (latestAdvice.adviceType) {
      case AdviceType.referenceToParentalGuide:
        return CoachAssets.CoachCategory1;
      case AdviceType.routineAdvice:
        return CoachAssets.CoachCategory1;
      case AdviceType.childPositiveReinforcement:
        return CoachAssets.CoachCategory1;
      case AdviceType.parentPositiveReinforcement:
      default:
        return CoachAssets.CoachCategory1;
    }
  }, [latestAdvice]);

  const onActionClick = () => {
    if (latestAdvice.action) {
      if (
        latestAdvice.action.type === VesaActionType.link &&
        latestAdvice.action.url
      ) {
        navigate(latestAdvice.action.url);
      }
    }
  };

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      mt={2}
      spacing={1}
      flex={1}
    >
      <Grid item>
        <Typography align="center" fontSize="0.9rem" fontWeight="bold">
          {t("validation.empty.message", {
            name: selectedChild ? childrenById[selectedChild]?.name : undefined,
          })}
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          img: {
            width: "100%",
            height: "100%",
            maxHeight: "175px",
            objectFit: "contain",
          },
        }}
      >
        <img
          src={imageSource}
          alt={`validation-advice-${latestAdvice.adviceType}`}
        />
      </Grid>
      <Grid item>
        <Typography align="center" fontSize="0.9rem" fontWeight="bold">
          <Trans
            i18nKey={`coach.validationEmptyStateAdvice.${latestAdvice.id}.text`}
          />
        </Typography>
      </Grid>
      {latestAdvice.action ? (
        <Grid item>
          <Button
            data-cy="vesa-action"
            variant="outlined"
            color="secondary"
            sx={(theme) => ({
              border: "1px solid " + theme.palette.secondary.main,
            })}
            onClick={onActionClick}
          >
            <Trans
              i18nKey={`coach.validationEmptyStateAdvice.${latestAdvice.id}.action`}
            />
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default ValidationEmptyState;
