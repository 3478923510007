import { Box, Button, ButtonProps, useTheme } from "@mui/material";
import Icons from "../../Icons";
import { CypressProps } from "@neurosolutionsgroup/models";

interface SelectInputButtonProps extends ButtonProps, CypressProps {
  hideIndicator?: boolean;
  indicator?: JSX.Element;
  leftIndicator?: JSX.Element;
  truncateOnOverflow?: boolean;
  placeholderStyle?: boolean;
}

const SelectInputButton = ({
  hideIndicator = false,
  indicator,
  leftIndicator,
  truncateOnOverflow = false,
  placeholderStyle,
  sx,
  ...props
}: SelectInputButtonProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <Button
      color="secondary"
      sx={[
        {
          "justifyContent": "space-between",
          "paddingY": 0.5,
          "paddingX": 2,
          "textAlign": "left",
          "& .icon-arrow": {
            width: "1rem",
            height: "1rem",
            minWidth: "1rem",
            minHeight: "1rem",
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {leftIndicator}
      <Box
        mx={leftIndicator ? "auto" : 0}
        pl={leftIndicator ? 1 : 0}
        pr={1}
        sx={[
          {
            ...(truncateOnOverflow
              ? {
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }
              : undefined),
          },
          {
            ...(placeholderStyle
              ? {
                  opacity: 0.5,
                }
              : undefined),
          },
          {
            fontWeight: 400,
          },
        ]}
      >
        {props.children}
      </Box>
      {!hideIndicator &&
        (indicator ?? (
          <Icons.ArrowIcon
            className={"select__indicator-icon"}
            arrowDirection="down"
            color={palette.secondary.main}
          />
        ))}
    </Button>
  );
};

export default SelectInputButton;
