import { Box } from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";
import BackButton, { BackButtonProps } from "./BackButton";

interface FixedBackButtonProps extends BackButtonProps {
  safeAreas: SafeAreas;
}

const FixedBackButton = ({
  safeAreas,
  ...buttonProps
}: FixedBackButtonProps): JSX.Element => {
  return (
    <Box
      sx={{
        position: "fixed",
        top: safeAreas.top ?? 0,
        left: 0,
      }}
    >
      <BackButton {...buttonProps} />
    </Box>
  );
};

export default FixedBackButton;
