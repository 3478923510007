import { Box } from "@mui/material";
import { PropsWithChildren } from "react";
import {
  BackgroundHexagons,
  HexagonPlacement,
} from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";

interface ChildSectionBoxProps extends PropsWithChildren {
  safeAreas: SafeAreas;
}

const ChildSectionBox = ({
  safeAreas,
  ...props
}: ChildSectionBoxProps): JSX.Element => {
  const hexagons: HexagonPlacement[] = [
    { size: 70, rotation: 35, top: -30, right: 30 },
    { size: 150, rotation: 10, top: "15%", left: -30 },
    { size: 70, rotation: 0, top: "30%", right: -5 },
    { size: 70, rotation: 30, top: "50%", left: "30%" },
    { size: 80, rotation: 10, bottom: -30, left: -20 },
    { size: 150, rotation: 10, bottom: -50, right: 20 },
  ];

  return (
    <Box
      sx={(theme) => ({
        position: "absolute",
        top: "34vh",
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: theme.palette.primary.main,
        overflow: "hidden",
        display: "flex",
        justifyContent: "stretch",
        alignItems: "stretch",
        paddingX: 2,
        paddingBottom: `calc(${theme.spacing(1)} + ${safeAreas.bottom})`,
      })}
    >
      <BackgroundHexagons hexagons={hexagons} />
      {props.children}
    </Box>
  );
};

export default ChildSectionBox;
