import { Box, Grid } from "@mui/material";
import ChatAvatar from "./ChatAvatar";
import ChatMessageCard from "./ChatMessageCard";

interface ChatMessageWrapperProps {
  avatarImgSrc: string;
  isUserMessage: boolean;
  message: string | JSX.Element | null;
  showAvatar: boolean;
  highlight?: boolean;
  error?: boolean;
}

const ChatMessageWrapper = ({
  avatarImgSrc,
  isUserMessage,
  message,
  showAvatar,
  highlight = false,
  error,
}: ChatMessageWrapperProps): JSX.Element => {
  return (
    <Grid item display="flex" alignItems="flex-end" xs={12}>
      {showAvatar ? (
        <ChatAvatar imgSrc={avatarImgSrc} size="65px" sx={{ marginRight: 1 }} />
      ) : (
        <Box
          sx={{
            minWidth: isUserMessage ? "85px" : "60px",
            boxSizing: "border-box",
            marginRight: 1,
          }}
        />
      )}
      <ChatMessageCard
        message={message}
        isUserMessage={isUserMessage}
        sx={{
          marginLeft: isUserMessage ? "auto" : undefined,
        }}
        highlight={highlight}
        error={error}
      />
    </Grid>
  );
};

export default ChatMessageWrapper;
