import { Box, Card } from "@mui/material";
import { useTranslation } from "react-i18next";
import TimelineStep from "./TimelineStep";

const TrialTimelineCard = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Card>
      <Box mt={2}>
        <TimelineStep
          title={t("navigation.subscription.screenTwo.timeline.point1.title")}
          text={t("navigation.subscription.screenTwo.timeline.point1.text")}
        />
        <TimelineStep
          title={t("navigation.subscription.screenTwo.timeline.point2.title")}
          text={t("navigation.subscription.screenTwo.timeline.point2.text")}
        />
        <TimelineStep
          title={t("navigation.subscription.screenTwo.timeline.point3.title")}
          text={t("navigation.subscription.screenTwo.timeline.point3.text")}
          isLast
        />
      </Box>
    </Card>
  );
};

export default TrialTimelineCard;
