import { Box, Card, Typography } from "@mui/material";
import { PropsWithChildren } from "react";

interface FormCardProps extends PropsWithChildren {
  title: string;
}

const FormCard = ({ title, ...props }: FormCardProps): JSX.Element => {
  return (
    <Card
      sx={{
        padding: 0,
      }}
    >
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.secondary.main,
          padding: 1.25,
        })}
      >
        <Typography color="#fff" variant="h4">
          {title}
        </Typography>
      </Box>
      {props.children}
    </Card>
  );
};

export default FormCard;
