export enum RoutinePeriod {
  Today,
  ThisWeek,
  LastTwoWeeks,
  ThisMonth,
  LastTwoMonths,
  LastThreeMonths,
  LastSixMonths,
  LastNineMonths,
  LastYear,
}

export type RoutinePeriodLoc = {
  en: {
    [x: number]: string;
  };
  fr: {
    [x: number]: string;
  };
  de: {
    [x: number]: string;
  };
};
