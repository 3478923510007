import {
  Box,
  ButtonBase,
  ButtonBaseProps,
  Typography,
  useTheme,
} from "@mui/material";
import Icons from "../Icons";
import { CypressProps } from "@neurosolutionsgroup/models";

interface EmptyStateAddButtonProps extends ButtonBaseProps, CypressProps {
  text: string | JSX.Element;
}

const EmptyStateAddButton = ({
  text,
  sx,
  ...props
}: EmptyStateAddButtonProps): JSX.Element => {
  const theme = useTheme();

  return (
    <ButtonBase
      sx={[
        {
          width: 1,
          backgroundColor: "#fff",
          borderRadius: "15px",
          boxShadow: "0px 4px 12px 3px rgba(0, 0, 0, 0.15)",
          paddingY: 1,
          paddingX: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          opacity: props.disabled ? 0.4 : 1,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      {...props}
    >
      {typeof text === "string" ? (
        <Typography fontWeight={800}>{text}</Typography>
      ) : (
        text
      )}
      <Box mt={0.5}>
        <Icons.AddIcon color={theme.palette.secondary.main} />
      </Box>
    </ButtonBase>
  );
};

export default EmptyStateAddButton;
