import { Components, Theme } from "@mui/material/styles";

const DEFAULT_BORDER_RADIUS = "15px";

export const components: Components<Theme> = {
  MuiCssBaseline: {
    styleOverrides: {
      ["@global"]: {
        body: {
          ["&::-webkit-scrollbar"]: {
            width: "10px",
          },
          ["&::-webkit-scrollbar-track"]: {
            background: "#fff",
            width: "10px",
            borderRadius: "999px",
          },
          ["&::-webkit-scrollbar-thumb"]: {
            borderRadius: "999px",
            background: "secondary.main",
          },
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        backgroundColor: "#fff",
      },
    },
  },
  MuiBottomNavigation: {
    styleOverrides: {
      root: {
        width: "100vw",
        height: "auto",
        minHeight: "65px",
        backgroundColor: "#dd4f4b",
        position: "absolute",
        bottom: 0,
        left: 0,
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
        display: "flex",
        justifyContent: "space-between",
        overflowX: "hidden",
      },
    },
  },
  MuiBottomNavigationAction: {
    styleOverrides: {
      root: {
        borderTopLeftRadius: "14px",
        borderTopRightRadius: "14px",
        paddingTop: "9px",
        paddingBottom: "6px",
        paddingLeft: "2px",
        paddingRight: "2px",
        color: "#fff",
        minWidth: 0,
        justifyContent: "flex-start",
        ["&.Mui-selected"]: {
          backgroundColor: "#fff",
          color: "#31737c",
          ["& .MuiBottomNavigationAction-label.Mui-selected"]: {
            fontSize: "10px",
          },
        },
        svg: {
          width: "20px",
          height: "20px",
          minWidth: "20px",
          minHeight: "20px",
        },
      },
      label: {
        maxWidth: "100%",
        paddingTop: "0.25em",
        overflowX: "hidden",
        textWrap: "wrap",
        fontSize: "10px",
        fontWeight: 600,
      },
    },
  },
  MuiButton: {
    variants: [
      {
        props: { variant: "text" },
        style: {
          backgroundColor: "#fff",
        },
      },
      {
        props: { variant: "link" },
        style: {
          background: "none",
          textDecoration: "underline",
          boxShadow: "none",
          ["&:hover"]: {
            background: "none",
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: "999px",
        minWidth: "8em",
        textTransform: "none",
        fontWeight: "bold",
        transition: "color .01s",
        boxShadow: "0px 4px 12px 3px rgba(0, 0, 0, 0.15)",
        padding: "6px 1em", // leave room at each end for the rounded corners
      },
      text: {
        "&:hover": {
          backgroundColor: "#fff",
        },
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: "primary.main",
        },
      },
      containedSecondary: {
        "&:hover": {
          backgroundColor: "secondary.main",
        },
      },
      containedSuccess: {
        "&:hover": {
          backgroundColor: "success.main",
        },
      },
      containedError: {
        "&:hover": {
          backgroundColor: "error.main",
        },
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        backgroundColor: "#fff",
        borderRadius: DEFAULT_BORDER_RADIUS,
        padding: "1em",
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: ({ theme }) => ({
        backgroundColor: theme.palette.background.default,
        borderRadius: DEFAULT_BORDER_RADIUS,
        maxWidth: "800px",
        margin: theme.spacing(2),
      }),
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        textAlign: "center",
        margin: "auto",
        fontSize: "1.5rem",
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: "#eee6dc",
      },
    },
  },
  MuiDrawer: {
    styleOverrides: {
      paperAnchorBottom: {
        borderTopLeftRadius: "1em",
        borderTopRightRadius: "1em",
      },
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        justifyContent: "center",
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.secondary.main,
        fontWeight: "bold",
      }),
    },
  },
  MuiLink: {
    defaultProps: {
      color: "secondary",
    },
  },
  MuiMenu: {
    variants: [
      {
        props: { variant: "selectedMenu" },
        style: {
          ["& .MuiPaper-root"]: {
            backgroundColor: "#fff",
            fontWeight: "bold",
            borderRadius: DEFAULT_BORDER_RADIUS,
            marginTop: "0.5em",
          },
        },
      },
    ],
  },
  MuiSelect: {
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          ["& fieldset"]: {
            borderColor: "#fff",
            borderRadius: DEFAULT_BORDER_RADIUS,
          },
        },
      },
    ],
    styleOverrides: {
      select: {
        backgroundColor: "#fff",
        borderRadius: DEFAULT_BORDER_RADIUS,
        border: "none",
        padding: "12px 10px",
      },
    },
  },
  MuiTextField: {
    variants: [
      {
        props: { variant: "outlined" },
        style: {
          outline: "none",
          backgroundColor: "#fff",
          borderColor: "#fff",
          ["& fieldset"]: {
            borderColor: "#fff",
            borderRadius: DEFAULT_BORDER_RADIUS,
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        borderRadius: DEFAULT_BORDER_RADIUS,
        ["& input"]: {
          padding: "12px 10px",
        },
      },
    },
  },
};
