import BaseIcon, { IconProps } from "./BaseIcon";

const BackSpaceIcon = ({ color, ...props }: IconProps): JSX.Element => {
  return (
    <BaseIcon {...props} classModifier="backspace">
      <path
        d="M 8.0763524,13.026153 14.711755,6.9738471 m 0,6.0523059 L 8.5186895,6.9738471 M 17.406155,2.218464 H 8.7810137 c -0.4517614,0 -0.8855388,0.1767707 -1.2086195,0.4925107 L 1.3793378,8.7632805 c -0.69415631,0.6783684 -0.69415631,1.7950875 0,2.4734645 l 6.1930564,6.052306 c 0.3230807,0.315671 0.7568581,0.492485 1.2086195,0.492485 h 8.6251413 c 0.955053,0 1.72923,-0.774176 1.72923,-1.72923 V 3.9476942 c 0,-0.9550279 -0.774177,-1.7292302 -1.72923,-1.7292302 z"
        stroke={color}
        strokeWidth="1.72923"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="path1"
      />
    </BaseIcon>
  );
};

export default BackSpaceIcon;
