import { Box, ButtonBase, useTheme } from "@mui/material";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import Icons from "../../Icons";
import { IconManager } from "@neurosolutionsgroup/tools";

interface TaskIconPickerDisplayMiniProps {
  icon: number;
  iconManager: IconManager;
  onClick: VoidFunction;
}

const TaskIconPickerDisplayMini = ({
  icon,
  iconManager,
  onClick,
}: TaskIconPickerDisplayMiniProps): JSX.Element => {
  const { palette } = useTheme();

  return (
    <ButtonBase
      data-cy="add-icon-button"
      onClick={onClick}
      sx={(theme) => ({
        ...SharedStyles.Layout.square("4em"),
        "borderRadius": "0.6em",
        "zIndex": 0,
        ...SharedStyles.Layout.flexCenter,
        "& .icon-picker__add-container": {
          ...SharedStyles.Layout.square("4em"),
          zIndex: 1,
          borderRadius: "0.6em",
          backgroundColor: icon ? "#fff" : theme.palette.secondary.main,
          ...SharedStyles.Layout.flexCenter,
        },
        "& .icon": {
          ...SharedStyles.Layout.square("3.25em"),
          path: {
            fill: "#fff",
          },
        },
        "& .icon-picker__icon": {
          ...SharedStyles.Layout.square("4em"),
        },
      })}
    >
      <Box className="icon-picker__add-container">
        {icon ? (
          <img
            key={icon}
            className="icon-picker__icon"
            src={iconManager.getIcon(icon)}
            alt={`Task Icon ${icon}`}
          />
        ) : (
          <Icons.AddIcon color={palette.primary.main} />
        )}
      </Box>
    </ButtonBase>
  );
};

export default TaskIconPickerDisplayMini;
