import {
  Button,
  ButtonBase,
  Container,
  Divider,
  Grid2,
  Typography,
} from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";
import React from "react";
import { useTranslation } from "react-i18next";
import {
  FullscreenPage,
  PageContentContainer,
} from "@neurosolutionsgroup/components";

export interface NotificationPageProps {
  notificationImageSrc: string;
  open: boolean;
  onAccept: VoidFunction;
  onRefuse: VoidFunction;
  safeAreas: SafeAreas;
}

const NotificationPage: React.FC<NotificationPageProps> = ({
  notificationImageSrc,
  open,
  onAccept,
  onRefuse,
  safeAreas,
}) => {
  const { t } = useTranslation();

  return open ? (
    <FullscreenPage>
      <PageContentContainer safeAreas={safeAreas}>
        <Container maxWidth="sm">
          <Grid2 container direction="column" spacing={1}>
            <Grid2
              display="flex"
              justifyContent="center"
              sx={{
                img: {
                  maxHeight: "200px",
                  objectFit: "contain",
                },
                marginTop: 4,
                marginBottom: 2,
              }}
            >
              <img src={notificationImageSrc} alt="" />
            </Grid2>
            <Grid2>
              <Typography variant="h3" textAlign="center" mb={2}>
                {t("settings.notificationsPrompt.optIn.title")}
              </Typography>
            </Grid2>
            <Grid2>
              <Typography fontWeight={800} mb={0.5}>
                {t("settings.notificationsPrompt.optIn.texts.first.title")}
              </Typography>
              <Typography>
                {t("settings.notificationsPrompt.optIn.texts.first.text")}
              </Typography>
            </Grid2>

            <Grid2>
              <Divider
                sx={{
                  borderColor: "secondary.main",
                }}
              />
            </Grid2>

            <Grid2>
              <Typography fontWeight={800} mb={0.5}>
                {t("settings.notificationsPrompt.optIn.texts.first.title")}
              </Typography>
              <Typography>
                {t("settings.notificationsPrompt.optIn.texts.first.text")}
              </Typography>
            </Grid2>

            <Grid2>
              <Divider
                sx={{
                  borderColor: "secondary.main",
                }}
              />
            </Grid2>

            <Grid2>
              <Typography>
                <i>{t("settings.notificationsPrompt.optIn.disclaimer")}</i>
              </Typography>
            </Grid2>

            <Grid2>
              <Button
                onClick={onAccept}
                data-cy="accept-notification-button"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{
                  marginTop: 4,
                }}
              >
                {t("settings.notificationsPrompt.optIn.buttons.activate")}
              </Button>
            </Grid2>

            <Grid2>
              <ButtonBase
                onClick={onRefuse}
                data-cy="refuse-notification-button"
                sx={{
                  width: "100%",
                  padding: 1,
                  borderRadius: 999,
                }}
              >
                <Typography
                  textAlign={"center"}
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  <strong>
                    {t("settings.notificationsPrompt.optIn.buttons.no")}
                  </strong>
                </Typography>
              </ButtonBase>
            </Grid2>
          </Grid2>
        </Container>
      </PageContentContainer>
    </FullscreenPage>
  ) : null;
};

export default NotificationPage;
