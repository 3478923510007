import calculateChallengeStartDate from "./calculateChallengeStartDate";
import calculateChallengeWeekIndex from "./calculateChallengeWeekIndex";
import calculateFinishedChallengeStatus from "./calculateFinishedChallengeStatus";
import challengeNeedsValidation from "./challengeNeedsValidation";
import challengeWeekToString from "./challengeWeekToString";
import { coalesceFirestoreChallengeToV2 } from "./coalesceFirestoreChallengeToV2";
import formatShortChallengeData from "./formatShortChallengeData";
import generateMissedChallengeWeekValidations from "./generateMissedChallengeWeekValidations";
import howManyHistoriesCanBeRetiredForCurrentWeek from "./howManyHistoriesCanBeRetiredForCurrentWeek";
import isChallengeComplete from "./isChallengeComplete";
import isChallengeImpossible from "./isChallengeImpossible";
import isChallengeV2 from "./isChallengeV2";
import orderSkillShortData from "./orderSkillShortData";
import transformHistoryToValidations from "./transformHistoryToValidations";

const V2 = {
  calculateChallengeStartDate,
  calculateChallengeWeekIndex,
  calculateFinishedChallengeStatus,
  challengeNeedsValidation,
  challengeWeekToString,
  coalesceFirestoreChallengeToV2,
  formatShortChallengeData,
  generateMissedChallengeWeekValidations,
  howManyHistoriesCanBeRetiredForCurrentWeek,
  isChallengeComplete,
  isChallengeImpossible,
  isChallengeV2,
  orderSkillShortData,
  transformHistoryToValidations,
};

export default V2;
