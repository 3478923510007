import { SideEffect, SideEffectById } from "@neurosolutionsgroup/models";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { useMemo } from "react";
import { HookResult } from "../HookResult";
import { useSideEffectContext } from "./SideEffectContext";

export interface useSideEffectSelector {
  sideEffectIds: string[] | undefined;
  sideEffectById: SideEffectById | undefined;
  sideEffectsSortedAlphabeticlly: SideEffect[];
}

export interface useSideEffectActions {
  setSideEffectIds: (value: string[] | undefined) => void;
  setSideEffectById: (value: SideEffectById | undefined) => void;
}

const useSideEffect = (): HookResult<
  useSideEffectSelector,
  useSideEffectActions
> => {
  const { language } = useLanguage();
  const { sideEffectIds, setSideEffectIds, sideEffectById, setSideEffectById } =
    useSideEffectContext();

  const sideEffectsSortedAlphabeticlly = useMemo(() => {
    const output: SideEffect[] = [];
    if (sideEffectById) {
      Object.values(sideEffectById).forEach((value) => {
        output.push(value);
      });
    }
    return output.sort((a, b) => {
      if (a.name[language] < b.name[language]) {
        return -1;
      }
      if (a.name[language] > b.name[language]) {
        return 1;
      }
      return 0;
    });
  }, [sideEffectById]);

  return {
    selectors: {
      sideEffectIds,
      sideEffectById,
      sideEffectsSortedAlphabeticlly,
    },
    actions: { setSideEffectIds, setSideEffectById },
  };
};

export default useSideEffect;
