import { Routine as RoutineModel } from "@neurosolutionsgroup/models";
import axios from "axios";
import { executeAuthedGet, getHeaders, getURL, handleApiError } from "./utils";

const getRoutines = async (): Promise<RoutineModel[]> => {
  const PATH = "/api/routine";

  return executeAuthedGet(PATH);
};

const updateRoutine = async (routine: RoutineModel): Promise<RoutineModel> => {
  const URL = getURL() + `/api/routine/${routine.id}`;

  const headers = await getHeaders(true, "updateRoutine");

  try {
    const result = await axios.put<RoutineModel>(URL, routine, { headers });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const Routine = {
  getRoutines,
  updateRoutine,
};

export default Routine;
