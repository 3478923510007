import { Grid, Collapse, Box } from "@mui/material";
import React, { SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { TransitionGroup } from "react-transition-group";
import {
  TasksToValidateByDateAndRoutine,
  ValidationStorage,
} from "../../Validation";
import ClearAllValidations from "./ClearAllValidations";
import RoutineEmptyState from "./RoutineEmptyState";
import RoutineValidationCard from "./RoutineValidationCard";
import ValidationSection from "../ValidationSection";

export interface RoutineSectionProps {
  showTitle: boolean;
  validationCount: number;
  tasksToValidateByRoutine: TasksToValidateByDateAndRoutine[];
  localValidationStatus: ValidationStorage;
  setLocalValidationStatus: React.Dispatch<SetStateAction<ValidationStorage>>;
  onRoutineValidated: VoidFunction;
}

const RoutineSection: React.FC<RoutineSectionProps> = ({
  showTitle,
  validationCount,
  tasksToValidateByRoutine,
  localValidationStatus,
  setLocalValidationStatus,
  onRoutineValidated,
}) => {
  const { t } = useTranslation();

  return (
    <ValidationSection
      title={t("validation.routines.title")}
      showTitle={showTitle}
      infodrawerText={t("validation.routines.information")}
    >
      <ClearAllValidations validationsCount={validationCount} />

      <Grid container spacing={1}>
        <RoutineEmptyState
          routinesToValidate={tasksToValidateByRoutine.length}
          small={showTitle}
        />

        <Grid item xs={12}>
          <TransitionGroup component={null}>
            {tasksToValidateByRoutine.map((ttv, i, array) => (
              <Collapse
                key={ttv.date + ttv.routineId}
                timeout={{ enter: 0, exit: 1000 }}
                style={{
                  transitionDelay: "500ms",
                }}
              >
                <Box mb={1}>
                  <RoutineValidationCard
                    routineId={ttv.routineId}
                    date={new Date(parseInt(ttv.date) * 1000)}
                    tasksToValidate={ttv.history}
                    ftueCard={i === 0}
                    localValidationStatus={localValidationStatus}
                    setLocalValidationStatus={setLocalValidationStatus}
                    onValidation={() => onRoutineValidated()}
                    isLast={array.length === 1}
                  />
                </Box>
              </Collapse>
            ))}
          </TransitionGroup>
        </Grid>
      </Grid>
    </ValidationSection>
  );
};
export default RoutineSection;
