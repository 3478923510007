import {
  Box,
  Button,
  ButtonBase,
  Grid,
  Input,
  Typography,
} from "@mui/material";
import { IconPinInput, Icons } from "@neurosolutionsgroup/components";
import PinAssets from "assets/pin-icons";
import { Drawer } from "common/Components";
import useChildren from "common/hooks/children/useChildren";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREA_BOTTOM } from "stylesheets";

interface ChildPinEntryProps {
  onSuccess: VoidFunction;
  onClose: VoidFunction;
  childId?: string;
}

const ChildPinEntry: React.FC<ChildPinEntryProps> = ({
  onClose,
  childId,
  onSuccess,
}) => {
  const { t } = useTranslation();

  const {
    selectors: { childrenById },
  } = useChildren();

  const child = childId ? childrenById[childId] : null;

  const [pin, setPin] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (pin !== "") {
      setError(false);
    }
  }, [pin]);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const patern = new RegExp("^[0-9]*$");
    if (patern.test(newValue)) {
      setPin(newValue);
    }
  };

  const onClear = () => {
    setPin("");
  };

  const onSubmit = () => {
    setPin("");
    if (pin === child?.nip) {
      onSuccess();
    } else {
      setError(true);
    }
  };

  return (
    <Drawer open={!!childId} onClose={onClose} anchor="bottom">
      <Box mt={1} mb={SAFE_AREA_BOTTOM}>
        <Grid container direction="column">
          <Grid item>
            <Typography fontWeight="bold" textAlign="center" mb={2}>
              {t("navigation.childPin.title")}
            </Typography>
          </Grid>
          <Grid item>
            {child?.useImageNip ? (
              <IconPinInput
                value={pin}
                setValue={setPin}
                onConfirm={onSubmit}
                error={error}
                errorMessage={t("navigation.childPin.error")}
                pinIcons={PinAssets.PinIcons}
              />
            ) : (
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                m={1}
              >
                <Box alignSelf="stretch">
                  <Input
                    sx={{
                      border: "1px solid",
                      borderColor: error ? "#ef4145" : "#31737c",
                      borderRadius: "10px",
                      paddingX: "1em",
                      width: "100%",
                      height: "2.5em",
                      borderWidth: "1px",
                      svg: {
                        height: "2.5em",
                        width: "2.5em",
                        path: {
                          opacity: "0.5",
                        },
                      },
                    }}
                    inputProps={{ pattern: "\\d*", inputMode: "numeric" }}
                    autoFocus
                    disableUnderline={true}
                    value={pin}
                    onChange={onChange}
                    endAdornment={
                      pin.length > 0 ? (
                        <ButtonBase onClick={onClear}>
                          <Icons.CloseIcon withCircle color="#31737c" />
                        </ButtonBase>
                      ) : null
                    }
                    fullWidth
                  />
                </Box>
                {error ? (
                  <Typography
                    fontSize="0.9rem"
                    color="error"
                    textAlign="center"
                  >
                    {t("navigation.childPin.error")}
                  </Typography>
                ) : null}
                <Box mt={6}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      onSubmit();
                    }}
                    disabled={pin.length === 0}
                  >
                    {t("general.actions.confirm")}
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item>
            <Typography fontSize="0.8rem" fontWeight="bold" textAlign="center">
              {t("navigation.childPin.forgotten.title")}
            </Typography>
            <Typography fontSize="0.8rem" textAlign="center">
              {t("navigation.childPin.forgotten.text")}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default ChildPinEntry;
