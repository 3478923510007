import React, { SetStateAction, useState, useEffect } from "react";
import clsx from "clsx";
import { Box, List, Typography, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import ControlledDrawer from "../../ControlledDrawer/ControlledDrawer";
import DrawerMenuSection from "../../ControlledDrawer/DrawerMenuSection";
import TaskIconPickerDisplay from "./TaskIconPickerDisplay";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import TaskIconPickerDisplayMini from "./TaskIconPickerDisplayMini";
import TaskIconPickerDisplayChallenge from "./TaskIconPickerDisplayChallenge";
import TaskIconPickerCategory from "./TaskIconPickerCategory";
import { CompleteIconsCategory } from "@neurosolutionsgroup/models";
import { IconManager } from "@neurosolutionsgroup/tools";

export interface TaskIconPickerProps {
  categorizedIcons: CompleteIconsCategory[];
  iconManager: IconManager;
  icon: number;
  setIcon: React.Dispatch<SetStateAction<number>>;
  lockPremiumIcons: boolean;
  onPremiumIconClicked: (icon: number) => void;
  safeAreas: SafeAreas;
  emptyStateImage?: string;
  freeIcons?: number[];
  showEmptyState?: boolean;
  variant?: "default" | "mini" | "challenge";
}

const TaskIconPicker = ({
  categorizedIcons,
  iconManager,
  icon,
  setIcon,
  lockPremiumIcons,
  onPremiumIconClicked,
  safeAreas,
  emptyStateImage,
  freeIcons,
  showEmptyState = true,
  variant = "default",
}: TaskIconPickerProps) => {
  const rootClass = "task-icon-picker";

  const { t } = useTranslation();

  const [showIconDrawer, setShowIconDrawer] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [renderedCategories, setRenderedCategories] = useState<
    CompleteIconsCategory[]
  >([]);

  useEffect(() => {
    if (showIconDrawer) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setRenderedCategories(categorizedIcons);
        setIsLoading(false);
      }, 100);
      return () => {
        clearTimeout(timer);
        setRenderedCategories([]);
        setIsLoading(true);
      };
    }
  }, [showIconDrawer, categorizedIcons]);

  const onIconClick = () => {
    setShowIconDrawer(true);
  };

  const onPremiumIconClick = (icon: number) => {
    setShowIconDrawer(false);
    onPremiumIconClicked(icon);
  };

  const handleVariant = () => {
    switch (variant) {
      case "mini":
        return (
          <TaskIconPickerDisplayMini
            icon={icon}
            iconManager={iconManager}
            onClick={onIconClick}
          />
        );
      case "challenge":
        return (
          <TaskIconPickerDisplayChallenge
            icon={icon}
            iconManager={iconManager}
            onClick={onIconClick}
          />
        );
      default:
        return (
          <>
            <Typography
              fontSize="0.8rem"
              textAlign="center"
              className={rootClass + "__label"}
            >
              {t(
                `routine.task.inputs.icon.label.${icon ? "modify" : "choose"}`
              )}
            </Typography>
            <TaskIconPickerDisplay
              icon={icon}
              iconManager={iconManager}
              onClick={onIconClick}
              showEmptyState={showEmptyState}
              emptyStateImage={emptyStateImage}
            />
          </>
        );
    }
  };

  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className={clsx(rootClass)}
      >
        {handleVariant()}
      </Box>
      <ControlledDrawer
        classes={{ paper: rootClass + "__icon-drawer" }}
        open={showIconDrawer}
        onClose={() => setShowIconDrawer(false)}
        safeAreas={safeAreas}
      >
        <DrawerMenuSection
          variant="header"
          sx={{ height: "auto", pt: 4, pb: 2 }}
        >
          <Typography
            variant="h4"
            className={clsx(rootClass + "__icon-drawer-title")}
          >
            {t("routine.task.inputs.icon.iconDrawer.title")}
          </Typography>
        </DrawerMenuSection>

        <Box
          px={2}
          pb={2}
          sx={{
            overflowY: "auto",
            ...SharedStyles.Layout.noScroll,
          }}
        >
          {isLoading ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight={200}
            >
              <CircularProgress color="secondary" />
            </Box>
          ) : (
            <List
              sx={{
                "padding": 0,
                "& .MuiListSubheader-root": {
                  backgroundColor: "background.paper",
                  borderBottom: "1px solid",
                  borderColor: "divider",
                  position: "sticky",
                  top: "-1px", // fixes awkward gap above the sticky titles
                  zIndex: 1,
                },
              }}
            >
              {renderedCategories.map((category) => (
                <TaskIconPickerCategory
                  key={category.id}
                  categoryTitle={category.text}
                  orderedIcons={category.icons}
                  iconManager={iconManager}
                  icon={icon}
                  setIcon={setIcon}
                  setShowIconDrawer={setShowIconDrawer}
                  lockPremiumIcons={lockPremiumIcons}
                  onPremiumIconClicked={onPremiumIconClick}
                  freeIcons={freeIcons}
                />
              ))}
            </List>
          )}
        </Box>
      </ControlledDrawer>
    </>
  );
};

export default TaskIconPicker;
