import { Typography } from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";
import FtueIntroDialog from "components/FtueIntroDialog";
import { Trans, useTranslation } from "react-i18next";

interface PrescriptionIntroDialogProps {
  imgSrc: string;
  onContinue: VoidFunction;
  onLaterClick: VoidFunction;
  safeAreas: SafeAreas;
}

const PrescriptionIntroDialog = ({
  onContinue,
  onLaterClick,
  safeAreas,
  ...props
}: PrescriptionIntroDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <>
      <FtueIntroDialog
        {...props}
        open={true}
        onLaterClick={onLaterClick}
        title={t("ftue.prescription.intro.title")}
        content={
          <Typography textAlign="center">
            <Trans i18nKey="ftue.prescription.intro.text" />
          </Typography>
        }
        actionText={t("general.understood")}
        dataCy="prescription-ftue-intro"
      />
    </>
  );
};

export default PrescriptionIntroDialog;
