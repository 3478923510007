import { Box, Slider, Typography } from "@mui/material";
import DurationSliderStep from "./DurationSliderStep";
import { Add as AddIcon } from "@mui/icons-material";
import { Remove as RemoveIcon } from "@mui/icons-material";
import { Language } from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface DurationInputProps {
  value: number | null;
  onChange: (newValue: number) => void;
  language: Language;
  min?: number;
  max?: number;
  step?: number;
  dataCy?: string;
}

const DurationInput = ({
  value,
  onChange,
  language,
  min = 0,
  max = 24 * 60,
  step = 15,
  dataCy,
}: DurationInputProps): JSX.Element => {
  const { t } = useTranslation();

  const formattedValue = useMemo(() => {
    if (value === null) {
      return "--";
    }

    return Tools.Time.Strings.localizedDurationFromMinutes(
      value,
      t("general.time.minutesShort"),
      t("general.time.hoursShort")
    );
  }, [value, language]);

  const onStepClick = (add: boolean) => {
    if (value === null) {
      onChange(0);
      return;
    }

    if (add) {
      if (value < max) {
        onChange(value + step);
      }
      return;
    }

    if (value > min) {
      onChange(value - step);
    }
  };

  return (
    <Box display="flex" flexDirection="column" data-cy={dataCy}>
      <Box
        sx={(theme) => ({
          backgroundColor: "#fff",
          borderRadius: "999px",
          borderColor: theme.palette.secondary.main,
          borderWidth: "1px",
          borderStyle: "solid",
          minWidth: "100px",
          padding: 0.5,
          marginX: "auto",
          marginBottom: 1,
        })}
      >
        <Typography textAlign="center">{formattedValue}</Typography>
      </Box>
      <Box display="flex" alignItems="center">
        <DurationSliderStep
          onClick={() => onStepClick(false)}
          disabled={value === null || value <= min}
          dataCy={dataCy + "-minus"}
        >
          <RemoveIcon color="inherit" fontSize="small" />
        </DurationSliderStep>
        <Slider
          value={value === null ? -1 : value}
          onChange={(_, v) => onChange(v as number)}
          min={min}
          max={max}
          step={step}
          color="secondary"
          sx={{
            height: 8,
            marginX: 2,
          }}
          data-cy={dataCy + "-slider"}
        />
        <DurationSliderStep
          onClick={() => onStepClick(true)}
          disabled={value !== null && value >= max}
          dataCy={dataCy + "-plus"}
        >
          <AddIcon color="inherit" fontSize="small" />
        </DurationSliderStep>
      </Box>
    </Box>
  );
};

export default DurationInput;
