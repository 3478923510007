import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
} from "@mui/material";
import { WebviewLink } from "@neurosolutionsgroup/components";
import {
  FTUEFlow,
  useFTUEContext,
  ProgressState,
} from "@neurosolutionsgroup/webviews-ftue";
import useParameters from "common/hooks/Parameters/useParameters";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { useSubscriptionContext } from "common/hooks/subscription/SubscriptionContext";
import React from "react";

const DebugMenu: React.FC = () => {
  const { progressState, setProgressState } = useFTUEContext();

  const { onShowSubscriptionScreen, subscriptionStatus } =
    useSubscriptionContext();

  const { openExternalLinkGeneric } = useParameters();

  const { handleUnknownError } = useErrorsContext();

  const switchFTUEDebug = (flow: FTUEFlow, state: boolean): void => {
    setProgressState((current: ProgressState) => ({
      ...current,
      [flow]: {
        ...current[flow],
        debug: state,
        progress: "none",
      },
    }));
  };

  return (
    <Grid container>
      <Grid item>
        <h3>FTUE</h3>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={progressState[FTUEFlow.FirstRoutine].debug}
                onChange={(e) => {
                  switchFTUEDebug(FTUEFlow.FirstRoutine, e.target.checked);
                }}
              />
            }
            label="First Routine"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={progressState[FTUEFlow.Validation].debug}
                onChange={(e) => {
                  switchFTUEDebug(FTUEFlow.Validation, e.target.checked);
                }}
              />
            }
            label="Validation"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={progressState[FTUEFlow.Observation].debug}
                onChange={(e) => {
                  switchFTUEDebug(FTUEFlow.Observation, e.target.checked);
                }}
              />
            }
            label="Observation"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={progressState[FTUEFlow.Dashboard].debug}
                onChange={(e) => {
                  switchFTUEDebug(FTUEFlow.Dashboard, e.target.checked);
                }}
              />
            }
            label="Dashboard"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={progressState[FTUEFlow.DashboardPrescriptions].debug}
                onChange={(e) => {
                  switchFTUEDebug(
                    FTUEFlow.DashboardPrescriptions,
                    e.target.checked
                  );
                }}
              />
            }
            label="DashboardPrescriptions"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={progressState[FTUEFlow.Journal].debug}
                onChange={(e) => {
                  switchFTUEDebug(FTUEFlow.Journal, e.target.checked);
                }}
              />
            }
            label="Journal"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={progressState[FTUEFlow.Prescriptions].debug}
                onChange={(e) => {
                  switchFTUEDebug(FTUEFlow.Prescriptions, e.target.checked);
                }}
              />
            }
            label="Prescription"
          />
        </FormGroup>

        <h3>Subscription</h3>
        <Button onClick={() => onShowSubscriptionScreen(false)}>
          Show sub screen
        </Button>
        <Button onClick={() => onShowSubscriptionScreen(true)}>
          Show sub screen - no trial
        </Button>

        <p>{JSON.stringify(subscriptionStatus, undefined, 2)}</p>

        <h3>Error</h3>
        <Button onClick={() => handleUnknownError({ message: "Text Error" })}>
          Display Error
        </Button>

        <h3>Test Link</h3>
        <WebviewLink
          onClick={() => {
            openExternalLinkGeneric("https://kairosgame.com/blogues");
          }}
        >
          Kairos Website - Blogs
        </WebviewLink>

        <h3>Safe areas</h3>
        <p>
          Top:{" "}
          {getComputedStyle(document.documentElement).getPropertyValue(
            "--safe-area-top"
          )}
        </p>
        <p>
          Bottom:{" "}
          {getComputedStyle(document.documentElement).getPropertyValue(
            "--safe-area-bottom"
          )}
        </p>
      </Grid>
    </Grid>
  );
};

export default DebugMenu;
