import React, { ChangeEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageSection } from "common/Components/PageSection";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { SettingsInputGroup } from "common/Settings";
import useUserProfile from "common/hooks/account/useUserProfile";
import { Checkbox } from "@mui/material";
import {
  Icons,
  SelectInput,
  SelectOption,
} from "@neurosolutionsgroup/components";
import useParameters from "common/hooks/Parameters/useParameters";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import { Language } from "@neurosolutionsgroup/models";

enum UniWebViewActions {
  ChangeSoundSetting = "changesoundsetting",
  LanguageChanged = "languagechanged",
}

export const GeneralSettings: React.FC = () => {
  const { t } = useTranslation();
  const { language, setLanguage, languageOptions } = useLanguage();

  const { sendMessageToUnity, version } = useParameters();
  const { checkFeatureFlag } = useRemoteConfig();

  const {
    selectors: { userProfile },
    actions: { updateLanguage, updateSoundSetting },
  } = useUserProfile();

  const [soundsEnabled, setSoundsEnabled] = useState<boolean>(
    // App should assume sounds are on if not specified.
    userProfile?.masterSettings?.settings.sounds === false ? false : true
  );

  const shouldShowSoundSettings = useMemo(
    () => !checkFeatureFlag(WebviewsFeatureFlag.SoundSettingsRemoved, version),
    [version]
  );

  const onSoundEnabledChanged = (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.checked;

    updateSoundSetting(value).then(() => {
      setSoundsEnabled(value);
      sendMessageToUnity(
        UniWebViewActions.ChangeSoundSetting,
        "mute=" + !value
      );
    });
  };

  const onLanguageChanged = (option: SelectOption<Language> | null) => {
    if (option === null) {
      // if the user hasn't selected a language,
      // just don't change anything
      return;
    }

    updateLanguage(option.value).then(() => {
      setLanguage(option.value);
      sendMessageToUnity(
        UniWebViewActions.LanguageChanged,
        "lang=" + option.value
      );
    });
  };

  return (
    <PageSection title={t("settings.generalSettings")} showBottomBorder>
      {shouldShowSoundSettings ? (
        <SettingsInputGroup
          labelText={t("settings.sounds")}
          htmlFor="input-sounds"
        >
          <Checkbox
            checked={soundsEnabled}
            onChange={onSoundEnabledChanged}
            icon={<Icons.CheckBoxIcon isChecked={false} />}
            checkedIcon={<Icons.CheckBoxIcon isChecked={true} />}
            sx={{
              "& svg": {
                width: "1.5rem",
                height: "1.5rem",
              },
            }}
          />
        </SettingsInputGroup>
      ) : null}
      <SettingsInputGroup
        labelText={t("settings.language")}
        htmlFor="input-language"
      >
        <SelectInput
          label={t("settings.language")}
          classes={{ root: "settings__select" }}
          id="input-language"
          options={languageOptions}
          value={language}
          onChange={onLanguageChanged}
        />
      </SettingsInputGroup>
    </PageSection>
  );
};
