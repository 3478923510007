import { Challenge, ChallengeHistory } from "@neurosolutionsgroup/models";
import { TFunction } from "react-i18next";

export const calculateChallengeValidationMessageToDisplay = (
  challenge: Challenge,
  childName: string,
  childIsMale: boolean,
  t: TFunction
): string => {
  const challengeComplete = challenge.successful !== null;

  const completeInstances = challenge.history.filter(
    (history) => history.parentStatus === true
  ).length;

  let latestValidations: ChallengeHistory[] = [];

  challenge.history.forEach((history) => {
    if (history.validationDate === null) {
      return;
    }

    if (latestValidations.length === 0) {
      latestValidations.push(history);
      return;
    }

    if (
      (latestValidations[0].validationDate ?? 0) < (history.validationDate ?? 0)
    ) {
      latestValidations = [history];
      return;
    }

    if (history.validationDate === latestValidations[0].validationDate) {
      latestValidations.push(history);
    }
  });

  if (!challengeComplete) {
    if (latestValidations.every((history) => history.parentStatus === false)) {
      return t("validation.challenge.finishedDialog.notDone");
    } else {
      return t(
        childIsMale
          ? "validation.challenge.finishedDialog.incomplete_m"
          : "validation.challenge.finishedDialog.incomplete_f",
        {
          childName,
          count: (challenge.occurrences ?? 1) - completeInstances,
        }
      );
    }
  } else {
    if (challenge.successful) {
      if (challenge.createdByName) {
        return `${t(
          "validation.challenge.finishedDialog.completeProfessional",
          {
            childName,
            professional: challenge.createdByName,
          }
        )} ${t(
          childIsMale
            ? "validation.challenge.finishedDialog.congratulate_m"
            : "validation.challenge.finishedDialog.congratulate_f"
        )}`;
      } else {
        return `${t("validation.challenge.finishedDialog.complete", {
          childName,
        })} ${t(
          childIsMale
            ? "validation.challenge.finishedDialog.congratulate_m"
            : "validation.challenge.finishedDialog.congratulate_f"
        )}`;
      }
    } else {
      if (completeInstances > 0) {
        return `${t(
          challenge.createdByName
            ? "validation.challenge.finishedDialog.partialFailProfessional"
            : "validation.challenge.finishedDialog.partialFail",
          {
            childName,
            count: completeInstances,
            occurrences: challenge.occurrences ?? 1,
            professional: challenge.createdByName,
            challengeName: challenge.title,
          }
        )} ${t(
          childIsMale
            ? "validation.challenge.finishedDialog.discuss_m"
            : "validation.challenge.finishedDialog.discuss_f"
        )}`;
      } else {
        return `${t(
          challenge.createdByName
            ? "validation.challenge.finishedDialog.failProfessional"
            : "validation.challenge.finishedDialog.fail",
          {
            childName,
            professional: challenge.createdByName,
          }
        )} ${t(
          childIsMale
            ? "validation.challenge.finishedDialog.discuss_m"
            : "validation.challenge.finishedDialog.discuss_f"
        )}`;
      }
    }
  }
};
