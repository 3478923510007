import {
  FTUEIntroDialogSeen,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import {
  ChildrenById,
  CoachNotificationRecord,
  FirestoreCollection,
  MessageRecord,
  MessagingDocument,
  RoutinesById,
  TasksToValidate,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import { Unsubscribe } from "firebase/auth";
import { doc, getFirestore, onSnapshot } from "firebase/firestore";
import { FTUEFlowDefinitions } from "flows";
import { DashboardProgress } from "flows/Dashboard";
import { DashboardPrescriptionsProgress } from "flows/DashboardPrescription";
import { FirstRoutineProgress } from "flows/FirstRoutine";
import { JournalProgress } from "flows/Journal";
import { ObservationProgress } from "flows/Observation";
import { PrescriptionsProgress } from "flows/Prescription";
import { ValidationProgress } from "flows/Validation";
import {
  FTUEAssets,
  FTUEFlow,
  FlowState,
  ProgressState,
  ftueStartOfFTUEDialog,
} from "models";
import { PropsWithChildren, SetStateAction, useEffect, useState } from "react";
import {
  createMessageDoc,
  isFtueRecord,
  migrateOldMessageRecords,
  updateMessageDoc,
} from "tools";

export interface FTUEContextData {
  ftueRunning: boolean;
  firstPageFTUEIsRunning: boolean;
  blockFTUE: boolean;
  setBlockFTUE: React.Dispatch<SetStateAction<boolean>>;
  template?: string;
  setTemplate: React.Dispatch<SetStateAction<string | undefined>>;
  progressState: ProgressState;
  setProgressState: React.Dispatch<SetStateAction<ProgressState>>;
  initialSetupFinished: boolean;
  ftueRecords: MessageRecord[];
  updateFTUERecord: (
    ftueId: string,
    extraData?: Record<string, string | number | boolean>
  ) => Promise<void>;
  assets: FTUEAssets;
  shouldShowIntroFTUE: boolean;
  setShouldShowIntroFTUE: (value: boolean) => void;
}

const [useFTUEContext, FTUEContextProvider] =
  Tools.Context.createGenericContext<FTUEContextData>("FTUEContext");

interface FTUEProviderProps extends PropsWithChildren {
  authProps: {
    userId?: string;
    tenantId?: string;
  };
  childProps: {
    childInitilizationComplete: boolean;
    selectedChild: string | null;
    childrenById: ChildrenById;
    tasksToValidate: TasksToValidate;
    childIds: string[];
  };
  medicalProps: {
    followUpInitializationComplete: boolean;
    hasRecords: boolean;
    observationsToDoCount: number;
    userHasPrescription: boolean;
  };
  profileProps: {
    profileInitializationComplete: boolean;
    coachNotifications: CoachNotificationRecord[];
  };
  routineProps: {
    routineInitializationComplete: boolean;
    routinesById: RoutinesById;
  };
  ftueAssets: FTUEAssets;
  active?: boolean;
}

const FTUEProvider = ({
  authProps: { userId, tenantId },
  childProps: {
    childInitilizationComplete,
    selectedChild,
    childrenById,
    tasksToValidate,
    childIds,
  },
  medicalProps: {
    followUpInitializationComplete,
    hasRecords,
    observationsToDoCount,
    userHasPrescription,
  },
  profileProps: { profileInitializationComplete, coachNotifications },
  routineProps: { routineInitializationComplete, routinesById },
  active = true,
  ftueAssets,
  ...props
}: FTUEProviderProps) => {
  const { handleEvent } = useAnalytics();

  // General.
  const [ftueRunning, setFTUERunning] = useState(true);
  const [initialSetupFinished, setInitialSetupFinished] = useState(false);
  const [blockFTUE, setBlockFTUE] = useState(false);
  const [firstPageFTUEIsRunning, setFirstPageFTUEIsRunning] = useState(true);
  const [ftueInitializationComplete, setFtueInitializationComplete] =
    useState(false);
  const [ftueRecords, setFtueRecords] = useState<MessageRecord[]>([]);
  const [shouldShowIntroFTUE, setShouldShowIntroFTUE] =
    useState<boolean>(false);

  // Flows.
  const emptyFTUEState: ProgressState = {
    [FTUEFlow.Dashboard]: {
      complete: true,
      progress: DashboardProgress.None,
      criteriaMet: false,
      debug: false,
    },
    [FTUEFlow.DashboardPrescriptions]: {
      complete: true,
      progress: DashboardPrescriptionsProgress.None,
      criteriaMet: false,
      debug: false,
    },
    [FTUEFlow.FirstRoutine]: {
      complete: true,
      progress: FirstRoutineProgress.None,
      criteriaMet: true,
      debug: false,
    },
    [FTUEFlow.Journal]: {
      complete: true,
      progress: JournalProgress.None,
      criteriaMet: true,
      debug: false,
    },
    [FTUEFlow.Observation]: {
      complete: true,
      progress: ObservationProgress.None,
      criteriaMet: false,
      debug: false,
    },
    [FTUEFlow.Prescriptions]: {
      complete: true,
      progress: PrescriptionsProgress.None,
      criteriaMet: true,
      debug: false,
    },
    [FTUEFlow.Validation]: {
      complete: true,
      progress: ValidationProgress.None,
      criteriaMet: false,
      debug: false,
    },
  };

  const [progressState, setProgressState] =
    useState<ProgressState>(emptyFTUEState);

  // Template ID for First Routine Flow.
  const [template, setTemplate] = useState<string | undefined>();

  useEffect(() => {
    setFTUERunning(
      Object.values(progressState).some(
        (ps) =>
          !(ps as FlowState<string>).complete &&
          (ps as FlowState<string>).criteriaMet
      )
    );
    setFirstPageFTUEIsRunning(
      (!progressState[FTUEFlow.FirstRoutine].complete &&
        progressState[FTUEFlow.FirstRoutine].criteriaMet) ||
        (!progressState[FTUEFlow.Dashboard].complete &&
          progressState[FTUEFlow.Dashboard].criteriaMet) ||
        (!progressState[FTUEFlow.Validation].complete &&
          progressState[FTUEFlow.Validation].criteriaMet) ||
        (!progressState[FTUEFlow.Observation].complete &&
          progressState[FTUEFlow.Observation].criteriaMet)
    );
  }, [progressState]);

  useEffect(() => {
    if (profileInitializationComplete) {
      if (userId && tenantId) {
        let unsub: Unsubscribe | null = null;

        const loadMessages = async () => {
          const db = getFirestore();
          const collection = FirestoreCollection.FTUEMessages;

          unsub = onSnapshot(doc(db, collection, userId), async (docSnap) => {
            if (docSnap.exists()) {
              const data = docSnap.data() as MessagingDocument;

              const messages = Object.values(data.messages);

              setFtueRecords(messages);
              setFtueInitializationComplete(true);
            } else {
              const migratedMessages = migrateOldMessageRecords(
                coachNotifications.filter((notif) => isFtueRecord(notif.id))
              );

              await createMessageDoc(
                collection,
                userId,
                tenantId,
                migratedMessages
              );
            }
          });
        };

        loadMessages();

        return () => {
          if (unsub) {
            unsub();
          }
        };
      }
    }
  }, [profileInitializationComplete, userId]);

  useEffect(() => {
    if (
      progressState[FTUEFlow.FirstRoutine].complete ||
      ftueRecords.map((r) => r.id).includes(ftueStartOfFTUEDialog)
    ) {
      setShouldShowIntroFTUE(false);
    } else if (childIds.length > 0) {
      const event: FTUEIntroDialogSeen = {
        name: "FTUE - Intro Dialog Seen",
      };

      handleEvent(event);

      setShouldShowIntroFTUE(true);
    }
  }, [childIds, progressState, ftueRecords]);

  // Initial Setup.
  useEffect(() => {
    const initialSetup = (records: MessageRecord[]) => {
      if (
        active &&
        routineInitializationComplete &&
        childInitilizationComplete &&
        ftueInitializationComplete &&
        followUpInitializationComplete
      ) {
        setTemplate(
          FTUEFlowDefinitions.FirstRoutineFTUEFlow.Logic.getTemplate(records)
        );

        const recordIds = records.map((r) => r.id);

        // Dashboard.
        const dashboardProgress =
          FTUEFlowDefinitions.DashboardFTUEFlow.Logic.getProgress(recordIds);

        // Dashboard Prescription.
        const dashboardPrescriptionsProgress =
          FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Logic.getProgress(
            recordIds
          );

        // First Routine.
        const firstRoutineProgress =
          FTUEFlowDefinitions.FirstRoutineFTUEFlow.Logic.getProgress(recordIds);

        // Journal.
        const journalProgress =
          FTUEFlowDefinitions.JournalFTUEFlow.Logic.getProgress(recordIds);

        // Observation.
        const observationProgress =
          FTUEFlowDefinitions.ObservationFTUEFlow.Logic.getProgress(recordIds);

        // Prescriptions.
        const prescriptionsProgress =
          FTUEFlowDefinitions.PrescriptionsFTUEFlow.Logic.getProgress(
            recordIds
          );

        // Validation.
        const validationProgress =
          FTUEFlowDefinitions.ValidationFTUEFlow.Logic.getProgress(recordIds);

        setProgressState({
          [FTUEFlow.Dashboard]: {
            progress: dashboardProgress,
            complete:
              FTUEFlowDefinitions.DashboardFTUEFlow.Logic.isComplete(
                dashboardProgress
              ),
            criteriaMet: false,
            debug: false,
          },
          [FTUEFlow.DashboardPrescriptions]: {
            progress: dashboardPrescriptionsProgress,
            complete:
              FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Logic.isComplete(
                dashboardPrescriptionsProgress
              ),
            criteriaMet: false,
            debug: false,
          },
          [FTUEFlow.FirstRoutine]: {
            progress: firstRoutineProgress,
            complete: FTUEFlowDefinitions.FirstRoutineFTUEFlow.Logic.isComplete(
              firstRoutineProgress,
              routinesById
            ),
            criteriaMet: true,
            debug: false,
          },
          [FTUEFlow.Journal]: {
            progress: journalProgress,
            complete:
              FTUEFlowDefinitions.JournalFTUEFlow.Logic.isComplete(
                journalProgress
              ),
            criteriaMet: true,
            debug: false,
          },
          [FTUEFlow.Observation]: {
            progress: observationProgress,
            complete:
              FTUEFlowDefinitions.ObservationFTUEFlow.Logic.isComplete(
                observationProgress
              ),
            criteriaMet: false,
            debug: false,
          },
          [FTUEFlow.Prescriptions]: {
            progress: prescriptionsProgress,
            complete:
              FTUEFlowDefinitions.PrescriptionsFTUEFlow.Logic.isComplete(
                prescriptionsProgress,
                userHasPrescription
              ),
            criteriaMet: true,
            debug: false,
          },
          [FTUEFlow.Validation]: {
            progress: validationProgress,
            complete:
              FTUEFlowDefinitions.ValidationFTUEFlow.Logic.isComplete(
                validationProgress
              ),
            criteriaMet: false,
            debug: false,
          },
        });

        setInitialSetupFinished(true);
      }
    };

    initialSetup(ftueRecords);
  }, [
    ftueInitializationComplete,
    childInitilizationComplete,
    followUpInitializationComplete,
  ]);

  // #region Check initial criteria for flows.

  // Dashboard.
  useEffect(() => {
    if (initialSetupFinished) {
      const criteriaMet =
        FTUEFlowDefinitions.DashboardFTUEFlow.Logic.meetsInitialCriteria(
          childrenById,
          selectedChild
        );

      if (criteriaMet) {
        setProgressState((current) => ({
          ...current,
          [FTUEFlow.Dashboard]: {
            ...current[FTUEFlow.Dashboard],
            criteriaMet,
          },
        }));
      }
    }
  }, [initialSetupFinished, childrenById, selectedChild]);

  // Dashboard Prescriptions.
  useEffect(() => {
    const checkCriteria = async () => {
      if (selectedChild) {
        const criteriaMet =
          FTUEFlowDefinitions.DashboardPrescriptionsFTUEFlow.Logic.meetsCriteria(
            hasRecords
          );

        if (criteriaMet) {
          setProgressState((current) => ({
            ...current,
            [FTUEFlow.DashboardPrescriptions]: {
              ...current[FTUEFlow.DashboardPrescriptions],
              criteriaMet,
            },
          }));
        }
      }
    };

    if (initialSetupFinished) {
      checkCriteria();
    }
  }, [initialSetupFinished, selectedChild, hasRecords]);

  // Observation.
  useEffect(() => {
    if (initialSetupFinished) {
      const criteriaMet =
        FTUEFlowDefinitions.ObservationFTUEFlow.Logic.meetsCriteria(
          observationsToDoCount
        );

      if (criteriaMet) {
        setProgressState((current) => ({
          ...current,
          [FTUEFlow.Observation]: {
            ...current[FTUEFlow.Observation],
            criteriaMet,
          },
        }));
      }
    }
  }, [initialSetupFinished, observationsToDoCount]);

  // Validation.
  useEffect(() => {
    if (initialSetupFinished) {
      const criteriaMet =
        FTUEFlowDefinitions.ValidationFTUEFlow.Logic.meetsCriteria(
          selectedChild,
          childrenById,
          tasksToValidate
        );

      if (criteriaMet) {
        setProgressState((current) => ({
          ...current,
          [FTUEFlow.Validation]: {
            ...current[FTUEFlow.Validation],
            criteriaMet,
          },
        }));
      }
    }
  }, [initialSetupFinished, selectedChild, tasksToValidate, childrenById]);

  // #endregion

  useEffect(() => {
    if (!userId) {
      setFTUERunning(true);
      setInitialSetupFinished(false);
      setBlockFTUE(false);
      setProgressState(emptyFTUEState);
    }
  }, [userId]);

  const updateFTUERecord = async (
    ftueId: string,
    extraData?: Record<string, string | number | boolean>
  ): Promise<void> => {
    if (userId) {
      const lastSeen = Tools.Time.Dates.getTimeStamp();

      await updateMessageDoc(FirestoreCollection.FTUEMessages, userId, [
        {
          id: ftueId,
          lastSeen,
          extraData,
        },
      ]);
    }
  };

  return (
    <FTUEContextProvider
      value={{
        ftueRunning,
        firstPageFTUEIsRunning,
        blockFTUE,
        setBlockFTUE,
        template,
        setTemplate,
        progressState,
        setProgressState,
        initialSetupFinished,
        ftueRecords,
        updateFTUERecord,
        assets: ftueAssets,
        shouldShowIntroFTUE,
        setShouldShowIntroFTUE,
      }}
    >
      {props.children}
    </FTUEContextProvider>
  );
};

export { useFTUEContext, FTUEProvider };
