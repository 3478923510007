import { createTheme } from "@mui/material/styles";
import { components } from "./components";
import { palette } from "./palette";
import { customResponsiveFontSizes, typography } from "./typography";

declare module "@mui/material/TextField" {
  interface TextFieldPropsVariantOverrides {
    contained: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    link: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }
  interface PaletteOptions {
    white: PaletteOptions["primary"];
  }

  interface ButtonVariants {
    link: React.CSSProperties;
  }

  interface ButtonVariantsOptions {
    link?: React.CSSProperties;
  }

  interface TypographyVariants {
    label: React.CSSProperties;
  }

  interface TypographyVariantsOptions {
    label?: React.CSSProperties;
  }
}

const createCustomTheme = () =>
  customResponsiveFontSizes(
    createTheme({
      palette,
      typography,
      components,
    })
  );

export default createCustomTheme;
