import Promo from "./Promo";
import Stripe from "./Stripe";
import Subscription from "./Subscription";

const Billing = {
  Promo,
  Stripe,
  Subscription,
};

export default Billing;
