import { CreateRecordBody } from "@neurosolutionsgroup/models";
import axios from "axios";
import { getHeaders, getURL, handleApiError } from "../utils";

const postRecords = async (
  medicalChildId: string,
  followedSideEffectId: string,
  body: CreateRecordBody
): Promise<void> => {
  const URL =
    getURL() +
    `/api/medicalChild/${medicalChildId}/followedSideEffect/${followedSideEffectId}/record`;

  const headers = await getHeaders(true, "postRecords");

  try {
    return await axios.post(URL, body, { headers });
  } catch (err) {
    return handleApiError(err);
  }
};

const Record = {
  postRecords,
};

export default Record;
