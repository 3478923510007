import {
  add,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
  Locale,
} from "date-fns";
import Time from "../../../Time";

const challengeWeekToString = (
  startDate: string,
  weekIndex: number,
  dateLocale: Locale
) => {
  const startDateDate = Time.Dates.parseDateStringToJsDate(startDate);

  const weekStartDate = add(startDateDate, { weeks: weekIndex - 1 });

  const weekEndDate = add(weekStartDate, { days: 6 });

  const endDate = format(weekEndDate, "d LLL u", {
    locale: dateLocale,
  });

  let startDateString = format(weekStartDate, "d", {
    locale: dateLocale,
  });

  if (isSameDay(weekEndDate, weekStartDate)) {
    return endDate;
  }

  if (!isSameMonth(weekEndDate, weekStartDate)) {
    startDateString =
      startDateString +
      " " +
      format(weekStartDate, "LLL", { locale: dateLocale });
  }

  if (!isSameYear(weekEndDate, weekStartDate)) {
    startDateString =
      startDateString +
      " " +
      format(weekStartDate, "u", { locale: dateLocale });
  }

  return startDateString + " - " + endDate;
};

export default challengeWeekToString;
