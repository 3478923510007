import React from "react";
import { Box, useTheme } from "@mui/material";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis as RCYAxis,
  Text,
} from "recharts";
import { GraphModels, GraphTools } from "@neurosolutionsgroup/graphs";
import { startOfWeek } from "date-fns";
import GraphContainer from "../GraphContainer";
import YAxis from "../YAxis";
import useLanguage from "common/hooks/Parameters/useLanguage";

interface ProgressAnalysisGraphProps {
  data: GraphModels.ProgressDatum[];
  categories: string[];
  periodStart: number;
  weeks: boolean;
}

const ProgressAnalysisGraph = ({
  data,
  categories,
  periodStart,
  weeks,
}: ProgressAnalysisGraphProps): JSX.Element => {
  const { palette } = useTheme();
  const { dateLocale } = useLanguage();

  return (
    <Box display="flex" ml={1} position="relative">
      <GraphContainer
        dataLength={data.length}
        dataWidth="32.5px"
        marginBottom={30}
        height="20em"
      >
        <ResponsiveContainer>
          <LineChart
            data={data}
            margin={{
              top: 10,
              left: 0,
              right: 0,
              bottom: 30,
            }}
          >
            <CartesianGrid stroke="#eee6dc" />
            <XAxis
              type="number"
              dataKey="valueX"
              domain={GraphTools.calculateXDomain(
                data.length,
                data.map((d) => d.valueX),
                0.7
              )}
              ticks={data.map((d) => d.valueX)}
              tickLine={true}
              stroke="#eee6dc"
              axisLine={false}
              interval={0}
              minTickGap={0}
              allowDecimals={false}
              tick={({ x, y, payload }) => {
                const strings = weeks
                  ? GraphTools.weeksAxisFormatterArray(
                      payload.value,
                      startOfWeek(new Date(periodStart * 1000), {
                        weekStartsOn: 1,
                      }),
                      dateLocale
                    )
                  : GraphTools.dateAxisFormatter(
                      payload.value,
                      new Date(periodStart * 1000),
                      dateLocale,
                      false
                    );

                const fontSize = 10;

                return (
                  <g>
                    {strings.map((text, i, array) => (
                      <Text
                        key={i}
                        x={
                          x -
                          i * (fontSize + 2) + // Move next line below first.
                          array.length * 0.5 * fontSize // Move entire text to center.
                        }
                        y={y}
                        angle={90}
                        fontSize={fontSize}
                        textAnchor={"start"}
                        verticalAnchor={"start"}
                        letterSpacing="0.15px"
                        fill={palette.secondary.main}
                      >
                        {text}
                      </Text>
                    ))}
                  </g>
                );
              }}
            />
            <RCYAxis
              axisLine={false}
              orientation="right"
              domain={[0, 3]}
              ticks={[0, 25, 50, 75, 100]}
              width={0}
            />
            {categories.map((c) => {
              return (
                <Line
                  type="bump"
                  isAnimationActive={false}
                  dataKey={c}
                  key={c}
                  stroke={GraphTools.getGraphColor(parseInt(c))}
                  dot={(props) =>
                    GraphTools.getLineGraphIcon(
                      parseInt(c),
                      props.cx,
                      props.cy,
                      props.value,
                      props.key,
                      undefined
                    )
                  }
                  connectNulls={true}
                  animationDuration={750}
                />
              );
            })}
          </LineChart>
        </ResponsiveContainer>
      </GraphContainer>
      <YAxis
        ticks={[
          {
            label: "0%",
            value: 0,
          },
          {
            label: "25%",
            value: 25,
          },
          {
            label: "50%",
            value: 50,
          },
          {
            label: "75%",
            value: 75,
          },
          {
            label: "100%",
            value: 100,
          },
        ]}
        maxValue={100}
        width="40px"
        marginBottom={30}
      />
    </Box>
  );
};

export default ProgressAnalysisGraph;
