import { MessageRecord } from "@neurosolutionsgroup/models";
import { isSameDay } from "date-fns";
import messages, { Vesa } from "./messages";

export const calculateNextAdviceMessage = (
  messageRecords?: MessageRecord[]
): Vesa => {
  if (!messageRecords || messageRecords.length === 0) {
    return messages[0];
  }

  // Sort messages from most recent to oldest.
  messageRecords.sort((a, b) => b.lastSeen - a.lastSeen);

  const latestMessage = messageRecords[0];

  // Check if message needs to be incremented.
  if (isSameDay(latestMessage.lastSeen * 1000, Date.now())) {
    return (
      messages.find((messages) => messages.id === latestMessage.id) ??
      messages[0]
    );
  }

  // Calculuate new message index.
  const messageIndex = messages.findIndex(
    (messages) => messages.id === latestMessage.id
  );

  let newMessageIndex = messageIndex + 1;

  if (newMessageIndex > messages.length - 1) {
    newMessageIndex = 1;
  }

  return messages[newMessageIndex];
};
