import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const CopyIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="copy" {...props}>
      <path
        id="path824"
        fill={color}
        d="M 2.9492186,0 1.069623,1.8187041 V 14.545036 H 2.1599263 L 2.9492186,13.254825 V 1.8187041 c 0,0 0.00115,0 0.00115,0 H 13.158318 L 14.230239,0.982307 V 0 Z m 3.76034,3.6362592 c -0.065806,0 -0.1308642,9.7e-5 -0.1872702,0.00919 h -0.00919 l -1.664753,1.6452217 c -0.0094,0.054547 -0.00919,0.1097453 -0.00919,0.1642923 0,-0.054547 -2.088e-4,-0.1097452 0.00919,-0.1642923 l -0.018382,0.018382 v 12.8722431 0.0011 L 6.5130972,19.990809 17.050781,20 18.930377,18.181296 V 9.0912224 L 13.290441,3.6362592 Z m 0,1.818704 H 12.350643 V 10 h 4.700138 v 8.181296 H 6.7095586 Z"
      />
    </BaseIcon>
  );
};

export default CopyIcon;
