import Account from "./Account";
import Analytics from "./Analytics";
import Billing from "./Billing";
import Challenge from "./Challenge";
import Chat from "./Chat";
import Child from "./Child";
import DataExport from "./DataExport";
import Medical from "./Medical";
import Routine from "./Routine";
import Session from "./Session";
import { handleApiError } from "./utils";

const FirebaseAPI = {
  Account,
  Analytics,
  Billing,
  Challenge,
  Chat,
  Child,
  DataExport,
  Medical,
  Routine,
  Session,
};

export { handleApiError };

export default FirebaseAPI;
