import { ChildrenById, TasksToValidate } from "@neurosolutionsgroup/models";
import { ValidationProgress } from "./ValidationProgress";

const calculateProgress = (step: ValidationProgress): number => {
  return (
    Object.values(ValidationProgress).findIndex((v) => v === step.toString()) -
    2
  );
};

const getProgress = (recordIds: string[]): ValidationProgress => {
  let latestValidationStep = ValidationProgress.None;

  if (
    recordIds.includes(ValidationProgress.ValidationFTUEFinished.toString())
  ) {
    latestValidationStep = ValidationProgress.ValidationFTUEFinished;
  } else {
    Object.values(ValidationProgress).forEach((v) => {
      if (recordIds.includes(v)) {
        latestValidationStep = v;
      }
    });
  }

  return latestValidationStep;
};

const isComplete = (progress: ValidationProgress): boolean => {
  if (progress === ValidationProgress.ValidationFTUEFinished) {
    return true;
  }

  return false;
};

const meetsCriteria = (
  selectedChild: string | null,
  childrenById: ChildrenById,
  tasksToValidate: TasksToValidate
): boolean => {
  return (
    !!selectedChild &&
    // Child has tasks to validate.
    tasksToValidate[selectedChild] &&
    tasksToValidate[selectedChild].length > 0 &&
    // Child has never had a routine validated.
    (!childrenById[selectedChild] ||
      childrenById[selectedChild].history.length ===
        tasksToValidate[selectedChild].length)
  );
};

const FTUEValidationLogic = {
  calculateProgress,
  getProgress,
  isComplete,
  meetsCriteria,
};

export default FTUEValidationLogic;
