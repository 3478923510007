import { Box, DialogProps, Typography } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import AlertDialog from "./AlertDialog";

export interface ConfirmationDialogProps extends DialogProps, CypressProps {
  onPositiveAction: VoidFunction;
  onNegativeAction: VoidFunction;
  title?: string;
  text?: string | JSX.Element;
  imgSrc?: string;
  hideIrreversibleWarning?: boolean;
}

const ConfirmationDialog = ({
  onPositiveAction,
  onNegativeAction,
  imgSrc,
  title,
  text,
  hideIrreversibleWarning = false,
  ...props
}: ConfirmationDialogProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <AlertDialog
      {...props}
      title={title ?? t("general.cancel.title")}
      text={
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            img: {
              maxWidth: "100px",
            },
          }}
          px={2}
        >
          <img src={imgSrc} alt="" />
          <Typography textAlign="center" fontSize="0.8rem" mt={1}>
            {t("general.cancel.sure")}
          </Typography>
          <Typography textAlign="center" fontSize="0.8rem">
            {text ?? t("general.cancel.generic")}
          </Typography>
          {hideIrreversibleWarning ? null : (
            <Typography textAlign="center" fontSize="0.8rem">
              {t("general.cancel.irreversible")}
            </Typography>
          )}
        </Box>
      }
      positiveAction={{
        "action": onPositiveAction,
        "text": t("general.actions.yes"),
        "data-cy": props["data-cy"] + "-positive",
      }}
      negativeAction={{
        "action": onNegativeAction,
        "text": t("general.actions.no"),
        "data-cy": props["data-cy"] + "-negative",
      }}
      PaperProps={{
        "data-cy": props["data-cy"] + "-dialog",
      }}
    />
  );
};

export default ConfirmationDialog;
