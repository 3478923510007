import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

export type ArrowType = "default" | "empty" | "simple" | "full";
export type ArrowDirection = "up" | "down" | "left" | "right";

export interface ArrowIconProps extends IconProps {
  arrowType?: ArrowType;
  arrowDirection?: ArrowDirection;
}

const ArrowIcon = ({
  onClick,
  arrowType = "empty",
  color = DEFAULT_ICON_COLOR,
  arrowDirection = "right",
  className,
  ...props
}: ArrowIconProps): JSX.Element => {
  let rotate = 0;

  switch (arrowDirection) {
    case "down":
      rotate = 90;
      break;
    case "left":
      rotate = 180;
      break;
    case "up":
      rotate = 270;
      break;
  }

  const path = () => {
    switch (arrowType) {
      case "empty":
        return (
          <path
            d="M 17.345519,9.9769609 6.7063209,-1e-7 3.8232809,2.6167199 10.526681,9.5694409 2.6544809,17.24348 5.3913609,20 Z"
            fill={color}
            id="path824"
            transform={`rotate(${rotate}, 10, 10)`}
          />
        );
      case "simple":
        return (
          <path
            d="M 5.4184202,18.571429 13.989848,9.9999998 5.4184187,1.4285712"
            stroke={color}
            strokeWidth="2.85714"
            strokeLinecap="round"
            id="path1"
            transform={`rotate(${rotate}, 10, 10)`}
          />
        );
      case "full":
        return (
          <>
            <path
              d="m 6.0233538,15.456773 c 1.3255561,1.223572 2.6511121,2.447145 3.9766682,3.670717 1.325541,-1.223572 2.651083,-2.447145 3.976624,-3.670717"
              stroke={color}
              strokeWidth="2.69016"
              strokeLinecap="round"
              transform={`rotate(${rotate - 90}, 10, 10)`}
              id="path1"
            />
            <path
              d="M 10.014197,18.991895 C 10.009465,13.428959 10.004732,7.8660234 10,2.3030877"
              stroke={color}
              strokeWidth="2.69016"
              strokeLinecap="round"
              transform={`rotate(${rotate - 90}, 10, 10)`}
              id="path2"
            />
          </>
        );
      case "default":
      default:
        return (
          <path
            transform={`rotate(${rotate - 90}, 10, 10)`}
            d="M 9.999974,15.62915 0,4.3708501 l 20,2e-6 z"
            fill={color}
            id="path824"
          />
        );
    }
  };

  return (
    <BaseIcon
      onClick={onClick}
      classModifier="arrow"
      className={["icon-arrow--" + arrowDirection, className].join(" ")}
      {...props}
    >
      {path()}
    </BaseIcon>
  );
};

export default ArrowIcon;
