import { FormLabel, TextField, Typography } from "@mui/material";
import React, { forwardRef, HTMLInputTypeAttribute } from "react";
import { CypressProps } from "@neurosolutionsgroup/models";
interface AuthTextFieldProps extends CypressProps {
  id: string;
  label: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  type: HTMLInputTypeAttribute;
  autoComplete?: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: boolean;
  tabIndex?: number;
  autoFocus?: boolean;
  errorText?: string;
  onReturnKey?: VoidFunction;
  placeholder?: string;
  endAdornment?: React.ReactNode;
}

const AuthTextField = forwardRef<HTMLInputElement, AuthTextFieldProps>(
  ({ id, label, errorText, onReturnKey, endAdornment, ...props }, ref) => {
    return (
      <>
        <FormLabel
          htmlFor={id}
          sx={{
            fontWeight: "bold",
            fontSize: "0.9rem",
          }}
        >
          {label}
        </FormLabel>
        <TextField
          inputRef={ref}
          data-cy={props["data-cy"]}
          id={id}
          {...props}
          fullWidth
          color="secondary"
          variant="outlined"
          sx={{
            "marginTop": "6px",
            "borderRadius": "999px",
            "boxShadow": "0px 0px 6px -1px rgba(196, 196, 196, 0.5)",
            "& fieldset": {
              borderRadius: "999px",
            },
            "input": {
              "paddingX": "20px",
              "paddingY": "6px",
              "&::placeholder": {
                opacity: "0.2",
              },
            },
          }}
          InputProps={{
            endAdornment,
          }}
          onKeyDown={(e) => {
            if (onReturnKey && e.code === "Enter") {
              onReturnKey();
            }
          }}
        />
        {props.error ? (
          <Typography color="error" fontSize="0.9rem">
            {errorText}
          </Typography>
        ) : null}
      </>
    );
  }
);

export default AuthTextField;
