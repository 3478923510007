import Time from "../../../Time";

/**
 * Calculate if challenge is possible for creation.
 * @param challengeStartDayIndex - ISO day index of challenge start.
 * @param days - Selected days in binary format.
 * @param frequency - How many time a week the challenge must be done.
 * @returns - string describing error type.
 */
const isChallengeImpossible = (
  challengeStartDayIndex: number,
  days: number,
  frequency: number
): "errorThisWeek" | "errorAnyWeek" | null => {
  const allDaysPossible = days === 0 || days === 127;

  const activeDays = allDaysPossible ? 7 : Time.Days.countDays(days);

  if (challengeStartDayIndex === 1 && allDaysPossible) {
    return null;
  }

  if (activeDays < frequency) {
    return "errorAnyWeek";
  } else {
    let daysLeftInWeek = 0;

    if (allDaysPossible) {
      daysLeftInWeek = 8 - challengeStartDayIndex;
    } else {
      daysLeftInWeek = Time.Days.dayIndexes.filter(
        (dayIndex, i) =>
          Time.Days.dayInDays(dayIndex, days) && i + 1 >= challengeStartDayIndex
      ).length;
    }

    if (daysLeftInWeek >= frequency) {
      return null;
    } else {
      return "errorThisWeek";
    }
  }
};

export default isChallengeImpossible;
