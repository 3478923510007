import { Box, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useFTUE from "useFTUE";

const IntroStart = (): JSX.Element => {
  const { t } = useTranslation();

  const {
    selectors: { assets },
  } = useFTUE();

  return (
    <Grid
      container
      direction="column"
      height="100vh"
      padding="0 8px"
      spacing={1}
    >
      <Grid item marginTop="auto">
        <Typography
          textAlign="center"
          color="#fff"
          fontWeight="bold"
          sx={{
            paddingBottom: "1em",
            paddingLeft: "1em",
            paddingRight: "1em",
          }}
        >
          {t("ftue.dashboard.intro.title")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          textAlign="center"
          color="#fff"
          sx={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "90%",
          }}
        >
          {t("ftue.dashboard.intro.text")}
        </Typography>
      </Grid>
      <Grid
        item
        marginLeft="auto"
        marginRight="auto"
        marginBottom="auto"
        width="85%"
      >
        <Box
          sx={{
            img: {
              width: "100%",
              maxHeight: "850px",
            },
          }}
        >
          <img src={assets.dashboardIntro} alt="" />
        </Box>
      </Grid>
    </Grid>
  );
};

export default IntroStart;
