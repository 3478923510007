import { Box, Typography } from "@mui/material";
import { Prescription } from "@neurosolutionsgroup/models";
import { CloseablePage } from "common/Components";
import React, { useMemo, useState } from "react";
import PrescriptionForm, {
  PrescriptionFormState,
} from "../prescriptionForm/PrescriptionForm";
import { useTranslation } from "react-i18next";
import useChildren from "common/hooks/children/useChildren";
import useFollowUp from "common/hooks/FollowUp/useFollowUp";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { Dialogs } from "@neurosolutionsgroup/components";
import DialogAssets from "assets/dialogs";

const ROOT_CLASS = "edit-prescription";

export interface EditPrescriptionProps {
  open: boolean;
  onClose: VoidFunction;
  initialPrescription: Prescription;
}

const EditPrescription: React.FC<EditPrescriptionProps> = ({
  open,
  onClose,
  initialPrescription,
}) => {
  const { t } = useTranslation();
  const { handleUnknownError } = useErrorsContext();

  const {
    selectors: { selectedChild, medicalChildrenByGamerId },
  } = useChildren();

  const {
    actions: { updatePrescription },
  } = useFollowUp();

  const [takesOtherMedication, setTakesOtherMedication] = useState<boolean>(
    initialPrescription.takesOtherMedication ?? false
  );
  const [showCancelDialog, setShowCancelDialog] = useState(false);

  const medicalChildId = useMemo(() => {
    return selectedChild
      ? medicalChildrenByGamerId[selectedChild]?.medicalChildId
      : null;
  }, [selectedChild]);

  const onSubmit = (newPrescription: Prescription) => {
    if (selectedChild) {
      try {
        updatePrescription(selectedChild, {
          ...newPrescription,
          takesOtherMedication,
        });
      } catch (err: unknown) {
        handleUnknownError(err);
      }
    }
  };

  const onCancel = () => {
    setShowCancelDialog(true);
  };

  return medicalChildId ? (
    <CloseablePage
      isOpen={open}
      onClose={onCancel}
      closeElement={<></>}
      className={ROOT_CLASS}
      header={
        <Box
          className={ROOT_CLASS + "--title title-header"}
          display="flex"
          justifyContent="start"
        >
          <Typography variant="h1" color="#fff">
            {t("general.medication")}
          </Typography>
        </Box>
      }
    >
      <PrescriptionForm
        onCancel={onCancel}
        onSubmit={(p) => {
          onSubmit(p);
          onClose();
        }}
        takesOtherMedication={takesOtherMedication}
        setTakesOtherMedication={setTakesOtherMedication}
        prescription={initialPrescription}
        medicalChildId={medicalChildId}
        formState={PrescriptionFormState.edition}
      />
      <Dialogs.ConfirmationDialog
        open={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        title={t("medication.followUp.edit.cancel.title")}
        text={t("medication.followUp.edit.cancel.text")}
        onPositiveAction={() => {
          setShowCancelDialog(false);
          onClose();
        }}
        onNegativeAction={() => {
          setShowCancelDialog(false);
        }}
        imgSrc={DialogAssets.CancelTag}
      />
    </CloseablePage>
  ) : null;
};
export default EditPrescription;
