import React, { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { PageSection } from "common/Components/PageSection";
import useUserProfile from "common/hooks/account/useUserProfile";
import { Box, Button, Typography } from "@mui/material";
import { Dialogs, Icons } from "@neurosolutionsgroup/components";
import useParameters from "common/hooks/Parameters/useParameters";
import { TaskButton } from "common/Components";
import useAuth from "common/hooks/auth/useAuth";
import Logos from "assets/logo";
import {
  ExternalLink,
  UniWebViewActions,
} from "common/hooks/Parameters/UniWebViewActions";
import {
  ConfigString,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";
import useSubscription from "common/hooks/subscription/useSubscription";
import { differenceInDays } from "date-fns";

export const AccountSettings: React.FC = () => {
  const ROOT_CLASS = "account-settings";

  const { t } = useTranslation();
  const { os, linkHandler, sendMessageToUnity } = useParameters();
  const { getRemoteConfigValue } = useRemoteConfig();

  const { user } = useAuth();
  const { subscriptionStatus } = useSubscription();
  const {
    selectors: { userProfile },
    actions: { deleteUserInfo },
  } = useUserProfile();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openSubscriptionDeleteDialog, setOpenSubscriptionDeleteDialog] =
    useState(false);
  const [openSecondDeleteDialog, setOpenSecondDeleteDialog] = useState(false);

  const signInMethod: "password" | "google" | "apple" = useMemo(() => {
    const providerData = user?.providerData;

    if (!providerData || providerData.length === 0) {
      return "password";
    } else {
      switch (providerData[0].providerId) {
        case "google.com":
          return "google";
        case "apple.com":
          return "apple";
        default:
          return "password";
      }
    }
  }, [user]);

  const renderSignInMethod = useCallback((): JSX.Element => {
    switch (signInMethod) {
      case "apple":
        return (
          <>
            <Typography>{t("settings.auth.provider.apple")}</Typography>
            <img src={Logos.AppleSmall} alt="" />
          </>
        );
      case "google":
        return (
          <>
            <Typography>{t("settings.auth.provider.google")}</Typography>
            <img src={Logos.GoogleSmall} alt="" />
          </>
        );
      default:
        return <Typography>{t("settings.auth.provider.password")}</Typography>;
    }
  }, [signInMethod]);

  const renderSubscriptionStatus = (): JSX.Element | null => {
    if (!subscriptionStatus?.isSubscribed) {
      return null;
    }

    if (subscriptionStatus?.isFreeTrial) {
      const remainingFreeTrialDays = subscriptionStatus.nextExpiration
        ? differenceInDays(
            new Date(subscriptionStatus.nextExpiration * 1000),
            new Date()
          ) + 1
        : null;

      return (
        <>
          {t("settings.freeTrial")}
          {remainingFreeTrialDays !== null ? (
            <>
              <br />
              <Trans
                i18nKey="settings.trialEnds"
                count={remainingFreeTrialDays ?? 0}
                components={{ bold: <strong /> }}
              />
            </>
          ) : null}
        </>
      );
    }

    return (
      <>
        {t("settings.hasSub")}
        <Icons.CheckMarkIcon color="#31737c" checkStyle="kairos" />
      </>
    );
  };

  const onManageSubscription = (
    store: "Apple" | "GooglePlay" | "Stripe" | undefined
  ) => {
    if (store === "Apple" || store === "GooglePlay") {
      sendMessageToUnity(
        UniWebViewActions.ManageSubscription,
        `store=${store}`
      );
    }
  };

  const renderSubscriptionManagement = () => {
    // Only show subscription management options if user subscription aligns with device OS when apple.
    if (
      subscriptionStatus?.isSubscribed &&
      (subscriptionStatus.store === "play_store" ||
        (os === "ios" && subscriptionStatus.store === "app_store"))
    ) {
      return (
        <>
          <TaskButton
            className={`${ROOT_CLASS}__subscribe-button`}
            onClick={() =>
              onManageSubscription(
                subscriptionStatus.store === "play_store"
                  ? "GooglePlay"
                  : "Apple"
              )
            }
            fullWidth
          >
            {t("settings.subscription.manage")}
          </TaskButton>
          <Button
            color="error"
            variant="outlined"
            onClick={() =>
              onManageSubscription(
                subscriptionStatus.store === "play_store"
                  ? "GooglePlay"
                  : "Apple"
              )
            }
            fullWidth
            sx={(theme) => ({
              border: "2px solid " + theme.palette.error.main,
              backgroundColor: "#fff",
            })}
          >
            {t("settings.subscription.unsubscribe")}
          </Button>
        </>
      );
    }
  };

  const onDeleteAccount = () => {
    setOpenDeleteDialog(false);

    if (userProfile?.isSubscribed) {
      setOpenSubscriptionDeleteDialog(true);
    } else {
      setOpenSecondDeleteDialog(true);
    }
  };

  const onSubscriptionDeleteContinue = () => {
    setOpenSubscriptionDeleteDialog(false);

    setOpenSecondDeleteDialog(true);
  };

  return (
    <PageSection title={t("settings.yourAccount")} showBottomBorder>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          svg: {
            height: "1.5rem",
            width: "1.5rem",
            marginLeft: "6px",
          },
          img: {
            height: "1.5rem",
            width: "1.5rem",
            marginLeft: "6px",
          },
        }}
      >
        {renderSignInMethod()}
      </Box>
      <Typography marginTop={1}>{user?.email}</Typography>
      <TaskButton
        onClick={() =>
          linkHandler(
            ExternalLink.WebAppSettings,
            getRemoteConfigValue(ConfigString.WebAppSettings).asString()
          )
        }
      >
        {t("settings.editAccount")}
      </TaskButton>
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography>{t("settings.subscription.title")}</Typography>
        <Typography
          fontSize="0.9rem"
          sx={{
            svg: {
              width: "2rem",
              height: "2rem",
              verticalAlign: "middle",
              path: {
                fill: "#b7d20f",
                stroke: "none",
              },
            },
          }}
          data-cy="settings-subscription-status"
        >
          {renderSubscriptionStatus()}
        </Typography>
        {renderSubscriptionManagement()}
      </Box>
      <Box display="flex" flexDirection="column" mb={2}>
        <Typography mb={1}>{t("settings.manageAccount")}</Typography>
        <Button
          data-cy="delete-account-button"
          color="error"
          variant="contained"
          onClick={() => setOpenDeleteDialog(true)}
          fullWidth
        >
          {t("settings.delete")}
        </Button>
      </Box>
      <Dialogs.AlertDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        title={t("settings.deleteDialog.title")}
        text={<Typography>{t("settings.deleteDialog.first.text")}</Typography>}
        positiveAction={{
          text: t("general.actions.continue"),
          action: onDeleteAccount,
        }}
        negativeAction={{
          text: t("general.actions.cancel"),
          action: () => setOpenDeleteDialog(false),
        }}
      />
      <Dialogs.AlertDialog
        open={openSubscriptionDeleteDialog}
        onClose={() => setOpenSubscriptionDeleteDialog(false)}
        title={t("settings.deleteDialog.title")}
        text={
          <Typography>
            {t("settings.deleteDialog.subscription.text")}
          </Typography>
        }
        positiveAction={{
          text: t("settings.deleteDialog.subscription.action"),
          action: () => onSubscriptionDeleteContinue(),
        }}
        negativeAction={{
          text: t("general.actions.cancel"),
          action: () => setOpenSubscriptionDeleteDialog(false),
        }}
      />
      <Dialogs.AlertDialog
        open={openSecondDeleteDialog}
        onClose={() => setOpenSecondDeleteDialog(false)}
        title={t("settings.deleteDialog.title")}
        text={<Typography>{t("settings.deleteDialog.final.text")}</Typography>}
        positiveAction={{
          text: t("settings.deleteDialog.final.action"),
          action: () => deleteUserInfo(),
        }}
        negativeAction={{
          text: t("general.actions.cancel"),
          action: () => setOpenSecondDeleteDialog(false),
        }}
      />
    </PageSection>
  );
};
