import {
  JoinedPrescriptions,
  RecordSummary,
  RecordsMap,
  SimplifiedRecordWithQuestion,
} from "@neurosolutionsgroup/models";

const extractRecordsFromSummaries = (
  recordSummaries: RecordSummary[],
  periodStartISODateString: string
): {
  recordsMap: RecordsMap;
  joinedPrescriptionIds: string[];
  joinedPrescriptions: JoinedPrescriptions;
} => {
  const joinedPrescriptionIds: string[] = [];

  const joinedPrescriptions: JoinedPrescriptions = {};

  const recordsMap: RecordsMap = {};

  for (let i = 0; i < recordSummaries.length; i++) {
    const summary = recordSummaries[i];
    const records = summary.records;

    const recordsForPeriod: SimplifiedRecordWithQuestion[] = records
      .filter(
        (r) => r.weekStartDate.localeCompare(periodStartISODateString) >= 0
      )
      .map((r) => ({
        ...r,
        questionId: summary.questionId,
      }))
      .sort((a, b) => a.weekStartDate.localeCompare(b.weekStartDate));

    recordsForPeriod.forEach((r) => {
      const joinedPrescriptionId = r.prescriptionIds.join(":");

      const idCreated = joinedPrescriptionIds.includes(joinedPrescriptionId);

      if (!idCreated) {
        joinedPrescriptionIds.push(joinedPrescriptionId);

        joinedPrescriptions[joinedPrescriptionId] = {
          start: r.weekStartDate,
          end: r.weekStartDate,
        };
      } else {
        const recordBeforeStart =
          joinedPrescriptions[joinedPrescriptionId].start.localeCompare(
            r.weekStartDate
          ) > 0;

        const recordAfterEnd =
          joinedPrescriptions[joinedPrescriptionId].end.localeCompare(
            r.weekStartDate
          ) < 0;

        if (recordBeforeStart) {
          joinedPrescriptions[joinedPrescriptionId].start = r.weekStartDate;
        }

        if (recordAfterEnd) {
          joinedPrescriptions[joinedPrescriptionId].end = r.weekStartDate;
        }
      }
    });

    if (recordsMap[summary.sideEffectId]) {
      recordsMap[summary.sideEffectId].push(...recordsForPeriod);
    } else {
      recordsMap[summary.sideEffectId] = recordsForPeriod;
    }
  }

  return { joinedPrescriptionIds, joinedPrescriptions, recordsMap };
};

export default extractRecordsFromSummaries;
