import {
  FirestoreCollection,
  MessageRecord,
} from "@neurosolutionsgroup/models";
import { doc, getFirestore, setDoc } from "firebase/firestore";

const createMessageDoc = async (
  collection: FirestoreCollection,
  userId: string,
  tenantId: string,
  initialData?: MessageRecord[]
): Promise<void> => {
  const db = getFirestore();

  let messages = {};

  if (initialData) {
    initialData.forEach((message) => {
      messages = {
        ...messages,
        [message.id]: message,
      };
    });
  }

  await setDoc(doc(db, collection, userId), {
    userId: userId,
    tenantId,
    messages: messages,
  });
};

export default createMessageDoc;
