import { ButtonBase, Grid } from "@mui/material";
import { ChildIconsDefintion } from "@neurosolutionsgroup/models";

export interface ChildPickerProps {
  childIcons: ChildIconsDefintion;
  selectedIcon: number | null;
  setIcon: (value: number) => void;
}

const ChildPicker: React.FC<ChildPickerProps> = ({
  childIcons,
  selectedIcon,
  setIcon,
}) => {
  const ROOT_CLASS = "child-icon-picker";

  return (
    <div className={ROOT_CLASS}>
      <Grid container direction="row" spacing={1}>
        {Object.entries(childIcons).map(([key]) => {
          if (key !== "-1") {
            return (
              <Grid item borderRadius={10} key={key} xs={2}>
                <ButtonBase
                  data-cy={"child-icon-" + key}
                  sx={{
                    height: "100%",
                    width: "100%",
                    borderRadius: 2,
                    backgroundColor:
                      selectedIcon === parseInt(key) ? "#31737C" : "#fff",
                    padding: 1,
                    img: {
                      width: "80%",
                    },
                  }}
                  onClick={() => setIcon(parseInt(key))}
                >
                  <img
                    src={childIcons[parseInt(key)]}
                    alt={`Child Icon ${parseInt(key)}`}
                  />
                </ButtonBase>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    </div>
  );
};
export default ChildPicker;
