import { DowngradeDialog } from "@neurosolutionsgroup/webviews-pages";
import { useSubscriptionContext } from "./SubscriptionContext";
import { useChildrenContext } from "../children/ChildrenContext";
import { useRoutineContext } from "../routines/RoutinesContext";
import ChildIcons from "assets/child-icons";
import CoachAssets from "assets/coach";
import RoutineTemplateIcons from "assets/routines/templates";
import React from "react";
import { SAFE_AREAS } from "stylesheets";
import useLanguage from "../Parameters/useLanguage";

const DowngradeDialogController = (): JSX.Element => {
  const { language } = useLanguage();

  const {
    loading,
    onDowngradeConfirm,
    onDowngradeReactivate,
    showDowngradeDialog,
  } = useSubscriptionContext();
  const { childrenById } = useChildrenContext();
  const { routinesById } = useRoutineContext();

  return (
    <DowngradeDialog
      open={showDowngradeDialog}
      childIcons={ChildIcons}
      childrenChoices={Object.values(childrenById)}
      coachWarningImgSrc={CoachAssets.CoachWarning}
      language={language}
      loading={loading}
      onDowngrade={onDowngradeConfirm}
      onUpgrade={onDowngradeReactivate}
      routineIcons={RoutineTemplateIcons}
      routines={Object.values(routinesById)}
      safeAreas={SAFE_AREAS}
    />
  );
};

export default DowngradeDialogController;
