import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import SelectInput, { SelectOption } from "../Inputs/SelectInput/SelectInput";
import {
  MedPeriod,
  RoutinePeriod,
  SafeAreas,
} from "@neurosolutionsgroup/models";

export const routinePeriodAbbreviations: {
  [period: number]: { en: string; fr: string; de: string };
} = {
  [RoutinePeriod.Today]: {
    en: "1 d",
    fr: "auj",
    de: "1 t",
  },
  [RoutinePeriod.ThisWeek]: {
    en: "1 w",
    fr: "1 s",
    de: "1 w",
  },
  [RoutinePeriod.LastTwoWeeks]: {
    en: "2 w",
    fr: "2 s",
    de: "2 w",
  },
  [RoutinePeriod.ThisMonth]: {
    en: "1 m",
    fr: "1 m",
    de: "1 m",
  },
  [RoutinePeriod.LastTwoMonths]: {
    en: "2 m",
    fr: "2 m",
    de: "2 m",
  },
  [RoutinePeriod.LastThreeMonths]: {
    en: "3 m",
    fr: "3 m",
    de: "3 m",
  },
  [RoutinePeriod.LastSixMonths]: {
    en: "6 m",
    fr: "6 m",
    de: "6 m",
  },
  [RoutinePeriod.LastNineMonths]: {
    en: "9 m",
    fr: "9 m",
    de: "9 m",
  },
  [RoutinePeriod.LastYear]: {
    en: "1 y",
    fr: "1 an",
    de: "1 j",
  },
};

export const medPeriodAbbreviations: {
  [period: number]: { en: string; fr: string; de: string };
} = {
  [MedPeriod.LastTwoWeeks]: {
    en: "2 w",
    fr: "2 s",
    de: "2 w",
  },
  [MedPeriod.ThisMonth]: {
    en: "1 m",
    fr: "1 m",
    de: "1 m",
  },
  [MedPeriod.LastTwoMonths]: {
    en: "2 m",
    fr: "2 m",
    de: "2 m",
  },
  [MedPeriod.LastThreeMonths]: {
    en: "3 m",
    fr: "3 m",
    de: "3 m",
  },
  [MedPeriod.LastSixMonths]: {
    en: "6 m",
    fr: "6 m",
    de: "6 m",
  },
  [MedPeriod.LastNineMonths]: {
    en: "9 m",
    fr: "9 m",
    de: "9 m",
  },
  [MedPeriod.LastYear]: {
    en: "1 y",
    fr: "1 an",
    de: "1 j",
  },
};

export type PeriodOption = SelectOption<number>;

export interface PeriodPickerProps {
  options: PeriodOption[];
  value: number;
  onChange: (value: number) => void;
  label?: string;
  loading?: boolean;
  hasPremiumLock?: boolean;
  onPremiumOptionClick?: (period: PeriodOption) => void;
  safeAreas?: SafeAreas;
}

const PeriodPicker = ({
  options,
  value,
  onChange,
  label,
  loading,
  hasPremiumLock = false,
  onPremiumOptionClick,
  safeAreas,
}: PeriodPickerProps): JSX.Element => {
  const { t } = useTranslation();

  const onPeriodChange = (option: PeriodOption) => {
    if (option) {
      onChange(option.value);
    }
  };

  return (
    <SelectInput
      label={label ?? t("dashboard.period.label")}
      value={value}
      options={options}
      onChange={(o) => onPeriodChange(o)}
      placeholder={label ?? t("dashboard.period.label")}
      leftIndicator={
        loading ? (
          <CircularProgress
            size="1.25rem"
            sx={(theme) => ({
              color: theme.palette.secondary.main,
              marginRight: theme.spacing(1),
            })}
          />
        ) : undefined
      }
      variant="outlined"
      fullWidth={false}
      hasPremiumLock={hasPremiumLock}
      onPremiumOptionClick={onPremiumOptionClick}
      safeAreas={safeAreas}
    />
  );
};

export default PeriodPicker;
