import {
  Badge,
  BottomNavigation,
  BottomNavigationAction,
  useTheme,
} from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import React from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router";
import { SAFE_AREA_BOTTOM } from "stylesheets";

interface AppBottomNavigatorProps {
  showFollowUpsBadge: boolean;
}

const AppBottomNavigator = ({
  showFollowUpsBadge,
}: AppBottomNavigatorProps): JSX.Element => {
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();

  const getColor = (path: string): string => {
    return location.pathname.includes(path)
      ? theme.palette.secondary.main
      : "#fff";
  };

  return (
    <BottomNavigation
      showLabels
      value={location.pathname}
      onChange={(_event, newValue) => {
        navigate(newValue);
      }}
      sx={{
        "& .MuiBottomNavigationAction-root": {
          paddingBottom: `calc(12px + ${SAFE_AREA_BOTTOM})`,
        },
      }}
      data-cy="parent-bottom-navigator"
    >
      <BottomNavigationAction
        data-cy="dashboard-link"
        label={t("navigation.pageTitle.dashboard")}
        value={"/dashboard"}
        icon={<Icons.BarChartIcon color={getColor("/dashboard")} />}
      />
      <BottomNavigationAction
        data-cy="follow-up-link"
        label={t("navigation.pageTitle.followUps")}
        value={"/follow-ups"}
        color="primary"
        icon={
          <Badge
            className={"bell-icon-badge"}
            color="secondary"
            sx={{
              "&.MuiBadge-root": {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              },
              "& .MuiBadge-badge": {
                backgroundColor: getColor("/follow-ups"),
              },
            }}
            variant="dot"
            invisible={!showFollowUpsBadge}
          >
            <Icons.NotificationIcon color={getColor("/follow-ups")} />
          </Badge>
        }
      />
      <BottomNavigationAction
        data-cy="objectives-link"
        label={t("navigation.pageTitle.objectives")}
        value="/objectives"
        icon={<Icons.MedalIcon color={getColor("/objectives")} />}
      />
      <BottomNavigationAction
        data-cy="medication-link"
        label={t("navigation.pageTitle.prescription")}
        value="/medication"
        icon={<Icons.MedicationIcon color={getColor("/medication")} />}
      />
      <BottomNavigationAction
        data-cy="journal-link"
        label={t("navigation.pageTitle.journal")}
        value={"/journal"}
        icon={<Icons.JournalIcon color={getColor("/journal")} />}
      />
    </BottomNavigation>
  );
};

export default AppBottomNavigator;
