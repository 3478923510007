import React from "react";
import Drawer, { DrawerProps } from "./Drawer";
import clsx from "clsx";
import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CoachAssets from "assets/coach";
import { Icons } from "@neurosolutionsgroup/components";

export interface InfoDrawerProps extends DrawerProps {
  text: string | JSX.Element;
  title?: string;
  showInfoIcon?: boolean;
}

const InfoDrawer: React.FC<InfoDrawerProps> = ({
  className,
  text,
  title,
  showInfoIcon = true,
  ...props
}) => {
  const ROOT_CLASS = "info-drawer";

  const { t } = useTranslation();

  return (
    <Drawer {...props} classes={{ paper: clsx(ROOT_CLASS, className) }}>
      <Box display="flex" pr="3em" alignItems={"center"} mb={1}>
        <Box mr="auto" pr={1}>
          {showInfoIcon && (
            <Icons.InformationIcon
              variant="outline"
              className={ROOT_CLASS + "__icon"}
            />
          )}
        </Box>
        {title && (
          <Box mr="auto" ml="auto">
            <Typography variant="h3" textAlign="center">
              {title}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography textAlign="center" fontSize="0.8rem">
        {text}
      </Typography>
      <img
        src={CoachAssets.CoachDynamic}
        className={ROOT_CLASS + "__coach"}
        alt=""
      />
      <Box display="flex" justifyContent="center" mt={1}>
        <Button variant="contained" color="secondary" onClick={props.onClose}>
          {t("general.understood")}
        </Button>
      </Box>
    </Drawer>
  );
};

export default InfoDrawer;
