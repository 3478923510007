import AppNavigator from "AppNavigator";
import Intro from "pages/Authentication/Intro/Intro";
import Login from "pages/Authentication/Login/Login";
import SignUp from "pages/Authentication/SignUp/SignUp";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/intro" element={<Intro />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/*" element={<AppNavigator />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
