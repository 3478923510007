import {
  AccordionDetails,
  Box,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {
  calculateStickyButtonPagePadding,
  ChildSelector,
  Dialogs,
  FormAccordion,
  FormAccordionSummary,
  PageWithHeader,
  StickyButtons,
} from "@neurosolutionsgroup/components";
import {
  ChildIconsDefintion,
  GamerChild,
  Language,
  SafeAreas,
} from "@neurosolutionsgroup/models";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ChallengeSection from "./Sections/ChallengeSection";
import ChallengeConfigSection from "./Sections/ChallengeConfigSection";
import ChallengeMoreSection from "./Sections/ChallengeMoreSection";
import ImpossibleChallengeDialog from "./Components/ImpossibleChallengeDialog";
import { IconManager, Tools } from "@neurosolutionsgroup/tools";
import ChallengeSummaryDialog from "./Components/ChallengeSummaryDialog";
import { getISODay } from "date-fns";
import { CompleteIconsCategory } from "@neurosolutionsgroup/models";

export interface ChallengeCreationValues {
  skillId: string;
  title: string;
  icon: number;
  description: string;
  frequency: number;
  duration: number;
  startThisWeek: boolean;
  reward: string;
  days: number;
  startTime: number | null;
  endTime: number | null;
}

interface ChallengeCreationPageProps {
  challengeChildId: string | null;
  setChallengeChildId: React.Dispatch<React.SetStateAction<string | null>>;
  childList: GamerChild[];
  childIcons: ChildIconsDefintion;
  disabledChildIds: string[];
  mySkillOptions: string[];
  categorizedIcons: CompleteIconsCategory[];
  taskIconAssets: Record<number, string>;
  iconManager: IconManager;
  onCancel: VoidFunction;
  onCreate: (
    values: ChallengeCreationValues,
    customReward: boolean
  ) => Promise<boolean>;
  open: boolean;
  dateLocale: Locale;
  language: Language;
  loading: boolean;
  coachErrorImgSrc: string;
  coachInfoIconImgSrc: string;
  coachCancelImgSrc: string;
  daysInfoImgSrc: string;
  frequencyInfoImgSrc: string;
  rewardInfoImgSrc: string;
  skillInfoImgSrc: string;
  summaryImgSrc: string;
  safeAreas: SafeAreas;
  sx?: SxProps<Theme>;
}

const ChallengeCreationPage = ({
  challengeChildId,
  setChallengeChildId,
  childList,
  childIcons,
  disabledChildIds,
  mySkillOptions,
  categorizedIcons,
  taskIconAssets,
  iconManager,
  onCancel,
  onCreate,
  open,
  dateLocale,
  language,
  loading,
  coachErrorImgSrc,
  coachInfoIconImgSrc,
  coachCancelImgSrc,
  daysInfoImgSrc,
  frequencyInfoImgSrc,
  rewardInfoImgSrc,
  skillInfoImgSrc,
  summaryImgSrc,
  safeAreas,
  sx,
}: ChallengeCreationPageProps): JSX.Element | null => {
  const { t } = useTranslation();

  const [challengeExpanded, setChallengeExpanded] = useState(true);
  const [configExpanded, setConfigExpanded] = useState(false);
  const [moreExpanded, setMoreExpanded] = useState(false);

  const [impossibleChallengeError, setImpossibleChallengeError] = useState<
    "errorThisWeek" | "errorAnyWeek" | null
  >(null);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);

  const [skill, setSkill] = useState<string | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState<number>(0);

  const [frequency, setFrequency] = useState<number>(1);
  const [duration, setDuration] = useState<number>(1);
  const [startDateOption, setStartDateOption] = useState<
    "thisWeek" | "nextWeek" | null
  >(null);
  const [rewardIndex, setRewardIndex] = useState<number | null>(null);
  const [reward, setReward] = useState<string>("");

  const [days, setDays] = useState<number>(0);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [endTime, setEndTime] = useState<number | null>(null);

  const disabled = useMemo(() => {
    if (startTime || endTime) {
      if (startTime && endTime) {
        if (endTime - startTime < 15 * 60) {
          return true;
        }
      } else {
        return true;
      }
    }

    return (
      skill === null ||
      title.length === 0 ||
      icon === 0 ||
      startDateOption === null ||
      reward.length === 0
    );
  }, [skill, title, icon, reward, startDateOption, startTime, endTime]);

  useEffect(() => {
    if (title.length > 0 && icon !== 0) {
      setConfigExpanded(true);
    }
  }, [title, icon]);

  const resetForm = () => {
    setSkill(null);
    setTitle("");
    setDescription("");
    setIcon(0);

    setFrequency(1);
    setDuration(1);
    setStartDateOption(null);
    setRewardIndex(null);
    setReward("");

    setDays(0);
    setStartTime(null);
    setEndTime(null);

    setCancelDialogOpen(false);
    setImpossibleChallengeError(null);
    setSummaryDialogOpen(false);

    setChallengeExpanded(true);
    setConfigExpanded(false);
    setMoreExpanded(false);
  };

  const onCancelClicked = () => {
    setCancelDialogOpen(true);
  };

  const onCancelConfirmed = () => {
    resetForm();

    onCancel();
  };

  const onConfirm = async () => {
    // Start day is monday if next week or today if this week.
    const challengeStartDay =
      startDateOption === "nextWeek" ? 1 : getISODay(new Date());

    const challengeError = Tools.Data.Challenges.V2.isChallengeImpossible(
      challengeStartDay,
      days,
      frequency
    );

    if (challengeError === null) {
      setSummaryDialogOpen(true);
    } else {
      setImpossibleChallengeError(challengeError);
    }
  };

  const onConfirmSummary = async () => {
    if (!skill) {
      return;
    }

    const success = await onCreate(
      {
        skillId: skill,
        title,
        icon,
        description,
        frequency,
        duration,
        startThisWeek: startDateOption === "thisWeek",
        reward,
        days,
        startTime,
        endTime,
      },
      rewardIndex === 0
    );

    if (success) {
      resetForm();
    }
  };

  const handleChildName = useMemo((): string | undefined => {
    if (!challengeChildId) {
      return undefined;
    }
    const childName = childList.find((c) => {
      return c.id === challengeChildId;
    })?.name;

    if (!childName) {
      return undefined;
    }
    return childName;
  }, [challengeChildId]);

  return open ? (
    <PageWithHeader
      headerContent={
        <Typography color="#fff" variant="h3">
          {t("routine.challenge.create")}
        </Typography>
      }
      safeAreas={safeAreas}
      sx={sx}
    >
      <Box
        sx={{
          paddingX: 1,
          paddingTop: 2,
          paddingBottom: calculateStickyButtonPagePadding(safeAreas),
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box mb={2} mx="auto">
          <ChildSelector
            childList={childList}
            childIcons={childIcons}
            disabledChildIds={disabledChildIds}
            selectedChildId={challengeChildId}
            onChildSelected={setChallengeChildId}
            safeAreas={safeAreas}
            variant="light"
            dataCy="challenge-form-child-selector"
          />
        </Box>

        <Box>
          <FormAccordion
            expanded={challengeExpanded}
            onChange={(_, expanded) => setChallengeExpanded(expanded)}
          >
            <FormAccordionSummary>
              {t("routine.challenge.form.challenge.title")}
            </FormAccordionSummary>
            <AccordionDetails>
              <ChallengeSection
                language={language}
                mySkillOptions={mySkillOptions}
                skill={skill}
                setSkill={setSkill}
                title={title}
                setTitle={setTitle}
                description={description}
                setDescription={setDescription}
                icon={icon}
                setIcon={setIcon}
                categorizedIcons={categorizedIcons}
                taskIconAssets={taskIconAssets}
                iconManager={iconManager}
                coachInfoIconImgSrc={coachInfoIconImgSrc}
                skillInfoImgSrc={skillInfoImgSrc}
                safeAreas={safeAreas}
              />
            </AccordionDetails>
          </FormAccordion>

          <FormAccordion
            expanded={configExpanded}
            onChange={(_, expanded) => setConfigExpanded(expanded)}
          >
            <FormAccordionSummary>
              {t("routine.challenge.form.config.title")}
            </FormAccordionSummary>
            <AccordionDetails>
              <ChallengeConfigSection
                frequency={frequency}
                setFrequency={setFrequency}
                duration={duration}
                setDuration={setDuration}
                startDateOption={startDateOption}
                setStartDateOption={setStartDateOption}
                rewardIndex={rewardIndex}
                setRewardIndex={setRewardIndex}
                reward={reward}
                setReward={setReward}
                coachInfoIconImgSrc={coachInfoIconImgSrc}
                frequencyInfoImgSrc={frequencyInfoImgSrc}
                rewardInfoImgSrc={rewardInfoImgSrc}
                safeAreas={safeAreas}
                dateLocale={dateLocale}
              />
            </AccordionDetails>
          </FormAccordion>

          <FormAccordion
            expanded={moreExpanded}
            onChange={(_, expanded) => setMoreExpanded(expanded)}
          >
            <FormAccordionSummary>
              {t("routine.challenge.form.more.title")}
            </FormAccordionSummary>
            <AccordionDetails>
              <ChallengeMoreSection
                days={days}
                setDays={setDays}
                startTime={startTime}
                setStartTime={setStartTime}
                endTime={endTime}
                setEndTime={setEndTime}
                language={language}
                coachInfoIconImgSrc={coachInfoIconImgSrc}
                daysInfoImgSrc={daysInfoImgSrc}
              />
            </AccordionDetails>
          </FormAccordion>
        </Box>
      </Box>

      <ImpossibleChallengeDialog
        coachErrorImgSrc={coachErrorImgSrc}
        error={impossibleChallengeError}
        onClose={() => setImpossibleChallengeError(null)}
      />

      <Dialogs.ConfirmationDialog
        open={cancelDialogOpen}
        onClose={() => setCancelDialogOpen(false)}
        title={t("routine.challenge.cancel.title")}
        text={t("routine.challenge.cancel.text")}
        imgSrc={coachCancelImgSrc}
        onPositiveAction={() => {
          onCancelConfirmed();
        }}
        onNegativeAction={() => {
          setCancelDialogOpen(false);
        }}
        hideIrreversibleWarning
      />

      <ChallengeSummaryDialog
        open={summaryDialogOpen}
        imgSrc={summaryImgSrc}
        challengeName={title}
        childName={handleChildName ?? ""}
        numberOfTime={frequency}
        numberOfWeeks={duration}
        onConfirm={async () => {
          setSummaryDialogOpen(false);
          onConfirmSummary();
        }}
        onBack={() => {
          setSummaryDialogOpen(false);
        }}
        days={days}
        hasSpecifiedTime={startTime !== null}
        thisWeek={startDateOption === "thisWeek"}
      />

      <StickyButtons
        onConfirm={onConfirm}
        onCancel={onCancelClicked}
        disabled={disabled || loading}
        loading={loading}
      />
    </PageWithHeader>
  ) : null;
};

export default ChallengeCreationPage;
