export const autonomyIconsOrder = [
  1, 9, 10, 11, 15, 17, 18, 20, 49, 64, 22, 42, 39, 80, 81, 65, 66, 67, 40, 68,
  58, 45, 61, 51, 76, 79, 108, 109, 110, 111, 112, 113, 114, 115, 116, 117, 118,
  119,
];

export const hygieneIconsOrder = [3, 4, 35, 8, 63, 23, 36, 37, 38, 69];
export const instructionsIconsOrder = [12, 13, 16, 25, 26, 41, 43, 72, 44, 78];
export const householdIconsOrder = [
  7, 70, 14, 28, 60, 29, 71, 30, 33, 47, 48, 52,
];
export const tidyingIconsOrder = [2, 19, 21, 27, 34, 46];
export const healthIconsOrder = [
  5, 6, 106, 107, 24, 77, 50, 73, 74, 75, 57, 59, 53, 54, 55, 56,
];
export const familyIconsOrder = [
  31, 32, 62, 82, 83, 84, 85, 104, 105, 86, 87, 88, 89, 90, 91, 92, 93, 94, 95,
  96, 97, 98, 99, 100, 101, 102, 103,
];
