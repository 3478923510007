export const SAFE_AREA_TOP = "env(safe-area-inset-top, 0px)";
export const SAFE_AREA_BOTTOM = "env(safe-area-inset-bottom, 0px)";

// Uncomment these two lines to simulate safe-area (notch).
/* export const SAFE_AREA_TOP = "50px";
export const SAFE_AREA_BOTTOM = "50px"; */

export const SAFE_AREAS = {
  top: SAFE_AREA_TOP,
  bottom: SAFE_AREA_BOTTOM,
};
