import { Box, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";
import { createPortal } from "react-dom";

interface FullscreenPageProps extends PropsWithChildren {
  sx?: SxProps<Theme>;
}

const portalRoot = document.getElementById("modal");

const FullscreenPage = ({
  sx,
  ...props
}: FullscreenPageProps): JSX.Element | null => {
  return portalRoot
    ? createPortal(
        <Box
          sx={[
            (theme) => ({
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: theme.palette.background.default,
            }),
            ...(Array.isArray(sx) ? sx : [sx]),
          ]}
        >
          {props.children}
        </Box>,
        portalRoot
      )
    : null;
};

export default FullscreenPage;
