import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import Localization from "@neurosolutionsgroup/localization";
import { isArray } from "lodash";
import { Tools } from "@neurosolutionsgroup/tools";

const resources: Resource = {
  en: {
    translation: {
      auth: Localization.en.auth,
      coach: Localization.en.coach,
      dashboard: Localization.en.dashboard,
      ftue: Localization.en.ftue,
      general: Localization.en.general,
      journal: Localization.en.journal,
      medication: Localization.en.medication,
      navigation: Localization.en.navigation,
      routine: Localization.en.routine,
      settings: Localization.en.settings,
      validation: Localization.en.validation,
    },
  },
  fr: {
    translation: {
      auth: Localization.fr.auth,
      coach: Localization.fr.coach,
      dashboard: Localization.fr.dashboard,
      ftue: Localization.fr.ftue,
      general: Localization.fr.general,
      journal: Localization.fr.journal,
      medication: Localization.fr.medication,
      navigation: Localization.fr.navigation,
      routine: Localization.fr.routine,
      settings: Localization.fr.settings,
      validation: Localization.fr.validation,
    },
  },
  de: {
    translation: {
      auth: Localization.de.auth,
      coach: Localization.de.coach,
      dashboard: Localization.de.dashboard,
      ftue: Localization.de.ftue,
      general: Localization.de.general,
      journal: Localization.de.journal,
      medication: Localization.de.medication,
      navigation: Localization.de.navigation,
      routine: Localization.de.routine,
      settings: Localization.de.settings,
      validation: Localization.de.validation,
    },
  },
};

export function initI18n(): void {
  i18n.use(initReactI18next).init({
    supportedLngs: ["en", "fr", "de"],
    fallbackLng: "en",
    lng: "fr",
    interpolation: {
      escapeValue: false,
      format: function (value, format) {
        if (format === "array-ul" && isArray(value)) {
          return (
            "<ul>" +
            value.map((string) => `<li>${string}</li>`).join("") +
            "</ul>"
          );
        }
        return value;
      },
      defaultVariables: {
        product: Tools.Environment.getProductName(),
      },
    },
    debug: process.env.NODE_ENV === "development",
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["br", "i", "strong", "ul", "li"],
    },
    resources,
  });
}
