import { Box, Typography } from "@mui/material";
import React from "react";

interface YAxisTick {
  value: number;
  label: string;
}

interface YAXISProps {
  ticks: YAxisTick[];
  maxValue: number;
  width?: string;
  marginTop?: number;
  marginBottom?: number;
}

const YAxis = ({
  ticks,
  maxValue,
  width = "70px",
  marginTop = 10,
  marginBottom = 10,
}: YAXISProps): JSX.Element => {
  return (
    <Box
      width={width}
      alignSelf="stretch"
      sx={{
        borderWidth: "1px",
        borderColor: "pink",
        flexShrink: 0,
        flexGrow: 0,
        position: "relative",
        paddingLeft: "5px",
      }}
    >
      {ticks.map((t, i) => (
        <Typography
          fontSize="0.75rem"
          sx={{
            position: "absolute",
            left: "5px",
            top: `calc(${(maxValue - t.value) / maxValue} * calc(100% - ${
              32 + marginTop + marginBottom
            }px))`,
          }}
          key={i}
        >
          {t.label}
        </Typography>
      ))}
      <Box
        sx={{
          position: "absolute",
          background: "rgb(238, 230, 220)",
          width: "1px",
          height: `calc(16em - ${marginTop + marginBottom + 30}px)`,
          left: "-1px",
          top: `${marginTop}px`,
        }}
      />
    </Box>
  );
};

export default YAxis;
