import { Grid, Typography } from "@mui/material";
import MiscAssets from "assets/misc";
import React from "react";
import { useTranslation } from "react-i18next";

const IntroValidation: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction="column"
      spacing={2}
      width="100%"
      height="100%"
      p={1}
      flexWrap="nowrap"
    >
      <Grid item>
        <Typography variant="h4" color="#fff" textAlign="center">
          {t("navigation.intro.validation.title")}
        </Typography>
      </Grid>
      <Grid item flex="1 1 auto" display="flex">
        <Grid container spacing={1} alignItems="center">
          <Grid
            item
            xs={5}
            sm={6}
            alignSelf="stretch"
            display="flex"
            justifyContent="center"
            sx={{
              img: {
                height: 0,
                minHeight: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              },
            }}
          >
            <img src={MiscAssets.IntroValidationChild} alt="" />
          </Grid>
          <Grid item xs={7} sm={6}>
            <Typography color="#fff" textAlign="center">
              {t("navigation.intro.validation.text1")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item flex="1 1 auto" display="flex">
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={7} sm={6}>
            <Typography color="#fff" textAlign="center">
              {t("navigation.intro.validation.text2")}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sm={6}
            alignSelf="stretch"
            display="flex"
            justifyContent="center"
            sx={{
              img: {
                height: 0,
                minHeight: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              },
            }}
          >
            <img src={MiscAssets.IntroValidationParent} alt="" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IntroValidation;
