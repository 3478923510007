import {
  NotificationCategorySettings,
  NotificationCategory,
} from "@neurosolutionsgroup/models";
import { Tools } from "@neurosolutionsgroup/tools";
import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import useAuth from "../auth/useAuth";
import useParameters from "../Parameters/useParameters";
import NotificationsLogic from "./NotificationsLogic";
import NotificationsManager from "./NotificationsManager";
import {
  FeatureFlag,
  useRemoteConfig,
} from "@neurosolutionsgroup/remote-config";

interface NotificationsData {
  globalOptIn: boolean;
  setGlobalOptIn: React.Dispatch<SetStateAction<boolean>>;
  notificationsSettings: NotificationCategorySettings;
  setNotificationsSettings: React.Dispatch<
    SetStateAction<NotificationCategorySettings>
  >;
  optInLastSeen?: number;
  setOptInLastSeen: React.Dispatch<SetStateAction<number | undefined>>;
  notificationsInitializationComplete: boolean;
  requiresNativePermission: boolean;
  ftueNotificationOpen: boolean;
  setFtueNotificationOpen: React.Dispatch<SetStateAction<boolean>>;
  triggerFtueNotificationOptIn: VoidFunction;
}

const [useNotificationsContext, NotificationsContextProvider] =
  Tools.Context.createGenericContext<NotificationsData>(__filename);

const NotificationsProvider: React.FC<PropsWithChildren> = (props) => {
  const [globalOptIn, setGlobalOptIn] = useState<boolean>(false);
  const [optInLastSeen, setOptInLastSeen] = useState<number | undefined>();
  const [notificationsSettings, setNotificationsSettings] =
    useState<NotificationCategorySettings>({
      [NotificationCategory.Routine]: false,
      [NotificationCategory.General]: false,
      [NotificationCategory.Validation]: false,
      [NotificationCategory.Observation]: false,
      [NotificationCategory.Coach]: false,
      [NotificationCategory.News]: false,
      [NotificationCategory.Promotion]: false,
    });
  const [
    notificationsInitializationComplete,
    setNotificationsInitializationComplete,
  ] = useState(false);
  const [requiresNativePermission, setRequiresNativePermission] =
    useState(false);
  const [ftueNotificationOpen, setFtueNotificationOpen] = useState(false);

  const { user } = useAuth();
  const { os, deviceData, version } = useParameters();
  const { checkFeatureFlagVersion } = useRemoteConfig();

  useEffect(() => {
    setRequiresNativePermission(
      NotificationsLogic.isPermissionRequired(os, deviceData)
    );
  }, [os, deviceData]);

  useEffect(() => {
    const getInitialSettings = async () => {
      try {
        if (user) {
          const notificationsManager = new NotificationsManager(
            checkFeatureFlagVersion(FeatureFlag.CustomerIo, version),
            Tools.Environment.isDevBuild()
          );

          const settings = await notificationsManager.getSettings();

          setGlobalOptIn(settings.globalOptIn);
          setOptInLastSeen(settings.optInLastSeen);
          setNotificationsSettings(settings.categories);

          if (!settings.optInLastSeen) {
            triggerFtueNotificationOptIn();
          }

          const configureDeviceNotifications = async (): Promise<void> => {
            let result = null;

            if (settings.globalOptIn && settings.optInLastSeen) {
              result = await notificationsManager.requestNativePermission();
            }

            await notificationsManager.toggleRoutineNotifications(
              settings.globalOptIn &&
                !!result &&
                settings.categories[NotificationCategory.Routine]
            );
          };

          await configureDeviceNotifications();
        }
      } catch (err) {
        console.error(
          "Error encountered setting up notifications settings: " + err
        );
      } finally {
        setNotificationsInitializationComplete(true);
      }
    };

    getInitialSettings();
  }, [user]);

  const triggerFtueNotificationOptIn = () => {
    if (!globalOptIn) {
      setFtueNotificationOpen(true);
    }
  };

  return (
    <NotificationsContextProvider
      value={{
        globalOptIn,
        setGlobalOptIn,
        notificationsSettings,
        setNotificationsSettings,
        optInLastSeen,
        setOptInLastSeen,
        notificationsInitializationComplete,
        requiresNativePermission,
        ftueNotificationOpen,
        setFtueNotificationOpen,
        triggerFtueNotificationOptIn,
      }}
    >
      {props.children}
    </NotificationsContextProvider>
  );
};

export { useNotificationsContext, NotificationsProvider };
