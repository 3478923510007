import { FTUEJournalScreen } from "./FullScreen";
import JournalFTUEHints from "./Hints";
import FTUEJournalLogic from "./JournalLogic";
import { JOURNAL_PROGRESS_TOTAL, JournalProgress } from "./JournalProgress";

export { JOURNAL_PROGRESS_TOTAL, JournalProgress };

const JournalFTUEFlow = {
  Hints: JournalFTUEHints,
  Logic: FTUEJournalLogic,
  Screen: FTUEJournalScreen,
};

export default JournalFTUEFlow;
