import React, { PropsWithChildren, useEffect, useState } from "react";
import { ValidationEmptyStateAdviceProvider } from "./validationEmptyStateAdvice/ValidationEmptyStateAdviceContext";
import { FTUEAssets, FTUEProvider } from "@neurosolutionsgroup/webviews-ftue";
import { CoachProvider } from "./Coach";
import useAuth from "../auth/useAuth";
import { useFollowUpContext } from "../FollowUp/FollowUpContext";
import { useProfileContext } from "../account/UserProfileContext";
import useFollowUp from "../FollowUp/useFollowUp";
import { useChildrenContext } from "../children/ChildrenContext";
import { useRoutineContext } from "../routines/RoutinesContext";
import MiscAssets from "assets/misc";
import CoachAssets from "assets/coach";
import RoutineTemplateIcons from "assets/routines/templates";
import { NPSProvider } from "common/FTUE/NPS/NPSContext";
import RoutineAssets from "assets/routines";

const MessagingProviders = (props: PropsWithChildren): JSX.Element => {
  const { user, tenantId } = useAuth();
  const {
    childInitilizationComplete,
    selectedChild,
    childrenById,
    tasksToValidate,
    childIds,
  } = useChildrenContext();
  const { followUpInitializationComplete } = useFollowUpContext();
  const {
    selectors: {
      followedSideEffectsByGamerChildId,
      getObservationsToDo,
      getRecordsForSideEffectAndTimePeriod,
    },
  } = useFollowUp();
  const { profileInitializationComplete, coachNotifications } =
    useProfileContext();

  const { routineInitializationComplete, routinesById } = useRoutineContext();

  const [hasRecords, setHasRecords] = useState(false);

  useEffect(() => {
    const checkRecords = async () => {
      if (selectedChild) {
        const followedSideEffects =
          followedSideEffectsByGamerChildId[selectedChild];

        if (followedSideEffects && followedSideEffects.length > 0) {
          const records = (
            await getRecordsForSideEffectAndTimePeriod(
              followedSideEffects[0].sideEffectId,
              2,
              selectedChild
            )
          ).records;

          setHasRecords(records.length > 0);
        }
      }
    };

    checkRecords();
  }, [selectedChild, followedSideEffectsByGamerChildId]);

  const ftueAssets: FTUEAssets = {
    challengeIcon: RoutineAssets.ChallengeIcon,
    dashboardFrequencyEn: MiscAssets.FTUEDashboardFrequencyEn,
    dashboardFrequencyFr: MiscAssets.FTUEDashboardFrequencyFr,
    dashboardIntensityEn: MiscAssets.FTUEDashboardIntensityEn,
    dashboardIntensityFr: MiscAssets.FTUEDashboardIntensityFr,
    dashboardIntro: MiscAssets.FTUEDashboardIntroStart,
    dashboardRoutineEn: MiscAssets.FTUEDashboardRoutinesEn,
    dashboardRoutineFr: MiscAssets.FTUEDashboardRoutinesFr,
    ftueEnd: MiscAssets.FTUEEnd,
    ftueWelcome: MiscAssets.FTUEWelcome,
    infoCoach: CoachAssets.CoachDynamic,
    infoCoachSmall: CoachAssets.CoachInfoHead,
    journalIntroEN: MiscAssets.FTUEJournalIntroEn,
    journalIntroFR: MiscAssets.FTUEJournalIntroFr,
    observationIntro: MiscAssets.FTUEObservationIntro,
    prescriptionIntro: MiscAssets.FTUEPrescriptionIntro,
    prescriptionGraph: MiscAssets.FTUEPrescriptionGraph,
    routineTemplateIcons: RoutineTemplateIcons,
    validationIntro: MiscAssets.FTUEValidationIntro,
  };

  return (
    <NPSProvider>
      <FTUEProvider
        active={true}
        authProps={{
          userId: user?.uid,
          tenantId,
        }}
        childProps={{
          childInitilizationComplete,
          selectedChild,
          childrenById,
          tasksToValidate,
          childIds,
        }}
        medicalProps={{
          followUpInitializationComplete,
          hasRecords,
          observationsToDoCount: getObservationsToDo().length,
          userHasPrescription: false,
        }}
        profileProps={{
          profileInitializationComplete,
          coachNotifications,
        }}
        routineProps={{
          routineInitializationComplete,
          routinesById,
        }}
        ftueAssets={ftueAssets}
      >
        <CoachProvider active={true}>
          <ValidationEmptyStateAdviceProvider>
            {props.children}
          </ValidationEmptyStateAdviceProvider>
        </CoachProvider>
      </FTUEProvider>
    </NPSProvider>
  );
};

export default MessagingProviders;
