import { isApiError } from "@neurosolutionsgroup/models";
import axios from "axios";

export const handleApiError = async (
  err: unknown,
  blob = false
): Promise<never> => {
  if (axios.isAxiosError(err)) {
    if (err.response) {
      let jsonData = err.response.data;

      if (blob) {
        try {
          const jsonDataRaw = await err.response.data.text();

          jsonData = JSON.parse(jsonDataRaw);
        } catch {
          return Promise.reject(err);
        }
      }

      if (isApiError(jsonData)) {
        return Promise.reject(jsonData);
      }
    }
  }

  return Promise.reject(err);
};
