import { Button, Grid, Typography } from "@mui/material";
import { Challenge, ChallengeHistory } from "@neurosolutionsgroup/models";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Icons, ValidationButton } from "@neurosolutionsgroup/components";
import { format } from "date-fns";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { Tools } from "@neurosolutionsgroup/tools";

interface ChallengeAccordionDetailsProps {
  challenge: Challenge;
  onChallengeValidated: (
    challengeId: string,
    history: ChallengeHistory[]
  ) => void;
}

const ChallengeAccordionDetails: React.FC<ChallengeAccordionDetailsProps> = ({
  challenge,
  onChallengeValidated,
}) => {
  const { t } = useTranslation();
  const { dateLocale } = useLanguage();

  const [history, setHistory] = useState<ChallengeHistory[]>(
    challenge.history.filter((history) => history.parentStatus === null)
  );

  useEffect(() => {
    setHistory(
      challenge.history.filter((history) => history.parentStatus === null)
    );
  }, [challenge]);

  const onHistoryStatusSet = (index: number, status: boolean) => {
    setHistory((current) => {
      const clone = [...current];

      clone[index].parentStatus = status;

      return clone;
    });
  };

  const onSubmit = () => {
    const submitHistory = [...history];

    submitHistory.forEach((instance) => {
      if (instance.parentStatus === null) {
        instance.parentStatus = instance.childStatus;
      }

      if (!instance.validationDate) {
        instance.validationDate = Tools.Time.Dates.getTimeStamp();
      }
    });

    onChallengeValidated(challenge.id, submitHistory);
  };

  return (
    <Grid container direction="column" spacing={1} width={"100%"}>
      <Grid item xs={12}>
        <Typography color="#000" fontSize="0.75rem">
          {t("validation.challenge.card.detailsInstruction")}
        </Typography>
      </Grid>
      {history.map((history, i) => (
        <Grid key={i} container item xs={12} alignItems="center" spacing={1}>
          <Grid item xs={8} flexGrow={1}>
            {history.childExecutionDate
              ? format(history.childExecutionDate * 1000, "dd/MM/yyyy", {
                  locale: dateLocale,
                })
              : null}
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ValidationButton
              onClick={() => onHistoryStatusSet(i, true)}
              active={
                history.parentStatus !== null
                  ? history.parentStatus === true
                  : history.childStatus === true
              }
              data-cy={"challenge-success-button"}
            >
              <Icons.CheckMarkIcon color="#A6DA2D" checkStyle="kairos" />
            </ValidationButton>
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <ValidationButton
              onClick={() => onHistoryStatusSet(i, false)}
              active={
                history.parentStatus !== null
                  ? history.parentStatus === false
                  : history.childStatus === false
              }
              data-cy={"challenge-failure-button"}
            >
              <Icons.CloseIcon color="#ef4044" />
            </ValidationButton>
          </Grid>
        </Grid>
      ))}
      <Grid item display="flex" justifyContent="flex-end">
        <Button
          variant="contained"
          color="secondary"
          disabled={history.some(
            (history) =>
              history.parentStatus === null && history.childStatus === null
          )}
          onClick={() => {
            onSubmit();
          }}
          sx={{
            "height": "2.5rem",
            "& .icon": {
              width: "1.5rem",
              height: "1.5rem",
            },
          }}
          data-cy="validate-button"
        >
          {t("general.actions.validate")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ChallengeAccordionDetails;
