import { Box, SxProps, Theme } from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { PropsWithChildren } from "react";

export interface PageContentContainerProps extends PropsWithChildren {
  safeAreas?: SafeAreas;
  sx?: SxProps<Theme>;
  dataCy?: string;
}

const PageContentContainer = ({
  safeAreas,
  sx,
  dataCy,
  ...props
}: PageContentContainerProps): JSX.Element => {
  return (
    <Box
      sx={[
        {
          boxSizing: "border-box",
          height: "100%",
          width: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          ...SharedStyles.Layout.noScroll,
        },
        ...(safeAreas
          ? [{ paddingTop: safeAreas.top, paddingBottom: safeAreas.bottom }]
          : []),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-cy={dataCy}
    >
      {props.children}
    </Box>
  );
};

export default PageContentContainer;
