import { doc, getFirestore, updateDoc } from "firebase/firestore";
import { useValidationEmptyStateAdviceContext } from "./ValidationEmptyStateAdviceContext";
import useAuth from "common/hooks/auth/useAuth";
import { FirestoreCollection } from "@neurosolutionsgroup/models";
import { Vesa } from "./messages";
import { Tools } from "@neurosolutionsgroup/tools";

interface UseValidationEmptyStateAdviceResult {
  latestAdvice: Vesa;
  onAdviceShown: (id: string) => void;
}

const useValidationEmptyStateAdvice =
  (): UseValidationEmptyStateAdviceResult => {
    const { latestAdvice } = useValidationEmptyStateAdviceContext();

    const { user } = useAuth();

    const onAdviceShown = async (id: string) => {
      if (user) {
        const db = getFirestore();

        const docRef = doc(
          db,
          FirestoreCollection.ValidationEmptyStateAdviceMessages,
          user.uid
        );

        await updateDoc(docRef, {
          [`messages.${id}.id`]: id,
          [`messages.${id}.lastSeen`]: Tools.Time.Dates.getTimeStamp(),
        });
      }
    };

    return {
      latestAdvice,
      onAdviceShown,
    };
  };

export default useValidationEmptyStateAdvice;
