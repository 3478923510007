import { Card, CardContent, Grid, Typography } from "@mui/material";

interface EmptyStateCardProps {
  coachImage: string;
  coachText: string;
}

const EmptyStateCard = ({
  coachImage,
  coachText,
}: EmptyStateCardProps): JSX.Element => {
  return (
    <Card
      sx={{
        position: "relative",
        height: "200px",
        maxWidth: "500px",
      }}
    >
      <CardContent sx={{ height: "100%" }}>
        <Grid container alignItems="center" height="100%">
          <Grid
            item
            xs={6}
            sx={{
              alignSelf: "end",
              img: {
                position: "absolute",
                bottom: 0,
                left: "calc(25% - 75px)",
                height: "200px",
              },
            }}
          >
            <img src={coachImage} alt="" />
          </Grid>
          <Grid item xs={6}>
            <Typography textAlign="center">{coachText}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default EmptyStateCard;
