import axios from "axios";
import { getHeaders, getURL, handleApiError } from "../utils";

const postPromoCode = async (code: string): Promise<boolean> => {
  const ENDPOINT = getURL() + "/Billing/Promo";

  const headers = await getHeaders();

  const data = {
    promoCode: code,
  };

  try {
    await axios.post(ENDPOINT, data, {
      headers: { ...headers },
    });

    return true;
  } catch (err) {
    return handleApiError(err);
  }
};

const Promo = {
  postPromoCode,
};

export default Promo;
