import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Dashboard, { DashboardProgress, DASHBOARD_PROGRESS_TOTAL } from "..";
import { FTUEFlow } from "models";
import { FTUEHint } from "components";
import useFTUE from "useFTUE";

const DashboardRoutinesGraph = (props: PropsWithChildren): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setDashboardProgress },
  } = useFTUE();

  const dashboardProgress = progress[FTUEFlow.Dashboard].progress;

  const onNext = () => {
    setDashboardProgress(DashboardProgress.RoutinesGraph);
  };

  const onQuit = () => {
    setDashboardProgress(DashboardProgress.DashboardFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        dashboardProgress === DashboardProgress.Routines &&
        canDisplayFlow(FTUEFlow.Dashboard)
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.dashboard.routinesGraph.text")}
      scrollPosition="start"
      currentStep={Dashboard.Logic.calculateProgress(
        DashboardProgress.Routines
      )}
      maxStep={DASHBOARD_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default DashboardRoutinesGraph;
