import {
  Box,
  Button,
  ButtonBase,
  Drawer,
  drawerClasses,
  Grid,
  LinearProgress,
  linearProgressClasses,
  Typography,
} from "@mui/material";
import { SideEffect } from "@neurosolutionsgroup/models";
import React, { useCallback, useState } from "react";
import AngleBackground from "assets/background/angle_background.svg";
import FrequencyForm from "./FrequencyForm";
import useLanguage from "common/hooks/Parameters/useLanguage";
import IntensityForm from "./IntensityForm";
import { useTranslation } from "react-i18next";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import { SAFE_AREA_TOP } from "stylesheets";
import { Icons } from "@neurosolutionsgroup/components";

interface ObservationsPageProps {
  sideEffect?: SideEffect;
  onClose: VoidFunction;
  onSubmit: (frequency?: number, intensity?: number) => void;
}

enum QuestionnaireStep {
  Frequency,
  Intensity,
}

const ObservationsPage: React.FC<ObservationsPageProps> = ({
  sideEffect,
  onClose,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const [questionnaireStep, setQuestionnaireStep] = useState<QuestionnaireStep>(
    QuestionnaireStep.Frequency
  );
  const [frequency, setFrequency] = useState<number | undefined>(0);
  const [intensity, setIntensity] = useState<number | undefined>(undefined);

  const onPageClose = () => {
    setQuestionnaireStep(QuestionnaireStep.Frequency);
    setFrequency(undefined);
    setIntensity(undefined);
    onClose();
  };

  const onNextStep = () => {
    if (questionnaireStep === QuestionnaireStep.Frequency) {
      setQuestionnaireStep(QuestionnaireStep.Intensity);
    } else {
      onSubmit(frequency, intensity);
      onPageClose();
    }
  };

  const onSkip = () => {
    if (questionnaireStep === QuestionnaireStep.Frequency) {
      setFrequency(undefined);
      setQuestionnaireStep(QuestionnaireStep.Intensity);
    } else {
      onSubmit(frequency, undefined);
      onPageClose();
    }
  };

  const isNextStepDisabled = useCallback((): boolean => {
    if (questionnaireStep === QuestionnaireStep.Frequency) {
      return frequency === undefined;
    } else if (questionnaireStep === QuestionnaireStep.Intensity) {
      return intensity === undefined;
    }

    return true;
  }, [questionnaireStep, frequency, intensity]);

  const getQuestionText = () => {
    return questionnaireStep === QuestionnaireStep.Intensity
      ? t("validation.observations.questions.intensity", {
          sideEffect: sideEffect?.name[language],
        })
      : t("validation.observations.questions.frequency", {
          sideEffect: sideEffect?.name[language],
        });
  };

  return (
    <Drawer
      open={!!sideEffect}
      anchor="bottom"
      sx={(theme) => ({
        [`& .${drawerClasses.paper}`]: {
          width: "100vw",
          height: "100vh",
          borderRadius: 0,
          backgroundColor: theme.palette.background.default,
        },
      })}
    >
      {sideEffect && (
        <Grid
          container
          direction="column"
          height="100%"
          sx={{
            paddingTop: SAFE_AREA_TOP,
          }}
        >
          <Grid item>
            <Box p={1} pb={2}>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <ButtonBase
                      onClick={onPageClose}
                      sx={(theme) => ({
                        "padding": "0.5em",
                        "& .icon-close": {
                          width: "2.5em",
                          height: "2.5em",
                          path: {
                            fill: theme.palette.primary.main,
                          },
                        },
                      })}
                    >
                      <Icons.CloseIcon />
                    </ButtonBase>
                  </Grid>
                  <Grid item>
                    <Typography variant="h4">
                      {sideEffect.name[language]}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <ButtonBase
                      onClick={onSkip}
                      sx={{
                        padding: "0.5em",
                        borderRadius: "10px",
                      }}
                    >
                      <Typography>{t("general.actions.skip")}</Typography>
                    </ButtonBase>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    textAlign="center"
                    fontSize="0.8rem"
                    color="secondary.light"
                    pb={1}
                  >
                    {questionnaireStep + 1} / 2{" "}
                    {t("journal.observations.question.progress", { count: 2 })}
                  </Typography>
                  <LinearProgress
                    value={
                      questionnaireStep === QuestionnaireStep.Frequency
                        ? 50
                        : 100
                    }
                    variant="determinate"
                    color="secondary"
                    sx={{
                      height: 10,
                      borderRadius: 5,
                      [`& .${linearProgressClasses.bar}`]: {
                        borderRadius: 5,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item flexGrow={1}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                borderRadius: "15px",
                backgroundColor: "#fff",
              }}
            >
              <Grid container direction="column" height="100%">
                <Grid item p={3}>
                  <Typography textAlign="center">
                    {getQuestionText()}
                  </Typography>
                </Grid>
                <Grid item flexGrow={1} display="flex">
                  <Box
                    flexGrow={1}
                    sx={{
                      backgroundImage: `url(${AngleBackground})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      paddingTop: "5em",
                      color: "#fff",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box mt="auto" p={1}>
                      {questionnaireStep === QuestionnaireStep.Frequency ? (
                        <FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationFrequency
                          onNext={() =>
                            isNextStepDisabled() ? onSkip() : onNextStep()
                          }
                        >
                          <FrequencyForm
                            frequency={frequency}
                            setFrequncy={setFrequency}
                          />
                        </FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationFrequency>
                      ) : (
                        <FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationIntensity
                          onNext={() =>
                            isNextStepDisabled() ? onSkip() : onNextStep()
                          }
                        >
                          <IntensityForm
                            intensity={intensity}
                            setIntensity={setIntensity}
                          />
                        </FTUEFlowDefinitions.ObservationFTUEFlow.Hints.ObservationIntensity>
                      )}
                    </Box>
                    <Box
                      mt="auto"
                      p={1}
                      sx={{
                        backgroundColor: "#fff",
                        borderTopLeftRadius: "15px",
                        borderTopRightRadius: "15px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        sx={{ paddingX: "2em" }}
                        disabled={isNextStepDisabled()}
                        onClick={onNextStep}
                      >
                        {questionnaireStep === QuestionnaireStep.Frequency
                          ? t("general.actions.next")
                          : t("general.actions.finish")}
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      )}
    </Drawer>
  );
};

export default ObservationsPage;
