import React, { SetStateAction } from "react";
import { Tools } from "@neurosolutionsgroup/tools";
import { useTranslation } from "react-i18next";
import { ButtonBase, Grid, useTheme } from "@mui/material";

interface DayPickerProps {
  value: number;
  setValue: React.Dispatch<SetStateAction<number>>;
  multiple?: boolean;
}

const DayPicker: React.FC<DayPickerProps> = ({
  value,
  setValue,
  multiple = true,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const onToggle = (day: number) => {
    setValue((current) => {
      if (multiple) {
        return Tools.Time.Days.toggleDay(day, current);
      } else {
        return day === current ? 0 : day;
      }
    });
  };

  return (
    <Grid container justifyContent="space-around" spacing={1}>
      {Tools.Time.Days.dayIndexes.map((day) => (
        <Grid
          item
          key={day}
          onClick={() => onToggle(day)}
          flex="1 1 0px"
          boxSizing="border-box"
          data-cy={"day-picker-" + day}
          justifyContent="st"
        >
          <ButtonBase
            sx={{
              paddingHorizontal: 0,
              paddingTop: 1,
              paddingBottom: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "#fff",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: Tools.Time.Days.dayInDays(day, value)
                ? palette.secondary.main
                : "transparent",
              borderRadius: 2,
              userSelect: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "0.75rem",
            }}
          >
            {t(`general.time.days.short.${day}`)}
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  );
};

export default DayPicker;
