import {
  ScrollSelect,
  ScrollSelectOption,
} from "@neurosolutionsgroup/components";
import { Gender } from "@neurosolutionsgroup/models";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export interface GenderPickerProps {
  gender: Gender | null | undefined;
  setGender: (value: Gender | null) => void;
  paddingX: string | number;
}

const GenderPicker: React.FC<GenderPickerProps> = ({
  gender,
  setGender,
  paddingX,
}) => {
  const { t } = useTranslation();

  const genderOptions: ScrollSelectOption<number>[] = [
    {
      value: Gender.MALE,
      label: t("settings.child.male"),
      dataCy: "kid-gender-0",
    },
    {
      value: Gender.FEMALE,
      label: t("settings.child.female"),
      dataCy: "kid-gender-1",
    },
    {
      value: Gender.NON_BINARY,
      label: t("settings.child.nonBinary"),
      dataCy: "kid-gender-2",
    },
    {
      value: Gender.OTHER,
      label: t("settings.child.otherGender"),
      dataCy: "kid-gender-3",
    },
    { value: -1, label: t("settings.child.noAnswer"), dataCy: "kid-gender-4" },
  ];

  const onChange = (value: number) => {
    if (value === -1) {
      setGender(null);
    } else {
      setGender(value);
    }
  };

  const value = useMemo(() => {
    if (gender === null) {
      return -1;
    } else {
      return gender === undefined ? null : gender;
    }
  }, [gender]);

  return (
    <>
      <ScrollSelect
        options={genderOptions}
        value={value}
        onChange={onChange}
        paddingX={paddingX}
      />
    </>
  );
};
export default GenderPicker;
