import {
  Dose,
  FirestorePrescriptionDocument,
} from "@neurosolutionsgroup/models";

export const constructPrescription = (
  userId: string,
  tenantId: string,
  medicalChildId: string,
  drugId: string,
  doses: Dose[],
  startDate: string,
  endDate: string | null
): FirestorePrescriptionDocument => {
  return {
    userId,
    tenantId,
    medicalChildId,
    drug: {
      drugId,
      doses,
    },
    startDate,
    endDate,
    takesOtherMedication: false,
  };
};
