import React from "react";

export interface ProviderProps {
  children?: React.ReactNode;
}

/**
 * Function to create a context without having to pass default values.
 */
export const createGenericContext = <T>(
  logName?: string
): readonly [() => T, React.Provider<T | undefined>] => {
  const genericContext = React.createContext<T | undefined>(undefined);

  const useGenericContext = () => {
    const contextIsDefined = React.useContext(genericContext);
    if (!contextIsDefined) {
      throw new Error(
        (logName ? `[${logName}] ` : "") +
          "Context was called outside the provider."
      );
    }
    return contextIsDefined;
  };

  return [useGenericContext, genericContext.Provider] as const;
};

const Context = {
  createGenericContext,
};

export default Context;
