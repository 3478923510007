import React, { PropsWithChildren } from "react";
import { RoutineProvider } from "common/hooks/routines/RoutinesContext";
import { UserProfileProvider } from "common/hooks/account/UserProfileContext";
import { AppDataProvider } from "common/hooks/AppDataContext";
import { ChildrenProvider } from "common/hooks/children/ChildrenContext";
import { MedicationProvider } from "common/hooks/medications/MedicationContext";
import { FollowUpProvider } from "common/hooks/FollowUp/FollowUpContext";
import { SideEffectProvider } from "common/hooks/sideEffect/SideEffectContext";
import { TasksProvider } from "common/hooks/routines/TasksContext";
import { NotificationsProvider } from "common/hooks/notifications/NotificationContext";
import AnalyticsStateListener from "common/Analytics/AnalyticsStateListener";
import { useAppInitializationContext } from "common/hooks/AppInitializationContext";
import MessagingProviders from "common/hooks/messaging/MessagingProviders";
import { ChallengeProvider } from "common/hooks/challenges/ChallengesContext";
import { SubscriptionProvider } from "common/hooks/subscription/SubscriptionContext";

const AppDataProviders: React.FC<PropsWithChildren> = (props) => {
  const { userInitiated } = useAppInitializationContext();

  return (
    <UserProfileProvider>
      {userInitiated ? (
        <ChildrenProvider>
          <TasksProvider>
            <RoutineProvider>
              <ChallengeProvider>
                <MedicationProvider>
                  <SideEffectProvider>
                    <FollowUpProvider>
                      <SubscriptionProvider>
                        <AppDataProvider>
                          <NotificationsProvider>
                            <MessagingProviders>
                              <AnalyticsStateListener />
                              {props.children}
                            </MessagingProviders>
                          </NotificationsProvider>
                        </AppDataProvider>
                      </SubscriptionProvider>
                    </FollowUpProvider>
                  </SideEffectProvider>
                </MedicationProvider>
              </ChallengeProvider>
            </RoutineProvider>
          </TasksProvider>
        </ChildrenProvider>
      ) : (
        props.children
      )}
    </UserProfileProvider>
  );
};

export default AppDataProviders;
