export enum UniWebViewActions {
  AddLoadingProgress = "add-loading-progress",
  ChildrenUpdated = "children-updated",
  Close = "close",
  CompletedRegistration = "completed-registration",
  Download = "download",
  GetAppInfo = "get-app-info",
  GetPriceInfo = "get-price-info",
  LoadingComplete = "loading-complete",
  LogOut = "logout",
  ManageSubscription = "manage-subscription",
  NativeAuthenication = "native-auth",
  NativeReview = "native-review",
  openChildSection = "open-child-section",
  PassAuthToken = "pass-auth-token",
  Subscribe = "subscribe",
  SubscribeProduct = "subscribe-product",
  TaskValidation = "task-validation",
  ToggleRoutineNotifications = "toggle-routine-notifications",
}

export enum UnityAuthProvider {
  Apple = "apple",
  Google = "google",
}

export enum UnityAuthAction {
  Signup = "signup",
  Login = "login",
}

export enum ExternalLink {
  ADHDTips = "adhdtips",
  ADHDTipsEN = "adhdtipsen",
  ADHDTipsFR = "adhdtipsfr",
  CliniqueFocus = "cliniquefocus",
  Development = "development",
  DevelopmentEN = "developmenten",
  DevelopmentFR = "developmentfr",
  Medication = "medication",
  MedicationEN = "medicationen",
  MedicationFR = "medicationfr",
  MedicationTable = "medicationtable",
  MedicationTableEN = "medicationtableen",
  MedicationTableFR = "medicationtablefr",
  ParentalControls = "parentalcontrols",
  ParentalControlsEN = "parentalcontrolsen",
  ParentalControlsFR = "parentalcontrolsfr",
  PrivacyDE = "privacyde",
  PrivacyEN = "privacyen",
  PrivacyFR = "privacyfr",
  ScreenTime = "screentime",
  ScreenTimeEN = "screentimeen",
  ScreenTimeFR = "screentimefr",
  SpaceManagement = "spacemanagement",
  Store = "store",
  Support = "support",
  TermsDE = "termsde",
  TermsEN = "termsen",
  TermsFR = "termsfr",
  TimeManagement = "timemanagement",
  TimeManagementEN = "timemanagementen",
  TimeManagementFR = "timemanagementfr",
  WebApp = "webapp",
  WebAppForgottenPassword = "webappForgottenPassword",
  WebAppSettings = "webappSettings",
}
