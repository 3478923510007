import axios from "axios";
import {
  GenerateMedicalPdfArgs,
  GenerateRoutinePdfArgs,
  Language,
} from "@neurosolutionsgroup/models";
import { getHeaders, getURL, handleApiError } from "./utils";

const getMedicalPdf = async (args: GenerateMedicalPdfArgs): Promise<Blob> => {
  const PATH = "/dataExport/medical";

  const headers = {
    ...(await getHeaders(true, "getMedicalPdf")),
    Accept: "application/pdf",
  };

  try {
    const res = await axios.get(getURL() + PATH, {
      responseType: "blob",
      headers,
      params: args,
      timeout: 60000,
    });

    const blob = new Blob([res.data], {
      type: "application/pdf",
    });

    return blob;
  } catch (err) {
    return handleApiError(err, true);
  }
};

const getRoutinePdf = async (args: GenerateRoutinePdfArgs): Promise<Blob> => {
  const PATH = "/dataExport/routine";

  const headers = {
    ...(await getHeaders(true, "getRoutinePdf")),
    Accept: "application/pdf",
  };

  try {
    const res = await axios.get(getURL() + PATH, {
      responseType: "blob",
      headers,
      params: args,
      timeout: 60000,
    });

    const blob = new Blob([res.data], {
      type: "application/pdf",
    });

    return blob;
  } catch (err) {
    return handleApiError(err, true);
  }
};

const getSymptomPdf = async (args: GenerateMedicalPdfArgs): Promise<Blob> => {
  const PATH = "/dataExport/symptom";

  const headers = {
    ...(await getHeaders(true, "getSymptomPdf")),
    Accept: "application/pdf",
  };

  try {
    const res = await axios.get(getURL() + PATH, {
      responseType: "blob",
      headers,
      params: args,
      timeout: 60000,
    });

    const blob = new Blob([res.data], {
      type: "application/pdf",
    });

    return blob;
  } catch (err) {
    return handleApiError(err, true);
  }
};

const sendFollowUpsExportemail = async (
  childrenIds: string[],
  language: Language
) => {
  const PATH = "/dataExport/medical/emailexport";

  const headers = {
    ...(await getHeaders(true, "sendEmailExport")),
  };

  const body = {
    childrenIds,
    language,
  };

  try {
    const res = await axios.post(getURL() + PATH, body, {
      headers,
    });

    return res;
  } catch (err) {
    return handleApiError(err);
  }
};

const DataExport = {
  getMedicalPdf,
  getRoutinePdf,
  getSymptomPdf,
  sendFollowUpsExportemail,
};

export default DataExport;
