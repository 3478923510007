import { NotificationsSettings } from "@neurosolutionsgroup/models";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import NotificationCategorySettingsGetterService from "./NotificationSettingsGetter";

const getSettingsFirebase = async (): Promise<NotificationsSettings> => {
  return FirebaseAPI.Account.getNotificationSettings();
};

const FirebaseSettingsGetterAdapter: NotificationCategorySettingsGetterService =
  {
    getSettings: getSettingsFirebase,
  };

export default FirebaseSettingsGetterAdapter;
