import { SxProps, Theme, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ChildAgeWarningProps {
  show: boolean;
  sx?: SxProps<Theme>;
}

const ChildAgeWarning = ({
  show,
  sx,
}: ChildAgeWarningProps): JSX.Element | null => {
  const { t } = useTranslation();

  return show ? (
    <Typography sx={sx} color="error">
      {t("settings.child.ageWarning")}
    </Typography>
  ) : null;
};

export default ChildAgeWarning;
