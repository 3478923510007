import { Tools } from "@neurosolutionsgroup/tools";
import {
  Product,
  SubscriptionScreen1,
  SubscriptionScreen2,
  SubscriptionScreenNoTrial,
} from "@neurosolutionsgroup/webviews-pages";
import SubscriptionAssets from "assets/subscription";
import React, { useEffect, useMemo, useState } from "react";
import { PropsWithChildren } from "react";
import { SAFE_AREAS } from "stylesheets";
import useParameters from "../Parameters/useParameters";
import FirebaseAPI from "@neurosolutionsgroup/api-client";
import {
  FirestoreCollection,
  MedPeriod,
  PriceInfo,
  RoutinePeriod,
  SubscriptionStatus,
  Template,
} from "@neurosolutionsgroup/models";
import { UniWebViewActions } from "../Parameters/UniWebViewActions";
import { CustomWindow } from "custom.window";
import { ControlledDrawer } from "@neurosolutionsgroup/components";
import { Box, Button, Typography } from "@mui/material";
import { doc, getFirestore, updateDoc } from "firebase/firestore";
import useAuth from "../auth/useAuth";
import userMustDowngrade from "./userMustDowngrade";
import { useChildrenContext } from "../children/ChildrenContext";
import { useRoutineContext } from "../routines/RoutinesContext";
import { useErrorsContext } from "../errors/ErrorContext";
import {
  createPremiumFeatureEvent,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import { useProfileContext } from "../account/UserProfileContext";
import { useChallengeContext } from "../challenges/ChallengesContext";
import { add } from "date-fns";

declare let window: CustomWindow;

export interface Permissions {
  allIcons: boolean;
  allMedicalPeriods: boolean;
  allRoutinePeriods: boolean;
  challenges: boolean;
  chatbot: boolean;
  childCreation: boolean;
  export: boolean;
  multipleRoutines: boolean;
  progressGraph: boolean;
  routineTemplates: boolean;
  tasksPerRoutine: number;
}

export const premiumRoutineTemplates: Template[] = [
  Template.Afternoon,
  Template.Evening,
  Template.Midday,
  Template.Other,
];

export const freeIcons: number[] = [
  2, 3, 4, 5, 6, 7, 8, 9, 10, 17, 20, 22, 26, 36, 42, 43, 45, 49, 84, 85, 90,
  91, 94, 95, 100, 101, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113, 114,
  115, 116, 117, 118, 119,
];

export const freeRoutinePeriods: RoutinePeriod[] = [
  RoutinePeriod.Today,
  RoutinePeriod.ThisWeek,
  RoutinePeriod.LastTwoWeeks,
];

export const freeMedicalPeriods: MedPeriod[] = [MedPeriod.LastTwoWeeks];

const freePermissions: Permissions = {
  allIcons: false,
  allMedicalPeriods: false,
  allRoutinePeriods: false,
  challenges: false,
  chatbot: false,
  childCreation: false,
  export: false,
  multipleRoutines: false,
  progressGraph: false,
  routineTemplates: false,
  tasksPerRoutine: 5,
};

const premiumPermissions: Permissions = {
  allIcons: true,
  allMedicalPeriods: true,
  allRoutinePeriods: true,
  challenges: true,
  chatbot: true,
  childCreation: true,
  export: true,
  multipleRoutines: true,
  progressGraph: true,
  routineTemplates: true,
  tasksPerRoutine: 9,
};

interface SubscriptionContextData {
  loading: boolean;
  onDowngradeConfirm: (
    selectedChildId: string | null,
    selectedRoutineId: string | null
  ) => Promise<void>;
  onDowngradeReactivate: VoidFunction;
  onShowSubscriptionScreen: (debugNoTrial?: boolean) => void;
  permissions: Permissions;
  priceInfo: PriceInfo | undefined;
  showDowngradeDialog: boolean;
  subscriptionStatus: SubscriptionStatus | null;
}

const [useSubscriptionContext, SubscriptionContextProvider] =
  Tools.Context.createGenericContext<SubscriptionContextData>(
    "SubscriptionContext"
  );

const SubscriptionProvider = (props: PropsWithChildren): JSX.Element => {
  const { handleEvent } = useAnalytics();
  const { user } = useAuth();
  const { handleUnknownError } = useErrorsContext();
  const { os, sendMessageToUnity, priceInfo } = useParameters();

  const { challenges } = useChallengeContext();
  const { childrenById, refreshChildren, setSelectedChild } =
    useChildrenContext();
  const { refreshUserProfile } = useProfileContext();
  const { routinesById, refreshRoutines } = useRoutineContext();

  const store = os === "ios" ? "Apple App Store" : "Google Play Store";

  const osStoreMap: { android: string; ios: string } = {
    android: "play_store",
    ios: "app_store",
  };

  const [loading, setLoading] = useState(false);

  const [showSubscriptionScreen1, setShowSubscriptionScreen1] = useState(false);
  const [showSubscriptionScreen2, setShowSubscriptionScreen2] = useState(false);
  const [showSubscriptionScreenNoTrial, setShowSubscriptionScreenNoTrial] =
    useState(false);

  const [showDowngradeDialog, setShowDowngradeDialog] = useState(false);

  const [openMockDrawer, setOpenMockDrawer] = useState(false);

  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus | null>(null);

  const permissions = useMemo(
    () =>
      subscriptionStatus?.isSubscribed ? premiumPermissions : freePermissions,
    [subscriptionStatus]
  );

  useEffect(() => {
    if (!priceInfo) {
      sendMessageToUnity(UniWebViewActions.GetPriceInfo);
    }
  }, [priceInfo]);

  useEffect(() => {
    const getSubscriptionStatus = async () => {
      const result =
        await FirebaseAPI.Billing.Subscription.getSubscriptionStatus();

      setSubscriptionStatus(result);
    };

    getSubscriptionStatus();
  }, []);

  useEffect(() => {
    if (subscriptionStatus) {
      if (!priceInfo) {
        sendMessageToUnity(UniWebViewActions.GetPriceInfo);
      }

      setShowDowngradeDialog(
        userMustDowngrade(
          subscriptionStatus,
          Object.values(childrenById),
          Object.values(routinesById),
          challenges
        )
      );
    }
  }, [subscriptionStatus, childrenById, routinesById]);

  const onShowSubscriptionScreen = (debugNoTrial?: boolean) => {
    if (debugNoTrial !== undefined) {
      if (debugNoTrial) {
        setShowSubscriptionScreenNoTrial(true);
      } else {
        setShowSubscriptionScreen1(true);
      }
    } else {
      if (!priceInfo) {
        sendMessageToUnity(UniWebViewActions.GetPriceInfo);
      }

      let hasPreviousSubscriptionOnThisOS = false;

      if (subscriptionStatus && subscriptionStatus?.hasPreviousSubscription) {
        if (
          subscriptionStatus.previousStores.some(
            (value) => value === osStoreMap[os]
          )
        ) {
          hasPreviousSubscriptionOnThisOS = true;
        }
      }

      if (hasPreviousSubscriptionOnThisOS) {
        setShowSubscriptionScreenNoTrial(true);
      } else {
        setShowSubscriptionScreen1(true);
      }
    }
  };

  const onSubscriptionScreen1Cancel = () => {
    setShowSubscriptionScreen1(false);
  };

  const onSubscriptionScreen1Confirm = () => {
    setShowSubscriptionScreen1(false);
    setShowSubscriptionScreen2(true);
  };

  const onSubscriptionScreen2Cancel = () => {
    setShowSubscriptionScreen2(false);
    setShowSubscriptionScreen1(true);
  };

  const onSubscriptionScreenNoTrialCancel = () => {
    setShowSubscriptionScreenNoTrial(false);
  };

  const onPlanChosen = (product: Product) => {
    setLoading(true);

    if (Tools.Environment.isDevBuild()) {
      setOpenMockDrawer(true);
    } else {
      sendMessageToUnity(
        UniWebViewActions.SubscribeProduct,
        `product=${product}`
      );
    }
  };

  const refreshSubscriptionStatusAfterPurchase = async () => {
    const result =
      await FirebaseAPI.Billing.Subscription.getSubscriptionStatus();

    // Don't overwrite isSubscribed to make sure we don't break immediate usage.
    setSubscriptionStatus((current) => ({
      ...result,
      isSubscribed: current?.isSubscribed ?? result.isSubscribed,
    }));
  };

  const refeshDataOnReupgrade = async () => {
    await FirebaseAPI.Account.reupgrade();

    await Promise.all([
      refreshUserProfile(),
      refreshRoutines(),
      refreshChildren(),
    ]);
  };

  window.subscriptionComplete = (status: boolean) => {
    setOpenMockDrawer(false);

    if (status) {
      const storeKey = os === "ios" ? "app_store" : "play_store";

      setSubscriptionStatus((current) =>
        current
          ? {
              ...current,
              isSubscribed: true,
              hasPreviousSubscription: true,
              store: storeKey,
              previousStores: [...current.previousStores, storeKey],
            }
          : {
              isSubscribed: true,
              hasPreviousSubscription: true,
              store: storeKey,
              previousStores: [storeKey],
              lastDowngradeExecution: null,
              isFreeTrial: false,
              nextExpiration: null,
            }
      );

      refeshDataOnReupgrade();
      refreshSubscriptionStatusAfterPurchase();

      setShowSubscriptionScreen2(false);
      setShowSubscriptionScreenNoTrial(false);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onDowngradeConfirm = async (
    selectedChildId: string | null,
    selectedRoutineId: string | null
  ) => {
    setLoading(true);

    try {
      await FirebaseAPI.Account.downgrade({
        selectedChildId,
        selectedRoutineId,
      });

      setSelectedChild(selectedChildId);

      setSubscriptionStatus((current) =>
        current
          ? {
              ...current,
              lastDowngradeExecution: Tools.Time.Dates.getTimeStamp(),
            }
          : current
      );

      await Promise.all([
        refreshUserProfile(),
        refreshRoutines(),
        refreshChildren(),
      ]);

      setShowDowngradeDialog(false);
    } catch (err) {
      handleUnknownError(err);
    } finally {
      setLoading(false);
    }
  };

  const onDowngradeReactivate = () => {
    const event = createPremiumFeatureEvent(PremiumFeature.Downgrade);

    handleEvent(event);

    onShowSubscriptionScreen();
  };

  return (
    <SubscriptionContextProvider
      value={{
        loading,
        onDowngradeConfirm,
        onDowngradeReactivate,
        onShowSubscriptionScreen,
        permissions,
        priceInfo,
        showDowngradeDialog,
        subscriptionStatus,
      }}
    >
      <SubscriptionScreen1
        onCancel={onSubscriptionScreen1Cancel}
        onConfirm={onSubscriptionScreen1Confirm}
        open={showSubscriptionScreen1}
        safeAreas={SAFE_AREAS}
        subscriptionCancelImgSrc={SubscriptionAssets.Cancel}
        subscriptionDecorationImgSrc={SubscriptionAssets.Decoration}
        sx={{
          zIndex: 10001,
        }}
      />
      <SubscriptionScreen2
        loading={loading}
        onCancel={onSubscriptionScreen2Cancel}
        onConfirm={onPlanChosen}
        open={showSubscriptionScreen2}
        safeAreas={SAFE_AREAS}
        priceInfo={priceInfo}
        store={store}
        subscriptionCalendarImgSrc={SubscriptionAssets.Calendar}
        sx={{
          zIndex: 10001,
        }}
      />
      <SubscriptionScreenNoTrial
        loading={loading}
        open={showSubscriptionScreenNoTrial}
        onCancel={onSubscriptionScreenNoTrialCancel}
        onConfirm={onPlanChosen}
        safeAreas={SAFE_AREAS}
        priceInfo={priceInfo}
        store={store}
        subscriptionDecorationImgSrc={SubscriptionAssets.Decoration}
        sx={{
          zIndex: 10001,
        }}
      />
      <ControlledDrawer
        open={openMockDrawer}
        onClose={() => {}}
        sx={{ zIndex: 10002 }}
      >
        <Box m={1} data-cy="mock-store-page">
          <Typography textAlign="center" variant="h3">
            Mock Store
          </Typography>
          <Typography my={1}>
            This is a component only available in staging that mocks the
            behaviour of the store.
          </Typography>
          <Box display="flex" justifyContent="center" mt={1}>
            <Button
              variant="contained"
              color="error"
              sx={{ marginRight: 2 }}
              onClick={() => {
                window.subscriptionComplete(false);
              }}
            >
              Fail Payment
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={async () => {
                if (user) {
                  const db = getFirestore();

                  await updateDoc(
                    doc(db, FirestoreCollection.Users, user.uid),
                    {
                      isSubscribed: true,
                      isFreeTrial: !subscriptionStatus?.hasPreviousSubscription,
                      nextExpiration: Tools.Time.Dates.getTimeStamp(
                        add(new Date(), { days: 7 })
                      ),
                      store: osStoreMap[os],
                      previousStores: [osStoreMap[os]],
                      hasPreviousSubscription: true,
                    }
                  );
                }

                window.subscriptionComplete(true);
              }}
              data-cy="mock-store-success"
            >
              Succeed Payment
            </Button>
          </Box>
        </Box>
      </ControlledDrawer>
      {props.children}
    </SubscriptionContextProvider>
  );
};

export { useSubscriptionContext, SubscriptionProvider };
