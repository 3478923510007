import {
  CreatePrivateMedicationBody,
  Medication as MedicationModel,
} from "@neurosolutionsgroup/models";
import { getHeaders, getURL, handleApiError } from "../utils";
import axios from "axios";

const createNewMedication = async (
  newMedication: MedicationModel
): Promise<void> => {
  const URL = getURL() + "/api/medication";

  const body: CreatePrivateMedicationBody = {
    medication: newMedication,
  };

  const headers = await getHeaders(true, "createNewMedication");

  try {
    const result = await axios.post(URL, body, {
      headers,
    });

    return result.data;
  } catch (err) {
    return handleApiError(err);
  }
};

const Medication = {
  createNewMedication,
};

export default Medication;
