import {
  BackgroundHexagons,
  FullscreenPage,
  HexagonPlacement,
  PageContentContainer,
} from "@neurosolutionsgroup/components";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { PropsWithChildren } from "react";

interface FirstRoutineScreenComponentProps extends PropsWithChildren {
  safeAreas: SafeAreas;
}

const FirstRoutineScreenComponent = ({
  safeAreas,
  ...props
}: FirstRoutineScreenComponentProps): JSX.Element => {
  const hexagons: HexagonPlacement[] = [
    { size: 70, rotation: 35, top: -30, right: 30 },
    { size: 150, rotation: 10, top: "15%", left: -30 },
    { size: 70, rotation: 0, top: "30%", right: -5 },
    { size: 70, rotation: 30, top: "50%", left: "30%" },
    { size: 80, rotation: 10, bottom: -30, left: -20 },
    { size: 150, rotation: 10, bottom: -50, right: 20 },
  ];

  return (
    <FullscreenPage
      sx={{
        backgroundColor: "primary.main",
      }}
    >
      <BackgroundHexagons hexagons={hexagons} />
      <PageContentContainer safeAreas={safeAreas}>
        {props.children}
      </PageContentContainer>
    </FullscreenPage>
  );
};

export default FirstRoutineScreenComponent;
