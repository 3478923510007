import React, {
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useRef,
} from "react";
import clsx from "clsx";
import { Drawer } from "common/Components";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { ButtonBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { CypressProps } from "@neurosolutionsgroup/models";

interface CardProps extends CypressProps {
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<SetStateAction<boolean>>;
  menuElements?: JSX.Element[];
  className?: string;
  menuTitle?: JSX.Element;
}

const Card = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<CardProps> & ReactHTMLDivProps
>(
  (
    { menuElements, className, menuOpen, setMenuOpen, menuTitle, ...props },
    ref
  ) => {
    const rootClass = "parent-card";

    const { t } = useTranslation();

    const divRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (menuOpen) {
        divRef.current?.scrollIntoView({
          block: "end",
          behavior: "smooth",
        });
      }
    }, [menuOpen]);

    return (
      <>
        <div className={rootClass + "__wrapper"} ref={ref}>
          <div
            {...props}
            className={clsx(rootClass, className, {
              [rootClass + "--menu-open"]: menuOpen,
            })}
            onClick={(e) =>
              props.onClick
                ? props.onClick(e)
                : setMenuOpen((current) => !current)
            }
          >
            {props.children}
          </div>
        </div>
        <Drawer open={menuOpen} onClose={() => setMenuOpen(false)}>
          <DrawerSection variant="header">
            {menuTitle ? (
              menuTitle
            ) : (
              <Typography variant="h4">{t("general.options")}</Typography>
            )}
          </DrawerSection>
          {menuElements?.map((me, i) => (
            <DrawerSection key={i}>{me}</DrawerSection>
          ))}
        </Drawer>
      </>
    );
  }
);

export default Card;

interface CardMenuElementProps extends CypressProps {
  id?: string;
  icon: JSX.Element;
  text: string;
}

export const CardMenuElement: React.FC<
  CardMenuElementProps & ReactHtmlButtonProps
> = ({ id, icon, text, onClick, disabled, ...props }) => {
  return (
    <ButtonBase
      id={id}
      onClick={onClick}
      data-cy={props["data-cy"]}
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        opacity: disabled ? 0.5 : 1,
      }}
      disabled={disabled}
    >
      <div className="parent-card__menu-element-icon">{icon}</div>
      <Typography className="parent-card__menu-element-label" fontSize="0.9em">
        {text}
      </Typography>
    </ButtonBase>
  );
};
