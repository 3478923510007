import { Button, Grid } from "@mui/material";
import React, { SetStateAction } from "react";

interface AMPMSelectorProps {
  isAM: boolean;
  setIsAM: React.Dispatch<SetStateAction<boolean>>;
}

const AMPMSelector = ({ isAM, setIsAM }: AMPMSelectorProps): JSX.Element => {
  return (
    <Grid container justifyContent="center">
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant={isAM ? "contained" : undefined}
          color="secondary"
          onClick={() => setIsAM(true)}
          sx={{
            minWidth: "fit-content",
            margin: "auto",
            borderRadius: "15px",
          }}
        >
          AM
        </Button>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          variant={!isAM ? "contained" : undefined}
          color="secondary"
          onClick={() => setIsAM(false)}
          sx={{
            minWidth: "fit-content",
            margin: "auto",
            borderRadius: "15px",
          }}
        >
          PM
        </Button>
      </Grid>
    </Grid>
  );
};

export default AMPMSelector;
