import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const CircledCheckMarkIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="check-circled" {...props}>
      <>
        <path
          d="m 10,1.3157875 c -4.7961563,0 -8.6842125,3.8880562 -8.6842125,8.6842125 0,4.796188 3.8880562,8.684187 8.6842125,8.684187 4.796188,0 8.684187,-3.887999 8.684187,-8.684187 C 18.684187,5.2038437 14.796188,1.3157875 10,1.3157875 Z M 0,10 C 0,4.47715 4.47715,0 10,0 15.522875,0 20,4.47715 20,10 20,15.522875 15.522875,20 10,20 4.47715,20 0,15.522875 0,10 Z"
          fill={color}
          id="path17"
        />
        <path
          d="M 16.623407,6.0266793 7.9434072,15.930124 2.6234068,11.249457 3.6492958,10.090568 7.8034072,13.735234 15.084095,5.531349 Z"
          fill={color}
          id="path98"
        />
      </>
    </BaseIcon>
  );
};

export default CircledCheckMarkIcon;
