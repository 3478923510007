import { Box, SxProps, Theme, useTheme } from "@mui/material";

interface PremiumTagProps {
  text: string;
  fontSize?: number;
  sx?: SxProps<Theme>;
}

const PremiumTag = ({
  text,
  fontSize = 18,
  sx,
}: PremiumTagProps): JSX.Element => {
  const { palette } = useTheme();

  const scale = fontSize / 18;

  const outlineSize = Math.ceil(fontSize / 10);

  const outlineSizeText = `${outlineSize.toFixed(0)}px`;

  const clipInset = `${(scale * 0.6).toFixed(2)}rem`;

  const paddingX = `${(scale * 0.75).toFixed(2)}rem`;

  const paddingY = `${(scale * 0.3).toFixed(2)}rem`;

  return (
    <Box
      sx={[
        {
          fontSize,
          display: "inline-block",
          color: palette.success.contrastText,
          fontFamily: "Ander Hedge",
          letterSpacing: ".1rem",
          textShadow: `${outlineSizeText} ${outlineSizeText} 1px #39A340, -${outlineSizeText} ${outlineSizeText} 1px #39A340, -${outlineSizeText} -${outlineSizeText} 1px #39A340, ${outlineSizeText} -${outlineSizeText} 1px #39A340, 0 ${outlineSizeText} 1px #39A340, 0 -${outlineSizeText} 1px #39A340`,
          filter: "drop-shadow(0 3px 3px rgba(0, 0, 0, 0.25))",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        sx={{
          paddingX,
          paddingY,
          background: `linear-gradient(150deg, ${palette.success.main}, ${palette.success.main} 50%, ${palette.success.light} 80%, ${palette.success.light})`,
          clipPath: `polygon(${clipInset} 0, calc(100% - ${clipInset}) 0, 100% 50%, calc(100% - ${clipInset}) 100%, ${clipInset} 100%, 0 50%)`,
          lineHeight: "1rem",
        }}
      >
        {text}
      </Box>
    </Box>
  );
};

export default PremiumTag;
