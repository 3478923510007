interface HexagonProps {
  color?: "black" | "white";
  opacity?: number;
  size?: number;
  rotation?: number;
}

const Hexagon = ({
  color = "white",
  opacity = 0.2,
  size = 20,
  rotation = 0,
}: HexagonProps): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        transform={`rotate(${rotation}, 10, 10)`}
        opacity={opacity}
        d="M 18.623876,4.2740406 C 15.724082,2.8547043 12.824288,1.4353679 9.9244945,0.01603158 7.0498468,1.9247216 4.1751992,3.8334116 1.3005515,5.7421016 c 0.025153,3.3280175 0.050307,6.6560354 0.07546,9.9840524 2.8997938,1.419272 5.7995877,2.838543 8.6993815,4.257815 2.874685,-1.908653 5.74937,-3.817306 8.624055,-5.725959 -0.02519,-3.32799 -0.05038,-6.6559796 -0.07557,-9.9839694 z"
        fill={color === "white" ? "#ffffff" : "#000000"}
        id="path6"
      />
    </svg>
  );
};

export default Hexagon;
