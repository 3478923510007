import MainPageButton from "./MainPageButton";

interface ChildButtonProps {
  childIcon: string;
  childName: string;
  onClick: VoidFunction;
}

const ChildButton = ({
  childName,
  childIcon,
  onClick,
}: ChildButtonProps): JSX.Element => {
  return (
    <MainPageButton content={childName} imgSrc={childIcon} onClick={onClick} />
  );
};

export default ChildButton;
