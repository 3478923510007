import { useCallback, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { CypressProps, Language } from "@neurosolutionsgroup/models";
import { useTranslation } from "react-i18next";
import TimePicker from "../TimePicker/TimePicker";

export interface TimeRangePickerProps extends CypressProps {
  start: number | null;
  setStart: (value: number) => void;
  end: number | null;
  setEnd: (value: number) => void;
  language: Language;
  startMin?: number;
  startMax?: number;
  endMin?: number;
  endMax?: number;
  error?: boolean;
  validatePeriod?: (start: number, end: number) => string | undefined;
}

const TimeRangePicker = ({
  start,
  setStart,
  end,
  setEnd,
  language,
  startMin,
  startMax,
  endMin,
  endMax,
  error,
  validatePeriod,
  ...props
}: TimeRangePickerProps): JSX.Element => {
  const dataCy = props["data-cy"] ?? "time-range-picker";

  const { t } = useTranslation();

  useEffect(() => {
    // Don't let end be earlier than start.
    if (start && end && start > end) {
      // Last end time possible 23h59.
      setEnd(start === 85500 ? 86340 : start + 900);
    }
  }, [start]);

  const externalValidationStart = useCallback(
    (newStart: number): string | undefined => {
      if (!validatePeriod || !end) {
        return undefined;
      }

      return validatePeriod(newStart, end);
    },
    [end, validatePeriod]
  );

  const externalValidationEnd = useCallback(
    (newEnd: number): string | undefined => {
      if (!validatePeriod || !start) {
        return undefined;
      }

      return validatePeriod(start, newEnd);
    },
    [start, validatePeriod]
  );

  return (
    <div className="time-range-picker" data-cy={dataCy}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Typography textAlign="center" fontSize="0.75rem">
            {t("general.start")}
          </Typography>
          <TimePicker
            data-cy={dataCy + "-start"}
            label={t("general.start")}
            time={start}
            setTime={setStart}
            language={language}
            min={startMin}
            max={startMax}
            error={error}
            validateTime={externalValidationStart}
            fullWidth
          />
        </Grid>

        <Grid item xs={6}>
          <Typography textAlign="center" fontSize="0.75rem">
            {t("general.end")}
          </Typography>
          <TimePicker
            data-cy={dataCy + "-end"}
            label={t("general.end")}
            time={end}
            setTime={setEnd}
            language={language}
            min={endMin}
            max={endMax}
            error={error}
            validateTime={externalValidationEnd}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default TimeRangePicker;
