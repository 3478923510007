import { Box, Grid } from "@mui/material";
import Intro from "./Screens/Intro";
import { FullScreenFTUEProps } from "components";
import { FTUEFlow } from "models";
import useFTUE from "useFTUE";
import { DashboardProgress } from "../DashboardProgress";

const FTUEDashboardScreen = (
  props: FullScreenFTUEProps
): JSX.Element | null => {
  const {
    selectors: { progress },
    actions: { canDisplayFlow },
  } = useFTUE();

  const dashboardProgress = progress[FTUEFlow.Dashboard].progress;

  const showFullScreen = () => {
    const fullScreenSteps = [DashboardProgress.None];
    return fullScreenSteps.includes(dashboardProgress);
  };

  const renderFTUESection = () => {
    switch (dashboardProgress) {
      case DashboardProgress.None:
        return <Intro {...props} />;
      default:
        return null;
    }
  };

  return canDisplayFlow(FTUEFlow.Dashboard) && showFullScreen() ? (
    <Box
      sx={{
        position: "fixed",
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "secondary.main",
        // FIXME: Try using Hexagons.
        // backgroundImage: `url(${BackgroundAssets.BlueBackground})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        paddingTop: `calc(0.5rem + ${props.safeAreas.top})`,
      }}
      p={1}
    >
      <Grid
        container
        direction="column"
        spacing={1}
        height="100%"
        width="100%"
        margin="0 auto"
      >
        {renderFTUESection()}
      </Grid>
    </Box>
  ) : null;
};

export default FTUEDashboardScreen;
