const CalendarIcon = (): JSX.Element => {
  return (
    <svg
      width="155"
      height="166"
      viewBox="0 0 155 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33.9133 16.9258H120.803C136.802 16.9258 149.716 29.477 149.716 44.8926V129.033C149.716 144.449 136.802 157 120.803 157H33.9133C17.9144 157 5 144.449 5 129.033V44.8926C5 29.477 17.9144 16.9258 33.9133 16.9258Z"
        fill="white"
        stroke="#31737C"
        strokeWidth="2"
      />
      <path
        d="M31.614 15.9258H123.154C138.366 15.9258 150.716 27.8855 150.716 42.6159V54.3598H4V42.666C4 27.9068 16.3725 15.9258 31.614 15.9258Z"
        fill="#33737C"
      />
      <path
        d="M45.7343 90.6451L38.9367 93.6833L32.8203 89.5037L33.4999 82.2829L40.2975 79.2432L46.4138 83.4243L45.7343 90.6451Z"
        fill="#33737C"
      />
      <path
        d="M83.0907 90.8765L76.2931 93.9147L70.1768 89.7351L70.8579 82.5143L77.6555 79.4746L83.7719 83.6557L83.0907 90.8765Z"
        fill="#33737C"
      />
      <path
        d="M119.918 90.8765L113.12 93.9147L107.004 89.7351L107.685 82.5143L114.483 79.4746L120.599 83.6557L119.918 90.8765Z"
        fill="#33737C"
      />
      <path
        d="M47.0807 0.578125H46.5722C43.2046 0.578125 40.4746 3.22171 40.4746 6.48275V23.7285C40.4746 26.9895 43.2046 29.6331 46.5722 29.6331H47.0807C50.4483 29.6331 53.1782 26.9895 53.1782 23.7285V6.48275C53.1782 3.22171 50.4483 0.578125 47.0807 0.578125Z"
        fill="#9CBCBB"
      />
      <path
        d="M109.202 0H108.693C105.326 0 102.596 2.64359 102.596 5.90462V23.1504C102.596 26.4114 105.326 29.055 108.693 29.055H109.202C112.569 29.055 115.299 26.4114 115.299 23.1504V5.90462C115.299 2.64359 112.569 0 109.202 0Z"
        fill="#9CBCBB"
      />
      <path
        d="M47.0184 124.854L40.2209 127.892L34.1045 123.713L34.7857 116.492L41.5832 113.452L47.6996 117.633L47.0184 124.854Z"
        fill="#33737C"
      />
      <path
        d="M84.3749 125.09L77.5789 128.128L71.4609 123.948L72.1421 116.728L78.9397 113.689L85.056 117.869L84.3749 125.09Z"
        fill="#33737C"
      />
      <path
        d="M121.202 125.09L114.406 128.128L108.288 123.948L108.969 116.728L115.767 113.689L121.883 117.869L121.202 125.09Z"
        fill="#33737C"
      />
    </svg>
  );
};

export default CalendarIcon;
