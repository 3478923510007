import { PrescriptionsProgress } from "./PrescriptionsProgress";

const calculateProgress = (step: PrescriptionsProgress): number => {
  return (
    Object.values(PrescriptionsProgress).findIndex(
      (v) => v === step.toString()
    ) - 1
  );
};

const getProgress = (recordIds: string[]): PrescriptionsProgress => {
  let latestPrescriptionStep = PrescriptionsProgress.None;

  if (
    recordIds.includes(PrescriptionsProgress.Finished.toString()) ||
    recordIds.includes(PrescriptionsProgress.Confirmation.toString())
  ) {
    latestPrescriptionStep = PrescriptionsProgress.Finished;
  } else if (
    recordIds.includes(PrescriptionsProgress.MedicationSelection.toString())
  ) {
    latestPrescriptionStep = PrescriptionsProgress.TrigerMedication;
  } else {
    Object.values(PrescriptionsProgress).forEach((v) => {
      if (recordIds.includes(v)) {
        latestPrescriptionStep = v;
      }
    });
  }

  const stepsToRollback = [PrescriptionsProgress.Intro];

  if (stepsToRollback.includes(latestPrescriptionStep)) {
    latestPrescriptionStep = PrescriptionsProgress.Later;
  }

  return latestPrescriptionStep;
};

const isComplete = (
  progress: PrescriptionsProgress,
  userHasPrescription: boolean
): boolean => {
  return progress === PrescriptionsProgress.Finished || userHasPrescription;
};

const FTUEPrescriptionsLogic = {
  calculateProgress,
  getProgress,
  isComplete,
};

export default FTUEPrescriptionsLogic;
