import {
  Box,
  Button,
  Drawer,
  Input,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import useMedication from "common/hooks/medications/useMedication";
import useLanguage from "common/hooks/Parameters/useLanguage";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Medication, MedicationCategory } from "@neurosolutionsgroup/models";
import { Dialogs, Icons } from "@neurosolutionsgroup/components";
import { FTUEFlowDefinitions } from "@neurosolutionsgroup/webviews-ftue";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { Filter, StickyButtons } from "common/Components";
import { STICKY_BUTTONS_PAGE_PADDING } from "common/Components/Buttons/StickyButtons";

export interface MedicationPickerProps {
  open: boolean;
  setOpen: (value: boolean) => void;
  medicationSelected: string | null;
  onSubmit: (value: string) => void;
}

const MedicationPicker: React.FC<MedicationPickerProps> = ({
  open,
  setOpen,
  medicationSelected,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const { language } = useLanguage();
  const {
    selectors: { medicationById },
    actions: { createNewMedication },
  } = useMedication();
  const { handleUnknownError } = useErrorsContext();

  const howManyMedicationInCategory = (
    category: MedicationCategory
  ): number => {
    const temp: string[] = [];
    if (medicationById) {
      Object.entries(medicationById).forEach(([key, value]) => {
        if (value.category === category) {
          temp.push(key);
        }
      });
    }
    return temp.length;
  };

  const [nameFilter, setNameFilter] = useState<string>("");
  const [firstCustomMedicationDialog, setFirstCustomMedicationDialog] =
    useState<boolean>(false);

  const [activeCategoryFilter, setActiveCategoryFilter] = useState<
    MedicationCategory[]
  >(
    howManyMedicationInCategory(MedicationCategory.MyMedication) > 0
      ? [MedicationCategory.MyMedication]
      : [MedicationCategory.ADHD]
  );

  const [pickerMedication, setPickerMedication] = useState(medicationSelected);

  useEffect(() => {
    setPickerMedication(medicationSelected);
  }, [medicationSelected]);

  const filteredMedication = useCallback((): string[] => {
    const output: string[] = [];
    if (medicationById) {
      Object.entries(medicationById).forEach(([key, value]) => {
        if (isMedicationLookedFor(value)) {
          output.push(key);
        }
      });
    }
    return output;
  }, [nameFilter, medicationById, activeCategoryFilter, medicationById]);

  const isMedicationLookedFor = (medication: Medication): boolean => {
    return (
      isMedicationCategoryLookedFor(medication) &&
      isMedicationNameLookedFor(medication) &&
      !isMedicationGeneric(medication)
    );
  };

  const isMedicationCategoryLookedFor = (medication: Medication): boolean => {
    if (activeCategoryFilter.includes(medication.category)) {
      return true;
    }
    return false;
  };

  const isMedicationNameLookedFor = (medication: Medication): boolean => {
    if (
      medication.name[language]
        ?.toLowerCase()
        .includes(nameFilter.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const isMedicationGeneric = (medication: Medication): boolean => {
    if (medication.id === "00000000-0000-0000-0000-000000000000") {
      return true;
    }
    return false;
  };

  const activateCategory = (category: MedicationCategory) => {
    setActiveCategoryFilter([category]);
  };

  const deactivateAllCategory = () => {
    setActiveCategoryFilter([]);
  };

  const submitNewMedication = (name: string): Promise<void> => {
    try {
      createNewMedication(name);
    } catch (err: unknown) {
      handleUnknownError(err);
    }

    return Promise.resolve();
  };

  const onClickAddNewMedication = () => {
    howManyMedicationInCategory(MedicationCategory.MyMedication) === 0
      ? setFirstCustomMedicationDialog(true)
      : submitNewMedication(nameFilter);
    deactivateAllCategory();
    activateCategory(MedicationCategory.MyMedication);
  };

  const medicationAlreadyExiste = (): boolean => {
    let output = false;
    if (medicationById) {
      Object.values(medicationById).forEach((value) => {
        if (value.name[language] === nameFilter) {
          output = true;
        }
      });
    }
    return output;
  };

  const canAddNewMedication = (): boolean => {
    return howManyMedicationInCategory(MedicationCategory.MyMedication) < 25;
  };

  const onNext = () => {
    if (pickerMedication) {
      onSubmit(pickerMedication);
      setOpen(false);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <Drawer anchor="bottom" open={open} onClose={() => setOpen(false)}>
      <Box className="medication-picker">
        <DrawerSection variant="header">
          <Typography
            variant="h4"
            className="routine-template-selection__title"
          >
            {t("general.medication")}
          </Typography>
        </DrawerSection>
        <FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.MedicationSelection
          disableNext={!pickerMedication}
          handleOnNext={onNext}
        >
          <Box className="medication-picker__input--container" p={1}>
            <Input
              className="medication-picker__input"
              value={nameFilter}
              onChange={(event) => setNameFilter(event.target.value)}
              startAdornment={
                <Box px={1}>
                  <Icons.SearchIcon color="#c4c4c4" />
                </Box>
              }
              endAdornment={
                nameFilter.length > 0 ? (
                  <Box
                    className="medication-picker__input--end-adornment"
                    px={1}
                    pt={1}
                    onClick={() => setNameFilter("")}
                  >
                    <Icons.CloseIcon />
                  </Box>
                ) : null
              }
              disableUnderline={true}
              placeholder={t(
                "medication.followUp.edit.medicationPicker.searchMedication"
              )}
            />
          </Box>
          <Filter
            options={[
              ...(howManyMedicationInCategory(MedicationCategory.MyMedication) >
              0
                ? [
                    {
                      id: MedicationCategory.MyMedication,
                      label: t(
                        "medication.followUp.edit.medicationPicker.myMedicationFilter"
                      ),
                      count: howManyMedicationInCategory(
                        MedicationCategory.MyMedication
                      ),
                    },
                  ]
                : []),
              {
                id: MedicationCategory.ADHD,
                label: t(
                  "medication.followUp.edit.medicationPicker.adhdMedicationFilter"
                ),
                count: howManyMedicationInCategory(MedicationCategory.ADHD),
              },
            ]}
            value={activeCategoryFilter}
            onChange={setActiveCategoryFilter}
          />
          <Box
            className="medication-picker__list"
            sx={{
              paddingBottom: STICKY_BUTTONS_PAGE_PADDING,
            }}
          >
            <RadioGroup
              value={pickerMedication}
              onChange={(e) => setPickerMedication(e.target.value)}
            >
              {filteredMedication().map((id) => {
                return medicationById ? (
                  <Box
                    px={1}
                    key={id}
                    className="medication-picker__list--item"
                  >
                    <Box
                      display="flex"
                      alignItems="center"
                      sx={{ "& label": { display: "block", flexGrow: 1 } }}
                    >
                      <label htmlFor={id}>
                        {medicationById[id].name[language]}
                      </label>
                      <Radio id={id} value={id} color="secondary" />
                    </Box>
                    <hr />
                  </Box>
                ) : null;
              })}
            </RadioGroup>
            {nameFilter.length > 0 && !medicationAlreadyExiste() ? (
              <Box px={1} className="medication-picker__list--item">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{ "& label": { display: "block", flexGrow: 1 } }}
                >
                  <Box display="flex" flexDirection="column">
                    {!canAddNewMedication() ? (
                      <Typography fontSize={".75em"} color="error">
                        {t(
                          "medication.followUp.edit.medicationPicker.addMedication.cantAddNew",
                          {
                            medicationName: nameFilter,
                          }
                        )}
                      </Typography>
                    ) : null}
                    <Typography>
                      {t(
                        "medication.followUp.edit.medicationPicker.addMedication.text",
                        {
                          medicationName: nameFilter,
                        }
                      )}
                    </Typography>
                  </Box>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => onClickAddNewMedication()}
                    disabled={!canAddNewMedication()}
                  >
                    {t(
                      "medication.followUp.edit.medicationPicker.addMedication.button"
                    )}
                  </Button>
                </Box>
              </Box>
            ) : null}
          </Box>
          <StickyButtons
            onConfirm={onNext}
            onCancel={onClose}
            disabled={!pickerMedication}
          />
        </FTUEFlowDefinitions.PrescriptionsFTUEFlow.Hints.MedicationSelection>
      </Box>
      <Dialogs.AlertDialog
        title={t(
          "medication.followUp.edit.medicationPicker.firstCustomMedicationDialog.title"
        )}
        text={
          <Typography>
            {t(
              "medication.followUp.edit.medicationPicker.firstCustomMedicationDialog.text",
              { medication: nameFilter }
            )}
          </Typography>
        }
        positiveAction={{
          text: t("general.understood"),
          action: () => {
            setFirstCustomMedicationDialog(false);
            submitNewMedication(nameFilter);
          },
        }}
        open={firstCustomMedicationDialog}
      />
    </Drawer>
  );
};

export default MedicationPicker;
