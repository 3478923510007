import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Dialogs } from "@neurosolutionsgroup/components";
import { Tools } from "@neurosolutionsgroup/tools";

interface ChallengeSummaryProps {
  open: boolean;
  imgSrc: string;
  challengeName: string;
  numberOfTime: number;
  numberOfWeeks: number;
  days: number;
  childName: string;
  hasSpecifiedTime: boolean;
  thisWeek: boolean;
  onConfirm: VoidFunction;
  onBack: VoidFunction;
}

const ChallengeSummaryDialog = ({
  open,
  imgSrc,
  challengeName,
  childName,
  numberOfTime,
  numberOfWeeks,
  days,
  hasSpecifiedTime,
  thisWeek,
  onConfirm,
  onBack,
}: ChallengeSummaryProps): JSX.Element => {
  const { t } = useTranslation();

  const handleDialogContent = (): JSX.Element => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          img: {
            maxWidth: "200px",
          },
          width: "100%",
        }}
        data-cy="challenge-summary-dialog"
      >
        <img src={imgSrc} alt="" />

        <Box
          display={"flex"}
          flexDirection={"column"}
          alignItems={"left"}
          width={"100%"}
          mt={1}
          fontSize="0.8rem"
        >
          <Typography fontSize="inherit">
            {t("routine.challenge.summary.challengeName", {
              challengeName,
            })}
          </Typography>

          <Typography fontSize="inherit">{handleNameText()}</Typography>

          <br />

          <Typography component="ul" fontSize="inherit">
            <li>
              {t("routine.challenge.summary.numberOfTime", {
                count: numberOfTime,
              })}
            </li>
            <li>
              {t("routine.challenge.summary.numberOfWeeks", {
                count: numberOfWeeks,
              })}
            </li>
          </Typography>

          <br />

          <Typography fontSize="inherit">{handleStartText()}</Typography>
        </Box>
      </Box>
    );
  };

  const handleNameText = (): string => {
    const allDaysPossible = days === 0 || days === 127;

    if (allDaysPossible) {
      if (hasSpecifiedTime) {
        return t("routine.challenge.summary.childName.anyday", {
          childName,
        });
      }

      return t("routine.challenge.summary.childName.anytime", {
        childName,
      });
    }

    return t("routine.challenge.summary.childName.selectedDays", {
      childName,
    });
  };

  const handleStartText = (): string => {
    const allDaysPossible = days === 0 || days === 127;

    if (thisWeek) {
      const today = Tools.Time.Days.getKairosDay(new Date());

      const todaySelected = allDaysPossible
        ? true
        : Tools.Time.Days.dayInDays(today, days);

      if (todaySelected) {
        return t(
          "routine.challenge.summary.challengeStart.thisWeek.mondaySelected"
        );
      } else {
        return t(
          "routine.challenge.summary.challengeStart.thisWeek.mondayNotSelected"
        );
      }
    } else {
      const mondaySelected = Tools.Time.Days.dayInDays(
        Tools.Time.Days.dayIndexes[0],
        days
      );

      if (allDaysPossible || mondaySelected) {
        return t("routine.challenge.summary.challengeStart.nextWeek.monday");
      } else {
        return t("routine.challenge.summary.challengeStart.nextWeek.nextWeek");
      }
    }
  };

  return (
    <Dialogs.AlertDialog
      open={open}
      onClose={onBack}
      data-cy="challenge-summary-pop-up"
      title={t("routine.challenge.summary.title")}
      text={handleDialogContent()}
      positiveAction={{
        "action": onConfirm,
        "text": t("general.actions.confirm"),
        "variant": "contained",
        "color": "secondary",
        "data-cy": "challenge-summary-confirm",
      }}
      negativeAction={{
        "action": onBack,
        "text": t("routine.challenge.summary.back"),
        "variant": "text",
        "color": "error",
        "data-cy": "challange-summary-cancel",
      }}
    />
  );
};

export default ChallengeSummaryDialog;
