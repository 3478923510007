import {
  AccordionSummary,
  accordionSummaryClasses,
  AccordionSummaryProps,
  styled,
} from "@mui/material";
import Icons from "../../Icons";

const FormAccordionSummary = styled((props: AccordionSummaryProps) => (
  <AccordionSummary
    expandIcon={<Icons.ArrowIcon arrowType="empty" arrowDirection="down" />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  maxWidth: "100%",
  minHeight: theme.spacing(5),
  [`&.${accordionSummaryClasses.expanded}`]: {
    minHeight: theme.spacing(5),
  },
  [`& .${accordionSummaryClasses.content}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    wordWrap: "break-word",
    wordBreak: "break-all",
    [`&.${accordionSummaryClasses.expanded}`]: {
      margin: "8px 0",
    },
  },
  color: theme.palette.secondary.contrastText,
  fontSize: "1rem",
  fontWeight: "bold",
  svg: {
    path: {
      fill: theme.palette.secondary.contrastText,
    },
  },
}));

export default FormAccordionSummary;
