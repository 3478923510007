import {
  ChallengeHistory,
  ChallengeValidationWeek,
} from "@neurosolutionsgroup/models";

const groupChallengeHistoryByWeek = (
  history: ChallengeHistory[]
): ChallengeValidationWeek[] => {
  const validationsMap: { [weekIndex: number]: ChallengeHistory[] } = [];

  history.forEach((instance) => {
    const weekIndex = instance.week ?? 1;

    if (validationsMap[weekIndex] !== undefined) {
      validationsMap[weekIndex].push(instance);
    } else {
      validationsMap[weekIndex] = [instance];
    }
  });

  const validationWeeks: ChallengeValidationWeek[] = [];

  Object.keys(validationsMap).forEach((weekIndexString) => {
    const weekIndex = parseInt(weekIndexString);

    // Do not include complete weeks.
    if (
      validationsMap[weekIndex].every(
        (instance) => instance.validationDate !== null
      )
    ) {
      return;
    }

    const validationWeek: ChallengeValidationWeek = {
      weekIndex,
      validations: validationsMap[weekIndex].sort(
        (a, b) => (a.week ?? 8) - (b.week ?? 8)
      ),
    };

    validationWeeks.push(validationWeek);
  });

  validationWeeks.sort((a, b) => a.weekIndex - b.weekIndex);

  return validationWeeks;
};

export default groupChallengeHistoryByWeek;
