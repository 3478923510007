import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import Journal, { JournalProgress, JOURNAL_PROGRESS_TOTAL } from "..";
import useFTUE from "useFTUE";

export interface JournalAddNoteProps extends PropsWithChildren {
  onAdd: VoidFunction;
}

const JournalAddNote: React.FC<JournalAddNoteProps> = ({ onAdd, ...props }) => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setJournalProgress },
  } = useFTUE();

  const journalProgress = progress[FTUEFlow.Journal].progress;

  const onNext = () => {
    setJournalProgress(JournalProgress.AddNote);
  };

  const onQuit = () => {
    setJournalProgress(JournalProgress.JournalFTUEFinished, true);
  };

  return (
    <FTUEHint
      condition={
        journalProgress === JournalProgress.DaysPicker &&
        canDisplayFlow(FTUEFlow.Journal)
      }
      hint={t("ftue.journal.addNote.text")}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      onClick={() => {
        onNext();
        onAdd();
      }}
      centered
      currentStep={Journal.Logic.calculateProgress(JournalProgress.DaysPicker)}
      maxStep={JOURNAL_PROGRESS_TOTAL}
      floating={true}
    >
      {props.children}
    </FTUEHint>
  );
};

export default JournalAddNote;
