import { MessageRecord, RoutinesById } from "@neurosolutionsgroup/models";
import { FirstRoutineProgress } from "./FirstRoutineProgress";

const calculateProgress = (step: FirstRoutineProgress): number => {
  return Object.values(FirstRoutineProgress).findIndex(
    (v) => v === step.toString()
  );
};

const getProgress = (recordIds: string[]): FirstRoutineProgress => {
  let latestFirstRoutineStep: FirstRoutineProgress = FirstRoutineProgress.None;

  Object.values(FirstRoutineProgress).forEach((v) => {
    if (recordIds.includes(v)) {
      latestFirstRoutineStep = v;
    }
  });

  const stepsToSkipOnReturn: FirstRoutineProgress[] = [
    FirstRoutineProgress.AddTask,
    FirstRoutineProgress.TaskIcon,
  ];

  const stepsToRollback: FirstRoutineProgress[] = [
    FirstRoutineProgress.Intro,
    FirstRoutineProgress.TemplateChosen,
  ];

  if (stepsToSkipOnReturn.includes(latestFirstRoutineStep)) {
    return FirstRoutineProgress.TaskComplete;
  } else if (stepsToRollback.includes(latestFirstRoutineStep)) {
    return FirstRoutineProgress.Later;
  } else {
    return latestFirstRoutineStep;
  }
};

const getTemplate = (records: MessageRecord[]): string | undefined => {
  const templateIndex = records.findIndex(
    (r) => r.id === FirstRoutineProgress.TemplateChosen.toString()
  );

  if (templateIndex !== -1) {
    const templateRecord = records[templateIndex];

    if (templateRecord.extraData?.template) {
      return templateRecord.extraData.template as string;
    }
  }

  return undefined;
};

const isComplete = (
  progress: FirstRoutineProgress,
  routinesById: RoutinesById
): boolean => {
  return (
    (routinesById &&
      Object.keys(routinesById).length > 0 &&
      Object.values(routinesById).some((r) => !r.locked) &&
      progress === FirstRoutineProgress.None) ||
    progress === FirstRoutineProgress.RoutineFTUEFinished
  );
};

const FTUEFirstRoutineLogic = {
  calculateProgress,
  getProgress,
  getTemplate,
  isComplete,
};

export default FTUEFirstRoutineLogic;
