import { Box } from "@mui/material";
import { SafeAreas } from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import { forwardRef, PropsWithChildren } from "react";

export interface StickyDrawerProps extends PropsWithChildren {
  safeAreas: SafeAreas | null;
}

const StickyDrawer = forwardRef<HTMLDivElement, StickyDrawerProps>(
  ({ safeAreas, ...props }, ref): JSX.Element => {
    return (
      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          maxHeight: safeAreas?.top ? `calc(95vh - ${safeAreas?.top})` : "90vh",
          paddingBottom: `calc(${safeAreas?.bottom ?? "0px"})`,
          backgroundColor: "#fff",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
          boxShadow: "-22px 0px 23px -5px rgba(0, 0, 0, 0.1)",
          ...SharedStyles.Layout.noScroll,
        }}
      >
        <Box ref={ref}>{props.children}</Box>
      </Box>
    );
  }
);

export default StickyDrawer;
