import { Box, Chip, Typography } from "@mui/material";
import { CypressProps } from "@neurosolutionsgroup/models";
import React from "react";

interface FilterOptionProps extends CypressProps {
  label: string;
  count?: number;
  active: boolean;
  onClick: VoidFunction;
  disabled?: boolean;
}

const FilterOption: React.FC<FilterOptionProps> = ({
  label,
  count,
  active,
  onClick,
  disabled = false,
  ...props
}) => {
  return (
    <Chip
      disabled={disabled}
      label={
        <Box display="flex" alignItems="center">
          <Typography color={active ? "#fff" : "secondary"} fontSize="0.8rem">
            {label}
          </Typography>
          {count !== undefined && (
            <Box
              sx={(theme) => ({
                marginLeft: "0.5rem",
                paddingX: "0.5rem",
                backgroundColor: "#fff",
                border: active
                  ? "none"
                  : `1px solid ${theme.palette.secondary.main}`,
                borderRadius: "5px",
                color: theme.palette.secondary.main,
              })}
            >
              <Typography fontWeight="bold">{count}</Typography>
            </Box>
          )}
        </Box>
      }
      onClick={onClick}
      sx={(theme) => ({
        "backgroundColor": active ? theme.palette.secondary.main : "#fff",
        "padding": "0.25em",
        "boxShadow": "-3px 1px 14px -3px rgba(0, 0, 0, 0.25)",
        "&:hover": {
          backgroundColor: active ? theme.palette.secondary.main : "#fff",
        },
      })}
      data-cy={props["data-cy"]}
    />
  );
};

export default FilterOption;
