import { Box, Drawer, Typography, drawerClasses } from "@mui/material";
import BackgroundAssets from "assets/background";
import React, { useEffect, useState } from "react";
import { SAFE_AREA_BOTTOM } from "stylesheets";

interface ToastProps {
  open: boolean;
  onClose: VoidFunction;
  timeout: number;
  title?: string;
  text?: string | JSX.Element;
  transparent?: boolean;
}

const Toast = ({
  open,
  onClose,
  timeout,
  title,
  text,
  transparent = false,
}: ToastProps): JSX.Element => {
  const [timeoutFunction, setTimeoutFunction] = useState<
    NodeJS.Timeout | undefined
  >(undefined);

  useEffect(() => {
    if (open) {
      const timeoutInstance = setTimeout(() => {
        onClose();
      }, timeout);

      setTimeoutFunction(timeoutInstance);
    }
  }, [open]);

  return (
    <Drawer
      open={open}
      onClose={() => {
        clearTimeout(timeoutFunction);
        setTimeoutFunction(undefined);
        onClose();
      }}
      anchor="bottom"
      hideBackdrop={transparent}
      disableEnforceFocus={transparent}
      disableAutoFocus={transparent}
      disableRestoreFocus={transparent}
      sx={(theme) => ({
        pointerEvents: transparent ? "none" : undefined,
        [`& .${drawerClasses.paper}`]: {
          backgroundColor: theme.palette.primary.main,
          backgroundImage: `url(${BackgroundAssets.RedBackgroundRoutine})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          paddingBottom: SAFE_AREA_BOTTOM,
        },
      })}
    >
      <Box p={2}>
        <Typography textAlign="center" color="#fff" fontWeight="bold">
          {title}
        </Typography>
        <Typography textAlign="center" color="#fff">
          {text}
        </Typography>
      </Box>
    </Drawer>
  );
};

export default Toast;
