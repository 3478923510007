import { InformationButton } from "common/Components";
import InfoDrawer from "common/Components/Drawer/InfoDrawer";
import React, { useState } from "react";
import { Trans } from "react-i18next";

interface ChallengeInfoDrawerProps {
  color?: "secondary" | "white" | "high-vis";
}

const ChallengeInfoDrawer = ({
  color,
}: ChallengeInfoDrawerProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <InformationButton
        color={color}
        iconVariant="outline"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(true);
        }}
      />
      <InfoDrawer
        open={open}
        onClose={() => setOpen(false)}
        text={
          <Trans
            i18nKey={"routine.challenge.info.text"}
            components={{ bold: <strong /> }}
          />
        }
      />
    </>
  );
};

export default ChallengeInfoDrawer;
