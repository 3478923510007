import { Grid, Box, Typography, Radio, Divider } from "@mui/material";
import React from "react";

interface SubjectPickerListProps {
  withTitle: boolean;
  title: string | undefined;
  list: { text: string; id: string }[];
  selectedValue: string | null;
  setValue: (value: string, checked: boolean) => void;
}

const SubjectPickerList = ({
  withTitle,
  title,
  list,
  selectedValue,
  setValue,
}: SubjectPickerListProps): JSX.Element => {
  return (
    <Grid
      container
      spacing={1}
      sx={{
        overflowY: "auto",
      }}
    >
      {withTitle ? (
        <Grid item xs={12}>
          <Box pb={0} pt={2} px={2}>
            <Typography variant="h4">{title}</Typography>
          </Box>
        </Grid>
      ) : null}
      {list.map((item) => (
        <Grid item xs={12} key={item.id}>
          <Box px={2} display="flex" alignItems="center">
            <Typography
              flexGrow={10}
              sx={{
                label: {
                  display: "block",
                  width: "100%",
                },
              }}
            >
              <label htmlFor={`category-check-${item.id}`}>{item.text}</label>
            </Typography>
            <Radio
              id={`category-check-${item.id}`}
              value={item.id}
              color="secondary"
              checked={selectedValue === item.id}
              onChange={(e) => setValue(item.id, e.target.checked)}
            />
          </Box>
          <Divider />
        </Grid>
      ))}
    </Grid>
  );
};

export default SubjectPickerList;
