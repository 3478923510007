import React from "react";
import BaseIcon, { DEFAULT_ICON_COLOR, IconProps } from "./BaseIcon";

const DragIcon = ({
  onClick,
  color = DEFAULT_ICON_COLOR,
  ...props
}: IconProps): JSX.Element => {
  return (
    <BaseIcon onClick={onClick} classModifier="drag" {...props}>
      <line
        x1="1"
        y1="4"
        x2="19"
        y2="4"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="line9"
      />
      <line
        x1="1"
        y1="10"
        x2="19"
        y2="10"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="line11"
      />
      <line
        x1="1"
        y1="16"
        x2="19"
        y2="16"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        id="line13"
      />
    </BaseIcon>
  );
};

export default DragIcon;
