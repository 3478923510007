import { Button, Typography, useTheme } from "@mui/material";
import {
  ChildIconsDefintion,
  GamerChild,
  SafeAreas,
} from "@neurosolutionsgroup/models";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ControlledDrawer from "../ControlledDrawer/ControlledDrawer";
import DrawerMenuSection from "../ControlledDrawer/DrawerMenuSection";
import Icons from "../Icons";

export interface ChildSelectorProps {
  childList: GamerChild[];
  childIcons: ChildIconsDefintion;
  selectedChildId: string | null;
  onChildSelected: (childId: string) => void;
  safeAreas: SafeAreas;
  allowSelectAll?: boolean;
  disabledChildIds?: string[];
  label?: string;
  maxChildren?: number;
  onChildCreate?: VoidFunction;
  premiumLockCreate?: boolean;
  onPremiumCreateLockClick?: VoidFunction;
  premiumLockDisabledChildren?: boolean;
  onPremiumLockedChildClick?: VoidFunction;
  dataCy?: string;
  drawerZIndex?: number;
  fullWidth?: boolean;
  placeholder?: string;
  selectAllOptionText?: string;
  variant?: "light" | "dark";
}

export const ALL_CHILDREN_VALUE = "all";

const ChildSelector = ({
  childList,
  childIcons,
  selectedChildId,
  onChildSelected,
  safeAreas,
  allowSelectAll = false,
  disabledChildIds = [],
  label,
  maxChildren,
  onChildCreate,
  premiumLockCreate = false,
  onPremiumCreateLockClick,
  premiumLockDisabledChildren,
  onPremiumLockedChildClick,
  dataCy = "child-selector",
  drawerZIndex,
  fullWidth = false,
  placeholder,
  selectAllOptionText,
  variant = "dark",
}: ChildSelectorProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const displayChildAdditions =
    maxChildren && onChildCreate && childList.length < maxChildren;

  const selectedChild: GamerChild | null = useMemo(() => {
    if (
      selectedChildId === null ||
      selectedChildId === ALL_CHILDREN_VALUE ||
      childList.length === 0
    ) {
      return null;
    }

    const childIndex = childList.findIndex(
      (child) => child.id === selectedChildId
    );

    return childIndex !== -1 ? childList[childIndex] : null;
  }, [childList, selectedChildId]);

  const inputDisplay: string | JSX.Element = useMemo(() => {
    if (onChildCreate && childList.length === 0) {
      return t("navigation.childSelector.empty");
    }

    const arrowColor = variant === "dark" ? "#fff" : palette.secondary.main;

    if (selectedChildId === ALL_CHILDREN_VALUE) {
      return (
        <>
          {selectAllOptionText ?? t("general.all")}
          <Icons.ArrowIcon
            arrowType="default"
            arrowDirection="down"
            color={arrowColor}
          />
        </>
      );
    }

    if (selectedChild === null) {
      return (
        <>
          {placeholder ?? label ?? t("navigation.childSelector.label")}
          <Icons.ArrowIcon
            arrowType="default"
            arrowDirection="down"
            color={arrowColor}
          />
        </>
      );
    }

    return (
      <>
        <img src={childIcons[selectedChild.icon]} alt="" />
        {selectedChild.name}
        <Icons.ArrowIcon
          arrowType="default"
          arrowDirection="down"
          color={arrowColor}
        />
      </>
    );
  }, [childList, selectedChildId, selectedChild]);

  const onButtonClick = () => {
    if (onChildCreate && childList.length === 0) {
      onChildCreate();
    } else {
      setOpen(true);
    }
  };

  const onPremiumClick = () => {
    if (onPremiumCreateLockClick) {
      setOpen(false);
      onPremiumCreateLockClick();
    }
  };

  const onAddChildClicked = () => {
    if (onChildCreate) {
      onChildCreate();
      setOpen(false);
    }
  };

  return (
    <>
      <Button
        onClick={onButtonClick}
        color="secondary"
        variant={variant === "dark" ? "contained" : "text"}
        fullWidth={fullWidth}
        sx={{
          minWidth: "10rem",
          display: "flex",
          alignItems: "center",
          img: {
            height: "1.5rem",
            width: "1.5rem",
            marginRight: "6px",
          },
          svg: {
            marginLeft: "auto",
            paddingLeft: "6px",
            width: "21px",
            height: "15px",
          },
        }}
        data-cy={dataCy}
      >
        {inputDisplay}
      </Button>
      <ControlledDrawer
        open={open}
        onClose={() => setOpen(false)}
        safeAreas={safeAreas}
        isPremiumLocked={!!(displayChildAdditions && premiumLockCreate)}
        sx={{
          zIndex: drawerZIndex,
        }}
      >
        <DrawerMenuSection variant="header">
          <Typography variant="h4">
            {label ?? t("navigation.childSelector.label")}
          </Typography>
        </DrawerMenuSection>
        {allowSelectAll ? (
          <DrawerMenuSection
            onClick={(e) => {
              e.stopPropagation();
              onChildSelected(ALL_CHILDREN_VALUE);
              setOpen(false);
            }}
          >
            <Typography fontWeight="bold">
              {selectAllOptionText ?? t("general.all")}
            </Typography>
          </DrawerMenuSection>
        ) : null}
        {childList
          .sort((a, b) => (a.isDisabled ? 1 : 0) - (b.isDisabled ? 1 : 0))
          .map((child) => (
            <DrawerMenuSection
              key={child.id}
              onClick={(e) => {
                e.stopPropagation();
                onChildSelected(child.id);
                setOpen(false);
              }}
              disabled={disabledChildIds.includes(child.id)}
              sx={{
                img: {
                  height: "2rem",
                  width: "2rem",
                  marginRight: "6px",
                },
              }}
              premiumLock={premiumLockDisabledChildren && !!child.isDisabled}
              onPremiumLockClick={onPremiumLockedChildClick}
              data-cy={dataCy ? dataCy + "-option" : "child-selector-option"}
            >
              <img src={childIcons[child.icon]} alt="" />
              <Typography fontWeight="bold">{child.name}</Typography>
            </DrawerMenuSection>
          ))}
        {displayChildAdditions ? (
          <DrawerMenuSection
            onClick={(e) => {
              e.stopPropagation();
              onAddChildClicked();
            }}
            sx={{
              img: {
                height: "2rem",
                width: "2rem",
              },
            }}
            premiumLock={premiumLockCreate}
            onPremiumLockClick={onPremiumClick}
            data-cy={dataCy ? dataCy + "-add" : "child-selector-add"}
          >
            <Icons.AddIcon color={palette.secondary.main} />
          </DrawerMenuSection>
        ) : null}
      </ControlledDrawer>
    </>
  );
};

export default ChildSelector;
