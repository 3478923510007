import { Card, Grid, Typography } from "@mui/material";
import {
  NotificationCategorySettings,
  NotificationCategory,
} from "@neurosolutionsgroup/models";
import { CloseablePage, Loader } from "common/Components";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { NotificationButtonProps } from "./NotificationButton";
import NotificationGroup from "./NotificationGroup";
import NotificationSwitch from "./NotificationSwitch";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import useNotifications from "common/hooks/notifications/useNotifications";

export interface NotificationSettingsPromptProps {
  open: boolean;
  onClose: () => void;
}

const NotificationSettingsPrompt: React.FC<NotificationSettingsPromptProps> = ({
  open,
  onClose,
}) => {
  const { t } = useTranslation();
  const { updateSettings, notificationsSettings } = useNotifications();
  const { handleUnknownError } = useErrorsContext();

  //#region state
  const [notificationSettings, setNotificationSettings] = useState<
    NotificationCategorySettings | undefined
  >(undefined);

  const [globalNotification, setGlobalNotification] = useState<boolean>(false);

  const [routineNotification, setRoutineNotification] =
    useState<boolean>(false);

  const [generalNotification, setGeneralNotification] =
    useState<boolean>(false);
  const [validationNotification, setValidationNotification] =
    useState<boolean>(false);
  const [observationNotification, setObservationNotification] =
    useState<boolean>(false);
  const [coachNotification, setCoachNotification] = useState<boolean>(false);

  const [newsNotification, setNewsNotification] = useState<boolean>(false);
  const [promotionNotification, setPromotionNotification] =
    useState<boolean>(false);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  //#endregion

  //#region useEffect
  useEffect(() => {
    if (!globalNotification && isAnyNonGlobalNotificationActive) {
      setGlobalNotification(true);
    } else if (globalNotification && isAllNonGlobalNotificationInactive) {
      setGlobalNotification(false);
    }
  }, [
    globalNotification,
    routineNotification,
    generalNotification,
    validationNotification,
    observationNotification,
    coachNotification,
    newsNotification,
    promotionNotification,
  ]);
  useEffect(() => {
    const settings = notificationsSettings;
    if (settings) {
      setNotificationSettings(settings);
    }
  }, []);
  useEffect(() => {
    if (notificationSettings) {
      setRoutineNotification(
        notificationSettings[NotificationCategory.Routine]
      );
      setGeneralNotification(
        notificationSettings[NotificationCategory.General]
      );
      setValidationNotification(
        notificationSettings[NotificationCategory.Validation]
      );
      setObservationNotification(
        notificationSettings[NotificationCategory.Observation]
      );
      setCoachNotification(notificationSettings[NotificationCategory.Coach]);
      setNewsNotification(notificationSettings[NotificationCategory.News]);
      setPromotionNotification(
        notificationSettings[NotificationCategory.Promotion]
      );
    }
  }, [notificationSettings]);
  //#endregion

  //#region global state conditions
  const isAnyNonGlobalNotificationActive =
    routineNotification ||
    generalNotification ||
    validationNotification ||
    observationNotification ||
    coachNotification ||
    newsNotification ||
    promotionNotification;

  const isAllNonGlobalNotificationInactive =
    !routineNotification &&
    !generalNotification &&
    !validationNotification &&
    !observationNotification &&
    !coachNotification &&
    !newsNotification &&
    !promotionNotification;
  //#endregion

  //#region notification object props
  const childNotificationgroup: NotificationButtonProps[] = [
    {
      label: t("settings.notificationsPrompt.childNotifications.routine"),
      state: routineNotification,
      setState: setRoutineNotification,
    },
  ];

  const parentNotificationgroup: NotificationButtonProps[] = [
    {
      label: t("settings.notificationsPrompt.parentNotifications.general"),
      state: generalNotification,
      setState: setGeneralNotification,
    },
    {
      label: t("settings.notificationsPrompt.parentNotifications.validation"),
      state: validationNotification,
      setState: setValidationNotification,
    },
    {
      label: t("settings.notificationsPrompt.parentNotifications.observation"),
      state: observationNotification,
      setState: setObservationNotification,
    },
    {
      label: t("settings.notificationsPrompt.parentNotifications.coach"),
      state: coachNotification,
      setState: setCoachNotification,
    },
  ];

  const newsNotificationgroup: NotificationButtonProps[] = [
    {
      label: t("settings.notificationsPrompt.newsAndPromotions.news"),
      state: newsNotification,
      setState: setNewsNotification,
    },
    {
      label: t("settings.notificationsPrompt.newsAndPromotions.promotion"),
      state: promotionNotification,
      setState: setPromotionNotification,
    },
  ];
  //#endregion

  const setAllNotifications = (value: boolean): void => {
    setGlobalNotification(value);

    setRoutineNotification(value);

    setGeneralNotification(value);
    setValidationNotification(value);
    setObservationNotification(value);
    setCoachNotification(value);

    setNewsNotification(value);
    setPromotionNotification(value);
  };
  const handleOnClose = async () => {
    setIsLoading(true);
    try {
      const optIn = globalNotification;
      const categories: NotificationCategorySettings = {
        [NotificationCategory.Routine]: routineNotification,
        [NotificationCategory.General]: generalNotification,
        [NotificationCategory.Validation]: validationNotification,
        [NotificationCategory.Observation]: observationNotification,
        [NotificationCategory.Coach]: coachNotification,
        [NotificationCategory.News]: newsNotification,
        [NotificationCategory.Promotion]: promotionNotification,
      };
      await updateSettings(optIn, categories);
    } catch (err) {
      handleUnknownError(err);
    } finally {
      onClose();
      setIsLoading(false);
    }
  };

  return !isLoading ? (
    <CloseablePage
      isOpen={open}
      onClose={handleOnClose}
      color="secondary"
      className="settings__page"
      navigationText={t("settings.notificationsPrompt.returnButton")}
      header={
        <h2 className="settings__title">
          {t("settings.notificationsPrompt.pageTitle")}
        </h2>
      }
      hideBodyBackground
    >
      <Card
        sx={(theme) => ({
          backgroundColor: theme.palette.background.default,
          color: theme.palette.secondary.main,
        })}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignContent="center"
        >
          <Grid>
            <Typography variant="h2" mb={2}>
              {t("settings.notificationsPrompt.cardTitle")}
            </Typography>
          </Grid>
          <Grid
            onClick={() => {
              setAllNotifications(!globalNotification);
            }}
          >
            <NotificationSwitch checked={globalNotification} />
          </Grid>
        </Grid>
        <NotificationGroup
          groupTitle={t(
            "settings.notificationsPrompt.childNotifications.title"
          )}
          buttons={childNotificationgroup}
        />
        <br />
        <NotificationGroup
          groupTitle={t(
            "settings.notificationsPrompt.parentNotifications.title"
          )}
          buttons={parentNotificationgroup}
        />
        <br />
        <NotificationGroup
          groupTitle={t("settings.notificationsPrompt.newsAndPromotions.title")}
          buttons={newsNotificationgroup}
        />
        <br />
      </Card>
    </CloseablePage>
  ) : (
    <Loader />
  );
};

export default NotificationSettingsPrompt;
