import React, { SetStateAction } from "react";
import { HookResult } from "../HookResult";
import { TaskRoutineMap, TasksById, useTasksContext } from "./TasksContext";
import { IconManager } from "@neurosolutionsgroup/tools";

interface UseTasksSelectors {
  tasksById: TasksById;
  taskRoutineMap: TaskRoutineMap;
  iconManager: IconManager;
}

interface UseTasksActions {
  setTasksById: React.Dispatch<SetStateAction<TasksById>>;
  setTaskRoutineMap: React.Dispatch<SetStateAction<TaskRoutineMap>>;
}

const useTasks = (): HookResult<UseTasksSelectors, UseTasksActions> => {
  const {
    tasksById,
    setTasksById,
    taskRoutineMap,
    setTaskRoutineMap,
    iconManager,
  } = useTasksContext();

  return {
    selectors: { tasksById, taskRoutineMap, iconManager },
    actions: { setTasksById, setTaskRoutineMap },
  };
};

export default useTasks;
