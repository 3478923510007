export enum ObservationProgress {
  None = "none",
  Later = "FTUE_Observation_Later",
  Intro = "FTUE_Observations_Intro",
  Display = "FTUE_Observations_Display",
  Frequency = "FTUE_Observations_Frequency",
  ObservationsFTUEFinished = "FTUE_Observations_Finished",
}

export const OBSERVATION_PROGRESS_TOTAL = 3;
