import AvatarCelebration from "./dynamism_avatar_celebration.png";
import Empty from "./dynamism_empty.png";
import Journal from "./dynamism_journal.png";
import JournalEN from "./dynamism_text_journal_en.png";
import JournalFR from "./dynamism_text_journal_fr.png";
import Medication from "./dynamism_medication.png";
import MedicationEN from "./dynamism_text_medication_en.png";
import MedicationFR from "./dynamism_text_medication_fr.png";
import Menu from "./dynamism_menu.png";
import ObservationEmptyDE from "./dynamism_observation_empty_de.png";
import ObservationEmptyEN from "./dynamism_observation_empty_en.png";
import ObservationEmptyFR from "./dynamism_observation_empty_fr.png";
import Routine from "./dynamism_routine.png";
import RoutineEmpty from "./dynamism_routine_empty.png";
import RoutineEN from "./dynamism_text_routine_en.png";
import RoutineFR from "./dynamism_text_routine_fr.png";
import TaskIcon from "./dynamism_task_icon.png";
import ValidationEmptyDE from "./dynamism_validation_empty_de.png";
import ValidationEmptyEN from "./dynamism_validation_empty_en.png";
import ValidationEmptyFR from "./dynamism_validation_empty_fr.png";
import NotificationOptIn from "./OptIn_Notifications_visuel.png";
import Observation_Validation from "./Observation_Validation.png";
import Routine_Validation from "./Routine_Validation.png";

const DynamismAssets = {
  AvatarCelebration,
  Empty,
  Journal,
  Medication,
  Menu,
  ObservationEmptyDE,
  ObservationEmptyEN,
  ObservationEmptyFR,
  Routine,
  RoutineEmpty,
  TaskIcon,
  Text: {
    JournalEN,
    JournalFR,
    MedicationEN,
    MedicationFR,
    RoutineEN,
    RoutineFR,
  },
  ValidationEmptyDE,
  ValidationEmptyEN,
  ValidationEmptyFR,
  NotificationOptIn,
  Observation_Validation,
  Routine_Validation,
};

export default DynamismAssets;
