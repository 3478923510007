import { Box, Slider, Typography, useTheme } from "@mui/material";
import BackgroundAssets from "assets/background";
import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import NPSDialog, { NPSDialogProps } from "./NPSDialog";

interface NPSRatingDialogProps
  extends Omit<
    NPSDialogProps,
    "onSubmit" | "skipText" | "submitText" | "children"
  > {
  onSubmit: (rating: number) => void;
}

const NPSRatingDialog: React.FC<NPSRatingDialogProps> = ({
  onSubmit,
  ...props
}) => {
  const [rating, setRating] = useState(10);

  const { palette } = useTheme();
  const { t } = useTranslation();

  const color: string = useMemo(() => {
    if (rating <= 3) {
      return palette.primary.main;
    } else if (rating <= 7) {
      return "#E69C2C";
    } else {
      return palette.secondary.main;
    }
  }, [rating]);

  const formatRating = useCallback(() => {
    if (rating <= 3) {
      return t("ftue.nps.recommend.low");
    } else if (rating <= 7) {
      return t("ftue.nps.recommend.medium");
    } else {
      return t("ftue.nps.recommend.high");
    }
  }, [rating]);

  return (
    <NPSDialog
      {...props}
      onSubmit={() => onSubmit(rating)}
      submitText={t("general.actions.submit")}
      skipText={t("general.notnow")}
    >
      <Box mt={2} display="flex" justifyContent="center">
        <Box
          sx={{
            backgroundImage: `url(${BackgroundAssets.HexagonBackground})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "center",
            width: "4rem",
            height: "4rem",
          }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            color={color}
            fontWeight="bold"
            fontSize="1.5rem"
            sx={{ transition: "color 0.5s ease" }}
          >
            {rating}
          </Typography>
        </Box>
      </Box>
      <Typography
        textAlign="center"
        color={color}
        sx={{ transition: "color 0.5s ease" }}
      >
        {formatRating()}
      </Typography>
      <Slider
        step={1}
        min={1}
        max={10}
        value={rating}
        onChange={(_, v) => setRating(v as number)}
        sx={{
          color: color,
          transition: "color 0.5s ease",
        }}
      />
    </NPSDialog>
  );
};

export default NPSRatingDialog;
