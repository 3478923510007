import React, { SetStateAction } from "react";
import { Tools } from "@neurosolutionsgroup/tools";
import { useTranslation } from "react-i18next";
import { ButtonBase, Grid, Typography, useTheme } from "@mui/material";
import { SharedStyles } from "@neurosolutionsgroup/themes";

export interface DaysPickerProps {
  value: number;
  setValue: React.Dispatch<SetStateAction<number>>;
  multiple?: boolean;
}

const DaysPicker = ({
  value,
  setValue,
  multiple = true,
}: DaysPickerProps): JSX.Element => {
  const { t } = useTranslation();
  const { palette } = useTheme();

  const onToggle = (day: number) => {
    setValue((current) => {
      if (multiple) {
        return Tools.Time.Days.toggleDay(day, current);
      } else {
        return day === current ? 0 : day;
      }
    });
  };

  return (
    <Grid container justifyContent="space-around" spacing={1}>
      {Tools.Time.Days.dayIndexes.map((day) => (
        <Grid
          item
          key={day}
          onClick={() => onToggle(day)}
          flex="1 1 0px"
          boxSizing="border-box"
          data-cy={"day-picker-" + day}
        >
          <ButtonBase
            sx={{
              width: "100%",
              height: "100%",
              paddingHorizontal: 0,
              paddingY: 0.75,
              backgroundColor: "#fff",
              borderWidth: "2px",
              borderStyle: "solid",
              borderColor: Tools.Time.Days.dayInDays(day, value)
                ? palette.secondary.main
                : "transparent",
              borderRadius: "10px",
              userSelect: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              boxShadow: SharedStyles.Shadows.defaultShadow,
              color: "secondary.main",
            }}
          >
            <Typography fontSize="0.75rem">
              {t(`general.time.days.short.${day}`)}
            </Typography>
          </ButtonBase>
        </Grid>
      ))}
    </Grid>
  );
};

export default DaysPicker;
