import useChildren from "common/hooks/children/useChildren";
import useLanguage from "common/hooks/Parameters/useLanguage";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import RoutineEdition from "./RoutineEdition/RoutineEdition";
import TemplateSelection from "./TemplateSelection/TemplateSelection";
import Objectives from "./Objectives/Objectives";
import useRoutines from "common/hooks/routines/useRoutines";
import { v4 } from "uuid";
import {
  Routine,
  Template,
  templateToRoutine,
} from "@neurosolutionsgroup/models";
import { Loader } from "@neurosolutionsgroup/components";
import { Box, Drawer, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import CoachErrorDialog, {
  CoachError,
  MAXIMUM_ACTIVE_ROUTINES,
  MAXIMUM_ROUTINES,
} from "common/hooks/messaging/Coach/CoachErrorDialog";
import { useErrorsContext } from "common/hooks/errors/ErrorContext";
import { useCoach } from "common/hooks/messaging/Coach";
import RoutineEditionLogic from "./RoutineEdition/RoutineEditionLogic";
import { Tools } from "@neurosolutionsgroup/tools";
import useNavigation from "common/hooks/navigation/useNavigation";
import { taskLoc, templateLoc } from "@neurosolutionsgroup/localization";
import TemplateMenuChoice from "./TemplateSelection/TemplateMenuChoice";
import useChallenges from "common/hooks/challenges/useChallenges";
import RoutineAssets from "assets/routines";
import {
  ChallengeCreationStarted,
  PremiumFeature,
  useAnalytics,
} from "@neurosolutionsgroup/analytics";
import {
  FTUEFlowDefinitions,
  useFTUE,
} from "@neurosolutionsgroup/webviews-ftue";
import { SAFE_AREAS } from "stylesheets";
import FTUEAssets from "assets/ftue";
import useSubscription from "common/hooks/subscription/useSubscription";
import { premiumRoutineTemplates } from "common/hooks/subscription/SubscriptionContext";
import ChallengeCreation from "./ChallengeCreation/ChallengeCreation";
import {
  useRemoteConfig,
  WebviewsFeatureFlag,
} from "@neurosolutionsgroup/remote-config";
import useParameters from "common/hooks/Parameters/useParameters";

export enum RoutineOperation {
  Add,
  Copy,
  Edit,
}

export enum ObjectivesNavState {
  Routines,
  Challenges,
}

const ObjectivesSection = (): JSX.Element => {
  const {
    selectors: { childIds },
  } = useChildren();
  const {
    selectors: { loading, routinesById },
    actions: { updateRoutine, getActiveRoutines },
  } = useRoutines();
  const {
    selectors: { challengeFeatureFlagActive, challengeLimitMet },
  } = useChallenges();
  const { handleEvent } = useAnalytics();

  const { language } = useLanguage();
  const { t } = useTranslation();
  const location = useLocation();
  const { handleUnknownError } = useErrorsContext();
  const { onRoutineActivated, onRoutineDeactivated } = useCoach();
  const {
    selectors: { templateRedirect },
    actions: { setTemplateRedirect, onParentSectionBack },
  } = useNavigation();
  const { version } = useParameters();
  const { checkFeatureFlag } = useRemoteConfig();
  const { onPremiumFeatureClick, permissions } = useSubscription();
  const {
    actions: { onChallengeAdded },
  } = useFTUE();

  const [navState, setNavState] = useState<ObjectivesNavState[]>([
    ObjectivesNavState.Routines,
  ]);
  const [multipleRoutinesWarning, setMultipleRoutinesWarning] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [showRoutineLimit, setShowRoutineLimit] = useState(false);
  const [showActiveRoutineLimit, setShowActiveRoutineLimit] = useState(false);
  const [showOverlappingRoutinesWarning, setShowOverlappingRoutinesWarning] =
    useState(false);
  const [showRoutineEdit, setShowRoutineEdit] = useState(false);
  const [selectedRoutine, setSelectedRoutine] = useState<Routine | undefined>(
    undefined
  );
  const [routineOperation, setRoutineOperation] = useState<RoutineOperation>(
    RoutineOperation.Add
  );
  const [showChallengeEdit, setShowChallengeEdit] = useState(false);

  const allowFtueChallengeCreation = checkFeatureFlag(
    WebviewsFeatureFlag.FtueChallengeCreation,
    version
  );

  useEffect(() => {
    if (!showDrawer) {
      setMultipleRoutinesWarning(false);
    }
  }, [showDrawer]);

  useEffect(() => {
    if (!showRoutineEdit) {
      setSelectedRoutine(undefined);
    }
  }, [showRoutineEdit]);

  /**
   * Handle passed template ID via navigation.
   * Must be after functions declaration or will not work on first load.
   */
  useEffect(() => {
    if (location.pathname.startsWith("/objectives") && templateRedirect) {
      const template = parseInt(templateRedirect);

      if (template && Object.values(Template).includes(template)) {
        setTemplateRedirect(undefined);
        onTemplateChosen(template);
      }
    }
  }, [location, templateRedirect]);

  const onChallengeAdd = () => {
    const event: ChallengeCreationStarted = {
      name: "Challenge Creation Started",
    };

    handleEvent(event);
    setShowDrawer(false);
    setShowChallengeEdit(true);
  };

  const onTemplateChosen = (template: Template) => {
    if (
      !permissions.multipleRoutines &&
      Object.keys(routinesById).filter(
        (r) => !routinesById[r].deleted && !routinesById[r].locked
      ).length > 0
    ) {
      setMultipleRoutinesWarning(true);
      return;
    }

    if (
      Object.keys(routinesById).filter((r) => !routinesById[r].deleted).length <
      MAXIMUM_ROUTINES
    ) {
      const routine = templateToRoutine(
        template,
        childIds,
        language,
        taskLoc,
        templateLoc
      );
      setRoutineOperation(RoutineOperation.Add);
      setShowDrawer(false);
      setSelectedRoutine({ ...routine });
      setShowRoutineEdit(true);
    } else {
      setShowDrawer(false);
      setShowRoutineLimit(true);
    }
  };

  const onRoutineAdd = () => {
    //! FIXME this have been commented to see if this is the cause of TFS-879.
    // if (
    //   progress[FTUEFlow.FirstRoutine].progress === FirstRoutineProgressV2.None
    // ) {
    //   requestRoutineFTUE();
    // } else {
    // }
    setShowDrawer(true);
  };

  const onRoutineEdit = (routine: Routine) => {
    setRoutineOperation(RoutineOperation.Edit);
    setSelectedRoutine({ ...routine });
    setShowDrawer(false);
    setShowRoutineEdit(true);
  };

  const onRoutineCopy = (routine: Routine) => {
    const newRoutine = { ...routine };
    newRoutine.id = v4();
    newRoutine.name +=
      routine.icon && routine.icon > 0 ? "" : ` (${t("general.actions.copy")})`;
    newRoutine.version = 0;
    newRoutine.tasks = newRoutine.tasks.map((t) => ({
      ...t,
      id: v4(),
      routine: newRoutine.id,
    }));
    setRoutineOperation(RoutineOperation.Copy);
    setSelectedRoutine({ ...newRoutine });
    setShowRoutineEdit(true);
  };

  const onRoutineDelete = async (routine: Routine) => {
    try {
      routine.deleted = true;
      routine.deletedDate = Tools.Time.Dates.getTimeStamp();
      routine.locked = routine.locked ?? null;
      await updateRoutine(routine);
    } catch (err) {
      handleUnknownError(err);
    }
  };

  const onRoutineActivate = async (routine: Routine, isDisabled: boolean) => {
    if (
      isDisabled === false &&
      !RoutineEditionLogic.validateActiveRoutinesCount(
        routine.users,
        routine.days,
        getActiveRoutines,
        routine.id,
        MAXIMUM_ACTIVE_ROUTINES
      )
    ) {
      setShowActiveRoutineLimit(true);
      return;
    }

    if (
      isDisabled === false &&
      !RoutineEditionLogic.validateRoutinesDontOverlap(
        routine.users,
        getActiveRoutines,
        routine.id,
        routine.days,
        routine.start,
        routine.end
      )
    ) {
      setShowOverlappingRoutinesWarning(true);
      return;
    }

    routine = RoutineEditionLogic.toggleRoutineActive(routine, isDisabled);

    try {
      await updateRoutine({ ...routine, locked: routine.locked ?? null });

      if (!isDisabled) {
        onRoutineActivated(routine);
      } else {
        onRoutineDeactivated();
      }
    } catch (err) {
      handleUnknownError(err);
    }
  };

  const onPremiumChallengeClick = () => {
    onPremiumFeatureClick(PremiumFeature.Challenge);
  };

  const onPremiumTemplateClick = (template: Template) => {
    if (template === Template.Afternoon) {
      onPremiumFeatureClick(PremiumFeature.RoutineAfternoon);
    } else if (template === Template.Midday) {
      onPremiumFeatureClick(PremiumFeature.RoutineMidday);
    } else if (template === Template.Evening) {
      onPremiumFeatureClick(PremiumFeature.RoutineEvening);
    } else {
      onPremiumFeatureClick(PremiumFeature.RoutineCustom);
    }
  };

  const onPremiumFTUEClick = (template: Template) => {
    if (template === Template.Afternoon) {
      onPremiumFeatureClick(PremiumFeature.RoutineAfternoon);
    } else if (template === Template.Midday) {
      onPremiumFeatureClick(PremiumFeature.RoutineMidday);
    } else if (template === Template.Evening) {
      onPremiumFeatureClick(PremiumFeature.RoutineEvening);
    } else {
      onPremiumFeatureClick(PremiumFeature.RoutineCustom);
    }
  };

  const onChallengeExit = () => {
    setNavState([ObjectivesNavState.Challenges]);
    setShowChallengeEdit(false);
    onChallengeAdded();
  };

  return (
    <>
      <Drawer
        open={showDrawer}
        anchor="bottom"
        onClose={() => {
          setShowDrawer(false);
        }}
        sx={{
          zIndex: 100,
        }}
      >
        <Box mt={2}>
          {challengeFeatureFlagActive ? (
            <>
              <TemplateMenuChoice
                text={t("routine.challenge.create")}
                imageSrc={RoutineAssets.ChallengeIcon}
                onClick={onChallengeAdd}
                disabled={challengeLimitMet}
                data-cy={"create-challenge-button"}
                premiumLock={!permissions.challenges}
                onPremiumLockClick={onPremiumChallengeClick}
              />
              {challengeLimitMet ? (
                <Typography ml={2} mt={-1} fontSize="0.85rem" fontWeight={600}>
                  {t("routine.challenge.limit")}
                </Typography>
              ) : null}
            </>
          ) : null}
          <TemplateSelection
            multipleRoutinesWarning={multipleRoutinesWarning}
            onClick={onTemplateChosen}
            onPremiumLockClick={onPremiumTemplateClick}
            lockedTemplates={
              permissions.routineTemplates ? [] : premiumRoutineTemplates
            }
          />
        </Box>
      </Drawer>

      <FTUEFlowDefinitions.FirstRoutineFTUEFlow.Screen
        safeAreas={SAFE_AREAS}
        language={language}
        allowChallengeCreation={allowFtueChallengeCreation}
        lockChallenge={!permissions.challenges}
        lockedRoutineTemplates={
          permissions.routineTemplates ? [] : premiumRoutineTemplates
        }
        onPremiumLockChallengeClick={() =>
          onPremiumFeatureClick(PremiumFeature.Challenge)
        }
        onPremiumLockTemplateClick={onPremiumFTUEClick}
        introImageSrc={FTUEAssets.RoutineIntro}
        onTemplateChoice={(challengeSelected, template) => {
          if (challengeSelected) {
            setShowChallengeEdit(true);
          } else {
            setTemplateRedirect(template ? template.toString() : undefined);
          }
        }}
        onFtueEnd={() => {
          onParentSectionBack();
        }}
      />

      <CoachErrorDialog
        open={showRoutineLimit}
        onClose={() => setShowRoutineLimit(false)}
        error={CoachError.RoutineTotal}
      />

      <CoachErrorDialog
        open={showActiveRoutineLimit}
        onClose={() => setShowActiveRoutineLimit(false)}
        error={CoachError.ActiveRoutines}
      />

      <CoachErrorDialog
        open={showOverlappingRoutinesWarning}
        onClose={() => setShowOverlappingRoutinesWarning(false)}
        error={CoachError.RoutinesOverlap}
      />

      {loading && <Loader />}

      <RoutineEdition
        show={showRoutineEdit}
        setShow={setShowRoutineEdit}
        routine={selectedRoutine}
        operation={routineOperation}
      />

      <ChallengeCreation open={showChallengeEdit} onClose={onChallengeExit} />

      <Objectives
        setSelectedRoutine={(r) => setSelectedRoutine(r)}
        addRoutine={onRoutineAdd}
        editRoutine={onRoutineEdit}
        copyRoutine={onRoutineCopy}
        deleteRoutine={onRoutineDelete}
        activateRoutine={onRoutineActivate}
        navState={navState}
        setNavState={setNavState}
      />
    </>
  );
};

export default ObjectivesSection;
