import React, { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import DashboardPrescription, {
  DashboardPrescriptionsProgress,
  DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL,
} from "..";
import { FTUEFlow } from "models";
import { FTUEHint } from "components";
import useFTUE from "useFTUE";

const DashboardPrescriptionsReminder = (
  props: PropsWithChildren
): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setDashboardPrescriptionsProgress },
  } = useFTUE();

  const dashboardPrescriptionsProgress =
    progress[FTUEFlow.DashboardPrescriptions].progress;

  const onNext = () => {
    setDashboardPrescriptionsProgress(DashboardPrescriptionsProgress.Reminder);
  };

  const onQuit = () => {
    setDashboardPrescriptionsProgress(
      DashboardPrescriptionsProgress.DashboardPrescriptionsFTUEFinished
    );
  };

  return (
    <FTUEHint
      condition={
        dashboardPrescriptionsProgress ===
          DashboardPrescriptionsProgress.None &&
        canDisplayFlow(FTUEFlow.DashboardPrescriptions)
      }
      onNext={onNext}
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={t("ftue.dashboardPrescriptions.reminder.text")}
      currentStep={DashboardPrescription.Logic.calculateProgress(
        DashboardPrescriptionsProgress.None
      )}
      maxStep={DASHBOARD_PRESCRIPTION_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default DashboardPrescriptionsReminder;
