import { Box } from "@mui/material";
import { Icons } from "@neurosolutionsgroup/components";
import { PropsWithChildren, useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import Validation, { ValidationProgress, VALIDATION_PROGRESS_TOTAL } from "..";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import useFTUE from "useFTUE";

export interface TaskValidationProps extends PropsWithChildren {
  validationDone: boolean;
}

const TaskValidation = ({
  validationDone,
  ...props
}: TaskValidationProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    selectors: { progress },
    actions: { canDisplayFlow, setValidationProgress },
  } = useFTUE();

  const validationProgress = progress[FTUEFlow.Validation].progress;

  useEffect(() => {
    if (
      validationDone &&
      validationProgress === ValidationProgress.Prefill &&
      canDisplayFlow(FTUEFlow.Validation)
    ) {
      setValidationProgress(ValidationProgress.Validate);
    }
  }, [validationDone]);

  const onQuit = () => {
    setValidationProgress(ValidationProgress.ValidationFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        validationProgress === ValidationProgress.Prefill &&
        canDisplayFlow(FTUEFlow.Validation)
      }
      quit={onQuit}
      quitText={t("ftue.validation.end")}
      hint={
        <Box
          component="span"
          sx={{
            svg: {
              width: "1em",
              height: "1em",
            },
          }}
        >
          <Trans
            i18nKey={"ftue.validation.validate.text"}
            components={{
              check: (
                <Icons.CheckMarkIcon color="#A6DA2D" checkStyle="kairos" />
              ),
              close: <Icons.CloseIcon color="#ef4044" />,
            }}
          />
        </Box>
      }
      scrollPosition="start"
      currentStep={Validation.Logic.calculateProgress(
        ValidationProgress.Prefill
      )}
      maxStep={VALIDATION_PROGRESS_TOTAL}
    >
      {props.children}
    </FTUEHint>
  );
};

export default TaskValidation;
