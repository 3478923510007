import { sub } from "date-fns";

const isChildYoungerThanSix = (dobMS: number | null): boolean => {
  if (dobMS === null) {
    return false;
  } else {
    const sixYearsAge = sub(new Date(), { years: 6 }).getTime();

    if (dobMS > sixYearsAge) {
      return true;
    } else {
      return false;
    }
  }
};

export default isChildYoungerThanSix;
