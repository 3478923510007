import {
  MedPeriodLoc,
  NeedsLoc,
  PdfLoc,
  RoutinePeriodLoc,
  TaskCategoryLoc,
  TaskLocType,
  TemplateLocType,
} from "@neurosolutionsgroup/models";
import * as Localization from "./locales";

export default Localization;

export const taskCategoryLoc = {
  en: Localization.en.templates.categories,
  fr: Localization.fr.templates.categories,
  de: Localization.de.templates.categories,
} as TaskCategoryLoc;

export const templateLoc = {
  en: Localization.en.templates.templates,
  fr: Localization.fr.templates.templates,
  de: Localization.de.templates.templates,
} as TemplateLocType;

export const taskLoc = {
  en: Localization.en.templates.tasks,
  fr: Localization.fr.templates.tasks,
  de: Localization.de.templates.tasks,
} as TaskLocType;

export const routinePeriodLoc = {
  en: Localization.en.dashboard.period.options,
  fr: Localization.fr.dashboard.period.options,
  de: Localization.de.dashboard.period.options,
} as RoutinePeriodLoc;

export const medPeriodLoc = {
  en: Localization.en.dashboard.period.med,
  fr: Localization.fr.dashboard.period.med,
  de: Localization.de.dashboard.period.med,
} as MedPeriodLoc;

export const pdfLoc = {
  en: {
    ...Localization.en.dashboard.export.pdf,
    needsEmpty: Localization.en.portal.children.fields.needs.none,
    noprescription: Localization.en.dashboard.med.graph.legend.noprescription,
    previousprescription: Localization.en.dashboard.previousPrescription,
    low: Localization.en.journal.observations.question.intensity.low,
    moderate: Localization.en.journal.observations.question.intensity.moderate,
    high: Localization.en.journal.observations.question.intensity.high,
  },
  fr: {
    ...Localization.fr.dashboard.export.pdf,
    needsEmpty: Localization.fr.portal.children.fields.needs.none,
    noprescription: Localization.fr.dashboard.med.graph.legend.noprescription,
    previousprescription: Localization.fr.dashboard.previousPrescription,
    low: Localization.fr.journal.observations.question.intensity.low,
    moderate: Localization.fr.journal.observations.question.intensity.moderate,
    high: Localization.fr.journal.observations.question.intensity.high,
  },
  de: {
    ...Localization.de.dashboard.export.pdf,
    // TODO: Update DE translation when we get them.
    needsEmpty: "",
    noprescription: "",
    previousprescription: "",
    low: Localization.de.journal.observations.question.intensity.low,
    moderate: Localization.de.journal.observations.question.intensity.moderate,
    high: Localization.de.journal.observations.question.intensity.high,
  },
} as PdfLoc;

export const needsLoc = {
  en: Localization.en.settings.child.needs,
  fr: Localization.fr.settings.child.needs,
  de: Localization.de.settings.child.needs,
} as NeedsLoc;
