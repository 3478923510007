import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ChallengeValidationLineComponent from "./ChallengeValidationLineComponent";
import { ChallengeValidationWeek } from "@neurosolutionsgroup/models";
interface ValidationWeekProps {
  validationWeek: ChallengeValidationWeek;
  onChange: (index: number, value: boolean) => void;
  weekString: string;
  weekDayToString: (dayIndex: number | null) => string;
}

const ChallengeValidationWeekComponent = ({
  validationWeek,
  onChange,
  weekString,
  weekDayToString,
}: ValidationWeekProps): JSX.Element => {
  const { t } = useTranslation();

  let index = 1;

  return (
    <Box>
      <Box>
        <Typography fontWeight={600} fontSize="0.9rem">
          {t("general.time.week")}&nbsp;{validationWeek.weekIndex}
        </Typography>
        <Typography fontSize="0.85rem">{weekString}</Typography>
      </Box>
      <Box my={1}>
        {validationWeek.validations.map((validation, i) => {
          let instanceIndex = null;

          if (
            !(
              validation.parentStatus === false &&
              validation.validationDate !== null
            )
          ) {
            instanceIndex = index;

            index++;
          }

          return (
            <Box mb={1} key={i + 1}>
              <ChallengeValidationLineComponent
                index={instanceIndex}
                text={weekDayToString(validation.day)}
                state={validation.parentStatus}
                confirmed={validation.validationDate !== null}
                onChange={(value) => onChange(i, value)}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default ChallengeValidationWeekComponent;
