import BaseIcon, { IconProps } from "./BaseIcon";

const BarChartIcon = ({ color, ...props }: IconProps): JSX.Element => {
  return (
    <BaseIcon {...props} classModifier="bar-chart">
      <g id="g3" transform="matrix(1.25,0,0,1.25,-5,-5)">
        <path
          d="M 8,9 C 6.6666667,9 5.3333333,9 4,9 c 0,3.666667 0,7.333333 0,11 1.3333333,0 2.6666667,0 4,0 0,-3.666667 0,-7.333333 0,-11 z"
          fill={color}
          id="path1"
        />
        <path
          d="m 20,13 c -1.333333,0 -2.666667,0 -4,0 0,2.333333 0,4.666667 0,7 1.333333,0 2.666667,0 4,0 0,-2.333333 0,-4.666667 0,-7 z"
          fill={color}
          id="path2"
        />
        <path
          d="m 14,4 c -1.333333,0 -2.666667,0 -4,0 0,5.3333333 0,10.666667 0,16 1.333333,0 2.666667,0 4,0 0,-5.333333 0,-10.6666667 0,-16 z"
          fill={color}
          id="path3"
        />
      </g>
    </BaseIcon>
  );
};

export default BarChartIcon;
