import {
  FirestoreFollowedSideEffectDocument,
  Timestamp,
} from "@neurosolutionsgroup/models";

export const constructFollowedSideEffect = (
  userId: string,
  tenantId: string,
  medicalChildId: string,
  sideEffectId: string,
  startDate: Timestamp
): FirestoreFollowedSideEffectDocument => {
  return {
    userId,
    tenantId,
    medicalChildId,
    sideEffectId,
    startDate,
    isActive: true,
    activeStatusChanges: [],
    recordsLast4Weeks: [],
  };
};
