import {
  LinearProgress,
  linearProgressClasses,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useMemo } from "react";

interface ChallengeProgressBarProps {
  value: number;
  allowEmpty?: boolean;
  emptyState?: boolean;
  inProgress?: boolean;
  future?: boolean;
}

const ChallengeProgressBar = ({
  value,
  allowEmpty = false,
  emptyState = false,
  inProgress = false,
  future = false,
}: ChallengeProgressBarProps): JSX.Element => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const color: string = useMemo(() => {
    if (emptyState) {
      return "#C2C2C2";
    }

    if (value < 33) {
      return "#FF233A";
    } else if (value > 66) {
      return "#2CD25A";
    } else {
      return "#FBC70C";
    }
  }, [value]);

  const minimum = allowEmpty ? 0 : matches ? 3 : 7;

  // Display a small amount even if zero.
  const correctedValue = value === 0 ? minimum : value;

  return future ? (
    <LinearProgress
      value={0}
      variant="determinate"
      sx={{
        height: 15,
        borderRadius: 8,
        borderStyle: "dashed",
        borderWidth: "1px",
        borderColor: "secondary.main",
        background: "none",
      }}
    />
  ) : (
    <LinearProgress
      value={inProgress ? 0 : correctedValue}
      variant="determinate"
      sx={(theme) => ({
        "height": 15,
        "borderRadius": 8,
        "background": inProgress
          ? `linear-gradient(0.375turn, ${theme.palette.background.default}, ${theme.palette.background.default} 35%, #FDFCFA 50%, ${theme.palette.background.default} 65%)`
          : theme.palette.background.default,
        "backgroundSize": "200% 200%",
        "animation": inProgress ? "Gradient 4.5s linear infinite" : "none",
        "@keyframes Gradient": {
          "0%": {
            backgroundPosition: "150% 50%",
          },
          "50%": {
            backgroundPosition: "150% 50%", // Adds delay between cycles by doing nothing until 50%.
          },
          "100%": {
            backgroundPosition: "-50% 50%",
          },
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 8,
          backgroundColor: color,
        },
      })}
    />
  );
};

export default ChallengeProgressBar;
