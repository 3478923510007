import { FTUEFlow } from "models/FTUEFlow";
import { ValidationProgress } from "..";
import Intro from "./Screens/Intro";
import useFTUE from "useFTUE";
import { SafeAreas } from "@neurosolutionsgroup/models";
import ValidationScreenComponent from "./ValidationScreenComponent";
import { useNavigate } from "react-router-dom";

export interface FTUEValidationScreenProps {
  safeAreas: SafeAreas;
}

const FTUEValidationScreen = ({
  safeAreas,
}: FTUEValidationScreenProps): JSX.Element | null => {
  const {
    selectors: { assets, progress },
    actions: { canDisplayFlow, setValidationProgress },
  } = useFTUE();
  const navigate = useNavigate();

  const validationProgress = progress[FTUEFlow.Validation].progress;

  const showFullScreen = () => {
    const fullScreenSteps = [ValidationProgress.None];

    return fullScreenSteps.includes(validationProgress);
  };

  const onIntroContinue = () => {
    navigate("/follow-ups");
    setValidationProgress(ValidationProgress.Intro);
  };

  const onIntroCancel = () => {
    setValidationProgress(ValidationProgress.Later);
  };

  const renderFTUESection = () => {
    switch (validationProgress) {
      case ValidationProgress.None:
        return (
          <Intro
            safeAreas={safeAreas}
            onCancel={onIntroCancel}
            onContinue={onIntroContinue}
            assets={assets}
          />
        );
      default:
        return null;
    }
  };

  return canDisplayFlow(FTUEFlow.Validation) && showFullScreen() ? (
    <ValidationScreenComponent safeAreas={safeAreas}>
      {renderFTUESection()}
    </ValidationScreenComponent>
  ) : null;
};

export default FTUEValidationScreen;
