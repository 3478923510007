import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Drawer } from "common/Components";
import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { SAFE_AREA_BOTTOM } from "stylesheets";

interface RadioOption {
  value: string;
  label: ReactNode;
}

interface ExportDrawerProps {
  open: boolean;
  onDismiss: VoidFunction;
  options: RadioOption[];
  onSubmit: (value: string) => void;
  title: string;
  loading: boolean;
}

const ExportDrawer = ({
  open,
  onDismiss,
  onSubmit,
  options,
  title,
  loading,
}: ExportDrawerProps): JSX.Element => {
  const { t } = useTranslation();

  const [value, setValue] = useState<string | null>(null);

  const onClose = () => {
    setValue(null);
    onDismiss();
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Box p={2} mb={SAFE_AREA_BOTTOM} display="flex" flexDirection="column">
        <Typography variant="h4" textAlign="center" mb={1}>
          {title}
        </Typography>
        <RadioGroup value={value} onChange={(e) => setValue(e.target.value)}>
          {options.map((o, i) => (
            <FormControlLabel
              value={o.value}
              control={<Radio color="secondary" />}
              label={o.label}
              labelPlacement="start"
              sx={{
                justifyContent: "space-between",
                marginLeft: 0,
              }}
              key={i}
              disabled={loading}
            />
          ))}
        </RadioGroup>

        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            onClick={() => value && !loading && onSubmit(value)}
            disabled={!value}
            fullWidth={false}
            color="secondary"
            variant="contained"
          >
            {loading ? (
              <CircularProgress
                size="1.5rem"
                style={{
                  color: "#fff",
                  marginRight: "6px",
                }}
              />
            ) : null}
            {t("dashboard.export.action")}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ExportDrawer;
