import { ButtonBase, ButtonBaseProps } from "@mui/material";
import { PropsWithChildren } from "react";

interface IconButtonProps extends PropsWithChildren, ButtonBaseProps {
  iconSize?: number | string;
  variant?: "default" | "solid";
  bouncing?: boolean;
}

const IconButton = ({
  iconSize = "1.5rem",
  variant = "default",
  bouncing = false,
  sx,
  ...props
}: IconButtonProps): JSX.Element => {
  return (
    <ButtonBase
      {...props}
      centerRipple
      sx={[
        {
          "svg": {
            width: iconSize,
            height: iconSize,
          },
          "padding": 1,
          "borderRadius": 999,
          ":disabled": {
            opacity: 0.5,
          },
          "@keyframes bounce": {
            "70%": { transform: "translateY(0%)" },
            "80%": { transform: "translateY(-20%)" },
            "90%": { transform: "translateY(0%)" },
            "95%": { transform: "translateY(-10%)" },
            "97%": { transform: "translateY(0%)" },
            "99%": { transform: "translateY(-7%)" },
            "100%": { transform: "translateY(0%)" },
          },
          "backgroundColor": variant === "solid" ? "#fff" : "transparent",
          "boxShadow":
            variant === "solid"
              ? "0px 4px 12px 3px rgba(0, 0, 0, 0.15)"
              : undefined,
          "animation": bouncing ? "bounce 2s ease infinite" : "none",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {props.children}
    </ButtonBase>
  );
};

export default IconButton;
