import { useEffect, useState } from "react";

export interface UseMediaQueryResult {
  matches: boolean;
}

const useMediaQuery = (query: string): UseMediaQueryResult => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    query = query.replace("@media ", "");
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, [matches, query]);

  return {
    matches,
  };
};

export default useMediaQuery;
