import FTUEObservationScreen from "./Fullscreen";
import ObservationFTUEHints from "./Hints";
import FTUEObservationLogic from "./ObservationLogic";
import {
  OBSERVATION_PROGRESS_TOTAL,
  ObservationProgress,
} from "./ObservationProgress";

export { OBSERVATION_PROGRESS_TOTAL, ObservationProgress };

const ObservationFTUEFlow = {
  Hints: ObservationFTUEHints,
  Logic: FTUEObservationLogic,
  Screen: FTUEObservationScreen,
};

export default ObservationFTUEFlow;
