import { Box, SxProps, Theme } from "@mui/material";

interface ChatAvatarProps {
  imgSrc: string;
  alt?: string;
  size?: string;
  sx?: SxProps<Theme>;
}

const ChatAvatar = ({
  imgSrc,
  alt = "",
  size = "100%",
  sx,
}: ChatAvatarProps): JSX.Element => {
  return (
    <Box
      sx={[
        {
          width: size,
          height: size,
          img: {
            maxWidth: size,
            maxHeight: size,
          },
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <img src={imgSrc} alt={alt} />
    </Box>
  );
};

export default ChatAvatar;
