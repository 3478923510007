import { ExpandableCard } from "@neurosolutionsgroup/components";
import {
  ChallengeValidationWeek,
  CypressProps,
} from "@neurosolutionsgroup/models";
import { useMemo, useState } from "react";
import ChallengeValidationBody from "./Components/ChallengeValidationBody/ChallengeValidationBody";
import ChallengeValidationSummary from "./Components/ChallengeValidationSummary";

interface ChallengeValidationCardProps extends CypressProps {
  title: string;
  icon: number;
  frequency: number;
  taskIconAssets: Record<number, string>;
  initialValidations: ChallengeValidationWeek[];
  onValidation: (validations: ChallengeValidationWeek[]) => Promise<void>;
  weekIndexToString: (weekIndex: number) => string;
  weekDayToString: (dayIndex: number | null) => string;
}

const ChallengeValidationCardV2 = ({
  title,
  icon,
  frequency,
  taskIconAssets,
  initialValidations,
  onValidation,
  weekIndexToString,
  weekDayToString,
  ...props
}: ChallengeValidationCardProps): JSX.Element => {
  const [open, setOpen] = useState(false);

  const validationsToDo = useMemo(() => {
    let validationsCount = 0;

    initialValidations.forEach((week) => {
      week.validations.forEach((validation) => {
        if (!validation.validationDate) {
          validationsCount++;
        }
      });
    });

    return validationsCount;
  }, [initialValidations]);

  return (
    <ExpandableCard
      open={open}
      setOpen={setOpen}
      summaryComponent={
        <ChallengeValidationSummary
          title={title}
          icon={icon}
          frequency={frequency}
          valiationsToDo={validationsToDo}
          taskIconAssets={taskIconAssets}
          data-cy={
            props["data-cy"]
              ? props["data-cy"] + "-summary"
              : "challenge-validation-summary"
          }
        />
      }
      detailsComponent={
        <ChallengeValidationBody
          onValidation={onValidation}
          initialValidations={initialValidations}
          weekDayToString={weekDayToString}
          weekIndexToString={weekIndexToString}
        />
      }
      data-cy={props["data-cy"]}
    />
  );
};

export default ChallengeValidationCardV2;
