import {
  ButtonBase,
  Grid,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import {
  Language,
  NeedsLoc,
  Note,
  SideEffectById,
  TaskCategoryLoc,
} from "@neurosolutionsgroup/models";
import { SharedStyles } from "@neurosolutionsgroup/themes";
import Icons from "../Icons";
import { Tools } from "@neurosolutionsgroup/tools";

interface NoteCardProps {
  language: Language;
  note: Note;
  onClick: VoidFunction;
  taskCategoryLoc: TaskCategoryLoc;
  sideEffectsById?: SideEffectById;
  skillLoc: NeedsLoc;
  sx?: SxProps<Theme>;
}

const NoteCard = ({
  language,
  note,
  onClick,
  taskCategoryLoc,
  sideEffectsById,
  skillLoc,
  sx,
}: NoteCardProps): JSX.Element => {
  const { palette } = useTheme();

  const cutText = (text: string, limit: number): string => {
    const cutText = text.slice(0, limit);

    const lastSpace = cutText.lastIndexOf(" ");

    const cutTextToLastWord = cutText.slice(0, lastSpace);

    return cutTextToLastWord + "...";
  };

  const description =
    note.text.length <= 95 ? note.text : cutText(note.text, 92);

  const title = () => {
    if (
      sideEffectsById &&
      note.sideEffectId &&
      sideEffectsById[note.sideEffectId] &&
      sideEffectsById[note.sideEffectId].name[language]
    ) {
      return sideEffectsById[note.sideEffectId].name[language];
    }
    if (
      note.needId &&
      skillLoc[language] &&
      skillLoc[language].needs[note.needId]
    ) {
      return skillLoc[language].needs[note.needId];
    }
    if (
      note.routineCategoryId &&
      taskCategoryLoc[language] &&
      taskCategoryLoc[language][note.routineCategoryId]
    ) {
      return taskCategoryLoc[language][note.routineCategoryId];
    }
    return note.title;
  };

  return (
    <ButtonBase
      onClick={onClick}
      sx={[
        {
          width: "100%",
          minHeight: "3rem",
          backgroundColor: "#fff",
          borderRadius: "15px",
          padding: 1,
          paddingLeft: 0.5,
          boxShadow: SharedStyles.Shadows.defaultShadow,
          fontSize: "0.85rem",
          lineHeight: "1.25",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      data-cy="note-card"
    >
      <Grid container alignItems="center">
        <Grid item xs={2.75} sm={2} paddingRight={0.5}>
          <Typography fontSize="inherit">
            {note.eventTime
              ? Tools.Time.Strings.localizedTimeFromSeconds(
                  note.eventTime,
                  language
                )
              : null}
          </Typography>
        </Grid>
        <Grid item xs={8.25} sm={9}>
          <Typography
            textAlign="left"
            fontSize="inherit"
            fontWeight="bold"
            lineHeight="inherit"
          >
            {title()}
          </Typography>
          <Typography textAlign="left" fontSize="inherit" lineHeight="inherit">
            {description}
          </Typography>
        </Grid>
        <Grid
          item
          xs={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Icons.ArrowIcon
            arrowDirection="right"
            color={palette.secondary.main}
          />
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default NoteCard;
