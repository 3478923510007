import { Box, Typography, useTheme } from "@mui/material";
import {
  AddFirstItemButton,
  IconButton,
  Icons,
  InformationButton,
} from "@neurosolutionsgroup/components";
import {
  Language,
  MedicationById,
  Prescription,
  SafeAreas,
} from "@neurosolutionsgroup/models";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PrescriptionCardMenu from "./PrescriptionCardMenu";
import PrescriptionDescription from "./PrescriptionDescription";

interface PrescriptionCardProps {
  childName: string;
  language: Language;
  medicationById: MedicationById;
  prescriptions: Prescription[];
  onAddSecondMedication?: VoidFunction;
  onCreate?: VoidFunction;
  onEdit: (p: Prescription) => void;
  onEnd?: () => void;
  withMenu?: boolean;
  safeAreas: SafeAreas;
  infoIconImage: string;
  infoGraphImage: string;
}

const PrescriptionCard = ({
  childName,
  language,
  medicationById,
  prescriptions,
  onAddSecondMedication,
  onCreate,
  onEdit,
  onEnd,
  withMenu = false,
  safeAreas,
  infoIconImage,
  infoGraphImage,
}: PrescriptionCardProps): JSX.Element => {
  const { palette } = useTheme();
  const { t } = useTranslation();

  const [prescriptionMenuOpen, setPrescriptionMenuOpen] = useState(false);
  const [selectedPrescription, setSelectedPrescription] = useState<
    string | undefined
  >(undefined);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#fff",
          borderRadius: 5,
          marginTop: 2,
        }}
      >
        <Box
          borderBottom={1}
          py={2}
          px={4}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <Typography variant="h3">
            {t("medication.prescriptionCard.title")}
          </Typography>
          <InformationButton
            infoTitle={t("medication.prescriptionCard.title")}
            infoText={
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{
                  img: {
                    width: "80vw",
                    maxHeight: "300px",
                    objectFit: "contain",
                  },
                }}
              >
                <img src={infoGraphImage} alt="" />
                <Typography
                  my={1}
                  mx={1}
                  sx={{
                    svg: {
                      width: "1rem",
                      height: "1rem",
                      marginX: "0.25rem",
                      marginBottom: "-0.15rem",
                    },
                  }}
                >
                  <Trans
                    i18nKey="medication.prescriptionCard.info.text"
                    components={{
                      icon: <Icons.MenuIcon color={palette.secondary.main} />,
                    }}
                  />
                </Typography>
              </Box>
            }
            coachImage={null}
            infoIconImage={infoIconImage}
          />
          {withMenu && (
            <IconButton
              onClick={() => {
                if (withMenu && prescriptions.length > 0) {
                  setPrescriptionMenuOpen(true);
                }
              }}
              sx={{
                marginLeft: "auto",
              }}
            >
              <Icons.MenuIcon color={palette.secondary.main} />
            </IconButton>
          )}
        </Box>
        {prescriptions && prescriptions.length > 0 ? (
          prescriptions.map((p, i) => {
            return (
              <PrescriptionDescription
                key={i}
                language={language}
                medicationById={medicationById}
                prescription={prescriptions[i]}
                isOpen={
                  selectedPrescription === p.prescriptionId ||
                  prescriptions.length < 2
                }
                isAlone={prescriptions.length < 2}
                isLast={i === prescriptions.length - 1}
                setSelectedPrescription={setSelectedPrescription}
                childName={childName}
              />
            );
          })
        ) : (
          <Box display="flex" justifyContent="center" py={4}>
            <AddFirstItemButton
              label={t("medication.prescriptionCard.addPrescription")}
              onClick={() => {
                onCreate && onCreate();
              }}
            />
          </Box>
        )}
      </Box>
      {withMenu && onEnd && (
        <PrescriptionCardMenu
          onEdit={(p) => {
            setPrescriptionMenuOpen(false);
            onEdit(p);
          }}
          onEnd={() => {
            setPrescriptionMenuOpen(false);
            onEnd();
          }}
          isOpen={prescriptionMenuOpen}
          setIsOpen={() => setPrescriptionMenuOpen(false)}
          prescriptions={prescriptions}
          onAddSecond={() => onAddSecondMedication && onAddSecondMedication()}
          language={language}
          medicationById={medicationById}
          safeAreas={safeAreas}
        />
      )}
    </>
  );
};

export default PrescriptionCard;
