import clsx from "clsx";
import isNull from "lodash/isNull";
import range from "lodash/range";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { Drawer } from "common/Components";
import DrawerSection from "common/Components/Drawer/DrawerSection";
import { Icons } from "@neurosolutionsgroup/components";
import { Button, ButtonBase, Typography, useTheme } from "@mui/material";
import ChildIcons from "assets/child-icons";

interface ChildIconPickerProps {
  icon: number | null;
  setIcon: (icon: number | null) => void;
}

export const ChildIconPicker: React.FC<ChildIconPickerProps> = ({
  icon,
  setIcon,
}) => {
  const ROOT_CLASS = "child-icon-picker";

  const { t } = useTranslation(undefined, {
    keyPrefix: "settings.child.icons",
  });

  const { palette } = useTheme();

  const [drawerOpen, setDrawerOpen] = useState(false);

  const availableIcons = useMemo(() => range(0, 6), []);

  const onButtonClick = () => setDrawerOpen(true);
  const onDrawerClose = () => setDrawerOpen(false);
  const onIconSelect = (icon: number) => {
    setIcon(icon);
    setDrawerOpen(false);
  };

  const renderIconOption = (iconOption: number) => (
    <DrawerSection key={iconOption} className={`${ROOT_CLASS}__item`}>
      <ButtonBase
        className={clsx(`${ROOT_CLASS}__icon-drawer-icon`, {
          [`${ROOT_CLASS}__icon-drawer-icon--selected`]: icon === iconOption,
        })}
        onClick={() => onIconSelect(iconOption)}
      >
        <img src={ChildIcons[iconOption]} alt={`Child Icon ${iconOption}`} />
      </ButtonBase>
    </DrawerSection>
  );

  const renderDrawer = () => (
    <Drawer
      classes={{ paper: `${ROOT_CLASS}__icon-drawer` }}
      open={drawerOpen}
      onClose={onDrawerClose}
    >
      <DrawerSection variant="header">
        <Typography variant="h4" className={`${ROOT_CLASS}__icon-drawer-title`}>
          {t("drawerTitle")}
        </Typography>
      </DrawerSection>
      {availableIcons.map(renderIconOption)}
    </Drawer>
  );

  return (
    <>
      <div className={ROOT_CLASS}>
        <Button
          color="secondary"
          variant="text"
          className={`${ROOT_CLASS}__button`}
          onClick={onButtonClick}
        >
          {!isNull(icon) ? (
            <img src={ChildIcons[icon]} alt={`Child Icon ${icon}`} />
          ) : (
            <div className={`${ROOT_CLASS}__text`}>{t("selectIcon")}</div>
          )}
          <div
            className={clsx(
              `${ROOT_CLASS}__indicator`,
              `${ROOT_CLASS}__indicator--right`
            )}
          >
            <Icons.ArrowIcon
              className={`${ROOT_CLASS}__indicator-icon`}
              arrowDirection="down"
              color={palette.secondary.main}
            />
          </div>
        </Button>
      </div>
      {renderDrawer()}
    </>
  );
};

export default ChildIconPicker;
