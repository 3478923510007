import JournalIntro from "./FTUE_Journal_Intro.png";
import ParentSectionFTUE from "./ParentSectionFTUE.png";
import PrescriptionIntro from "./FTUE_Prescription_Intro.png";
import RoutineIntro from "./FTUE_Routine_Intro.png";
import StartDialog from "./FTUE_Start.png";

const FTUEAssets = {
  JournalIntro,
  ParentSectionFTUE,
  PrescriptionIntro,
  RoutineIntro,
  StartDialog,
};

export default FTUEAssets;
