import React, { PropsWithChildren } from "react";
import { Trans, useTranslation } from "react-i18next";
import Validation, { ValidationProgress, VALIDATION_PROGRESS_TOTAL } from "..";
import FTUEHint from "components/FTUEHint";
import { FTUEFlow } from "models/FTUEFlow";
import useFTUE from "useFTUE";
import { InfoDrawer } from "@neurosolutionsgroup/components";
import { Typography } from "@mui/material";

const ValidationEnd: React.FC<PropsWithChildren> = (props) => {
  const { t } = useTranslation();

  const {
    selectors: { assets, progress },
    actions: { canDisplayFlow, setValidationProgress },
  } = useFTUE();

  const validationProgress = progress[FTUEFlow.Validation].progress;

  const onNext = () => {
    setValidationProgress(ValidationProgress.ValidationFTUEFinished);
  };

  return (
    <FTUEHint
      condition={
        validationProgress === ValidationProgress.Validate &&
        canDisplayFlow(FTUEFlow.Validation)
      }
      currentStep={Validation.Logic.calculateProgress(
        ValidationProgress.Validate
      )}
      maxStep={VALIDATION_PROGRESS_TOTAL}
    >
      {props.children}
      {validationProgress === ValidationProgress.Validate &&
      canDisplayFlow(FTUEFlow.Validation) ? (
        <InfoDrawer
          sx={{
            "& .MuiBackdrop-root": {
              display: "none",
            },
          }}
          open={true}
          onClose={onNext}
          title={t("ftue.validation.endScreen.title")}
          text={
            <Typography>
              <Trans
                i18nKey="ftue.validation.endScreen.text"
                components={{ bold: <strong /> }}
              />
            </Typography>
          }
          coachImage={assets.infoCoach}
        />
      ) : null}
    </FTUEHint>
  );
};

export default ValidationEnd;
