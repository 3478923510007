export enum DashboardProgress {
  None = "none",
  Intro = "FTUE_Dashboard_Intro",
  Filters = "FTUE_Dashboard_Filters",
  Routines = "FTUE_Dashboard_Routines",
  RoutinesGraph = "FTUE_Dashboard_Routines_Graph",
  DashboardFTUEFinished = "FTUE_Dashboard_Finished",
}

export const DASHBOARD_PROGRESS_TOTAL = 4;
