export const STOP_SHOWING_IN_APP_REVIEW_FLAG = "stopShowingInAppReview";
export const LAST_IN_APP_REVIEW_DATE_KEY = "lastInAppReviewDate";
export const LAST_IN_APP_REVIEW_30_DAYS_DATE_KEY =
  "lastInAppReview30DaysPurchaseDate";
export const STOP_SHOWING_IN_APP_REVIEW_30_DAYS_KEY =
  "stopShowingInAppReview30DaysPurchase";

export const APP_USED_COUNT_KEY = "appUsedCount";
export const FIRST_SUBSCRIPTION_PURCHASE_DATE_KEY =
  "firstSubscriptionPurchaseDate";
export const FREE_TRIAL_START_DATE = "FreeTrialStartedDate";
